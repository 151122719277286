<template>
  <div>
    <SimpleModal
      ref="modal"
      :title="targetInvitation?.organizationEvent.name"
      no-actions
      @cancel="closeModal"
    >
      <template v-if="targetInvitation">
        <Chip
          :class="eventColorClass(targetInvitation.eventApplicationStatus)"
          class="mb-3"
        >
          {{ translater(targetInvitation.eventApplicationStatus) }}
        </Chip>

        <OrganizationEventBaseInfo
          v-if="targetInvitation && organization"
          :organization-event="targetInvitation.organizationEvent"
          :organization="organization"
        />
      </template>
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { translater } from "/@/modules/string";
import { eventColorClass } from "/@/modules/organizationEvent";
import { SimpleModal } from "/@/vue/components/Molecules";
import { OrganizationEventBaseInfo } from "/@/vue/components/Organisms";
import { OrganizationEventInvitationClient, Organization } from "/@/types";
import Chip from "primevue/chip";

defineProps<{
  organization?: Organization;
}>();

const modal = ref();

const targetInvitation = ref<OrganizationEventInvitationClient>();

function openModal(invitation: OrganizationEventInvitationClient) {
  targetInvitation.value = invitation;

  modal.value?.openModal();
}

function closeModal() {
  targetInvitation.value = undefined;
  modal.value?.closeModal();
}

defineExpose({
  openModal,
  closeModal,
});
</script>

<style scoped></style>
