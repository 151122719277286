<template>
  <div>
    <div v-if="work.scheduleGroupType" class="mb-3">
      <div>目標グループ {{ translater(work.scheduleGroupType) }}</div>
      <div v-if="work.scheduleGroupType === 'unnecessary'">
        <div class="mt-3">
          <BasicButton
            variant="primary"
            w300
            label="案件の編集へ"
            icon="pi pi-file-edit"
            @click="goto({ name: 'WorksEdit', query: { id: work.id } })"
          />
        </div>
      </div>
    </div>
    <div
      v-for="group in work.scheduleGroups"
      :key="group.targetDateRange[0]"
      class="mb-3 card card-body"
    >
      <div>{{ rangeOfTargetDateToStr(group.targetDateRange) }}</div>
      <div
        v-if="
          group.meetingTarget?.id && meetingTargetsMap[group.meetingTarget.id]
        "
      >
        <MeetingTargetViewer
          :meeting-targets="meetingTargetsMap[group.meetingTarget.id]"
          :basis-report-items="basisReportItems"
        />
        <div class="d-flex w-100 mt-3">
          <BasicButton
            variant="success"
            w300
            label="編集"
            icon="pi pi-file-edit"
            @click="$emit('editMeetingTargets', group.meetingTarget)"
          />
          <div class="mx-1"></div>
          <BasicButton
            variant="danger"
            label="削除"
            icon="pi pi-trash"
            button-type="submit"
            w300
            @click="$emit('destroyMeetingTargets', group.meetingTarget)"
          />
        </div>
      </div>
      <div v-else>
        <div class="text-danger mb-3">目標が作成されていません</div>

        <BasicButton
          variant="primary"
          label="追加"
          icon="pi pi-plus"
          w-300
          @click="$emit('addMeetingTargets', [group.workSchedules[0]])"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { fromISO } from "/@/modules/luxon";
import { translater } from "/@/modules/string";
import { useRouterUtil } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import {
  WorkClient,
  WorkSchedule,
  MeetingTargetClient,
  BasisReportItemClient,
} from "/@/types";
import { MeetingTargetViewer } from ".";

const props = defineProps<{
  work: WorkClient;
  basisReportItems: BasisReportItemClient[];
}>();

defineEmits<{
  (e: "addMeetingTargets", wss: WorkSchedule[]): void;
  (e: "editMeetingTargets", meetingTarget: MeetingTargetClient): void;
  (e: "destroyMeetingTargets", meetingTarget: MeetingTargetClient): void;
}>();

const { goto } = useRouterUtil();

function rangeOfTargetDateToStr(range: string[]) {
  return range.map((d) => fromISO(d).toFormat("yyyy年MM月dd日")).join(" - ");
}

const meetingTargetsMap = computed(() => {
  return props.work.meetingTargets.reduce((acc, mt) => {
    acc[mt.id] = mt;
    return acc;
  }, {} as Record<number, MeetingTargetClient>);
});

function getMeetingTargets(id: number) {
  return meetingTargetsMap.value[id];
}
</script>

<style scoped></style>
