<template>
  <div>
    <SelectButton
      :model-value="selectedCarriers"
      :options="carrierItems"
      option-label="name"
      option-value="value"
      multiple
      aria-labelledby="multiple"
      class="me-2"
      :class="itemClass"
      @update:model-value="$emit('update:selectedCarriers', $event)"
    />
    <SelectButton
      :model-value="selectedCategories"
      :options="categoryItems"
      option-label="name"
      option-value="value"
      multiple
      aria-labelledby="multiple"
      class="me-2"
      :class="itemClass"
      @update:model-value="$emit('update:selectedCategories', $event)"
    />
    <SelectButton
      :model-value="selectedPlaces"
      :options="placeItems"
      option-label="name"
      option-value="value"
      multiple
      aria-labelledby="multiple"
      :class="itemClass"
      @update:model-value="$emit('update:selectedPlaces', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import SelectButton from "primevue/selectbutton";
import { computed } from "vue";
import { WorkCategory, Carrier } from "/@/types";
import { translater } from "/@/modules/string";

const props = defineProps<{
  selectedCategories: WorkCategory[];
  selectedPlaces: string[];
  selectedCarriers: Carrier[];
  workCategories: WorkCategory[];
  workPlaces: string[];
  carriers: Carrier[];
  itemClass?: string;
}>();

defineEmits<{
  (e: "update:selectedCategories", categories: WorkCategory[]): void;
  (e: "update:selectedPlaces", places: string[]): void;
  (e: "update:selectedCarriers", carriers: Carrier[]): void;
}>();

const categoryItems = computed(() => {
  return props.workCategories.map((wc) => ({
    name: translater(wc),
    value: wc,
  }));
});

const placeItems = computed(() => {
  return props.workPlaces.map((wp) => ({
    name: wp,
    value: wp,
  }));
});

const carrierItems = computed(() => {
  return props.carriers.map((c) => ({
    name: translater(c),
    value: c,
  }));
});
</script>

<style scoped></style>
