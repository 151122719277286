import Index from "./Index.vue";
import Show from "./Show.vue";

export default [
  {
    path: "",
    name: "WorkRecordDailiesIndex",
    component: Index,
  },
  {
    path: "show",
    name: "WorkRecordDailiesShow",
    component: Show,
  },
];
