<template>
  <div>
    <OrganizationSelector
      v-if="organizationsData"
      v-model:selected-organization-id="selectedOrganizationId"
      class="mb-3"
      :organizations="organizationsData"
    />

    <div
      v-show="organizationMembers && membersHasTasks.length > 1"
      class="mb-3 d-flex flex-wrap flex-column"
    >
      <ContentLabel label="メンバー" />
      <ObjectSelector
        :value="selectedOrganizationMemberId"
        :items="membersHasTasks || []"
        value-key="id"
        :show-fn="(member: OrganizationMember) => member.user.name"
        form-id="select-organization-member"
        without-select-text="全員"
        is-valid
        can-without-select
        @update:value="selectedOrganizationMemberId = $event?.id"
      />
    </div>

    <SelectDate
      v-model:year="selectedDate.year"
      v-model:month="selectedDate.month"
      :exists-dates="existsDates"
      only-year-month
      with-reset-btn
      disabled-auto-select
      class="mb-3"
    />

    <WorkTaskList
      :work-tasks="selectedMemberTasks"
      :last-work-record-entry="lastWorkRecordEntry"
      :basis-report-items="basisReportItems || []"
      :selected-id="selectedId"
      :is-owner="isOwner"
      @update-work-task="handleUpdate"
      @destroy-work-task="handleDestroy"
      @toggle-contract-work-task="handleContract"
      @toggle-lost-work-task="handleLost"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, reactive } from "vue";
import {
  luxonNow,
  createIsoRange,
  isSameMonth,
  isSameYear,
} from "/@/modules/luxon";
import { getLast } from "/@/modules/workRecords";
import {
  useOrganization,
  useWorkTasks,
  useWorkRecords,
  useRouterUtil,
  useOrganizationMember,
  useBasisReportItems,
} from "/@/vue/composables";
import {
  ContentLabel,
  ObjectSelector,
  SelectDate,
} from "/@/vue/components/Atom";
import {
  WorkTaskList,
  OrganizationSelector,
} from "/@/vue/components/Organisms";
import {
  WorkTaskClient,
  OrganizationMember,
  WorkRecordClient,
  LostReason,
  DateMapForm,
} from "/@/types";

const { query } = useRouterUtil();

const selectedId = computed(() => {
  if (Array.isArray(query.value.id)) {
    return undefined;
  }

  return query.value.id ? parseInt(query.value.id) : undefined;
});

// date

const today = luxonNow();

const selectedDate = reactive<DateMapForm>({
  year: undefined,
  month: undefined,
});

const existsDates = computed(() => {
  const startOn = "2023-06-01"; // Log 始動月
  const endOn = today.toISO(); // 次の月は見れるように?

  if (!endOn) {
    throw new Error("cannot get today");
  }

  const range = createIsoRange(startOn, endOn, "month");

  return range;
});

// organization

const {
  getOrganizations,
  isOwner,
  selectedOrganizationId,
  selectedOrganization,
  organizations,
} = useOrganization();
const { data: organizationsData } = getOrganizations();

watch(organizationsData, (os) => {
  if (!os) return;
  organizations.value = os;
});

const { getOrganizationMembers } = useOrganizationMember();
const { data: organizationMembers } = getOrganizationMembers(
  selectedOrganization,
  isOwner
);

const currentOrganizationMemberId = computed(() => {
  if (!selectedOrganization.value) {
    return;
  }

  return selectedOrganization.value.organizationMemberId;
});

watch(currentOrganizationMemberId, (id) => {
  if (!id) return;
  selectedOrganizationMemberId.value = id;
});

const selectedOrganizationMemberId = ref<number | undefined>();

const {
  getWorkTasks,
  editWorkTask,
  destroyWorkTask,
  toggleContractWorkTask,
  toggleLostWorkTask,
} = useWorkTasks();
const { data: workTasks, mutate: getWorkTasksMutate } =
  getWorkTasks(selectedOrganization);

const membersHasTasks = computed(() => {
  if (!workTasks.value || !organizationMembers.value) {
    return [];
  }

  const memberIdsHasTasks = [
    ...new Set(workTasks.value.map((task) => task.organizationMemberId)),
  ];

  return organizationMembers.value.filter((member) =>
    memberIdsHasTasks.includes(member.id)
  );
});

const filteredWorkTasks = computed(() => {
  if (!workTasks.value) return [];

  if (!selectedDate.year && !selectedDate.month) {
    return workTasks.value;
  }

  if (selectedDate.year && !selectedDate.month) {
    return workTasks.value.filter((wt) =>
      isSameYear(selectedDate, wt.actionAt)
    );
  }

  return workTasks.value.filter((wt) => isSameMonth(selectedDate, wt.actionAt));
});

const selectedMemberTasks = computed(() => {
  if (!selectedOrganizationMemberId.value) {
    return filteredWorkTasks.value;
  }
  return filteredWorkTasks.value.filter(
    (task) => task.organizationMemberId === selectedOrganizationMemberId.value
  );
});

// current work record
const { getWorkRecords } = useWorkRecords();
const { data: workRecords } = getWorkRecords(selectedOrganization);

const lastWorkRecord = computed<WorkRecordClient | undefined>(() => {
  return getLast(workRecords.value);
});

const lastWorkRecordEntry = computed(() => {
  if (!lastWorkRecord.value) {
    return;
  }

  return getLast(lastWorkRecord.value.workRecordEntries);
});

// basis report items

const { getBasisReportItems } = useBasisReportItems();
const { data: basisReportItems } = getBasisReportItems();

// update

const loading = ref<boolean>(false);

async function handleUpdate(workTask: WorkTaskClient) {
  loading.value = true;

  if (await editWorkTask(workTask)) {
    alert("更新に成功しました");
    getWorkTasksMutate();
  } else {
    alert("更新に失敗しました");
  }

  loading.value = false;
}

async function handleDestroy(id: number) {
  if (!window.confirm("表示中のタスクを削除します。よろしいですか？")) {
    return;
  }

  if (await destroyWorkTask(id)) {
    alert("削除に成功しました");
    getWorkTasksMutate();
  } else {
    alert("削除に失敗しました");
  }
}

async function handleContract(prms: {
  id: number;
  countInContractedDate?: boolean;
}) {
  const { id } = prms;

  const targetTask = workTasks.value?.find((task) => task.id === id);

  if (!targetTask) {
    return;
  }

  if (
    !window.confirm(
      targetTask.contractedAt
        ? "成約をキャンセルします。よろしいですか？"
        : "成約完了にします。よろしいですか？"
    )
  ) {
    return;
  }

  if (await toggleContractWorkTask(prms)) {
    alert("成約処理に成功しました");
    getWorkTasksMutate();
  } else {
    alert("成約処理に失敗しました");
  }
}

async function handleLost(prms: {
  id: number;
  lostReason?: LostReason;
  lostReasonDetails?: string;
}) {
  const { id } = prms;

  const targetTask = workTasks.value?.find((task) => task.id === id);

  if (!targetTask) {
    return;
  }

  if (
    !window.confirm(
      targetTask.lostedAt
        ? "失注をキャンセルします。よろしいですか？"
        : "失注します。よろしいですか？"
    )
  ) {
    return;
  }

  if (await toggleLostWorkTask(prms)) {
    alert("失注処理に成功しました");
    getWorkTasksMutate();
  } else {
    alert("失注処理に失敗しました");
  }
}
</script>

<style scoped></style>
