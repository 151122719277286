import { EventApplicationStatus } from "/@/types";

export function eventStatusColor(status: EventApplicationStatus) {
  switch (status) {
    case "pending":
      return "warning";
    case "approved":
      return "success";
    case "rejected":
      return "danger";
  }
}

export function eventColorClass(eventStatus: EventApplicationStatus) {
  return `bg-${eventStatusColor(eventStatus)} text-white`;
}
