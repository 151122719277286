import parsePhoneNumber from "libphonenumber-js";

export function parseJpPhoneNumber(number: string) {
  const parsed = parsePhoneNumber(number, "JP");

  if (!parsed) {
    return undefined;
  }

  return parsed;
}

export function formatNational(number: string) {
  return parseJpPhoneNumber(number)?.formatNational();
}

export function nationalToFlat(nationalNumber: string | undefined) {
  if (!nationalNumber) {
    return undefined;
  }

  const parsed = parsePhoneNumber(nationalNumber, "JP");

  if (!parsed) {
    return nationalNumber;
  }

  return "0" + parsed.nationalNumber;
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const n = "09011112222";

  it("parseJpPhoneNumber", () => {
    expect(parseJpPhoneNumber("hoge")).toBeFalsy();
    expect(parseJpPhoneNumber("09011112222")).toBeTruthy();
  });

  it("formatNational", () => {
    expect(formatNational(n)).toBe("090-1111-2222");
    expect(formatNational("hoge")).toBeFalsy();
  });

  it("nationalToFlat", () => {
    expect(nationalToFlat("hoge")).toBe("hoge");
    expect(nationalToFlat("09011112222")).toBe("09011112222");
    expect(nationalToFlat("090-1111-2222")).toBe("09011112222");
  });
}
