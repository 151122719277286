<template>
  <div id="header">
    <Toolbar>
      <template #start>
        <Button
          v-if="user"
          icon="pi pi-bars"
          class="me-2"
          id="menu-button"
          @click="showHeader = !showHeader"
        />
        <span
          id="current-page-title"
          class="fw-bold"
          :class="{
            h4: !isMobile,
            h6: isMobile,
          }"
          >{{ currentRouteLabel }}</span
        >
      </template>
      <template #end>
        <div v-if="user" class="d-flex align-items-center">
          <router-link
            :to="{ name: 'UsersIndex' }"
            :class="{ 'me-3': !isMobile }"
          >
            <div
              class="d-flex flex-row justify-content-center align-items-center"
            >
              <div
                class="user-icon d-flex justify-content-center align-items-center"
                :class="{
                  'me-3': !isMobile,
                }"
              >
                {{ user.name.at(0) }}
              </div>
              <div
                v-if="!isMobile"
                class="d-flex flex-column align-items-center"
              >
                <div>{{ user.name.slice(0, 7) }}</div>
                <small class="user-mail">{{ user.email }}</small>
              </div>
            </div>
          </router-link>
          <div
            class="dropdown d-flex flex-row align-items-center justify-content-center"
          >
            <DropdownWrapper content-id="for-logout">
              <template #toggler>
                <span class="toggler-text">⋮</span>
              </template>
              <template #contents>
                <li>
                  <button class="dropdown-item" @click="handleLogout">
                    Logout
                  </button>
                </li>
              </template>
            </DropdownWrapper>
          </div>
        </div>
      </template>
    </Toolbar>
    <Sidebar v-if="user" v-model:visible="showHeader">
      <TieredMenu :model="headerItems" class="without-border w-100" />
    </Sidebar>
    <Breadcrumb
      v-if="
        breadcrumbs.length > 1 &&
        user &&
        currentRoute.name !== 'Home' &&
        !isAdminPage
      "
      :home="breadcrumbHome"
      :model="breadcrumbs"
    />
  </div>
</template>

<script lang="ts" setup>
import Sidebar from "primevue/sidebar";
import Button from "primevue/button";
import TieredMenu from "primevue/tieredmenu";
import Toolbar from "primevue/toolbar";
import Breadcrumb from "primevue/breadcrumb";
import { ref, onMounted, computed } from "vue";
import { Collapse } from "bootstrap";
import { useMqUtils, useRouterUtil, useAuthorize } from "/@/vue/composables";
import { DropdownWrapper } from "/@/vue/components/Molecules";
import type { User } from "/@/types/user";

interface Props {
  user?: User;
  isAdminPage?: boolean;
}

const props = defineProps<Props>();
defineEmits(["logout", "hideHeader"]);

const showHeader = ref<boolean>(false);

const { isMobile } = useMqUtils();

const canCreateWork = computed(() => {
  if (!props.user) return false;

  return props.user.organizationCategories.some((oc) => oc === "create_work");
});

const isOwner = computed(() => {
  return props.user?.organizations.find((o) => o.roles.includes("owner"));
});

const isOrganizationOwner = computed(() => {
  const joinedOrganizations = props.user?.organizations;

  if (!joinedOrganizations) return false;

  return joinedOrganizations.some((o) => o.roles.includes("owner"));
});

const isDepartmentOwner = computed(() => {
  const joinedOrganizations = props.user?.organizations;

  if (!joinedOrganizations) return false;

  return joinedOrganizations.some(
    // label_type is department_owner
    (o) => o.labels.some((label) => label.labelType === "department_owner")
  );
});

const isFRYTH = computed(() => {
  return !!props.user?.organizations.some((o) => o.name === "(株)FRYTH");
});

const isFRYTHOwner = computed(() => {
  return isFRYTH.value && isOrganizationOwner.value;
});

const canShowWorkMeetingTarget = computed(() => {
  const joinedOrganizations = props.user?.organizations;

  if (!joinedOrganizations) return false;

  return joinedOrganizations.some(
    // label_type is department_owner
    (o) =>
      o.labels.some(
        (label) =>
          label.labelType === "request_organization" &&
          label.config.canShowWorkMeetingTarget
      )
  );
});

const { query, currentRoute, goto } = useRouterUtil();

const routeNames = {
  SignIn: "ログイン",
  SignUp: "新規登録",
  Home: "ホーム",
  //
  AntibodyTestsNew: "抗体検査提出",
  AttendancesIndex: "出勤簿",
  BodyTemperaturesNew: "体温提出",
  EditRequestsIndex: "編集リクエスト",
  LaborInfosIndex: "勤務状況",
  LaborInfosShow: "労務管理",
  ManagementLabelsIndex: "ラベル管理",
  ManagementLabelsEdit: "ラベルの設定画面",
  MeetingTargetsIndex: "目標設定",
  OrganizationEventsIndex: "ミーティング",
  OrganizationsShow: "組織情報",
  PlansIndex: "業務計画",
  PortalIndex: "ポータル",
  TaskManagersIndex: "タスク管理",
  UsersIndex: "アカウント情報",
  WorkRecordDailiesIndex: "業務記録",
  WorkRecordDailiesShow: "記録詳細",
  WorkRecordsNew: "業務開始",
  WorkRecordsFinish: "業務終了",
  WorkRecordsEnter: "入店",
  WorkRecordsLeave: "退店",
  SubmitLocation: "位置情報提出",
  WorkReportsIndex: "案件実績",
  WorksIndex: "案件一覧",
  WorksNew: "案件作成",
  WorksEdit: "案件編集",
  WorkTasksIndex: "予約・架電管理",
};

const breadcrumbHome = ref({
  icon: "pi pi-home",
  to: { name: "Index" },
});

const breadcrumbs = computed(() => {
  const routeName = currentRoute.value.name?.toString();

  if (!routeName) return [];

  let result = [];

  const homeChildren = [
    "AntibodyTestsNew",
    "AttendancesIndex",
    "BodyTemperaturesNew",
    "EditRequestsIndex",
    "LaborInfosIndex",
    "LaborInfosShow",
    "ManagementLabelsIndex",
    "MeetingTargetsIndex",
    "OrganizationEventsIndex",
    "OrganizationsShow",
    "PlansIndex",
    "PortalIndex",
    "TaskManagersIndex",
    "UsersIndex",
    "WorkRecordDailiesIndex",
    "WorkRecordsNew",
    "WorkRecordsFinish",
    "WorkRecordsEnter",
    "WorkRecordsLeave",
    "SubmitLocation",
    "WorkReportsIndex",
    "WorksIndex",
    "WorkTasksIndex",
  ];

  const ManagementLabelsIndexChildren = ["ManagementLabelsEdit"];
  const WorkRecordDailiesIndexChildren = ["WorkRecordDailiesShow"];
  const WorksIndexChildren = ["WorksNew", "WorksEdit"];

  if (homeChildren.includes(routeName)) {
    result.push(routeName);
  } else if (ManagementLabelsIndexChildren.includes(routeName)) {
    result.push("ManagementLabelsIndex");
    result.push(routeName);
  } else if (WorkRecordDailiesIndexChildren.includes(routeName)) {
    result.push("WorkRecordDailiesIndex");
    result.push(routeName);
  } else if (WorksIndexChildren.includes(routeName)) {
    result.push("WorksIndex");
    result.push(routeName);
  }

  return result.map((rName) => ({
    // @ts-ignore
    label: routeNames[rName],
    to: {
      name: rName,
    },
  }));
});

function commandHandler(command: () => void) {
  return () => {
    command();
    showHeader.value = false;
  };
}

const headerItems = computed(() => {
  return [
    {
      label: routeNames.SignIn,
      icon: "pi pi-home",
      visible: !props.user,
      command: commandHandler(() =>
        goto({ name: "SignIn", query: query.value })
      ),
    },
    {
      label: routeNames.Home,
      icon: "pi pi-home",
      command: commandHandler(() => goto({ name: "Home", query: query.value })),
    },
    {
      label: routeNames.WorksIndex,
      icon: "pi pi-shopping-bag",
      visible: canCreateWork.value && isFRYTHOwner.value,
      command: commandHandler(() =>
        goto({ name: "WorksIndex", query: query.value })
      ),
    },
    {
      label: routeNames.PlansIndex,
      icon: "pi pi-calendar",
      command: commandHandler(() =>
        goto({ name: "PlansIndex", query: query.value })
      ),
    },
    {
      label: routeNames.OrganizationEventsIndex,
      icon: "pi pi-flag",
      visible: isFRYTHOwner.value,
      command: commandHandler(() =>
        goto({ name: "OrganizationEventsIndex", query: query.value })
      ),
    },
    {
      label: routeNames.LaborInfosIndex,
      icon: "pi pi-server",
      visible: isFRYTHOwner.value,
      command: commandHandler(() =>
        goto({ name: "LaborInfosIndex", query: query.value })
      ),

      /*
      items: [
        {
          label: routeNames.LaborInfosIndex,
          icon: "pi pi-id-card",
          command: () => {
            goto({ name: "LaborInfosIndex", query: query.value });
          },
        },
      ],
      */
    },
    {
      label: routeNames.LaborInfosShow,
      icon: "pi pi-server",
      visible: isFRYTHOwner.value,
      command: commandHandler(() =>
        goto({ name: "LaborInfosShow", query: query.value })
      ),
    },
    {
      label: routeNames.MeetingTargetsIndex,
      icon: "pi pi-chart-line",
      visible: isFRYTHOwner.value || canShowWorkMeetingTarget.value,
      command: commandHandler(() =>
        goto({ name: "MeetingTargetsIndex", query: query.value })
      ),
    },
    {
      label: routeNames.AttendancesIndex,
      icon: "pi pi-id-card",
      //visible: isFRYTH.value && (isOwner.value || isDepartmentOwner.value),
      visible: isFRYTHOwner.value,
      command: commandHandler(() =>
        goto({ name: "AttendancesIndex", query: query.value })
      ),
    },
    {
      label: routeNames.WorkReportsIndex,
      icon: "pi pi-chart-bar",
      visible: isFRYTH.value,
      command: commandHandler(() =>
        goto({ name: "WorkReportsIndex", query: query.value })
      ),
    },
    {
      label: routeNames.WorkRecordDailiesIndex,
      icon: "pi pi-clock",
      visible: isFRYTH.value,
      command: commandHandler(() =>
        goto({ name: "WorkRecordDailiesIndex", query: query.value })
      ),
    },
    {
      label: routeNames.WorkTasksIndex,
      icon: "pi pi-file-edit",
      command: commandHandler(() =>
        goto({ name: "WorkTasksIndex", query: query.value })
      ),
    },
    {
      label: routeNames.EditRequestsIndex,
      icon: "pi pi-history",
      //notificationCout: props.user?.unhandledRequestsCount,
      visible: false,
      command: commandHandler(() =>
        goto({ name: "EditRequestsIndex", query: query.value })
      ),
    },
    {
      label: routeNames.ManagementLabelsIndex,
      icon: "pi pi-tags",
      visible: isFRYTHOwner.value,
      command: commandHandler(() =>
        goto({ name: "ManagementLabelsIndex", query: query.value })
      ),
    },
    {
      label: routeNames.OrganizationsShow,
      icon: "pi pi-users",
      command: commandHandler(() =>
        goto({ name: "OrganizationsShow", query: query.value })
      ),
    },
    {
      label: routeNames.UsersIndex,
      icon: "pi pi-user",
      command: commandHandler(() =>
        goto({ name: "UsersIndex", query: query.value })
      ),
    },
  ];
});

// query

const currentRouteLabel = computed(() => {
  const routeName = currentRoute.value.name?.toString();

  if (!routeName) return "";
  // @ts-ignore
  return routeNames[routeName];
});

// color
const isDark = ref(false);

function toggleTheme() {
  isDark.value = !isDark.value;
  const htmlElm = document.getElementById("root");

  if (!htmlElm) {
    return;
  }

  if (isDark.value) {
    htmlElm.setAttribute("data-bs-theme", "dark");
  } else {
    htmlElm.setAttribute("data-bs-theme", "light");
  }
}

// header collapse

const headerCollapse = ref();

onMounted(() => {
  const header = document.getElementById("navbarSupportedContent");

  if (header) {
    headerCollapse.value = new Collapse(header, {
      toggle: false,
    });
  }
});

// logout

const { user, logout } = useAuthorize();

async function handleLogout() {
  await logout();
}
</script>

<style lang="scss" scoped>
#header {
  color: $main-gray-color;
}
.user-icon {
  border: 1px solid $main-gray-color;
  border-radius: 50%;
  height: 36px;
  width: 36px;
}
.user-mail {
  font-size: 50%;
}
</style>
