<template>
  <div id="admins-root">
    <TabMenu v-if="admin" :model="menuItems" />
    <router-view></router-view>
  </div>
</template>

<script setup lang="ts">
import TabMenu from "primevue/tabmenu";
import { computed } from "vue";
import { useRouterUtil, useAuthorize } from "/@/vue/composables";
import type { MenuItem } from "primevue/menuitem";

const { admin } = useAuthorize();
const { goto } = useRouterUtil();

const menuItems = computed<MenuItem[]>(() => {
  return [
    {
      label: "ホーム",
      icon: "pi pi-fw pi-home",
      command: () => {
        goto({ name: "AdminsHome" });
      },
    },
    {
      label: "イベント会場管理",
      icon: "pi pi-fw pi-bolt",
      command: () => {
        goto({ name: "AdminsEventPlaces" });
      },
    },
    {
      label: "店舗管理",
      icon: "pi pi-fw pi-microsoft",
      command: () => {
        goto({ name: "AdminsWorkPlaces" });
      },
    },
    {
      label: "商材設定",
      icon: "pi pi-fw pi-shopping-cart",
      command: () => {
        goto({ name: "AdminsReportItems" });
      },
    },
  ];
});
</script>

<style lang="scss" scoped></style>
