<template>
  <div>
    <div class="input-group">
      <div v-if="$slots.frontSlot" class="input-group-text">
        <slot name="frontSlot"></slot>
      </div>
      <slot name="frontButtonSlot"></slot>
      <input
        :id="formId"
        :value="value"
        :class="formClass"
        :type="formType"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :disabled="disabled"
        :readonly="readonly"
        :step="step"
        :min="min"
        :max="max"
        :pattern="pattern"
        @input="handleInput"
        @change="handleChange"
      />
      <div v-if="$slots.backSlot" class="input-group-text">
        <slot name="backSlot"></slot>
      </div>
      <slot name="backButtonSlot"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
  value?: string | number | undefined;
  isValid?: boolean;
  formId?: string;
  placeholder?: string;
  autocomplete?: string;
  formType?: string;
  disabled?: boolean;
  readonly?: boolean;
  step?: number;
  textEnd?: boolean;
  withoutBorder?: boolean;
  whenChange?: boolean;
  max?: number;
  min?: number;
  pattern?: string;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "update:value", value: string): void;
}>();

const formClass = computed(() => {
  return {
    "text-end": props.textEnd,
    "form-control": true,
    "is-invalid": !props.isValid,
    "no-border": props.withoutBorder,
  };
});

function update(e: Event) {
  if (e.target instanceof HTMLInputElement) {
    emit("update:value", e.target.value);
  } else {
    throw new Error("予期せぬエラーが発生しました");
  }
}

function handleInput(e: Event) {
  if (props.whenChange) {
    return;
  }

  update(e);
}

function handleChange(e: Event) {
  if (!props.whenChange) {
    return;
  }

  update(e);
}
</script>

<style lang="scss" scoped>
.no-border {
  border: none;
}

.input-group-text {
  --bs-tertiary-bg: white;
}
</style>
