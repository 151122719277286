import zod from "zod";
import { DateTimeScheme, z } from "/@/modules/zodUtils";
import { PrefectureScheme } from "/@/types/address.d";
import { PhotoDataObjectScheme } from "/@/types/axios.d";

export const EventPlaceAttachedImageScheme = z.object({
  id: z.number().optional(),
  name: z.string(),
  imageUrl: z.string().optional().nullable(),
  image: PhotoDataObjectScheme.optional(),
});

export type EventPlaceAttachedImage = zod.infer<
  typeof EventPlaceAttachedImageScheme
>;

export const EventPlaceAttachedImageFormScheme =
  EventPlaceAttachedImageScheme.partial().extend({
    _destroy: z.number().optional(),
  });

export type EventPlaceAttachedImageForm = zod.infer<
  typeof EventPlaceAttachedImageFormScheme
>;

export const EventPlaceSelectedPlaceScheme = z.object({
  workPlaceId: z.number(),
  workPlaceName: z.string().optional(),
});

export const EventPlaceSelectedPlaceFormScheme =
  EventPlaceSelectedPlaceScheme.partial().extend({
    _destroy: z.number().optional(),
  });

export type EventPlaceSelectedPlaceForm = zod.infer<
  typeof EventPlaceSelectedPlaceFormScheme
>;

export const EventPlaceScheme = z.object({
  name: z.string(),
  shortName: z.string().optional(),
  prefecture: PrefectureScheme,
  postcode: z.string().optional(),
  address: z.string().optional(),
  venueName: z.string().optional(),
  gmapUrl: z.string().optional(),
  meetingPlace: z.string().optional(),
  firstMeetingAt: DateTimeScheme.optional(),
  meetingAt: DateTimeScheme.optional(),
  startAt: DateTimeScheme.optional(),
  finishAt: DateTimeScheme.optional(),
  eventPlaceAttachedImages: EventPlaceAttachedImageScheme.array().min(0),
  eventPlaceSelectedPlaces: EventPlaceSelectedPlaceScheme.array().min(0),
});

export type EventPlace = zod.infer<typeof EventPlaceScheme>;

export const EventPlaceCreateFormScheme = EventPlaceScheme.partial().extend({
  eventPlaceAttachedImagesAttributes: EventPlaceAttachedImageFormScheme.array(),
  eventPlaceSelectedPlacesAttributes: EventPlaceSelectedPlaceScheme.array(),
});
export type EventPlaceCreateForm = zod.infer<typeof EventPlaceCreateFormScheme>;

export const EventPlaceUpdateScheme = EventPlaceCreateFormScheme.extend({
  id: z.number(),
});
export type EventPlaceUpdate = zod.infer<typeof EventPlaceUpdateScheme>;

export const EventPlaceUpdateFormScheme = EventPlaceUpdateScheme.partial();
export type EventPlaceUpdateForm = zod.infer<typeof EventPlaceUpdateFormScheme>;

export const EventPlaceClientScheme = EventPlaceScheme.extend({
  id: z.number(),
});

export type EventPlaceClient = zod.infer<typeof EventPlaceClientScheme>;
