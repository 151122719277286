<template>
  <div>
    <SimpleModal
      ref="modal"
      :title="modalTitle"
      :submit-text="submitText"
      :loading="loading"
      :modal-class="{
        'modal-lg': true,
      }"
      scrollable
      @cancel="closeModal"
      @submit="handleSubmit"
    >
      <div class="form-wrapper">
        <div v-if="selectedReportItem" class="mb-3">
          <ContentLabel label="選択した項目" />
          <div>
            {{ selectedReportItem.name }}
          </div>
        </div>

        <div class="mb-3">
          <ContentLabel label="グループ" />
          <Dropdown
            v-model="form.groupId"
            :options="basisReportItemGroups"
            option-label="name"
            option-value="id"
            placeholder="グループを選択"
            show-clear
          />
          <BasicButton
            small
            variant="primary"
            icon="pi pi-plus"
            label="グループを追加"
            class="ms-2"
            @click="createGroup"
          />
          <BasicButton
            v-if="form.groupId"
            small
            variant="danger"
            icon="pi pi-trash"
            label="削除"
            class="ms-2"
            @click="destroyGroup"
          />
        </div>

        <div v-if="selectedReportItem && false" class="delete-btn">
          <BasicButton
            small
            variant="danger"
            icon="pi pi-trash"
            @click="onDestroy"
          />
        </div>

        <AdminBasisReportItemBaseForm
          v-model:name="form.name"
          v-model:short-name="form.shortName"
          v-model:unit="form.unit"
          v-model:unit-range="form.unitRange"
          v-model:visible="form.visible"
          v-model:valid-from="form.validFrom"
          v-model:valid-to="form.validTo"
          :errors="errors"
          :is-meeting-target-index="isMeetingTargetIndex"
        />

        <template v-if="!isMeetingTargetIndex">
          <BasicButton
            small
            variant="primary"
            icon="pi pi-plus"
            label="設定を追加"
            class="mb-3"
            :disabled="addDisabled"
            @click="addConfig"
          />
          <Accordion :active-index="0">
            <AccordionTab
              v-for="(config, idx) in form.configs"
              :key="`config-${idx}`"
            >
              <template #header>
                <div
                  class="d-flex justify-content-between align-items-center w-100"
                >
                  <span>
                    設定{{ idx + 1 }}
                    {{
                      config.workCarriers.map((c) => translater(c)).join("/")
                    }}
                    {{
                      config.workCategories.map((c) => translater(c)).join("/")
                    }}
                  </span>
                  <BasicButton
                    v-if="form.configs.length > 1"
                    small
                    variant="danger"
                    icon="pi pi-trash"
                    @click="removeConfig(idx)"
                  />
                </div>
              </template>
              <div class="d-flex flex-wrap">
                <div class="mb-3 me-2" style="max-width: 100%">
                  <ContentLabel label="業務種別" />
                  <div style="overflow: scroll" class="w-100">
                    <MultiSelect
                      v-model="config.workCategories"
                      :options="workCategories"
                      :option-label="translater"
                      :compare-fn="categoryCompareFn"
                      placeholder="未選択の場合は全てで表示されます"
                    />
                  </div>
                </div>

                <div class="mb-3 me-2 w-100" style="max-width: 100%">
                  <ContentLabel label="キャリアー" />
                  <MultiSelect
                    v-model="config.workCarriers"
                    :options="carriers"
                    :option-label="translater"
                    :compare-fn="carrierCompareFn"
                    placeholder="未選択の場合は全てで表示されます"
                  />
                </div>

                <div class="mb-3" style="max-width: 100%">
                  <ContentLabel label="依頼組織" />
                  <MultiSelect
                    v-model="config.requestOrganizationIds"
                    :options="requestOrganizations"
                    option-label="name"
                    option-value="id"
                    filter
                    placeholder="未選択の場合は全てで表示されます"
                  />
                </div>
              </div>

              <AdminBasisReportItemBaseForm
                v-model:name="config.name"
                v-model:short-name="config.shortName"
                v-model:unit="config.unit"
                v-model:unit-range="config.unitRange"
                v-model:valid-from="config.validFrom"
                v-model:valid-to="config.validTo"
                v-model:visible="config.visible"
                :base-name="form.name"
                :base-short-name="form.shortName"
                :base-unit="form.unit"
                :base-unit-range="form.unitRange"
                is-config
                :errors="errors?.config?.[idx] || { _errors: [] }"
              />

              <ContentLabel label="使用する種別" />
              <div class="d-flex flex-wrap">
                <ToggleButtonLabelItem
                  v-model:value="config.individualCustomers"
                  label="個別応対記録"
                  content-id="individualCustomers"
                  horizontal
                  class="mb-3 me-2"
                />
                <ToggleButtonLabelItem
                  v-model:value="config.overallCustomers"
                  label="全体応対記録"
                  content-id="overallCustomers"
                  horizontal
                  class="mb-3 me-2"
                />
                <ToggleButtonLabelItem
                  v-model:value="config.individual"
                  label="個別実績"
                  content-id="individual"
                  horizontal
                  class="mb-3 me-2"
                />
                <ToggleButtonLabelItem
                  v-model:value="config.overall"
                  label="全体実績"
                  content-id="overall"
                  horizontal
                  class="mb-3 me-2"
                />
              </div>
            </AccordionTab>
          </Accordion>
          <BasicButton
            small
            variant="primary"
            icon="pi pi-plus"
            class="mt-3"
            :disabled="addDisabled"
            label="設定を追加"
            @click="addConfig"
          />
        </template>
      </div>
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { translater } from "/@/modules/string";
import { carrierCompareFn } from "/@/modules/carrier";
import { categoryCompareFn } from "/@/modules/workCategory";
import { useZodScheme } from "/@/vue/composables";
import { BasicButton, ContentLabel } from "/@/vue/components/Atom";
import {
  SimpleModal,
  ToggleButtonLabelItem,
} from "/@/vue/components/Molecules";
import {
  BasisReportItemClient,
  BasisReportItemGroupClient,
  BasisReportItemForm,
  BasisReportItemScheme,
  BasisReportItem,
  BasisReportItemKey,
  Organization,
  workCategories,
  carriers,
  WorkCategory,
  Carrier,
} from "/@/types";
import { errorHandle } from "/@/modules/error";
import { MultiSelect } from "/@/vue/components/Atom";
import { AdminBasisReportItemBaseForm } from "/@/vue/components/Organisms";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Dropdown from "primevue/dropdown";

const props = defineProps<{
  selectedWorkCategory?: WorkCategory;
  selectedWorkCarrier?: Carrier;
  basisReportItems: BasisReportItemClient[];
  basisReportItemGroups: BasisReportItemGroupClient[];
  requestOrganizations: Organization[];
  isMeetingTargetIndex?: boolean;
}>();

const emit = defineEmits<{
  (e: "submit", form: BasisReportItem): void;
  (e: "destroy", id: number): void;
  (e: "createGroup", name: string): void;
  (e: "destroyGroup", id: number): void;
}>();

//

const modalTitle = computed(() => {
  return selectedReportItem.value ? "項目の編集" : "項目の追加";
});

const submitText = computed(() => {
  return selectedReportItem.value ? "更新" : "追加";
});

const loading = ref<boolean>(false);

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } = useFormAndErrors<BasisReportItemForm>(
  BasisReportItemScheme
);

function handleSubmit() {
  try {
    loading.value = true;
    startValidation.value = true;

    const basisReportItemForm: BasisReportItem =
      BasisReportItemScheme.parse(form);
    emit("submit", basisReportItemForm);
    closeModal();
  } catch (e) {
    errorHandle(e);
    loading.value = false;
  }
}

const modal = ref();

function generateDefaultConfig() {
  if (selectedReportItem.value) {
    return {
      workCategories: [],
      workCarriers: [],
      requestOrganizationIds: [],
      overall: false,
      individual: false,
      individualCustomers: false,
      overallCustomers: false,
      visible: true,
    };
  } else {
    return {
      workCategories: props.selectedWorkCategory
        ? [props.selectedWorkCategory]
        : [],
      workCarriers: props.selectedWorkCarrier
        ? [props.selectedWorkCarrier]
        : [],
      requestOrganizationIds: [],
      overall: false,
      individual: false,
      individualCustomers: false,
      overallCustomers: false,
      visible: true,
    };
  }
}

function resetForm(reportItem?: BasisReportItemClient) {
  if (reportItem) {
    const keys = Object.keys(
      BasisReportItemScheme.shape
    ) as BasisReportItemKey[];

    keys.forEach((key) => {
      form[key] = reportItem[key];
    });

    form.configs = reportItem.configs;

    if (form.configs.length === 0) {
      form.configs = [generateDefaultConfig()];
    }
  } else {
    form.id = undefined;
    form.name = undefined;
    form.groupId = undefined;
    form.order = 0;
    form.visible = true;
    form.unitRange = 1;
    form.unit = "件";
    form.configs = [generateDefaultConfig()];
  }
}

function addConfig() {
  if (!form.configs) {
    form.configs = [];
  }

  form.configs.push(generateDefaultConfig());
}

const addDisabled = computed(() => {
  if (!form.configs) {
    return false;
  }

  return form.configs.some((config) => {
    const { workCategories, workCarriers, requestOrganizationIds } = config;

    if (!workCategories || !workCarriers || !requestOrganizationIds) {
      return true;
    }

    return (
      workCategories.length === 0 &&
      workCarriers.length === 0 &&
      requestOrganizationIds.length === 0
    );
  });
});

function removeConfig(idx: number) {
  form.configs.splice(idx, 1);
}

const selectedReportItem = ref<BasisReportItemClient | undefined>();

function openModal(id?: number) {
  selectedReportItem.value = undefined;

  if (id) {
    selectedReportItem.value = props.basisReportItems.find(
      (item) => item.id === id
    );
  }

  resetForm(selectedReportItem.value);

  loading.value = false;
  modal.value.openModal();
}

function closeModal() {
  selectedReportItem.value = undefined;
  resetForm();
  modal.value.closeModal();
}

function onDestroy() {
  if (!selectedReportItem.value) {
    alert("選択されていません");
    return;
  }

  emit("destroy", selectedReportItem.value.id);
  closeModal();
}

defineExpose({
  openModal,
  closeModal,
});

function createGroup() {
  const groupName = prompt("グループ名を入力してください");

  if (!groupName) {
    return;
  }

  emit("createGroup", groupName);
}

function destroyGroup() {
  if (!form.groupId) {
    return;
  }

  const targetGroup = props.basisReportItemGroups.find(
    (group) => group.id === form.groupId
  );

  if (!window.confirm(`${targetGroup?.name}を削除しますか？`)) {
    return;
  }

  emit("destroyGroup", form.groupId);
}
</script>

<style scoped lang="scss">
.form-wrapper {
  position: relative;

  .delete-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
}
</style>
