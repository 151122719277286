<template>
  <tr>
    <td v-if="ownerMode" class="sticky"></td>
    <td></td>
    <td v-if="showEditMode || showRequestOrganization">勤務日数</td>
    <td>{{ workedDayCount }}</td>
    <td
      v-if="
        showEditMode ||
        (selectedFilter === 'requestOrganization' && !hideMapBase.workCategory)
      "
    >
      稼働
    </td>
    <td
      v-if="
        showEditMode ||
        (selectedFilter === 'requestOrganization' && !hideMapBase.workerNumber)
      "
    >
      {{ laneCount }}
    </td>
    <td v-if="showEditMode || !hideMapBase.workerNames">合計</td>
    <ReportItemsTds
      :category-carrier-keys="categoryCarrierKeys"
      :customers-items="customersItems"
      :indivi-items="indiviItems"
      :overall-items="overallItems"
      :work-reports="workReports"
      :tasks="contractedTasks"
      :hide-map="hideMap"
      :show-edit-mode="showEditMode"
    />
  </tr>
</template>

<script setup lang="ts">
import _ from "lodash";
import { isSameDate } from "/@/modules/luxon";
import {
  CategoryCarrierKey,
  CategoryCarrierReportItemsMap,
  WorkTaskClient,
  WorkReport,
  HideMap,
  HideMapBase,
  FilterKey,
  WorkReportClient,
} from "/@/types";

import ReportItemsTds from "./ReportItemsTds.vue";
import { computed } from "vue";

const props = defineProps<{
  ownerMode: boolean;
  contractedTasks: WorkTaskClient[];
  workReports: WorkReportClient[];
  categoryCarrierKeys: CategoryCarrierKey[];
  customersItems: CategoryCarrierReportItemsMap;
  indiviItems: CategoryCarrierReportItemsMap;
  overallItems: CategoryCarrierReportItemsMap;
  hideMap: HideMap;
  hideMapBase: HideMapBase;
  showEditMode?: boolean;
  selectedFilter: FilterKey;
  showRequestOrganization?: boolean;
}>();

const countTargetReports = computed(() => {
  return props.workReports.filter((report) => {
    return report.reportType === "individual";
  });
});

const workedDayCount = computed(() => {
  const uniqued = _.uniqWith(
    countTargetReports.value.map((report) => report.targetDate),
    isSameDate
  );

  return uniqued.length;
});

const laneCount = computed(() => {
  const uniqued = _.uniqWith(
    countTargetReports.value,
    (a: WorkReport, b: WorkReport) =>
      isSameDate(a.targetDate, b.targetDate) &&
      a.organizationMemberId === b.organizationMemberId
  );

  return uniqued.length;
});
</script>

<style scoped></style>
