import { axios } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";

export function useOrganizationOwnerGroup() {
  async function createGroup(prms: { id: number; name: string }) {
    const { id, name } = prms;

    try {
      await axios.post(
        `/api/v1/organizations/${id}/organization_owner_groups`,
        {
          organization_owner_groups: {
            name,
          },
        }
      );

      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    createGroup,
  };
}
