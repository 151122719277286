import zod from "zod";
import { DateTimeScheme, z } from "/@/modules/zodUtils";
import { PrefectureScheme } from "/@/types/address.d";
import { WorkCategoriesScheme } from "/@/types/workCategories.d";
import { CarriersScheme } from "/@/types/carriers.d";
import { WorkScheme } from "/@/types/work.d";
import { WorkScheduleScheme } from "/@/types/workSchedule.d";
import { WorkPlaceScheme } from "/@/types/workPlace.d";
import { RestTimeScheme } from "/@/types/restTime.d";
import { WorkRecordClient } from "/@/types/workRecord.d";
import { OrganizationCategoryScheme } from "/@/types/category.d";
import { OrganizationEventInvitationClientScheme } from "/@/types/organizationEvent.d";

export const applicationTypes = ["paid", "holiday", "halfday"] as const;

export const ApplicationTypeScheme = z.enum(applicationTypes);
export type ApplicationType = zod.infer<typeof ApplicationTypeScheme>;

const AbsenceApplicationPlanScheme = z.object({
  id: z.number(),
  targetDate: z.string().datetime({ offset: true }),
  applicationType: ApplicationTypeScheme,
  accept: z.boolean(),
  organizationMember: z.object({
    id: z.number(),
    name: z.string(),
  }),
  createdAt: z.string().datetime({ offset: true }).optional(),
});

export type AbsenceApplicationPlan = zod.infer<
  typeof AbsenceApplicationPlanScheme
>;

export const WorkAsPlanScheme = z.object({
  id: z.number(),
  name: z.string().optional(),
  organizationId: z.number(),
  requestOrganizationId: z.number().optional(),
  requestOrganizationName: z.string().optional(),
  workCategory: WorkCategoriesScheme,
  carrier: CarriersScheme,
  prefectures: PrefectureScheme.array(),
  startOn: z.string().datetime({ offset: true }),
  finishOn: z.string().datetime({ offset: true }),
  workOrganizations: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .array(),
  workPlaces: z
    .object({
      id: z.number(),
      name: z.string(),
      shortName: z.string().optional(),
    })
    .array(),
  workSchedules: WorkScheduleScheme,
});

export const WorkAsPlansLightScheme = WorkAsPlanScheme.omit({
  workSchedules: true,
}).extend({
  workScheduleIds: z.number().array(),
});

export type WorkAsPlansLight = zod.infer<typeof WorkAsPlansLightScheme>;

export type WorkAsPlan = zod.infer<typeof WorkAsPlanScheme>;

// WorkScheduleAsPlan

export const AssignedMemberScheme = z.object({
  organizationId: z.number(),
  organizationName: z.string(),
  organizationMemberId: z.number(),
  organizationMemberName: z.string(),
  workScheduleAssigmentId: z.number(),
  schedulePlanId: z.number(),
  absence: z.boolean(),
});

export type AssignedMember = zod.infer<typeof AssignedMemberScheme>;

export const AssignedOrganizationScheme = z.object({
  organizationId: z.number(),
  organizationName: z.string(),
  workScheduleAssigmentId: z.number(),
  schedulePlanId: z.number(),
  assignedNeedCount: z.number(),
});

export type AssignedOrganization = zod.infer<typeof AssignedOrganizationScheme>;

export const WorkScheduleAsPlanScheme = WorkScheduleScheme.pick({
  targetDate: true,
  startAt: true,
  finishAt: true,
  needCount: true,
  vacancyCount: true,
  eventPlaceName: true,
  eventPlaceShortName: true,
  eventPlaceGmapUrl: true,
  workPlaceName: true,
  workPlaceShortName: true,
  currentCount: true,
  enoughCount: true,
  workCarrier: true,
  canceled: true,
}).extend({
  id: z.number(),
  work: WorkScheme.pick({
    workCategory: true,
    carrier: true,
    prefectures: true,
  }).extend({
    id: z.number(),
  }),
  workPlace: WorkPlaceScheme.pick({
    id: true,
    name: true,
    shortName: true,
    organizationName: true,
  }),
  workTypeId: z.number(),
  workTypeName: z.string(),
  assignedMembers: AssignedMemberScheme.array(),
  assignedOrganizations: AssignedOrganizationScheme.array(),
  workingHours: z.number(),
  workCarrier: CarriersScheme,
});

export type WorkScheduleAsPlan = zod.infer<typeof WorkScheduleAsPlanScheme>;

// OrganizationPlan

export const OrganizationScheduleScheme = z.object({
  id: z.number().optional(),
  organizationId: z.number(),
  targetDate: DateTimeScheme,
  workScheduleIds: z.number().array(),
  restTimes: RestTimeScheme.array(),
});

export type OrganizationSchedule = zod.infer<typeof OrganizationScheduleScheme>;

// MemberPlan

export const OrganizationMemberScheduleScheme = z.object({
  id: z.number().optional(),
  organizationMemberId: z.number(),
  targetDate: DateTimeScheme,
  absence: z.boolean(),
  workScheduleIds: z.number().array(),
  restTimes: RestTimeScheme.array(),
});

export type OrganizationMemberSchedule = zod.infer<
  typeof OrganizationMemberScheduleScheme
>;

export const OrganizationMemberScheduleFormScheme =
  OrganizationMemberScheduleScheme.partial();

export type OrganizationMemberScheduleForm = zod.infer<
  typeof OrganizationMemberScheduleFormScheme
>;

export const ScheduleInPlanScheme = WorkScheduleScheme.pick({
  targetDate: true,
  eventPlaceId: true,
  eventPlaceName: true,
  eventPlaceShortName: true,
  eventPlaceGmapUrl: true,
  eventPlaceAttachedImages: true,
  meetingPlace: true,
  firstMeetingAt: true,
  meetingAt: true,
  startAt: true,
  finishAt: true,
  needCount: true,
  currentCount: true,
  enoughCount: true,
  meetingTargets: true,
  meetingTargetFilter: true,
  canceled: true,
}).extend({
  id: z.number(),
  workScheduleAssignmentId: z.number(),
  done: z.boolean().optional(),
  absence: z.boolean().optional(),
  vacancy: z.boolean().optional(),
  needMeetingTargets: z.boolean(),
  workId: z.number(),
  workCategory: WorkCategoriesScheme,
  workCarrier: CarriersScheme,
  workPrefectures: PrefectureScheme.array().optional(),
  workTypeName: z.string(),
  workTypeCategories: OrganizationCategoryScheme.array(),
  workPlaceName: z.string(),
  workPlaceShortName: z.string(),
  workPlacePrefecture: PrefectureScheme,
  workPlaceOrganizationName: z.string(),
  workPlaceOrganizationId: z.number(),
});

export const ScheduleInOrganizationScheme = ScheduleInPlanScheme.extend({
  organizationName: z.string(),
});

export type ScheduleInOrganization = zod.infer<
  typeof ScheduleInOrganizationScheme
>;

export const ScheduleInOrganizationMemberScheme = ScheduleInPlanScheme.extend({
  organizationMemberName: z.string(),
});

export type ScheduleInOrganizationMember = zod.infer<
  typeof ScheduleInOrganizationMemberScheme
>;

export const OrganizationMemberSchedulePlanScheme =
  OrganizationMemberScheduleScheme.pick({
    organizationMemberId: true,
    targetDate: true,
    absence: true,
  }).extend({
    id: z.number(),
    organizationMemberName: z.string(),
    memberOrganizationId: z.number(),
    workSchedules: ScheduleInOrganizationMemberScheme.array(),
    restTimeSchedules: RestTimeScheme.array(),
    haveOwneredAssignments: z.boolean(),
    haveOwneredWorkSchedules: z.boolean(),
  });

export type OrganizationMemberSchedulePlan = zod.infer<
  typeof OrganizationMemberSchedulePlanScheme
>;

export const OrganizationMemberSchedulePlanLightScheme =
  OrganizationMemberSchedulePlanScheme.omit({
    workSchedules: true,
  }).extend({
    workScheduleIds: z.number().array(),
  });

export type OrganizationMemberSchedulePlanLight = zod.infer<
  typeof OrganizationMemberSchedulePlanLightScheme
>;

export const OrganizationSchedulePlanScheme = OrganizationScheduleScheme.pick({
  organizationId: true,
  targetDate: true,
}).extend({
  id: z.number(),
  organizationName: z.string(),
  workSchedules: WorkScheduleScheme.pick({
    id: true,
  }),
  restTimeSchedules: RestTimeScheme.array(),
});

export type OrganizationSchedulePlan = zod.infer<
  typeof OrganizationSchedulePlanScheme
>;

export const OrganizationSchedulePlanLightScheme =
  OrganizationSchedulePlanScheme.omit({
    workSchedules: true,
  }).extend({
    workScheduleIds: z.number().array(),
  });

export type OrganizationSchedulePlanLight = zod.infer<
  typeof OrganizationSchedulePlanLightScheme
>;

// Plan

export type Plan =
  | AbsenceApplicationPlan
  | WorkAsPlan
  | WorkScheduleAsPlan
  | OrganizationMemberSchedulePlan
  | OrganizationMemberSchedule
  | OrganizationSchedulePlan;

export const PlanMapScheme = z.object({
  absenceApplicationPlans: AbsenceApplicationPlanScheme.array(),
  workAsPlans: WorkAsPlanScheme.array(),
  workScheduleAsPlans: WorkScheduleAsPlanScheme.array(),
  organizationMemberSchedulePlans: OrganizationMemberSchedulePlanScheme.array(),
  organizationSchedulePlans: OrganizationSchedulePlanScheme.array(),
  assignOrganizationSchedulePlans: OrganizationSchedulePlanScheme.array(),
  organizationEventInvitations: OrganizationEventInvitationClientScheme.array(),
});

export type PlanMap = zod.infer<typeof PlanMapScheme>;

// responses

export const AssignScheduleResponseScheme = z.object({
  workSchedules: WorkScheduleAsPlanScheme.array(),
  organizationMemberSchedulePlan: OrganizationMemberSchedulePlanScheme,
  organizationSchedulePlan: OrganizationSchedulePlanScheme,
});

export type AssignScheduleResponse = zod.infer<
  typeof AssignScheduleResponseScheme
>;

// for calendar

export type MemberSchedulePlanMap = Partial<
  Record<number, Record<string, OrganizationMemberSchedulePlan[]>>
>;

export type MemberAbsencePlanMap = Partial<
  Record<number, Record<string, AbsenceApplicationPlan[]>>
>;

export type MemberWorkRecordMap = Partial<
  Record<number, Record<string, WorkRecordClient[]>>
>;
