import { WorkCategory } from "/@/types/workCategories.d";

export function categoryCompareFn(a: WorkCategory, b: WorkCategory) {
  /*
  if (a === "normal") {
    return -1;
  }

  if (b === "normal") {
    return 1;
  }
  */

  if (a === "shop_helper") {
    return -1;
  }

  if (b === "shop_helper") {
    return 1;
  }

  if (a === "event_helper") {
    return -1;
  }

  if (b === "event_helper") {
    return 1;
  }

  if (a === "fulltime") {
    return -1;
  }

  if (b === "fulltime") {
    return 1;
  }

  if (a === "rounder") {
    return -1;
  }

  if (b === "rounder") {
    return 1;
  }

  if (a === "consulting") {
    return -1;
  }

  if (b === "consulting") {
    return 1;
  }

  if (a === "light_ad") {
    return -1;
  }

  if (b === "light_ad") {
    return 1;
  }

  if (a === "sv") {
    return -1;
  }

  if (b === "sv") {
    return 1;
  }

  if (a === "sajit") {
    return -1;
  }

  if (b === "sajit") {
    return 1;
  }

  return 0;
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  it("categoryCompareFn", () => {
    expect(categoryCompareFn("shop_helper", "event_helper")).toStrictEqual(-1);
    expect(
      ["event_helper", "shop_helper"].sort(categoryCompareFn)
    ).toStrictEqual(["shop_helper", "event_helper"]);

    expect(
      ["light_ad", "consulting", "shop_helper", "event_helper"].sort(
        categoryCompareFn
      )
    ).toStrictEqual(["shop_helper", "event_helper", "consulting", "light_ad"]);
  });
}
