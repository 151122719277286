import useSWRV from "swrv";
import { ComputedRef, Ref, ref } from "vue";
import { createDateParams, createQueryParams } from "/@/modules/url";
import { axios, fetcher } from "/@/modules/axios";
import type {
  Carrier,
  DateMap,
  Organization,
  OrganizationCategory,
  Prefecture,
  WorkPlaceClient,
  WorkPlaceUpdate,
} from "/@/types";
import { errorHandle } from "/@/modules/error";

export function useWorkPlaces() {
  const selectedCategories = ref<OrganizationCategory[]>([]);
  const selectedCarrier = ref<Carrier | undefined>(undefined);
  const selectedPrefectures = ref<Prefecture[]>([]);

  function searchWorkPlaces(
    categories: OrganizationCategory[],
    carrier: Carrier,
    prefectures: Prefecture[]
  ) {
    selectedCategories.value = categories;
    selectedCarrier.value = carrier;
    selectedPrefectures.value = prefectures;
  }

  function getWorkPlacesKey(
    organization?:
      | Ref<Organization | undefined>
      | ComputedRef<Organization | undefined>,
    selectedDate?: Ref<DateMap | undefined>,
    isMeetingTarget?: Ref<boolean>
  ) {
    if (
      !selectedCarrier.value ||
      selectedPrefectures.value.length == 0 ||
      (!organization?.value && isMeetingTarget?.value)
    ) {
      return null;
    }

    let uri = `/api/v1/work_places?`;

    uri += `${createQueryParams({
      categories: selectedCategories.value,
      carrier: selectedCarrier.value,
      prefectures: selectedPrefectures.value,
      is_meeting_target: isMeetingTarget?.value,
    })}`;

    if (selectedDate?.value) {
      const dateParams = createDateParams(selectedDate.value);
      uri += `?${dateParams}`;
    }

    uri += organization?.value
      ? `&organization_id=${organization.value.id}`
      : "";

    return uri;
  }

  function getWorkPlaces(
    organization?:
      | Ref<Organization | undefined>
      | ComputedRef<Organization | undefined>,
    selectedDate?: Ref<DateMap | undefined>,
    isMeetingTarget?: Ref<boolean>
  ) {
    return useSWRV<WorkPlaceClient[]>(
      () => getWorkPlacesKey(organization, selectedDate, isMeetingTarget),
      fetcher
    );
  }

  async function updateWorkPlace(prms: WorkPlaceUpdate) {
    try {
      await axios.patch(`/api/v1/work_places/${prms.id}`, {
        workPlaces: prms,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    selectedCategories,
    selectedCarrier,
    selectedPrefectures,
    searchWorkPlaces,
    getWorkPlaces,

    updateWorkPlace,
  };
}
