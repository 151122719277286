<template>
  <div>
    <BasicCalendar
      v-if="selectedCalendarType === 'calendar'"
      :selected-date="selectedDate"
      :events="events"
      :exists-dates="existsDates"
      :jp-holidays="jpHolidays"
      class="pb-5"
      @event-click="$emit('openShowPlanModal', $event)"
      @update:selected-date="$emit('update:selectedDate', $event)"
    />
    <UserRowCalendar
      v-if="isOwner"
      v-show="selectedCalendarType === 'user' && selectedOrganization"
      :organization="selectedOrganization"
      :members="organizationMembers || []"
      :date-map="selectedDate"
      :schedule-plans="organizationMemberSchedulePlans"
      :jp-holidays="jpHolidays"
      :absence-application-plans="absenceApplicationPlans"
      :work-records="workRecords"
      :highlight-canceled="highlightCanceled"
      class="pb-5"
      @select-schedule-as-plan="$emit('selectMemberScheduleAsPlan', $event)"
    />
    <OrganizationRowCalendar
      v-if="(isOwner && canShowMemberWorkScheduleCalendar) || isFrythOwner"
      v-show="selectedCalendarType === 'organization'"
      :date-map="selectedDate"
      :work-schedules="workScheduleAsPlans"
      :work-as-plans="workAsPlans"
      :selected-member="selectedOrganizationMember"
      :highlight-non-enough="highlightNonEnough"
      :highlight-canceled="highlightCanceled"
      :jp-holidays="jpHolidays"
      class="pb-5"
      @select-work-schedule="$emit('selectWorkSchedule', $event)"
      @select-schedule-as-plan="$emit('selectScheduleAsPlan', $event)"
      @select-empty="selectEmpty"
    />
  </div>
</template>

<script setup lang="ts">
import { useCalendar, useWorks } from "/@/vue/composables";
import { BasicCalendar } from "/@/vue/components/Molecules";
import {
  UserRowCalendar,
  OrganizationRowCalendar,
} from "/@/vue/components/Organisms";
import {
  CalendarEventArgs,
  DateMap,
  ToggleType,
  Organization,
  OrganizationMember,
  OrganizationMemberSchedulePlan,
  WorkScheduleAsPlan,
  WorkAsPlan,
  AbsenceApplicationPlan,
  WorkRecordClient,
} from "/@/types";

const props = defineProps<{
  selectedOrganization: Organization;
  selectedOrganizationMember?: OrganizationMember;
  selectedCalendarType: ToggleType;
  selectedDate: DateMap;
  existsDates: string[];
  events: CalendarEventArgs[];
  organizationMembers: OrganizationMember[];
  organizationMemberSchedulePlans: OrganizationMemberSchedulePlan[];
  workAsPlans: WorkAsPlan[];
  workScheduleAsPlans: WorkScheduleAsPlan[];
  workRecords?: WorkRecordClient[];
  absenceApplicationPlans: AbsenceApplicationPlan[];
  highlightNonEnough?: boolean;
  highlightCanceled?: boolean;
  isOwner?: boolean;
  isFrythOwner?: boolean;
  canShowMemberWorkScheduleCalendar?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:selectedDate", datem: DateMap): void;
  (e: "openShowPlanModal", event: CalendarEventArgs): void;
  (
    e: "selectMemberScheduleAsPlan",
    targetSchedule: OrganizationMemberSchedulePlan
  ): void;
  (e: "selectWorkSchedule", prms: { id: number }): void;
  (e: "selectScheduleAsPlan", targetSchedule: WorkScheduleAsPlan): void;
  (e: "getPlanMapMutate"): void;
}>();

// holiday
const { getJpHolidays } = useCalendar();
const { data: jpHolidays } = getJpHolidays();

// actions

const { cloneSchedule } = useWorks();

async function selectEmpty(prms: {
  workId: number;
  workTypeId: number;
  targetDate: string;
}) {
  if (!props.isFrythOwner) {
    return;
  }

  if (window.confirm(`左隣のスケジュールをコピーしますか？`)) {
    if (await cloneSchedule(prms)) {
      emit("getPlanMapMutate");
    } else {
      alert("コピーの作成に失敗しました");
    }
  }
}
</script>

<style scoped></style>
