<template>
  <div>
    <div class="mb-3 me-2">
      <FormItem
        :value="startOn"
        label="開始日"
        :form-id="`${formId ? formId : ''}startOn`"
        form-type="date"
        :errors="errors"
        @update:value="$emit('update:startOn', $event)"
      />
    </div>

    <div class="mb-3">
      <FormItem
        :value="finishOn"
        label="終了日"
        :form-id="`${formId ? formId : ''}finishOn`"
        form-type="date"
        :min-date="minDateOfFinish"
        :errors="errors"
        @update:value="$emit('update:finishOn', $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { DateTime } from "luxon";
import { computed, watch } from "vue";
import { fromISO } from "/@/modules/luxon";
import { FormItem } from "/@/vue/components/Molecules";
import type { ZodFormattedErrors } from "/@/modules/zodUtils";

const props = defineProps<{
  startOn?: string;
  finishOn?: string;
  formId?: string;
  errors: ZodFormattedErrors;
}>();

const emit = defineEmits<{
  (e: "update:startOn", value: string | undefined): void;
  (e: "update:finishOn", value: string | undefined): void;
}>();

watch(
  () => props.startOn,
  (nStartOn) => {
    if (!nStartOn || !props.finishOn) {
      return;
    }

    const luxonStartOn = fromISO(nStartOn);
    const luxonFinishOn = fromISO(props.finishOn);

    if (!luxonStartOn || !luxonFinishOn) {
      return;
    }

    if (luxonStartOn >= luxonFinishOn) {
      emit("update:finishOn", undefined);
    }
  }
);

const minDateOfFinish = computed<DateTime | undefined>(() => {
  if (!props.startOn) {
    return undefined;
  }

  const minDate = fromISO(props.startOn);
  if (!minDate) {
    return undefined;
  }

  return minDate.startOf("day").plus({ day: 1 });
});
</script>

<style scoped></style>
