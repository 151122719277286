import useSWRV from "swrv";
import { axios, fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import {
  BasisReportItem,
  BasisReportItemGroupClient,
  Organization,
} from "/@/types";
import { Ref } from "vue";

export function useBasisReportItems() {
  function getBasisReportItems() {
    return useSWRV("/api/v1/basis_report_items", fetcher);
  }

  function getBasisReportItemGroupsKey(
    organization?: Ref<Organization | undefined>
  ) {
    if (!organization || !organization.value) {
      return null;
    }

    return `/api/v1/basis_report_item_groups?organization_id=${organization.value.id}`;
  }

  function getBasisReportItemGroups(
    organization?: Ref<Organization | undefined>
  ) {
    return useSWRV<BasisReportItemGroupClient[]>(
      () => getBasisReportItemGroupsKey(organization),
      fetcher
    );
  }

  async function createBasisReportItemGroup(name: string) {
    try {
      await axios.post(`/api/v1/basis_report_item_groups/`, {
        basisReportItemGroups: { name },
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function destroyBasisReportItemGroup(id: number) {
    try {
      await axios.delete(`/api/v1/basis_report_item_groups/${id}`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function createBasisReportItem(prms: BasisReportItem) {
    try {
      await axios.post(`/api/v1/basis_report_items/`, {
        basisReportItems: prms,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function updateBasisReportItem(
    prms: BasisReportItem | { id: number; order: number }
  ) {
    // TODO: form上で validTo や validFrom に undefined を入れると、nil での更新ではなく更新がかからないので、
    // それらの値を undefined にする処理を入れる。
    // 根本的な解決をしたい

    const form = { ...prms };

    if (!form.validFrom) {
      form.validFrom = null;
    }

    if (!form.validTo) {
      form.validTo = null;
    }

    form.configs.forEach((config) => {
      if (!config.validFrom) {
        config.validFrom = null;
      }

      if (!config.validTo) {
        config.validTo = null;
      }
    });

    try {
      await axios.patch(`/api/v1/basis_report_items/${form.id}`, {
        basisReportItems: form,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function destroyBasisReportItem(id: number) {
    try {
      await axios.delete(`/api/v1/basis_report_items/${id}`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function changeOrderItem(id: number, order: number) {
    try {
      await axios.patch(`/api/v1/basis_report_items/${id}/change_order`, {
        basisReportItems: { order },
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    getBasisReportItems,
    createBasisReportItem,
    updateBasisReportItem,
    destroyBasisReportItem,

    getBasisReportItemGroups,
    createBasisReportItemGroup,
    destroyBasisReportItemGroup,
    changeOrderItem,
  };
}
