<template>
  <td class="align-top td-row-item" role="button" @click="selectEmpty">
    <div class="wrapper">
      <TooltipWrapper
        v-for="(map, idx) in titleMaps"
        :key="`organization-row-calendar-item-${map.id}`"
        class="d-flex flex-column align-items-center"
        :class="{
          'enough-count': highlightNonEnough && map.enoughCount,
          vacancy: map.vacancyCount > 0,
          canceled: map.canceled,
          'highlight-canceled': highlightCanceled && map.canceled,
          'mb-1': idx != titleMaps.length - 1,
        }"
        :tooltip-title="(tooltipTitle[idx] as string)"
        :content-id="`organization-row-calendar-item-${targetDate}-${idx}`"
        @click="selectId($event, map.id, 'click')"
        @dblclick="selectId($event, map.id, 'dblclick')"
      >
        <div class="d-flex flex-column w-100">
          <span>{{ map.workPlaceShortName }}</span>
          <span>{{ map.eventPlaceShortName }}</span>
          <template v-if="!map.enoughCount">
            <template
              v-for="o in map.organizations"
              :key="`organization-${o.organizationId}`"
            >
              <mark v-if="o.organizationId === highlightMemberId">
                {{ o.organizationName }}
              </mark>
              <span v-else>{{ o.organizationName }}</span>
            </template>
          </template>

          <template
            v-for="member in map.organizationMembers"
            :key="`member-${member.organizationMemberId}`"
          >
            <div
              class="d-flex align-items-center justify-content-center"
              :class="{
                absence: !map.canceled && member.absence,
                vacancy: !map.canceled && member.vacancy,
              }"
            >
              <i
                v-if="!map.canceled && member.absence"
                class="text-danger pi pi-times"
                style="font-size: 0.9rem"
              ></i>
              <mark
                v-if="member.organizationMemberId === highlightMemberId"
                class="w-100"
              >
                {{ member.organizationMemberName }}
              </mark>
              <span v-else>{{ member.organizationMemberName }}</span>
            </div>
          </template>

          <span>{{ map.countStatus }}</span>
        </div>
        <div v-if="idx != titleMaps.length - 1" class="divider mt-1"></div>
      </TooltipWrapper>
    </div>
  </td>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { workScheduleToTitleMap } from "/@/modules/workSchedule";
import { getTargetDateWorkSchedules } from "/@/modules/plan";
import { TooltipWrapper } from "/@/vue/components/Molecules";
import type { WorkScheduleAsPlan } from "/@/types";

const props = defineProps<{
  workSchedules: WorkScheduleAsPlan[];
  workId: number;
  workType: { id: number; name: string };
  targetDate: string;
  short?: boolean;
  highlightMemberId?: number;
  highlightNonEnough?: boolean;
  highlightCanceled?: boolean;
  isMobile: boolean;
}>();

const emit = defineEmits<{
  (e: "selectWorkSchedule", params: { id: number }): void;
  (e: "selectScheduleAsPlan", targetSchedule: WorkScheduleAsPlan): void;
  (
    e: "selectEmpty",
    prms: { workId: number; workTypeId: number; targetDate: string }
  ): void;
}>();

const targetSchedules = computed(() => {
  return getTargetDateWorkSchedules(
    props.workSchedules,
    props.workId,
    props.targetDate
  );
});

function schedulesToCalendarTitle(schedules: WorkScheduleAsPlan[]) {
  const maps = schedules.map((schedule) => {
    return workScheduleToTitleMap(schedule);
  });

  return maps;
}

const titleMaps = computed(() => {
  return schedulesToCalendarTitle(targetSchedules.value);
});

function assignedNames(memberNames: string[], organizationNames: string[]) {
  const names = [...memberNames, ...organizationNames];

  return names.length ? names : ["割り当て無し"];
}

const tooltipTitle = computed(() => {
  let m: Partial<Record<number, string>> = {};

  titleMaps.value.forEach((tmap, idx) => {
    const infos = [
      !tmap.canceled && tmap.absence && "欠勤",
      tmap.canceled && "中止",
      tmap.vacancyCount > 0 && `欠員${tmap.vacancyCount}`,
      tmap.workPlaceName,
      tmap.eventPlaceName,
    ].filter((b) => b);
    const memberNames = tmap.organizationMembers.map(
      (member) =>
        member.organizationMemberName +
        `(${member.organizationName})` +
        `${!tmap.canceled && member.absence ? "（欠勤）" : ""}`
    );
    const organizationNames = tmap.organizations.map((o) => o.organizationName);

    m[idx] = [
      ...infos,
      ...assignedNames(memberNames, organizationNames).filter((b) => b),
    ]
      .map((s) => `<span>${s}</span>`)
      .join("<br>");
  });

  return m;
});

// event

function selectEmpty(e: Event) {
  e.stopPropagation();

  if (targetSchedules.value.length) {
    return;
  }

  emit("selectEmpty", {
    workId: props.workId,
    workTypeId: props.workType.id,
    targetDate: props.targetDate,
  });
}

function selectId(e: Event, id: number, clickType: "click" | "dblclick") {
  if (props.isMobile && clickType === "click") {
    return;
  }

  e.stopPropagation();

  const targetSchedule = targetSchedules.value.find(
    (schedule) => schedule.id === id
  );

  if (!targetSchedule) {
    return;
  }

  if (
    targetSchedule.assignedMembers.length ||
    targetSchedule.assignedOrganizations.length
  ) {
    emit("selectScheduleAsPlan", targetSchedule);
  } else {
    emit("selectWorkSchedule", { id: targetSchedule.id });
  }
}
</script>

<style scoped>
span {
  white-space: pre-wrap;
}

.enough-count {
  background-color: var(--gray-700);
}

.vacancy {
  background-color: var(--green-200);
}

.absence {
  background-color: var(--red-200);
}

.canceled {
  text-decoration: line-through;
}

.highlight-canceled {
  background-color: var(--blue-200);
}

.td-row-item {
  max-width: 45px;
  min-width: 45px;
}
</style>
