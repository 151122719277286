import useSWRV from "swrv";
import { axios, fetcher } from "/@/modules/axios";
import type { ComputedRef, Ref } from "vue";
import type { LostReason, Organization, WorkTaskClient } from "/@/types";
import { errorHandle } from "/@/modules/error";

export function useWorkTasks() {
  function getWorkTasksKey(
    organization:
      | Ref<Organization | undefined>
      | ComputedRef<Organization | undefined>
  ) {
    if (!organization.value || !organization.value.organizationMemberId) {
      return null;
    }

    return `/api/v1/organization_members/${organization.value.organizationMemberId}/work_tasks`;
  }

  function getWorkTasks(organization: Ref<Organization | undefined>) {
    return useSWRV<WorkTaskClient[]>(
      () => getWorkTasksKey(organization),
      fetcher
    );
  }

  // api

  async function editWorkTask(workTask: WorkTaskClient) {
    try {
      await axios.patch(`/api/v1/work_tasks/${workTask.id}`, {
        work_tasks: workTask,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function destroyWorkTask(id: number) {
    try {
      await axios.delete(`/api/v1/work_tasks/${id}`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function toggleContractWorkTask(prms: {
    id: number;
    countInContractedDate?: boolean;
  }) {
    const { id, countInContractedDate } = prms;

    try {
      await axios.post(`/api/v1/work_tasks/${id}/toggle_contract`, {
        workTasks: {
          countInContractedDate: !!countInContractedDate,
        },
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function toggleLostWorkTask(prms: {
    id: number;
    lostReason: LostReason;
    lostReasonDetails: string;
  }) {
    const { id, lostReason, lostReasonDetails } = prms;

    try {
      await axios.post(`/api/v1/work_tasks/${id}/toggle_lost`, {
        workTasks: {
          id: id,
          lostReason: lostReason,
          lostReasonDetails: lostReasonDetails,
        },
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    getWorkTasks,
    editWorkTask,
    destroyWorkTask,
    toggleContractWorkTask,
    toggleLostWorkTask,
  };
}
