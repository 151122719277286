<template>
  <div>
    <div class="card">
      <CollapseWrapper
        :contents-id="`edit-request-card-${editRequest.id}`"
        card-header
      >
        <template #header>
          <FormItemShow label="依頼者" content-id="model-name" without-margin>
            {{ editRequest.organizationMemberName }}
            ({{ basicFormatter(editRequest.createdAt, "withTime") }})
          </FormItemShow>
        </template>
        <template #contents>
          <FormItemShow label="変更対象" content-id="model-name">
            <div>
              {{ basicFormatter(editRequest.jsonBeforeChange.created_at) }}の{{
                editRequestTranslater(editRequest.changeableType)
              }}
            </div>
            <div v-if="editRequest.changeableType === 'WorkRecordEntryReport'">
              {{ translater(editRequest.workCategory) }}
              {{ translater(editRequest.workCarrier) }}
              {{ editRequest.workPlaceName }}
              {{ editRequest.workTypeName }}
            </div>
          </FormItemShow>
          <FormItemShow
            label="依頼種別 (更新または削除)"
            content-id="request-type"
          >
            {{ editRequestTranslater(editRequest.operationType) }}
          </FormItemShow>

          <template
            v-if="editRequest.changeableType === 'WorkRecordEntryReport'"
          >
            <FormItemShow v-if="false" label="差分" content-id="diff">
              <div
                v-for="(diff, idx) in editRequest.changeDiff"
                :key="`change-diff-${idx}`"
              >
                <span>{{ diffToStr(diff) }}</span>
              </div>
            </FormItemShow>

            <FormItemShow label="変更前" content-id="before-change">
              <WorkReportsShowForm
                :work-reports="[editRequest.jsonBeforeChange]"
                :work-category="editRequest.workCategory"
                :work-carrier="editRequest.workCarrier"
                :work-type-categories="editRequest.workTypeCategories"
                :request-organization-id="editRequest.organizationId"
                class="card card-body"
              />
            </FormItemShow>
            <FormItemShow label="変更後" content-id="after-change">
              <WorkReportsShowForm
                :work-reports="[editRequest.jsonAfterChange]"
                :work-category="editRequest.workCategory"
                :work-carrier="editRequest.workCarrier"
                :work-type-categories="editRequest.workTypeCategories"
                class="card card-body"
              />
            </FormItemShow>
          </template>

          <FormItemShow label="状態" content-id="status">
            {{ editRequestTranslater(editRequest.requestStatus) }}
          </FormItemShow>
          <div v-if="isPending" class="d-flex flex-wrap w-100">
            <BasicButton
              variant="success"
              label="受理"
              icon="pi pi-check-circle"
              class="me-2"
              button-type="submit"
              @click="$emit('accept', editRequest.id)"
            />

            <BasicButton
              variant="danger"
              label="拒否"
              icon="pi pi-times-circle"
              button-type="submit"
              @click="$emit('reject', editRequest.id)"
            />
          </div>
        </template>
      </CollapseWrapper>
    </div>
  </div>
</template>

<script setup lang="ts">
import { translater } from "/@/modules/string";
import { editRequestTranslater } from "/@/modules/editRequest";
import { BasicButton } from "/@/vue/components/Atom";
import { FormItemShow, CollapseWrapper } from "/@/vue/components/Molecules";
import { WorkReportsShowForm } from "/@/vue/components/Organisms";
import { EditRequestClient } from "/@/types";
import { computed } from "vue";
import { basicFormatter } from "/@/modules/luxon";

/*
type EditRequestClient = {
  id: number;
  organizationMemberId: number;
  organizationMemberName: string;
  changeableType: "WorkRecordEntryReport";
  changeableId: number;
  requestStatus: "pending" | "accept" | "reject";
  operationType: "update" | "destroy";
  dataBeforeChange?: any;
  dataAfterChange?: any;
}
*/

const props = defineProps<{
  editRequest: EditRequestClient;
}>();

defineEmits<{
  (e: "accept", requestId: number): void;
  (e: "reject", requestId: number): void;
}>();

const isPending = computed(() => {
  return props.editRequest.requestStatus === "pending";
});

function diffToStr(diff: any) {
  const operation = diff["op"] === "add" ? "追加" : "変更";
  const val = diff["value"]?.["key"];
  const achivementType = diff["value"]?.["overall"]
    ? "全体実績"
    : diff["value"]?.["customers"]
    ? "応対記録"
    : "個人実績";
  const count = diff["value"]?.["count"];

  return `操作内容：${operation} ${achivementType} ${
    val ? translater(val) : ""
  } ${count}件`;
}
</script>

<style scoped></style>
