<template>
  <div
    :id="modalId"
    ref="modalEl"
    :class="`modal simple-modal fade`"
    tabindex="-1"
    role="dialog"
    aria-hidden="false"
  >
    <div
      role="document"
      :class="{
        ...{
          'modal-dialog': true,
          'modal-dialog-centered': true,
          'modal-dialog-scrollable': scrollable,
        },
        ...modalClass,
      }"
    >
      <div :class="`modal-content ${slim ? 'slim' : ''}`">
        <div
          v-if="!noHeader"
          class="modal-header d-flex justify-content-center"
          data-bs-dismiss="modal"
          @click="$emit('cancel')"
        >
          <p class="modal-title" :class="titleClass">{{ title }}</p>
        </div>
        <button
          class="btn close"
          data-dismiss="modal"
          aria-label="Close"
          type="button"
          @click="$emit('cancel')"
        >
          <i class="pi pi-times text-white"></i>
        </button>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div v-if="!noActions" class="modal-footer">
          <CancelButton
            v-if="!onlyOk"
            :label="cancelText"
            @click="$emit('cancel')"
          />
          <SubmitButton
            v-if="!onlyCancel"
            :disabled="submitDisabled"
            @click="$emit('submit')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { Modal } from "bootstrap";
import { v4 as uuidv4 } from "uuid";
import { CancelButton, SubmitButton } from "/@/vue/components/Molecules";

const props = withDefaults(
  defineProps<{
    title?: string;
    cancelText?: string;
    submitText?: string;
    submitDisabled?: boolean;
    submitTextVariant?: "danger" | "primary" | "";
    hint?: string;
    onlyOk?: boolean;
    onlyCancel?: boolean;
    noActions?: boolean;
    noHeader?: boolean;
    scrollable?: boolean;
    slim?: boolean;
    titleClass?: Object;
    modalClass?: Object;
    loading?: boolean;
  }>(),
  {
    title: "",
    cancelText: "キャンセル",
    submitText: "OK",
    submitTextVariant: "",
    hint: "",
    titleClass: () => ({}),
    modalClass: () => ({}),
  }
);

const emit = defineEmits<{
  (e: "close"): void;
  (e: "cancel"): void;
  (e: "submit"): void;
  (e: "hidden-bs-modal"): void;
}>();

const submitTextClass = computed(() => ({
  btn: true,
  "btn-submit": true,
  "text-danger": props.submitTextVariant == "danger",
  "text-primary": props.submitTextVariant == "primary",
}));

// modal

const modalEl = ref();
const modal = ref();
const modalId = "a" + uuidv4();

onMounted(() => {
  if (modalEl.value) {
    modal.value = new Modal(modalEl.value);
    modalEl.value.addEventListener("hidden.bs.modal", hiddenModal);
  }
});

function hiddenModal() {
  emit("hidden-bs-modal");
}

function openModal() {
  modal.value.show();
}

function closeModal() {
  modal.value.hide();
}

function submit() {
  if (!modal.value) {
    return;
  }

  emit("submit");
}

defineExpose({
  openModal,
  closeModal,
  submit,
});
</script>

<style lang="scss" scoped>
.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-content {
  position: relative;
  border-radius: 15px;
  color: $main-gray-color;
  max-width: 90%;

  .modal-header {
    border-bottom: none;
    .modal-title {
      font-size: 24px;
    }
  }

  .btn.close {
    position: absolute;
    right: -2.3rem;
    top: 0.8rem;
    background-color: $main-primary-color;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    border: 1px solid $main-gray-color;
    padding: 0.25rem 0.75rem 0.25rem 0.5rem;
    z-index: -10;
  }

  .modal-body {
    font-size: 15px;
    padding: 16px 26px;
  }

  .modal-footer {
    display: flex;
    justify-content: center;
    padding: 0.5rem;

    > * {
      width: 40%;
    }
  }
}
</style>
