import zod from "zod";
import { DateScheme, DateTimeScheme, z } from "/@/modules/zodUtils";

export const TaskItemClientScheme = z.object({
  projectId: z.number(),
  id: z.number(),
  parentId: z.number().optional(),
  name: z.string().optional(),
  startOn: DateScheme.optional(),
  finishOn: DateScheme.optional(),
  doneAt: DateTimeScheme.optional(),
  needTime: z.number().optional(),
  remarks: z.string().optional(),
  sortOrder: z.number(),
  orderPath: z.number().array(), // 祖先の order からなる path (2-1, 1-4-3, etc...)
  childrenIds: z.number().array(),
  descendantIds: z.number().array(),
  /*
  assignedMembers: z
    .object({
      organizationMemberId: z.number(),
      organizationMemberName: z.string(),
    })
    .array()
    .optional(),
  */
});

export const TaskItemAttributesScheme = TaskItemClientScheme.keyof();
export type TaskItemAttributes = zod.infer<typeof TaskItemAttributesScheme>;

export const UpdatableTaskItemAttributesScheme = zod.enum([
  "name",
  "startOn",
  "finishOn",
  "doneAt",
  "needTime",
  "remarks",
]);
export type UpdatableTaskItemAttributes = zod.infer<
  typeof UpdatableTaskItemAttributesScheme
>;

export type TaskItemUpdateParams = Partial<
  Record<UpdatableTaskItemAttributes, number | string | null | undefined>
>;

export type TaskItemClient = zod.infer<typeof TaskItemClientScheme>;

export const TaskItemFormScheme = TaskItemClientScheme.partial({
  projectId: true,
  name: true,
  startOn: true,
  finishOn: true,
  doneAt: true,
  needTime: true,
  remarks: true,
});
export type TaskItemForm = zod.infer<typeof TaskItemFormScheme>;

// nested

export type NestedTaskItemClient = TaskItemClient & {
  children: NestedTaskItemClient[];
};

export const NestedTaskItemClientScheme: z.ZodType<NestedTaskItemClient> =
  TaskItemClientScheme.extend({
    children: z.lazy(() => NestedTaskItemClientScheme.array()),
  });

// project

export const TaskProjectScheme = z.object({
  id: z.number(),
  organizationMemberId: z.number(),
  name: z.string(),
  lastPlannedDate: DateTimeScheme.optional(), // プロジェクトが持つタスクの計画日の中で最も期日が後のもの; AutoShift のため
  createdAt: DateTimeScheme.optional(),
});

export type TaskProject = zod.infer<typeof TaskProjectScheme>;

export const TaskProjectForm = TaskProjectScheme.partial();

export type TaskProjectClient = TaskProject & {
  hierarchalTaskItems: NestedTaskItemClient[];
  flatTaskItems: NestedTaskItemClient[];
};
