import zod from "zod";
import { DateScheme, DateTimeScheme, z } from "/@/modules/zodUtils";
import { RestTimeScheme } from "/@/types/restTime.d";
import { EventPlaceAttachedImageScheme } from "/@/types/eventPlace.d";
import { MeetingTargetWorkPlaceClientScheme } from "/@/types/meetingTargets.d";
import { WorkCategoriesScheme } from "/@/types/workCategories.d";
import { ReportItemFilterScheme } from "/@/types/workReport.d";

// Work Schedule

export const WorkScheduleScheme = z.object({
  id: z.number().optional(),
  workId: z.number().optional(),
  workPlaceId: z.number(),
  eventPlaceId: z.number().optional().nullable(),
  eventPlaceName: z.string().optional().nullable(),
  eventPlaceShortName: z.string().optional().nullable(),
  eventPlaceGmapUrl: z.string().optional().nullable(),
  eventPlaceAttachedImages: EventPlaceAttachedImageScheme.array().optional(),
  meetingPlace: z.string().optional().nullable(),
  workTypeId: z.number(),
  needCount: z.number().optional(),
  vacancyCount: z.number().optional(), // TODO: optional 外して問題ないか
  currentCount: z.number().optional(),
  enoughCount: z.boolean().optional(),
  canceled: z.boolean().optional(),
  assignedMembers: z
    .object({
      id: z.number(),
      name: z.string().optional(),
    })
    .array()
    .optional(),
  targetDate: DateTimeScheme,
  firstMeetingAt: DateTimeScheme.optional().nullable(),
  meetingAt: DateTimeScheme.optional().nullable(),
  startAt: DateTimeScheme,
  finishAt: DateTimeScheme,
  workCategory: WorkCategoriesScheme,
  needBodyTemperature: z.boolean().optional(),
  needAntibodyTestWithin: z.number().optional(),
  meetingTargets: MeetingTargetWorkPlaceClientScheme.optional(),
  meetingTargetFilter: ReportItemFilterScheme.array().optional(),
});

export type WorkSchedule = zod.infer<typeof WorkScheduleScheme>;

export const WorkScheduleFormScheme = WorkScheduleScheme.partial({
  id: true,
  workId: true,
  workPlaceId: true,
  workTypeId: true,
  meetingAt: true,
  startAt: true,
  finishAt: true,
  assignedMembers: true,
});

export type WorkScheduleForm = zod.infer<typeof WorkScheduleFormScheme>;

export const WorkScheduleWithAdditionalInfoScheme =
  WorkScheduleFormScheme.extend({
    luxonTargetDate: z.date().transform((value) => fromDate(value)),
    idx: z.number(),
  });

export type WorkScheduleWithAdditionalInfo = zod.infer<
  typeof WorkScheduleWithAdditionalInfoScheme
>;

export type EachTypeWorkSchedulesMap = {
  [key in WorkCategory]: WorkScheduleWithAdditionalInfo[];
};

export const WorkScheduleForWorkAssignmentsScheme = z.object({
  id: z.number(),
  needCount: z.number(),
});

export type WorkScheduleForWorkAssignments = zod.infer<
  typeof WorkScheduleForWorkAssignmentsScheme
>;

export const WorkScheduleAssignmentScheme = z.object({
  targetDate: DateScheme,
  organizationMemberId: z.number().optional(),
  organizationId: z.number().optional(),
  workScheduleIds: WorkScheduleForWorkAssignmentsScheme.array(),
  restTimes: RestTimeScheme.array(),
});

export type WorkScheduleAssignment = zod.infer<
  typeof WorkScheduleAssignmentScheme
>;

export const WorkScheduleAssignmentFormScheme =
  WorkScheduleAssignmentScheme.partial({
    targetDate: true,
  });

export type WorkScheduleAssignmentForm = zod.infer<
  typeof WorkScheduleAssignmentFormScheme
>;
