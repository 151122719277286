<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 13.2 13.4"
  >
    <defs>
      <filter
        id="plus_button_blue_small"
        x="0"
        y="0"
        width="13.199"
        height="13.399"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g
      transform="matrix(1, 0, 0, 1, 0, 0)"
      filter="url(#plus_button_blue_small)"
    >
      <g
        id="plus_button_blue_small-2"
        data-name="plus_button_blue_small"
        transform="translate(-9304.9 8260.8)"
        fill="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path
          d="M 9311.9013671875 -8249 L 9311.1005859375 -8249 L 9311.1005859375 -8254.099609375 L 9306.0009765625 -8254.099609375 L 9306.0009765625 -8254.900390625 L 9311.1005859375 -8254.900390625 L 9311.1005859375 -8259.9990234375 L 9311.9013671875 -8259.9990234375 L 9311.9013671875 -8254.900390625 L 9317 -8254.900390625 L 9317 -8254.099609375 L 9311.9013671875 -8254.099609375 L 9311.9013671875 -8249 Z"
          stroke="none"
        />
        <path
          d="M 9311.5009765625 -8259.9990234375 L 9311.5009765625 -8254.5 L 9311.5009765625 -8259.9990234375 M 9306.0009765625 -8254.5 L 9311.5009765625 -8254.5 L 9306.0009765625 -8254.5 M 9311.5009765625 -8254.5 L 9317 -8254.5 L 9311.5009765625 -8254.5 M 9311.5009765625 -8254.5 L 9311.5009765625 -8249 L 9311.5009765625 -8254.5 M 9311.5009765625 -8260.798828125 C 9311.9423828125 -8260.798828125 9312.30078125 -8260.4404296875 9312.30078125 -8259.9990234375 L 9312.30078125 -8255.2998046875 L 9317 -8255.2998046875 C 9317.44140625 -8255.2998046875 9317.7998046875 -8254.94140625 9317.7998046875 -8254.5 C 9317.7998046875 -8254.05859375 9317.44140625 -8253.7001953125 9317 -8253.7001953125 L 9312.30078125 -8253.7001953125 L 9312.30078125 -8249 C 9312.30078125 -8248.55859375 9311.9423828125 -8248.2001953125 9311.5009765625 -8248.2001953125 C 9311.0595703125 -8248.2001953125 9310.701171875 -8248.55859375 9310.701171875 -8249 L 9310.701171875 -8253.7001953125 L 9306.0009765625 -8253.7001953125 C 9305.5595703125 -8253.7001953125 9305.201171875 -8254.05859375 9305.201171875 -8254.5 C 9305.201171875 -8254.94140625 9305.5595703125 -8255.2998046875 9306.0009765625 -8255.2998046875 L 9310.701171875 -8255.2998046875 L 9310.701171875 -8259.9990234375 C 9310.701171875 -8260.4404296875 9311.0595703125 -8260.798828125 9311.5009765625 -8260.798828125 Z"
          stroke="none"
          fill="#4285f5"
        />
      </g>
    </g>
  </svg>
</template>
