<template>
  <div>
    <PageHeader page-title="抗原検査の提出" />
    <AntibodyTest @submit="handleSubmit" />
  </div>
</template>

<script setup lang="ts">
import { axios } from "/@/modules/axios";
import { useRouterUtil } from "/@/vue/composables";
import { PageHeader } from "/@/vue/components/Layouts";
import { AntibodyTest } from "/@/vue/components/Organisms";
import type { AntibodyTest as AntibodyTestType } from "/@/types";
import { errorHandle } from "/@/modules/error";

const { goto, query } = useRouterUtil();

async function handleSubmit(antibodyTest: AntibodyTestType) {
  try {
    axios.post("/api/v1/antibody_tests", {
      antibodyTests: antibodyTest,
    });
    goto({ name: "Home", query: query.value });
  } catch (e) {
    errorHandle(e);
    return false;
  }
}
</script>

<style scoped></style>
