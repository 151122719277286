<template>
  <div>
    <BasicButton
      label="各日程の追加"
      button-type="button"
      icon="pi pi-plus-circle"
      @click="openModal"
    />

    <SimpleModal
      ref="modal"
      class="no-select"
      title="日にちの追加"
      submit-text="追加"
      :submit-disabled="!addingDates.length"
      @submit="addSchedules"
      @cancel="closeModal"
    >
      <div class="d-flex flex-column align-items-center">
        <div>
          <ContentLabel label="表示月の既存の日数・稼動数" />
          <CurrentSchedulesCounter
            :schedules="selectedDateSchedules"
            class="mb-3"
          />

          <ContentLabel label="追加後の日数・稼動数" without-padding />
          <ContentLabel label="(追加分の稼働数は1とする)" />
          <CurrentSchedulesCounter
            :schedules="
              selectedDateSchedules
                .map((schedule) => ({
                  targetDate: schedule.targetDate,
                  needCount: schedule.needCount,
                }))
                .concat(
                  convertedAddingDates.map((d) => ({
                    targetDate: d,
                    needCount: 1,
                  }))
                )
            "
            class="mb-3"
          />

          <div class="w-100 d-flex justify-content-center mb-3">
            <DateForm
              v-model:value="addingDates"
              multi-dates
              inline
              :is-valid="!!addingDates.length"
              :max-date="luxonMaxDate"
              :min-date="luxonMinDate"
              :markers="markers"
              @update-month-year="updateMonthYear"
            />
          </div>

          <Accordion>
            <AccordionTab header="業務種別を変更する">
              <FormItemSelector
                v-model:value="workCategory"
                :items="workCategories"
                label="種別"
                :show-fn="translater"
                form-id="select-schedule-type"
                form-type="general"
                class="mb-3"
              />
            </AccordionTab>
          </Accordion>
        </div>
      </div>
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import { ref, computed, watch, onMounted, reactive } from "vue";
import { isContainInDateMap, luxonNow, fromISOToDate } from "/@/modules/luxon";
import { translater } from "/@/modules/string";
import { SimpleModal, FormItemSelector } from "/@/vue/components/Molecules";
import { BasicButton, ContentLabel, DateForm } from "/@/vue/components/Atom";
import { CancelButton } from "/@/vue/components/Molecules";
import { WorkCategory, WorkScheduleForm, workCategories } from "/@/types";
import { Markers } from "/@/modules/datepicker";
import { fromISO } from "/@/modules/luxon";
import CurrentSchedulesCounter from "./CurrentSchedulesCounter.vue";

const props = defineProps<{
  maxDate?: string;
  minDate?: string;
  workSchedules?: WorkScheduleForm[];
  defaultPrms: {
    workCategory?: WorkCategory;
    needBodyTemperature?: boolean;
    needAntibodyTestWithin?: number;
  };
}>();

const emits = defineEmits<{
  (e: "update:workSchedules", schedules: WorkScheduleForm[]): void;
}>();

const today = luxonNow();

const selectedMonthYear = reactive({
  year: today.year,
  month: today.month,
});

function updateMonthYear(prms: { month: number; year: number }) {
  const { month, year } = prms;

  selectedMonthYear.year = year;
  selectedMonthYear.month = month;
}

const selectedDateSchedules = computed(() => {
  if (!props.workSchedules) return [];

  return props.workSchedules.filter((schedule) =>
    isContainInDateMap(schedule.targetDate, selectedMonthYear)
  );
});

const luxonMaxDate = computed(() => {
  if (!props.maxDate) {
    return;
  }
  return fromISO(props.maxDate).endOf("day");
});

const luxonMinDate = computed(() => {
  if (!props.minDate) {
    return;
  }
  return fromISO(props.minDate);
});

const addingDates = ref<string[]>([]);

const convertedAddingDates = computed(() => {
  return addingDates.value.map((d) => fromISOToDate(d));
});

const markers = computed<Markers[]>(() => {
  if (!props.workSchedules) {
    return [];
  }

  return props.workSchedules.map((ws) => ({
    date: ws.targetDate,
    type: "dot",
    color: "red",
  }));
});

const workCategory = ref<WorkCategory>("shop_helper");

onMounted(() => {
  workCategory.value = props.defaultPrms.workCategory || "shop_helper";
});

watch(
  () => props.defaultPrms.workCategory,
  (st) => {
    if (st) {
      workCategory.value = st;
    }
  }
);

function addSchedules() {
  if (!props.workSchedules) {
    return;
  }

  const adder: WorkScheduleForm[] = convertedAddingDates.value.map((d) => ({
    targetDate: d,
    workCategory: workCategory.value,
    needCount: 1,
    needBodyTemperature: props.defaultPrms.needBodyTemperature || false,
    needAntibodyTestWithin: props.defaultPrms.needAntibodyTestWithin || 0,
  }));

  const newSchedules = [...adder, ...props.workSchedules];

  newSchedules.sort((a, b) => {
    const at = fromISO(b.targetDate);
    const bt = fromISO(a.targetDate);

    if (!at || !bt) {
      return 0;
    }

    return at < bt ? 1 : -1;
  });

  emits("update:workSchedules", newSchedules);
  addingDates.value = [];
  workCategory.value = "shop_helper";
  closeModal();
}

// modal

const modal = ref();

function openModal() {
  modal.value.openModal();
}

function closeModal() {
  modal.value.closeModal();
}
</script>

<style scoped></style>
