<template>
  <table
    class="table table-responsive table-bordered table-striped table-sm small"
  >
    <thead>
      <tr class="sticky">
        <th class="sticky cell-name">名前\日にち</th>
        <th
          v-for="d in dates"
          :key="`header-${d.dateKey}`"
          class="text-center cell-date"
          :class="{
            [getWeekdayColor(d, jpHolidays)]: true,
          }"
        >
          {{ d.luxonDate.day }}
        </th>
        <th class="cell-working-count">稼働数</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="sticky cell-name">割当可能人数</td>
        <td
          v-for="d in dates"
          :key="`header-${d.dateKey}`"
          class="text-center cell-date"
        >
          {{ eachDateStatusStatisticksMap[d.dateKey]?.canWork }}
        </td>
        <td>
          {{ sumOfCanWork }}
        </td>
      </tr>
      <LaborInforUserRowCalendarRow
        v-for="member in organizationMembers"
        :key="`member-${member.id}`"
        :member="member"
        :member-each-date-status-map="memberEachDateStatusMap"
        :dates="dates"
        :selected-date="selectedDate"
        :jp-holidays="jpHolidays"
      />
    </tbody>
  </table>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { createDateForCalendars, getWeekdayColor } from "/@/modules/luxon";
import type {
  AbsenceApplicationPlan,
  DateForCalendar,
  DateMap,
  JpHolidays,
  OrganizationMember,
  OrganizationMemberSchedulePlanLight,
  MemberAbsenceApplicationPlanMap,
  MemberSchedulePlanMap,
  MemberStatusMap,
  MemberEachDateStatusMap,
  StatisticksMap,
} from "/@/types";

import LaborInforUserRowCalendarRow from "./LaborInfoUserRowCalendarRow.vue";

const props = defineProps<{
  selectedDate: DateMap;
  jpHolidays: JpHolidays;
  organizationMembers: OrganizationMember[];
  absenceApplicationPlans: AbsenceApplicationPlan[];
  organizationMemberSchedules: OrganizationMemberSchedulePlanLight[];
}>();

const dates = computed<DateForCalendar[]>(() => {
  if (!props.selectedDate) {
    return [];
  }

  return createDateForCalendars(props.selectedDate);
});

const memberAbsenceApplicationPlanMap = computed(() => {
  let m: MemberAbsenceApplicationPlanMap = {};

  props.organizationMembers.forEach((member) => {
    if (!member.id) {
      return;
    }

    if (!m[member.id]) {
      m[member.id] = {};
    }

    const memberAbsences = props.absenceApplicationPlans.filter(
      (ap) => ap.organizationMember.id === member.id
    );

    memberAbsences.forEach((ap) => {
      // @ts-ignore
      m[member.id][ap.targetDate] = ap;
    });
  });

  return m;
});

const memberOrganizationMemberSchedulePlanMap = computed(() => {
  let m: MemberSchedulePlanMap = {};

  props.organizationMembers.forEach((member) => {
    if (!member.id) {
      return;
    }

    if (!m[member.id]) {
      m[member.id] = {};
    }

    const memberSchedule = props.organizationMemberSchedules.filter(
      (ms) => ms.organizationMemberId === member.id
    );

    memberSchedule.forEach((ms) => {
      // @ts-ignore
      m[member.id][ms.targetDate] = ms;
    });
  });

  return m;
});

function getStatusStr(
  absence: AbsenceApplicationPlan | undefined,
  schedule: OrganizationMemberSchedulePlanLight | undefined
): MemberStatusMap {
  let status: MemberStatusMap = {
    str: "",
    key: "canWork",
    textClass: "",
    icon: undefined,
  };

  if (schedule) {
    status.icon = "pi-check";
    status.key = "scheduled";
  } else if (!absence) {
    status.icon = "pi-circle";
    status.key = "canWork";
  } else {
    status.textClass = "text-danger";

    switch (absence?.applicationType) {
      case "paid":
        status.str = "有給";
        status.key = "paid";
        break;
      case "holiday":
        status.str = "全休申請";
        status.key = "holiday";
        break;
      case "halfday":
        status.str = "半休申請";
        status.key = "halfday";
    }
  }

  return status;
}

const memberEachDateStatusMap = computed<MemberEachDateStatusMap>(() => {
  let m: MemberEachDateStatusMap = {};

  props.organizationMembers.forEach((member) => {
    m[member.id] = {};

    dates.value.forEach((d) => {
      const absence =
        memberAbsenceApplicationPlanMap.value[member.id]?.[d.dateKey];
      const schedule =
        memberOrganizationMemberSchedulePlanMap.value[member.id]?.[d.dateKey];

      // @ts-ignore
      m[member.id][d.dateKey] = {
        ...d,
        ...getStatusStr(absence, schedule),
      };
    });
  });

  return m;
});

const eachDateStatusStatisticksMap = computed(() => {
  let m: Partial<Record<string, StatisticksMap>> = {};

  dates.value.forEach((d) => {
    m[d.dateKey] = {
      canWork: 0,
      paid: 0,
      holiday: 0,
      halfday: 0,
      scheduled: 0,
    };

    props.organizationMembers.forEach((member) => {
      const status = memberEachDateStatusMap.value[member.id]?.[d.dateKey];

      if (!status) {
        return;
      }

      // @ts-ignore
      m[d.dateKey][status.key]++;
    });
  });

  return m;
});

const sumOfCanWork = computed(() => {
  return Object.values(eachDateStatusStatisticksMap.value).reduce(
    (acc, cur) => (cur ? acc + cur.canWork : acc),
    0
  );
});
</script>

<style lang="scss" scoped>
.cell-name {
  max-width: 80px;
  min-width: 80px;
}

.cell-date {
  max-width: 45px;
  min-width: 45px;
}
</style>
