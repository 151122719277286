<template>
  <div :class="`card ${!errors[formId] ? '' : 'text-danger'}`">
    <CollapseWrapper
      :contents-id="formId"
      card-header
      open-default
      :disabled="!showPlaceSelector"
    >
      <template #header>
        <div v-if="showPlaceSelector" class="me-2">案件で使用する店舗</div>
        <MessageList
          :messages="errorMessage"
          :status="!showPlaceSelector ? 'sync-location' : 'error'"
        />
      </template>
      <template #contents>
        <div v-if="filterdWorkPlacesCount == 0" class="text-danger">
          対象の店舗が存在しません
        </div>
        <div v-else class="w-100 place-multi-selector-wrapper">
          <MultiSelect
            :model-value="selectedPlaceIds"
            :options="filteredWorkPlaces"
            filter
            empty-filter-message="店舗が見つかりません"
            :option-label="
              (wp) =>
                `${wp.name} (${translater(wp.placeSubCategory)}/${translater(
                  wp.prefecture
                )})`
            "
            option-value="id"
            placeholder="店舗を選択してください"
            class="h-100"
            :class="{
              'p-invalid': selectedPlaceIds.length == 0,
            }"
            @update:model-value="$emit('update:selectedPlaceIds', $event)"
          />
        </div>
        <div
          v-for="(c, i) in selectedCarriers"
          v-if="false"
          :key="c"
          :class="`card ${i != 0 ? 'mt-2' : ''}`"
        >
          <CollapseWrapper :contents-id="formId + c" card-header open-default>
            <template #header>
              {{ translater(c) }}
            </template>
            <template #contents>
              <div v-if="workPlaceCheckItemMap[c]">
                <template v-for="(p, j) in selectedPrefectures" :key="p">
                  <div
                    v-if="existsWorkPlace(workPlaceCheckItemMap, c, p)"
                    :class="`card ${j != 0 ? 'mt-2' : ''}`"
                  >
                    <CollapseWrapper
                      :contents-id="formId + c + p"
                      card-header
                      open-default
                    >
                      <template #header>
                        {{ translater(p) }}
                        ({{ workPlaceCheckItemMap[c]?.[p]?.count }})
                      </template>
                      <template #contents>
                        <div class="place-list">
                          <CheckFormArray
                            :values="selectedPlaceIds"
                            :items="
                              targetWorkPlaces(workPlaceCheckItemMap, c, p)
                            "
                            :form-id="formId"
                            :is-valid="!errors[formId]"
                            :disabled-values="disabledIds"
                            @update:values="
                              $emit('update:selectedPlaceIds', $event)
                            "
                          />
                        </div>
                      </template>
                    </CollapseWrapper>
                  </div>
                </template>
              </div>
            </template>
          </CollapseWrapper>
        </div>
      </template>
    </CollapseWrapper>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import {
  getErrorMessagesFromErrors,
  ZodFormattedErrors,
} from "/@/modules/zodUtils";
import { translater } from "/@/modules/string";
import {
  createWorkPlaceCheckItemMap,
  createFilteredWorkPlacesCount,
  existsWorkPlace,
  targetWorkPlaces,
} from "/@/modules/workPlaces";
import { CollapseWrapper, CheckFormArray } from "/@/vue/components/Molecules";
import { MessageList, MultiSelect } from "/@/vue/components/Atom";
import type {
  WorkPlace,
  WorkPlaceCheckItemMap,
  Prefecture,
  Carrier,
} from "/@/types";

const props = defineProps<{
  workPlaces: WorkPlace[];
  selectedCarriers: Carrier[];
  selectedPrefectures: Prefecture[];
  selectedPlaceIds: number[];
  disabledIds?: number[];
  errors: ZodFormattedErrors;
  formId: string;
}>();

defineEmits<{
  (e: "update:selectedPlaceIds", placeIds: number[]): void;
}>();

const showPlaceSelector = computed(() => {
  return !!props.selectedCarriers.length && !!props.selectedPrefectures.length;
});

// Carrier/Prefecture の変化で selectedPlaceIds を初期化

/*
watch(
  () => props.selectedCarriers,
  (newCarriers, oldCarriers) => {
    // 配列の等値比較
    if (!_.isEqual(newCarriers, oldCarriers)) {
      emit("update:selectedPlaceIds", []);
    }
  }
);

watch(
  () => props.selectedPrefectures,
  (newPrefectures, oldPrefectures) => {
    // 配列の等値比較
    if (!_.isEqual(newPrefectures, oldPrefectures)) {
      emit("update:selectedPlaceIds", []);
    }
  }
);
*/

const filteredWorkPlaces = computed<WorkPlace[]>(() => {
  const wps = props.workPlaces.filter((wp) => {
    return (
      props.selectedCarriers.includes(wp.placeSubCategory) &&
      props.selectedPrefectures.includes(wp.prefecture)
    );
  });

  wps.sort((a, b) => {
    if (a.prefecture < b.prefecture) {
      return -1;
    }

    if (a.prefecture > b.prefecture) {
      return 1;
    }

    if (a.placeSubCategory < b.placeSubCategory) {
      return -1;
    }

    if (a.placeSubCategory > b.placeSubCategory) {
      return 1;
    }

    if (a.name < b.name) {
      return -1;
    }

    if (a.name > b.name) {
      return 1;
    }

    return 0;
  });

  return wps;
});

const workPlaceCheckItemMap = computed<WorkPlaceCheckItemMap>(() => {
  return createWorkPlaceCheckItemMap({
    wps: props.workPlaces,
    cs: props.selectedCarriers,
    ps: props.selectedPrefectures,
  });
});

const filterdWorkPlacesCount = computed<number>(() => {
  return createFilteredWorkPlacesCount({
    wpcim: workPlaceCheckItemMap.value,
    cs: props.selectedCarriers,
    ps: props.selectedPrefectures,
  });
});

const errorMessage = computed<string[]>(() => {
  if (!showPlaceSelector.value) {
    return ["キャリアとエリアを選択すると", "店舗一覧が表示されます"];
  }

  return getErrorMessagesFromErrors(props.errors, props.formId);
});
</script>

<style scoped>
.is-invalid {
  color: var(--bs-danger-text);
}

.place-list {
  max-height: 200px;
  overflow-y: scroll;
}

.place-multi-selector-wrapper {
  overflow-x: scroll;
}
</style>
