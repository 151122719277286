<template>
  <div>
    <BasicDatepicker
      :model-value="timeObjValue"
      locale="ja"
      :format="format"
      close-on-scroll
      :preview-format="() => ''"
      select-text="決定"
      cancel-text="キャンセル"
      :time-picker="true"
      :minutes-increment="minutesIncrement"
      :minutes-grid-increment="minutesIncrement"
      :min-time="timeObjMinTime"
      :max-time="timeObjMaxTime"
      :start-time="startTime"
      :state="isValid ? undefined : false"
      :month-change-on-scroll="false"
      :input-class-name="`timepicker ${isPc ? '' : 'mobile-ui'}`"
      :teleport-center="teleportCenter"
      @update:model-value="updateTime"
      @cleared="$emit('cleared')"
    >
      <template #input-icon>
        <div
          class="d-flex align-items-center justify-content-center form-icon-wrapper"
        >
          <ClockIcon class="small-icon" />
        </div>
      </template>
    </BasicDatepicker>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import {
  atToTimeObj,
  timeObjToAt,
  createStartTime,
} from "/@/modules/datepicker";
import type { PickerTimeObject } from "/@/types/datepicker";
import { LuxonTimeObject } from "/@/modules/luxon";
import { zeroPadding } from "/@/modules/string";
import { ClockIcon, BasicDatepicker } from "/@/vue/components/Atom";

type Props = {
  value?: string | null;
  minTime?: string;
  maxTime?: string;
  env?: LuxonTimeObject;
  startTime?: PickerTimeObject | undefined;
  minutesIncrement?: number;
  isPc?: boolean;
  isValid?: boolean;
  teleportCenter?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  value: undefined,
  minTime: undefined,
  maxTime: undefined,
  isPc: false,
  // @ts-ignore
  startTime: (props: Props) => createStartTime(props.value),
  minutesIncrement: 10,
  env: () => ({ year: 2000, month: 1, day: 1 }),
});

const emit = defineEmits(["update:value", "cleared"]);

const timeObjValue = computed(() => {
  return atToTimeObj(props.value);
});

const timeObjMinTime = computed(() => {
  return atToTimeObj(props.minTime);
});

const timeObjMaxTime = computed(() => {
  return atToTimeObj(props.maxTime);
});

function updateTime(timeObj: PickerTimeObject | undefined) {
  emit("update:value", timeObjToAt(timeObj, props.env));
}

function format(day: Date) {
  if (!day) {
    return null;
  }

  return `${zeroPadding(day.getHours(), 2)}:${zeroPadding(
    day.getMinutes(),
    2
  )}`;
}
</script>

<style lang="scss" scoped></style>
