<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="22.6"
    height="22.8"
    viewBox="0 0 22.6 22.8"
  >
    <defs>
      <filter
        id="パス_27"
        x="0"
        y="0"
        width="22.6"
        height="22.8"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="合体_10"
        x="6.155"
        y="7.244"
        width="10.288"
        height="8.312"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur-2" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-2" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="check_circle_icon_blue_small" transform="translate(0.3)">
      <g transform="matrix(1, 0, 0, 1, -0.3, 0)" filter="url(#パス_27)">
        <g
          id="パス_27-2"
          data-name="パス 27"
          transform="translate(0.3)"
          fill="none"
        >
          <path d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Z" stroke="none" />
          <path
            d="M 11 1.5 C 5.761680603027344 1.5 1.5 5.761680603027344 1.5 11 C 1.5 16.23831939697266 5.761680603027344 20.5 11 20.5 C 16.23831939697266 20.5 20.5 16.23831939697266 20.5 11 C 20.5 5.761680603027344 16.23831939697266 1.5 11 1.5 M 11 0 C 17.07513046264648 0 22 4.924869537353516 22 11 C 22 17.07513046264648 17.07513046264648 22 11 22 C 4.924869537353516 22 0 17.07513046264648 0 11 C 0 4.924869537353516 4.924869537353516 0 11 0 Z"
            stroke="none"
            fill="#4285f5"
          />
        </g>
      </g>
      <g
        id="グループ_29"
        data-name="グループ 29"
        transform="translate(-337.594 -145.283)"
      >
        <g
          id="グループ_325"
          data-name="グループ 325"
          transform="translate(344.499 153.277)"
        >
          <g
            transform="matrix(1, 0, 0, 1, -7.21, -7.99)"
            filter="url(#合体_10)"
          >
            <path
              id="合体_10-2"
              data-name="合体 10"
              d="M0,1.889,2.174,6.012ZM8.188,0,2.174,6.012Z"
              transform="translate(7.21 7.99)"
              fill="none"
              stroke="#4285f5"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
