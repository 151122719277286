<template>
  <td rowspan="2">
    {{ memberScheduleValidCount }}/{{
      targetMonthLabor?.prescribedWorkingDays || "-"
    }}
  </td>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { isSameMonth } from "/@/modules/luxon";
import {
  OrganizationMember,
  OrganizationMemberSchedulePlan,
  DateMap,
} from "/@/types";

const props = defineProps<{
  member: OrganizationMember;
  schedules: OrganizationMemberSchedulePlan[];
  selectedDate: DateMap;
}>();

const memberSchedules = computed(() => {
  return props.schedules.filter(
    (schedule) =>
      schedule.organizationMemberId === props.member.id &&
      !schedule.workSchedules?.every((s) => s.canceled)
  );
});

const memberScheduleValidCount = computed(() => {
  return memberSchedules.value.filter((schedule) => {
    return !schedule.absence;
  }).length;
});

const targetMonthLabor = computed(() => {
  return props.member.organizationMemberLabors?.find((labor) =>
    isSameMonth(props.selectedDate, labor.targetDate)
  );
});
</script>

<style scoped></style>
