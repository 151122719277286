<template>
  <div>
    <ContentLabel v-if="label" :label="label" :content-id="formId" />
    <slot></slot>
    <MessageList :messages="errorMessage" status="error" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import {
  getErrorMessagesFromErrors,
  ZodFormattedErrors,
} from "/@/modules/zodUtils";
import { ContentLabel, MessageList } from "/@/vue/components/Atom";

interface Props {
  label?: string;
  formId: string;
  errors: ZodFormattedErrors;
  errorMessage?: string; // これがある場合は、エラーが発生した時にその文章を単に表示する
}

const props = withDefaults(defineProps<Props>(), {
  label: undefined,
  errorMessage: "入力に問題があります",
});

const errorMessage = computed<string[]>(() => {
  const ms = getErrorMessagesFromErrors(props.errors, props.formId);

  if (!ms || !ms.length) {
    return [];
  }
  return ms.at(0) == "has_nested_error" ? [props.errorMessage] : ms;
});
</script>

<style scoped></style>
