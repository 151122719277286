<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="22.622"
    height="19.363"
    viewBox="0 0 22.622 19.363"
  >
    <defs>
      <filter
        id="パス_144"
        x="0"
        y="1.506"
        width="20.927"
        height="7.363"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="楕円形_45"
        x="5.164"
        y="5.155"
        width="10.6"
        height="10.8"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur-2" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-2" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="パス_143"
        x="0.006"
        y="0"
        width="22.616"
        height="19.363"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur-3" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-3" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="hide_button_gray" transform="translate(1.714 1.407)">
      <g transform="matrix(1, 0, 0, 1, -1.71, -1.41)" filter="url(#パス_144)">
        <path
          id="パス_144-2"
          data-name="パス 144"
          d="M0-3.2S4.375-7.35,8.75-7.35,17.5-3.2,17.5-3.2"
          transform="translate(1.71 9.86)"
          fill="none"
          stroke="#c2c2c2"
          stroke-linecap="round"
          stroke-width="2"
        />
      </g>
      <g transform="matrix(1, 0, 0, 1, -1.71, -1.41)" filter="url(#楕円形_45)">
        <g
          id="楕円形_45-2"
          data-name="楕円形 45"
          transform="translate(5.46 5.16)"
          fill="#fff"
          stroke="#c2c2c2"
          stroke-width="3"
        >
          <circle cx="5" cy="5" r="5" stroke="none" />
          <circle cx="5" cy="5" r="3.5" fill="none" />
        </g>
      </g>
      <g transform="matrix(1, 0, 0, 1, -1.71, -1.41)" filter="url(#パス_143)">
        <path
          id="パス_143-2"
          data-name="パス 143"
          d="M19.2,10.252,0,26"
          transform="translate(1.71 -8.84)"
          fill="none"
          stroke="#c2c2c2"
          stroke-linecap="round"
          stroke-width="2"
        />
      </g>
    </g>
  </svg>
</template>
