<template>
  <div>
    <table
      class="table table-sm table-responsive table-bordered table-striped small bg-white mb-2"
    >
      <thead>
        <tr>
          <th>対象期間</th>
          <th colspan="2">氏名</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {{ basicFormatter(targetRange.start) }}
            ~
            {{ basicFormatter(targetRange.end) }}
          </td>
          <td class="member-code">{{ member.code }}</td>
          <td>{{ member.user.name }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { basicFormatter, getStartEndFromMap } from "/@/modules/luxon";
import { DateMap, OrganizationMember } from "/@/types";

const props = defineProps<{
  selectedDate: DateMap;
  member: OrganizationMember;
}>();

const targetRange = computed(() => {
  return getStartEndFromMap(props.selectedDate);
});
</script>

<style scoped></style>
