<template>
  <div>
    <table class="table table-bordered table-striped">
      <thead>
        <tr>
          <th v-for="target of allTargets" :key="target.key" class="header-th">
            {{ target.key }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-for="target of allTargets" :key="`current-${target.key}`">
            {{ currentMap[target.key] || 0 }}/{{ target.count }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { ReportItemFilterKey, MeetingTargetWorkPlaceClient } from "/@/types";

const props = defineProps<{
  meetingTarget: MeetingTargetWorkPlaceClient;
}>();

const allTargets = computed(() => {
  if (!props.meetingTarget) return [];

  return props.meetingTarget.reportItems;
});

const currentStatus = computed(() => {
  if (!props.meetingTarget) return [];

  return props.meetingTarget.currentStatusOverall;
});

const currentMap = computed<Record<ReportItemFilterKey, number>>(() => {
  let m: Record<ReportItemFilterKey, number> = {};

  currentStatus.value.forEach((target) => {
    m[target.key] = target.count;
  });

  return m;
});
</script>

<style scoped lang="scss">
.table {
  width: auto;

  th.header-th {
    width: 100px;
  }
}
</style>
