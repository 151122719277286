<template>
  <div>
    <SimpleModal
      id="assignment-modal"
      ref="modal"
      :title="modalTitle"
      submit-text="作成"
      :loading="loading"
      :submit-disabled="submitDisabled"
      no-actions
      @cancel="closeModal"
      @submit="submit"
    >
      <template v-if="targetDate">
        <GroupToggler
          v-if="subContractors.length"
          v-model:selected="showTarget"
          :toggle-types="toggleTypes"
          class="mb-3"
          form-id="toggle-target-types"
        />

        <span
          v-if="!workerMembers.length"
          class="text-danger"
          style="font-size: 0.8rem"
        >
          管理対象のメンバーにこの日に割り当て可能なメンバーがいません
        </span>
        <FormItemSelector
          v-if="showTarget === 'member'"
          class="mb-3 w-100"
          :value="form.organizationMemberId"
          label="メンバー"
          form-type="object"
          :errors="errors"
          :items="workerMembers"
          form-id="organizationMemberId"
          adding-id="assignment-member"
          value-key="id"
          :show-fn="memberShow"
          @update:value="form.organizationMemberId = $event.id"
        />

        <template v-if="showTarget === 'organization'">
          <FormItemSelector
            class="mb-3 w-100"
            :value="form.organizationId"
            label="下請け"
            form-type="object"
            :errors="errors"
            :items="subContractors"
            form-id="organizationMemberId"
            show-key="name"
            value-key="id"
            @update:value="form.organizationId = $event.id"
          />
        </template>

        <div>
          <div class="d-flex align-items-center mb-2">
            <ContentLabel
              label="業務計画"
              content-id="work-schedule-ids"
              without-padding
              class="me-2"
            />
            <BasicButton
              v-if="false"
              small
              rounded
              icon="pi pi-plus"
              @click="addNeedCount"
            />
          </div>

          <template v-if="form.workScheduleIds">
            <div
              v-for="(_, idx) in scheduleCountArray"
              :key="`schedule-selector-${idx}`"
              class="mb-2 d-flex align-items-center"
            >
              <WorkScheduleAssignmentWorkScheduleForm
                :work-schedule-for-assignment="form.workScheduleIds[idx]"
                :unselected-schedules="
                  unselectedByOtherWorkSchedules(
                    form.workScheduleIds.map((ws) => ws.id)[idx]
                  )
                "
                :idx="idx"
                :is-for-organization="showTarget === 'organization'"
                class="w-100"
                @update-work-schedule-for-assignment="
                  form.workScheduleIds[idx] = $event
                "
              />

              <BasicButton
                v-if="showReduceBtn"
                small
                rounded
                icon="pi pi-trash"
                variant="danger"
                @click="reduceNeedCount(idx)"
              />
            </div>
          </template>
        </div>
      </template>

      <div
        v-if="invalidTotalWorkingHours"
        class="d-flex flex-column text-danger mt-3"
      >
        <span>業務が8時間未満になるように休憩時間を設定してください</span>
        <span>現在の業務時間: {{ totalWorkingHours }}時間</span>
        <span>現在の休憩時間: {{ totalRestTimeHours }}時間</span>
      </div>

      <RestTimeForm
        v-if="totalWorkingHours > 9"
        v-model:rest-times="form.restTimes"
        :errors="errors"
        class="mb-3"
      />

      <div class="d-flex w-100 justify-content-between pt-2">
        <BasicButton
          variant="primary"
          label="作成"
          icon="pi pi-plus-circle"
          button-type="submit"
          :disabled="loading"
          @click="submit"
        />

        <MenuButton v-if="isFrythOwner" :menu-items="menuItems" />
      </div>
    </SimpleModal>
    <VacantNumberModal
      v-model="showVacantNumberDialog"
      :target-work-schedule="targetWorkSchedule"
      @vacancy="updateVacancyCount"
    />
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";
import { ref, computed, onMounted } from "vue";
import { memberShowFn, validMember } from "/@/modules/organizationMember";
import { useRouterUtil, useZodScheme } from "/@/vue/composables";
import { ContentLabel, BasicButton } from "/@/vue/components/Atom";
import { MenuButton } from "/@/vue/components/Molecules";
import {
  FormItemSelector,
  GroupToggler,
  SimpleModal,
} from "/@/vue/components/Molecules";
import { RestTimeForm } from "/@/vue/components/Organisms";
import { VacantNumberModal } from "/@/vue/components/Organisms";
import {
  CalendarEventArgs,
  Organization,
  OrganizationMember,
  ContractRelationOrganization,
  WorkScheduleAsPlan,
  WorkScheduleAssignment,
  WorkScheduleAssignmentScheme,
  WorkScheduleAssignmentForm,
  AbsenceApplicationPlan,
} from "/@/types";
import { removeNthElement } from "/@/modules/array";
import {
  basicFormatter,
  countHoursBetween,
  isSameDate,
} from "/@/modules/luxon";
import { errorHandle } from "/@/modules/error";
import Dialog from "primevue/dialog";

import WorkScheduleAssignmentWorkScheduleForm from "./WorkScheduleAssignmentWorkScheduleForm.vue";

const props = defineProps<{
  organizationMembers: OrganizationMember[];
  subContractors: ContractRelationOrganization[];
  selectedOrganization: Organization;
  workSchedules: WorkScheduleAsPlan[];
  absenceApplicationPlans: AbsenceApplicationPlan[];
  loading?: boolean;
  isFrythOwner?: boolean;
}>();

const emit = defineEmits<{
  (e: "submit", workScheduleAssignment: WorkScheduleAssignment): void;
  (e: "destroy", id: number): void;
  (e: "cancel", id: number): void;
  (e: "restore", id: number): void;
  (e: "vacancy", id: number, vacancyCount: number): void;
  (e: "close"): void;
}>();

const events = ref<CalendarEventArgs[]>();
const targetDate = ref();

const nonEnoughWorkSchedules = computed(() => {
  return props.workSchedules.filter((ws) => !ws.enoughCount);
});

const modalTitle = computed(() => {
  const base = "計画の作成";
  return targetDate.value
    ? `${base} ${basicFormatter(targetDate.value, "noYear")}`
    : base;
});

const targetDateHolidayPlans = computed(() => {
  const td = targetDate.value;

  if (!td) return [];

  return props.absenceApplicationPlans.filter(
    (p) => isSameDate(p.targetDate, td) && p.applicationType === "holiday"
  );
});

function memberShow(member: OrganizationMember) {
  return memberShowFn(
    member,
    props.selectedOrganization,
    props.subContractors,
    targetDateHolidayPlans.value
  );
}

const workerMembers = computed(() => {
  if (!props.organizationMembers) return [];

  const targetMembers = props.organizationMembers.filter(
    (member) => !member.hideFromSelect
  );

  if (!targetDate.value) {
    return targetMembers;
  } else {
    const memberIdsInTargetDate = [
      ...new Set(
        props.workSchedules
          .filter((schedule) => schedule.targetDate === targetDate.value)
          .map((schedule) => schedule.assignedMembers)
          .flat()
          .map((m) => m.organizationMemberId)
      ),
    ];

    return targetMembers.filter(
      (m) =>
        !memberIdsInTargetDate.includes(m.id) &&
        validMember(m, targetDate.value)
    );
  }
});

const targetWorkSchedule = computed(() => {
  if (!form.workScheduleIds) return;

  // only for one schedule case

  const targetWorkScheduleId = form.workScheduleIds[0];

  if (!targetWorkScheduleId) return;

  return props.workSchedules.find((ws) => ws.id === targetWorkScheduleId.id);
});

// toggle

type ToggleShowTypes = "member" | "organization";

const showTarget = ref<ToggleShowTypes>("member");

const toggleTypes = [
  {
    name: "メンバー",
    key: "member",
  },
  {
    name: "下請け",
    key: "organization",
  },
];

// vacancy count

const vacancyCount = ref(0);
const showVacantNumberDialog = ref(false);

const vacancyCountOptions = computed(() => {
  if (!targetWorkSchedule.value) return [0];

  return _.range(0, (targetWorkSchedule.value.needCount || 0) + 1);
});

function updateVacancyCount(targetScheduleId: number, vacancyCount: number) {
  emit("vacancy", targetScheduleId, vacancyCount);
  showVacantNumberDialog.value = false;
}

// menu items

const { goto } = useRouterUtil();

const isCanceled = computed(() => {
  return !!targetWorkSchedule.value?.canceled;
});

const menuItems = computed(() => {
  return [
    {
      label: "案件ページへ",
      icon: "pi pi-shopping-bag",
      command: () => {
        if (!targetWorkSchedule.value) {
          alert("案件が見つかりません");
          return;
        }

        goto({
          name: "WorksEdit",
          query: { id: targetWorkSchedule.value.work.id },
        });
        closeModal();
      },
    },
    {
      label: "計画の削除",
      icon: "pi pi-trash",
      command: () => {
        destroyWorkSchedule();
      },
    },
    {
      label: isCanceled.value ? "中止のキャンセル" : "計画の中止",
      icon: isCanceled.value ? "pi pi-circle" : "pi pi-ban",
      command: () => {
        if (isCanceled.value) {
          restoreWorkSchedule();
        } else {
          cancelWorkSchedule();
        }
      },
    },
    {
      label: "欠員の設定",
      icon: "pi pi-user-minus",
      command: () => {
        showVacantNumberDialog.value = true;
      },
    },
  ];
});

// data

const scheduleCount = ref<number>(1);

const scheduleCountArray = computed(() => {
  return Array(scheduleCount.value).fill((_: any, i: any) => i);
});

function addNeedCount() {
  if (scheduleCount.value === targetWorkSchedules.value.length) {
    return;
  } else {
    scheduleCount.value += 1;
  }
}

function reduceNeedCount(idx: number) {
  if (!form.workScheduleIds) {
    return;
  }

  if (scheduleCount.value === 1) {
    return;
  } else {
    scheduleCount.value -= 1;
    form.workScheduleIds = removeNthElement(form.workScheduleIds, idx);
  }
}

const showAddBtn = computed(() => {
  return scheduleCount.value < targetWorkSchedules.value.length;
});

const showReduceBtn = computed(() => {
  return scheduleCount.value !== 1;
});

const targetWorkSchedules = computed(() => {
  if (!events.value || !events.value.length) {
    return [];
  }

  const targetEvent = events.value.at(0);

  if (!targetEvent) {
    return [];
  }

  return nonEnoughWorkSchedules.value.filter(
    (schedule) => schedule.targetDate === targetEvent.extendedProps.targetDate
  );
});

function unselectedByOtherWorkSchedules(selectedId: number | undefined) {
  return targetWorkSchedules.value.filter(
    (ws) =>
      !form.workScheduleIds?.map((ws) => ws.id).includes(ws.id) ||
      ws.id === selectedId
  );
}

const totalWorkingHours = computed(() => {
  if (!form.workScheduleIds) {
    return 0;
  }

  return (
    form.workScheduleIds
      .map((ws) => ws.id)
      .map((wsId) => props.workSchedules.find((ws) => ws.id === wsId))
      // @ts-ignore
      .reduce((acc, current) => current.workingHours + acc, 0)
  );
});

// modal difinissions

const modal = ref();

function openModal(event: CalendarEventArgs) {
  events.value = [event];
  targetDate.value = event.extendedProps.targetDate;

  const workSchedule = event.extendedProps;

  form.workScheduleIds = [{ id: workSchedule.id, needCount: 1 }];
  vacancyCount.value = workSchedule.vacancyCount;

  modal.value.openModal();
}

function closeModal() {
  modal.value.closeModal();
  // 同じ人を当てることが多いので、あえて organizationMemberId は初期化しない
  //form.organizationMemberId = undefined;
  targetDate.value = undefined;
  resetForm();
  emit("close");
}

defineExpose({
  openModal,
  closeModal,
});

// modal form scheme

const { useFormAndErrors } = useZodScheme();

const { form, errors, startValidation } =
  useFormAndErrors<WorkScheduleAssignmentForm>(WorkScheduleAssignmentScheme);

function resetForm() {
  form.targetDate = undefined;
  form.workScheduleIds = [];
  form.restTimes = [];
}

const totalRestTimeHours = computed(() => {
  if (
    !form.restTimes ||
    form.restTimes.length === 0 ||
    form.restTimes.some((restTime) => !restTime.startAt || !restTime.finishAt)
  ) {
    return 0;
  }

  return form.restTimes
    .map((restTime) => countHoursBetween(restTime.startAt, restTime.finishAt))
    .reduce((acc, current) => acc + current, 0);
});

const invalidTotalWorkingHours = computed(() => {
  return (
    !(totalWorkingHours.value <= 9 && totalRestTimeHours.value === 0) &&
    totalWorkingHours.value - totalRestTimeHours.value > 8
  );
});

const submitDisabled = computed(() => {
  return invalidTotalWorkingHours.value;
});

onMounted(() => {
  form.targetDate = targetDate.value;
  //form.workScheduleIds = [];
  form.restTimes = [];
  startValidation.value = false;
});

// core

async function submit() {
  form.targetDate = targetDate.value;

  startValidation.value = true;

  try {
    switch (showTarget.value) {
      case "member":
        form.organizationId = undefined;
        break;
      case "organization":
        form.organizationMemberId = undefined;
        break;
    }

    if (targetDateHolidayPlans.value.length) {
      const targetMemberPlan = targetDateHolidayPlans.value.find(
        (p) => p.organizationMember.id === form.organizationMemberId
      );

      if (targetMemberPlan) {
        if (
          !window.confirm(
            `このメンバーは${basicFormatter(
              form.targetDate
            )}に休日申請を行っています。このままメンバーへの計画割当を続けますか？`
          )
        ) {
          return;
        }
      }
    }

    const workScheduleAssignment = WorkScheduleAssignmentScheme.parse(form);
    emit("submit", workScheduleAssignment);
  } catch (e) {
    errorHandle(e);
  }
}

function destroyWorkSchedule() {
  if (!form.workScheduleIds) return;
  if (form.workScheduleIds.length > 1) {
    window.alert("スケジュールが複数選択されているため削除できません");
    return;
  }

  const targetSchedule = form.workScheduleIds[0];

  emit("destroy", targetSchedule.id);
}

function cancelWorkSchedule() {
  if (!form.workScheduleIds) return;
  if (form.workScheduleIds.length > 1) {
    window.alert("スケジュールが複数選択されているためキャンセルできません");
    return;
  }

  const targetSchedule = form.workScheduleIds[0];

  emit("cancel", targetSchedule.id);
}

function restoreWorkSchedule() {
  if (!form.workScheduleIds) return;
  if (form.workScheduleIds.length > 1) {
    window.alert("スケジュールが複数選択されているため有効化できません");
    return;
  }

  const targetSchedule = form.workScheduleIds[0];

  emit("restore", targetSchedule.id);
}
</script>

<style scoped></style>
