<template>
  <tr>
    <td class="text-nowrap align-middle sticky cell-name">
      {{ member.user.name }}
    </td>
    <td
      v-for="d in dates"
      :key="`date-${d.dateKey}`"
      class="text-center cell-date"
      :class="{
        [currentMemberStatus[d.dateKey]?.textClass]: true,
      }"
    >
      <span v-if="!currentMemberStatus[d.dateKey]?.icon">
        {{ currentMemberStatus[d.dateKey]?.str }}
      </span>
      <i
        v-else
        class="pi small"
        :class="currentMemberStatus[d.dateKey]?.icon"
      ></i>
    </td>
    <td class="cell-working-count">
      <span>
        {{ scheduleCount }}/{{
          memberLabor?.prescribedWorkingDays ||
          (memberLabor?.prescribedWorkingDays === 0 ? 0 : "-")
        }}
      </span>
    </td>
  </tr>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { isSameMonth } from "/@/modules/luxon";
import {
  OrganizationMember,
  DateMap,
  DateForCalendar,
  JpHolidays,
  MemberEachDateStatusMap,
} from "/@/types";

const props = defineProps<{
  member: OrganizationMember;
  memberEachDateStatusMap: MemberEachDateStatusMap;
  dates: DateForCalendar[];
  selectedDate: DateMap;
  jpHolidays: JpHolidays;
}>();

const currentMemberStatus = computed(() => {
  return props.memberEachDateStatusMap[props.member.id];
});

const scheduleCount = computed(() => {
  return props.dates.flatMap((d) => {
    return props.memberEachDateStatusMap[props.member.id]?.[d.dateKey]?.key ===
      "scheduled"
      ? true
      : [];
  }).length;
});

const memberLabor = computed(() => {
  if (!props.member.organizationMemberLabors) return;

  return props.member.organizationMemberLabors?.find((labor) => {
    return isSameMonth(props.selectedDate, labor.targetDate);
  });
});
</script>

<style scoped lang="scss">
.cell-date {
  max-width: 45px;
  min-width: 45px;
}
</style>
