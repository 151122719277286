<template>
  <div>
    <SimpleModal
      ref="modal"
      title="実績の作成"
      submit-text="送信"
      :submit-disabled="disabled"
      @cancel="closeModal"
      @submit="submit"
    >
      <ObjectSelector
        :value="form.workId"
        :items="relatedWorks"
        value-key="id"
        show-key="name"
        form-id="workId"
        label="実績を作成する案件"
        :is-valid="!!form.workId"
        class="mb-3"
        no-auto-select
        @update:value="form.workId = $event?.id"
      />

      <template v-if="selectedWork">
        <ObjectSelector
          :value="form.workTypeId"
          :items="relatedWorkTypes"
          value-key="id"
          show-key="name"
          form-id="workTypeId"
          label="業務内容"
          :is-valid="!!form.workTypeId"
          class="mb-3"
          no-auto-select
          @update:value="form.workTypeId = $event?.id"
        />

        <DateForm
          v-model:value="form.targetDate"
          :is-valid="form.targetDate"
          :min-date="fromISO(selectedWork.startOn)"
          :max-date="fromISO(selectedWork.finishOn)"
          class="mb-3"
        />

        <WorkReportForm
          v-if="selectedWork"
          v-model:report-items="form.reportItems"
          :report-type="form.reportType"
          :work-type-categories="['telecommunications']"
          :work-carrier="selectedWork.carrier"
          :work-category="selectedWork.workCategory"
          :request-organization-id="selectedWork.requestOrganizationId"
          :basis-report-items="basisReportItems"
        />
      </template>
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { fromISO } from "/@/modules/luxon";
import { useZodScheme } from "/@/vue/composables";
import { ObjectSelector, DateForm } from "/@/vue/components/Atom";
import { SimpleModal } from "/@/vue/components/Molecules";
import { WorkReportForm } from "/@/vue/components/Organisms";
import {
  Carrier,
  BasisReportItemClient,
  WorkRecordEntryReportForm,
  WorkRecordEntryReportScheme,
  WorkClient,
  WorkTypeClient,
} from "/@/types";
import { errorHandle } from "/@/modules/error";

const props = defineProps<{
  works: WorkClient[];
  workTypes: WorkTypeClient[];
  basisReportItems: BasisReportItemClient[];
}>();

const emit = defineEmits<{
  (e: "submit", report: WorkRecordEntryReportForm): void;
}>();

const relatedWorks = computed(() => {
  const workPlaceId = form.workPlaceId;

  if (!workPlaceId) return [];

  return props.works.filter((w) => {
    return w.workPlaceIds.includes(workPlaceId);
  });
});

const selectedWork = computed(() => {
  return props.works.find((work) => work.id === form.workId);
});

const relatedWorkTypes = computed(() => {
  if (!selectedWork.value) return [];

  return props.workTypes.filter((wt) => {
    return selectedWork.value.workTypeIds.includes(wt.id);
  });
});

const { useFormAndErrors } = useZodScheme();
const { form, startValidation } = useFormAndErrors<WorkRecordEntryReportForm>(
  WorkRecordEntryReportScheme
);

const disabled = computed(() => {
  const reportItems = form.reportItems;

  return (
    !reportItems ||
    reportItems.length === 0 ||
    !reportItems.some((item) => item["count"] && item["count"] > 0)
  );
});

function initForm(report?: WorkRecordEntryReportForm) {
  if (report) {
    form.workPlaceId = report.workPlaceId;
    form.reportType = report.reportType;
    form.reportItems = report.reportItems;
    form.targetDate = report.targetDate;
    form.id = report.id;
  }
}

const loading = ref<boolean>(false);

async function submit() {
  if (!selectedWork.value) return;

  try {
    startValidation.value = true;
    loading.value = true;

    form.workCategory = selectedWork.value?.workCategory;

    const workReport = WorkRecordEntryReportScheme.parse(form);
    emit("submit", workReport);
  } catch (e) {
    errorHandle(e);
  }

  loading.value = false;
}

const modal = ref();

function openModal(workReport: WorkRecordEntryReportForm) {
  initForm(workReport);
  modal.value?.openModal();
}

function closeModal() {
  initForm({});
  modal.value?.closeModal();
}

defineExpose({
  openModal,
  closeModal,
});
</script>

<style scoped></style>
