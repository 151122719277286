import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { OrganizationCategoryScheme } from "/@/types/category.d";

export const WorkTypeClientScheme = z.object({
  id: z.number(),
  name: z.string(),
  categories: OrganizationCategoryScheme.array(),
});

export type WorkTypeClient = zod.infer<typeof WorkTypeClientScheme>;
