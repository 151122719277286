<template>
  <div>
    <SelectButton
      :model-value="selectedPrefectures"
      :options="prefectureItems"
      option-label="name"
      option-value="value"
      multiple
      aria-labelledby="multiple"
      @update:model-value="$emit('update:selectedPrefectures', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import SelectButton from "primevue/selectbutton";
import { computed } from "vue";
import { translater } from "/@/modules/string";
import { Prefecture, EventPlaceClient, prefectures } from "/@/types";

const props = defineProps<{
  eventPlaces: EventPlaceClient[];
  selectedPrefectures: Prefecture[];
}>();

defineEmits<{
  (e: "update:selectedPrefectures", prefectures: Prefecture[]): void;
}>();

const prefectureItems = computed(() => {
  return prefectures.map((pre) => ({
    name: translater(pre),
    value: pre,
  }));
});
</script>

<style scoped></style>
