<template>
  <div>
    <SelectButton
      :model-value="selectedMemberTypes"
      :options="memberTypeItems"
      option-label="name"
      option-value="value"
      multiple
      aria-labelledby="multiple"
      @update:model-value="$emit('update:selectedMemberTypes', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import SelectButton from "primevue/selectbutton";
import { memberTypeTranslater } from "/@/modules/organizationMember";

import { OrganizationMember, MemberType, memberTypes } from "/@/types";
import { computed } from "vue";

const props = defineProps<{
  selectedMemberTypes: MemberType[];
  organizationMembers: OrganizationMember[];
}>();

defineEmits<{
  (e: "update:selectedMemberTypes", memberTypes: MemberType[]): void;
}>();

const memberTypeItems = computed(() => {
  return memberTypes.map((mt) => ({
    name: memberTypeTranslater(mt),
    value: mt,
  }));
});
</script>

<style scoped></style>
