<template>
  <div>
    <SaveShowingConfig
      v-if="isOwner && showEditMode"
      :hide-map="hideMap"
      :hide-map-base="hideMapBase"
      :hide-map-configs="hideMapConfigs"
      class="d-flex justify-content-end w-100"
      data-html2canvas-ignore="true"
      @load-hide-map-config="loadHideMapConfig"
      @save-hide-map-config="saveHideMapConfig"
      @destroy-hide-map-config="destroyHideMapConfig"
      @exit-hide-map-config="$emit('exitHideMapConfig')"
    />

    <div class="p-2">{{ tableTitle }}</div>
    <div class="table-wrapper">
      <table
        class="table table-sm table-responsive table-bordered align-middle table-fixed table-striped"
      >
        <thead>
          <tr v-if="showEditMode">
            <th class="sticky"></th>
            <th
              v-for="k in filteredHideMapBaseKeys"
              :key="`hide-map-base-${k}`"
            >
              <div
                class="d-flex align-items-center justify-content-center text-nowrap"
              >
                <CheckForm
                  v-model:value="hideMapBase[k]"
                  :form-id="`hide-map-base-${k}`"
                  label="非表示"
                  is-valid
                />
              </div>
            </th>

            <template v-for="cck in existsCCKs" :key="`template-${cck.key}`">
              <template
                v-for="hkey in hideMapKeys"
                :key="`template-${hkey}-${cck.key}`"
              >
                <td
                  v-for="(_, idx) in hideMapArray?.[cck.key]?.[hkey]"
                  :key="`hide-map-check-${idx}`"
                >
                  <div
                    class="d-flex align-items-center justify-content-center text-nowrap"
                  >
                    <CheckForm
                      :value="hideMap?.[cck.key]?.[hkey]?.[idx]"
                      :form-id="`hide-map-check-${hkey}-${cck}-${idx}`"
                      label="非表示"
                      is-valid
                      @update:value="updateHideMap(cck.key, hkey, idx, $event)"
                    />
                  </div>
                </td>
              </template>
            </template>
          </tr>
          <tr class="sticky">
            <th v-if="ownerMode" class="sticky" rowspan="2">編集</th>
            <th class="sticky th-day" rowspan="2">日</th>
            <th
              v-if="
                showRequestOrganization &&
                (showEditMode || !hideMapBase.requestOrganization)
              "
              class="text-nowrap cell-request-organization"
              rowspan="2"
            >
              依頼店舗
            </th>
            <th
              v-if="showEditMode || !hideMapBase.workPlace"
              class="text-nowrap cell-work-place"
              rowspan="2"
            >
              業務場所
            </th>
            <th
              v-if="showEditMode || !hideMapBase.workCategory"
              class="text-nowrap cell-work-category"
              rowspan="2"
            >
              業務種別
            </th>
            <th
              v-if="
                selectedFilter !== 'member' &&
                (showEditMode || !hideMapBase.workerNumber)
              "
              class="text-nowrap cell-work-number"
              rowspan="2"
            >
              稼働数
            </th>
            <th
              v-if="
                selectedFilter !== 'member' &&
                (showEditMode || !hideMapBase.workerNames)
              "
              class="text-nowrap cell-worker-names"
              rowspan="2"
            >
              名前
            </th>
            <ReportItemsHeaderTop
              :category-carrier-keys="existsCCKs.map((cck) => cck.key)"
              :customers-items="customersItems"
              :indivi-items="indiviItems"
              :overall-items="overallItems"
              :hide-map="hideMap"
              :show-edit-mode="showEditMode"
              :shorter-mode="shorterMode"
            />
          </tr>
          <tr class="sticky">
            <ReportItemsHeaderBottom
              :category-carrier-keys="existsCCKs.map((cck) => cck.key)"
              :customers-items="customersItems"
              :indivi-items="indiviItems"
              :overall-items="overallItems"
              :basis-report-items="basisReportItems"
              :hide-map="hideMap"
              :show-edit-mode="showEditMode"
              :shorter-mode="shorterMode"
            />
          </tr>
        </thead>
        <tbody>
          <ResultsTableBodyTr
            v-for="d in dates"
            :key="`dates-${d.dateKey}`"
            :d="d"
            :customers-items="customersItems"
            :indivi-items="indiviItems"
            :overall-items="overallItems"
            :work-reports="targetMonthReports"
            :work-tasks="targetMonthTasks"
            :owner-mode="ownerMode"
            :category-carrier-keys="existsCCKs.map((cck) => cck.key)"
            :jp-holidays="jpHolidays"
            :hide-map="hideMap"
            :hide-map-base="hideMapBase"
            :show-edit-mode="showEditMode"
            :show-only-worked-days="showOnlyWorkedDays"
            :shorter-mode="shorterMode"
            :selected-filter="selectedFilter"
            :show-request-organization="showRequestOrganization"
            @open-edit-modal="openEditWorkReportModal"
          />
          <ResultsTableBodyWorkTaskTr
            :owner-mode="ownerMode"
            :contracted-tasks="targetMonthTasks"
            :category-carrier-keys="existsCCKs.map((cck) => cck.key)"
            :customers-items="customersItems"
            :indivi-items="indiviItems"
            :overall-items="overallItems"
            :selected-date="dateMap"
            :hide-map="hideMap"
            :hide-map-base="hideMapBase"
            :show-edit-mode="showEditMode"
            :selected-filter="selectedFilter"
            :show-request-organization="showRequestOrganization"
          />
          <ResultsTableBodyWorkTaskTr
            :owner-mode="ownerMode"
            :contracted-tasks="targetMonthTasks"
            :category-carrier-keys="existsCCKs.map((cck) => cck.key)"
            :customers-items="customersItems"
            :indivi-items="indiviItems"
            :overall-items="overallItems"
            :selected-date="lastMonthMap"
            :hide-map="hideMap"
            :hide-map-base="hideMapBase"
            :show-edit-mode="showEditMode"
            :selected-filter="selectedFilter"
            :show-request-organization="showRequestOrganization"
          />
          <ResultsTableBodyTotalTr
            :owner-mode="ownerMode"
            :work-reports="targetMonthReports"
            :contracted-tasks="targetMonthTasks"
            :category-carrier-keys="existsCCKs.map((cck) => cck.key)"
            :customers-items="customersItems"
            :indivi-items="indiviItems"
            :overall-items="overallItems"
            :show-edit-mode="showEditMode"
            :hide-map="hideMap"
            :hide-map-base="hideMapBase"
            :selected-filter="selectedFilter"
            :show-request-organization="showRequestOrganization"
          />
        </tbody>
      </table>
    </div>

    <teleport to="body">
      <SimpleModal
        ref="editWorkReportModal"
        title="レポートの更新"
        submit-text="更新"
        :loading="loading"
        @submit="handleUpdate"
        @cancel="closeEditWorkReportModal"
      >
        <WorkReportForm
          v-if="selectedWorkReport"
          v-model:report-items="form.reportItems"
          :report-type="selectedWorkReport.reportType"
          :work-category="selectedWorkReport.workCategory"
          :work-carrier="selectedWorkReport.workCarrier"
          :request-organization-id="selectedWorkReport.requestOrganizationId"
          :work-type-categories="categories"
          :basis-report-items="basisReportItems"
          is-edit
        />
      </SimpleModal>
    </teleport>
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";
import { computed, ref, watch } from "vue";
import { errorHandle } from "/@/modules/error";
import {
  createDateForCalendars,
  isSameDate,
  isSameMonth,
  prevMonth,
  getStartEndFromMap,
} from "/@/modules/luxon";
import {
  basisToReport,
  isShowItem,
  getReportItemsNew,
  categoryCarrierPairToKey,
} from "/@/modules/workReport";
import { useZodScheme } from "/@/vue/composables";
import { CheckForm } from "/@/vue/components/Atom";
import { SimpleModal } from "/@/vue/components/Molecules";
import { WorkReportForm } from "/@/vue/components/Organisms";
import {
  WorkCategory,
  Carrier,
  DateForCalendar,
  DateMap,
  OrganizationCategory,
  WorkReportClient,
  WorkTaskClient,
  WorkRecordEntryReportForm,
  WorkRecordEntryReportScheme,
  CategoryCarrierReportItemsMap,
  CategoryCarrierPair,
  CategoryCarrierKey,
  JpHolidays,
  hideMapKeys,
  HideMap,
  ReportItemForm,
  HideMapKey,
  HideMapBase,
  hideMapBaseKeys,
  HideMapConfigForm,
  HideMapConfig,
  FilterKey,
  BasisReportItemClient,
} from "/@/types";

import ResultsTableBodyTr from "./ResultsTableBodyTr.vue";
import ResultsTableBodyWorkTaskTr from "./ResultsTableBodyWorkTaskTr.vue";
import ResultsTableBodyTotalTr from "./ResultsTableBodyTotalTr.vue";
import ReportItemsHeaderTop from "./ReportItemsHeaderTop.vue";
import ReportItemsHeaderBottom from "./ReportItemsHeaderBottom.vue";
import SaveShowingConfig from "./SaveShowingConfig.vue";

const props = withDefaults(
  defineProps<{
    tableTitle: string;
    dateMap: DateMap;
    categories: OrganizationCategory[];
    workReports: WorkReportClient[];
    workTasks: WorkTaskClient[]; // contracted
    basisReportItems: BasisReportItemClient[];
    categoryCarrierPairs: CategoryCarrierPair[];
    selectedFilter: FilterKey;
    selectedRequestOrganizationId?: number;
    ownerMode?: boolean;
    showEditMode?: boolean;
    shorterMode?: boolean;
    showCustomerReport?: boolean;
    showOnlyWorkedDays?: boolean;
    hideMapConfigs: HideMapConfig[];
    isOwner?: boolean;
    showRequestOrganization?: boolean;
    jpHolidays?: JpHolidays;
  }>(),
  {
    ownerMode: false,
    selectedRequestOrganizationId: undefined,
    jpHolidays: undefined,
  }
);

const emit = defineEmits<{
  (e: "updateWorkReport", workReport: WorkRecordEntryReportForm): void;
  (e: "saveHideMapConfig", config: HideMapConfigForm): void;
  (e: "destroyHideMapConfig", config: HideMapConfig): void;
  (e: "exitHideMapConfig"): void;
}>();

const filteredHideMapBaseKeys = computed(() => {
  switch (props.selectedFilter) {
    case "requestOrganization":
      return hideMapBaseKeys;
    case "member":
      return hideMapBaseKeys.filter(
        (k) => !["workerNumber", "workerNames"].includes(k)
      );
    default:
      return [];
  }
});

const dates = computed<DateForCalendar[]>(() => {
  if (!props.dateMap) {
    return [];
  }

  return createDateForCalendars(props.dateMap);
});

const lastMonthMap = computed(() => {
  return prevMonth(props.dateMap);
});

function optionCreator(
  k: { key: CategoryCarrierKey; category: WorkCategory; carrier: Carrier },
  options: {
    individual?: boolean;
    overall?: boolean;
    individualCustomers?: boolean;
    overallCustomers?: boolean;
  }
) {
  const startOfMonth = getStartEndFromMap(props.dateMap).start;

  return {
    workTypeCategories: ["telecommunications"] as OrganizationCategory[],
    workCarrier: k.carrier,
    workCategory: k.category,
    requestOrganizationId: props.selectedRequestOrganizationId,
    now: startOfMonth,
    isMonthly: true,
    ...options,
  };
}

const existsCCKs = computed(() => {
  const keys = props.categoryCarrierPairs.map((p) => ({
    ...p,
    key: categoryCarrierPairToKey(p),
  }));

  return _.sortBy(keys, "key");
});

const visibleBasisReportItems = computed(() => {
  const now = getStartEndFromMap(props.dateMap).start;

  return props.basisReportItems.filter((item) =>
    isShowItem(item, { now, isMonthly: true })
  );
});

const customersItems = computed<CategoryCarrierReportItemsMap>(() => {
  let m: CategoryCarrierReportItemsMap = {};

  if (!props.showCustomerReport) {
    return m;
  }

  const options = { individualCustomers: true };

  existsCCKs.value.forEach((k) => {
    m[k.key] = getReportItemsNew(
      visibleBasisReportItems.value,
      optionCreator(k, options)
    ).map((item) => basisToReport(item, options));
  });

  return m;
});

const indiviItems = computed<CategoryCarrierReportItemsMap>(() => {
  let m: CategoryCarrierReportItemsMap = {};

  const options = { individual: true };

  existsCCKs.value.forEach((k) => {
    m[k.key] = getReportItemsNew(
      visibleBasisReportItems.value,
      optionCreator(k, options)
    ).map((item) => basisToReport(item, options));
  });

  return m;
});

const overallItems = computed<CategoryCarrierReportItemsMap>(() => {
  let m: CategoryCarrierReportItemsMap = {};

  const options = { overall: true };

  existsCCKs.value.forEach((k) => {
    m[k.key] = getReportItemsNew(
      visibleBasisReportItems.value,
      optionCreator(k, options)
    ).map((item) => basisToReport(item, options));
  });

  return m;
});

const targetMonthReports = computed(() => {
  return props.workReports.filter((report) =>
    isSameMonth(props.dateMap, report.targetDate)
  );
});

const targetMonthTasks = computed(() => {
  return props.workTasks.filter((task) => {
    if (!task.contractedAt) {
      throw new Error("unexpect uncontracted task.");
    }

    return isSameMonth(props.dateMap, task.contractedAt);
  });
});

// show map

const hideMapBase = ref<HideMapBase>({
  requestOrganization: false,
  workPlace: false,
  workerNumber: false,
  workerNames: false,
});

type HideMapArray = Partial<
  Record<CategoryCarrierKey, Partial<Record<HideMapKey, ReportItemForm[]>>>
>;

function createHideMapArray(
  existsCCKs: CategoryCarrierKey[],
  customersItems: CategoryCarrierReportItemsMap,
  indiviItems: CategoryCarrierReportItemsMap,
  overallItems: CategoryCarrierReportItemsMap
): HideMapArray {
  let m: HideMapArray = {};

  existsCCKs.forEach((cck) => {
    m[cck] = {};

    // @ts-ignore
    m[cck].customers = customersItems[cck];
    // @ts-ignore
    m[cck].indivi = indiviItems[cck];
    // @ts-ignore
    m[cck].overall = overallItems[cck];
  });

  return m;
}

const hideMapArray = computed<HideMapArray>(() => {
  return createHideMapArray(
    existsCCKs.value.map((cck) => cck.key),
    customersItems.value,
    indiviItems.value,
    overallItems.value
  );
});

const hideMap = ref<HideMap>({});

function updateHideMap(
  cck: CategoryCarrierKey,
  hkey: HideMapKey,
  idx: number,
  val: boolean
) {
  if (!hideMap.value) {
    hideMap.value = {};
  }

  if (!hideMap.value[cck]) {
    // @ts-ignore
    hideMap.value[cck] = {};
  }

  // @ts-ignore
  if (!hideMap.value[cck][hkey]) {
    // @ts-ignore
    hideMap.value[cck][hkey] = {};
  }

  // @ts-ignore
  hideMap.value[cck][hkey][idx] = val;
}

watch(
  () => props.workReports,
  () => {
    hideMap.value = {};
  }
);

function loadHideMapConfig(config: HideMapConfig) {
  hideMapBase.value = config.config_map.hide_map_base;
  hideMap.value = config.config_map.hide_map;
}

function saveHideMapConfig(name: string) {
  emit("saveHideMapConfig", {
    name,
    configMap: { hideMap: hideMap.value, hideMapBase: hideMapBase.value },
  });
}

function destroyHideMapConfig(config: HideMapConfig) {
  emit("destroyHideMapConfig", config);
}

// edit modal

const editWorkReportModal = ref();
const selectedWorkReport = ref<WorkReportClient>();

const { useFormAndErrors } = useZodScheme();
const { form, startValidation } = useFormAndErrors<WorkRecordEntryReportForm>(
  WorkRecordEntryReportScheme
);

function openEditWorkReportModal(dateKey: string) {
  selectedWorkReport.value = props.workReports.find((wr) =>
    isSameDate(wr.targetDate, dateKey)
  );

  if (!selectedWorkReport.value) {
    return;
  }

  form.id = selectedWorkReport.value.id;
  form.reportType = selectedWorkReport.value.reportType;
  form.reportItems = selectedWorkReport.value.reportItems;

  editWorkReportModal.value.openModal();
}

function closeEditWorkReportModal() {
  form.reportItems = [];
  editWorkReportModal.value.closeModal();
}

const loading = ref(false);

async function handleUpdate() {
  if (!selectedWorkReport.value) {
    throw new Error(
      "予期せぬエラーが発生しました。selectedWorkReport が空です"
    );
  }

  try {
    startValidation.value = true;
    loading.value = true;

    const workReport = WorkRecordEntryReportScheme.parse(form);
    workReport.id = selectedWorkReport.value?.id;
    emit("updateWorkReport", workReport);
  } catch (e) {
    errorHandle(e);
  }

  closeEditWorkReportModal();
  loading.value = false;
}
</script>

<style scoped lang="scss">
.table-wrapper {
  max-height: 80vh;
  overflow-y: scroll;
}

.th-day {
  width: 50px;
}

.cell-request-organization {
  max-width: 50px;
  min-width: 50px;
}

.cell-work-place {
  width: 50px;
  max-width: 50px;
}

.cell-work-category {
  max-width: 50px;
  min-width: 50px;
}

.cell-work-number {
  max-width: 40px;
  min-width: 40px;
}

.cell-worker-names {
  max-width: 30px;
  min-width: 30px;
}

th.sticky:nth-child(2) {
  left: 40.1px;
}

th.sticky:nth-child(3) {
  left: 80.2px;
}

tr.sticky:nth-child(2),
tr.sticky:nth-child(3) {
  th {
    top: 20.2px;
  }
}
</style>
