export const basisReportItems = [
  {
    id: 1,
    organizationId: null,
    name: "キャッチ",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 1,
        basisReportItemId: 1,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: "2023-12-09T15:00:00.000Z",
        validTo: "2023-12-15T15:00:00.000Z",
        visible: true,
        order: null,
        individualCustomers: true,
        workCategories: ["event_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 24,
        basisReportItemId: 1,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individualCustomers: true,
        workCategories: ["event_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 119,
        basisReportItemId: 1,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individualCustomers: true,
        workCategories: ["fulltime"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 142,
        basisReportItemId: 1,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individualCustomers: true,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 2,
    organizationId: null,
    name: "応対数",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 2,
        basisReportItemId: 2,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individualCustomers: true,
        workCategories: ["event_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 25,
        basisReportItemId: 2,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individualCustomers: true,
        workCategories: ["event_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 46,
        basisReportItemId: 2,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individualCustomers: true,
        workCategories: ["shop_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 63,
        basisReportItemId: 2,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individualCustomers: true,
        workCategories: ["shop_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 81,
        basisReportItemId: 2,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individualCustomers: true,
        workCategories: ["rounder"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 103,
        basisReportItemId: 2,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individualCustomers: true,
        workCategories: ["rounder"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 120,
        basisReportItemId: 2,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individualCustomers: true,
        workCategories: ["fulltime"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 143,
        basisReportItemId: 2,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individualCustomers: true,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 169,
        basisReportItemId: 2,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individualCustomers: true,
        workCategories: ["consulting"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 3,
    organizationId: null,
    name: "着座数",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 3,
        basisReportItemId: 3,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individualCustomers: true,
        workCategories: ["event_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 26,
        basisReportItemId: 3,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individualCustomers: true,
        workCategories: ["event_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 121,
        basisReportItemId: 3,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individualCustomers: true,
        workCategories: ["fulltime"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 144,
        basisReportItemId: 3,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individualCustomers: true,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 4,
    organizationId: null,
    name: "PI(プレミア)",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: false,
    order: 0,
    configs: [
      {
        id: 4,
        basisReportItemId: 4,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 20,
        basisReportItemId: 4,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 47,
        basisReportItemId: 4,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 82,
        basisReportItemId: 4,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 122,
        basisReportItemId: 4,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 138,
        basisReportItemId: 4,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 170,
        basisReportItemId: 4,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["consulting"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 5,
    organizationId: null,
    name: "PI(OCN)",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: false,
    order: 0,
    configs: [
      {
        id: 5,
        basisReportItemId: 5,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: false,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 21,
        basisReportItemId: 5,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: false,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 48,
        basisReportItemId: 5,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: false,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 83,
        basisReportItemId: 5,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: false,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 123,
        basisReportItemId: 5,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: false,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 139,
        basisReportItemId: 5,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: false,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 171,
        basisReportItemId: 5,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: false,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["consulting"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 6,
    organizationId: null,
    name: "PI(eximo)",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 6,
        basisReportItemId: 6,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 22,
        basisReportItemId: 6,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 49,
        basisReportItemId: 6,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 84,
        basisReportItemId: 6,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 124,
        basisReportItemId: 6,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 140,
        basisReportItemId: 6,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 172,
        basisReportItemId: 6,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["consulting"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 7,
    organizationId: null,
    name: "PI(irumo)",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 7,
        basisReportItemId: 7,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 23,
        basisReportItemId: 7,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 50,
        basisReportItemId: 7,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 85,
        basisReportItemId: 7,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 125,
        basisReportItemId: 7,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 141,
        basisReportItemId: 7,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 173,
        basisReportItemId: 7,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["consulting"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 8,
    organizationId: null,
    name: "ドコモ光",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 8,
        basisReportItemId: 8,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 51,
        basisReportItemId: 8,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 86,
        basisReportItemId: 8,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 126,
        basisReportItemId: 8,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 174,
        basisReportItemId: 8,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["consulting"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 9,
    organizationId: null,
    name: "TVOP",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 9,
        basisReportItemId: 9,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 52,
        basisReportItemId: 9,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 87,
        basisReportItemId: 9,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 99,
        basisReportItemId: 9,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 127,
        basisReportItemId: 9,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 175,
        basisReportItemId: 9,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["consulting"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 187,
        basisReportItemId: 9,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["consulting"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 10,
    organizationId: null,
    name: "スカパー",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 10,
        basisReportItemId: 10,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 53,
        basisReportItemId: 10,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 88,
        basisReportItemId: 10,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 100,
        basisReportItemId: 10,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 128,
        basisReportItemId: 10,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 176,
        basisReportItemId: 10,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["consulting"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 188,
        basisReportItemId: 10,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["consulting"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 11,
    organizationId: null,
    name: "home5G",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 11,
        basisReportItemId: 11,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 54,
        basisReportItemId: 11,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 89,
        basisReportItemId: 11,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 129,
        basisReportItemId: 11,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 177,
        basisReportItemId: 11,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["consulting"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 12,
    organizationId: null,
    name: "GOLD",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 12,
        basisReportItemId: 12,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 55,
        basisReportItemId: 12,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 90,
        basisReportItemId: 12,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 130,
        basisReportItemId: 12,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 178,
        basisReportItemId: 12,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["consulting"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 13,
    organizationId: null,
    name: "R",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 13,
        basisReportItemId: 13,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 56,
        basisReportItemId: 13,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 91,
        basisReportItemId: 13,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 131,
        basisReportItemId: 13,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 179,
        basisReportItemId: 13,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["consulting"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 14,
    organizationId: null,
    name: "マイグレ",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 14,
        basisReportItemId: 14,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 57,
        basisReportItemId: 14,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 92,
        basisReportItemId: 14,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 132,
        basisReportItemId: 14,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 180,
        basisReportItemId: 14,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["consulting"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 15,
    organizationId: null,
    name: "爆アゲ",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 15,
        basisReportItemId: 15,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 58,
        basisReportItemId: 15,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 93,
        basisReportItemId: 15,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 133,
        basisReportItemId: 15,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 181,
        basisReportItemId: 15,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["consulting"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 16,
    organizationId: null,
    name: "d系",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 16,
        basisReportItemId: 16,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 59,
        basisReportItemId: 16,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 94,
        basisReportItemId: 16,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 134,
        basisReportItemId: 16,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 182,
        basisReportItemId: 16,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["consulting"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 17,
    organizationId: null,
    name: "アフィリエイト",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 17,
        basisReportItemId: 17,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 60,
        basisReportItemId: 17,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 78,
        basisReportItemId: 17,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 95,
        basisReportItemId: 17,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 135,
        basisReportItemId: 17,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 159,
        basisReportItemId: 17,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 183,
        basisReportItemId: 17,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["consulting"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 18,
    organizationId: null,
    name: "コーティング",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 18,
        basisReportItemId: 18,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 61,
        basisReportItemId: 18,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 96,
        basisReportItemId: 18,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 136,
        basisReportItemId: 18,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 184,
        basisReportItemId: 18,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["consulting"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 19,
    organizationId: null,
    name: "galaxy",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 19,
        basisReportItemId: 19,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 62,
        basisReportItemId: 19,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 97,
        basisReportItemId: 19,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 137,
        basisReportItemId: 19,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 185,
        basisReportItemId: 19,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["consulting"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 20,
    organizationId: null,
    name: "SMNP端末付",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 27,
        basisReportItemId: 20,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 64,
        basisReportItemId: 20,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 104,
        basisReportItemId: 20,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 145,
        basisReportItemId: 20,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 21,
    organizationId: null,
    name: "SMNPsim単",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 28,
        basisReportItemId: 21,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 65,
        basisReportItemId: 21,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 105,
        basisReportItemId: 21,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 146,
        basisReportItemId: 21,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 22,
    organizationId: null,
    name: "S新規",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 29,
        basisReportItemId: 22,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 66,
        basisReportItemId: 22,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 106,
        basisReportItemId: 22,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 147,
        basisReportItemId: 22,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 23,
    organizationId: null,
    name: "YMNP端末付",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 30,
        basisReportItemId: 23,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 67,
        basisReportItemId: 23,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 107,
        basisReportItemId: 23,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 148,
        basisReportItemId: 23,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 24,
    organizationId: null,
    name: "YMNPsim単",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 31,
        basisReportItemId: 24,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 68,
        basisReportItemId: 24,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 108,
        basisReportItemId: 24,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 149,
        basisReportItemId: 24,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 25,
    organizationId: null,
    name: "Y新規",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 32,
        basisReportItemId: 25,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 69,
        basisReportItemId: 25,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 109,
        basisReportItemId: 25,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 150,
        basisReportItemId: 25,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 26,
    organizationId: null,
    name: "UP",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 33,
        basisReportItemId: 26,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 42,
        basisReportItemId: 26,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 70,
        basisReportItemId: 26,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 110,
        basisReportItemId: 26,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 151,
        basisReportItemId: 26,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 163,
        basisReportItemId: 26,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 27,
    organizationId: null,
    name: "光",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 34,
        basisReportItemId: 27,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 43,
        basisReportItemId: 27,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 71,
        basisReportItemId: 27,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 98,
        basisReportItemId: 27,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 111,
        basisReportItemId: 27,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 116,
        basisReportItemId: 27,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 152,
        basisReportItemId: 27,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 164,
        basisReportItemId: 27,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 186,
        basisReportItemId: 27,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["consulting"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 28,
    organizationId: null,
    name: "光TV",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 35,
        basisReportItemId: 28,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 44,
        basisReportItemId: 28,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 72,
        basisReportItemId: 28,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 112,
        basisReportItemId: 28,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 117,
        basisReportItemId: 28,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 153,
        basisReportItemId: 28,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 165,
        basisReportItemId: 28,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 29,
    organizationId: null,
    name: "Air",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 36,
        basisReportItemId: 29,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 45,
        basisReportItemId: 29,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 73,
        basisReportItemId: 29,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 113,
        basisReportItemId: 29,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 118,
        basisReportItemId: 29,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 154,
        basisReportItemId: 29,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 166,
        basisReportItemId: 29,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 30,
    organizationId: null,
    name: "payC",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 37,
        basisReportItemId: 30,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 74,
        basisReportItemId: 30,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 114,
        basisReportItemId: 30,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 155,
        basisReportItemId: 30,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 31,
    organizationId: null,
    name: "payG",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 38,
        basisReportItemId: 31,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 75,
        basisReportItemId: 31,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 115,
        basisReportItemId: 31,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["rounder"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 156,
        basisReportItemId: 31,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 32,
    organizationId: null,
    name: "MNP端末付",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 39,
        basisReportItemId: 32,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 160,
        basisReportItemId: 32,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 33,
    organizationId: null,
    name: "MNPsim単",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 40,
        basisReportItemId: 33,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 161,
        basisReportItemId: 33,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 34,
    organizationId: null,
    name: "新規",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 41,
        basisReportItemId: 34,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 162,
        basisReportItemId: 34,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 35,
    organizationId: null,
    name: "設定サポ",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 76,
        basisReportItemId: 35,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 157,
        basisReportItemId: 35,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 36,
    organizationId: null,
    name: "HELPO",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 77,
        basisReportItemId: 36,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["shop_helper"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 158,
        basisReportItemId: 36,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["fulltime"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 37,
    organizationId: null,
    name: "研修数",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 79,
        basisReportItemId: 37,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individualCustomers: true,
        workCategories: ["rounder"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 101,
        basisReportItemId: 37,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individualCustomers: true,
        workCategories: ["rounder"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 167,
        basisReportItemId: 37,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individualCustomers: true,
        workCategories: ["consulting"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 38,
    organizationId: null,
    name: "OJT三角接客",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 80,
        basisReportItemId: 38,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individualCustomers: true,
        workCategories: ["rounder"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
      {
        id: 102,
        basisReportItemId: 38,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individualCustomers: true,
        workCategories: ["rounder"],
        workCarriers: ["softbank"],
        requestOrganizationIds: [],
      },
      {
        id: 168,
        basisReportItemId: 38,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individualCustomers: true,
        workCategories: ["consulting"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 39,
    organizationId: null,
    name: "光新規",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 189,
        basisReportItemId: 39,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["light_ad"],
        workCarriers: ["yahoo"],
        requestOrganizationIds: [],
      },
      {
        id: 196,
        basisReportItemId: 39,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["light_ad"],
        workCarriers: ["yahoo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 40,
    organizationId: null,
    name: "光転用",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 190,
        basisReportItemId: 40,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["light_ad"],
        workCarriers: ["yahoo"],
        requestOrganizationIds: [],
      },
      {
        id: 197,
        basisReportItemId: 40,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["light_ad"],
        workCarriers: ["yahoo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 41,
    organizationId: null,
    name: "光事業者変更",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 191,
        basisReportItemId: 41,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["light_ad"],
        workCarriers: ["yahoo"],
        requestOrganizationIds: [],
      },
      {
        id: 198,
        basisReportItemId: 41,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["light_ad"],
        workCarriers: ["yahoo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 42,
    organizationId: null,
    name: "光サービス変更",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 192,
        basisReportItemId: 42,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["light_ad"],
        workCarriers: ["yahoo"],
        requestOrganizationIds: [],
      },
      {
        id: 199,
        basisReportItemId: 42,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["light_ad"],
        workCarriers: ["yahoo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 43,
    organizationId: null,
    name: "Air新規",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 193,
        basisReportItemId: 43,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["light_ad"],
        workCarriers: ["yahoo"],
        requestOrganizationIds: [],
      },
      {
        id: 200,
        basisReportItemId: 43,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["light_ad"],
        workCarriers: ["yahoo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 44,
    organizationId: null,
    name: "Air機変",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 194,
        basisReportItemId: 44,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["light_ad"],
        workCarriers: ["yahoo"],
        requestOrganizationIds: [],
      },
      {
        id: 201,
        basisReportItemId: 44,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["light_ad"],
        workCarriers: ["yahoo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 45,
    organizationId: null,
    name: "Airサービス変更",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 195,
        basisReportItemId: 45,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: true,
        overall: false,
        customers: false,
        workCategories: ["light_ad"],
        workCarriers: ["yahoo"],
        requestOrganizationIds: [],
      },
      {
        id: 202,
        basisReportItemId: 45,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["light_ad"],
        workCarriers: ["yahoo"],
        requestOrganizationIds: [],
      },
    ],
  },
  {
    id: 46,
    organizationId: null,
    name: "D/EV 全体 only request organiaziton test",
    shortName: null,
    unit: "件",
    unitRange: 1,
    validFrom: null,
    validTo: null,
    visible: true,
    order: 0,
    configs: [
      {
        id: 203,
        basisReportItemId: 46,
        name: null,
        shortName: null,
        unit: null,
        unitRange: null,
        validFrom: null,
        validTo: null,
        visible: true,
        order: null,
        individual: false,
        overall: true,
        customers: false,
        workCategories: ["event_helper"],
        workCarriers: ["docomo"],
        requestOrganizationIds: [9],
      },
    ],
  },
];
