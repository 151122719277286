<template>
  <div id="session-sign-up">
    <div v-if="!loading" class="infos">
      <MessageList
        :messages="responseMessage.messages"
        :status="responseMessage.status"
      />
      <SignUpForm v-if="!hideForm" :loading="loading" @signup="signup" />
    </div>
    <LoadingAnimation v-else />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, reactive } from "vue";
import { useAuthorize, useRouterUtil } from "/@/vue/composables";
import { LoadingAnimation, MessageList } from "/@/vue/components/Atom";
import { SignUpForm } from "/@/vue/components/Organisms";
import { MessageStatus, SingUpResponse, User } from "/@/types";

// core

const loading = ref<boolean>(false);
const responseMessage = reactive<{
  messages: Array<string>;
  status?: MessageStatus;
}>({
  messages: [],
});

const { signup: userSignup } = useAuthorize();
const { goto } = useRouterUtil();
const signUpResult = ref<SingUpResponse>();

const hideForm = computed<boolean>(() => {
  return (
    signUpResult.value?.status == "confirm" ||
    signUpResult.value?.status == "success"
  );
});

async function signup(userForm: User) {
  loading.value = true;
  signUpResult.value = await userSignup(userForm);

  switch (signUpResult.value.status) {
    case "confirm":
      responseMessage.messages = [
        "アカウントを作成し認証用のメールを送信しました。10分以内にメールを認証して下さい。",
      ];
      responseMessage.status = "success";
      break;
    case "error":
      responseMessage.messages = ["アカウント作成に失敗しました。"];
      responseMessage.status = "error";
      break;
    case "success":
      goto({ name: "Home" });
  }

  loading.value = false;
}
</script>
