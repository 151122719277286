import { InjectionKey } from "vue";
import { createStore, useStore as baseUseStore, Store } from "vuex";
//import createPersistedState from "vuex-persistedstate";
import { userState } from "./user";
import { adminState } from "./admin";
import type { UserStoreState } from "./user";
import type { AdminStoreState } from "./admin";

export type RootState = {
  user: UserStoreState;
  admin: AdminStoreState;
};

export function createStoreWith() {
  const s = {
    modules: {
      user: userState,
      admin: adminState,
    },
  };

  return createStore(s);
}

export const key: InjectionKey<Store<RootState>> = Symbol();

export function useStore() {
  return baseUseStore(key);
}

/*
plugins: [
      window.navigator.cookieEnabled &&
        createPersistedState({
          key: "order-system",
          storage: window.sessionStorage,
        }),
    ].filter((b) => b),
*/
