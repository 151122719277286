import { dateMapToISO } from "./luxon";
import { DateMap, LaborInfoForm } from "/@/types";

export function laborInfoUpdater(
  form: LaborInfoForm,
  prms: {
    key: keyof LaborInfoForm;
    val: any;
  }
) {
  const { key, val } = prms;

  if (key === "targetDate" && (typeof val === "string" || val === undefined)) {
    form[key] = val;
  } else if (
    key !== "targetDate" &&
    (typeof val === "number" || val === undefined)
  ) {
    form[key] = val || "" ? parseInt(val) : 0;
  } else {
    throw new Error(`no match updateFormItem`);
  }
}

export function isPrescribedWorkingDaysCase(prms: {
  key: keyof LaborInfoForm;
  val: number | string | undefined;
}) {
  return prms.key === "prescribedWorkingDays" && typeof prms.val === "number";
}

export function createDefaultLaborInfoForm(prms: {
  organizationMemberId?: number;
  selectedDate?: DateMap;
  daysInMonth?: number;
}): LaborInfoForm {
  const { organizationMemberId, selectedDate, daysInMonth } = prms;

  const m = {
    targetDate: selectedDate ? dateMapToISO(selectedDate) : undefined,
    prescribedWorkingDays: daysInMonth ? daysInMonth - 10 : 0,
    publicHolidays: 10,
    workScheduleDays: daysInMonth ? daysInMonth - 10 : 0,
    holidayScheduledDays: 0,
    specialLeaveDays: 0,
    plannedPaidHolidays: 0,
  };

  if (organizationMemberId) {
    return {
      organizationMemberId,
      ...m,
    };
  }

  return m;
}
