<template>
  <div id="labor-infor-show">
    <OrganizationSelector
      v-model:selected-organization-id="selectedOrganizationId"
      class="mb-3"
      :organizations="organizationsData || []"
    />

    <span v-if="notAllowedOrganizationAcess" class="text-danger">
      権限がありません
    </span>
    <template v-else>
      <GroupToggler
        v-model:selected="selectedShowType"
        :toggle-types="showTypes"
        class="mb-3"
        form-id="show-type-toggler"
      />

      <template v-if="selectedOrganization && selectedShowType === 'member'">
        <div class="d-flex align-items-center flex-wrap w-100 mb-3">
          <div class="me-2 mb-2">
            <ContentLabel label="メンバー" />
            <ObjectSelector
              :value="selectedOrganizationMemberId"
              :items="organizationMembers || []"
              value-key="id"
              :show-fn="(member: OrganizationMember) => memberShowFn(member, selectedOrganization, subContractors)"
              form-id="select-organization-member"
              :is-valid="!!selectedOrganizationMemberId"
              @update:value="selectedOrganizationMemberId = $event?.id"
            />
          </div>
          <div class="mb-2">
            <ContentLabel label="年" />
            <GeneralSelector
              v-model:value="selectedYear"
              :items="years"
              is-valid
            />
          </div>
        </div>

        <template v-if="selectedOrganizationMember">
          <div class="">
            <table class="table table-sm table-bordered table-striped">
              <thead>
                <tr>
                  <th>社員番号</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span>{{ selectedOrganizationMember.code }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>

        <div class="d-flex mb-3">
          <MenuButton :menu-items="menuItems" />
        </div>

        <LaborInfoTableDates
          v-if="selectedOrganizationMember"
          :year="selectedYear"
          :member="selectedOrganizationMember"
          :edit-mode="editMode"
          @update-labor-infos="handleUpdateLaborInfos"
        />
      </template>
      <template
        v-else-if="selectedOrganization && selectedShowType === 'month'"
      >
        <div class="d-flex align-items-center my-3">
          <SelectDate
            v-model:year="selectedDate.year"
            v-model:month="selectedDate.month"
            v-model:day="selectedDate.day"
            :exists-dates="existsDates"
            only-year-month
            disabled-auto-select
            class="me-2"
          />

          <div v-if="selectedDate.month" class="mb-2">
            {{ selectedDate.month }}月の日数：{{ daysInMonth }}日
          </div>
        </div>

        <div class="d-flex mb-3">
          <MenuButton :menu-items="menuItems" />
        </div>

        <div v-if="selectedDate.month" class="table-wrapper">
          <LaborInfoTableMembers
            v-if="organizationMembers"
            :members="organizationMembers"
            :selected-date="selectedDate"
            :edit-mode="editMode"
            @update-labor-infos="handleUpdateLaborInfos"
          />
        </div>
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";
import { computed, ref, reactive, watch } from "vue";
import { getStorage, setStorage } from "/@/modules/localStorage";
import {
  createIsoRange,
  getDaysCountInMonth,
  luxonNow,
} from "/@/modules/luxon";
import { memberShowFn } from "/@/modules/organizationMember";
import {
  useRouterUtil,
  useOrganization,
  useOrganizationMember,
  useLaborInfo,
} from "/@/vue/composables";
import {
  ContentLabel,
  ObjectSelector,
  GeneralSelector,
  SelectDate,
} from "/@/vue/components/Atom";
import { GroupToggler, MenuButton } from "/@/vue/components/Molecules";
import {
  LaborInfoTableMembers,
  LaborInfoTableDates,
  OrganizationSelector,
} from "/@/vue/components/Organisms";
import type { OrganizationMember, LaborInfoForm, DateMap } from "/@/types";

// show types

type ShowType = "member" | "month";
type ToggleItem = { name: string; key: ShowType };

const selectedShowType = ref<ShowType>(
  getStorage("LaborInfosShowSelectedShowType") || "month"
);
watch(selectedShowType, (newV) => {
  setStorage("LaborInfosShowSelectedShowType", newV);
});

const showTypes: ToggleItem[] = [
  { name: "月毎", key: "month" },
  { name: "メンバー毎", key: "member" },
];

// menu

const menuItems = computed(() => {
  return [
    {
      label: editMode.value ? "閲覧モード" : "編集モード",
      icon: editMode.value ? "pi pi-file " : "pi pi-file-edit",
      style: {
        "background-color": editMode.value ? "var(--cyan-200)" : "inherit",
      },
      command: () => {
        editMode.value = !editMode.value;
      },
    },
  ];
});

const editMode = ref<boolean>(false);

// organization

const { query, goto } = useRouterUtil();

const {
  getOrganizations,
  organizations,
  isOrganizationOwner,
  selectedOrganizationId,
  selectedOrganization,
  subContractors,
} = useOrganization();
const { data: organizationsData } = getOrganizations();

watch(organizationsData, (os) => {
  organizations.value = os || [];
});

const notAllowedOrganizationAcess = computed(() => {
  return (
    !organizations.value ||
    !selectedOrganization.value ||
    !isOrganizationOwner.value
  );
});

// month

const today = luxonNow();
const selectedDate = reactive<DateMap>({
  year: getStorage("LaborInfosShowSelectedDateYear") || today.year,
  month: getStorage("LaborInfosShowSelectedDateMonth") || today.month,
  day: undefined,
});

watch(selectedDate, (newV) => {
  setStorage("LaborInfosShowSelectedDateYear", newV.year);
  setStorage("LaborInfosShowSelectedDateMonth", newV.month);
});

const daysInMonth = computed(() => {
  return getDaysCountInMonth(selectedDate);
});

const existsDates = computed(() => {
  return createIsoRange("2023-01-01", "2100-12-31", "month");
});

const selectedYear = ref<number>(today.year);
const years = _.range(2023, 2100, 1);

// member

const { getOrganizationMembers } = useOrganizationMember();
const { data: organizationMembers, mutate: getOrganizationMembersMutate } =
  getOrganizationMembers(
    selectedOrganization,
    isOrganizationOwner,
    selectedDate,
    { withLaborInfo: true }
  );

const selectedOrganizationMemberId = ref<number | undefined>(
  getStorage("LaborInfosShowSelectedMemberId")
);
watch(selectedOrganizationMemberId, (newV) => {
  setStorage("LaborInfosShowSelectedMemberId", newV);
});

const selectedOrganizationMember = computed(() => {
  if (!selectedOrganizationMemberId.value || !organizationMembers.value) {
    return;
  }

  return organizationMembers.value.find(
    (om) => om.id == selectedOrganizationMemberId.value
  );
});

// api

const { updateLaborInfos } = useLaborInfo();

const loading = ref<boolean>(false);

async function handleUpdateLaborInfos(forms: LaborInfoForm[]) {
  if (!selectedOrganization.value) {
    return;
  }

  loading.value = true;

  if (await updateLaborInfos(selectedOrganization.value, forms)) {
    alert("更新に成功しました");
    getOrganizationMembersMutate();
    editMode.value = false;
    goto({ name: "LaborInfosShow" });
  } else {
    alert("更新に失敗しました");
  }
}
</script>

<style scoped>
#labor-infor-show {
  height: 100vh;
}

.table-wrapper {
  flex-grow: 1;
}
</style>
