import { fromISO } from "/@/modules/luxon";
import {
  Organization,
  OrganizationMember,
  MemberType,
  ContractRelationOrganization,
  AbsenceApplicationPlan,
  LabelType,
  ManagementLabelClient,
} from "/@/types";

export function memberShowFn(
  member?: OrganizationMember,
  organization?: Organization,
  subContractors?: ContractRelationOrganization[],
  absenceApplicationPlans?: AbsenceApplicationPlan[] // targetDate と type が holiday であることを前提とする
) {
  let resultStr = "";

  if (!member) return resultStr;

  if (!organization || member.organizationId == organization.id) {
    resultStr = member.user.name;
  } else if (subContractors?.length) {
    const subCon = subContractors.find((sc) => sc.id === member.organizationId);

    resultStr = member.user.name + `(${subCon?.name})`;
  }

  if (absenceApplicationPlans) {
    const targetMemberPlan = absenceApplicationPlans.find(
      (p) => p.organizationMember.id === member.id
    );

    resultStr = targetMemberPlan ? resultStr + " 本日休暇申請済み" : resultStr;
  }

  return resultStr;
}

export function validMember(
  member: OrganizationMember,
  targetDateIso: string
): boolean {
  const targetDate = fromISO(targetDateIso);

  if (!targetDate) return false;

  const validFrom = member.validFrom && fromISO(member.validFrom);
  const validTo = member.validTo && fromISO(member.validTo);

  return (
    (!validFrom || validFrom <= targetDate) &&
    (!validTo || validTo >= targetDate)
  );
}

export function memberTypeTranslater(memberType: MemberType): string {
  switch (memberType) {
    case "own_organization":
      return "社内";
    case "outsourcing":
      return "外注";
    default:
      return "";
  }
}

export function existsLabel(
  labels: ManagementLabelClient[],
  labelType: LabelType,
  name?: string
) {
  return labels.some((label) => {
    return label.labelType === labelType && (!name || label.name === name);
  });
}

export function isFreelance(labels: ManagementLabelClient[]) {
  return existsLabel(labels, "labor", "フリーランス");
}

export function isDummyMember(member: OrganizationMember) {
  return (
    member.user.name.startsWith("free") ||
    member.user.name.startsWith("sub") ||
    member.user.name.startsWith("fryth")
  );
}

export function organizationMemberCompareFn(
  aid: number,
  bid: number,
  organizationMembers: OrganizationMember[],
  organization: Organization
) {
  const a = organizationMembers.find((om) => om.id === aid);
  const b = organizationMembers.find((om) => om.id === bid);

  if (!a || !b) return 0;

  if (a.organizationId === organization.id) {
    if (b.organizationId === organization.id) {
      return a.id - b.id;
    }

    return -1;
  }

  if (b.organizationId === organization.id) {
    return 1;
  }

  return a.id - b.id;
}
