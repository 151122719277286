<template>
  <template v-for="cck in categoryCarrierKeys" :key="`header-${cck}`">
    <th
      v-if="
        customersItems[cck] &&
        (showEditMode
          ? customersItems[cck].length
          : customersItems[cck].length - hideMapHideCount(cck, 'customers'))
      "
      :colspan="
        showEditMode
          ? customersItems[cck].length
          : customersItems[cck].length - hideMapHideCount(cck, 'customers')
      "
      :class="`bg-${categoryCarrierColor(
        cck
      )} text-white text-nowrap th-header-item`"
    >
      {{ categoryCarrierTranslater(cck, { shorter: shorterMode }) }}
      {{ shorterMode ? "応対" : "応対記録" }}
    </th>
    <th
      v-if="
        indiviItems[cck] &&
        (showEditMode
          ? indiviItems[cck].length
          : indiviItems[cck].length - hideMapHideCount(cck, 'indivi'))
      "
      :colspan="
        showEditMode
          ? indiviItems[cck].length
          : indiviItems[cck].length - hideMapHideCount(cck, 'indivi')
      "
      :class="`bg-${categoryCarrierColor(
        cck
      )} text-white text-nowrap th-header-item`"
    >
      {{ categoryCarrierTranslater(cck, { shorter: shorterMode }) }}
      {{ shorterMode ? "個人" : "個人結果" }}
    </th>
    <th
      v-if="
        overallItems[cck] &&
        (showEditMode
          ? overallItems[cck].length
          : overallItems[cck].length - hideMapHideCount(cck, 'overall'))
      "
      :colspan="
        showEditMode
          ? overallItems[cck].length
          : overallItems[cck].length - hideMapHideCount(cck, 'overall')
      "
      :class="`bg-${categoryCarrierColor(
        cck
      )} text-white text-nowrap th-header-item`"
    >
      {{ categoryCarrierTranslater(cck, { shorter: shorterMode }) }}
      {{ shorterMode ? "全体" : "全体結果" }}
    </th>
  </template>
</template>

<script setup lang="ts">
import { shorter } from "/@/modules/string";
import {
  categoryCarrierColor,
  categoryCarrierTranslater,
} from "/@/modules/workReport";
import {
  CategoryCarrierKey,
  CategoryCarrierReportItemsMap,
  HideMap,
  HideMapKey,
} from "/@/types";

const props = defineProps<{
  categoryCarrierKeys: CategoryCarrierKey[];
  customersItems: CategoryCarrierReportItemsMap;
  indiviItems: CategoryCarrierReportItemsMap;
  overallItems: CategoryCarrierReportItemsMap;
  hideMap: HideMap;
  showEditMode?: boolean;
  shorterMode?: boolean;
}>();

function hideMapHideCount(cck: CategoryCarrierKey, hmk: HideMapKey) {
  const hide = props.hideMap?.[cck]?.[hmk];

  if (!hide) {
    return 0;
  }

  return Object.values(hide).filter((b) => b).length;
}
</script>

<style scoped></style>
