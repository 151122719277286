<template>
  <div>
    <div class="mb-3"></div>
    <AdminWorkPlaceList
      :work-places="filteredWorkPlaces"
      :shop-owner-organizations="shopOwnerOrganizations"
      @update-work-place="handleUpdateWorkPlace"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useAdmin, useWorkPlaces } from "/@/vue/composables";
import { AdminWorkPlaceList } from "/@/vue/components/Organisms";
import { WorkPlaceUpdate } from "/@/types";

const { getOrganizations, getWorkPlaces } = useAdmin();
const { data: organizations } = getOrganizations();
const shopOwnerOrganizations = computed(() => {
  if (!organizations.value) return [];

  return organizations.value.filter((o) =>
    o.categories?.includes("shop_owner")
  );
});

const { data: workPlaces } = getWorkPlaces();

const filteredWorkPlaces = computed(() => {
  if (!workPlaces.value) return [];

  return workPlaces.value.filter(
    (wp) => !["自宅", "FRYTH事務所"].includes(wp.name)
  );
});

const { updateWorkPlace } = useWorkPlaces();

async function handleUpdateWorkPlace(prms: WorkPlaceUpdate) {
  if (await updateWorkPlace(prms)) {
    alert("更新に成功しました");
  } else {
    alert("更新に失敗しました");
  }
}
</script>

<style scoped></style>
