<template>
  <div>
    <ContentLabel label="本日の稼動" />
    <div class="card card-body">
      <template
        v-for="(member, idx) of sameOrganizationMembers"
        :key="`member-schedule-${member.id}`"
      >
        <TodayStatus
          :today-work-records="member.todayWorkRecords || []"
          :today-work-schedules="member.todayWorkSchedules || []"
          :member-id="member.id"
          :member-name="member.user.name"
          :class="{ 'mt-3': idx != 0 }"
        />
      </template>

      <template
        v-for="(subcon, subconIdx) of subContractors"
        :key="`sub-contractor-schedule-${subcon.id}`"
      >
        <div
          v-if="
            subcon.organizationMembers.some(
              (member) =>
                member.todayWorkRecords?.length ||
                member.todayWorkSchedules?.length
            )
          "
        >
          <ContentLabel :label="subcon.name" without-padding />
          <template
            v-for="(member, idx) of subcon.organizationMembers.filter(
              (m) => isAdmin || subconMembers.some((sm) => sm.id === m.id)
            )"
            :key="`subcon-${subcon.id}-member-schedule-${member.id}`"
          >
            <TodayStatus
              :today-work-records="member.todayWorkRecords || []"
              :today-work-schedules="member.todayWorkSchedules || []"
              :member-id="member.id"
              :member-name="member.userName"
            />
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { ContentLabel } from "/@/vue/components/Atom";
import type {
  OrganizationMember,
  ContractRelationOrganization,
} from "/@/types";

import TodayStatus from "./TodayStatus.vue";

const props = defineProps<{
  ownerOrganizationId?: number;
  members: OrganizationMember[];
  subContractors: ContractRelationOrganization[];
  isAdmin?: boolean;
}>();

const sameOrganizationMembers = computed(() => {
  return props.members.filter(
    (m) => m.organizationId === props.ownerOrganizationId
  );
});

const subconMembers = computed(() => {
  return props.members.filter(
    (m) => m.organizationId !== props.ownerOrganizationId
  );
});
</script>

<style lang="scss" scoped>
.entry-wrapper {
  padding-left: 1rem;
  border-left: 1px solid $main-gray-color;
}
</style>
