import { isSameDate } from "./luxon";
import type {
  OrganizationMemberSchedulePlan,
  ScheduleInOrganizationMember,
  PlanMap,
  WorkScheduleAsPlan,
  AbsenceApplicationPlan,
} from "/@/types";
import { schedulePlans } from "/@/vue/store/test.db";

export function getTargetDateMemberSchedules(
  schedulePlans: OrganizationMemberSchedulePlan[],
  memberId: number,
  targetDate: string
): ScheduleInOrganizationMember[] {
  return schedulePlans
    .filter(
      (sp) =>
        sp.organizationMemberId == memberId &&
        isSameDate(sp.targetDate, targetDate)
    )
    .map((sp) => sp.workSchedules)
    .flat();
}

export function getTargetDateMemberAbsence(
  absenceApplicationPlans: AbsenceApplicationPlan[],
  memberId: number,
  targetDate: string
): AbsenceApplicationPlan[] {
  return absenceApplicationPlans.filter(
    (plan) =>
      isSameDate(plan.targetDate, targetDate) &&
      plan.organizationMember.id === memberId
  );
}

export function getTargetDateWorkSchedules(
  workSchedules: WorkScheduleAsPlan[],
  workId: number,
  targetDate: string
): WorkScheduleAsPlan[] {
  return workSchedules.filter(
    (ws) => ws.work.id == workId && isSameDate(ws.targetDate, targetDate)
  );
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  it("createExistsDateMap", () => {
    expect(
      getTargetDateMemberSchedules(schedulePlans, 1, "2023-04-03").some(
        (schedule) => schedule.id == 23
      )
    ).toBeTruthy();
    expect(
      getTargetDateMemberSchedules(schedulePlans, 5, "2023-04-05").some(
        (schedule) => schedule.id == 25
      )
    ).toBeTruthy();
  });
}

export function getExistsDateFromPlanMap(planMap: PlanMap) {
  return [
    ...(planMap.absenceApplicationPlans?.map((p) => p.targetDate) || []),
    ...(planMap.organizationMemberSchedulePlans?.map((s) => s.targetDate) ||
      []),
    ...(planMap.workAsPlans?.map((p) => p.startOn) || []),
    ...(planMap.workScheduleAsPlans?.map((p) => p.targetDate) || []),
  ];
}

export function createAbsenceApplicationStatus(
  absencePlans: AbsenceApplicationPlan[]
) {
  const isHoliday = !!absencePlans.find(
    (plan) => plan.applicationType === "holiday"
  );

  const isHalfday = !!absencePlans.find(
    (plan) => plan.applicationType === "halfday"
  );

  const isPaid = !!absencePlans.find((plan) => plan.applicationType === "paid");

  return {
    isHoliday,
    isHalfday,
    isPaid,
  };
}
