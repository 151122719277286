<template>
  <div>
    <FormItemShow
      v-if="customerResultMap.hasNonemptyResult"
      :label="hideLabel ? '' : '応対記録'"
      content-id="nonoverall-reports"
    >
      <div class="d-flex flex-wrap">
        <template v-for="item of customerItems" :key="item.key">
          <span v-if="customerResultMap[item.key]" class="me-2 mb-2">
            {{ item.key }}
            {{ customerResultMap[item.key] }}
          </span>
        </template>
      </div>
    </FormItemShow>
    <FormItemShow
      v-if="individualResultMap.hasNonemptyResult"
      :label="hideLabel ? '' : '個人成績'"
      content-id="nonoverall-reports"
    >
      <div class="d-flex flex-wrap">
        <template v-for="item of individualItems" :key="item.key">
          <span v-if="individualResultMap[item.key]" class="me-2 mb-2">
            {{ item.key }}
            {{ individualResultMap[item.key] }}件
          </span>
        </template>
      </div>
    </FormItemShow>
    <div class="mb-2"></div>
    <FormItemShow
      v-if="overallResultMap.hasNonemptyResult"
      :label="hideLabel ? '' : '全体実績'"
      content-id="nonoverall-reports"
    >
      <div class="d-flex flex-wrap">
        <template v-for="item of overallItems" :key="item.key">
          <span v-if="overallResultMap[item.key]" class="me-2 mb-2">
            {{ item.key }}
            {{ overallResultMap[item.key] }}件
          </span>
        </template>
      </div>
    </FormItemShow>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { createReportItems, getReportItemsNew } from "/@/modules/workReport";
import { FormItemShow } from "/@/vue/components/Molecules";
import {
  Carrier,
  OrganizationCategory,
  ReportItemKey,
  WorkCategory,
  WorkTaskClient,
  ReportItem,
  BasisReportItemClient,
} from "/@/types";

const props = defineProps<{
  workReports: {
    reportItems: ReportItem[];
    [key: string]: any;
  }[];
  workTasks?: WorkTaskClient[];
  workTypeCategories: OrganizationCategory[];
  workCarrier: Carrier;
  workCategory: WorkCategory;
  requestOrganizationId?: number;
  basisReportItems: BasisReportItemClient[];
  hideLabel?: boolean;
}>();

const optionsBase = computed(() => {
  return {
    workTypeCategories: props.workTypeCategories,
    workCarrier: props.workCarrier,
    workCategory: props.workCategory,
    requestOrganizationId: props.requestOrganizationId,
  };
});

const customerItems = computed(() => {
  const options = {
    ...optionsBase.value,
    individualCustomers: true,
  };

  return createReportItems(
    getReportItemsNew(props.basisReportItems, options),
    options
  );
});

const individualItems = computed(() => {
  const options = {
    ...optionsBase.value,
    individual: true,
  };

  return createReportItems(
    getReportItemsNew(props.basisReportItems, options),
    options
  );
});

const overallItems = computed(() => {
  const options = {
    ...optionsBase.value,
    overall: true,
  };

  return createReportItems(
    getReportItemsNew(props.basisReportItems, options),
    options
  );
});

const reportItems = computed(() => {
  const workReports = props.workReports
    .map((wr) => wr.reportItems || wr.report_items) // TODO: editRequst 用のもの
    .flat();
  const taskReports =
    props.workTasks?.map((task) => task.reportItems).flat() || [];

  return workReports.concat(taskReports);
});

type ReportResultMap = Partial<
  Record<ReportItemKey, number> & { hasNonemptyResult: boolean }
>;

const customerResultMap = computed(() => {
  let m: ReportResultMap = {};

  let hasNonemptyResult = false;

  customerItems.value.forEach((item) => {
    const targets = reportItems.value.filter((reportItem) => {
      return (
        reportItem.basisReportItemId === item.basisReportItemId &&
        reportItem.customers
      );
    });

    const newCount = targets.reduce((acc, current) => acc + current.count, 0);

    if (newCount) {
      hasNonemptyResult = true;
    }

    // @ts-ignore m[item.key] is not undefined always
    m[item.key] = m[item.key] ? m[item.key] + newCount : newCount;
  });

  m["hasNonemptyResult"] = hasNonemptyResult;

  return m;
});

const individualResultMap = computed(() => {
  let m: ReportResultMap = {};
  let hasNonemptyResult = false;

  individualItems.value.forEach((item) => {
    const targets = reportItems.value.filter((reportItem) => {
      return (
        reportItem.basisReportItemId === item.basisReportItemId &&
        !reportItem.overall
      );
    });

    const newCount = targets.reduce((acc, current) => acc + current.count, 0);

    if (newCount) {
      hasNonemptyResult = true;
    }

    // @ts-ignore m[item.key] is not undefined always
    m[item.key] = m[item.key] ? m[item.key] + newCount : newCount;
  });

  m["hasNonemptyResult"] = hasNonemptyResult;

  return m;
});

const overallResultMap = computed(() => {
  let m: ReportResultMap = {};
  let hasNonemptyResult = false;

  overallItems.value.forEach((item) => {
    const targets = reportItems.value.filter(
      (reportItem) =>
        reportItem.basisReportItemId === item.basisReportItemId &&
        !!reportItem.overall
    );
    const newCount = targets.reduce((acc, current) => acc + current.count, 0);
    if (newCount) {
      hasNonemptyResult = true;
    }
    // @ts-ignore m[item.key] is not undefined always
    m[item.key] = m[item.key] ? m[item.key] + newCount : newCount;
  });

  m["hasNonemptyResult"] = hasNonemptyResult;

  return m;
});
</script>

<style scoped></style>
