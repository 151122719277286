<template>
  <div>
    <Accordion :active-index="0">
      <AccordionTab>
        <template #header>
          <div
            class="d-flex align-items-center flex-wrap"
            :class="{ 'p-invalid': !isValid }"
          >
            <div class="me-1">{{ taskName }}</div>
            <div v-if="workTask.actionAt" class="me-1">
              {{ basicFormatter(workTask.actionAt) }}
            </div>
            <div v-if="workTask.customerName" class="me-1">
              {{ workTask.customerName }}様
            </div>
            <div v-if="!workTask.actionAt" class="text-danger me-2">
              日にちを設定して下さい
            </div>
            <div v-if="emptyReport(workTask.reportItems)" class="text-danger">
              内容（件数）を入力して下さい
            </div>
          </div>
        </template>
        <div class="form-item-card">
          <GroupToggler
            v-if="!isContract"
            :selected="workTask.workTaskType"
            :toggle-types="
              workTaskTypes.map((t) => ({
                name: translater(t),
                key: t,
              }))
            "
            class="me-3 mb-2"
            :form-id="`${workTask.id || workTask.idx}`"
            @update:selected="
              $emit('updateWorkTask', {
                key: 'workTaskType',
                value: $event,
                idx: workTask.idx,
              })
            "
            @click="handlePropagation"
          />
          <div class="delete-btn">
            <BasicButton
              v-if="!singleEditMode"
              rounded
              small
              icon="pi pi-trash"
              variant="danger"
              @click="
                $emit(
                  'destroyWorkTask',
                  singleEditMode ? workTask.id : workTask.idx
                )
              "
            />
          </div>

          <template v-if="!isContract">
            <ContentLabel label="日時" />
            <div class="d-flex flex-row flex-wrap">
              <DateForm
                :value="workTask.actionAt"
                auto-apply
                class="me-2"
                teleport-center
                :is-valid="!!workTask.actionAt"
                :min-date="now.startOf('day')"
                @update:value="
                  $emit('updateWorkTask', {
                    key: 'actionAt',
                    value: $event,
                    idx: workTask.idx,
                  })
                "
              />
              <TimeForm
                :value="workTask.actionAt"
                class="mb-2"
                :env="envForTime"
                teleport-center
                :is-valid="!!workTask.actionAt"
                @update:value="
                  $emit('updateWorkTask', {
                    key: 'actionAt',
                    value: $event,
                    idx: workTask.idx,
                  })
                "
              />
            </div>
          </template>
          <template v-if="isContract || isOwner">
            <ContentLabel label="成約日" />
            <div class="d-flex flex-row flex-wrap mb-3">
              <DateForm
                :value="workTask.contractedAt"
                auto-apply
                class="me-2"
                teleport-center
                :max-date="now"
                is-valid
                @update:value="
                  $emit('updateWorkTask', {
                    key: 'contractedAt',
                    value: $event,
                    idx: workTask.idx,
                  })
                "
              />
            </div>
          </template>
          <div v-if="isOwner">
            <CheckForm
              :value="workTask.countInContractedDate"
              label="成約日加算"
              form-id="count-in-contracted-date-check"
              is-valid
              class="mb-3"
              @update:value="
                $emit('updateWorkTask', {
                  key: 'countInContractedDate',
                  value: $event,
                  idx: workTask.idx,
                })
              "
            />
          </div>

          <div v-if="!isContract" class="d-flex flex-wrap">
            <FormItem
              :value="workTask.customerName"
              label="お客様氏名(TKNK等のアルファベット)"
              form-id="customerName"
              form-type="text"
              :errors="errors"
              class="me-2 mb-2"
              @update:value="
                $emit('updateWorkTask', {
                  key: 'customerName',
                  value: $event,
                  idx: workTask.idx,
                })
              "
            />

            <FormItem
              :value="workTask.phoneNumber"
              label="電話番号(下4桁)"
              form-id="phoneNumber"
              form-type="text"
              :errors="errors"
              class="me-2 mb-2"
              @update:value="
                $emit('updateWorkTask', {
                  key: 'phoneNumber',
                  value: $event,
                  idx: workTask.idx,
                })
              "
            />
          </div>

          <WorkReportForm
            :report-items="workTask.reportItems || []"
            report-type="individual"
            :work-carrier="entry.workCarrier"
            :work-category="entry.workCategory"
            :work-type-categories="entry.workTypeCategories"
            :request-organization-id="entry.requestOrganizationId"
            :basis-report-items="basisReportItems"
            is-work-task
            @update:report-items="
              $emit('updateWorkTask', {
                key: 'reportItems',
                value: $event,
                idx: workTask.idx,
              })
            "
          />

          <div :class="`form-group mb-2`">
            <ContentLabel label="備考" :label-for="`port-in-remarks`" />
            <div class="d-flex flex-row align-items-center">
              <div class="me-2 w-100">
                <TextArea
                  :value="workTask.remarks"
                  is-valid
                  @update:value="
                    $emit('updateWorkTask', {
                      key: 'remarks',
                      value: $event,
                      idx: workTask.idx,
                    })
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </AccordionTab>
    </Accordion>
  </div>
</template>

<script setup lang="ts">
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import { computed } from "vue";
import { basicFormatter, fromISO, luxonNow } from "/@/modules/luxon";
import { translater } from "/@/modules/string";
import { emptyReport } from "/@/modules/workReport";
import { ZodFormattedErrors } from "/@/modules/zodUtils";
import {
  BasicButton,
  ContentLabel,
  DateForm,
  TimeForm,
  TextArea,
  CheckForm,
} from "/@/vue/components/Atom";
import { FormItem, GroupToggler } from "/@/vue/components/Molecules";
import { WorkReportForm } from "/@/vue/components/Organisms";
import {
  WorkTaskKey,
  WorkTaskWithIdxForm,
  workTaskTypes,
  Carrier,
  WorkCategory,
  OrganizationCategory,
  BasisReportItemClient,
} from "/@/types";

const props = defineProps<{
  workTask: WorkTaskWithIdxForm;
  entry: {
    workCarrier: Carrier;
    workCategory: WorkCategory;
    workTypeCategories: OrganizationCategory[];
    [key: string]: any;
  };
  basisReportItems: BasisReportItemClient[];
  taskName: string;
  errors: ZodFormattedErrors;
  singleEditMode?: boolean;
  isContract?: boolean;
  isOwner?: boolean;
  isValid?: boolean;
}>();

defineEmits<{
  (e: "destroyWorkTask", idxOrId: number): void;
  (
    e: "updateWorkTask",
    prms: { key: WorkTaskKey; value: any; idx: number }
  ): void;
}>();

const now = luxonNow().set({ minute: 0 });

const envForTime = computed(() => {
  if (!props.workTask.actionAt) {
    return;
  }

  const l = fromISO(props.workTask.actionAt);
  return {
    year: l.year,
    month: l.month,
    day: l.day,
  };
});

function handlePropagation(e: Event) {
  e.stopPropagation();
}
</script>

<style lang="scss" scoped>
.form-item-card {
  position: relative;

  .delete-btn {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }

  .number-form {
    width: 125px;
  }
}
</style>
