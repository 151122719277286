import zod from "zod";
import { DateTimeScheme, z } from "/@/modules/zodUtils";
import { UserScheme } from "/@/types/user.d";
import { WorkRecordClientScheme } from "/@/types/workRecord.d";
import { ScheduleInOrganizationMemberScheme } from "/@/types/plan.d";
import { OrganizationOwnerGroupRolesScheme } from "/@/types/organizationOwnerGroup.d";
import { LaborInfoClientScheme } from "/@/types/LaborInfo.d";
import { ManagementLabelClientScheme } from "/@/types/managementLabel.d";

export const organizationMemberRoles = [
  "owner",
  "subOwner",
  "worker",
  "developer",
  "designer",
] as const;

export const OrganizationMemberRolesScheme = z.enum(organizationMemberRoles);
export type OrganizationMemberRole = zod.infer<
  typeof OrganizationMemberRolesScheme
>;

export const OrganizationMemberLaborsScheme = z.object({
  targetMonth: DateTimeScheme,
  workingCount: z.number(),
  legalHolidayCount: z.number(),
});

export const OrganizationMemberScheme = z.object({
  id: z.number(),
  user: UserScheme,
  organizationId: z.number(),
  code: z.string().optional(),
  enteringOn: z.string().datetime({ offset: true }),
  validFrom: DateTimeScheme.optional(),
  validTo: DateTimeScheme.optional(),
  roles: OrganizationMemberRolesScheme.array(),
  labels: z.number().array(),
  needFinishReport: z.boolean(),
  needEnterLeaveReport: z.boolean(),
  submitLocationTime: z.string().optional(),
  hideFromSelect: z.boolean(),
  memberColor: z.string().optional(),
  todayWorkRecords: WorkRecordClientScheme.array().optional(),
  todayWorkSchedules: ScheduleInOrganizationMemberScheme.array().optional(),
  organizationOwnerGroups: z
    .object({
      groupId: z.number(),
      role: OrganizationOwnerGroupRolesScheme,
    })
    .array()
    .optional(),
  managementLabels: ManagementLabelClientScheme.array().optional(),
  fromOrganizationName: z.string().optional(),
  organizationMemberLabors: LaborInfoClientScheme.array().optional(),
});

const OrganizationMemberNeedOptionsScheme = z.union([
  z.literal("needFinishReport"),
  z.literal("needEnterLeaveReport"),
  z.literal("submitLocationTime"),
]);

export type OrganizationMemberNeedOptions = zod.infer<
  typeof OrganizationMemberNeedOptionsScheme
>;

export type OrganizationMember = zod.infer<typeof OrganizationMemberScheme>;

export const OrganizationMemberFormScheme =
  OrganizationMemberScheme.partial().pick({
    id: true,
    code: true,
    roles: true,
    labels: true,
    needEnterLeaveReport: true,
    needFinishReport: true,
    submitLocationTime: true,
    hideFromSelect: true,
    memberColor: true,
    organizationOwnerGroups: true,
  });

export type OrganizationMemberForm = zod.infer<
  typeof OrganizationMemberFormScheme
>;

// filter

export const memberTypes = ["own_organization", "outsourcing"] as const;
export const MemberTypeScheme = z.enum(memberTypes);
export type MemberType = zod.infer<typeof MemberTypeScheme>;
