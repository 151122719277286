<template>
  <div>
    <template v-if="customerItems.length && !isWorkTask">
      <div class="d-flex flex-row flex-wrap mb-3">
        <WorkReportFormItem
          v-for="item of customerItems"
          :key="item.key"
          :item="item"
          :basis-report-item="basisReportItemsMap[item.basisReportItemId]"
          :form-items="reportItems || []"
          class="me-2 mb-4"
          @update-item="updateReportItem"
        />
      </div>
    </template>
    <template v-if="showIndividualItems && individualItems.length">
      <ContentLabel
        :label="isWorkTask ? `内容` : `${isMeetingTargets ? `目標` : `実績`}`"
      />
      <span v-if="!isWorkTask && !isMeetingTargets" class="text-danger mb-3">
        予約の結果をここに加えないで下さい。
      </span>
      <div v-if="!isWorkTask && !isMeetingTargets" class="mb-3"></div>
      <div class="d-flex flex-row flex-wrap mb-3">
        <WorkReportFormItem
          v-for="item of individualItems"
          :key="item.key"
          :item="item"
          :form-items="reportItems || []"
          :basis-report-item="basisReportItemsMap[item.basisReportItemId]"
          class="me-2 mb-4"
          @update-item="updateReportItem"
        />
      </div>
    </template>

    <template v-if="showOverallItems && overallItems.length">
      <ContentLabel
        v-if="!isEdit && !isWorkTask && !isMeetingTargets"
        :label="`全体${isMeetingTargets ? `目標` : `実績`}`"
      />
      <div class="d-flex flex-row flex-wrap mb-3">
        <WorkReportFormItem
          v-for="item of overallItems"
          :key="item.key"
          :item="item"
          :basis-report-item="basisReportItemsMap[item.basisReportItemId]"
          :form-items="reportItems || []"
          class="me-2 mb-4"
          @update-item="updateReportItem"
        />
      </div>
    </template>
    <span
      v-else-if="
        reportType === 'overall' &&
        !isMeetingTargets &&
        !overallItems.length &&
        !overallCustomerItems.length
      "
    >
      全体実績の入力項目がありません
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import {
  contractNewItem,
  getReportItemsNew,
  createReportItems,
} from "/@/modules/workReport";
import { ContentLabel } from "/@/vue/components/Atom";
import {
  BasisReportItemClient,
  OrganizationCategory,
  ReportItemForm,
  ReportItemFilter,
  Carrier,
  WorkCategory,
  ReportType,
} from "/@/types";

import WorkReportFormItem from "./WorkReportFormItem.vue";

const props = defineProps<{
  reportItems?: ReportItemForm[];
  reportType?: ReportType;
  workTypeCategories: OrganizationCategory[];
  workCategory?: WorkCategory;
  workCarrier: Carrier;
  requestOrganizationId?: number;
  itemFilter?: ReportItemFilter[];
  isWorkTask?: boolean;
  isMeetingTargets?: boolean;
  isMeetingTargetsViewer?: boolean;
  isEdit?: boolean;
  basisReportItems: BasisReportItemClient[];
  now?: string;
}>();

const emit = defineEmits<{
  (e: "update:reportItems", items: ReportItemForm[]): void;
  (e: "update:reportType", type: ReportType): void;
}>();

const basisReportItemsMap = computed(() => {
  return props.basisReportItems.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {} as Record<number, BasisReportItemClient>);
});

const optionsBase = computed(() => {
  return {
    workTypeCategories: props.workTypeCategories,
    workCarrier: props.workCarrier,
    workCategory: props.workCategory,
    requestOrganizationId: props.requestOrganizationId,
    itemFilter: props.itemFilter,
    now: props.now,
  };
});

const individualCustomerItems = computed(() => {
  const options = {
    ...optionsBase.value,
    individualCustomers: true,
  };

  return createReportItems(
    getReportItemsNew(props.basisReportItems, options),
    options
  );
});

const overallCustomerItems = computed(() => {
  const options = {
    ...optionsBase.value,
    overallCustomers: true,
  };

  return createReportItems(
    getReportItemsNew(props.basisReportItems, options),
    options
  );
});

const customerItems = computed(() => {
  return props.reportType === "individual"
    ? individualCustomerItems.value
    : overallCustomerItems.value;
});

const individualItems = computed(() => {
  const options = {
    ...optionsBase.value,
    individual: true,
  };

  return createReportItems(
    getReportItemsNew(props.basisReportItems, options),
    options
  );
});

const overallItems = computed(() => {
  const options = {
    ...optionsBase.value,
    overall: true,
  };

  let reportItems = createReportItems(
    getReportItemsNew(props.basisReportItems, options),
    options
  );

  if (props.isMeetingTargets) {
    reportItems = reportItems.filter((item) => {
      return !individualItems.value.some(
        (i) => i.basisReportItemId === item.basisReportItemId
      );
    });
  }

  return reportItems;
});

// predicate

const showIndividualItems = computed(() => {
  const targetTypes: ReportType[] = ["individual"];
  return !props.reportType || targetTypes.includes(props.reportType);
});

const showOverallItems = computed(() => {
  const targetTypes: ReportType[] = ["overall"];
  return !props.reportType || targetTypes.includes(props.reportType);
});

// updater

function updateReportItem({
  key,
  count,
  overall,
  customers,
  basisReportItemId,
}: {
  key: string;
  count: number;
  overall?: boolean;
  customers?: boolean;
  basisReportItemId: number;
}) {
  emit(
    "update:reportItems",
    contractNewItem<ReportItemForm>(props.reportItems || [], {
      key,
      count,
      overall,
      customers,
      basisReportItemId,
    })
  );
}
</script>

<style scoped>
label {
  width: 100px;
}
.number-form {
  width: 120px;
}
</style>
