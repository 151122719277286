import useSWRV from "swrv";
import { axios, fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import type { ComputedRef, Ref } from "vue";
import type {
  DateMapForm,
  Organization,
  Work,
  WorkClient,
  WorkForm,
} from "/@/types";
import { LocationQuery } from "vue-router";

export function useWorks() {
  function getWorksKey(
    organization: Ref<Organization | undefined>,
    selectedDate?: Ref<DateMapForm>
  ) {
    if (!organization.value || !organization.value.id) {
      return null;
    }
    let apiUri = `/api/v1/organizations/${organization.value.id}/works`;

    if (selectedDate && selectedDate.value) {
      apiUri += `?year=${selectedDate.value.year}&month=${selectedDate.value.month}`;
    }

    return apiUri;
  }

  function getWorks(
    organization: Ref<Organization | undefined>,
    selectedDate?: Ref<DateMapForm>
  ) {
    return useSWRV<{ works: WorkClient[]; startOn: string; finishOn: string }>(
      () => getWorksKey(organization, selectedDate),
      fetcher
    );
  }

  function getWorkKey(query: ComputedRef<LocationQuery>) {
    if (!query.value || !query.value.id) {
      return null;
    }

    return `/api/v1/works/${query.value.id}`;
  }

  function getWork(query: ComputedRef<LocationQuery>) {
    return useSWRV<Work>(() => getWorkKey(query), fetcher);
  }

  async function createWork(work: WorkForm) {
    try {
      const { data }: { data: Work } = await axios.post(
        `/api/v1/organizations/${work.organizationId}/works`,
        {
          works: work,
        }
      );
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function editWork(work: WorkForm) {
    try {
      await axios.patch(`/api/v1/works/${work.id}`, {
        works: work,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function destroyWork(id: number) {
    try {
      await axios.delete(`/api/v1/works/${id}`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function cloneSchedule(prms: {
    workId: number;
    workTypeId: number;
    targetDate: string;
  }) {
    const { workId, workTypeId, targetDate } = prms;

    try {
      await axios.post(`/api/v1/works/${workId}/clone_schedule`, {
        works: {
          targetDate,
          workTypeId,
        },
      });

      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    getWork,
    getWorks,
    createWork,
    editWork,
    destroyWork,
    cloneSchedule,
  };
}
