<template>
  <div>
    <Dialog
      v-model:visible="modalVisible"
      header="情報の更新"
      modal
      dismissable-mask
      style="width: 90vw; max-width: 500px"
      @after-hide="closeModal"
    >
      <div v-if="selectedWorkPlace">
        <div class="mb-3">
          <ContentLabel label="選択した店舗" />
          <div>
            {{ selectedWorkPlace.name }}
          </div>
        </div>

        <div class="mb-3">
          <ContentLabel label="店舗名" />
          <FormItem
            v-model:value="form.name"
            label=""
            :errors="errors"
            form-id="name"
            form-type="text"
          ></FormItem>
        </div>

        <div class="mb-3">
          <ContentLabel label="省略名(未入力の場合は先頭3文字)" />
          <FormItem
            v-model:value="form.shortName"
            label=""
            :errors="errors"
            form-id="shortName"
            form-type="text"
          ></FormItem>
        </div>

        <Dropdown
          :model-value="form.organizationId"
          :options="organizations"
          option-label="name"
          option-value="id"
          placeholder="管理組織を選択"
          :form-id="`organizationId`"
          :class="{
            'p-invalid': !form.organizationId,
          }"
          filter
          auto-filter-focus
          empty-filter-message="候補が見つかりませんでした"
          @update:model-value="form.organizationId = $event"
        />

        <div class="d-flex justify-content-center pt-4">
          <CancelButton label="キャンセル" class="m-1" @click="closeModal" />
          <SubmitButton class="m-1" @click="handleSubmit" />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import Dropdown from "primevue/dropdown";
import { ref } from "vue";
import { useZodScheme } from "/@/vue/composables";
import { CancelButton, SubmitButton } from "/@/vue/components/Molecules";
import { ContentLabel } from "/@/vue/components/Atom";
import { FormItem } from "/@/vue/components/Molecules";
import {
  Organization,
  WorkPlace,
  WorkPlaceUpdate,
  WorkPlaceUpdateForm,
  WorkPlaceUpdateScheme,
} from "/@/types";
import { errorHandle } from "/@/modules/error";
import Dialog from "primevue/dialog";

const props = defineProps<{
  workPlaces: WorkPlace[];
  organizations: Organization[];
}>();

const emit = defineEmits<{
  (e: "submit", prms: WorkPlaceUpdate): void;
}>();

const loading = ref<boolean>(false);

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } = useFormAndErrors<WorkPlaceUpdateForm>(
  WorkPlaceUpdateScheme
);

function handleSubmit() {
  try {
    startValidation.value = true;
    const workPlaceUpdateParams: WorkPlaceUpdate =
      WorkPlaceUpdateScheme.parse(form);
    emit("submit", workPlaceUpdateParams);

    closeModal();
  } catch (e) {
    errorHandle(e);
  }
}

const selectedWorkPlace = ref<WorkPlace | undefined>();
const modalVisible = ref<boolean>(false);

function openModal(id: number) {
  selectedWorkPlace.value = props.workPlaces.find((wp) => wp.id === id);

  if (!selectedWorkPlace.value) {
    return;
  }

  form.id = selectedWorkPlace.value.id;
  form.name = selectedWorkPlace.value.name;
  form.shortName = selectedWorkPlace.value.shortName;
  form.organizationId = selectedWorkPlace.value.organizationId;

  modalVisible.value = true;
}

function closeModal() {
  selectedWorkPlace.value = undefined;
  modalVisible.value = false;
}

defineExpose({
  openModal,
  closeModal,
});
</script>

<style scoped></style>
