import Axios from "axios";
import type { Ref } from "vue";
import { ZodError } from "zod";

export function errorHandle(
  e: any,
  {
    errors,
    alertMessage,
    unexpected,
  }: {
    errors?: Ref<string[]>;
    alertMessage?: string;
    unexpected?: boolean;
  } = {}
) {
  console.error(e);

  if (alertMessage) {
    alert(alertMessage);
  } else if (unexpected) {
    alert(
      "予期せぬエラーが発生しました。ページのリロードを行いもう一度お試し下さい。"
    );
  }

  if (Axios.isAxiosError(e) && e.response) {
    if (e.response.status === 426) {
      window.alert("アップデートがあるためページを更新します");
      window.location.reload();
      return;
    }

    if (e.response.status === 404) {
      window.alert("リンクが見つかりませんでした。");
      return;
    }

    if (e.response.data.errors) {
      alert(`${e.response.data.errors}`);
    }

    if (e.response.data && e.response.data.errors && errors) {
      errors.value = e.response.data.errors;
      return e.response.data.errors;
    }
  }

  if (Axios.isAxiosError(e)) {
    return;
  }

  if (e instanceof ZodError) {
    if (errors) {
      errors.value = e.format()._errors;
    }
    alert("フォームの入力に問題があります");
    return e;
  }

  alert("予期せぬエラーが発生しました。");

  return [];
}
