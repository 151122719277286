<template>
  <div>
    <div v-for="item in items" :key="item.key" class="form-check">
      <input
        :id="`${formId}_${item.key}`"
        class="form-check-input"
        type="radio"
        name="formId"
        :value="item.value"
        :checked="checked(item.value)"
        @change="update"
      />
      <label class="form-check-label" :for="`${formId}_${item.key}`">
        {{ item.key }}
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
type Item = {
  value: any;
  key: string;
};

const props = defineProps<{
  value?: any;
  items: Item[];
  formId: string;
}>();

const emit = defineEmits<{
  (e: "update:value", value: any): void;
}>();

function checked(value: any) {
  return props.value === value;
}

function update(e: Event) {
  if (e.target) {
    emit("update:value", e.target.value);
  }
}
</script>

<style scoped></style>
