import useSWRV from "swrv";
import { Ref } from "vue";
import { axios, fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import {
  ManagementLabel,
  ManagementLabelClient,
  ManagementLabelConfigClient,
  Organization,
} from "/@/types/";

export function useManagementLabel() {
  // swr

  function getManagementLabelsKey(organization: Ref<Organization | undefined>) {
    if (!organization.value) {
      return null;
    }

    return `/api/v1/organizations/${organization.value.id}/management_labels`;
  }

  function getManagementLabels(organization: Ref<Organization | undefined>) {
    return useSWRV<ManagementLabelClient[]>(
      () => getManagementLabelsKey(organization),
      fetcher
    );
  }

  // api

  async function updateManagementLabel(form: {
    id: number;
    config: ManagementLabelConfigClient;
  }) {
    try {
      await axios.patch(`/api/v1/management_labels/${form.id}`, {
        managementLabels: form,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function createManagementLabel(
    organization: Organization,
    form: ManagementLabel
  ) {
    try {
      await axios.post(
        `/api/v1/organizations/${organization.id}/management_labels`,
        {
          managementLabels: form,
        }
      );
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function destroyManagementLabel(id: number) {
    try {
      await axios.delete(`/api/v1/management_labels/${id}`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    getManagementLabels,

    updateManagementLabel,
    createManagementLabel,
    destroyManagementLabel,
  };
}
