<template>
  <div>
    <table
      class="table table-responsive table-bordered table-striped small bg-white me-2 table-sm"
    >
      <thead>
        <tr>
          <th>所定労働日数</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {{
              selectedMonthLabor
                ? selectedMonthLabor.prescribedWorkingDays
                : "-"
            }}
          </td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th>公休日数</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {{ selectedMonthLabor ? selectedMonthLabor.publicHolidays : "-" }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { isSameMonth } from "/@/modules/luxon";
import { DateMap, OrganizationMember } from "/@/types";

const props = defineProps<{
  member: OrganizationMember;
  selectedDate: DateMap;
}>();

const selectedMonthLabor = computed(() => {
  if (!props.member?.organizationMemberLabors) {
    return undefined;
  }

  return props.member.organizationMemberLabors.find((labor) =>
    isSameMonth(props.selectedDate, labor.targetDate)
  );
});
</script>

<style scoped></style>
