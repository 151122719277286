import SignIn from "./SignIn.vue";
import Home from "./Home.vue";
import EventPlaces from "./EventPlaces.vue";
import WorkPlaces from "./WorkPlaces.vue";
import ReportItems from "./ReportItems.vue";

export default [
  {
    path: "",
    name: "AdminsHome",
    component: Home,
  },
  {
    path: "sign_in",
    name: "AdminsSignIn",
    component: SignIn,
  },
  {
    path: "event_places",
    name: "AdminsEventPlaces",
    component: EventPlaces,
  },
  {
    path: "work_places",
    name: "AdminsWorkPlaces",
    component: WorkPlaces,
  },
  {
    path: "report_items",
    name: "AdminsReportItems",
    component: ReportItems,
  },
];
