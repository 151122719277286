<template>
  <div>
    <CheckForm
      v-for="i in items"
      :key="i.value"
      :value="values"
      :checked-value="i.value"
      :label="i.name"
      :form-id="formId + i.value"
      :is-valid="isValid"
      :disabled="disabledValues?.includes(i.value)"
      :class="itemClass"
      :btn-style="btnStyle"
      :btn-slim="btnSlim"
      @click="clickCheckBox"
      @update:value="updateValues"
    />
    <div
      v-if="withOperationBtn && items.length > 1"
      class="ms-4 me-2 d-flex flex-wrap"
    >
      <button
        class="btn btn-success text-white me-2"
        :class="{ 'btn-sm': btnSlim }"
        @click="allCheck"
      >
        全て選択
      </button>
      <button
        class="btn btn-danger text-white"
        :class="{ 'btn-sm': btnSlim }"
        @click="allUncheck"
      >
        全て解除
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CheckForm } from "/@/vue/components/Atom";
import type { CheckItem } from "/@/types";

const props = defineProps<{
  items: CheckItem[] | readonly CheckItem[];
  values?: any[];
  formId: string;
  isValid?: boolean;
  btnStyle?: boolean;
  btnSlim?: boolean;
  disabledValues?: any[];
  itemClass?: string;
  withOperationBtn?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:values", values: any[]): void;
}>();

function updateValues(v: any[]) {
  emit("update:values", v);
}

function clickCheckBox(e: Event) {
  e.stopPropagation();
}

// operation

function allCheck() {
  emit(
    "update:values",
    props.items.map((item) => item.value)
  );
}

function allUncheck() {
  emit("update:values", []);
}
</script>

<style scoped></style>
