<template>
  <div class="d-flex w-100 justify-content-between align-items-center">
    <span>{{ workToListTitle(work) }}</span>
    <span>
      <MenuButton :menu-items="menuItems" />
    </span>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { MenuButton } from "/@/vue/components/Molecules";
import { workToListTitle } from "/@/modules/work";
import { Work } from "/@/types";

const props = defineProps<{
  work: Work;
}>();

const emit = defineEmits<{
  (e: "updateWork", id: number): void;
  (e: "destroyWork", id: number): void;
}>();

const menuItems = ref([
  {
    label: "案件操作",
    items: [
      {
        label: "編集",
        icon: "pi pi-file-edit",
        command: () => {
          emit("updateWork", props.work.id);
        },
      },
      {
        label: "削除",
        icon: "pi pi-trash",
        command: () => {
          emit("destroyWork", props.work.id);
        },
      },
    ],
  },
]);
</script>

<style scoped></style>
