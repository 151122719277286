<template>
  <div class="d-flex flex-row">
    <InputForm
      :value="value?.toString()"
      form-type="number"
      :is-valid="isValid"
      :step="0.1"
      @update:value="updateTemperature"
    />
    <div class="form-append-icon p-2">℃</div>
  </div>
</template>

<script setup lang="ts">
import { InputForm } from ".";

defineProps<{
  value?: number;
  isValid?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:value", temperature: number | undefined): void;
}>();

function updateTemperature(val: string | undefined) {
  emit("update:value", val ? parseFloat(val) : undefined);
}
</script>

<style lang="scss" scoped>
input {
  text-align: right;
}
</style>
