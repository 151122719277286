import zod from "zod";
import { EventApi } from "@fullcalendar/core";
import { z } from "/@/modules/zodUtils";
import {
  AbsenceApplicationPlan,
  OrganizationMemberSchedulePlan,
  WorkAsPlan,
  WorkScheduleAsPlan,
} from "/@/types/plan";

const eventTypes = [
  "AbsenceApplicationPlan",
  "WorkAsPlan",
  "WorkScheduleAsPlan",
  "OrganizationMemberSchedulePlan",
  "OrganizationSchedulePlan",
] as const;

const EventTypeScheme = z.enum(eventTypes);
export type EventType = zod.infer<typeof EventTypeScheme>;

export type CalendarEventArgs = Pick<
  EventApi,
  | "id"
  | "title"
  | "backgroundColor"
  | "borderColor"
  | "allDay"
  | "extendedProps"
  | "classNames"
> & {
  start: Date | string;
  end?: Date | string;
  extendedProps: (
    | AbsenceApplicationPlan
    | WorkAsPlan
    | WorkScheduleAsPlan
    | OrganizationMemberSchedulePlan
    | OrganizationSchedulePlan
  ) & {
    eventType: EventType;
  };
};

export const toggleTypes = ["calendar", "user", "organization"] as const;
export const ToggleTypeScheme = z.enum(toggleTypes);
export type ToggleType = zod.infer<typeof ToggleTypeScheme>;

export type JpHolidays = Partial<Record<string, string>> | {};
