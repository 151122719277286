import zod from "zod";
import { DateTimeScheme, z } from "/@/modules/zodUtils";
import { CarriersScheme } from "/@/types/carriers.d";
import { WorkCategoriesScheme } from "/@/types/workCategories.d";
import { ReportItemScheme } from "/@/types/workReport.d";
import { OrganizationCategoryScheme } from "/@/types/category.d";

export const workTaskStatus = ["contracted", "losted", "undone"] as const;
export const WorkTaskStatusScheme = z.enum(workTaskStatus);
export type WorkTaskStatus = zod.infer<typeof WorkTaskStatusScheme>;

export const workTaskTypes = ["reservation", "calling"] as const;
const WorkTaskTypeScheme = z.enum(workTaskTypes);
export type WorkTaskType = zod.infer<typeof WorkTaskTypeScheme>;

export const WorkTaskScheme = z.object({
  id: z.number().optional(),
  workTaskType: WorkTaskTypeScheme,
  actionAt: DateTimeScheme,
  contractedAt: DateTimeScheme.optional(),
  phoneNumber: z.string().optional().nullable(),
  customerName: z.string().optional().nullable(),
  remarks: z.string().optional().nullable(),
  reportItems: ReportItemScheme.array(),
  countInContractedDate: z.boolean().or(z.string()).optional(),
});
export type WorkTask = zod.infer<typeof WorkTaskScheme>;

export const lostReasons = [
  "family_opposition",
  "other_stores",
  "change_mind",
  "unreachable",
  "other_reason",
] as const;
export const LostReasonScheme = z.enum(lostReasons);
export type LostReason = zod.infer<typeof LostReasonScheme>;

export const WorkTaskClientScheme = WorkTaskScheme.extend({
  id: z.number(),
  workId: z.number(),
  organizationMemberId: z.number(),
  contractedAt: DateTimeScheme.optional(),
  lostedAt: DateTimeScheme.optional(),
  lostReason: LostReasonScheme.optional(),
  lostReasonDetails: z.string().optional(),
  createdAt: DateTimeScheme,
  entryId: z.number(),
  entryStartAt: DateTimeScheme,
  entryFinishAt: DateTimeScheme.optional(),
  entryWorkId: z.number(),
  entryWorkCategory: WorkCategoriesScheme,
  entryWorkCarrier: CarriersScheme,
  entryWorkRequestOrganizationId: z.number(),
  entryWorkRequestOrganizationName: z.string(),
  entryWorkPlaceName: z.string(),
  entryEventPlaceName: z.string().optional(),
  entryWorkTypeName: z.string(),
  entryWorkTypeCategories: OrganizationCategoryScheme.array(),
  entryWorkScheduleEventPlaceName: z.string().optional(),
  countInContractedDate: z.boolean(),
  contractedEntryId: z.number().optional(),
});

export type WorkTaskClient = zod.infer<typeof WorkTaskClientScheme>;

export const WorkTaskFormScheme = WorkTaskScheme.partial({
  reportItems: false,
});
export type WorkTaskForm = zod.infer<typeof WorkTaskFormScheme>;

export type WorkTaskWithIdxForm = WorkTaskForm & { idx: number };

type WorkTaskKey = keyof WorkTask;
