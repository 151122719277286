<template>
  <div>
    <CollapseWrapper
      :key="`entry-${entry.id}`"
      :contents-id="`entry-${entry.id}`"
      class="card card-body"
    >
      <template #header>
        <FormItemShow label="業務内容" content-id="content" without-margin>
          <span>
            {{
              entryToTitle(entry, {
                withoutRequestOrganization: true,
                scheduleMode: !isActual,
              })
            }}
          </span>
        </FormItemShow>
      </template>
      <template #contents>
        <FormItemShow label="入退店時間" content-id="entry-at">
          <span> {{ entryStartAt }}~{{ entryFinishAt }} </span>
        </FormItemShow>
        <FormItemShow
          v-if="entry.startPhotoUrl"
          label="入店時情報"
          content-id="start-info"
        >
          <FormItemShow label="位置情報" content-id="startLatLng">
            <ShowLocation :lat="entry.startLat" :lng="entry.startLng" />
          </FormItemShow>
          <FormItemShow label="写真" content-id="startPhoto">
            <ShowPhoto :photo-url="entry.startPhotoUrl" alt="開始時写真" />
          </FormItemShow>
        </FormItemShow>
        <FormItemShow
          v-if="entry.finishPhotoUrl"
          label="退店時情報"
          content-id="finish-info"
        >
          <FormItemShow label="位置情報" content-id="startLatLng">
            <ShowLocation :lat="entry.finishLat" :lng="entry.finishLng" />
          </FormItemShow>
          <FormItemShow label="写真" content-id="startPhoto">
            <ShowPhoto :photo-url="entry.finishPhotoUrl" alt="終了時写真" />
          </FormItemShow>
        </FormItemShow>

        <FormItemShow
          v-if="entry.workReports && entry.workReports.length"
          label="業務成績"
          content-id="workReports"
        >
          <div class="mb-2"></div>
          <WorkReportsShowForm
            :work-reports="entry.workReports"
            :work-tasks="targetTasks"
            :work-category="entry.workCategory"
            :work-carrier="entry.workCarrier"
            :work-type-categories="entry.workTypeCategories"
            :request-organization-id="entry.workRequestOrganizationId"
            :basis-report-items="basisReportItems"
          />
        </FormItemShow>
      </template>
    </CollapseWrapper>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { entryToTitle } from "/@/modules/workRecords";
import {
  FormItemShow,
  ShowPhoto,
  ShowLocation,
  CollapseWrapper,
} from "/@/vue/components/Molecules";
import { WorkReportsShowForm } from "/@/vue/components/Organisms";
import {
  WorkRecordEntryClient,
  WorkTaskClient,
  BasisReportItemClient,
} from "/@/types";
import { basicFormatter } from "/@/modules/luxon";

const props = defineProps<{
  entry: WorkRecordEntryClient;
  tasks: WorkTaskClient[];
  basisReportItems: BasisReportItemClient[];
  isActual?: boolean;
}>();

const targetTasks = computed(() => {
  return props.tasks.filter(
    (task) =>
      (!task.countInContractedDate && task.entryId === props.entry.id) ||
      (task.countInContractedDate && task.contractedEntryId === props.entry.id)
  );
});

const entryStartAt = computed(() => {
  if (props.isActual) {
    return basicFormatter(props.entry.startAt, "onlyTime");
  } else {
    return basicFormatter(props.entry.workScheduleStartAt, "onlyTime");
  }
});

const entryFinishAt = computed(() => {
  if (props.entry.finishAt) {
    return basicFormatter(
      props.isActual ? props.entry.finishAt : props.entry.workScheduleFinishAt,
      "onlyTime"
    );
  } else {
    return "入店中";
  }
});
</script>

<style scoped></style>
