<template>
  <div>
    <SelectButton
      :model-value="selectedLabelGroups"
      :options="labelGroupItems"
      option-label="name"
      option-value="value"
      multiple
      aria-labelledby="multiple"
      @update:model-value="$emit('update:selectedLabelGroups', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { groupTypeTranslater } from "/@/modules/managementLabel";
import { LabelGroup } from "/@/types";
import SelectButton from "primevue/selectbutton";

const props = defineProps<{
  selectedLabelGroups: string[];
  labelGroups: LabelGroup[];
}>();

defineEmits<{
  (e: "update:selectedLabelGroups", labelGroup: string[]): void;
}>();

const labelGroupItems = computed(() => {
  const groupTypes = [...new Set(props.labelGroups.map((lg) => lg.groupType))];

  return groupTypes.map((gt) => ({
    name: groupTypeTranslater(gt),
    value: gt,
  }));
});
</script>

<style scoped></style>
