<template>
  <div>
    <ProjectListItem
      v-if="selected"
      :project="selected"
      class="mb-2"
      selected
    />
    <ProjectListItem
      v-for="project of others"
      :key="project.id"
      :project="project"
      class="mb-2"
      @delete-project="$emit('deleteProject', $event)"
      @select-project="$emit('selectProject', $event)"
    />
    <BasicButton
      slim
      label="プロジェクト追加"
      icon="pi pi-plus-circle"
      @click="createProject"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { BasicButton } from "/@/vue/components/Atom";
import ProjectListItem from "./ProjectListItem.vue";
import type { TaskProjectClient } from "/@/types";

const props = defineProps<{
  projects: TaskProjectClient[];
  selectedProjectId: number;
}>();

const selected = computed(() => {
  return props.projects.find(
    (project) => project.id === props.selectedProjectId
  );
});

const others = computed(() => {
  return props.projects.filter(
    (project) => project.id !== props.selectedProjectId
  );
});

const emits = defineEmits<{
  (e: "createProject"): void;
  (e: "deleteProject", id: number): void;
  (e: "selectProject", id: number): void;
}>();

function createProject() {
  emits("createProject");
}
</script>

<style scoped></style>
