import axios from "axios";
import { errorHandle } from "/@/modules/error";

export function useWorkSchedules() {
  async function destroyWorkSchedule(id: number) {
    try {
      await axios.delete(`/api/v1/work_schedules/${id}`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function cancelWorkSchedule(id: number) {
    try {
      await axios.post(`/api/v1/work_schedules/${id}/cancel`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function restoreWorkSchedule(id: number) {
    try {
      await axios.post(`/api/v1/work_schedules/${id}/restore`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function updateWorkSchedule(
    id: number,
    prms: { vacancyCount: number }
  ) {
    try {
      await axios.patch(`/api/v1/work_schedules/${id}`, {
        workSchedules: prms,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    destroyWorkSchedule,
    cancelWorkSchedule,
    restoreWorkSchedule,
    updateWorkSchedule,
  };
}
