<template>
  <div>
    <PageHeader page-title="編集リクエスト" hide-back-btn />
    <OrganizationSelector
      v-model:selected-organization-id="selectedOrganizationId"
      class="w-100 mb-3"
      :organizations="organizations || []"
    />

    <CheckFormArray
      v-model:values="selectedStatuses"
      :items="statusItems"
      form-id="select-carrier"
      btn-style
      btn-slim
      with-operation-btn
      class="d-flex mb-2 flex-wrap"
    />
    <template v-if="filteredRequests.length">
      <div v-for="request in filteredRequests" :key="request.id">
        <EditRequestCard
          :edit-request="request"
          class="mb-2"
          @accept="handleAccepet"
          @reject="handleReject"
        />
      </div>
    </template>
    <span v-else class="text-danger p-2">条件に合うリクエストはありません</span>
  </div>
</template>

<script setup lang="ts">
import { useStore } from "/@/vue/store";
import { ref, watch, computed } from "vue";
import { useOrganization, useEditRequest } from "/@/vue/composables";
import {
  EditRequestCard,
  OrganizationSelector,
} from "/@/vue/components/Organisms";
import { CheckFormArray } from "/@/vue/components/Molecules";
import { PageHeader } from "/@/vue/components/Layouts";
import { EditRequestStatus, editRequestStatuses } from "/@/types";
import { editRequestTranslater } from "/@/modules/editRequest";

const {
  getOrganizations,
  organizations,
  selectedOrganizationId,
  selectedOrganization,
} = useOrganization();
const { data: organizationsData } = getOrganizations();
watch(organizationsData, (os) => {
  organizations.value = os || [];
});

// edit requests

const { getEditRequests, handleRequest } = useEditRequest();
const { data: editRequests } = getEditRequests(selectedOrganization);

const selectedStatuses = ref<EditRequestStatus[]>(["pending"]);
const statusItems = computed(() => {
  return editRequestStatuses.map((status) => ({
    value: status,
    name: editRequestTranslater(status),
  }));
});

const filteredRequests = computed(() => {
  if (!editRequests.value) return [];

  return editRequests.value.filter((req) =>
    selectedStatuses.value.includes(req.requestStatus)
  );
});

const store = useStore();

async function handleAccepet(id: number) {
  if (await handleRequest(id, "accept")) {
    window.alert("受理しました。");
    await store.dispatch("user/GET_USER");
  } else {
    window.alert("受理の処理に失敗しました。");
  }
}

async function handleReject(id: number) {
  if (await handleRequest(id, "reject")) {
    window.alert("拒否しました。");
    await store.dispatch("user/GET_USER");
  } else {
    window.alert("拒否の処理に失敗しました。");
  }
}
</script>

<style scoped></style>
