import { basicFormatter } from "./luxon";
import { carrierToPrefix, shorter, translater } from "/@/modules/string";
import { Carrier, WorkCategory, ScheduleInOrganizationMember } from "/@/types";

type WorkScheduleTitleMap = {
  id?: number;
  workCategory: string;
  workCategoryShort: string;
  workCarrier: string;
  workCarrierShort: string;
  workPlaceName: string;
  workPlaceShortName: string;
  eventPlaceName: string;
  eventPlaceShortName: string;
  workTypeName: string;
  organizationMemberName: string;
  organizationMemberNameShort: string;
  organizations?: {
    organizationName: string;
    organizationId: number;
  }[];
  organizationMembers?: {
    organizationId: number;
    organizationName: string;
    organizationMemberName: string;
    organizationMemberId: number;
    absence: boolean;
    vacancy: boolean;
  }[];
  countStatus: string;
  enoughCount: boolean;
  vacancyCount: number;
  canceled?: boolean;
  absence?: boolean;
};

export function workScheduleToTitleMap(workSchedule: {
  id?: number;
  workTypeName: string;
  workPlaceName: string;
  workPlaceShortName?: string;
  eventPlaceName?: string;
  eventPlaceShortName?: string;
  workCategory: WorkCategory;
  workCarrier: Carrier;
  startAt: string;
  finishAt: string;
  organizationMemberName: string;
  assignedOrganizations?: {
    organizationId: number;
    organizationName: string;
  }[];
  assignedMembers?: {
    organizationId: number;
    organizationName: string;
    organizationMemberName: string;
    organizationMemberId: number;
    absence: boolean;
    vacancy: boolean;
  }[];
  needCount?: number;
  vacancyCount: number;
  currentCount?: number;
  enoughCount?: boolean;
  canceled?: boolean;
  [key: string]: any;
}): WorkScheduleTitleMap {
  let workCategoryPrefix = carrierToPrefix(workSchedule.workCarrier);
  if (workCategoryPrefix === "Y") {
    workCategoryPrefix = "";
  } else {
    workCategoryPrefix += "/";
  }

  return {
    id: workSchedule.id,
    workCategory: `${workCategoryPrefix}${translater(
      workSchedule.workCategory
    )}`,
    workCategoryShort: `${workCategoryPrefix}${translater(
      workSchedule.workCategory,
      true
    )}`,
    workCarrier: translater(workSchedule.workCarrier),
    workCarrierShort: translater(workSchedule.workCarrier, true),
    workTypeName: workSchedule.workTypeName,
    workPlaceName: workSchedule.workPlaceName,
    workPlaceShortName:
      workSchedule.workPlaceShortName ||
      shorter(workSchedule.workPlaceName, 3) ||
      "",
    eventPlaceName: workSchedule.eventPlaceName || "",
    eventPlaceShortName:
      workSchedule.eventPlaceShortName ||
      shorter(workSchedule.eventPlaceName || "", 3) ||
      "",
    organizations:
      workSchedule.assignedOrganizations?.map((ao) => ({
        organizationName: ao.organizationName.replace("(株)", ""),
        organizationId: ao.organizationId,
      })) || [],
    organizationMemberName: workSchedule.organizationMemberName,
    organizationMemberNameShort:
      shorter(workSchedule.organizationMemberName) || "",
    organizationMembers:
      workSchedule.assignedMembers?.map((member) => {
        const organizationMemberName = member.organizationMemberName
          .split(" ")
          .at(0);

        if (!organizationMemberName) throw new Error("detect emtpy name user");

        return {
          organizationId: member.organizationId,
          organizationName: member.organizationName.replace("(株)", ""),
          organizationMemberName,
          organizationMemberId: member.organizationMemberId,
          absence: member.absence,
          vacancy: member.vacancy,
        };
      }) || [],
    enoughCount: !!workSchedule.enoughCount,
    countStatus: !workSchedule.enoughCount
      ? `${workSchedule.currentCount}/${workSchedule.needCount}`
      : "",
    canceled: !!workSchedule.canceled,
    vacancyCount: workSchedule.vacancyCount,
  };
}

export function workScheduleToTitle(
  workSchedule:
    | ScheduleInOrganizationMember
    | {
        workPlaceName: string;
        workPlaceShortName?: string;
        workTypeName: string;
        workCategory: WorkCategory;
        workCarrier: Carrier;
        meetingAt?: string;
        startAt: string;
        finishAt: string | undefined;
        eventPlaceName?: string;
        eventPlaceShortName?: string;
        needCount?: number;
        currentCount?: number;
        canceled: boolean;
        absence: boolean;
        vacancy: boolean;
        [key: string]: any;
      },
  options: { short?: boolean; withoutCurrentStatus?: boolean } = {
    short: false,
    withoutCurrentStatus: false,
  }
) {
  const {
    eventPlaceName,
    eventPlaceShortName,
    workPlaceName,
    workPlaceShortName,
    workCategory,
    workCarrier,
    needCount,
    currentCount,
    canceled,
  } = workSchedule;

  const { short, withoutCurrentStatus } = options;

  const placeName = short
    ? eventPlaceShortName ||
      workPlaceShortName ||
      shorter(eventPlaceName || workPlaceName || "", 3)
    : eventPlaceName || workPlaceName || "";

  const prefix = translater(workCarrier, short);

  const base = `${prefix}${translater(workCategory, short)} ${placeName}`;
  const canceledStr = canceled ? "(中止) " : "";
  const absenceStr = !canceled && workSchedule.absence ? "(欠勤) " : "";
  const vacancyStr = workSchedule.vacancy ? "(欠員) " : "";

  const currentCountStatus =
    canceledStr +
    absenceStr +
    vacancyStr +
    (withoutCurrentStatus
      ? ""
      : needCount && needCount > 1 && (currentCount || currentCount === 0)
      ? `${currentCount}/${needCount} `
      : "");

  if (!short) {
    return (
      currentCountStatus +
      `${
        workSchedule.meetingAt
          ? `集合${basicFormatter(workSchedule.meetingAt, "onlyTime")} `
          : ""
      }` +
      `${basicFormatter(workSchedule.startAt, "onlyTime")}~${basicFormatter(
        workSchedule.finishAt,
        "onlyTime"
      )} ` +
      base
    );
  } else {
    return currentCountStatus + `${base}`;
  }
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const workSchedule: ScheduleInOrganizationMember = {
    id: 11,
    targetDate: "2023-03-29",
    startAt: "2000-01-01T09:00:00.000+09:00",
    finishAt: "2000-01-01T18:00:00.000+09:00",
    workId: 1,
    workCategory: "rounder",
    workCarrier: "softbank",
    workTypeName: "獲得支援",
    workPlaceName: "ラスパ白山",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "ダイワ通信(株)",
    workPlaceOrganizationId: 1,
    organizationMemberName: "武田",
    workScheduleAssignmentId: 1,
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    canceled: false,
  };

  const workSchedule2 = {
    ...workSchedule,
    needCount: 2,
  };

  it("workScheduleToTitle", () => {
    expect(workScheduleToTitle(workSchedule)).toBe(
      "09:00~18:00 Sラウンダー ラスパ白山"
    );
    expect(workScheduleToTitle(workSchedule2)).toBe(
      "1/2 09:00~18:00 Sラウンダー ラスパ白山"
    );
    expect(workScheduleToTitle(workSchedule, { short: true })).toBe(
      "SRD ラスパ"
    );
  });

  it("workScheduleToTitleMap", () => {
    expect(workScheduleToTitleMap(workSchedule)).toStrictEqual({
      id: 11,
      countStatus: "",
      eventPlaceName: "",
      eventPlaceShortName: "",
      workCategory: "ラウンダー",
      workCategoryShort: "RD",
      workCarrier: "S",
      workCarrierShort: "S",
      workTypeName: "獲得支援",
      workPlaceName: "Sラスパ白山",
      workPlaceShortName: "Sラス",
      organizationMemberName: "武田",
      organizationMemberNameShort: "武田",
    });
  });
}
