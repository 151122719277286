<template>
  <div>
    <SimpleModal
      ref="modal"
      title="イベント会場の作成"
      submit-text="作成"
      :loading="loading"
      @cancel="closeModal"
      @submit="handleSubmit"
    >
      <AdminEventPlaceForm
        v-model:name="form.name"
        v-model:short-name="form.shortName"
        v-model:prefecture="form.prefecture"
        v-model:meeting-place="form.meetingPlace"
        v-model:postcode="form.postcode"
        v-model:address="form.address"
        v-model:venue-name="form.venueName"
        v-model:gmap-url="form.gmapUrl"
        v-model:first-meeting-at="form.firstMeetingAt"
        v-model:meeting-at="form.meetingAt"
        v-model:start-at="form.startAt"
        v-model:finish-at="form.finishAt"
        v-model:event-place-attached-images="
          form.eventPlaceAttachedImagesAttributes
        "
        v-model:event-place-selected-places="
          form.eventPlaceSelectedPlacesAttributes
        "
        :work-places="workPlaces"
        :errors="errors"
      />
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useZodScheme } from "/@/vue/composables";
import { SimpleModal } from "/@/vue/components/Molecules";
import {
  EventPlaceCreateForm,
  EventPlace,
  EventPlaceScheme,
  WorkPlace,
} from "/@/types";
import { errorHandle } from "/@/modules/error";
import AdminEventPlaceForm from "./AdminEventPlaceForm.vue";

defineProps<{
  workPlaces: WorkPlace[];
}>();

const emit = defineEmits<{
  (e: "submit", prms: EventPlace): void;
}>();

//

const loading = ref<boolean>(false);

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } =
  useFormAndErrors<EventPlaceCreateForm>(EventPlaceScheme, {
    eventPlaceAttachedImages: [],
    eventPlaceAttachedImagesAttributes: [],
    eventPlaceSelectedPlaces: [],
    eventPlaceSelectedPlacesAttributes: [],
  });

function resetForm() {
  form.name = undefined;
  form.shortName = undefined;
  form.prefecture = "ishikawa";
  form.meetingPlace = undefined;
  form.postcode = undefined;
  form.address = undefined;
  form.venueName = undefined;
  form.gmapUrl = undefined;
  form.firstMeetingAt = undefined;
  form.meetingAt = undefined;
  form.startAt = undefined;
  form.finishAt = undefined;
  form.eventPlaceAttachedImages = [];
  form.eventPlaceAttachedImagesAttributes = [];
  form.eventPlaceSelectedPlaces = [];
  form.eventPlaceSelectedPlacesAttributes = [];
}

function handleSubmit() {
  try {
    loading.value = true;
    startValidation.value = true;
    const eventPlaceUpdateParams: EventPlace = EventPlaceScheme.parse(form);
    emit("submit", eventPlaceUpdateParams);
    closeModal();
    resetForm();
  } catch (e) {
    errorHandle(e);
    loading.value = false;
  }
}

const modal = ref();

function openModal(id: number) {
  loading.value = false;
  resetForm();
  form.prefecture = "ishikawa";
  modal.value.openModal();
}

function closeModal() {
  resetForm();
  modal.value.closeModal();
}

defineExpose({
  openModal,
  closeModal,
});
</script>

<style scoped></style>
