<template>
  <div>
    <table
      class="table table-bordered table-sm table-fixed table-striped align-middle"
    >
      <thead>
        <tr class="sticky">
          <th>名前</th>
          <th v-for="group in labelGroups" :key="group.name">
            {{ group.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="member in organizationMembers" :key="member.id">
          <template v-if="membersLabelForm[member.id]">
            <ManagementLabelUserRow
              :labels="membersLabelForm[member.id]"
              :member="member"
              :management-labels="managementLabels"
              :label-groups="labelGroups"
              :sub-contractors="subContractors"
              @update:labels="updateMembersLabelForm(member.id, $event)"
            />
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from "vue";
import { createLabelGroups } from "/@/modules/managementLabel";
import {
  ContractRelationOrganization,
  OrganizationMember,
  ManagementLabelClient,
} from "/@/types";

import ManagementLabelUserRow from "./ManagementLabelUserRow.vue";
import { watchEffect } from "vue";

const props = defineProps<{
  organizationMembers: OrganizationMember[];
  managementLabels: ManagementLabelClient[];
  subContractors: ContractRelationOrganization[];
}>();

const emit = defineEmits<{
  (e: "updateMember", prms: { id: number; labels: number[] }): void;
}>();

const labelGroups = computed(() => {
  return createLabelGroups(props.managementLabels);
});

// form

const membersLabelForm = reactive<Partial<Record<number, number[]>>>({});

watchEffect(() => {
  if (props.organizationMembers.length) {
    props.organizationMembers.forEach((member) => {
      membersLabelForm[member.id] = member.labels;
    });
  }
});

function updateMembersLabelForm(memberId: number, labelIds: number[]) {
  membersLabelForm[memberId] = labelIds;
  emit("updateMember", { id: memberId, labels: labelIds });
}
</script>

<style scoped lang="scss">
th,
td {
  min-width: 200px;
}
</style>
