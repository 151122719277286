import zod from "zod";
import { DateTimeScheme, z } from "/@/modules/zodUtils";

export const organizationEventTypes = [
  "meeting",
  "study",
  "meeting_training",
] as const;
export const OrganizationEventTypeScheme = z.enum(organizationEventTypes);
export type OrganizationEventType = zod.infer<
  typeof OrganizationEventTypeScheme
>;

export const eventAppliactionStatus = [
  "pending",
  "approved",
  "rejected",
] as const;
export const EventApplicationStatusScheme = z.enum(eventAppliactionStatus);
export type EventApplicationStatus = zod.infer<
  typeof EventApplicationStatusScheme
>;

export const OrganizationEventScheme = z.object({
  id: z.number().optional(),
  name: z.string(),
  eventType: OrganizationEventTypeScheme,
  startAt: DateTimeScheme,
  finishAt: DateTimeScheme.optional(),
  forceInvitation: z.boolean(),
  organizationMemberIds: z.number().array(),
  remarks: z.string().optional(),
  placeName: z.string().optional(),
  placeGmapUrl: z.string().optional(),
});
export type OrganizationEvent = zod.infer<typeof OrganizationEventScheme>;

export const OrganizationEventInvitationScheme = z.object({
  id: z.number().optional(),
  organizationMemberId: z.number(),
  eventApplicationStatus: EventApplicationStatusScheme,
});
export type OrganizationEventInvitation = zod.infer<
  typeof OrganizationEventInvitationScheme
>;

export const OrganizationEventInvitationClientScheme =
  OrganizationEventInvitationScheme.extend({
    id: z.number(),
    organizationId: z.number(),
    organizationEvent: OrganizationEventScheme,
  });
export type OrganizationEventInvitationClient = zod.infer<
  typeof OrganizationEventInvitationClientScheme
>;

export const OrganizationEventFormScheme =
  OrganizationEventScheme.partial().extend({
    organizationMemberIds: z.number().array(),
  });
export type OrganizationEventForm = zod.infer<
  typeof OrganizationEventFormScheme
>;

export const OrganizationEventClientScheme = OrganizationEventScheme.extend({
  id: z.number(),
  organizationId: z.number(),
  organizationMemberId: z.number(),
  organizationMemberName: z.string(),
  organizationMemberIds: z.number().array(),
  organizationEventInvitations: OrganizationEventInvitationClientScheme.array(),
});
export type OrganizationEventClient = zod.infer<
  typeof OrganizationEventClientScheme
>;
