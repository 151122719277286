<template>
  <div>
    <GetLocation
      v-if="needEnterLeaveReport"
      class="mb-3"
      :lat="lat"
      :lng="lng"
      @update:lat="$emit('update:lat', $event)"
      @update:lng="$emit('update:lng', $event)"
    />
    <div></div>

    <FormItemSelector
      class="mb-3"
      :value="workScheduleId"
      :items="workSchedules"
      label="業務計画"
      form-type="object"
      form-id="workScheduleId"
      :show-fn="workScheduleToTitle"
      :errors="errors"
      @update:value="$emit('update:workScheduleId', $event.id)"
    />

    <FormItemSelector
      v-if="canFreeWork"
      class="mb-3"
      :value="workId"
      :items="works"
      form-type="object"
      label="案件"
      form-id="workId"
      value-key="id"
      :show-fn="showWork"
      :errors="errors"
      @update:value="$emit('update:workId', $event.id)"
    />
    <FormItemShow
      v-else-if="selectedWorkSchedule && false"
      label="案件"
      content-id="work"
      class="mb-3"
    >
      {{
        workToTitle({
          workCategory: selectedWorkSchedule.workCategory,
          carrier: selectedWorkSchedule.workCarrier,
          requestOrganizaitonName: selectedWorkSchedule.requestOrganizaitonName,
          workPlaceName: selectedWorkSchedule.workPlaceName,
          prefectures: [selectedWorkSchedule.workPlacePrefecture],
        })
      }}
    </FormItemShow>

    <FormItemShow
      v-if="selectedWorkSchedule"
      label="業務種別"
      content-id="workType"
    >
      {{ selectedWorkSchedule.workTypeName }}
    </FormItemShow>

    <FormItemSelector
      v-if="canFreeWork"
      class="mb-3"
      :value="workPlaceId"
      :items="workPlaces"
      form-type="object"
      label="業務場所"
      form-id="workPlaceId"
      value-key="id"
      show-key="name"
      :errors="errors"
      @update:value="$emit('update:workPlaceId', $event.id)"
    />
    <FormItemShow
      v-else-if="selectedWorkSchedule"
      class="mb-3"
      label="業務場所"
      content-id="workPlace"
    >
      {{
        selectedWorkSchedule.eventPlaceName ||
        selectedWorkSchedule.workPlaceName
      }}
    </FormItemShow>

    <FormItemShow
      v-if="selectedWorkSchedule?.meetingAt"
      label="集合時間・場所"
      content-id="meetingTime"
      class="mb-3"
    >
      <span v-if="selectedWorkSchedule.meetingAt">
        {{ basicFormatter(selectedWorkSchedule.meetingAt, "onlyTime")
        }}{{
          selectedWorkSchedule.firstMeetingAt
            ? `(初回${basicFormatter(
                selectedWorkSchedule.firstMeetingAt,
                "onlyTime"
              )})`
            : ""
        }}
        {{ selectedWorkSchedule.meetingPlace || "" }}
      </span>
    </FormItemShow>

    <FormItemShow
      v-if="selectedWorkSchedule"
      label="業務予定時間"
      content-id="workTime"
      class="mb-3"
    >
      {{
        toTimeRangeString(
          selectedWorkSchedule.startAt,
          selectedWorkSchedule.finishAt
        )
      }}
    </FormItemShow>

    <MeetingTargetForm
      v-if="false"
      :report-items="reportItems || []"
      :work-category="selectedWorkSchedule.workCategory"
      :work-carrier="selectedWorkSchedule.workCarrier"
      :work-type-name="selectedWorkSchedule.workTypeName"
      :work-type-categories="selectedWorkSchedule.workTypeCategories"
      @update:report-items="$emit('update:reportItems', $event)"
    />

    <FormItemPhoto
      v-if="needEnterLeaveReport"
      class="mb-3"
      :photo="photo"
      form-id="startPhoto"
      label=""
      :errors="errors"
      @update:photo="$emit('update:photo', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import { basicFormatter, toTimeRangeString } from "/@/modules/luxon";
import { translater } from "/@/modules/string";
import { workScheduleToTitle } from "/@/modules/workSchedule";
import { workToTitle } from "/@/modules/work";
import { ZodFormattedErrors } from "/@/modules/zodUtils";
import {
  FormItemSelector,
  FormItemPhoto,
  FormItemShow,
} from "/@/vue/components/Molecules";
import { GetLocation, MeetingTargetForm } from "/@/vue/components/Organisms";
import type {
  Work,
  ScheduleInOrganizationMember,
  WorkPlace,
  WorkTypeClient,
  PhotoDataObject,
  ReportItemForm,
} from "/@/types";
import { computed } from "vue";

const canFreeWork = false;

const props = defineProps<{
  // values
  workScheduleId?: number;
  workId?: number;
  workPlaceId?: number;
  lat?: number;
  lng?: number;
  photo?: PhotoDataObject;
  reportItems?: ReportItemForm[];

  // args
  workSchedules: ScheduleInOrganizationMember[];
  works: Work[];
  workPlaces: WorkPlace[];
  workTypes: WorkTypeClient[];
  errors: ZodFormattedErrors;
  needEnterLeaveReport?: Boolean;
}>();

defineEmits<{
  (e: "update:workId", id: number): void;
  (e: "update:workScheduleId", id: number): void;
  (e: "update:workPlaceId", id: number): void;
  (e: "update:lat", lat: number): void;
  (e: "update:lng", lng: number): void;
  (e: "update:photo", photo: PhotoDataObject): void;
}>();

//

const selectedWorkSchedule = computed(() => {
  if (!props.workScheduleId || !props.workSchedules.length) {
    return undefined;
  }

  return props.workSchedules.find((ws) => ws.id == props.workScheduleId);
});

function showWork(work: Work) {
  return `${translater(work.workCategory)} ${translater(
    work.carrier
  )} ${basicFormatter(work.startOn)}~${basicFormatter(
    work.finishOn,
    "noYear"
  )}`;
}
</script>

<style scoped></style>
