import zod from "zod";
import { DateTimeScheme, z } from "/@/modules/zodUtils";

export const RestTimeScheme = z.object({
  id: z.number().optional(),
  workRecordId: z.number().optional(),
  startAt: DateTimeScheme,
  finishAt: DateTimeScheme,
});

export const RestTimeFormScheme = RestTimeScheme.partial();

export type RestTimeForm = zod.infer<typeof RestTimeFormScheme>;

export const RestTimeClientScheme = RestTimeScheme.extend({
  id: z.number(),
  workRecordId: z.number(),
});

export type RestTimeClient = zod.infer<typeof RestTimeClientScheme>;
