<template>
  <div>
    <ContentLabel v-if="label" :label="label" :content-id="formId" />
    <GeneralSelector
      v-if="formType == 'general'"
      :value="value"
      :items="items"
      :show-fn="showFn ? showFn : (x: any) => x"
      :is-valid="!errors || !errors[formId]"
      :form-id="formId + (addingId ? `-${addingId}` : '')"
      :disabled="disabled"
      :no-auto-select="noAutoSelect"
      :can-without-select="canWithoutSelect"
      :without-select-text="withoutSelectText"
      @update:value="$emit('update:value', $event)"
    />
    <ObjectSelector
      v-if="formType == 'object'"
      :value="value"
      :items="items"
      :value-key="valueKey"
      :show-key="showKey"
      :show-fn="showFn ? showFn : (x: any) => x"
      :form-id="formId + (addingId ? `-${addingId}` : '')"
      :is-valid="!errors || !errors[formId]"
      :disabled="disabled"
      :no-auto-select="noAutoSelect"
      :can-without-select="canWithoutSelect"
      :without-select-text="withoutSelectText"
      @update:value="$emit('update:value', $event)"
    />
    <MessageList :messages="errorMessage" status="error" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import {
  getErrorMessagesFromErrors,
  ZodFormattedErrors,
} from "/@/modules/zodUtils";
import {
  ContentLabel,
  MessageList,
  GeneralSelector,
  ObjectSelector,
} from "/@/vue/components/Atom";

type Item = any;

interface Props {
  value?: Item;
  items: Item[] | readonly Item[];
  label: string;
  formId: string;
  addingId?: string;
  formType: "general" | "object";
  valueKey?: string;
  showKey?: string;
  showFn?: (x: any) => string;
  disabled?: boolean;
  noAutoSelect?: boolean;
  canWithoutSelect?: boolean;
  withoutSelectText?: string;
  errors?: ZodFormattedErrors;
}

const props = defineProps<Props>();
defineEmits<{
  (e: "update:value", value: Item): void;
}>();

const errorMessage = computed<string[]>(() => {
  if (!props.errors) {
    return [];
  }

  return getErrorMessagesFromErrors(props.errors, props.formId);
});
</script>

<style scoped></style>
