<template>
  <div>
    <ContentLabel v-if="label" :content-id="formId" :label="label" />
    <select
      :id="formId"
      :class="`form-select ${isValid ? '' : 'is-invalid'} text-ellipsis`"
      :disabled="disabled"
      @change="update"
    >
      <option
        :value="undefined"
        :selected="value == undefined"
        :disabled="!canWithoutSelect"
      >
        {{ canWithoutSelect ? withoutSelectText : "選択して下さい" }}
      </option>
      <option
        v-for="item in items"
        :key="valueKey ? item[valueKey].toString() : item"
        :value="valueKey ? item[valueKey] : item"
        :selected="isSelected(item)"
      >
        {{ showFn(showKey ? item[showKey] : item) }}
      </option>
      <slot></slot>
    </select>
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";
import { onMounted, watch } from "vue";
import { useRouterUtil } from "/@/vue/composables";
import { ContentLabel } from "/@/vue/components/Atom";

type Item = {
  [valueKey: string]: any;
};

const props = withDefaults(
  defineProps<{
    value?: any;
    items: Item[] | readonly Item[];
    label?: string;
    formId: string;
    valueKey?: string;
    showKey?: string;
    showFn?: (x: any) => string;
    disabled?: boolean;
    isValid?: boolean;
    noAutoSelect?: boolean;
    useCache?: boolean;
    canWithoutSelect?: boolean;
    withoutSelectText?: string;
  }>(),
  {
    value: undefined,
    label: undefined,
    formId: undefined,
    valueKey: undefined,
    showFn: (x: any) => x,
    showKey: undefined,
    withoutSelectText: "選択しない",
  }
);

const emit = defineEmits<{
  (e: "update:value", v: Item | undefined): void;
}>();

function autoSelect(idx = 0) {
  if (
    !props.value &&
    !props.noAutoSelect &&
    !query.value[props.formId] &&
    props.items &&
    props.items.length >= 1
  ) {
    emit("update:value", props.items.at(idx));
  }
}

onMounted(() => {
  if (props.useCache) {
    restoreFromCache();
  } else {
    if (!props.value) {
      autoSelect();
    }
  }
});

watch(
  () => props.items,
  (newItems, oldItems) => {
    if (!_.isEqual(newItems, oldItems) && !props.useCache) {
      autoSelect();
    }
  }
);

function isEqualValueAndItem(value: any, item: Item, valueKey?: string) {
  if (valueKey) {
    return (
      value == item[valueKey] || (value && value[valueKey] == item[valueKey])
    );
  } else {
    return value == item || (value && value == item);
  }
}

function isSelected(item: Item) {
  return isEqualValueAndItem(props.value, item, props.valueKey);
}

// updater

const { query, currentRoute, replace } = useRouterUtil();

function storeToCache(val: any) {
  if (props.useCache && props.formId && query.value[props.formId] != val) {
    let loc = { ...currentRoute.value, query: { [props.formId]: val } };

    replace(loc);
  }
}

function restoreFromCache() {
  if (!props.formId) {
    return;
  }

  if (query.value[props.formId]) {
    const target = props.items.find(
      (item) =>
        (props.valueKey ? item[props.valueKey] : item) ==
        // @ts-ignore
        query.value[props.formId]
    );

    emit("update:value", target);
  } else {
    autoSelect();
  }
}

function update(e: Item) {
  const selected = props.items[e.target.selectedIndex - 1];
  emit("update:value", selected);
  storeToCache(
    props.valueKey && selected ? selected[props.valueKey] : props.value
  );
}
</script>

<style lang="scss" scoped></style>
