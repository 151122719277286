<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 36.6 36.8"
  >
    <defs>
      <filter
        id="長方形_707"
        x="18"
        y="13"
        width="18.6"
        height="23.8"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="パス_190"
        x="6"
        y="6"
        width="14.6"
        height="14.8"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur-2" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-2" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="楕円形_49"
        x="21"
        y="10"
        width="5.6"
        height="6.8"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur-3" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-3" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="楕円形_55"
        x="16.535"
        y="1.687"
        width="8.378"
        height="8.578"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur-4" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-4" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="楕円形_56"
        x="1.686"
        y="16.536"
        width="8.378"
        height="8.578"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur-5" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-5" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="楕円形_51"
        x="10"
        y="21"
        width="6.6"
        height="5.8"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur-6" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-6" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="楕円形_52"
        x="10"
        y="0"
        width="6.6"
        height="5.8"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur-7" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-7" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="楕円形_53"
        x="16.535"
        y="16.536"
        width="8.378"
        height="8.578"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur-8" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-8" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="楕円形_54"
        x="1.686"
        y="1.686"
        width="8.378"
        height="8.578"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur-9" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-9" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="楕円形_50"
        x="0"
        y="10"
        width="5.6"
        height="6.8"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur-10" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-10" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="antibody_test_icon_purple" transform="translate(0.3)">
      <g id="covid19" transform="translate(148 -79) rotate(90)">
        <g transform="matrix(0, -1, 1, 0, 79, 148.3)" filter="url(#長方形_707)">
          <g
            id="長方形_707-2"
            data-name="長方形 707"
            transform="translate(18.3 13)"
            fill="#fff"
            stroke="#8a8a8e"
            stroke-width="1.5"
          >
            <rect width="18" height="23" rx="1" stroke="none" />
            <rect
              x="0.75"
              y="0.75"
              width="16.5"
              height="21.5"
              rx="0.25"
              fill="none"
            />
          </g>
        </g>
        <g transform="matrix(0, -1, 1, 0, 79, 148.3)" filter="url(#パス_190)">
          <g
            id="パス_190-2"
            data-name="パス 190"
            transform="translate(20.3 6) rotate(90)"
            fill="#885eb7"
          >
            <path
              d="M 7 13.25 C 3.55374002456665 13.25 0.75 10.44626045227051 0.75 7 C 0.75 3.55374002456665 3.55374002456665 0.75 7 0.75 C 10.44626045227051 0.75 13.25 3.55374002456665 13.25 7 C 13.25 10.44626045227051 10.44626045227051 13.25 7 13.25 Z"
              stroke="none"
            />
            <path
              d="M 7 1.5 C 3.967289924621582 1.5 1.5 3.967289924621582 1.5 7 C 1.5 10.03271007537842 3.967289924621582 12.5 7 12.5 C 10.03271007537842 12.5 12.5 10.03271007537842 12.5 7 C 12.5 3.967289924621582 10.03271007537842 1.5 7 1.5 M 7 0 C 10.86598968505859 0 14 3.134010314941406 14 7 C 14 10.86598968505859 10.86598968505859 14 7 14 C 3.134010314941406 14 0 10.86598968505859 0 7 C 0 3.134010314941406 3.134010314941406 0 7 0 Z"
              stroke="none"
              fill="#8a8a8e"
            />
          </g>
        </g>
        <g transform="matrix(0, -1, 1, 0, 79, 148.3)" filter="url(#楕円形_49)">
          <g
            id="楕円形_49-2"
            data-name="楕円形 49"
            transform="translate(26.3 10) rotate(90)"
            fill="#885eb7"
            stroke="#8a8a8e"
            stroke-width="1.5"
          >
            <ellipse cx="3" cy="2.5" rx="3" ry="2.5" stroke="none" />
            <ellipse cx="3" cy="2.5" rx="2.25" ry="1.75" fill="none" />
          </g>
        </g>
        <g transform="matrix(0, -1, 1, 0, 79, 148.3)" filter="url(#楕円形_55)">
          <g
            id="楕円形_55-2"
            data-name="楕円形 55"
            transform="translate(21.08 9.46) rotate(-135)"
            fill="#885eb7"
            stroke="#8a8a8e"
            stroke-width="1.5"
          >
            <ellipse cx="3" cy="2.5" rx="3" ry="2.5" stroke="none" />
            <ellipse cx="3" cy="2.5" rx="2.25" ry="1.75" fill="none" />
          </g>
        </g>
        <g transform="matrix(0, -1, 1, 0, 79, 148.3)" filter="url(#楕円形_56)">
          <g
            id="楕円形_56-2"
            data-name="楕円形 56"
            transform="translate(6.23 24.31) rotate(-135)"
            fill="#885eb7"
            stroke="#8a8a8e"
            stroke-width="1.5"
          >
            <ellipse cx="3" cy="2.5" rx="3" ry="2.5" stroke="none" />
            <ellipse cx="3" cy="2.5" rx="2.25" ry="1.75" fill="none" />
          </g>
        </g>
        <g transform="matrix(0, -1, 1, 0, 79, 148.3)" filter="url(#楕円形_51)">
          <g
            id="楕円形_51-2"
            data-name="楕円形 51"
            transform="translate(16.3 26) rotate(180)"
            fill="#885eb7"
            stroke="#8a8a8e"
            stroke-width="1.5"
          >
            <ellipse cx="3" cy="2.5" rx="3" ry="2.5" stroke="none" />
            <ellipse cx="3" cy="2.5" rx="2.25" ry="1.75" fill="none" />
          </g>
        </g>
        <g transform="matrix(0, -1, 1, 0, 79, 148.3)" filter="url(#楕円形_52)">
          <g
            id="楕円形_52-2"
            data-name="楕円形 52"
            transform="translate(16.3 5) rotate(180)"
            fill="#885eb7"
            stroke="#8a8a8e"
            stroke-width="1.5"
          >
            <ellipse cx="3" cy="2.5" rx="3" ry="2.5" stroke="none" />
            <ellipse cx="3" cy="2.5" rx="2.25" ry="1.75" fill="none" />
          </g>
        </g>
        <g transform="matrix(0, -1, 1, 0, 79, 148.3)" filter="url(#楕円形_53)">
          <g
            id="楕円形_53-2"
            data-name="楕円形 53"
            transform="translate(24.61 20.07) rotate(135)"
            fill="#885eb7"
            stroke="#8a8a8e"
            stroke-width="1.5"
          >
            <ellipse cx="3" cy="2.5" rx="3" ry="2.5" stroke="none" />
            <ellipse cx="3" cy="2.5" rx="2.25" ry="1.75" fill="none" />
          </g>
        </g>
        <g transform="matrix(0, -1, 1, 0, 79, 148.3)" filter="url(#楕円形_54)">
          <g
            id="楕円形_54-2"
            data-name="楕円形 54"
            transform="translate(9.76 5.22) rotate(135)"
            fill="#885eb7"
            stroke="#8a8a8e"
            stroke-width="1.5"
          >
            <ellipse cx="3" cy="2.5" rx="3" ry="2.5" stroke="none" />
            <ellipse cx="3" cy="2.5" rx="2.25" ry="1.75" fill="none" />
          </g>
        </g>
        <g transform="matrix(0, -1, 1, 0, 79, 148.3)" filter="url(#楕円形_50)">
          <g
            id="楕円形_50-2"
            data-name="楕円形 50"
            transform="translate(5.3 10) rotate(90)"
            fill="#885eb7"
            stroke="#8a8a8e"
            stroke-width="1.5"
          >
            <ellipse cx="3" cy="2.5" rx="3" ry="2.5" stroke="none" />
            <ellipse cx="3" cy="2.5" rx="2.25" ry="1.75" fill="none" />
          </g>
        </g>
        <line
          id="線_100"
          data-name="線 100"
          y2="3"
          transform="translate(101 135) rotate(90)"
          fill="none"
          stroke="#8a8a8e"
          stroke-width="1.5"
        />
        <line
          id="線_101"
          data-name="線 101"
          y2="3"
          transform="translate(86 135) rotate(90)"
          fill="none"
          stroke="#8a8a8e"
          stroke-width="1.5"
        />
        <line
          id="線_104"
          data-name="線 104"
          y2="3"
          transform="translate(98.364 141.364) rotate(135)"
          fill="none"
          stroke="#8a8a8e"
          stroke-width="1.5"
        />
        <line
          id="線_105"
          data-name="線 105"
          y2="3"
          transform="translate(87.758 130.757) rotate(135)"
          fill="none"
          stroke="#8a8a8e"
          stroke-width="1.5"
        />
        <line
          id="線_103"
          data-name="線 103"
          y2="3"
          transform="translate(92 144) rotate(180)"
          fill="none"
          stroke="#8a8a8e"
          stroke-width="1.5"
        />
        <line
          id="線_102"
          data-name="線 102"
          y2="3"
          transform="translate(92 129) rotate(180)"
          fill="none"
          stroke="#8a8a8e"
          stroke-width="1.5"
        />
        <line
          id="線_106"
          data-name="線 106"
          y2="3"
          transform="translate(85.636 141.364) rotate(-135)"
          fill="none"
          stroke="#8a8a8e"
          stroke-width="1.5"
        />
        <line
          id="線_107"
          data-name="線 107"
          y2="3"
          transform="translate(96.242 130.757) rotate(-135)"
          fill="none"
          stroke="#8a8a8e"
          stroke-width="1.5"
        />
        <line
          id="線_112"
          data-name="線 112"
          y1="10"
          transform="translate(110.5 116)"
          fill="none"
          stroke="#8a8a8e"
          stroke-linecap="round"
          stroke-width="1.5"
        />
        <line
          id="線_113"
          data-name="線 113"
          y1="10"
          transform="translate(103.5 116)"
          fill="none"
          stroke="#8a8a8e"
          stroke-linecap="round"
          stroke-width="1.5"
        />
        <line
          id="線_114"
          data-name="線 114"
          y1="10"
          transform="translate(96.5 116)"
          fill="none"
          stroke="#8a8a8e"
          stroke-linecap="round"
          stroke-width="1.5"
        />
      </g>
    </g>
  </svg>
</template>
