<template>
  <div>
    <div class="d-flex flex-wrap">
      <FormItemSelector
        v-if="organizations.length > 1"
        :value="selectedOrganization"
        label="案件を作成する組織"
        form-id="organizationId"
        form-type="object"
        :items="organizations"
        value-key="id"
        show-key="name"
        :errors="errors"
        class="mb-3 me-2"
        @update:value="selectOrganization"
      />
      <FormItem
        v-model:value="workForm.name"
        label="案件名"
        :errors="errors"
        form-id="name"
        form-type="text"
        class="mb-3 me-2"
      />
      <template v-if="selectedOrganization">
        <div class="">
          <ContentLabel label="依頼組織" />
          <Dropdown
            v-model="workForm.requestOrganizationId"
            :options="requestOrganizations"
            option-label="name"
            option-value="id"
            placeholder="業務店舗を選択"
            :form-id="`selct-request-organization`"
            class="mb-3 me-2"
            :class="{
              'p-invalid': !workForm.requestOrganizationId,
            }"
            filter
            auto-filter-focus
            empty-filter-message="候補が見つかりませんでした"
          />
        </div>

        <FormItemSelector
          v-model:value="workForm.workCategory"
          label="カテゴリー"
          form-id="workCategory"
          form-type="general"
          :items="targetCategories"
          :show-fn="translater"
          :errors="errors"
          class="mb-3 me-2"
        />

        <template v-if="isTelecommunications">
          <FormItemSelector
            v-model:value="workForm.carrier"
            :errors="errors"
            :items="targetCarriers"
            :show-fn="translater"
            label="キャリア"
            form-id="carrier"
            form-type="general"
            class="mb-3 me-2"
          />

          <div>
            <ContentLabel label="エリア" />
            <SelectButton
              v-model="workForm.prefectures"
              :options="prefectureItems"
              option-label="name"
              option-value="value"
              multiple
              aria-labelledby="multiple"
              class="mb-3 me-2"
            />
          </div>
        </template>
      </template>
    </div>

    <template v-if="isTelecommunications">
      <div class="mb-3">
        <LoadingAnimation v-if="!workPlaces" />
        <PlaceSelector
          v-else
          v-model:selected-place-ids="workForm.workPlaceIds"
          :work-places="workPlaces"
          :disabled-ids="selectedWorkPlaceIdsInSchedules"
          form-id="workPlaceIds"
          :selected-carriers="workForm.carrier ? [workForm.carrier] : []"
          :selected-prefectures="workForm.prefectures || []"
          :errors="errors"
        />
      </div>

      <div class="mb-3">
        <ContentLabel label="業務種別" />
        <SelectButton
          v-model="workForm.workTypeIds"
          :options="workTypeItems"
          option-label="name"
          option-value="value"
          multiple
          aria-labelledby="multiple"
          class="mb-3 me-2"
          :class="{
            'p-invalid':
              !workForm.workTypeIds || workForm.workTypeIds.length < 1,
          }"
        />
      </div>

      <div v-if="workForm.workConfig && false" class="mb-3">
        <ConfigForm
          v-model:need-body-temperature="
            workForm.workConfig.needBodyTemperature
          "
          v-model:need-antibody-test-within="
            workForm.workConfig.needAntibodyTestWithin
          "
        />
      </div>

      <StartFinishSelector
        v-model:start-on="workForm.startOn"
        v-model:finish-on="workForm.finishOn"
        :errors="errors"
        class="d-flex flex-wrap"
      />

      <FormItemWrapper
        form-id="workSchedules"
        :errors="errors"
        label="業務スケジュール"
        error-message="スケジュールの入力に問題があります"
      >
        <AddWorkSchedules
          v-model:work-schedules="workForm.workSchedules"
          :min-date="workForm.startOn"
          :max-date="workForm.finishOn"
          :default-prms="{
            workCategory: workForm.workCategory,
            needBodyTemperature: workForm.workConfig?.needBodyTemperature,
            needAntibodyTestWithin: workForm.workConfig?.needAntibodyTestWithin,
          }"
          class="mb-3"
        />

        <WorkSchedulesEditor
          v-if="workForm.workSchedules"
          v-model:work-schedules="workForm.workSchedules"
          :work-places="selectedWorkPlaces"
          :work-types="selectedWorkTypes"
          :event-places="selectedEventPlaces"
          :organization-members="organizationMembers"
          class="work-schdelue-wrapper"
        />
      </FormItemWrapper>

      <MailerForm
        v-if="workForm.workConfigMailers"
        v-model:work-config-mailers="workForm.workConfigMailers"
      />

      <WorkMeetingTargetConfigForm
        v-if="workForm.workCategory && workForm.carrier && false"
        v-model:value="workForm.scheduleGroupType"
        v-model:meeting-target-filter="workForm.meetingTargetFilter"
        :work-type-categories="workTypeCategories"
        :work-category="workForm.workCategory"
        :work-carrier="workForm.carrier"
        :requset-organization-id="workForm.requestOrganizationId"
        :basis-report-items="basisReportItems"
        :now="workForm?.startOn"
        :errors="errors"
        class="card card-body"
      />

      <OrganizationMemberSelector
        v-if="false"
        v-model:assigned-member-ids="workForm.assignedMemberIds"
        :organization-members="organizationMembers"
      />
    </template>

    <div class="d-flex py-3">
      <BasicButton
        :disabled="loading"
        button-type="button"
        variant="danger"
        class="me-1"
        label="キャンセル"
        icon="pi pi-times-circle"
        @click="$emit('cancel')"
      />

      <BasicButton
        :disabled="loading"
        button-type="submit"
        icon="pi pi-upload"
        variant="warning"
        class="ms-1"
        :label="submitText"
        @click="submit"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import Dropdown from "primevue/dropdown";
import SelectButton from "primevue/selectbutton";
import { computed, onMounted, onUpdated, watchEffect, watch, ref } from "vue";
import { translater } from "/@/modules/string";
import { errorHandle } from "/@/modules/error";
import { removeUndefinedFilterFn } from "/@/modules/array";
import { useZodScheme } from "/@/vue/composables";
import {
  FormItem,
  FormItemSelector,
  FormItemWrapper,
} from "/@/vue/components/Molecules";
import {
  OrganizationMemberSelector,
  AddWorkSchedules,
  WorkSchedulesEditor,
  ConfigForm,
} from "/@/vue/components/Organisms";
import {
  BasicButton,
  LoadingAnimation,
  ContentLabel,
} from "/@/vue/components/Atom";
import {
  PlaceSelector,
  StartFinishSelector,
  MailerForm,
  WorkMeetingTargetConfigForm,
} from ".";
import { WorkScheme, carriers, workCategories, prefectures } from "/@/types";
import type {
  Work,
  WorkForm,
  Organization,
  OrganizationMember,
  OrganizationCategory,
  WorkPlace,
  WorkTypeClient,
  Carrier,
  Prefecture,
  EventPlaceClient,
  BasisReportItemClient,
} from "/@/types";

const props = withDefaults(
  defineProps<{
    submitText?: string;
    organizations: Organization[];
    requestOrganizations: Organization[];
    loading?: boolean;
    workPlaces: WorkPlace[];
    workTypes: WorkTypeClient[];
    workTypeCategories: OrganizationCategory[];
    basisReportItems: BasisReportItemClient[];
    eventPlaces: EventPlaceClient[];
    searching?: boolean;
    organizationMembers: OrganizationMember[];
    editWork?: WorkForm;
    isHomeworker?: boolean;
  }>(),
  {
    submitText: "作成",
    editWork: undefined,
  }
);

const emit = defineEmits<{
  (e: "selectOrganization", organization: Organization): void;
  (
    e: "searchWorkPlaces",
    categories: OrganizationCategory[],
    carrier: Carrier,
    prefectures: Prefecture[]
  ): void;
  (e: "cancel"): void;
  (e: "submit", work: Work): void;
}>();

// scheme

const { useFormAndErrors } = useZodScheme();
const {
  form: workForm,
  errors,
  startValidation,
} = useFormAndErrors<WorkForm>(WorkScheme);

function initForm() {
  if (props.editWork) {
    const {
      id,
      name,
      organizationId,
      requestOrganizationId,
      carrier,
      workCategory,
      startOn,
      finishOn,
      workPlaceIds,
      workTypeIds,
      prefectures,
      assignedMemberIds,
      workSchedules,
      workConfig,
      workConfigMailers,
      scheduleGroupType,
      meetingTargetFilter,
    } = props.editWork;

    workForm.id = id;
    workForm.name = name;
    workForm.organizationId = organizationId;
    workForm.requestOrganizationId = requestOrganizationId;
    workForm.carrier = carrier;
    workForm.workCategory = workCategory;
    workForm.startOn = startOn;
    workForm.finishOn = finishOn;
    workForm.workPlaceIds = workPlaceIds;
    workForm.workTypeIds = workTypeIds;
    workForm.prefectures = prefectures;
    workForm.assignedMemberIds = assignedMemberIds;
    workForm.workSchedules = workSchedules;
    workForm.workConfig = workConfig;
    workForm.workConfigMailers = workConfigMailers;
    workForm.scheduleGroupType = scheduleGroupType;
    workForm.meetingTargetFilter = meetingTargetFilter;
  } else {
    if (props.organizations.length > 0) {
      workForm.organizationId = props.organizations.at(0)?.id;
    }

    workForm.prefectures = [];
    workForm.workPlaceIds = [];
    workForm.workTypeIds = [];
    workForm.assignedMemberIds = [];
    workForm.workSchedules = [];
    workForm.workConfig = {
      needBodyTemperature: false,
      needAntibodyTestWithin: 0,
    };
    workForm.workConfigMailers = [];
    workForm.scheduleGroupType = "unnecessary";
    workForm.meetingTargetFilter = [];
  }
}

onMounted(() => {
  initForm();
});

onUpdated(() => {
  //initForm();
});

// searchWorkPlaces

watchEffect(() => {
  if (
    workForm.carrier &&
    workForm.prefectures &&
    workForm.prefectures.length >= 1
  ) {
    emit(
      "searchWorkPlaces",
      categories.value,
      workForm.carrier,
      workForm.prefectures
    );
  }
});

// selected

function selectOrganization(organization: Organization) {
  workForm.organizationId = organization.id;
  emit("selectOrganization", organization);
}

const selectedOrganization = computed<Organization | undefined>(() => {
  return props.organizations.find((o) => o.id == workForm.organizationId);
});

// @ts-ignore
watch(selectedOrganization, (organization: Organization) => {
  emit("selectOrganization", organization);
});

// items

const targetCategories = computed(() => {
  if (props.isHomeworker) {
    return workCategories.filter((category) => category === "normal");
  }
  return workCategories.filter((category) => category !== "normal");
});

const targetCarriers = computed(() => {
  if (props.isHomeworker) {
    return carriers.filter((carrier) => carrier === "home");
  }

  return carriers.filter((carrier) => carrier !== "home");
});

const workTypeItems = computed(() => {
  if (!props.workTypes) {
    return [];
  }

  return props.workTypes
    .filter((wt) => wt.name !== "通常業務")
    .map((wt) => {
      return {
        value: wt.id,
        name: wt.name,
      };
    });
});

const targetPrefectures = computed(() => {
  if (props.isHomeworker) {
    return prefectures.filter((p) => p === "other");
  }

  return prefectures;
});

const prefectureItems = computed(() => {
  if (!selectedOrganization.value) {
    return [];
  }

  return targetPrefectures.value.map((p) => ({
    value: p,
    name: translater(p),
  }));
});

const categories = computed<OrganizationCategory[]>(() => {
  return selectedOrganization.value?.categories || [];
});

const isTelecommunications = computed<boolean>(() => {
  return !!selectedOrganization.value?.categories?.find(
    (c) => c == "telecommunications"
  );
});

const selectedWorkPlaces = computed<WorkPlace[]>(() => {
  if (!workForm.workPlaceIds) {
    return [];
  }

  return workForm.workPlaceIds
    .map((id) => props.workPlaces.find((wp) => wp.id == id))
    .filter(removeUndefinedFilterFn);
});

const selectedWorkTypes = computed(() => {
  if (!workForm.workTypeIds) {
    return [];
  }

  return workForm.workTypeIds
    .map((id) => props.workTypes.find((wt) => wt.id == id))
    .filter(removeUndefinedFilterFn);
});

const selectedEventPlaces = computed(() => {
  return props.eventPlaces;
});

const selectedWorkPlaceIdsInSchedules = computed(() => {
  return workForm.workSchedules
    ? [
        ...new Set(
          workForm.workSchedules?.map((schedule) => schedule.workPlaceId)
        ),
      ]
    : [];
});

// api

// submit

function submit() {
  try {
    startValidation.value = true;

    if (workForm.scheduleGroupType === "unnecessary") {
      workForm.meetingTargetFilter = undefined;
    }

    const work: Work = WorkScheme.parse(workForm);
    emit("submit", work);
  } catch (e) {
    errorHandle(e);
  }
}

// ui
</script>
<style scoped lang="scss"></style>
