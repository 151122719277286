<template>
  <div>
    <div
      class="card card-body"
      :class="{
        'need-handle': needHandle,
        'is-losted': isLosted,
        'is-contracted': isContracted,
      }"
    >
      <CollapseWrapper
        :contents-id="`work-task-titles-${workTask.id}`"
        without-body-padding
        :open-default="isSelected"
      >
        <template #header>
          <div class="w-100 d-flex flex-wrap">
            <div v-if="workTask.contractedAt" class="contracted-mark">
              <CheckBlueIcon class="icon" />
            </div>
            <FormItemShow
              label="種別"
              :content-id="`work-task-work-task-type-${workTask.id}`"
              class="me-3"
            >
              {{ translater(workTask.workTaskType) }}
            </FormItemShow>
            <FormItemShow
              :label="
                workTask.workTaskType === 'calling'
                  ? '架電予定日'
                  : '来店予定日'
              "
              :content-id="`work-task-action-at-${workTask.id}`"
              class="me-3"
            >
              {{ basicFormatter(workTask.actionAt, "noYear") }}
              {{ basicFormatter(workTask.actionAt, "onlyTime") }}
            </FormItemShow>
            <FormItemShow
              label="お客様情報"
              :content-id="`work-task-customer-info-${workTask.id}`"
            >
              {{ workTask.customerName }}様 {{ workTask.phoneNumber }}
            </FormItemShow>
          </div>
        </template>
        <template #contents>
          <FormItemShow
            :label="`${translater(workTask.workTaskType)}取得日`"
            :content-id="`work-task-record-entry-info-${workTask.id}`"
          >
            {{ basicFormatter(workTask.createdAt, "noYear") }} {{ entryTitle }}
          </FormItemShow>
          <FormItemShow
            v-if="workTask.contractedAt"
            label="成約日"
            :content-id="`work-task-contracted-at-${workTask.id}`"
          >
            {{ basicFormatter(workTask.contractedAt, "noYear") }}
          </FormItemShow>
          <FormItemShow
            v-if="workTask.lostedAt"
            label="失注日"
            :content-id="`work-task-losted-at-${workTask.id}`"
          >
            {{ basicFormatter(workTask.lostedAt) }}
          </FormItemShow>
          <FormItemShow
            label="手続き内容"
            :content-id="`work-task-contents-${workTask.id}`"
          >
            <WorkReportsShowForm
              :work-reports="[{ reportItems: workTask.reportItems }]"
              :work-category="workTask.entryWorkCategory"
              :work-carrier="workTask.entryWorkCarrier"
              :work-type-categories="workTask.entryWorkTypeCategories"
              :basis-report-items="basisReportItems"
              hide-label
            />
          </FormItemShow>
          <FormItemShow
            label="備考"
            :content-id="`work-task-remarks-${workTask.id}`"
          >
            <div>{{ workTask.remarks }}</div>
            <div v-if="workTask.countInContractedDate">・結果は成約日加算</div>
            <div v-if="workTask.lostReason">
              <span>・失注理由：{{ translater(workTask.lostReason) }}</span>
              <span v-if="workTask.lostReasonDetails">
                （{{ workTask.lostReasonDetails }}）
              </span>
            </div>
          </FormItemShow>

          <div class="d-flex justify-content-end">
            <div class="p-1">
              <BasicButton
                variant="warning"
                :label="isHome ? '編集ページへ' : '編集'"
                slim
                :disabled="isLockEdit"
                icon="pi pi-file-edit"
                @click="selectWorkTask(workTask)"
              />
            </div>
            <div
              v-if="!isHome && workTask.workTaskType !== 'calling'"
              class="p-1"
            >
              <BasicButton
                icon="pi pi-check-circle"
                :label="workTask.contractedAt ? '成約済' : '成約'"
                slim
                :disabled="isLockEdit"
                button-type="submit"
                @click="toggleContractWorkTask(workTask)"
              />
            </div>
            <div v-if="!isHome" class="p-1">
              <BasicButton
                variant="danger"
                slim
                :label="workTask.lostedAt ? '失注済' : '失注'"
                icon="pi pi-eraser"
                :disabled="isLockEdit"
                button-type="submit"
                @click="toggleLostWorkTask(workTask)"
              />
            </div>
          </div>
        </template>
      </CollapseWrapper>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { translater } from "/@/modules/string";
import { basicFormatter, isAfterToday } from "/@/modules/luxon";
import { workTaskEntryToTitle } from "/@/modules/workTask";
import { BasicButton, CheckBlueIcon } from "/@/vue/components/Atom";
import { FormItemShow, CollapseWrapper } from "/@/vue/components/Molecules";
import { WorkReportsShowForm } from "/@/vue/components/Organisms";
import { WorkTaskClient, BasisReportItemClient } from "/@/types";

const props = defineProps<{
  workTask: WorkTaskClient;
  basisReportItems: BasisReportItemClient[];
  isOwner?: boolean;
  isHome?: boolean;
  isSelected?: boolean;
}>();

const emit = defineEmits<{
  (e: "selectWorkTask", id: number): void;
  (e: "toggleContractWorkTask", id: number): void;
  (e: "toggleLostWorkTask", id: number): void;
}>();

function selectWorkTask(workTask: WorkTaskClient) {
  emit("selectWorkTask", workTask.id);
}

function toggleContractWorkTask(workTask: WorkTaskClient) {
  emit("toggleContractWorkTask", workTask.id);
}

function toggleLostWorkTask(workTask: WorkTaskClient) {
  emit("toggleLostWorkTask", workTask.id);
}

const entryTitle = computed(() => {
  return workTaskEntryToTitle(props.workTask);
});

const isLockEdit = computed(() => {
  return (
    !props.isOwner && !!(props.workTask.contractedAt || props.workTask.lostedAt)
  );
});

const isContracted = computed(() => {
  return props.workTask.contractedAt;
});

const isLosted = computed(() => {
  return props.workTask.lostedAt;
});

const needHandle = computed(() => {
  return (
    !props.workTask.contractedAt &&
    !props.workTask.lostedAt &&
    !isAfterToday(props.workTask.actionAt)
  );
});
</script>

<style scoped lang="scss">
.contracted-mark {
  position: absolute;
  top: 0;
  right: 0;
}

.need-handle {
  border-color: red;
}

.is-losted {
  opacity: 0.5;
}

.is-contracted {
  border-color: $primary;
}
</style>
