<template>
  <div>
    <div class="d-flex mb-2">
      <BasicButton
        :label="editMode ? '変更完了' : '数値変更'"
        :icon="editMode ? 'pi pi-check' : 'pi pi-pencil'"
        :variant="editMode ? 'success' : 'primary'"
        class="text-nowrap me-2"
        :button-type="editMode ? 'submit' : 'button'"
        @click="editMode ? submitMeetingTarget() : startEdit()"
      />
      <BasicButton
        v-if="editMode"
        label="キャンセル"
        variant="warning"
        class="text-nowrap"
        @click="cancelEdit"
      />
      <BasicButton
        v-if="!editMode && !isHome"
        label="項目の編集"
        icon="pi pi-pencil"
        variant="success"
        class="text-nowrap me-2"
        @click="openFormModal"
      />
      <BasicButton
        v-if="!editMode"
        :label="`${showDetails ? '詳細情報の表示' : '詳細情報の表示'}`"
        :variant="showDetails ? 'success' : 'primary'"
        class="text-nowrap"
        @click="showDetails = !showDetails"
      />
    </div>
    <div class="table-wrapper">
      <table
        class="table table-fixed table-bordered tabled-striped align-middle"
      >
        <thead>
          <tr>
            <th class="cell-name">{{ tableTitle }}</th>
            <th class="cell-item-content">目標更新日</th>
            <th class="cell-item-content col2" :colspan="2">
              {{ isoFormat(meetingTarget?.updatedAt, "yy/MM/dd HH:mm") || "-" }}
            </th>
            <th class="cell-item-content">更新者</th>
            <th class="cell-item-content">
              {{ meetingTarget?.organizationMemberName?.split(" ")[0] || "-" }}
            </th>
          </tr>
        </thead>
      </table>
      <table class="table table-bordered table-striped align-middle">
        <thead>
          <tr>
            <th class="cell-name" rowspan="2">項目</th>
            <template
              v-for="(item, idx) in targetReportItems"
              :key="`meeting-target-name-group-${item.key}`"
            >
              <th
                v-if="
                  item.basisReportItem?.groupId && !isPrevSameGroup(item, idx)
                "
                class="cell-item-content"
                :colspan="countCountinuousSameGroup(item, idx)"
              >
                <div class="d-flex flex-column">
                  <span>{{ item.basisReportItem?.groupName }}</span>
                  <template v-if="editMode && isOwner">
                    <ToggleButton
                      v-if="countCountinuousSameGroup(item, idx) > 1"
                      :model-value="form.reportItems?.[idx].summarizeTarget"
                      on-label="目標共有"
                      off-label="目標共有"
                      on-icon="pi pi-check"
                      off-icon="pi pi-times"
                      class="mb-1"
                      :disabled="!isOwner"
                      @update:model-value="
                        updateMeetingTargetReportItemChecker(
                          item.key,
                          $event,
                          'summarizeTarget'
                        )
                      "
                    />
                    <ToggleButton
                      v-if="countCountinuousSameGroup(item, idx) > 1"
                      :model-value="form.reportItems?.[idx].summarizeCurrent"
                      on-label="実績共有"
                      off-label="実績共有"
                      on-icon="pi pi-check"
                      off-icon="pi pi-times"
                      :disabled="!isOwner"
                      @update:model-value="
                        updateMeetingTargetReportItemChecker(
                          item.key,
                          $event,
                          'summarizeCurrent'
                        )
                      "
                    />
                  </template>
                </div>
              </th>
              <th
                v-else-if="!item.basisReportItem?.groupId"
                class="cell-item-content"
                :rowspan="existsInGroupItem ? 2 : 1"
              >
                {{ item.basisReportItem?.shortName || item.key }}
              </th>
            </template>
          </tr>
          <tr v-if="existsInGroupItem">
            <template v-if="meetingTarget">
              <template
                v-for="item in targetReportItems"
                :key="`meeting-target-name-${item.key}`"
              >
                <th
                  v-if="item.basisReportItem?.groupId"
                  class="cell-item-content"
                >
                  {{ item.basisReportItem?.shortName || item.key }}
                </th>
              </template>
            </template>
            <td v-else>目標がありません</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="cell-name">計画値</td>
            <template v-if="meetingTarget">
              <template
                v-for="(item, idx) in targetReportItems"
                :key="`meeting-target-count-${item.key}`"
              >
                <td
                  v-if="showItem('summarizeTarget', idx)"
                  class="cell-item-content"
                  :colspan="getColspan('summarizeTarget', idx)"
                  :class="{
                    edit: editMode,
                  }"
                >
                  <span v-if="!editMode || !isOwner">
                    {{ meetingTargetEachKeyMap[item.key]?.count || 0 }}
                  </span>
                  <NumberForm
                    v-else-if="form.reportItems?.[idx]"
                    v-model:value="form.reportItems[idx].count"
                    :form-id="`meeting-target-report-form-target-${item.key}`"
                    :items="countNumbers(item)"
                  />
                </td>
              </template>
            </template>
          </tr>
          <tr v-if="showDetails">
            <td class="cell-name">計画達成率</td>
            <template v-if="meetingTarget">
              <template
                v-for="(item, idx) in targetReportItems"
                :key="`meeting-target-parcent-${item.key}`"
              >
                <td
                  v-if="showItem('summarizeTarget', idx)"
                  class="cell-item-content"
                  :colspan="getColspan('summarizeTarget', idx)"
                  :class="{
                    edit: editMode,
                  }"
                >
                  <span v-if="!editMode || !isOwner">
                    {{ meetingTargetEachKeyMap[item.key]?.targetParcent }}%
                  </span>
                  <NumberForm
                    v-else-if="form.reportItems?.[idx]"
                    :value="form.reportItems[idx].targetParcent"
                    :form-id="`meeting-target-report-form-target-parcent-${item.key}`"
                    :items="countParcents"
                    :is-valid="!!form.reportItems[idx].targetParcent"
                    @update:value="
                      updateMeetingTargetReportItemChecker(
                        item.key,
                        $event,
                        'targetParcent'
                      )
                    "
                  />
                </td>
              </template>
            </template>
          </tr>
          <tr>
            <td class="cell-name">全体実績</td>
            <template v-if="meetingTarget">
              <template
                v-for="(item, idx) in targetReportItems"
                :key="`meeting-target-count-${item.key}`"
              >
                <td
                  v-if="showItem('summarizeCurrent', idx)"
                  class="cell-item-content"
                  :colspan="getColspan('summarizeCurrent', idx)"
                  :class="{
                    edit: editMode,
                  }"
                >
                  <span v-if="!editMode">
                    {{ meetingTargetEachKeyMap[item.key]?.currentCount || 0 }}
                  </span>
                  <NumberForm
                    v-else-if="form.reportItems?.[idx]"
                    v-model:value="form.reportItems[idx].currentCount"
                    :form-id="`meeting-target-report-form-current-${item.key}`"
                    :items="countNumbers(item)"
                  />
                </td>
              </template>
            </template>
          </tr>
          <tr v-if="showDetails">
            <td class="cell-name">進捗</td>
            <template v-if="meetingTarget">
              <template
                v-for="(item, idx) in targetReportItems"
                :key="`meeting-target-count-${item.key}`"
              >
                <td
                  v-if="showItem('summarizeCurrent', idx)"
                  :colspan="getColspan('summarizeCurrent', idx)"
                  class="cell-item-content"
                >
                  {{
                    editMode
                      ? progressRate(
                          form.reportItems?.[idx].currentCount || 0,
                          form.reportItems?.[idx].count || 0
                        )
                      : progressRate(
                          meetingTargetEachKeyMap[item.key]?.currentCount || 0,
                          meetingTargetEachKeyMap[item.key]?.count || 0
                        )
                  }}%
                </td>
              </template>
            </template>
          </tr>
          <tr v-if="showDetails && false">
            <td class="cell-name">FRYTH実績</td>
            <template v-if="meetingTarget">
              <template
                v-for="(item, idx) in targetReportItems"
                :key="`meeting-target-count-${item.key}`"
              >
                <td
                  v-if="showItem('summarizeCurrent', idx)"
                  class="cell-item-content"
                  :colspan="getColspan('summarizeCurrent', idx)"
                  :class="{
                    edit: editMode,
                  }"
                >
                  <span v-if="!editMode || !isOwner">
                    {{
                      meetingTargetEachKeyMap[item.key]
                        ?.currentOrganizationCount || 0
                    }}
                  </span>
                  <NumberForm
                    v-else-if="form.reportItems?.[idx]"
                    v-model:value="
                      form.reportItems[idx].currentOrganizationCount
                    "
                    :form-id="`meeting-target-report-form-current-organization-${item.key}`"
                    :items="countNumbers(item)"
                  />
                </td>
              </template>
            </template>
          </tr>
          <tr v-if="showDetails && false">
            <td class="cell-name">FRYTH寄与率</td>
            <template v-if="meetingTarget">
              <template
                v-for="(item, idx) in targetReportItems"
                :key="`meeting-target-count-${item.key}`"
              >
                <td
                  v-if="showItem('summarizeCurrent', idx)"
                  :colspan="getColspan('summarizeCurrent', idx)"
                >
                  {{
                    editMode
                      ? progressRate(
                          form.reportItems?.[idx].currentOrganizationCount || 0,
                          form.reportItems?.[idx].currentCount || 0
                        )
                      : progressRate(
                          meetingTargetEachKeyMap[item.key]
                            ?.currentOrganizationCount || 0,
                          meetingTargetEachKeyMap[item.key]?.currentCount || 0
                        )
                  }}%
                </td>
              </template>
            </template>
          </tr>
        </tbody>
      </table>
    </div>

    <MeetingTargetViewer
      v-if="meetingTarget && false"
      :meeting-targets="meetingTarget"
      :basis-report-items="basisReportItems"
      :organization="organization"
      only-show-related-table
      :is-owner="isOwner"
      class="mt-3"
      @update-work-report="$emit('updateWorkReport', $event)"
      @select-work-report="$emit('selectWorkReport', $event)"
      @create-work-report="$emit('createWorkReport', $event)"
    />

    <Divider v-if="isShowDivider" class="mb-3" />
    <teleport to="body">
      <MeetingTargetWorkPlaceFormModal
        ref="formModal"
        :work-places="[workPlace]"
        :basis-report-items="basisReportItems"
        :added-form-items="addedFormItems"
        :selected-date="selectedDate"
        :selected-carrier="workCarrier"
        @add-meeting-target-added-form-item="
          $emit('addMeetingTargetAddedFormItem', $event)
        "
        @destroy-meeting-target-added-form-item="
          $emit('destroyMeetingTargetAddedFormItem', $event)
        "
        @submit="$emit('updateMeetingTargetWorkPlace', $event)"
      />
    </teleport>
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";
import { translater } from "/@/modules/string";
import { computed, ref, reactive } from "vue";
import { isSameMonth, isoFormat } from "/@/modules/luxon";
import { BasicButton, NumberForm } from "/@/vue/components/Atom";
import {
  MeetingTargetViewer,
  MeetingTargetWorkPlaceFormModal,
} from "/@/vue/components/Organisms";
import {
  Carrier,
  WorkPlace,
  DateMap,
  BasisReportItemClient,
  Organization,
  MeetingTargetWorkPlace,
  MeetingTargetWorkPlaceClient,
  MeetingTargetReportItem,
  WorkReportClient,
  WorkRecordEntryReportForm,
  MeetingTargetAddedFormItemClient,
  MeetingTargetWorkPlaceForm,
  MeetingTargetWorkPlaceScheme,
} from "/@/types";
import Divider from "primevue/divider";
import ToggleButton from "primevue/togglebutton";
import { useZodScheme } from "/@/vue/composables";
import { errorHandle } from "/@/modules/error";

const props = defineProps<{
  workPlace: WorkPlace;
  workCarrier: Carrier;
  selectedDate: DateMap;
  basisReportItems: BasisReportItemClient[];
  addedFormItems: MeetingTargetAddedFormItemClient[];
  meetingTargets: MeetingTargetWorkPlaceClient[];
  organization: Organization;
  isOwner?: boolean;
  isShowDivider?: boolean;
  isHome?: boolean;
}>();

const emit = defineEmits<{
  (e: "updateMeetingTargetWorkPlace", prms: MeetingTargetWorkPlace): void;
  (e: "updateWorkReport", workReport: WorkRecordEntryReportForm): void;
  (e: "selectWorkReport", workReport: WorkReportClient): void;
  (e: "createWorkReport", workReport: WorkRecordEntryReportForm): void;
  (e: "addMeetingTargetAddedFormItem", prms: { name: string }): void;
  (e: "destroyMeetingTargetAddedFormItem", id: number): void;
}>();

const editMode = ref<boolean>(false);
const showDetails = ref<boolean>(props.isOwner);

const meetingTarget = computed(() => {
  return props.meetingTargets.find(
    (mt) =>
      mt.workPlaceId === props.workPlace.id &&
      isSameMonth(props.selectedDate, mt.targetDate)
  );
});

const meetingTargetEachKeyMap = computed(() => {
  let m: Record<string, MeetingTargetReportItem> = {};

  if (!meetingTarget.value) return m;

  for (const item of meetingTarget.value.reportItems) {
    m[item.key] = item;
  }

  return m;
});

const tableTitle = computed(() => {
  if (!meetingTarget.value) return "";

  let title = `${translater(meetingTarget.value.workPlaceCarrier, true)}${
    props.workPlace.shortName || props.workPlace.name
  }`;

  if (meetingTarget.value.workPlaceCarrier === "docomo") {
    title += `店`;
  }

  return title;
});

function progressRate(current: number, target: number) {
  if (target === 0) return 0;

  return Math.round((current / target) * 100);
}

const targetReportItems = computed(() => {
  if (!meetingTarget.value) return [];

  return meetingTarget.value.reportItems.map((item) => {
    const basisReportItem = props.basisReportItems.find(
      (b) => b.id === item.basisReportItemId
    );

    return {
      ...item,
      basisReportItem,
    };
  });
});

const existsInGroupItem = computed(() => {
  return targetReportItems.value.some((item) => item.basisReportItem?.groupId);
});

// update

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } =
  useFormAndErrors<MeetingTargetWorkPlaceForm>(MeetingTargetWorkPlaceScheme);

function startEdit() {
  editMode.value = true;

  form.id = meetingTarget.value?.id;
  form.workPlaceId = meetingTarget.value?.workPlaceId;
  form.targetDate = meetingTarget.value?.targetDate;
  form.organizationId = meetingTarget.value?.organizationId;
  form.organizationMemberId = meetingTarget.value?.organizationMemberId;
  form.reportItems = targetReportItems.value.map((item) => {
    const basisReportItem = props.basisReportItems.find(
      (b) => b.id === item.basisReportItemId
    );

    return {
      key: item.key,
      customers: item.customers,
      overall: item.overall,
      basisReportItemId: item.basisReportItemId,
      count: item.count,
      currentCount: item.currentCount,
      currentOrganizationCount: item.currentOrganizationCount,
      targetParcent: item.targetParcent,
      summarizeTarget: item.summarizeTarget,
      summarizeCurrent: item.summarizeCurrent,
      basisReportItem,
    };
  });
}

function submitMeetingTarget() {
  try {
    startValidation.value = true;

    if (form.reportItems?.some((ri) => !ri.targetParcent)) {
      window.alert("目標達成率が未設定の項目があります");
      return;
    }

    const meetingTargetPrms = MeetingTargetWorkPlaceScheme.parse(form);

    emit("updateMeetingTargetWorkPlace", meetingTargetPrms);
    editMode.value = false;
  } catch (e) {
    errorHandle(e);
  }
}

function cancelEdit() {
  editMode.value = false;
  Object.assign(form, reactive({}));
}

function updateMeetingTargetReportItemChecker(
  key: string,
  value: boolean | number,
  valueKey: "summarizeTarget" | "summarizeCurrent" | "targetParcent"
) {
  let prevResult:
    | (MeetingTargetReportItem & {
        basisReportItem?: BasisReportItemClient;
      })
    | undefined = undefined;
  let afterTarget = false;

  form.reportItems = form.reportItems?.map((item, idx) => {
    if (item.key === key) {
      afterTarget = true;
      prevResult = {
        ...item,
        summarizeTarget: !!item.summarizeTarget,
        summarizeCurrent: !!item.summarizeCurrent,
        [valueKey]: value,
      };
      return prevResult;
    } else {
      if (
        afterTarget &&
        isPrevSameGroup(item, idx, prevResult?.basisReportItem?.groupId)
      ) {
        if (valueKey === "summarizeTarget" || valueKey === "summarizeCurrent") {
          return {
            ...item,
            targetParcent: prevResult?.summarizeTarget
              ? prevResult.targetParcent
              : item.targetParcent,
            [valueKey]: prevResult?.[valueKey],
          };
        } else {
          // targetParcent
          return {
            ...item,
            targetParcent: prevResult?.summarizeTarget
              ? prevResult.targetParcent
              : item.targetParcent,
          };
        }
      }
    }

    return item;
  });
}

function countNumbers(
  reportItem: MeetingTargetReportItem & {
    basisReportItem: BasisReportItemClient;
  }
) {
  const unitRange = reportItem.basisReportItem?.unitRange || 1;
  return _.range(0, unitRange * 1000, unitRange);
}

const countParcents = computed(() => {
  return _.range(1, 201, 1);
});

function isSameGroup(
  a: MeetingTargetReportItem & {
    basisReportItem?: BasisReportItemClient;
  },
  b: MeetingTargetReportItem & {
    basisReportItem?: BasisReportItemClient;
  }
) {
  return (
    a.basisReportItem?.groupId &&
    a.basisReportItem?.groupId === b.basisReportItem?.groupId
  );
}

function isPrevSameGroup(
  reportItem: MeetingTargetReportItem & {
    basisReportItem?: BasisReportItemClient;
  },
  idx: number,
  targetGroupId?: number
): boolean {
  if (idx < 1) return false;
  let prevItem;

  if (editMode.value) {
    prevItem = form.reportItems?.[idx - 1];
  } else {
    prevItem = targetReportItems.value[idx - 1];
  }

  if (!prevItem) return false;
  if (targetGroupId && prevItem.basisReportItem?.groupId !== targetGroupId)
    return false;
  if (!reportItem.basisReportItem?.groupId) return false;
  if (isSameGroup(reportItem, prevItem)) return true;

  return false;
}

function countCountinuousSameGroup(
  reportItem: MeetingTargetReportItem & {
    basisReportItem?: BasisReportItemClient;
  },
  idx: number
): number {
  let nextItem;

  if (editMode.value) {
    nextItem = form.reportItems?.[idx + 1];
  } else {
    nextItem = targetReportItems.value[idx + 1];
  }

  if (!nextItem) return 1;
  if (!reportItem.basisReportItem?.groupId) return 1;
  if (isSameGroup(reportItem, nextItem))
    return 1 + countCountinuousSameGroup(nextItem, idx + 1);

  return 1;
}

function getItem(idx: number) {
  if (editMode.value) {
    return form.reportItems?.[idx];
  } else {
    return targetReportItems.value[idx];
  }
}

function showItem(
  targetSummarize: "summarizeTarget" | "summarizeCurrent",
  idx: number
) {
  const item = getItem(idx);

  // @ts-ignore
  return !(item[targetSummarize] && isPrevSameGroup(item, idx));
}

function getColspan(
  targetSummarize: "summarizeTarget" | "summarizeCurrent",
  idx: number
) {
  const item = getItem(idx);

  // @ts-ignore
  return item[targetSummarize] ? countCountinuousSameGroup(item, idx) : 1;
}

// modal

const formModal = ref();

function openFormModal() {
  formModal.value?.openModal({
    meetingTarget: meetingTarget.value,
    workPlaceId: props.workPlace.id,
    organizationId: props.organization.id,
    organizationMemberId: props.organization.organizationMemberId,
  });
}

function closeFormModal() {
  formModal.value?.closeModal();
}
</script>

<style scoped lang="scss">
.table-wrapper {
  max-width: 100%;
  overflow: scroll;
}
.cell-name {
  max-width: 110px;
  min-width: 110px;
}

.cell-label {
  max-width: 90px;
  min-width: 90px;
}

.cell-update {
  max-width: 130px;
  min-width: 130px;
}

.cell-item-content {
  max-width: 80px;
  min-width: 80px;

  &.edit {
    padding: 0;
  }

  &.col2 {
    max-width: 160px;
    min-width: 160px;
  }
}
.p-dropdown .p-dropdown-trigger {
  width: 2rem;
}
</style>
