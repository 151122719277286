import useSWRV from "swrv";
import { Ref } from "vue";
import { fetcher } from "/@/modules/axios";
import {
  AbsenceApplicationPlan,
  DateMap,
  Organization,
  OrganizationMemberSchedulePlanLight,
  OrganizationSchedulePlanLight,
  PlanMap,
  WorkAsPlansLight,
  WorkScheduleAsPlan,
} from "/@/types";
import { createDateParams } from "/@/modules/url";

export function usePlans() {
  function getPlansChildrenFn(
    key:
      | "absence_application_plans"
      | "work_as_plans"
      | "work_schedule_as_plans"
      | "organization_member_schedule_plans"
      | "organization_schedule_plans"
      | "assign_organization_schedule_plans",
    organization: Ref<Organization | undefined>,
    dateMap: Ref<DateMap | undefined> | undefined,
    isOwner: Ref<boolean | undefined>,
    page?: "plans_index"
  ) {
    if (!organization.value || !isOwner.value) {
      return null;
    }

    let uri = `/api/v1/organizations/${organization.value.id}/${key}`;

    const params = createDateParams(dateMap?.value);

    if (params) {
      uri += `?${params}`;
    }
    if (page) {
      uri += `&page=${page}`;
    }

    return uri;
  }

  function getAssignOrganizationSchedules(
    organization: Ref<Organization | undefined>,
    dateMap: Ref<DateMap | undefined> | undefined = undefined,
    isOwner: Ref<boolean | undefined>,
    options: { revalidateOnFocus: boolean } = { revalidateOnFocus: true }
  ) {
    return useSWRV<OrganizationSchedulePlanLight[]>(
      () =>
        getPlansChildrenFn(
          "assign_organization_schedule_plans",
          organization,
          dateMap,
          isOwner
        ),
      fetcher,
      options
    );
  }

  function getOrganizationSchedules(
    organization: Ref<Organization | undefined>,
    dateMap: Ref<DateMap | undefined> | undefined = undefined,
    isOwner: Ref<boolean | undefined>,
    options: { revalidateOnFocus: boolean } = { revalidateOnFocus: true }
  ) {
    return useSWRV<OrganizationSchedulePlanLight[]>(
      () =>
        getPlansChildrenFn(
          "organization_schedule_plans",
          organization,
          dateMap,
          isOwner
        ),
      fetcher,
      options
    );
  }

  function getOrganizationMemberSchedules(
    organization: Ref<Organization | undefined>,
    dateMap: Ref<DateMap | undefined> | undefined = undefined,
    isOwner: Ref<boolean | undefined>,
    options: { revalidateOnFocus: boolean } = { revalidateOnFocus: true }
  ) {
    return useSWRV<OrganizationMemberSchedulePlanLight[]>(
      () =>
        getPlansChildrenFn(
          "organization_member_schedule_plans",
          organization,
          dateMap,
          isOwner
        ),
      fetcher,
      options
    );
  }

  function getWorkScheduleAsPlans(
    organization: Ref<Organization | undefined>,
    dateMap: Ref<DateMap | undefined> | undefined = undefined,
    isOwner: Ref<boolean | undefined>,
    options: { revalidateOnFocus: boolean; page?: "plans_index" } = {
      revalidateOnFocus: true,
      page: undefined,
    }
  ) {
    return useSWRV<WorkScheduleAsPlan[]>(
      () =>
        getPlansChildrenFn(
          "work_schedule_as_plans",
          organization,
          dateMap,
          isOwner,
          options.page
        ),
      fetcher,
      options
    );
  }

  function getWorkAsPlans(
    organization: Ref<Organization | undefined>,
    dateMap: Ref<DateMap | undefined> | undefined = undefined,
    isOwner: Ref<boolean | undefined>,
    options: { revalidateOnFocus: boolean } = { revalidateOnFocus: true }
  ) {
    return useSWRV<WorkAsPlansLight[]>(
      () => getPlansChildrenFn("work_as_plans", organization, dateMap, isOwner),
      fetcher,
      options
    );
  }

  function getAbsenceApplicationPlans(
    organization: Ref<Organization | undefined>,
    dateMap: Ref<DateMap | undefined> | undefined = undefined,
    isOwner: Ref<boolean | undefined>,
    options: { revalidateOnFocus: boolean } = { revalidateOnFocus: true }
  ) {
    return useSWRV<AbsenceApplicationPlan[]>(
      () =>
        getPlansChildrenFn(
          "absence_application_plans",
          organization,
          dateMap,
          isOwner
        ),
      fetcher,
      options
    );
  }

  function getPlansFn(
    organization: Ref<Organization | undefined>,
    isOwner: Ref<boolean | undefined>,
    dateMap: Ref<DateMap | undefined> | undefined,
    options: { page?: "plans_index" } = {}
  ) {
    if (!organization.value || isOwner.value) {
      return null;
    }

    let uri = `/api/v1/organization_members/${organization.value.organizationMemberId}/plans`;

    const params = createDateParams(dateMap?.value);

    if (params) {
      uri += `?${params}`;
    }

    if (options.page) {
      uri += `&page=${options.page}`;
    }

    return uri;
  }

  function getPlanMaps(
    organization: Ref<Organization | undefined>,
    isOwner: Ref<boolean | undefined>,
    dateMap: Ref<DateMap | undefined> | undefined = undefined,
    options: { page?: "plans_index" } = {}
  ) {
    return useSWRV<PlanMap>(
      () => getPlansFn(organization, isOwner, dateMap, options),
      fetcher
    );
  }

  return {
    // for owner
    getAbsenceApplicationPlans,
    getWorkAsPlans,
    getWorkScheduleAsPlans,
    getOrganizationMemberSchedules,
    getOrganizationSchedules,
    getAssignOrganizationSchedules,

    // for member
    getPlanMaps,
  };
}
