<template>
  <div>
    <SimpleModal
      ref="modal"
      title="情報の更新"
      submit-text="更新"
      :loading="loading"
      @cancel="closeModal"
      @submit="handleSubmit"
    >
      <div v-if="selectedEventPlace" class="form-wrapper">
        <div class="delete-btn">
          <BasicButton
            small
            variant="danger"
            button-type="submit"
            icon="pi pi-trash"
            @click="$emit('destroy', selectedEventPlace.id)"
          />
        </div>

        <div class="mb-3">
          <ContentLabel label="選択したイベント会場" />
          <div>
            {{ selectedEventPlace.name }}
          </div>
        </div>

        <AdminEventPlaceForm
          v-model:name="form.name"
          v-model:short-name="form.shortName"
          v-model:prefecture="form.prefecture"
          v-model:meeting-place="form.meetingPlace"
          v-model:postcode="form.postcode"
          v-model:address="form.address"
          v-model:venue-name="form.venueName"
          v-model:gmap-url="form.gmapUrl"
          v-model:first-meeting-at="form.firstMeetingAt"
          v-model:meeting-at="form.meetingAt"
          v-model:start-at="form.startAt"
          v-model:finish-at="form.finishAt"
          v-model:event-place-attached-images="
            form.eventPlaceAttachedImagesAttributes
          "
          v-model:event-place-selected-places="
            form.eventPlaceSelectedPlacesAttributes
          "
          :work-places="workPlaces"
          :errors="errors"
        />
      </div>
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useZodScheme } from "/@/vue/composables";
import { ContentLabel, BasicButton, TrashIcon } from "/@/vue/components/Atom";
import { SimpleModal } from "/@/vue/components/Molecules";
import {
  EventPlaceClient,
  EventPlaceUpdate,
  EventPlaceUpdateScheme,
  EventPlaceUpdateForm,
  WorkPlace,
} from "/@/types";
import { errorHandle } from "/@/modules/error";
import AdminEventPlaceForm from "./AdminEventPlaceForm.vue";

const props = defineProps<{
  eventPlaces: EventPlaceClient[];
  workPlaces: WorkPlace[];
}>();

const emit = defineEmits<{
  (e: "submit", prms: EventPlaceUpdate): void;
  (e: "destroy", id: number): void;
}>();

const selectedEventPlace = ref<EventPlaceClient | undefined>();

//

const loading = ref<boolean>(false);

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } =
  useFormAndErrors<EventPlaceUpdateForm>(EventPlaceUpdateScheme);

function handleSubmit() {
  try {
    loading.value = true;
    startValidation.value = true;

    form.eventPlaceAttachedImagesAttributes =
      form.eventPlaceAttachedImagesAttributes?.filter((attr) => {
        return attr._destroy || (attr.name && attr.image);
      });

    const eventPlaceUpdateParams: EventPlaceUpdate =
      EventPlaceUpdateScheme.parse(form);
    emit("submit", eventPlaceUpdateParams);
    closeModal();
  } catch (e) {
    errorHandle(e);
    loading.value = false;
  }
}

const modal = ref();

function resetForm(eventPlace?: EventPlaceClient) {
  if (eventPlace) {
    const {
      id,
      name,
      shortName,
      prefecture,
      meetingPlace,
      postcode,
      address,
      venueName,
      gmapUrl,
      firstMeetingAt,
      meetingAt,
      startAt,
      finishAt,
      eventPlaceAttachedImages,
      eventPlaceSelectedPlaces,
    } = eventPlace;

    form.id = id;
    form.name = name || undefined;
    form.shortName = shortName || undefined;
    form.prefecture = prefecture;
    form.meetingPlace = meetingPlace || undefined;
    form.postcode = postcode || undefined;
    form.address = address || undefined;
    form.venueName = venueName || undefined;
    form.gmapUrl = gmapUrl || undefined;
    form.firstMeetingAt = firstMeetingAt || undefined;
    form.meetingAt = meetingAt || undefined;
    form.startAt = startAt || undefined;
    form.finishAt = finishAt || undefined;
    form.eventPlaceAttachedImages = eventPlaceAttachedImages;
    form.eventPlaceAttachedImagesAttributes = eventPlaceAttachedImages;
    form.eventPlaceSelectedPlaces = eventPlaceSelectedPlaces;
    form.eventPlaceSelectedPlacesAttributes = eventPlaceSelectedPlaces;
  }
}

function openModal(id: number) {
  selectedEventPlace.value = props.eventPlaces.find((ep) => ep.id === id);

  if (!selectedEventPlace.value) {
    return;
  }

  resetForm(selectedEventPlace.value);

  loading.value = false;
  modal.value.openModal();
}

function closeModal() {
  selectedEventPlace.value = undefined;
  modal.value.closeModal();
}

defineExpose({
  openModal,
  closeModal,
});
</script>

<style scoped lang="scss">
.form-wrapper {
  position: relative;

  .delete-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
}
</style>
