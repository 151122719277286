<template>
  <tr v-if="targetTasks.length">
    <td v-if="ownerMode" class="sticky"></td>
    <td class="sticky" :colspan="sumColSpan">{{ targetMonth }}月戻り成約</td>
    <ReportItemsTds
      :category-carrier-keys="categoryCarrierKeys"
      :customers-items="customersItems"
      :indivi-items="indiviItems"
      :overall-items="overallItems"
      :work-reports="[]"
      :tasks="targetTasks"
      :hide-map="hideMap"
      :show-edit-mode="showEditMode"
    />
  </tr>
</template>

<script setup lang="ts">
import { computed } from "vue";
import {
  CategoryCarrierKey,
  CategoryCarrierReportItemsMap,
  WorkTaskClient,
  DateMap,
  HideMap,
  HideMapBase,
  FilterKey,
} from "/@/types";

import ReportItemsTds from "./ReportItemsTds.vue";
import { isSameMonth } from "/@/modules/luxon";

const props = defineProps<{
  ownerMode: boolean;
  selectedDate: DateMap;
  contractedTasks: WorkTaskClient[];
  categoryCarrierKeys: CategoryCarrierKey[];
  customersItems: CategoryCarrierReportItemsMap;
  indiviItems: CategoryCarrierReportItemsMap;
  overallItems: CategoryCarrierReportItemsMap;
  hideMap: HideMap;
  hideMapBase: HideMapBase;
  showEditMode?: boolean;
  selectedFilter: FilterKey;
  showRequestOrganization?: boolean;
}>();

const targetMonth = computed(() => {
  return props.selectedDate.month;
});

const targetTasks = computed(() => {
  return props.contractedTasks.filter((task) => {
    if (!task.contractedAt) {
      throw new Error("unexpect uncontracted task.");
    }

    return isSameMonth(props.selectedDate, task.createdAt);
  });
});

const sumColSpan = computed(() => {
  let cols = 1;

  if (
    props.showRequestOrganization &&
    (props.showEditMode || !props.hideMapBase.requestOrganization)
  )
    cols++;
  if (props.showEditMode || !props.hideMapBase.workPlace) cols++;
  if (props.showEditMode || !props.hideMapBase.workCategory) cols++;

  if (props.selectedFilter !== "member") {
    if (props.showEditMode || !props.hideMapBase.workerNumber) cols++;
    if (props.showEditMode || !props.hideMapBase.workerNames) cols++;
  }

  return cols;
});
</script>

<style scoped></style>
