<template>
  <div :class="{ 'form-check': !withoutLabel }">
    <input
      :id="formId"
      :value="checkedValue"
      :class="{
        'btn-check': btnStyle,
        'form-check-input': !btnStyle,
        'is-invalid': !isValid,
        'without-label': withoutLabel,
      }"
      type="checkbox"
      :disabled="disabled"
      :checked="checked"
      @click="update"
    />
    <label
      v-if="label"
      :class="{
        'btn btn-outline-primary': btnStyle,
        'form-check-label': !btnStyle,
        'btn-sm': btnSlim,
      }"
      :for="formId"
    >
      {{ label }}
    </label>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    value: any;
    checkedValue?: any;
    uncheckedValue?: any;
    label?: string;
    formId: string;
    disabled?: boolean;
    isValid?: boolean;
    btnStyle?: boolean;
    btnSlim?: boolean;
    withoutLabel?: boolean;
  }>(),
  {
    label: undefined,
    checkedValue: true,
    uncheckedValue: undefined,
  }
);

const emit = defineEmits<{
  (e: "update:value", value: any): void;
}>();

const checked = computed(() => {
  if (props.value instanceof Array) {
    return !!props.value.find((v) => v == props.checkedValue);
  } else {
    return props.value == props.checkedValue;
  }
});

function update(e: Event) {
  if (e.target instanceof HTMLInputElement) {
    if (props.value instanceof Array) {
      if (props.value.find((v) => v == props.checkedValue)) {
        emit(
          "update:value",
          props.value.filter((v) => v != props.checkedValue)
        );
      } else {
        emit("update:value", [...props.value, props.checkedValue]);
      }
    } else {
      if (props.value) {
        emit("update:value", props.uncheckedValue);
      } else {
        emit("update:value", props.checkedValue);
      }
    }
  } else {
    throw new Error("予期せぬエラーが発生しました");
  }
}
</script>

<style scoped lang="scss">
.form-check-input.without-label {
  height: 1rem;
  width: 1rem;

  &:disabled {
    background-color: gray;
  }
}
</style>
