<template>
  <div>
    <FormItem
      :value="name"
      label="会場通称"
      :errors="errors"
      form-id="name"
      form-type="text"
      class="mb-3"
      @update:value="$emit('update:name', $event as string | undefined)"
    />

    <FormItem
      :value="shortName"
      label="省略名(未入力の場合は先頭3文字)"
      :errors="errors"
      form-id="shortName"
      form-type="text"
      class="mb-3"
      @update:value="$emit('update:shortName', $event as string | undefined)"
    />

    <FormItem
      :value="venueName"
      label="正式名称"
      :errors="errors"
      form-id="venueName"
      form-type="text"
      class="mb-3"
      @update:value="$emit('update:venueName', $event as string | undefined)"
    />

    <FormItemSelector
      :value="prefecture"
      label="エリア"
      form-type="general"
      :errors="errors"
      :items="prefectures"
      :show-fn="translater"
      form-id="prefecture"
      class="mb-3"
      @update:value="$emit('update:prefecture', $event)"
    />

    <FormItem
      :value="postcode"
      label="郵便番号"
      :errors="errors"
      form-id="postcode"
      form-type="text"
      class="mb-3"
      @update:value="$emit('update:postcode', $event as string | undefined)"
    />

    <FormItem
      :value="address"
      label="住所"
      :errors="errors"
      form-id="address"
      form-type="text"
      class="mb-3"
      @update:value="$emit('update:address', $event as string | undefined)"
    />

    <div class="gmap-form mb-3">
      <FormItem
        :value="gmapUrl"
        label="Gmap URL"
        :errors="errors"
        form-id="gmapUrl"
        form-type="text"
        class="mb-3"
        @update:value="$emit('update:gmapUrl', $event as string | undefined)"
      />
      <div class="d-flex generate-gmap-url-btn">
        <BasicButton class="me-2" @click="generateGmapUrl"> 生成 </BasicButton>
        <BasicButton @click="checkGmapUrl"> 確認 </BasicButton>
      </div>
    </div>

    <FormItem
      :value="meetingPlace"
      label="集合場所"
      :errors="errors"
      form-id="meetingPlace"
      form-type="text"
      class="mb-3"
      @update:value="$emit('update:meetingPlace', $event as string | undefined)"
    />

    <div class="d-flex mb-3">
      <FormItemTime
        :value="firstMeetingAt"
        label="初回集合"
        :start-time="{ hours: 9, minutes: 30 }"
        form-id="firstMeetingAt"
        :errors="errors"
        class="me-1"
        is-valid
        @update:value="
          $emit('update:firstMeetingAt', $event as string | undefined)
        "
      />

      <FormItemTime
        :value="meetingAt"
        label="連日集合"
        :start-time="{ hours: 9, minutes: 50 }"
        form-id="meetingAt"
        :errors="errors"
        class="ms-1"
        is-valid
        @update:value="$emit('update:meetingAt', $event as string | undefined)"
      />
    </div>

    <div class="d-flex mb-3">
      <FormItemTime
        :value="startAt"
        label="開始時間"
        :start-time="{ hours: 10, minutes: 0 }"
        form-id="startAt"
        :errors="errors"
        class="me-1"
        is-valid
        @update:value="$emit('update:startAt', $event as string | undefined)"
      />

      <FormItemTime
        :value="finishAt"
        label="終了時間"
        :start-time="{ hours: 19, minutes: 0 }"
        form-id="finishAt"
        :errors="errors"
        class="ms-1"
        is-valid
        @update:value="$emit('update:finishAt', $event as string | undefined)"
      />
    </div>

    <div class="mb-3">
      <ContentLabel label="関連店舗" content-id="relation-work-places" />
      <div class="w-100" :style="{ 'overflow-x': 'scroll' }">
        <MultiSelect
          :model-value="selectedWorkPlaces.map((wp) => wp.id)"
          :options="workPlaces"
          filter
          empty-filter-message="店舗が見つかりません"
          option-label="name"
          option-value="id"
          placeholder="表示する店舗を選択"
          without-auto-sort
          @update:model-value="updateSelectedPlace"
        />
      </div>
    </div>

    <div class="mb-3">
      <template
        v-for="(attachedImage, idx) in eventPlaceAttachedImages"
        :key="`select-image-${idx}`"
      >
        <div
          v-if="!attachedImage._destroy"
          class="card card-body mb-3 image-form-item"
        >
          <FormItem
            :value="attachedImage.name"
            label="画像名"
            :errors="{}"
            form-id="attatched-image-name"
            form-type="text"
            class="mb-3"
            @update:value="updateAttachedImageName($event, idx)"
          />
          <SelectImage
            :image="attachedImage.image"
            with-remove-button
            @update:image="updateAttachedImage($event, idx)"
          />
          <div v-if="attachedImage.imageUrl && !attachedImage.image">
            <ShowPhoto
              :photo-url="attachedImage.imageUrl"
              :alt="attachedImage.name || ''"
              class="w-100 d-flex justify-content-center"
            />
          </div>
          <div class="remove-btn">
            <BasicButton
              rounded
              small
              icon="pi pi-times"
              :with-shadow="false"
              variant="danger"
              @click="removeImage(idx)"
            />
          </div>
        </div>
      </template>
      <BasicButton
        label="画像を追加"
        icon="pi pi-upload"
        :disabled="someEmptyImage"
        @click="addImage"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { ZodFormattedErrors } from "/@/modules/zodUtils";
import { translater } from "/@/modules/string";
import {
  BasicButton,
  SelectImage,
  ContentLabel,
  MultiSelect,
} from "/@/vue/components/Atom";
import {
  FormItem,
  FormItemSelector,
  FormItemTime,
  ShowPhoto,
} from "/@/vue/components/Molecules";
import {
  WorkPlace,
  Prefecture,
  prefectures,
  EventPlaceAttachedImageForm,
  EventPlaceSelectedPlaceForm,
  PhotoDataObject,
} from "/@/types";
import { createGmapURL, openGmapInNewTab } from "/@/modules/address";

const props = defineProps<{
  name?: string;
  shortName?: string;
  prefecture?: Prefecture;
  postcode?: string;
  address?: string;
  venueName?: string;
  gmapUrl?: string;
  meetingPlace?: string;
  firstMeetingAt?: string;
  meetingAt?: string;
  startAt?: string;
  finishAt?: string;
  eventPlaceAttachedImages: EventPlaceAttachedImageForm[];
  eventPlaceSelectedPlaces: EventPlaceSelectedPlaceForm[];
  workPlaces: WorkPlace[];
  errors: ZodFormattedErrors;
}>();

const emit = defineEmits<{
  (e: "update:name", val?: string | undefined): void;
  (e: "update:shortName", val?: string | undefined): void;
  (e: "update:prefecture", val?: string | undefined): void;
  (e: "update:postcode", val?: string | undefined): void;
  (e: "update:address", val?: string | undefined): void;
  (e: "update:venueName", val?: string | undefined): void;
  (e: "update:gmapUrl", val?: string | undefined): void;
  (e: "update:meetingPlace", val?: string | undefined): void;
  (e: "update:firstMeetingAt", val?: string | undefined): void;
  (e: "update:meetingAt", val?: string | undefined): void;
  (e: "update:startAt", val?: string | undefined): void;
  (e: "update:finishAt", val?: string | undefined): void;
  (
    e: "update:eventPlaceAttachedImages",
    val?: EventPlaceAttachedImageForm[]
  ): void;
  (
    e: "update:eventPlaceSelectedPlaces",
    val?: EventPlaceSelectedPlaceForm[]
  ): void;
}>();

// work place

const selectedWorkPlaces = computed(() => {
  if (!props.eventPlaceSelectedPlaces) return [];

  return props.eventPlaceSelectedPlaces.map((r) =>
    props.workPlaces.find((wp) => wp.id === r.workPlaceId)
  );
});

function updateSelectedPlace(placeIds: number[]) {
  emit(
    "update:eventPlaceSelectedPlaces",
    placeIds.map((id) => ({
      workPlaceId: id,
    }))
  );
}

// gmap

function generateGmapUrl() {
  const before = props.gmapUrl;
  const after = createGmapURL(props.name, [
    props.postcode ? props.postcode : "",
    props.prefecture ? translater(props.prefecture) : "",
    props.address ? props.address : "",
  ]);

  emit("update:gmapUrl", after);

  if (!before || before === after) {
    return;
  }

  window.alert(
    "GmapのURLが更新されました。確認ボタンで生成されたURLを確認してください"
  );
}

function checkGmapUrl() {
  openGmapInNewTab(props.gmapUrl);
}

const someEmptyImage = computed(() => {
  if (!props.eventPlaceAttachedImages) {
    return false;
  }

  return props.eventPlaceAttachedImages.some(
    (image) =>
      !image._destroy && (!image.name || (!image.image && !image.imageUrl))
  );
});

function addImage() {
  if (someEmptyImage.value) {
    return;
  }
  const current = props.eventPlaceAttachedImages;

  emit("update:eventPlaceAttachedImages", [
    ...current,
    { name: undefined, image: undefined },
  ]);
}

function removeImage(idx: number) {
  const target = props.eventPlaceAttachedImages[idx];
  const front = props.eventPlaceAttachedImages.slice(0, idx);
  const back = props.eventPlaceAttachedImages.slice(idx + 1);

  emit("update:eventPlaceAttachedImages", [
    ...front,
    { ...target, _destroy: 1 },
    ...back,
  ]);
}

function updateAttachedImageName(name: string, idx: number) {
  const front = props.eventPlaceAttachedImages.slice(0, idx);
  const back = props.eventPlaceAttachedImages.slice(idx + 1);
  const target = props.eventPlaceAttachedImages[idx];

  emit("update:eventPlaceAttachedImages", [
    ...front,
    { ...target, name },
    ...back,
  ]);
}

function updateAttachedImage(image: PhotoDataObject, idx: number) {
  const front = props.eventPlaceAttachedImages.slice(0, idx);
  const back = props.eventPlaceAttachedImages.slice(idx + 1);
  const target = props.eventPlaceAttachedImages[idx];

  emit("update:eventPlaceAttachedImages", [
    ...front,
    { ...target, image },
    ...back,
  ]);
}
</script>

<style scoped lang="scss">
.gmap-form {
  position: relative;
  .generate-gmap-url-btn {
    position: absolute;
    top: -0.25rem;
    left: 80px;
    > .btn {
      width: 60px;
      height: 25px;
      font-size: 80%;
    }
  }
}

.image-form-item {
  position: relative;

  .remove-btn {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
  }
}
</style>

<style>
.p-multiselect-panel {
  z-index: 1100 !important;
}
</style>
