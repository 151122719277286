<template>
  <div>
    <BasicCard v-if="successUpdateEmail" with-shadow class="mb-3">
      <span class="half-small">
        登録したメールアドレスに認証メールを送信しました。メールを確認し認証してください。また、認証が行われるまでは現在のメールアドレスが利用されます。
      </span>
    </BasicCard>
    <ContentCard title="基本情報">
      <FormItem
        :value="name"
        :errors="errors"
        class="mb-3"
        label="アカウント名"
        form-id="name"
        form-type="text"
        readonly
      />
      <div class="d-flex align-items-center justify-content-between mb-1">
        <ContentLabel label="メールアドレス" content-id="email" />
        <div class="edit-btn-wrapper">
          <EditButton v-show="!editMode" @click="startEdit" />
        </div>
      </div>
      <FormItem
        v-model:value="updateEmailForm.email"
        :errors="errors"
        label=""
        form-id="email"
        form-type="email"
        :readonly="!editMode"
      />

      <div v-if="editMode" class="pt-4">
        <div class="d-flex">
          <BasicButton
            icon="pi pi-arrow-left"
            label="戻る"
            class="me-3"
            @click="cancelEdit"
          />
          <BasicButton
            variant="danger"
            class="ms-3"
            icon="pi pi-refresh"
            label="変更する"
            button-type="submit"
            :disabled="loading"
            @click="updateEmail"
          />
        </div>
      </div>

      <LoadingAnimation v-if="loading" class="mt-3" />
    </ContentCard>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { errorHandle } from "/@/modules/error";
import { useZodScheme } from "/@/vue/composables";
import {
  BasicCard,
  ContentCard,
  ContentLabel,
  EditButton,
  BasicButton,
  LoadingAnimation,
} from "/@/vue/components/Atom";
import { FormItem } from "/@/vue/components/Molecules";

const props = defineProps<{
  name: string;
  currentEmail: string;
  successUpdateEmail?: boolean;
  loading?: boolean;
}>();

const emit = defineEmits<{
  (e: "updateEmail", email: string): void;
}>();

const editMode = ref(false);

const UpdateEmailSchemeBase = z.object({
  email: z.string().email(),
});

const UpdateEmailFormScheme = UpdateEmailSchemeBase.partial();

type UpdateEmailForm = zod.infer<typeof UpdateEmailFormScheme>;

const UpdateEmailScheme = UpdateEmailSchemeBase.refine(
  (data) => data.email !== props.currentEmail,
  {
    message: "メールアドレスが変更されていません",
    path: ["email"],
  }
);

const { useFormAndErrors } = useZodScheme();

const {
  form: updateEmailForm,
  errors,
  startValidation,
} = useFormAndErrors<UpdateEmailForm>(UpdateEmailScheme);

onMounted(() => {
  updateEmailForm.email = props.currentEmail;
});

function updateEmail() {
  try {
    startValidation.value = true;
    const updateEmail = UpdateEmailScheme.parse(updateEmailForm);
    emit("updateEmail", updateEmail.email);
    updateEmail.email = props.currentEmail;
    editMode.value = false;
  } catch (e) {
    errorHandle(e);
  }
}

function startEdit() {
  editMode.value = true;
  updateEmailForm.email = "";
}

function cancelEdit() {
  editMode.value = false;
  startValidation.value = false;
  updateEmailForm.email = props.currentEmail;
}
</script>
