<template>
  <div>
    <CollapseWrapper
      ref="collapse"
      :contents-id="`collapse-project-list-${project.id}`"
      card-header
      class="card"
      :class="{
        selected: selected,
      }"
      @toggle-collapse="isCollapseOpen = $event"
    >
      <template #header>
        <div class="d-flex justify-content-between w-100">
          <span>{{ project.name }}</span>
          <div class="me-2">
            <BasicButton
              v-if="isCollapseOpen"
              rounded
              small
              icon="pi pi-trash"
              variant="danger"
              @click="deleteProject"
            />
          </div>
        </div>
      </template>
      <template #contents>
        <FormItemShow
          label="開始日"
          :content-id="`project-${project.id}-created-at`"
        >
          {{ basicFormatter(project.createdAt) }}
        </FormItemShow>

        <BasicButton
          v-if="!selected"
          label="切り替え"
          icon="pi pi-arrows-h"
          @click="selectProject"
        />
      </template>
    </CollapseWrapper>
  </div>
</template>

<script setup lang="ts">
import { CollapseWrapper, FormItemShow } from "/@/vue/components/Molecules";
import { TaskProjectClient } from "/@/types";
import { basicFormatter } from "/@/modules/luxon";
import { BasicButton, TrashIcon } from "/@/vue/components/Atom";
import { ref } from "vue";

const props = defineProps<{
  project: TaskProjectClient;
  selected?: boolean;
}>();

const emits = defineEmits<{
  (e: "selectProject", id: number): void;
  (e: "deleteProject", id: number): void;
}>();

const isCollapseOpen = ref();

function selectProject(e: Event) {
  e.stopPropagation();
  emits("selectProject", props.project.id);
}

function deleteProject(e: Event) {
  e.stopPropagation();

  emits("deleteProject", props.project.id);
}
</script>

<style scoped lang="scss">
.card {
  background-color: lightsteelblue;

  &.selected {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    background-color: $primary;
    color: white !important;
  }
}
</style>
