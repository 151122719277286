import Index from "./Index.vue";
import Edit from "./Edit.vue";

export default [
  {
    path: "",
    name: "ManagementLabelsIndex",
    component: Index,
  },
  {
    path: "edit",
    name: "ManagementLabelsEdit",
    component: Edit,
  },
];
