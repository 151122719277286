<template>
  <div class="mb-3">
    <FormItem
      v-model:value="signUpForm.name"
      label="名前"
      :errors="errors"
      form-type="text"
      form-id="name"
    />
  </div>

  <div class="mb-3">
    <FormItem
      v-model:value="signUpForm.email"
      :errors="errors"
      label="メール"
      form-id="email"
      form-type="email"
    />
  </div>

  <div class="mb-3">
    <FormItem
      v-model:value="signUpForm.password"
      :errors="errors"
      label="パスワード"
      form-id="password"
      form-type="password"
    />
  </div>

  <div class="mb-3">
    <FormItem
      v-model:value="signUpForm.passwordConfirmation"
      :errors="
        errors.password ? { passwordConfirmation: { _errors: [] } } : errors
      "
      label="パスワード(確認)"
      form-id="passwordConfirmation"
      form-type="password"
    />
  </div>

  <div>
    <BasicButton
      :disabled="loading"
      button-type="submit"
      variant="warning"
      icon="pi pi-user-plus"
      label="アカウント作成"
      @click="signup"
    />
  </div>
</template>

<script setup lang="ts">
import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { errorHandle } from "/@/modules/error";
import { useZodScheme } from "/@/vue/composables/zod";
import { BasicButton } from "/@/vue/components/Atom";
import { FormItem } from "/@/vue/components/Molecules";
import { User } from "/@/types";

//

defineProps<{
  loading?: boolean;
}>();

interface Emits {
  (e: "signup", user: User): void;
}

const emit = defineEmits<Emits>();

// form

const SignUpScheme = z
  .object({
    name: z.string(),
    email: z.string().email(),
    password: z.string().min(6, { message: "6文字以上である必要があります" }),
    passwordConfirmation: z.string(),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: "パスワードが一致していません",
    path: ["passwordConfirmation"], // path of error
  });

type SignUpForm = zod.infer<typeof SignUpScheme>;

const { useFormAndErrors } = useZodScheme();

const {
  form: signUpForm,
  errors,
  startValidation,
} = useFormAndErrors<SignUpForm>(SignUpScheme);

function signup() {
  try {
    startValidation.value = true;
    const user = SignUpScheme.parse(signUpForm);
    emit("signup", user);
  } catch (e) {
    errorHandle(e);
  }
}
</script>

<style scoped></style>
