import { ref } from "vue";

export function useCollapse() {
  const isOpen = ref<boolean>(false);

  function prepareCollapse(element: HTMLElement) {
    if (element) {
      element.addEventListener("shown.bs.collapse", function (event) {
        event.stopPropagation();
        isOpen.value = true;
      });

      element.addEventListener("hidden.bs.collapse", function (event) {
        event.stopPropagation();

        isOpen.value = false;
      });
    }
  }

  return {
    isOpen,
    prepareCollapse,
  };
}
