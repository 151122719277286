import type {
  Carrier,
  OrganizationCategory,
  ReportItemForm,
  ReportItemKey,
  WorkCategory,
  CategoryCarrierKey,
  CategoryCarrierPair,
  ReportType,
  ReportItemFilter,
  BasisReportItemClient,
  BasisReportItemConfig,
  BasisReportItemConfigClient,
  BasisReportItemKey,
} from "/@/types";

import { basisReportItems } from "/@/vue/store/basisReportItems.db";
import { fromISO, isContain, isSameMonth, luxonNow } from "/@/modules/luxon";
import { DateTime } from "luxon";

function createReportItemForm(
  customers: ReportItemKey[],
  indivis: ReportItemKey[],
  overalls: ReportItemKey[]
) {
  return [
    ...customers.map((k) => ({ key: k, customers: true })),
    ...indivis.map((k) => ({ key: k, overall: false })),
    ...overalls.map((k) => ({
      key: k,
      overall: true,
    })),
  ];
}

export function getReportItems<T extends ReportItemFilter | ReportItemForm>({
  workTypeCategories,
  carrier,
  workCategory,
  isWorkTask,
  isMeetingTargets,
  itemFilter,
}: {
  workTypeCategories: OrganizationCategory[];
  carrier: Carrier;
  workCategory: WorkCategory;
  isWorkTask?: boolean;
  isMeetingTargets?: boolean;
  itemFilter?: ReportItemFilter[];
}): T[] {
  let result: T[] = [];

  if (workTypeCategories.includes("telecommunications")) {
    const unneededCustomerKeys: boolean = !!(isWorkTask || isMeetingTargets);

    const eventDocomoCustomerKeys: ReportItemKey[] = [
      //"catchCount",
      "customerCount",
      //"sitCount",
    ];

    const eventDocomoIndiviKeys: ReportItemKey[] = [
      "premiere",
      "ocn",
      "eximo",
      "irumo",
      "docomo_light",
      "tvop",
      "sky_perfect",
      "home_5g",
      "gold",
      "r",
      "migration",
      "bakuage",
      "d_type",
      "affiliate",
      "coating",
      "galaxy",
    ];
    const eventDocomoOverallKeys: ReportItemKey[] = [
      "premiere",
      "ocn",
      "eximo",
      "irumo",
    ];

    const eventSoftbankCustomerKeys: ReportItemKey[] = [
      ...eventDocomoCustomerKeys,
    ];

    const eventSoftbankIndiviKeys: ReportItemKey[] = [
      "smnp_with_unit",
      "smnp_only_sim",
      "s_new_with_sim",
      "ymnp_with_unit",
      "ymnp_only_sim",
      "y_new_with_unit",
      "up_with_unit",
      "light",
      "light_tv",
      "air",
      "pay_c",
      "pay_g",
    ];

    const eventSoftbankOverallKeys: ReportItemKey[] = [
      "mnp_with_unit",
      "mnp_only_sim",
      "new_with_unit",
      "up_with_unit",
      "light",
      "light_tv",
      "air",
    ];

    const shopDocomoCustomerKeys: ReportItemKey[] = [
      "visitors_count",
      "customerCount",
      "suggestion_count",
    ];

    const shopDocomoIndiviKeys: ReportItemKey[] = [
      "premiere",
      "ocn",
      "eximo",
      "irumo",
      "docomo_light",
      "tvop",
      "sky_perfect",
      "home_5g",
      "gold",
      "r",
      "migration",
      "bakuage",
      "d_type",
      "affiliate",
      "coating",
      "galaxy",
    ];

    const shopSoftbankCustomerKeys: ReportItemKey[] = [
      ...shopDocomoCustomerKeys,
    ];
    const shopSoftbankIndiviKeys: ReportItemKey[] = [
      "smnp_with_unit",
      "smnp_only_sim",
      "s_new_with_sim",
      "ymnp_with_unit",
      "ymnp_only_sim",
      "y_new_with_unit",
      "up_with_unit",
      "light",
      "light_tv",
      "air",
      "pay_c",
      "pay_g",
      "setting_support",
      "helpo",
      "affiliate",
    ];

    const rounderDocomoCustomerKeys: ReportItemKey[] = [
      "training",
      "ojt_triangle",
      "customerCount",
    ];

    const rounderDocomoIndiviKeys: ReportItemKey[] = [
      "premiere",
      "ocn",
      "eximo",
      "irumo",
      "docomo_light",
      "tvop",
      "sky_perfect",
      "home_5g",
      "gold",
      "r",
      "migration",
      "bakuage",
      "d_type",
      "affiliate",
      "coating",
      "galaxy",
    ];
    const rounderDocomoOverallKeys: ReportItemKey[] = [
      "light",
      "tvop",
      "sky_perfect",
    ];

    const rounderSoftbankCustomerKeys: ReportItemKey[] = [
      ...rounderDocomoCustomerKeys,
    ];
    const rounderSoftbankIndiviKeys: ReportItemKey[] = [
      "smnp_with_unit",
      "smnp_only_sim",
      "s_new_with_sim",
      "ymnp_with_unit",
      "ymnp_only_sim",
      "y_new_with_unit",
      "up_with_unit",
      "light",
      "light_tv",
      "air",
      "pay_c",
      "pay_g",
    ];
    const rounderSoftbankOverallKeys: ReportItemKey[] = [
      "light",
      "light_tv",
      "air",
    ];

    const consultingDocomoCustomerKeys = rounderDocomoCustomerKeys;
    const consultingDocomoIndiviKeys = rounderDocomoIndiviKeys;
    const consultingDocomoOverallKeys = rounderDocomoOverallKeys;

    const adYahooCustomerKeys: ReportItemKey[] = [...shopDocomoCustomerKeys];
    const adYahooIndiviKeys: ReportItemKey[] = [
      "light_new", // 光新規
      "light_diversion", // 光転用
      "light_operator_change", // 光事業者変更（光事変）
      "light_service_change", // 光サービス変更（光サ変）
      "air_new", // Air新規
      "air_model_change", // Air機変
      "air_service_change", // Airサービス変更（Airサ変）
    ];

    if (workCategory === "event_helper") {
      if (carrier === "docomo") {
        if (isWorkTask) {
          result = createReportItemForm(
            [],
            ["premiere", "ocn", "eximo", "irumo"],
            []
          );
        } else {
          result = createReportItemForm(
            isMeetingTargets ? [] : eventDocomoCustomerKeys,
            eventDocomoIndiviKeys,
            eventDocomoOverallKeys
          );
        }
      } else if (carrier === "softbank") {
        if (isWorkTask) {
          result = createReportItemForm(
            [],
            [
              "smnp_with_unit",
              "smnp_only_sim",
              "s_new_with_sim",
              "ymnp_with_unit",
              "ymnp_only_sim",
              "y_new_with_unit",
              "up_with_unit",
              "light",
              "air",
              "pay_c",
            ],
            []
          );
        } else {
          result = createReportItemForm(
            eventSoftbankCustomerKeys,
            eventSoftbankIndiviKeys,
            eventSoftbankOverallKeys
          );
        }
      }
    } else if (workCategory === "shop_helper") {
      if (carrier === "docomo") {
        result = createReportItemForm(
          unneededCustomerKeys ? [] : shopDocomoCustomerKeys,
          shopDocomoIndiviKeys,
          []
        );
      } else if (carrier === "softbank") {
        result = createReportItemForm(
          unneededCustomerKeys ? [] : shopSoftbankCustomerKeys,
          shopSoftbankIndiviKeys,
          []
        );
      }
    } else if (workCategory === "rounder") {
      if (carrier === "docomo") {
        result = createReportItemForm(
          unneededCustomerKeys ? [] : rounderDocomoCustomerKeys,
          rounderDocomoIndiviKeys,
          isWorkTask ? [] : rounderDocomoOverallKeys
        );
      } else if (carrier === "softbank") {
        result = createReportItemForm(
          unneededCustomerKeys ? [] : rounderSoftbankCustomerKeys,
          rounderSoftbankIndiviKeys,
          isWorkTask ? [] : rounderSoftbankOverallKeys
        );
      }
    } else if (workCategory === "fulltime") {
      if (carrier === "docomo") {
        result = createReportItemForm(
          unneededCustomerKeys ? [] : shopDocomoCustomerKeys,
          [...new Set(shopDocomoIndiviKeys.concat(eventDocomoIndiviKeys))],
          isWorkTask ? [] : eventDocomoOverallKeys
        );
      } else if (carrier === "softbank") {
        result = createReportItemForm(
          unneededCustomerKeys ? [] : eventSoftbankCustomerKeys,
          [...new Set(eventSoftbankIndiviKeys.concat(shopSoftbankIndiviKeys))],
          isWorkTask ? [] : eventSoftbankOverallKeys
        );
      }
    } else if (workCategory === "consulting") {
      if (carrier === "docomo") {
        result = createReportItemForm(
          unneededCustomerKeys ? [] : consultingDocomoCustomerKeys,
          consultingDocomoIndiviKeys,
          isWorkTask ? [] : consultingDocomoOverallKeys
        );
      }
    } else if (workCategory === "light_ad") {
      if (carrier === "yahoo") {
        if (isWorkTask) {
          result = createReportItemForm([], adYahooIndiviKeys, []);
        } else {
          result = createReportItemForm(
            adYahooCustomerKeys,
            adYahooIndiviKeys,
            adYahooIndiviKeys
          );
        }
      }
    }
  }

  if (itemFilter) {
    const validFilter = itemFilter.filter((item) => !!item.value);

    if (validFilter.length) {
      result = result.filter((item) => {
        return validFilter.some((filterItem) => {
          return (
            filterItem.basisReportItemId === item.basisReportItemId &&
            !!filterItem.overall === !!item.overall &&
            !!filterItem.customers === !!item.customers
          );
        });
      });
    }
  }

  return result;
}

function isSameMonthOfRange(
  target: DateTime,
  { start, end }: { start?: string | null; end?: string | null }
) {
  if (!start && !end) {
    return true;
  }

  if (start) {
    return target.hasSame(fromISO(start), "month");
  }

  if (end) {
    return target.hasSame(fromISO(end), "month");
  }

  return false;
}

export function columnToStr(column: BasisReportItemKey) {
  const translateMap: Record<BasisReportItemKey, string> = {
    id: "ID",
    organizationId: "組織ID",
    groupId: "グループ",
    name: "名前",
    shortName: "短縮名",
    unit: "単位",
    unitRange: "件数選択単位",
    validFrom: "開始日",
    validTo: "終了日",
    visible: "表示設定",
    overall: "全体実績",
    individual: "個別実績",
    individualCustomers: "応対記録",
    overallCustomers: "全体対応記録",
    order: "表示順",
    workCategories: "業務種別",
    workCarriers: "キャリア",
    requestOrganizationIds: "代理店",
  };

  return translateMap[column];
}

export function isShowItem(
  basisReportItem: {
    validFrom?: string | null;
    validTo?: string | null;
    visible?: boolean | null;
  },
  options: {
    now?: string;
    isMonthly?: boolean;
  }
) {
  const { validFrom, validTo, visible } = basisReportItem;
  const { now, isMonthly } = options;

  // check date

  const lNow = now ? fromISO(now) : luxonNow();
  const lNowS = lNow.toISO();

  if (!lNowS) {
    throw new Error("now is invalid");
  }

  const isContainP = isContain(lNowS, {
    start: validFrom || undefined,
    end: validTo || undefined,
  });

  const isSameMonthP = isSameMonthOfRange(lNow, {
    start: validFrom,
    end: validTo,
  });

  const isInTargetRange = isMonthly ? isContainP || isSameMonthP : isContainP;

  return visible && isInTargetRange;
}

export function isTargetConfig(
  config: BasisReportItemConfigClient,
  {
    workCategory,
    workCarrier,
    requestOrganizationId,
    individual,
    overall,
    individualCustomers,
    overallCustomers,
    itemFilter,
    isAdmin = false,
    isMonthly = false,
    isInvalid = false,
    now,
  }: {
    workCategory?: WorkCategory;
    workCarrier?: Carrier;
    requestOrganizationId?: number;
    individual?: boolean;
    overall?: boolean;
    individualCustomers?: boolean;
    overallCustomers?: boolean;
    itemFilter?: ReportItemFilter[];
    isAdmin?: boolean;
    isMonthly?: boolean;
    isInvalid?: boolean;
    now?: string;
  }
) {
  return (
    (isAdmin || isShowItem(config, { now, isMonthly })) &&
    (!workCategory ||
      !config.workCategories.length ||
      (workCategory && config.workCategories.includes(workCategory))) &&
    (!workCarrier ||
      !config.workCarriers.length ||
      (workCarrier && config.workCarriers.includes(workCarrier)) ||
      !config.workCarriers.length) &&
    ((!requestOrganizationId && !config.requestOrganizationIds.length) ||
      requestOrganizationId === -1 ||
      (requestOrganizationId && !config.requestOrganizationIds.length) ||
      (requestOrganizationId &&
        config.requestOrganizationIds.includes(requestOrganizationId))) &&
    ((config.overall && overall) ||
      (config.individual && individual) ||
      (config.individualCustomers && individualCustomers) ||
      (config.overallCustomers && overallCustomers) ||
      // invalid case
      (isInvalid &&
        !config.overall &&
        !config.individual &&
        !config.individualCustomers &&
        !config.overallCustomers)) &&
    (!itemFilter ||
      itemFilter.some((filter) => {
        return (
          !!filter.value &&
          filter.basisReportItemId === config.basisReportItemId &&
          ((filter.customers && filter.overall === config.overallCustomers) ||
            (filter.customers &&
              !filter.overall === config.individualCustomers) ||
            (!filter.customers && filter.overall === config.overall) ||
            (!filter.customers && !filter.overall === config.individual))
        );
      }))
  );
}

export function getTargetConfig(
  basisReportItem: BasisReportItemClient,
  options: {
    workTypeCategories?: OrganizationCategory[];
    workCategory?: WorkCategory;
    workCarrier?: Carrier;
    overall?: boolean;
    individual?: boolean;
    individualCustomers?: boolean;
    overallCustomers?: boolean;
    itemFilter?: ReportItemFilter[];
    requestOrganizationId?: number;
    isMonthly?: boolean;
    now?: string;
  }
) {
  return basisReportItem.configs.find((config) =>
    isTargetConfig(config, options)
  );
}

if (import.meta.vitest) {
  const { expect, it, describe } = import.meta.vitest;

  describe("getTargetConfig", () => {
    it("get event_helper docomo", () => {
      expect(
        // @ts-ignore
        getTargetConfig(basisReportItems[0], {
          workCarrier: "softbank",
          workCategory: "event_helper",
          requestOrganizationId: undefined,
          workTypeCategories: ["telecommunications"],
          individualCustomers: true,
        })
      ).toBeTruthy();
      expect(
        // @ts-ignore
        getTargetConfig(basisReportItems[0], {
          workCarrier: "softbank",
          workCategory: undefined,
          requestOrganizationId: undefined,
          workTypeCategories: ["telecommunications"],
          individualCustomers: true,
        })
      ).toBeTruthy();
    });
  });
}

export function getReportItemsNew(
  reportItems: BasisReportItemClient[],
  options: {
    workTypeCategories: OrganizationCategory[];
    workCategory?: WorkCategory;
    workCarrier?: Carrier;
    requestOrganizationId?: number;
    overall?: boolean;
    individual?: boolean;
    individualCustomers?: boolean;
    overallCustomers?: boolean;
    itemFilter?: ReportItemFilter[];
    now?: string;
    isMonthly?: boolean;
  }
): BasisReportItemClient[] {
  const { workTypeCategories } = options;

  if (!workTypeCategories.includes("telecommunications")) {
    return []; // TODO
  }

  const visibleItems = reportItems.filter((item) => isShowItem(item, options));

  return visibleItems.filter((item) => {
    return getTargetConfig(item, options);
  });
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const getBaseOptions = {
    workTypeCategories: ["telecommunications"] as OrganizationCategory[],
    now: "2023-12-08T10:00:00.000+09:00",
  };

  describe("getReportItemsNew", () => {
    describe("docomo", () => {
      it("get event_helper", () => {
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            ...getBaseOptions,
            workCategory: "event_helper",
            workCarrier: "docomo",
            individualCustomers: true,
          }).map((item) => item.name)
        ).toMatchObject(["応対数", "着座数"]);
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "event_helper",
            workCarrier: "docomo",
            workTypeCategories: ["telecommunications"],
            individual: true,
          }).map((item) => item.name)
        ).toMatchObject([
          "PI(eximo)",
          "PI(irumo)",
          "ドコモ光",
          "TVOP",
          "スカパー",
          "home5G",
          "GOLD",
          "R",
          "マイグレ",
          "爆アゲ",
          "d系",
          "アフィリエイト",
          "コーティング",
          "galaxy",
        ]);
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "event_helper",
            workCarrier: "docomo",
            workTypeCategories: ["telecommunications"],
            overallCustomers: true,
          }).map((item) => item.name)
        ).toMatchObject([]);
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "event_helper",
            workCarrier: "docomo",
            workTypeCategories: ["telecommunications"],
            requestOrganizationId: undefined,
            overall: true,
          }).map((item) => item.name)
        ).toMatchObject(["PI(eximo)", "PI(irumo)"]);
      });
      it("get shop_helper", () => {
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "shop_helper",
            workCarrier: "docomo",
            workTypeCategories: ["telecommunications"],
            individualCustomers: true,
          }).map((item) => item.name)
        ).toMatchObject(["応対数"]);
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "shop_helper",
            workCarrier: "docomo",
            workTypeCategories: ["telecommunications"],
            individual: true,
          }).map((item) => item.name)
        ).toMatchObject([
          "PI(eximo)",
          "PI(irumo)",
          "ドコモ光",
          "TVOP",
          "スカパー",
          "home5G",
          "GOLD",
          "R",
          "マイグレ",
          "爆アゲ",
          "d系",
          "アフィリエイト",
          "コーティング",
          "galaxy",
        ]);
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "shop_helper",
            workCarrier: "docomo",
            workTypeCategories: ["telecommunications"],
            overallCustomers: true,
          }).map((item) => item.name)
        ).toMatchObject([]);
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "shop_helper",
            workCarrier: "docomo",
            workTypeCategories: ["telecommunications"],
            overall: true,
          }).map((item) => item.name)
        ).toMatchObject([]);
      });
      it("get rounder", () => {
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "rounder",
            workCarrier: "docomo",
            workTypeCategories: ["telecommunications"],
            individualCustomers: true,
          }).map((item) => item.name)
        ).toMatchObject(["応対数", "研修数", "OJT三角接客"]);
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "rounder",
            workCarrier: "docomo",
            workTypeCategories: ["telecommunications"],
            individual: true,
          }).map((item) => item.name)
        ).toMatchObject([
          "PI(eximo)",
          "PI(irumo)",
          "ドコモ光",
          "TVOP",
          "スカパー",
          "home5G",
          "GOLD",
          "R",
          "マイグレ",
          "爆アゲ",
          "d系",
          "アフィリエイト",
          "コーティング",
          "galaxy",
        ]);
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "rounder",
            workCarrier: "docomo",
            workTypeCategories: ["telecommunications"],
            overallCustomers: true,
          }).map((item) => item.name)
        ).toMatchObject([]);
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "rounder",
            workCarrier: "docomo",
            workTypeCategories: ["telecommunications"],
            overall: true,
          }).map((item) => item.name)
        ).toMatchObject(["TVOP", "スカパー", "光"]);
      });
      it("get fulltime", () => {
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "fulltime",
            workCarrier: "docomo",
            workTypeCategories: ["telecommunications"],
            individualCustomers: true,
          }).map((item) => item.name)
        ).toMatchObject(["キャッチ", "応対数", "着座数"]);
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "fulltime",
            workCarrier: "docomo",
            workTypeCategories: ["telecommunications"],
            individual: true,
          }).map((item) => item.name)
        ).toMatchObject([
          "PI(eximo)",
          "PI(irumo)",
          "ドコモ光",
          "TVOP",
          "スカパー",
          "home5G",
          "GOLD",
          "R",
          "マイグレ",
          "爆アゲ",
          "d系",
          "アフィリエイト",
          "コーティング",
          "galaxy",
        ]);
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "fulltime",
            workCarrier: "docomo",
            workTypeCategories: ["telecommunications"],
            overallCustomers: true,
          }).map((item) => item.name)
        ).toMatchObject([]);
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "fulltime",
            workCarrier: "docomo",
            workTypeCategories: ["telecommunications"],
            overall: true,
          }).map((item) => item.name)
        ).toMatchObject(["PI(eximo)", "PI(irumo)"]);
      });
      it("with filter", () => {
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            ...getBaseOptions,
            workCategory: "event_helper",
            workCarrier: "docomo",
            individualCustomers: true,
            itemFilter: [
              {
                key: "応対数",
                value: true,
                overall: false,
                customers: true,
                basisReportItemId: 2,
              },
            ],
          }).map((item) => item.name)
        ).toMatchObject(["応対数"]);
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            ...getBaseOptions,
            workCategory: "event_helper",
            workCarrier: "docomo",
            individualCustomers: true,
            itemFilter: [
              {
                key: "応対数",
                value: true,
                overall: true,
                customers: false,
                basisReportItemId: 2,
              },
            ],
          }).map((item) => item.name)
        ).toMatchObject([]);
      });
    });
    describe("softbank", () => {
      it("get event_helper", () => {
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "event_helper",
            workCarrier: "softbank",
            workTypeCategories: ["telecommunications"],
            individualCustomers: true,
          }).map((item) => item.name)
        ).toMatchObject(["キャッチ", "応対数", "着座数"]);
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "event_helper",
            workCarrier: "softbank",
            workTypeCategories: ["telecommunications"],
            individual: true,
          }).map((item) => item.name)
        ).toMatchObject([
          "SMNP端末付",
          "SMNPsim単",
          "S新規",
          "YMNP端末付",
          "YMNPsim単",
          "Y新規",
          "UP",
          "光",
          "光TV",
          "Air",
          "payC",
          "payG",
        ]);
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "event_helper",
            workCarrier: "softbank",
            workTypeCategories: ["telecommunications"],
            overallCustomers: true,
          }).map((item) => item.name)
        ).toMatchObject([]);
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "event_helper",
            workCarrier: "softbank",
            workTypeCategories: ["telecommunications"],
            overall: true,
          })
            .map((item) => item.name)
            .sort()
        ).toMatchObject(
          ["MNP端末付", "MNPsim単", "新規", "UP", "光", "光TV", "Air"].sort()
        );
      });
      it("get shop_helper", () => {
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "shop_helper",
            workCarrier: "softbank",
            workTypeCategories: ["telecommunications"],
            individualCustomers: true,
          }).map((item) => item.name)
        ).toMatchObject(["応対数"]);
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "shop_helper",
            workCarrier: "softbank",
            workTypeCategories: ["telecommunications"],
            individual: true,
          })
            .map((item) => item.name)
            .sort()
        ).toMatchObject(
          [
            "SMNP端末付",
            "SMNPsim単",
            "S新規",
            "YMNP端末付",
            "YMNPsim単",
            "Y新規",
            "UP",
            "光",
            "光TV",
            "Air",
            "payC",
            "payG",
            "設定サポ",
            "HELPO",
            "アフィリエイト",
          ].sort()
        );
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "shop_helper",
            workCarrier: "softbank",
            workTypeCategories: ["telecommunications"],
            overallCustomers: true,
          }).map((item) => item.name)
        ).toMatchObject([]);
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "shop_helper",
            workCarrier: "softbank",
            workTypeCategories: ["telecommunications"],
            overall: true,
          }).map((item) => item.name)
        ).toMatchObject([]);
      });
      it("get rounder", () => {
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "rounder",
            workCarrier: "softbank",
            workTypeCategories: ["telecommunications"],
            individualCustomers: true,
          })
            .map((item) => item.name)
            .sort()
        ).toMatchObject(["応対数", "研修数", "OJT三角接客"].sort());
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "rounder",
            workCarrier: "softbank",
            workTypeCategories: ["telecommunications"],
            individual: true,
          })
            .map((item) => item.name)
            .sort()
        ).toMatchObject(
          [
            "SMNP端末付",
            "SMNPsim単",
            "S新規",
            "YMNP端末付",
            "YMNPsim単",
            "Y新規",
            "UP",
            "光",
            "光TV",
            "Air",
            "payC",
            "payG",
          ].sort()
        );
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "rounder",
            workCarrier: "softbank",
            workTypeCategories: ["telecommunications"],
            overallCustomers: true,
          }).map((item) => item.name)
        ).toMatchObject([]);
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "rounder",
            workCarrier: "softbank",
            workTypeCategories: ["telecommunications"],
            overall: true,
          })
            .map((item) => item.name)
            .sort()
        ).toMatchObject(["光", "光TV", "Air"].sort());
      });
    });
    describe("yahoo", () => {
      it("get light_ad", () => {
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "light_ad",
            workCarrier: "yahoo",
            workTypeCategories: ["telecommunications"],
            individualCustomers: true,
          }).map((item) => item.name)
        ).toMatchObject([]);
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "light_ad",
            workCarrier: "yahoo",
            workTypeCategories: ["telecommunications"],
            individual: true,
          })
            .map((item) => item.name)
            .sort()
        ).toMatchObject(
          [
            "光新規",
            "光転用",
            "光事業者変更",
            "光サービス変更",
            "Air新規",
            "Air機変",
            "Airサービス変更",
          ].sort()
        );
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "light_ad",
            workCarrier: "yahoo",
            workTypeCategories: ["telecommunications"],
            overallCustomers: true,
          }).map((item) => item.name)
        ).toMatchObject([]);
        expect(
          getReportItemsNew(basisReportItems as BasisReportItemClient[], {
            workCategory: "light_ad",
            workCarrier: "yahoo",
            workTypeCategories: ["telecommunications"],
            overall: true,
          })
            .map((item) => item.name)
            .sort()
        ).toMatchObject(
          [
            "光新規",
            "光転用",
            "光事業者変更",
            "光サービス変更",
            "Air新規",
            "Air機変",
            "Airサービス変更",
          ].sort()
        );
      });
    });
  });
}

export function createReportItem(
  basisReportItem: BasisReportItemClient,
  options: {
    workCategory?: WorkCategory;
    workCarrier?: Carrier;
    individualCustomers?: boolean;
    overallCustomers?: boolean;
    overall?: boolean;
    individual?: boolean;
    workTypeCategories?: OrganizationCategory[];
    requestOrganizationId?: number;
    now?: string;
  }
) {
  const config = getTargetConfig(basisReportItem, options);

  if (!config) {
    return;
  }

  return {
    key: basisReportItem.name,
    // @ts-ignore
    customers: !!options.individualCustomers || !!options.overallCustomers,
    // @ts-ignore
    overall: !!options.overall || !!options.overallCustomers,
    count: 0,
    basisReportItemId: basisReportItem.id,
  };
}

export function createReportItems(
  basisReportItems: BasisReportItemClient[],
  options: {
    workCategory?: WorkCategory;
    workCarrier?: Carrier;
    overall?: boolean;
    individual?: boolean;
    individualCustomers?: boolean;
    overallCustomers?: boolean;
    workTypeCategories?: OrganizationCategory[];
    requestOrganizationId?: number;
    now?: string;
  }
): ReportItemForm[] {
  return basisReportItems
    .map((item) => createReportItem(item, options))
    .filter((item) => !!item);
}

if (import.meta.vitest) {
  const { it, expect, describe } = import.meta.vitest;

  describe("createReportItems", () => {
    it("create", () => {
      expect(
        createReportItems(
          basisReportItems.slice(0, 3) as BasisReportItemClient[],
          {
            workCategory: "event_helper",
            workCarrier: "docomo",
            workTypeCategories: ["telecommunications"],
            individualCustomers: true,
            now: "2023-12-10T10:00:00.000+09:00",
          }
        )
      ).toMatchObject([
        {
          key: "キャッチ",
          customers: true,
          overall: false,
          count: 0,
          basisReportItemId: 1,
        },
        {
          key: "応対数",
          customers: true,
          overall: false,
          count: 0,
          basisReportItemId: 2,
        },
        {
          key: "着座数",
          customers: true,
          overall: false,
          count: 0,
          basisReportItemId: 3,
        },
      ]);
      expect(
        createReportItems(
          basisReportItems.slice(0, 3) as BasisReportItemClient[],
          {
            workCategory: "event_helper",
            workCarrier: "docomo",
            workTypeCategories: ["telecommunications"],
            individualCustomers: true,
            now: "2023-12-08T10:00:00.000+09:00",
          }
        )
      ).toMatchObject([
        {
          key: "応対数",
          customers: true,
          overall: false,
          count: 0,
          basisReportItemId: 2,
        },
        {
          key: "着座数",
          customers: true,
          overall: false,
          count: 0,
          basisReportItemId: 3,
        },
      ]);
    });
  });
}

export function getEachItems<T extends ReportItemFilter | ReportItemForm>(
  targetItems: T[],
  options: {
    individual?: boolean;
    overall?: boolean;
    customers?: boolean;
    workTypeCategories?: OrganizationCategory[];
  }
): T[] {
  const { overall, customers, workTypeCategories } = options;

  if (!workTypeCategories) {
    return [];
  }

  if (workTypeCategories.includes("telecommunications")) {
    return targetItems
      .filter(
        (item) =>
          !!item.overall === !!overall && !!item.customers === !!customers
      )
      .map((item) => {
        return { ...item, count: 0 };
      });
  } else {
    return [];
  }
}

export function contractNewItem<T extends ReportItemFilter | ReportItemForm>(
  reportItems: T[],
  {
    key,
    count,
    overall,
    customers,
    value,
    basisReportItemId,
  }: {
    key: string;
    count?: number;
    value?: boolean;
    overall?: boolean;
    customers?: boolean;
    basisReportItemId?: number;
  }
): T[] {
  const targetItem = reportItems.find(
    (item) =>
      item.basisReportItemId == basisReportItemId &&
      !!item.overall === !!overall &&
      !!item.customers === !!customers
  );

  if (!targetItem) {
    return [
      ...reportItems,
      {
        key,
        count,
        value,
        basisReportItemId,
        overall: !!overall,
        customers: !!customers,
      },
    ];
  } else {
    const others = reportItems.filter(
      (item) =>
        item.basisReportItemId !== item.basisReportItemId ||
        item.overall !== overall ||
        item.customers !== customers
    );

    return [
      ...others,
      { key, count, value, basisReportItemId, overall, customers },
    ];
  }
}

export function basisToReport(
  basisReportItem: BasisReportItemClient,
  options: {
    individualCustomers?: boolean;
    overallCustomers?: boolean;
    overall?: boolean;
    individual?: boolean;
  }
): ReportItemForm & { shortKey: string } {
  const { individual, overall, overallCustomers, individualCustomers } =
    options;

  return {
    key: basisReportItem.name,
    basisReportItemId: basisReportItem.id,
    count: 0,
    shortKey: basisReportItem.shortName || basisReportItem.name,
    overall: overallCustomers || overall,
    customers: overallCustomers || individualCustomers,
  };
}

export function basisToFilter(
  basisReportItem: BasisReportItemClient,
  options: {
    individual?: boolean;
    overall?: boolean;
    individualCustomers?: boolean;
    overallCustomers?: boolean;
  }
): ReportItemFilter {
  return {
    key: basisReportItem.name,
    basisReportItemId: basisReportItem.id,
    value: false,
    customers: !!options.individualCustomers || !!options.overallCustomers,
    overall: !!options.overall || !!options.overallCustomers,
  };
}

// category carrier

export function categoryCarrierTranslater(
  k: CategoryCarrierKey,
  { shorter }: { shorter?: boolean }
): string {
  const m = {
    docomo_normal: "",
    softbank_normal: "",
    au_normal: "",
    docomo_rounder: shorter ? "D/RD" : "Dラウンド業務",
    softbank_rounder: shorter ? "S/RD" : "Sラウンド業務",
    au_rounder: shorter ? "A/RD" : "Aラウンド業務",
    docomo_shop_helper: shorter ? "D/HP" : "D店舗業務",
    softbank_shop_helper: shorter ? "S/HP" : "S店舗業務",
    au_shop_helper: shorter ? "A/HP" : "A店舗業務",
    docomo_event_helper: shorter ? "D/EV" : "Dイベント業務",
    softbank_event_helper: shorter ? "S/EV" : "Sイベント業務",
    au_event_helper: shorter ? "A/EV" : "Aイベント業務",
    docomo_fulltime: shorter ? "D/常勤" : "D常勤",
    softbank_fulltime: shorter ? "S/常勤" : "S常勤",
    au_fulltime: shorter ? "A/常勤" : "A常勤",
    docomo_consulting: shorter ? "D/CO" : "Dコンサル業務",
    softbank_consulting: shorter ? "S/CO" : "Sコンサル業務",
    au_consulting: shorter ? "A/CO" : "Aコンサル業務",
    yahoo_light_ad: shorter ? "Y/AD" : "Y光AD",
  };

  return m[k];
}

export function categoryCarrierColor(k: CategoryCarrierKey): string {
  const m = {
    docomo_normal: "danger",
    docomo_rounder: "danger",
    docomo_shop_helper: "danger",
    docomo_event_helper: "danger",
    docomo_fulltime: "danger",
    docomo_consulting: "danger",
    docomo_light_ad: "danger",

    softbank_normal: "primary",
    softbank_rounder: "primary",
    softbank_shop_helper: "primary",
    softbank_event_helper: "primary",
    softbank_fulltime: "primary",
    softbank_consulting: "primary",
    softbank_light_ad: "primary",

    au_normal: "success",
    au_rounder: "success",
    au_shop_helper: "success",
    au_event_helper: "success",
    au_fulltime: "success",
    au_consulting: "success",

    yahoo_light_ad: "success",
  };

  return m[k];
}

export function categoryCarrierPairToKey(
  p: CategoryCarrierPair
): CategoryCarrierKey {
  return `${p.carrier}_${p.category}` as CategoryCarrierKey;
}

export function reportTypeToStr(reportType: ReportType) {
  switch (reportType) {
    case "overall":
      return "全体実績";
    case "individual":
      return "個人実績";
  }
}

export function emptyReport(reportItems?: ReportItemForm[]) {
  if (!reportItems) return true;

  return reportItems.reduce((acc, cur) => acc + (cur.count || 0), 0) === 0;
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  it("emptyReport", () => {
    expect(emptyReport(undefined)).toBe(true);

    expect(
      emptyReport([
        { key: "customerCount", count: 0 },
        { key: "sitCount", count: 0 },
      ])
    ).toBe(true);
    expect(
      emptyReport([
        { key: "customerCount", count: 3 },
        { key: "sitCount", count: 0 },
      ])
    ).toBe(false);
  });
}
