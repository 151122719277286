import axios from "axios";
import { errorHandle } from "/@/modules/error";
import type { User, UserParams } from "/@/types";
import useSWRV from "swrv";
import { fetcher } from "/@/modules/axios";

export function useUser() {
  // api

  async function updateUser(user: UserParams): Promise<User | undefined> {
    try {
      const { data }: { data: User } = await axios.post(
        "/api/v1/current_user/",
        {
          user,
        }
      );
      return data;
    } catch (e) {
      errorHandle(e);
      return undefined;
    }
  }

  type PasswordCheckError = "over3Count";

  type PasswordCheckResponse = {
    data: {
      status: boolean;
      currentCount?: number;
      errorCode?: PasswordCheckError;
    };
  };

  async function checkPassword(password: string | undefined) {
    if (!password) {
      return {
        status: false,
        currentCount: undefined,
        errorCode: undefined,
      };
    }

    try {
      const { data }: PasswordCheckResponse = await axios.post(
        "/api/v1/current_user/check_password",
        {
          user: {
            password,
          },
        }
      );

      return data;
    } catch (e) {
      errorHandle(e);
      return {
        status: false,
        currentCount: undefined,
        errorCode: undefined,
      };
    }
  }

  async function resetPassword() {
    try {
      await axios.post("/api/v1/current_user/reset_password");
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  // body temperature

  function getTodayNewestBodyTemperature() {
    return useSWRV(
      "/api/v1/current_user/today_newest_body_temperature",
      fetcher
    );
  }

  return {
    // api
    updateUser,
    checkPassword,
    resetPassword,

    getTodayNewestBodyTemperature,
  };
}
