<template>
  <PageHeader page-title="案件結果" hide-back-btn />

  <OrganizationSelector
    v-model:selected-organization-id="selectedOrganizationId"
    class="w-100 mb-3"
    :organizations="organizations || []"
  />

  <div v-if="isOwner" class="mb-3">
    <ContentLabel label="表示する枠組" />
    <ObjectSelector
      form-id="select-filter"
      :value="selectedFilter"
      :items="filterItems"
      value-key="key"
      :show-fn="(item: FilterItem) => item.name"
      :is-valid="!!selectedFilter"
      @update:value="selectedFilter = $event?.key"
    />
  </div>

  <div id="pdf-contents">
    <div
      v-if="selectedFilter === 'requestOrganization'"
      class="mb-3"
      data-html2canvas-ignore="true"
    >
      <ContentLabel label="代理店" />
      <ObjectSelector
        :value="selectedRequestOrganizationId"
        :items="filteredRequestOrganizations"
        value-key="id"
        show-key="name"
        form-id="select-organization"
        :is-valid="!!selectedRequestOrganizationId"
        @update:value="selectedRequestOrganizationId = $event?.id"
      />
    </div>

    <div
      v-if="selectedFilter === 'member'"
      class="mb-3"
      data-html2canvas-ignore="true"
    >
      <ContentLabel label="メンバー" />
      <ObjectSelector
        :value="selectedOrganizationMemberId"
        :items="organizationMembers || []"
        value-key="id"
        :show-fn="memberShow"
        form-id="select-organization-member"
        is-valid
        can-without-select
        @update:value="selectedOrganizationMemberId = $event?.id"
      />
    </div>

    <SelectDate
      v-model:year="selectedDate.year"
      v-model:month="selectedDate.month"
      :exists-dates="existsDates"
      only-year-month
      class="mb-3"
      data-html2canvas-ignore="true"
    />

    <ResultsTableFilter
      v-model:selected-categories="selectedWorkCategories"
      v-model:selected-places="selectedPlaces"
      v-model:selected-carriers="selectedCarriers"
      :work-categories="workCategories"
      :work-places="workPlaces"
      :carriers="carriers"
      item-class="mb-3"
      data-html2canvas-ignore="true"
      class="mb-3 d-flex flex-wrap"
    />

    <template v-if="resultReports.length">
      <div
        class="d-flex align-items justify-content-end py-2"
        data-html2canvas-ignore="true"
      >
        <MenuButton :menu-items="menuItems" />
      </div>

      <ResultsTabel
        :table-title="tableTitle"
        :date-map="selectedDate"
        :categories="organizationCategories"
        :work-reports="resultReports"
        :work-tasks="contractedTasks || []"
        :basis-report-items="basisReportItems || []"
        :category-carrier-pairs="needCategoryCarrierPairs"
        :owner-mode="ownerMode && selectedFilter === 'member'"
        :selected-filter="selectedFilter"
        :show-edit-mode="showEditMode"
        :show-customer-report="showCustomerReport"
        :show-only-worked-days="showOnlyWorkedDays"
        :shorter-mode="shorterMode"
        :jp-holidays="jpHolidays"
        :is-owner="isFRYTHOwner"
        :selected-request-organization-id="selectedRequestOrganizationId"
        :show-request-organization="isFRYTH && (isOwner || isDepartmentOwner)"
        :hide-map-configs="hideMapConfigs || []"
        class="table-wrapper mb-3"
        @update-work-report="handleUpdate"
        @save-hide-map-config="saveHideMapConfig"
        @destroy-hide-map-config="handleDestroyHideMapConfig"
        @exit-hide-map-config="showEditMode = false"
      />

      <div
        v-if="contractedAndCreatedInThisMonthTasks.length"
        class="lost-work-tasks mb-3"
        data-html2canvas-ignore="true"
      >
        <ResultsTableContractedWorkTasks
          :selected-date="selectedDate"
          :contracted-tasks="contractedAndCreatedInThisMonthTasks"
          :basis-report-items="basisReportItems || []"
          :shorter-mode="shorterMode"
        />
      </div>

      <div
        v-if="lostedTasks.length && false"
        class="lost-work-tasks"
        data-html2canvas-ignore="true"
      >
        <ResultsTableLostWorkTasks
          :losted-tasks="lostedTasks"
          :basis-report-items="basisReportItems || []"
        />
      </div>
    </template>
    <template v-else>
      <span class="text-danger">データが無いか閲覧権限がありません。</span>
    </template>
  </div>
</template>

<script setup lang="ts">
// TODO: 表示に必要な Work Category と Work Carrier の配列を渡すようにして、
// それぞれ必要な分だけテーブルを横に伸ばすようにする
import _ from "lodash";
import { ref, computed, reactive, watch, onMounted } from "vue";
import { getStorage, setStorage } from "/@/modules/localStorage";
import { memberShowFn } from "/@/modules/organizationMember";
import { removeDupStrict } from "/@/modules/array";
import { generatePDF } from "/@/modules/pdf";
import {
  useOrganization,
  useWorks,
  useWorkTasks,
  useWorkReport,
  useBasisReportItems,
  useOrganizationMember,
  useCalendar,
} from "/@/vue/composables";
import { PageHeader } from "/@/vue/components/Layouts";
import {
  SelectDate,
  ContentLabel,
  ObjectSelector,
} from "/@/vue/components/Atom";
import { MenuButton } from "/@/vue/components/Molecules";
import {
  OrganizationSelector,
  ResultsTabel,
  ResultsTableLostWorkTasks,
  ResultsTableFilter,
  ResultsTableContractedWorkTasks,
} from "/@/vue/components/Organisms";
import type {
  Carrier,
  Organization,
  DateMap,
  OrganizationMember,
  WorkRecordEntryReportForm,
  Work,
  WorkReport,
  FilterKey,
  WorkCategory,
  HideMapConfigForm,
  HideMapConfig,
  WorkTaskClient,
} from "/@/types";
import { basicFormatter, isContainInDateMap, luxonNow } from "/@/modules/luxon";
import type { MenuItem } from "primevue/menuitem";

const {
  getOrganizations,
  asOwner,
  asDepartmentOwner,
  getRequestOrganizations,
} = useOrganization();
const { data: organizations } = getOrganizations();

const selectedOrganizationId = ref<number | undefined>(
  getStorage("selectedOrganizationId") || undefined
);
watch(selectedOrganizationId, (id) => {
  setStorage("selectedOrganizationId", id);
});

const selectedOrganization = computed<Organization | undefined>(() => {
  if (!organizations.value) {
    return;
  }

  return organizations.value.find((o) => o.id == selectedOrganizationId.value);
});

const organizationCategories = computed(() => {
  return selectedOrganization.value?.categories || [];
});

const isOrganizationOwner = computed(() => {
  return asOwner(selectedOrganization.value);
});

const isFRYTH = computed(() => {
  return selectedOrganization.value?.name === "(株)FRYTH";
});

const isDepartmentOwner = computed(() => {
  return asDepartmentOwner(selectedOrganization.value);
});

const isOwner = computed(() => {
  return isOrganizationOwner.value || isDepartmentOwner.value;
});

const isFRYTHOwner = computed(() => {
  return isFRYTH.value && isOrganizationOwner.value;
});

const subContractors = computed(() => {
  if (!isOwner.value || !selectedOrganization.value) return [];

  return selectedOrganization.value.subContractors;
});

function memberShow(member: OrganizationMember) {
  return memberShowFn(member, selectedOrganization.value, subContractors.value);
}
// select filter

const selectedFilter = ref<FilterKey>(
  getStorage("WorkReportsIndexSelectedFilter") || "member"
);
watch(selectedFilter, (newValue) => {
  setStorage("WorkReportsIndexSelectedFilter", newValue);
});

onMounted(() => {
  if (isOwner.value) {
    selectedFilter.value = "member";
  }
});

type FilterItem = {
  name: string;
  key: FilterKey;
};

const filterItems: FilterItem[] = [
  { name: "依頼代理店", key: "requestOrganization" },
  { name: "メンバー", key: "member" },
];

// mode

const ownerMode = ref<boolean>(false);
const showEditMode = ref<boolean>(false);
const shorterMode = ref<boolean>(true);
const showCustomerReport = ref<boolean>(false);
const showOnlyWorkedDays = ref<boolean>(false);

// menu

const menuItems = computed<MenuItem[]>(() => {
  return [
    {
      label: "PDFで印刷",
      icon: "pi pi-print",
      visible: isFRYTHOwner.value,
      command: () => {
        generateWorkReportPDF();
      },
    },
    {
      label: "編集モード",
      icon: "pi pi-pencil",
      visible: isFRYTHOwner.value && selectedFilter.value === "member",
      command: () => {
        ownerMode.value = !ownerMode.value;
      },
      style: {
        "background-color": ownerMode.value ? "var(--cyan-200)" : "inherit",
      },
    },
    {
      label: "表示設定モード",
      icon: "pi pi-cog",
      visible: isFRYTHOwner.value && false,
      command: () => {
        showEditMode.value = !showEditMode.value;
      },
      style: {
        "background-color": showEditMode.value ? "var(--cyan-200)" : "inherit",
      },
    },
    {
      label: "短縮表示モード",
      icon: "pi pi-ellipsis-h",
      command: () => {
        shorterMode.value = !shorterMode.value;
      },
      style: {
        "background-color": shorterMode.value ? "var(--cyan-200)" : "inherit",
      },
    },
    {
      label: "応対記録の表示",
      icon: "pi pi-eye",
      command: () => {
        showCustomerReport.value = !showCustomerReport.value;
      },
      style: {
        "background-color": showCustomerReport.value
          ? "var(--cyan-200)"
          : "inherit",
      },
    },
    {
      label: "勤務日のみ表示",
      icon: "pi pi-eye-slash",
      command: () => {
        showOnlyWorkedDays.value = !showOnlyWorkedDays.value;
      },
      style: {
        "background-color": showOnlyWorkedDays.value
          ? "var(--cyan-200)"
          : "inherit",
      },
    },
  ];
});

// api

// calendar

const { getJpHolidays } = useCalendar();
const { data: jpHolidays } = getJpHolidays();

// date

const today = luxonNow();

const selectedDate = reactive<DateMap>({
  year: getStorage("WorkReportsIndexSelectedDateYear") || today.year,
  month: getStorage("WorkReportsIndexSelectedDateMonth") || today.month,
});
watch(
  () => selectedDate.year,
  (newValue) => {
    setStorage("WorkReportsIndexSelectedDateYear", newValue);
  }
);
watch(
  () => selectedDate.month,
  (newValue) => {
    setStorage("WorkReportsIndexSelectedDateMonth", newValue);
  }
);

const existsDates = computed(() => {
  if (!workReports.value) return [];

  return workReports.value.map((report) => report.targetDate);
});

// organization members

const { getOrganizationMembers } = useOrganizationMember();
const { data: organizationMembers } = getOrganizationMembers(
  selectedOrganization,
  isOwner,
  selectedDate
);

const selectedOrganizationMemberId = ref<number | undefined>();
const selectedOrganizationMember = computed(() => {
  return organizationMembers.value?.find(
    (om) => om.id === selectedOrganizationMemberId.value
  );
});

// works

const { getWorks } = useWorks();
const { data: worksRes } = getWorks(selectedOrganization);

const works = computed(() => {
  if (!worksRes.value) {
    return [];
  }
  return worksRes.value.works;
});

const filteredWorks = computed(() => {
  if (!selectedDate.year || !works.value) return [];

  return works.value.filter(
    (w) =>
      isContainInDateMap(w.startOn, selectedDate) ||
      isContainInDateMap(w.finishOn, selectedDate)
  );
});

// request organizations

const { data: requestOrganizations } =
  getRequestOrganizations(selectedOrganization);

const filteredRequestOrganizations = computed(() => {
  if (!requestOrganizations.value) {
    return [];
  }

  return requestOrganizations.value.filter((o) =>
    filteredWorks.value?.find((w) => w.requestOrganizationId === o.id)
  );
});
const selectedRequestOrganizationId = ref<number | undefined>();
const selectedRequestOrganization = computed(() => {
  return requestOrganizations.value?.find(
    (ro) => ro.id === selectedRequestOrganizationId.value
  );
});

const selectedEventPlace = ref<string | undefined>();

// reports

const { getBasisReportItems } = useBasisReportItems();
const { data: basisReportItems } = getBasisReportItems();

const {
  getWorkReports,
  updateWorkReport,
  getHideMapConfigs,
  createHideMapConfig,
  destroyHideMapConfig,
} = useWorkReport();
const { data: workReports } = getWorkReports(selectedOrganization);

const filteredByDateReports = computed(() => {
  if (!workReports.value || !selectedDate.year) return [];

  return workReports.value.filter((report) => {
    return isContainInDateMap(report.targetDate, selectedDate);
  });
});

const filteredReports = computed(() => {
  if (!workReports.value || !works.value) {
    return [];
  }

  const selectedEventPlaceName = selectedEventPlace.value;

  switch (selectedFilter.value) {
    case "requestOrganization":
      return filteredByDateReports.value?.filter((report) => {
        const work = works.value?.find((w) => w.id === report.workId);

        return (
          work &&
          work.requestOrganizationId === selectedRequestOrganizationId.value &&
          (!selectedEventPlaceName ||
            report.eventPlaceName === selectedEventPlaceName)
        );
      }); // TODO
    case "member":
      return filteredByDateReports.value?.filter(
        (report) =>
          report.organizationMemberId === selectedOrganizationMemberId.value
      );
    default:
      return [];
  }
});

const selectedWorkCategories = ref<WorkCategory[]>([]);
const selectedPlaces = ref<string[]>([]);
const selectedCarriers = ref<Carrier[]>([]);

const workCategories = computed(() => {
  return [...new Set(filteredReports.value.map((r) => r.workCategory))];
});

watch(workCategories, (wcs, olds) => {
  if (!_.isEqual(wcs, olds)) {
    selectedWorkCategories.value = wcs;
  }
});

const workPlaces = computed(() => {
  return [
    ...new Set(
      filteredReports.value.map((r) => r.eventPlaceName || r.workPlaceName)
    ),
  ];
});

watch(workPlaces, (wps, olds) => {
  if (!_.isEqual(wps, olds)) {
    selectedPlaces.value = wps;
  }
});

const carriers = computed(() => {
  return [...new Set(filteredReports.value.map((r) => r.workCarrier))];
});

watch(carriers, (cs, olds) => {
  if (!_.isEqual(cs, olds)) {
    selectedCarriers.value = cs;
  }
});

const resultReports = computed(() => {
  return filteredReports.value.filter((report) => {
    return (
      selectedCarriers.value.includes(report.workCarrier) &&
      selectedWorkCategories.value.includes(report.workCategory) &&
      selectedPlaces.value.includes(
        report.eventPlaceName || report.workPlaceName
      )
    );
  });
});

// tasks

const { getWorkTasks } = useWorkTasks();
const { data: workTasks } = getWorkTasks(selectedOrganization);

const filteredTasks = computed(() => {
  if (!workTasks.value) {
    return [];
  }

  const selectedEventPlaceName = selectedEventPlace.value;

  let tmps: WorkTaskClient[] = [];

  switch (selectedFilter.value) {
    case "requestOrganization":
      tmps = workTasks.value.filter(
        (task) =>
          task.entryWorkRequestOrganizationId ===
            selectedRequestOrganizationId.value &&
          (!selectedEventPlaceName ||
            task.entryEventPlaceName === selectedEventPlaceName)
      );
      break;
    case "member":
      tmps = workTasks.value.filter(
        (task) =>
          task.organizationMemberId === selectedOrganizationMemberId.value
      );
      break;
    default:
      tmps = [];
      break;
  }

  return tmps.filter((t) => {
    return (
      selectedCarriers.value.includes(t.entryWorkCarrier) &&
      selectedWorkCategories.value.includes(t.entryWorkCategory) &&
      selectedPlaces.value.includes(
        t.entryEventPlaceName || t.entryWorkPlaceName
      )
    );
  });
});

const contractedTasks = computed(() => {
  return filteredTasks.value.filter(
    (t) => t.contractedAt && isContainInDateMap(t.contractedAt, selectedDate)
  );
});

const contractedAndCreatedInThisMonthTasks = computed(() => {
  return contractedTasks.value.filter((task) =>
    isContainInDateMap(task.createdAt, selectedDate)
  );
});

const lostedTasks = computed(() => {
  return filteredTasks.value.filter((t) => t.lostedAt);
});

function getCategoryCarrierPairsFromWork(
  work: Work,
  workReports: WorkReport[]
) {
  return removeDupStrict(
    workReports
      .filter((report) => report.workId === work.id)
      .map((report) => ({
        category: report.workCategory,
        carrier: report.workCarrier,
      }))
  );
}

function getCategoryCarrierPairsFromMember(
  memberId: number,
  workReports: WorkReport[]
) {
  return removeDupStrict(
    workReports
      .filter((report) => report.organizationMemberId === memberId)
      .map((report) => ({
        category: report.workCategory,
        carrier: report.workCarrier,
      }))
  );
}

/*
function getCategoryCarrierPairs(workReports: WorkReport[]) {
  return removeDupStrict(
    workReports.map((report) => ({
      category: report.workCategory,
      carrier: report.workCarrier,
    }))
  );
}
*/

const needCategoryCarrierPairs = computed(() => {
  switch (selectedFilter.value) {
    case "requestOrganization":
      if (!works.value) {
        return [];
      }

      return removeDupStrict(
        works.value
          .filter(
            (w) =>
              w.requestOrganizationId === selectedRequestOrganizationId.value
          )
          .map((w) => getCategoryCarrierPairsFromWork(w, resultReports.value))
          .flat()
      );
    case "member":
      if (!selectedOrganizationMemberId.value || !works.value) {
        return [];
      }
      return getCategoryCarrierPairsFromMember(
        selectedOrganizationMemberId.value,
        resultReports.value
      );

    default:
      return [];
  }
});

const tableTitle = computed(() => {
  let main = "";

  switch (selectedFilter.value) {
    case "member":
      main = selectedOrganizationMember.value?.user.name || "";
      break;
    case "requestOrganization":
      main = selectedRequestOrganization.value?.name || "";
      if (selectedEventPlace.value) {
        main += `（${selectedEventPlace.value}）`;
      }
      break;
  }

  return `${selectedDate.month}月 ${main}`;
});

// update work report

async function handleUpdate(workReport: WorkRecordEntryReportForm) {
  if (!selectedOrganizationMemberId.value) return;

  if (await updateWorkReport(workReport, selectedOrganizationMemberId.value)) {
    alert("業務記録の更新に成功しました");
  } else {
    alert("業務記録の更新に失敗しました");
  }
}

function generateWorkReportPDF() {
  let filename: string = "";

  switch (selectedFilter.value) {
    case "member":
      filename += selectedOrganizationMember.value?.user.name;
      break;
    case "requestOrganization":
      filename += selectedRequestOrganization.value?.name;

      if (selectedEventPlace.value) {
        filename += `_${selectedEventPlace.value}`;
      }

      break;
    default:
      return;
  }

  filename = filename + "_" + basicFormatter(today, "noYear") + ".pdf";

  generatePDF("pdf-contents", filename, {
    unit: "in",
    format: "a4",
    orientation: "l",
    precision: 0,
  });
}

// hide map configs

const { data: hideMapConfigs } = getHideMapConfigs(selectedOrganization);

async function saveHideMapConfig(config: HideMapConfigForm) {
  if (!selectedOrganizationId.value) return;
  if (await createHideMapConfig(config, selectedOrganizationId.value)) {
    return;
  } else {
    alert("作成に失敗しました。");
  }
}

async function handleDestroyHideMapConfig(config: HideMapConfig) {
  if (!selectedOrganizationId.value) return;

  if (!window.confirm(`「${config.name}」を削除します。よろしいですか？`)) {
    return;
  }

  if (await destroyHideMapConfig(config, selectedOrganizationId.value)) {
    alert("削除に成功しました。");
  } else {
    alert("作成に失敗しました。");
  }
}
</script>

<style scoped>
.table-wrapper {
  overflow-x: scroll;
  max-height: 100%;
}
</style>
