<template>
  <draggable
    :class="{
      dragArea: !!tasks.length,
      emptyDragArea: !tasks.length,
      'list-group': true,
    }"
    tag="ul"
    :list="tasks"
    :group="{ name: 'g1', pull: 'clone', put: true }"
    item-key="name"
  >
    <template #item="{ element }">
      <li
        :data-id="element.id"
        :data-parent-id="element.parent_id"
        class="list-group-item"
      >
        <p>
          {{ element.orderPath.map((x: number) => x + 1).join("-") }}
          {{ element.name }}
          {{ getPlanRange(element) }}
        </p>
        <nested-draggable
          :tasks="element.children"
          @end="handleUpdate"
          @on-end="handleUpdate"
        />
      </li>
    </template>
  </draggable>
</template>

<script setup lang="ts">
import draggable from "vuedraggable";
import type { NestedTaskItemClient, TaskItemClient } from "/@/types";
import { basicFormatter } from "/@/modules/luxon";

defineProps<{
  tasks: NestedTaskItemClient[];
}>();

function getPlanRange(element: TaskItemClient) {
  if (!element.startOn || !element.finishOn) {
    return "-";
  }

  return `${basicFormatter(element.startOn, "slashStyle")}~${basicFormatter(
    element.finishOn,
    "slashStyle"
  )}`;
}

const emit = defineEmits<{
  (e: "onEnd", evt: any): void;
}>();

function handleUpdate(evt: any) {
  emit("onEnd", evt);
}
</script>
<style scoped>
.emptyDragArea {
  min-height: 30px;
  padding: 1rem;
  border: 1px dotted black;
}

p {
  margin-bottom: 0.25rem;
}

.dragArea {
  padding: 0.25rem;
}

.list-group-item {
  cursor: move;
  padding-left: 2rem;
  padding-right: 2rem;
}
.list-group-item i {
  cursor: pointer;
}
</style>
