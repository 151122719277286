import { axios } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import { MeetingTargetForm } from "/@/types/meetingTargets";

export function useMeetingTargets() {
  async function createMeetingTargets(
    workId: number,
    workScheduleId: number,
    form: MeetingTargetForm
  ) {
    try {
      await axios.post(
        `/api/v1/works/${workId}/meeting_targets?work_schedule_id=${workScheduleId}`,
        {
          meetingTargets: form,
        }
      );
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function updateMeetingTarget(form: MeetingTargetForm) {
    try {
      await axios.patch(`/api/v1/meeting_targets/${form.id}`, {
        meetingTargets: form,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function destroyMeetingTargets(id: number) {
    try {
      await axios.delete(`/api/v1/meeting_targets/${id}`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    createMeetingTargets,
    updateMeetingTarget,
    destroyMeetingTargets,
  };
}
