import type { Organization, OrganizationMemberRole } from "/@/types";

export function hasRole(
  { roles }: Partial<Organization>,
  role: OrganizationMemberRole
) {
  if (!roles) {
    return false;
  }

  return !!roles.includes(role);
}

export function isOwner(organization: Organization) {
  return hasRole(organization, "owner");
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  it("hasRole", () => {
    expect(hasRole({}, "owner")).toBeFalsy();
    expect(hasRole({ roles: ["owner"] }, "owner")).toBeTruthy();
  });
}
