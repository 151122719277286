<template>
  <div>
    <FormItemNumber
      :value="bodyTemperatureForm.temperature"
      label="体温"
      form-id="temperature"
      form-type="temperature"
      :errors="errors"
      @update:value="updateTemperature"
    />

    <div v-if="!forSickDay" class="d-flex mt-3 py-2">
      <BasicButton
        variant="danger"
        :disabled="loading"
        class="me-1"
        button-type="button"
        icon="pi pi-exclamation-circle"
        label="欠勤する"
        @click="toSickDay"
      />
      <BasicButton
        button-type="submit"
        variant="primary"
        label="提出"
        icon="pi pi-file-export"
        :disabled="loading"
        class="ms-1"
      />
    </div>
    <div v-else>
      <div class="mt-3">
        <TakePhoto
          v-model:photo="sickBodyTemperatureForm.photo"
          :is-valid="!sickErrors['photo']"
        />
        <MessageList :messages="photoErrorMessages" status="error" />
      </div>

      <div class="mt-3">
        <FormItem
          v-model:value="sickBodyTemperatureForm.explanation"
          :errors="sickErrors"
          label="説明"
          form-id="explanation"
          form-type="textarea"
        />
      </div>

      <div class="d-flex mt-3 py-2">
        <BasicButton
          :disabled="loading"
          class="me-2"
          button-type="button"
          label="キャンセル"
          variant="danger"
          icon="pi pi-times-circle"
          @click="backto"
        />
        <BasicButton
          button-type="submit"
          variant="primary"
          :disabled="loading"
          icon="pi pi-file-export"
          label="提出"
          class="ms-1"
          @click="submit"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from "vue";
import type { Ref } from "vue";
import { getErrorMessagesFromErrors } from "/@/modules/zodUtils";
import { useZodScheme, useRouterUtil } from "/@/vue/composables";
import {
  FormItem,
  FormItemNumber,
  TakePhoto,
} from "/@/vue/components/Molecules";
import { BasicButton, MessageList } from "/@/vue/components/Atom";
import { errorHandle } from "/@/modules/error";
import { BodyTemperatureScheme, SickDayBodyTemperatureScheme } from "/@/types";
import type { BodyTemperatureForm } from "/@/types";

defineProps<{
  loading?: boolean;
}>();

// TODO: type module への移動

const emit = defineEmits<{
  (e: "submit", bodyTemperature: BodyTemperatureForm): void;
}>();

const { backto } = useRouterUtil();

const { useFormAndErrors } = useZodScheme();

const {
  form: bodyTemperatureForm,
  errors,
  startValidation,
}: {
  form: BodyTemperatureForm;
  errors: any;
  startValidation: Ref<boolean | undefined>;
} = useFormAndErrors<BodyTemperatureForm>(BodyTemperatureScheme);

// Sick

const { useFormAndErrors: useSickFormErrors } = useZodScheme();

const {
  form: sickBodyTemperatureForm,
  errors: sickErrors,
  startValidation: sickStartValidation,
}: {
  form: BodyTemperatureForm;
  errors: any;
  startValidation: Ref<boolean | undefined>;
} = useSickFormErrors<BodyTemperatureForm>(SickDayBodyTemperatureScheme);

const photoErrorMessages = computed<string[]>(() => {
  const errors = getErrorMessagesFromErrors(sickErrors.value, "photo");
  return errors;
});

// update

onMounted(() => {
  bodyTemperatureForm.temperature = 36.5;
  sickBodyTemperatureForm.temperature = 36.5;
});

function updateTemperature(temperature: number | undefined) {
  bodyTemperatureForm.temperature = temperature;
  sickBodyTemperatureForm.temperature = temperature;
}

function submit() {
  try {
    startValidation.value = true;
    sickStartValidation.value = true;

    if (forSickDay.value) {
      SickDayBodyTemperatureScheme.parse(sickBodyTemperatureForm);
      emit("submit", sickBodyTemperatureForm);
    } else {
      BodyTemperatureScheme.parse(bodyTemperatureForm);
      emit("submit", bodyTemperatureForm);
    }
  } catch (e) {
    errorHandle(e);
  }
}

// for sick day

const forSickDay = ref<boolean>(true);

function toSickDay() {
  forSickDay.value = true;
}

function cancelToSickDay() {
  forSickDay.value = false;
}
</script>

<style scoped></style>
