<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="36"
    viewBox="0 0 40 36"
  >
    <g id="camera_icon_white" transform="translate(2)">
      <g
        id="グループ_23"
        data-name="グループ 23"
        transform="translate(0 8.937)"
      >
        <g
          id="長方形_4"
          data-name="長方形 4"
          transform="translate(0 0.063)"
          fill="none"
          stroke="#C2C2C2"
          stroke-linejoin="round"
          stroke-width="2"
        >
          <rect width="36" height="25" stroke="none" />
          <rect x="-1" y="-1" width="38" height="27" fill="none" />
        </g>
      </g>
      <g
        id="楕円形_13"
        data-name="楕円形 13"
        transform="translate(9 13)"
        fill="none"
        stroke="#C2C2C2"
        stroke-width="2"
      >
        <ellipse cx="9" cy="8.5" rx="9" ry="8.5" stroke="none" />
        <ellipse cx="9" cy="8.5" rx="8" ry="7.5" fill="none" />
      </g>
      <g
        id="長方形_14"
        data-name="長方形 14"
        transform="translate(23)"
        fill="none"
        stroke="#C2C2C2"
        stroke-width="2"
      >
        <rect width="11" height="9" rx="1" stroke="none" />
        <rect x="1" y="1" width="9" height="7" fill="none" />
      </g>
    </g>
  </svg>
</template>
