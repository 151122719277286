<template>
  <template v-for="cck in categoryCarrierKeys" :key="`row-${cck}`">
    <template
      v-for="(item, idx) in customersItems[cck]"
      :key="`body-tr-${item.key}`"
    >
      <td
        v-if="showEditMode || !hideMap?.[cck]?.customers?.[idx]"
        class="cell-number"
      >
        <span v-if="!!customersItemCountMap[cck]">
          {{ customersItemCountMap[cck]?.[item.key + idx]?.sum || "" }}
        </span>
      </td>
    </template>
    <template
      v-for="(item, idx) in indiviItems[cck]"
      :key="`body-tr-${item.key}`"
    >
      <td
        v-if="showEditMode || !hideMap?.[cck]?.indivi?.[idx]"
        class="cell-number"
      >
        <span v-if="!!indiviItemCountMap[cck]">
          {{ indiviItemCountMap[cck]?.[item.key + idx]?.sum || "" }}
        </span>
      </td>
    </template>
    <template
      v-for="(item, idx) in overallItems[cck]"
      :key="`body-tr-${item.key}`"
    >
      <td
        v-if="showEditMode || !hideMap?.[cck]?.overall?.[idx]"
        class="cell-number"
      >
        <span v-if="!!overallItemCountMap[cck]">
          {{ overallItemCountMap?.[cck]?.[item.key + idx]?.sum || "" }}
        </span>
      </td>
    </template>
  </template>
</template>

<script setup lang="ts">
import { computed } from "vue";
import {
  CategoryCarrierKey,
  CategoryCarrierReportItemsMap,
  ReportItem,
  WorkReport,
  WorkTaskClient,
  HideMap,
} from "/@/types";

const props = defineProps<{
  categoryCarrierKeys: CategoryCarrierKey[];
  customersItems: CategoryCarrierReportItemsMap;
  indiviItems: CategoryCarrierReportItemsMap;
  overallItems: CategoryCarrierReportItemsMap;
  workReports: WorkReport[];
  tasks: WorkTaskClient[];
  hideMap: HideMap;
  showEditMode?: boolean;
}>();

function getCountByKey(reportItems: ReportItem[], reportItem: ReportItem) {
  return (
    reportItems.find(
      (item) =>
        item.basisReportItemId === reportItem.basisReportItemId &&
        !!item.overall === !!reportItem.overall &&
        !!item.customers === !!reportItem.customers
    )?.count || 0
  );
}

function createItemCountMap(itemMap: CategoryCarrierReportItemsMap) {
  const m: Partial<
    Record<
      CategoryCarrierKey,
      Record<string, { reportCount: number; taskCount: number; sum: number }>
    >
  > = {};

  props.categoryCarrierKeys.forEach((k) => {
    m[k] = {};

    itemMap[k]?.forEach((item, idx) => {
      const targetCategoryCarrierReports = props.workReports.filter(
        (r) => k.includes(r.workCategory) && k.includes(r.workCarrier)
      );

      const reportCount = targetCategoryCarrierReports.reduce(
        (acc, current) => {
          return acc + getCountByKey(current.reportItems, item);
        },
        0
      );

      const targetCategoryCarrierTaskReports = props.tasks.filter(
        (r) => k.includes(r.entryWorkCategory) && k.includes(r.entryWorkCarrier)
      );

      const taskCount = targetCategoryCarrierTaskReports.reduce(
        (acc, current) => {
          // @ts-ignore
          return acc + getCountByKey(current.reportItems, item);
        },
        0
      );

      const mapKey = item.key.toString() + idx.toString();

      // @ts-ignore
      m[k][mapKey] = {
        reportCount,
        taskCount,
        sum: reportCount + taskCount,
      };
    });
  });

  return m;
}

const customersItemCountMap = computed(() => {
  return createItemCountMap(props.customersItems);
});

const indiviItemCountMap = computed(() => {
  return createItemCountMap(props.indiviItems);
});

const overallItemCountMap = computed(() => {
  return createItemCountMap(props.overallItems);
});
</script>

<style scoped lang="scss">
.cell-number {
  max-width: 50px;
  min-width: 50px;
}
</style>
