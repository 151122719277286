<template>
  <div>
    <div class="d-flex align-items-center">
      <ContentLabel
        v-if="label"
        :label="label"
        :content-id="formId"
        without-padding
      />
      <slot></slot>
    </div>

    <CheckFormArray
      :values="value"
      :items="items"
      :form-id="formId"
      :is-valid="!errors[formId]"
      :class="checkFormArrayClass"
      :item-class="arrayItemClass"
      @update:values="$emit('update:value', $event)"
    />
    <MessageList :messages="errorMessage" status="error" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { CheckFormArray } from "/@/vue/components/Molecules";
import { getErrorMessagesFromErrors } from "/@/modules/zodUtils";
import { ContentLabel, MessageList } from "/@/vue/components/Atom";

type Item = {
  value: any;
  name: string;
};

interface Props {
  value?: any[];
  items: Item[] | readonly Item[];
  label: string;
  formId: string;
  valueKey?: string;
  showKey?: string;
  disabled?: boolean;
  checkFormArrayClass?: string;
  arrayItemClass?: string;
  errors: {
    [key in string]?: {
      _errors: string[];
    };
  };
}

const props = defineProps<Props>();
defineEmits<{
  (e: "update:value", value: Item): void;
}>();

const errorMessage = computed<string[]>(() => {
  return getErrorMessagesFromErrors(props.errors, props.formId);
});
</script>

<style scoped></style>
