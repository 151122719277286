<template>
  <div>
    <SimpleModal
      ref="modal"
      :title="form.id ? '目標の更新' : '目標の作成'"
      submit-text="送信"
      :loading="loading"
      :modal-class="{
        'modal-xl': true,
      }"
      @cancel="closeModal"
      @submit="handleSubmit"
    >
      <span v-if="!workPlaces.length">
        対象のキャリア/エリアに権限がある店舗がありません
      </span>
      <FormItemSelector
        v-if="!form.id"
        :value="form.workPlaceId"
        label="店舗名"
        form-type="object"
        :errors="errors"
        :items="workPlaces"
        form-id="selected-work-schedules"
        show-key="name"
        value-key="id"
        class="mb-3"
        @update:value="form.workPlaceId = $event.id"
      />
      <!--
      <MeetingTargetForm
        v-if="selectedWorkPlace && false"
        v-model:report-items="form.reportItems"
        :work-type-categories="[selectedWorkPlace.placeCategory]"
        :work-carrier="selectedWorkPlace.placeSubCategory"
        :basis-report-items="basisReportItems"
        :errors="errors"
      />
      -->

      <template v-if="form.workPlaceId">
        <template v-if="isMobile">
          <div>中央で使用/解除の切り替えが、</div>
          <div class="mb-3">下部で表示順の変更ができます</div>
        </template>

        <div v-if="false" class="d-flex justify-content-end">
          <BasicButton
            label="項目の追加"
            icon="pi pi-plus-circle"
            class="me-2"
            @click="addMeetingTargetFormItem"
          />
          <BasicButton
            label="追加項目の削除"
            icon="pi pi-trash"
            variant="danger"
            @click="deleteAddedFormItemMode = !deleteAddedFormItemMode"
          />
        </div>
        <FormItem
          v-model:value="searchItemWord"
          form-id="seacrh"
          label="検索"
          form-type="text"
          :errors="{}"
          class="mb-3"
        />
        <PickList
          v-model="useFormItem"
          list-style="height:342px"
          data-key="id"
          breakpoint="1400px"
          class="w-100"
          :show-source-controls="false"
          :move-all-to-target-props="{
            style: 'display: none',
          }"
          :move-all-to-source-props="{
            severity: 'danger',
            style: 'margin: 0.25rem 0.15rem',
          }"
          :move-to-target-props="{
            style: 'margin: 0.25rem 0.15rem',
          }"
          :move-to-source-props="{
            severity: 'danger',
            style: 'margin: 0.25rem 0.15rem',
          }"
          :move-up-button-props="{
            style: 'margin: 0.25rem 0.15rem',
          }"
          :move-down-button-props="{
            style: 'margin: 0.25rem 0.15rem',
          }"
          :move-top-button-props="{
            style: 'margin: 0.25rem 0.15rem',
          }"
          :move-bottom-button-props="{
            style: 'margin: 0.25rem 0.15rem',
          }"
        >
          <template #sourceheader> 候補 </template>
          <template #targetheader> 使用する項目 </template>
          <template #movetotargeticon>
            <div class="d-flex text-nowrap">
              <i class="pi pi-plus-circle me-1"></i>
              <span>追加</span>
            </div>
          </template>
          <template #movetosourceicon>
            <div class="d-flex text-nowrap">
              <i class="pi pi-trash me-1"></i>
              <span>解除</span>
            </div>
          </template>
          <template #movealltosourceicon>
            <div class="d-flex text-nowrap">
              <i class="pi pi-trash me-1"></i>
              <span>全解除</span>
            </div>
          </template>
          <template #moveupicon>
            <div class="d-flex text-nowrap">
              <i class="pi pi-angle-up"></i>
              <span>1上</span>
            </div>
          </template>
          <template #movedownicon>
            <div class="d-flex text-nowrap">
              <i class="pi pi-angle-down"></i>
              <span>1下</span>
            </div>
          </template>
          <template #movetopicon>
            <div class="d-flex text-nowrap">
              <i class="pi pi-angle-double-up"></i>
              <span>最上部</span>
            </div>
          </template>
          <template #movebottomicon>
            <div class="d-flex text-nowrap">
              <i class="pi pi-angle-double-down"></i>
              <span>最下部</span>
            </div>
          </template>
          <template #item="slotProps">
            <div
              v-if="slotProps.item.isAdded"
              class="d-flex w-100 justify-content-between align-items-center"
            >
              <span>{{ slotProps.item.name }}</span>
              <BasicButton
                v-if="deleteAddedFormItemMode"
                icon="pi pi-trash"
                variant="danger"
                @click="
                  emit('destroyMeetingTargetAddedFormItem', slotProps.item.id)
                "
              />
            </div>
            <span v-else>
              <div class="d-flex align-items-center">
                <span class="me-2">{{
                  slotProps.item.key || slotProps.item.name
                }}</span>
                <Chip
                  v-if="slotProps.item.groupName"
                  :label="slotProps.item.groupName"
                />
              </div>
            </span>
          </template>
        </PickList>
      </template>
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";
import { computed, ref, onMounted, watch } from "vue";
import { useZodScheme, useMqUtils } from "/@/vue/composables";
import { isTargetConfig } from "/@/modules/workReport";
import { errorHandle } from "/@/modules/error";
import {
  dateMapToISO,
  isBeforeToday,
  isAfterToday,
  isContain,
  dateMapToLuxonDate,
} from "/@/modules/luxon";
import { BasicButton } from "/@/vue/components/Atom";
import {
  FormItemSelector,
  SimpleModal,
  FormItem,
} from "/@/vue/components/Molecules";
import {
  BasisReportItemClient,
  MeetingTargetWorkPlaceScheme,
  MeetingTargetWorkPlaceForm,
  MeetingTargetWorkPlaceClient,
  MeetingTargetWorkPlace,
  MeetingTargetReportItem,
  WorkPlace,
  DateMap,
  Carrier,
  MeetingTargetAddedFormItemClient,
} from "/@/types";
import PickList from "primevue/picklist";
import Chip from "primevue/chip";

const { isMobile } = useMqUtils();

const props = defineProps<{
  workPlaces: WorkPlace[];
  basisReportItems: BasisReportItemClient[];
  addedFormItems: MeetingTargetAddedFormItemClient[];
  selectedDate: DateMap;
  selectedCarrier?: Carrier;
}>();

const emit = defineEmits<{
  (e: "submit", form: MeetingTargetWorkPlace): void;
  (e: "addMeetingTargetAddedFormItem", prms: { name: string }): void;
  (e: "destroyMeetingTargetAddedFormItem", id: number): void;
}>();

const loading = ref<boolean>();

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } =
  useFormAndErrors<MeetingTargetWorkPlaceForm>(MeetingTargetWorkPlaceScheme, {
    reportItems: [],
  });

const selectedWorkPlace = computed(() => {
  if (!form.workPlaceId) return undefined;

  return props.workPlaces.find((w) => w.id === form.workPlaceId);
});

const useFormItem = ref<
  [BasisReportItemClient[] | MeetingTargetReportItem[], any[]]
>([[], []]);
const searchItemWord = ref<string | undefined>();

function formReset(meetingTarget?: MeetingTargetWorkPlaceClient) {
  form.id = meetingTarget?.id;
  form.workPlaceId = meetingTarget?.workPlaceId;
  form.remarks = meetingTarget?.remarks || undefined;
  form.targetDate = meetingTarget?.targetDate;
  form.reportItems = meetingTarget?.reportItems || [];
  form.organizationId = meetingTarget?.organizationId;
  form.organizationMemberId = meetingTarget?.organizationMemberId;
}

onMounted(() => {});

async function handleSubmit() {
  try {
    loading.value = true;
    startValidation.value = true;

    const targetDate = dateMapToISO(props.selectedDate);

    if (!targetDate) throw new Error("targetDate is unavailable");

    form.targetDate = targetDate;
    form.reportItems = useFormItem.value[1].map((i, idx) => {
      if (i.isAdded) {
        return {
          key: i.name,
          count: 1,
          currentCount: 0,
          currentOrganizationCount: 0,
          overall: false,
          customers: false,
          targetParcent: i.targetParcent,
          summarizeTarget: !!i.summarizeTarget,
          summarizeCurrent: !!i.summarizeCurrent,
          basisReportItemId: undefined,
          order: idx,
          isAdded: true,
        };
      } else {
        return {
          key: i.name || i.key,
          count: i.count || 1,
          currentCount: 0,
          currentOrganizationCount: 0,
          overall: i.overall || false,
          customers: i.customers || false,
          targetParcent: i.targetParcent || 100,
          summarizeTarget: !!i.summarizeTarget,
          summarizeCurrent: !!i.summarizeCurrent,
          basisReportItemId: i.id || i.basisReportItemId || undefined,
          order: idx,
        };
      }
    });

    const meetingTargetPrms: MeetingTargetWorkPlace =
      MeetingTargetWorkPlaceScheme.parse(form);

    emit("submit", meetingTargetPrms);
    closeModal();
    formReset();
  } catch (e) {
    errorHandle(e);
    loading.value = false;
  }
}

function isShow(basisReportItem: BasisReportItemClient) {
  /*
  if (
    !basisReportItem.configs.some((c) => {
      const hitConfig = isTargetConfig(c, {
        workCarrier: props.selectedCarrier,
        overall: true,
        individual: true,
        overallCustomers: true,
        individualCustomers: true,
      });

      return hitConfig;
    })
  ) {
    return false;
  }
  */

  return (
    (!basisReportItem.validFrom && !basisReportItem.validTo) ||
    (!!basisReportItem.validFrom &&
      isBeforeToday(
        basisReportItem.validFrom,
        dateMapToLuxonDate(props.selectedDate)
      )) ||
    (!!basisReportItem.validTo &&
      isAfterToday(
        basisReportItem.validTo,
        dateMapToLuxonDate(props.selectedDate)
      )) ||
    (basisReportItem.validFrom &&
      basisReportItem.validTo &&
      isContain(dateMapToISO(props.selectedDate), {
        start: basisReportItem.validFrom,
        end: basisReportItem.validTo,
      }))
  );
}

function isTarget(basisItem: BasisReportItemClient) {
  return (
    isShow(basisItem) &&
    basisItem.configs.some((c) => {
      const hitConfig =
        isTargetConfig(c, {
          workCarrier: props.selectedCarrier,
          overall: true,
          individual: true,
          overallCustomers: true,
          individualCustomers: true,
        }) ||
        isTargetConfig(c, {
          workCarrier: props.selectedCarrier,
          isInvalid: true,
        });

      return hitConfig;
    }) &&
    (!form.reportItems ||
      !form.reportItems.some(
        (i) => basisItem.name === i.key || basisItem.id === i.basisReportItemId
      ))
  );
}

function isTargetAdded(addedFormItem: MeetingTargetAddedFormItemClient) {
  return !useFormItem.value[1].some((i) => addedFormItem.name === i.key);
}

const targetAdded = computed(() => {
  return props.addedFormItems
    .filter((item) => isTargetAdded(item))
    .map((item) => ({
      ...item,
      isAdded: true,
    }));
});

const targetBasis = computed(() => {
  return _.sortBy(
    props.basisReportItems.filter((item) => isTarget(item)),
    ["name"]
  );
});

const modal = ref();

function openModal({
  meetingTarget,
  organizationId,
  organizationMemberId,
  workPlaceId,
}: {
  meetingTarget: MeetingTargetWorkPlaceClient | undefined;
  organizationId: number;
  organizationMemberId: number;
  workPlaceId: number;
}) {
  if (meetingTarget) {
    formReset(meetingTarget);
  } else {
    formReset();
    form.workPlaceId = workPlaceId;
    form.organizationId = organizationId;
    form.organizationMemberId = organizationMemberId;
  }

  const targetItems = [
    //...targetAdded.value,
    ...targetBasis.value,
  ];

  useFormItem.value = [
    targetItems,
    form.reportItems?.map((ri) => ({
      ...ri,
      ...props.basisReportItems.find((ti) => ti.id === ri.basisReportItemId),
    })),
  ];
  modal.value?.openModal();
}

function updateUseFormItem(
  w: string,
  added: MeetingTargetAddedFormItemClient[],
  basis: BasisReportItemClient[]
) {
  useFormItem.value = [
    [
      //...added.filter((item) => !w || w === "" || item.name?.includes(w)),
      ...basis.filter(
        (item) =>
          isTarget(item) &&
          (!w ||
            w === "" ||
            item.name?.includes(w) ||
            item.shortName?.includes(w))
      ),
    ],
    useFormItem.value[1],
  ];
}

watch(
  () => searchItemWord.value,
  (w) => {
    updateUseFormItem(w, targetAdded.value, targetBasis.value);
  }
);

watch(
  () => targetAdded.value,
  (added) => {
    updateUseFormItem(searchItemWord.value, added, targetBasis.value);
  }
);

function closeModal() {
  searchItemWord.value = undefined;
  modal.value?.closeModal();
}

const deleteAddedFormItemMode = ref<boolean>(false);

function addMeetingTargetFormItem() {
  const name = prompt("項目名を入力してください");

  if (!name) {
    return;
  }

  emit("addMeetingTargetAddedFormItem", { name });
}

function destroyMeetingTargetAddedFormItem(id: number) {
  emit("destroyMeetingTargetAddedFormItem", { id });
}

defineExpose({
  openModal,
  closeModal,
});
</script>

<style scoped lang="scss">
button[aria-label="Move All to Target"] {
  display: none;
}

.p-chip .p-chip-text {
  margin: 0;
}
</style>
