import { fromISO, isContain } from "./luxon";
import type {
  DateMapForm,
  NestedTaskItemClient,
  TaskItemClient,
  UpdatableTaskItemAttributes,
} from "/@/types";

export function taskIdFromAncestry(orderPath: number[]) {
  return orderPath.map((order) => order + 1).join("-");
}

export function getIndentCount(orderPath: number[]) {
  return orderPath.length - 1;
}

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;

  it("taskIdFromAncestry", () => {
    expect(taskIdFromAncestry([0])).toBe("1");
    expect(taskIdFromAncestry([0, 1, 2])).toBe("1-2-3");
    expect(taskIdFromAncestry([2, 3, 1])).toBe("3-4-2");
  });

  it("getIndentCount", () => {
    expect(getIndentCount([1])).toBe(0);
    expect(getIndentCount([1, 2, 3])).toBe(2);
    expect(getIndentCount([1, 2, 3, 4])).toBe(3);
  });
}

export function updateTaskItem(
  taskItems: TaskItemClient[],
  params: {
    taskId: string;
    key: UpdatableTaskItemAttributes;
    value: number | string | undefined;
  }
) {
  // TODO: 使用しているコードを削除しこれも削除
  // 同じ名前のAPIと通信する用の別な関数が存在しているので注意

  const { taskId, key, value } = params;

  const target = taskItems.find((task) => task.taskId == taskId);

  if (!target) {
    throw new Error(`id ${taskId} not found in taskItems`);
  }

  if (
    "needTime" === key &&
    (typeof value === "number" || typeof value === "undefined")
  ) {
    target[key] = value;
  } else if (
    "needTime" !== key &&
    (typeof value === "string" || typeof value === "undefined")
  ) {
    target[key] = value;
  } else {
    throw new Error(`dont expected pattern in updateTaskItem: ${key} ${value}`);
  }
}

export function taskItemsFilter(
  taskItems: NestedTaskItemClient[],
  searchParams: {
    searchWord: string | undefined;
    needCount: number;
    selectedDate: DateMapForm;
    hideDoneTask: boolean;
  }
): NestedTaskItemClient[] {
  const { searchWord, needCount, selectedDate, hideDoneTask } = searchParams;

  return taskItems
    .filter((taskItem) => {
      if (hideDoneTask && !!taskItem.doneAt) {
        return false;
      }

      if (!searchWord || searchWord.length < needCount) {
        return true;
      }

      if (taskItem.name?.includes(searchWord)) {
        return true;
      } else if (taskItem.children) {
        const filteredChildren = taskItemsFilter(
          taskItem.children,
          searchParams
        );
        return filteredChildren.length > 0;
      } else {
        return false;
      }
    })
    .filter((taskItem: NestedTaskItemClient) => {
      // 日付に関するフィルター
      if (!selectedDate.year) {
        return true;
      }

      if (!taskItem.startOn || !taskItem.finishOn) {
        return false;
      }

      if (selectedDate.day) {
        return isContain(selectedDate.day, {
          start: taskItem.startOn,
          end: taskItem.finishOn,
        });
      } else {
        const luxonStartOn = fromISO(taskItem.startOn);
        const luxonFinishOn = fromISO(taskItem.finishOn);

        return (
          (luxonStartOn.year === selectedDate.year &&
            (!selectedDate.month ||
              luxonStartOn.month === selectedDate.month)) ||
          (luxonFinishOn.year === selectedDate.year &&
            (!selectedDate.month || luxonFinishOn.month === selectedDate.month))
        );
      }
    });
}
