<template>
  <tr>
    <td class="name-td">
      <div class="d-flex flex-column">
        <span>{{ member.user.name }}</span>
        <span v-if="subContractor" class="small text-secondary">
          {{ subContractor.name }}
        </span>
      </div>
    </td>
    <td v-for="group of labelGroups" :key="group.name" class="value-td">
      <MultiSelect
        :model-value="
          labels.filter((id) =>
            labelsMap[group.name]?.map((label) => label.id).includes(id)
          )
        "
        :options="labelsMap[group.name] || []"
        option-value="id"
        :option-label="(label: ManagementLabelClient) => labelToStr(label)"
        without-border
        without-trigger
        without-bg
        class="w-100"
        @update:model-value="updateLabels(group.name, $event)"
      />
    </td>
  </tr>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { labelToStr } from "/@/modules/managementLabel";
import { MultiSelect } from "/@/vue/components/Atom";
import {
  OrganizationMember,
  ManagementLabelClient,
  ContractRelationOrganization,
  LabelGroup,
} from "/@/types";

const props = defineProps<{
  labels: number[];
  member: OrganizationMember;
  managementLabels: ManagementLabelClient[];
  labelGroups: LabelGroup[];
  subContractors: ContractRelationOrganization[];
}>();

const emit = defineEmits<{
  (e: "update:labels", labelIds: number[]): void;
}>();

const subContractor = computed(() => {
  return props.subContractors.find(
    (subc) => subc.id === props.member.organizationId
  );
});

// label

const labelsMap = computed(() => {
  let m: Partial<Record<string, ManagementLabelClient[]>> = {};

  props.labelGroups.forEach((group) => {
    m[group.name] = props.managementLabels.filter(
      (label) =>
        (label.labelType === "department_owner" &&
          label.departmentName === group.name) ||
        (label.labelType !== "department_owner" &&
          label.labelType === group.groupType)
    );
  });

  return m;
});

// update

function updateLabels(groupName: string, labelIds: number[]) {
  // calculate diifference set of props.labels and labelsMap[groupName]
  const difference = props.labels.filter(
    (id) =>
      !labelsMap.value[groupName]?.map((label) => label.id).includes(id) &&
      !labelIds.includes(id)
  );

  const sortedLabelIds =
    labelsMap.value[groupName]
      ?.filter((label) => {
        return labelIds.includes(label.id);
      })
      .map((label) => label.id) || [];

  const newLabels = [...new Set(difference.concat(sortedLabelIds))];

  emit("update:labels", newLabels);
}
</script>

<style scoped lang="scss">
.p-multiselect {
  border: none;
  .p-multiselect-trigger {
    display: none;
  }
}

table.table {
  td.value-td {
    min-width: 200px;
  }
}
</style>
