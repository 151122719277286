<template>
  <div>
    <SimpleModal
      ref="modal"
      title="目標の編集"
      submit-text="送信"
      :loading="loading"
      @cancel="closeModal"
      @submit="handleSubmit"
    >
      <MeetingTargetForm
        v-if="meetingTarget"
        v-model:report-items="form.reportItems"
        report-type="overall"
        :work-type-categories="meetingTarget.workTypeCategories"
        :work-carrier="meetingTarget.workCarrier"
        :work-category="meetingTarget.workCategory"
        :meeting-target-filter="meetingTargetFilter"
        :basis-report-items="basisReportItems"
      />
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import { MeetingTargetForm } from "/@/vue/components/Organisms";
import { useZodScheme } from "/@/vue/composables";
import { SimpleModal } from "/@/vue/components/Molecules";
import {
  BasisReportItemClient,
  MeetingTargetForm as MeetingTargetFormType,
  MeetingTargetSubmitScheme,
  MeetingTargetSubmit,
  MeetingTargetClient,
  ReportItemFilter,
} from "/@/types";
import { errorHandle } from "/@/modules/error";

const props = defineProps<{
  meetingTarget?: MeetingTargetClient;
  meetingTargetFilter?: ReportItemFilter[];
  organizationMemberId?: number;
  basisReportItems: BasisReportItemClient[];
}>();

function formReset() {
  if (!props.meetingTarget || !props.organizationMemberId) return;

  form.reportItems = props.meetingTarget.reportItems;
  form.id = props.meetingTarget.id;
  form.organizationMemberId = props.meetingTarget.organizationMemberId;
}

const emit = defineEmits<{
  (e: "submit", form: MeetingTargetFormType): void;
}>();

watch(
  () => props.meetingTarget,
  () => formReset()
);

const loading = ref<boolean>();

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } =
  useFormAndErrors<MeetingTargetFormType>(MeetingTargetSubmitScheme);

onMounted(() => {
  formReset();
});

async function handleSubmit() {
  try {
    if (!props.meetingTarget) return;

    loading.value = true;
    startValidation.value = true;

    const meetingTargetPrms: MeetingTargetSubmit =
      MeetingTargetSubmitScheme.parse(form);

    emit("submit", meetingTargetPrms);
    closeModal();
    formReset();
  } catch (e) {
    errorHandle(e);
    loading.value = false;
    formReset();
  }

  loading.value = false;
}

const modal = ref();

function openModal() {
  formReset();
  modal.value?.openModal();
}

function closeModal() {
  modal.value?.closeModal();
}

defineExpose({
  openModal,
  closeModal,
});
</script>

<style scoped></style>
