<template>
  <div>
    <InputForm
      :value="value"
      :form-id="formId"
      form-type="search"
      :disabled="disabled"
      is-valid
      @update:value="update"
    >
    </InputForm>
  </div>
</template>

<script setup lang="ts">
import { InputForm } from "./";

interface Props {
  value?: string;
  formId?: string;
  disabled?: boolean;
}

defineProps<Props>();

const emit = defineEmits<{
  (e: "update:value", value: string | undefined): void;
}>();

function update(val: string | undefined) {
  emit("update:value", val);
}
</script>

<style lang="scss" scoped>
.btn {
  border-color: var(--bs-border-color);
}
</style>
