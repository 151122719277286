<template>
  <div id="work-index d-flex flex-column">
    <template v-if="isCreateWork">
      <OrganizationSelector
        v-if="organizations"
        v-model:selected-organization-id="selectedOrganizationId"
        class="mb-3"
        :organizations="organizations"
      />
      <template v-if="isOwner">
        <div class="filter-and-btn">
          <div
            v-if="isOwner || isHomeworker"
            class="w-100 d-flex justify-content-end new-btn"
          >
            <div>
              <BasicButton
                class="text-nowrap"
                label="新規作成"
                icon="pi pi-plus-circle"
                @click="goto({ name: 'WorksNew' })"
              />
            </div>
          </div>
          <WorkListFilter
            v-if="worksRes"
            v-model:selected-date="selectedDate"
            v-model:selected-carriers="selectedCarriers"
            v-model:selected-categories="selectedCategories"
            :oldest-start-on="worksRes.startOn"
            :newest-finish-on="worksRes.finishOn"
          />
        </div>
        <div class="list-wrapper">
          <WorkList
            :is-owner="isOwner"
            :works="filteredWorks"
            @update-work="toUpdateWork"
            @destroy-work="handleDestroy"
          />
        </div>
      </template>
    </template>
    <template v-else>
      <span class="text-danger"> この組織では案件作成の権限がありません </span>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { luxonNow } from "/@/modules/luxon";
import { getStorage, setStorage } from "/@/modules/localStorage";
import { useOrganization, useWorks, useRouterUtil } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import {
  OrganizationSelector,
  WorkList,
  WorkListFilter,
} from "/@/vue/components/Organisms";
import type {
  Organization,
  DateMapForm,
  Carrier,
  WorkCategory,
} from "/@/types";

const { goto } = useRouterUtil();

const { getOrganizations, asOwner, asHomeworker } = useOrganization();
const { data: organizations } = getOrganizations();

const selectedOrganizationId = ref<number | undefined>(
  getStorage("selectedOrganizationId")
);
watch(selectedOrganizationId, (id) => setStorage("selectedOrganizationId", id));
const selectedOrganization = computed<Organization | undefined>(() => {
  if (!organizations.value) {
    return;
  }

  return organizations.value.find((o) => o.id == selectedOrganizationId.value);
});

const isOwner = computed(() => {
  return asOwner(selectedOrganization.value);
});

const isHomeworker = computed(() => {
  return asHomeworker(selectedOrganization.value);
});

const isCreateWork = computed(() => {
  if (!selectedOrganization.value) return;
  return selectedOrganization.value.categories?.includes("create_work");
});

// filter

const today = luxonNow();
const selectedDate = ref<DateMapForm>(
  getStorage("WorksIndexSelectedDate") || {
    year: today.year,
    month: today.month,
  }
);

watch(selectedDate, (newValue) => {
  setStorage("WorksIndexSelectedDate", newValue);
});

const selectedCarriers = ref<Carrier[]>(
  getStorage("WorksIndexSelectedCarriers") || [
    "docomo",
    "softbank",
    "au",
    "yahoo",
    "uq",
  ]
);
watch(selectedCarriers, (newValue) => {
  setStorage("WorksIndexSelectedCarriers", newValue);
});
const selectedCategories = ref<WorkCategory[]>(
  getStorage("WorksIndexSelectedCategories") || [
    "event_helper",
    "rounder",
    "shop_helper",
    "light_ad",
    "consulting",
    "fulltime",
    "sv",
    "sajit",
  ]
);
watch(selectedCategories, (newValue) => {
  setStorage("WorksIndexSelectedCategories", newValue);
});

// works

const { getWorks, destroyWork } = useWorks();
const { data: worksRes, mutate: getWorksMutate } = getWorks(
  selectedOrganization,
  selectedDate
);

const works = computed(() => {
  if (!worksRes.value) {
    return [];
  }

  return worksRes.value.works;
});

const filteredWorks = computed(() => {
  if (!works.value) {
    return [];
  }

  return works.value.filter((w) => {
    return (
      selectedCarriers.value.includes(w.carrier) &&
      selectedCategories.value.includes(w.workCategory)
    );
  });
});

const newestFinishOn = computed<string>(() => {
  const endOn = today.plus({ year: 1 }).toISO(); // 1年は見れるように

  if (!endOn) {
    throw new Error("unexpected toISO parse");
  }

  return endOn;
});

async function handleDestroy(id: number) {
  if (!window.confirm("案件を削除します。よろしいですか？")) {
    return;
  }

  if (await destroyWork(id)) {
    alert("削除に成功しました");
    getWorksMutate();
  }
}

// to

function toUpdateWork(id: number) {
  goto({ name: "WorksEdit", query: { id } });
}
</script>

<style scoped lang="scss">
#work-index {
  height: 100vh;
}
.filter-and-btn {
  position: relative;
  .new-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.list-wrapper {
  overflow-y: scroll;
  max-height: 100%;
}
</style>
