<template>
  <div>
    <PageHeader page-title="案件詳細" />
    <OrganizationSelector
      v-if="organizations"
      v-model:selected-organization-id="selectedOrganizationId"
      class="mb-3"
      :organizations="organizations"
    />
    <template v-if="organizationMemberId">
      <WorkRecordDailyShowForm
        v-if="daily"
        :daily="daily"
        :basis-report-items="basisReportItems || []"
        :is-actual="isFRYTHOwner"
      />
      <span v-else class="text-danger">権限がありません</span>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { setStorage, getStorage } from "/@/modules/localStorage";
import {
  useRouterUtil,
  useWorkRecords,
  useOrganization,
  useBasisReportItems,
} from "/@/vue/composables";
import { PageHeader } from "/@/vue/components/Layouts";
import {
  WorkRecordDailyShowForm,
  OrganizationSelector,
} from "/@/vue/components/Organisms";
import { Organization } from "/@/types";

const { query } = useRouterUtil();

const targetDate = computed(() => {
  return query.value.targetDate as string;
});

const { getOrganizations, asOwner } = useOrganization();
const { data: organizations } = getOrganizations();

const selectedOrganizationId = ref<number | undefined>(
  getStorage("selectedOrganizationId")
);
watch(selectedOrganizationId, (id) => setStorage("selectedOrganizationId", id));
const selectedOrganization = computed<Organization | undefined>(() => {
  if (!organizations.value) {
    return;
  }

  return organizations.value?.find((o) => o.id == selectedOrganizationId.value);
});

const isOwner = computed(() => {
  return asOwner(selectedOrganization.value);
});

const isFRYTHOwner = computed(() => {
  return isOwner.value && selectedOrganization.value?.name === "(株)FRYTH";
});

const organizationMemberId = computed(() => {
  return query.value.organizationMemberId as string;
});

const { getWorkRecordDaily } = useWorkRecords();
const { data: daily } = getWorkRecordDaily(organizationMemberId, targetDate);

const { getBasisReportItems } = useBasisReportItems();
const { data: basisReportItems } = getBasisReportItems();
</script>

<style scoped></style>
