<template>
  <div>
    <FormItemSelector
      :value="labelType"
      label="ラベル種別"
      form-type="general"
      :errors="errors"
      :items="labelTypes"
      :show-fn="labelTypeToStr"
      form-id="labelType"
      class="mb-3"
      @update:value="$emit('update:labelType', $event)"
    />

    <WorkPlaceFilter
      v-if="labelType === 'work_place'"
      v-model:selected-carriers="selectedCarriers"
      v-model:selected-prefectures="selectedPrefectures"
      :carriers="carriers"
      :prefectures="prefectures"
      @update:selected-carriers="searchWorkPlace({ carriers: $event })"
      @update:selected-prefectures="searchWorkPlace({ prefectures: $event })"
    />

    <FormItemSelector
      v-if="needDeps(labelType)"
      :value="labelType"
      :label="dependancyTitle"
      form-type="object"
      :errors="errors"
      :items="dependancies"
      :show-fn="(dep) => dep.name"
      form-id="dependancyId"
      class="mb-3"
      without-select-text="選択してください"
      no-auto-select
      can-without-select
      @update:value="$emit('update:dependancyId', $event?.id)"
    />

    <FormItemWrapper
      v-if="labelType === 'department_owner'"
      label="グループ名"
      form-id="departmentName"
      class="mb-3"
      :errors="errors"
    >
      <AutoComplete
        :model-value="departmentName"
        :suggestions="suggestions"
        :class="{
          'p-invalid': errors.departmentName,
        }"
        :empty-search-message="`このまま送信するとグループ${departmentName}を新しく作成します`"
        dropdown
        @update:model-value="
          $emit('update:departmentName', $event as string | undefined)
        "
        @complete="onComplete"
      />
    </FormItemWrapper>

    <FormItem
      v-if="!['request_organization', 'work_place'].includes(labelType)"
      :value="name"
      :label="`ラベル名称(例：${sampleOfEachType})`"
      :errors="errors"
      form-id="name"
      form-type="text"
      class="mb-3"
      :disabled="
        labelType === 'request_organization' || labelType === 'work_place'
      "
      @update:value="$emit('update:name', $event as string | undefined)"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, computed, watch } from "vue";
import { ZodFormattedErrors } from "/@/modules/zodUtils";
import { labelTypeToStr, needDeps } from "/@/modules/managementLabel";
import {
  FormItem,
  FormItemSelector,
  FormItemWrapper,
} from "/@/vue/components/Molecules";
import { WorkPlaceFilter } from "/@/vue/components/Organisms";
import {
  LabelType,
  Carrier,
  Prefecture,
  carriers,
  labelTypes,
  prefectures,
} from "/@/types";
import AutoComplete from "primevue/autocomplete";

const props = defineProps<{
  name?: string;
  labelType: LabelType;
  departmentName?: string;
  dependancyId?: number;
  dependancies: any[];
  departmentNames: string[];
  errors: ZodFormattedErrors;
}>();

const emit = defineEmits<{
  (e: "update:name", name: string | undefined): void;
  (e: "update:departmentName", name: string | undefined): void;
  (e: "update:labelType", labelType: LabelType): void;
  (e: "update:dependancyId", dependancyId: number): void;
  (
    e: "searchWorkPlace",
    prms: { prefectures: Prefecture[]; carriers: Carrier[] }
  ): void;
}>();

const sampleOfEachType = computed(() => {
  switch (props.labelType) {
    case "request_organization":
      return "(株)○○○○";
    case "department_owner":
      return "管理者・メンバー";
    case "labor":
      return "アルバイト";
    case "role":
      return "平社員・役員";
    default:
      return "";
  }
});

const suggestions = ref<string[]>([]);

onMounted(() => {
  suggestions.value = props.departmentNames;
});

function onComplete(event: any) {
  suggestions.value = props.departmentNames.filter((name) =>
    name.includes(event.query)
  );
}

const dependancyTitle = computed(() => {
  switch (props.labelType) {
    case "request_organization":
      return "代理店";
    case "work_place":
      return "店舗";
    default:
      return "";
  }
});

const selectedDependancy = computed(() => {
  return props.dependancies.find((d) => d.id === props.dependancyId);
});

watch(selectedDependancy, (d) => {
  if (d) {
    emit("update:name", d.name);
  }
});

const selectedPrefectures = ref<Prefecture[]>([]);
const selectedCarriers = ref<Carrier[]>([]);

function searchWorkPlace({
  carriers,
  prefectures,
}: {
  carriers: Carrier[];
  prefectures: Prefecture[];
}) {
  if (carriers) {
    selectedCarriers.value = carriers;
  }

  if (prefectures) {
    selectedPrefectures.value = prefectures;
  }

  emit("searchWorkPlace", {
    prefectures: selectedPrefectures.value,
    carriers: selectedCarriers.value,
  });
}
</script>

<style scoped lang="scss"></style>
