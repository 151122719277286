import useSWRV from "swrv";
import { axios, fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import {
  DateMap,
  HideMapConfig,
  HideMapConfigForm,
  Organization,
  WorkRecordEntryReportForm,
  WorkReport,
} from "/@/types";
import { ComputedRef, Ref } from "vue";
import { createDateParams } from "/@/modules/url";

export function useWorkReport() {
  function getWorkReportsKey(
    organization:
      | Ref<Organization | undefined>
      | ComputedRef<Organization | undefined>,
    selectedDate: Ref<DateMap | undefined> | undefined = undefined
  ) {
    if (!organization.value) {
      return null;
    }

    let apiUri = `/api/v1/organizations/${organization.value.id}/work_reports`;

    if (selectedDate && selectedDate.value) {
      const dateParams = createDateParams(selectedDate.value);
      apiUri += `?${dateParams}`;
    }

    return apiUri;
  }

  function getWorkReports(
    organization:
      | Ref<Organization | undefined>
      | ComputedRef<Organization | undefined>,
    selectedDate: Ref<DateMap | undefined> | undefined = undefined
  ) {
    return useSWRV<WorkReport[]>(
      () => getWorkReportsKey(organization, selectedDate),
      fetcher
    );
  }

  async function createWorkReport(
    form: WorkRecordEntryReportForm,
    memberId: number
  ) {
    try {
      await axios.post(
        `/api/v1/work_record_entry_reports?organization_member_id=${memberId}`,
        {
          workRecordEntryReports: form,
        }
      );
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function updateWorkReport(
    form: WorkRecordEntryReportForm,
    memberId: number,
    options: { skipRequest: boolean } = { skipRequest: false }
  ) {
    if (!form.id) {
      throw new Error("unexpected update work report: without id");
    }

    const { skipRequest } = options;
    let uri = `/api/v1/work_record_entry_reports/${form.id}?organization_member_id=${memberId}`;

    if (skipRequest) {
      uri += "&skip_request=true";
    }

    try {
      await axios.patch(uri, {
        workRecordEntryReports: form,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  function getHideMapConfigsKey(
    organization:
      | Ref<Organization | undefined>
      | ComputedRef<Organization | undefined>
  ) {
    if (!organization.value) {
      return null;
    }

    return `/api/v1/hide_map_configs?organization_id=${organization.value.id}`;
  }

  function getHideMapConfigs(
    organization:
      | Ref<Organization | undefined>
      | ComputedRef<Organization | undefined>
  ) {
    return useSWRV<HideMapConfig[]>(
      () => getHideMapConfigsKey(organization),
      fetcher
    );
  }

  async function createHideMapConfig(
    prms: HideMapConfigForm,
    organizationId: number
  ) {
    try {
      await axios.post(
        `/api/v1/hide_map_configs?organization_id=${organizationId}`,
        {
          hideMapConfigs: prms,
        }
      );
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function destroyHideMapConfig(
    config: HideMapConfig,
    organizationId: number
  ) {
    try {
      await axios.delete(
        `/api/v1/hide_map_configs/${config.id}?organization_id=${organizationId}`
      );
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    getWorkReports,
    updateWorkReport,
    createWorkReport,
    getHideMapConfigs,
    createHideMapConfig,
    destroyHideMapConfig,
  };
}
