<template>
  <tr :key="`organization-member-${member.id}`">
    <td class="text-nowrap text-center">
      {{ member.user.name }}
    </td>
    <td
      v-for="prop in userAttributes"
      :key="`om-${member.id}-${prop}`"
      class="text-nowrap text-center"
    >
      {{ member.user[prop] }}
    </td>
    <td v-if="!selectedGroup" class="text-nowrap text-center">
      {{ member.roles.map((r) => translater(r)).join(",") }}
    </td>
    <td v-else class="text-nowrap text-center">
      {{ selectedGroupMemberInfo }}
    </td>
    <td v-for="option in memberNeedOptions" :key="option" class="text-center">
      <CheckBlueIcon v-if="member[option]" class="icon" />
    </td>
    <td class="text-center text-nowrap">
      <span v-if="member.submitLocationTime">{{
        basicFormatter(member.submitLocationTime, "onlyTime")
      }}</span>
    </td>
  </tr>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { translater } from "/@/modules/string";
import { basicFormatter } from "/@/modules/luxon";
import { CheckBlueIcon } from "/@/vue/components/Atom";
import {
  OrganizationMember,
  OrganizationOwnerGroup,
  UserAttributes,
  OrganizationMemberNeedOptions,
} from "/@/types";

const props = defineProps<{
  member: OrganizationMember;
  selectedGroup?: OrganizationOwnerGroup;
}>();

const userAttributes: UserAttributes[] = ["email"];
const memberNeedOptions: OrganizationMemberNeedOptions[] = [
  "needEnterLeaveReport",
  "needFinishReport",
];

const selectedGroupMemberInfo = computed(() => {
  const selectedGroup = props.selectedGroup;

  if (!selectedGroup) {
    return;
  }

  const memberOwnerGroup = props.member.organizationOwnerGroups?.find(
    (og) => og.groupId === selectedGroup.id
  );

  return memberOwnerGroup ? translater(memberOwnerGroup.role) : "";
});
</script>

<style scoped></style>
