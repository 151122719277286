import html2pdf from "html2pdf.js";

export function generatePDF(
  targetId: string,
  filename: string,
  jsPdfOption: any = {
    unit: "in",
    format: "a4",
    orientation: "portrait",
    precision: 0,
  }
) {
  const element = document.getElementById(targetId);

  const opt = {
    margin: [0.2, 0.2, 0.2, 0.2],
    filename: filename,
    jsPDF: jsPdfOption,
  };

  html2pdf(element, opt);
}
