import { ComputedRef, Ref } from "vue";
import { axios, fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import {
  MeetingTargetWorkPlace,
  MeetingTargetWorkPlaceClient,
  MeetingTargetAddedFormItemClient,
} from "/@/types/meetingTargets";
import { Carrier, DateMap, Organization, Prefecture } from "/@/types";
import useSWRV from "swrv";
import { Computed } from "vuex";
import { createDateParams } from "/@/modules/url";

export function useMeetingTargetWorkPlaces() {
  function getMeetingTargetWorkPlaces(
    organization:
      | Ref<Organization | undefined>
      | ComputedRef<Organization | undefined>,
    options: {
      date?: Ref<DateMap>;
      carrier?: Ref<Carrier>;
      prefectures?: Ref<Prefecture[]>;
    }
  ) {
    function getMeetingTargetWorkPlacesKey() {
      if (!organization.value) {
        return null;
      }

      let uri = `/api/v1/organizations/${organization.value.id}/meeting_target_work_places`;

      if (options.date?.value) {
        const dateParams = createDateParams(options.date.value);
        uri += `?${dateParams}`;
      }

      if (options.carrier?.value) {
        const carrier = options.carrier.value;
        uri += `&carriers=${carrier}`;
      }

      if (options.prefectures?.value) {
        const prefectures = options.prefectures.value;
        uri += `&prefectures=${prefectures.join(",")}`;
      }

      return uri;
    }

    return useSWRV<MeetingTargetWorkPlaceClient[]>(
      () => getMeetingTargetWorkPlacesKey(),
      fetcher
    );
  }

  async function createMeetingTargetWorkPlaces(form: MeetingTargetWorkPlace) {
    try {
      await axios.post(
        `/api/v1/organizations/${form.organizationId}/meeting_target_work_places`,
        {
          meetingTargetWorkPlaces: form,
        }
      );
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function updateMeetingTargetWorkPlace(form: MeetingTargetWorkPlace) {
    try {
      await axios.patch(`/api/v1/meeting_target_work_places/${form.id}`, {
        meetingTargetWorkPlaces: form,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function destroyMeetingTargetWorkPlaces(id: number) {
    try {
      await axios.delete(`/api/v1/meeting_target_work_places/${id}`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  // added form item

  function getMeetingTargetAddedFormItemsKey(
    organization:
      | Ref<Organization | undefined>
      | ComputedRef<Organization | undefined>
  ) {
    if (!organization.value) {
      return null;
    }

    return `/api/v1/organizations/${organization.value.id}/meeting_target_added_form_items`;
  }

  function getMeetingTargetAddedFormItems(
    organization:
      | Ref<Organization | undefined>
      | ComputedRef<Organization | undefined>
  ) {
    return useSWRV<MeetingTargetAddedFormItemClient[]>(
      () => getMeetingTargetAddedFormItemsKey(organization),
      fetcher
    );
  }

  async function createMeetingTargetAddedFormItem(
    organization: Organization,
    prms: { name: string }
  ) {
    try {
      await axios.post(
        `/api/v1/organizations/${organization.id}/meeting_target_added_form_items`,
        {
          meetingTargetAddedFormItems: prms,
        }
      );
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function destroyMeetingTargetAddedFormItem(id: number) {
    try {
      await axios.delete(`/api/v1/meeting_target_added_form_items/${id}`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    getMeetingTargetWorkPlaces,
    createMeetingTargetWorkPlaces,
    updateMeetingTargetWorkPlace,
    destroyMeetingTargetWorkPlaces,

    getMeetingTargetAddedFormItems,
    createMeetingTargetAddedFormItem,
    destroyMeetingTargetAddedFormItem,
  };
}
