import zod from "zod";
import { DateScheme, DateTimeScheme, z } from "/@/modules/zodUtils";
import { DateTime } from "luxon";

const DateMapScheme = z.object({
  year: z.number(),
  month: z.number(),
  day: DateTimeScheme.optional(),
});

export type DateMap = zod.infer<typeof DateMapScheme>;

const DateMapFormScheme = DateMapScheme.partial();

export type DateMapForm = zod.infer<typeof DateMapFormScheme>;

// for calendar

export const DateForCalendarScheme = z.object({
  dateKey: DateScheme,
  luxonDate: z.custom((value) => {
    if (value instanceof DateTime) {
      return value;
    }
    throw new Error("Invalid luxon date object");
  }),
  weekday: z.number().min(1).max(7),
});

export type DateForCalendar = {
  dateKey: string;
  luxonDate: DateTime;
  weekday: number;
};
