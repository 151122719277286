import { useRouter } from "vue-router";
import { useStore } from "/@/vue/store";
import { clearStorage } from "/@/modules/localStorage";
import { User, SingUpResponse, Admin } from "/@/types";
import { computed } from "vue";
import { errorHandle } from "/@/modules/error";

export function useAuthorize() {
  const store = useStore();
  const router = useRouter();

  const user = computed(() => {
    return store.state.user.user;
  });

  const admin = computed(() => {
    return store.state.admin.admin;
  });

  async function login(user: User) {
    clearStorage();

    return await store.dispatch("user/LOGIN", user);
  }

  async function adminLogin(admin: Admin) {
    clearStorage();
    return await store.dispatch("admin/LOGIN", admin);
  }

  async function adminProxyLogin(memberId: number) {
    clearStorage();
    return await store.dispatch("admin/PROXY_LOGIN", memberId);
  }

  async function logout(
    options: { withoutConfirm: boolean; withoutRedirect: boolean } = {
      withoutConfirm: false,
      withoutRedirect: false,
    }
  ) {
    const { withoutConfirm, withoutRedirect } = options;

    if (!withoutConfirm && !window.confirm("ログアウトしますか？")) {
      return;
    }

    clearStorage();

    await store.dispatch("user/LOGOUT");

    if (!withoutRedirect) {
      router.push({ name: "Index" });
    }
  }

  async function adminLogout() {
    if (!window.confirm("ログアウトしますか？")) {
      return;
    }

    clearStorage();

    try {
      await store.dispatch("admin/LOGOUT");
      router.push({ name: "Index" });
    } catch (e) {
      errorHandle(e);
    }
  }

  async function signup(user: User): Promise<SingUpResponse> {
    return await store.dispatch("user/SIGN_UP", user);
  }

  return {
    user,
    login,
    logout,
    signup,

    admin,
    adminLogin,
    adminLogout,
    adminProxyLogin,
  };
}
