import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { PhotoDataObjectScheme } from "/@/types/axios.d";

export const AntibodyTestScheme = z.object({
  photo: PhotoDataObjectScheme,
});

export type AntibodyTest = zod.infer<typeof AntibodyTestScheme>;

export const AntibodyTestFormScheme = AntibodyTestScheme.partial();

export type AntibodyTestForm = zod.infer<typeof AntibodyTestFormScheme>;
