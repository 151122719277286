<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 15 21"
  >
    <defs>
      <filter
        id="長方形_745"
        x="8.874"
        y="0"
        width="6.43"
        height="5.898"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="長方形_746"
        x="2.624"
        y="3.031"
        width="10.93"
        height="13.692"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur-2" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-2" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="前面オブジェクトで型抜き_8"
        x="0"
        y="13.857"
        width="7.301"
        height="7.408"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur-3" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-3" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="pen_icon_blue" transform="translate(0.302)">
      <g
        id="グループ_807"
        data-name="グループ 807"
        transform="translate(-1083.727 -2734.965) rotate(30)"
      >
        <g
          transform="matrix(0.87, -0.5, 0.5, 0.87, 2305.76, 1826.84)"
          filter="url(#長方形_745)"
        >
          <path
            id="長方形_745-2"
            data-name="長方形 745"
            d="M1,0H4A1,1,0,0,1,5,1V3A0,0,0,0,1,5,3H0A0,0,0,0,1,0,3V1A1,1,0,0,1,1,0Z"
            transform="translate(10.67 0) rotate(30)"
            fill="#4285f4"
          />
        </g>
        <g
          transform="matrix(0.87, -0.5, 0.5, 0.87, 2305.76, 1826.84)"
          filter="url(#長方形_746)"
        >
          <rect
            id="長方形_746-2"
            data-name="長方形 746"
            width="5"
            height="12"
            transform="translate(8.92 3.03) rotate(30)"
            fill="#4285f4"
          />
        </g>
        <g
          transform="matrix(0.87, -0.5, 0.5, 0.87, 2305.76, 1826.84)"
          filter="url(#前面オブジェクトで型抜き_8)"
        >
          <path
            id="前面オブジェクトで型抜き_8-2"
            data-name="前面オブジェクトで型抜き 8"
            d="M21953.076-1699.254a.985.985,0,0,1-.937-.645l-1.564-4.1h5l-1.562,4.1A.978.978,0,0,1,21953.076-1699.254Z"
            transform="translate(-19859.08 -9485.73) rotate(30)"
            fill="#4285f4"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
