<template>
  <div>
    <div class="d-flex align-items-center w-100 mb-3">
      <ContentLabel
        :label="workTaskTypes.map((t) => translater(t)).join('・')"
        without-padding
        class="me-2"
      />
      <BasicButton
        small
        rounded
        icon="pi pi-plus"
        :disabled="existsEmptyForm"
        @click="addWorkTask"
      />
    </div>

    <WorkTaskFormItem
      v-for="(task, idx) of workTasksWithIdx"
      :key="`work-task-form-item-${task.idx}`"
      :work-task="task"
      :task-name="workTaskNames[idx]"
      :entry="entry"
      :basis-report-items="basisReportItems"
      :errors="{}"
      :is-valid="!isEmptyForm(task)"
      class="mb-2"
      @update-work-task="updateWorkTask"
      @destroy-work-task="destroyWorkTask"
    />

    <div class="mb-3">
      <BasicButton
        v-if="workTasksWithIdx.length > 0"
        small
        rounded
        icon="pi pi-plus"
        :disabled="existsEmptyForm"
        @click="addWorkTask"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { numberToCircle, translater } from "/@/modules/string";
import { emptyReport } from "/@/modules/workReport";
import { BasicButton, ContentLabel } from "/@/vue/components/Atom";
import {
  BasisReportItemClient,
  workTaskTypes,
  WorkTaskForm,
  WorkTaskWithIdxForm,
  WorkTaskKey,
  WorkRecordEntryClient,
} from "/@/types";
import WorkTaskFormItem from "./WorkTaskFormItem.vue";
import { cloneDeep } from "lodash";
import { removeNthElement } from "/@/modules/array";

const props = defineProps<{
  workTasks: WorkTaskForm[];
  entry: WorkRecordEntryClient;
  basisReportItems: BasisReportItemClient[];
}>();

const emit = defineEmits<{
  (e: "update:workTasks", workTasks: WorkTaskForm[]): void;
}>();

const workTasksWithIdx = computed<WorkTaskWithIdxForm[]>(() => {
  return props.workTasks.map((t, idx) => {
    let newm: WorkTaskWithIdxForm = {
      ...t,
      idx: idx,
    };

    return newm;
  });
});

const workTaskNames = computed(() => {
  const reservations = workTasksWithIdx.value.filter(
    (t) => t.workTaskType === "reservation"
  );

  const callings = workTasksWithIdx.value.filter(
    (t) => t.workTaskType === "calling"
  );

  return workTasksWithIdx.value.map((t) => {
    const typeIdx =
      t.workTaskType === "reservation"
        ? reservations.findIndex((r) => r.idx === t.idx)
        : callings.findIndex((c) => c.idx === t.idx);
    const circleNumber = numberToCircle(typeIdx + 1);

    return `${translater(t.workTaskType)}${circleNumber}`;
  });
});

function isEmptyForm(workTask: WorkTaskForm) {
  return !workTask.actionAt || emptyReport(workTask.reportItems);
}

const existsEmptyForm = computed(() => {
  return props.workTasks.some((wt) => isEmptyForm(wt));
});

function addWorkTask() {
  if (existsEmptyForm.value) return;

  const newTask = {
    workTaskType: workTaskTypes[0],
    actionAt: undefined,
    phoneNumber: undefined,
    customerName: undefined,
    remarks: undefined,
    reportItems: [],
  };

  emit("update:workTasks", [...props.workTasks, newTask]);
}

function updateWorkTask(prms: { key: WorkTaskKey; value: any; idx: number }) {
  const { key, value, idx } = prms;

  const newTasks = cloneDeep(props.workTasks);
  const target = newTasks.at(idx);

  if (!target) {
    return;
  }

  if (key === "workTaskType" && workTaskTypes.includes(value)) {
    target[key] = value;
  } else if (key === "reportItems" && Array.isArray(value)) {
    target[key] = value;
  } else if (key === "phoneNumber" && typeof value === "string") {
    target[key] = value.slice(0, 4);
  } else if (key === "countInContractedDate" && typeof value === "boolean") {
    target[key] = value;
  } else if (
    key !== "reportItems" &&
    key !== "workTaskType" &&
    key !== "id" &&
    typeof value === "string"
  ) {
    target[key] = value;
  } else {
    throw new Error(
      `unexpected pattern in handleUpdate: key - ${key}, value - ${value}`
    );
  }

  emit("update:workTasks", newTasks);
}

function destroyWorkTask(idx: number) {
  emit("update:workTasks", removeNthElement(props.workTasks, idx));
}
</script>

<style scoped></style>
