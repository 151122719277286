import zod, { string } from "zod";
import { DateScheme, DateTimeScheme, z } from "/@/modules/zodUtils";
import {
  ReportItemScheme,
  WorkReportClientScheme,
} from "/@/types/workReport.d";
import { WorkCategoriesScheme } from "/@/types/workCategories.d";
import { CarriersScheme } from "/@/types/carriers.d";
import { OrganizationCategoryScheme } from "/@/types/category.d";
import { WorkRecordentryReportClientScheme } from "/@/types/workRecord.d";

export const meetingTargetTypes = ["work", "workPlace"] as const;
export const MeetingTargetType = z.enum(meetingTargetTypes);
export type MeetingTargetType = zod.infer<typeof MeetingTargetType>;

export const MeetingTargetBaseScheme = z.object({
  organizationMemberId: z.number(),
  remarks: z.string().optional(),
  reportItems: ReportItemScheme.array(),
});

export type MeetingTarget = zod.infer<typeof MeetingTargetBaseScheme>;

export const MeetingTargetFormScheme = MeetingTargetBaseScheme.pick({
  organizationMemberId: true,
  remarks: true,
  reportItems: true,
  workScheduleId: true,
}).extend({
  id: z.number().optional(),
});
export type MeetingTargetForm = zod.infer<typeof MeetingTargetFormScheme>;

export const MeetingTargetSubmitScheme = MeetingTargetFormScheme.pick({
  organizationMemberId: true,
  id: true,
  remarks: true,
  reportItems: true,
});
export type MeetingTargetSubmit = zod.infer<typeof MeetingTargetSubmitScheme>;

export const MeetingTargetClientScheme = MeetingTargetBaseScheme.extend({
  id: z.number(),
  organizationMemberId: z.number(),
  organizationMemberName: z.string(),
  workId: z.number(),
  workRecordEntryId: z.number(),
  workScheduleId: z.number(),
  rangeOfTargetDate: DateScheme.array(),
  currentStatus: ReportItemScheme.array(),
  workCategory: WorkCategoriesScheme,
  workCarrier: CarriersScheme,
  workTypeCategories: OrganizationCategoryScheme.array(),
  workRecordEntryReports: WorkReportClientScheme.array(),
});
export type MeetingTargetClient = zod.infer<typeof MeetingTargetClientScheme>;

// work place

export const MeetingTargetReportItemScheme = ReportItemScheme.extend({
  currentCount: z.number(),
  currentOrganizationCount: z.number(),
  targetParcent: z.number(),
  basisReportItemId: z.number().optional(),
  summarizeTarget: z.boolean(),
  summarizeCurrent: z.boolean(),
});

export type MeetingTargetReportItem = zod.infer<
  typeof MeetingTargetReportItemScheme
>;

export const MeetingTargetWorkPlaceScheme = MeetingTargetBaseScheme.extend({
  id: z.number().optional(),
  organizationId: z.number(),
  workPlaceId: z.number(),
  targetDate: DateScheme,
  remarks: z.string().optional(),
  reportItems: MeetingTargetReportItemScheme.array(),
});

export type MeetingTargetWorkPlace = zod.infer<
  typeof MeetingTargetWorkPlaceScheme
>;

export const MeetingTargetWorkPlaceFormScheme =
  MeetingTargetWorkPlaceScheme.partial();
export type MeetingTargetWorkPlaceForm = zod.infer<
  typeof MeetingTargetWorkPlaceFormScheme
>;

export const MeetingTargetWorkPlaceClientScheme =
  MeetingTargetWorkPlaceScheme.extend({
    id: z.number(),
    organizationName: z.string(),
    workPlaceName: z.string(),
    workPlaceShortName: z.string().optional(),
    workPlaceCarrier: CarriersScheme,
    organizationMemberId: z.number(),
    organizationMemberName: z.string(),
    currentStatus: ReportItemScheme.array(),
    currentStatusOverall: ReportItemScheme.array(),
    workRecordEntryReports: WorkRecordentryReportClientScheme.array(),
    createdAt: DateTimeScheme,
    updatedAt: DateTimeScheme,
  });
export type MeetingTargetWorkPlaceClient = zod.infer<
  typeof MeetingTargetWorkPlaceClientScheme
>;

// added

export const MeetingTargetAddedFormItemScheme = z.object({
  name: z.string(),
});

export const MeetingTargetAddedFormItemClientScheme =
  MeetingTargetAddedFormItemScheme.extend({
    id: z.number(),
  });

export type MeetingTargetAddedFormItemClient = zod.infer<
  typeof MeetingTargetAddedFormItemClientScheme
>;
