<template>
  <div>
    <SimpleModal
      ref="modal"
      title="目標の更新"
      submit-text="送信"
      @cancel="closeModal"
      @submit="submit"
    >
      <template v-if="workRecordEntryReport">
        <WorkReportForm
          v-model:report-items="form.reportItems"
          :report="workRecordEntryReport"
          :report-type="workRecordEntryReport.reportType"
          :work-type-categories="workRecordEntryReport.workTypeCategories"
          :work-carrier="workRecordEntryReport.workCarrier"
          :work-category="workRecordEntryReport.workCategory"
          :request-organization-id="workRecordEntryReport.requestOrganizationId"
          :basis-report-items="props.basisReportItems"
        />
      </template>
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useZodScheme } from "/@/vue/composables";
import { SimpleModal } from "/@/vue/components/Molecules";
import { WorkReportForm } from "/@/vue/components/Organisms";
import {
  BasisReportItemClient,
  WorkRecordEntryReportForm,
  WorkReportClient,
  WorkRecordEntryReportScheme,
} from "/@/types";
import { errorHandle } from "/@/modules/error";
import { watch } from "vue";

const props = defineProps<{
  workRecordEntryReport?: WorkReportClient;
  basisReportItems: BasisReportItemClient[];
}>();

const emit = defineEmits<{
  (e: "submit", report: WorkRecordEntryReportForm): void;
}>();

const { useFormAndErrors } = useZodScheme();
const { form, startValidation } = useFormAndErrors<WorkRecordEntryReportForm>(
  WorkRecordEntryReportScheme
);

function initForm(report?: WorkReportClient) {
  if (report) {
    form.reportType = report.reportType;
    form.reportItems = report.reportItems;
    form.targetDate = report.targetDate;
    form.id = report.id;
  }
}

onMounted(() => {
  initForm(props.workRecordEntryReport);
});

watch(
  () => props.workRecordEntryReport,
  (report) => {
    if (report) {
      initForm(report);
    }
  }
);

const loading = ref<boolean>(false);

async function submit() {
  try {
    startValidation.value = true;
    loading.value = true;

    const workReport = WorkRecordEntryReportScheme.parse(form);
    emit("submit", workReport);
  } catch (e) {
    errorHandle(e);
  }

  loading.value = false;
}

const modal = ref();

function openModal() {
  modal.value?.openModal();
}

function closeModal() {
  modal.value?.closeModal();
}

defineExpose({
  openModal,
  closeModal,
});
</script>

<style scoped></style>
