import useSWRV from "swrv";
import { Ref } from "vue";
import { createQueryParams } from "/@/modules/url";
import { fetcher } from "/@/modules/axios";
import type { Organization } from "/@/types";

export function useWorkTypes() {
  function getWorkTypesKey(
    organization: Ref<Organization | undefined>,
    isHomeworker: Ref<boolean | undefined> | undefined = undefined
  ) {
    if (!organization.value || !organization.value.categories) {
      return null;
    }

    const targetCategories = organization.value.categories.filter(
      (category) => {
        if (isHomeworker && isHomeworker.value) {
          return category === "general";
        } else {
          return true;
        }
      }
    );

    return `/api/v1/work_types?${createQueryParams({
      categories: targetCategories,
    })}`;
  }

  function getWorkTypes(
    organization: Ref<Organization | undefined>,
    isHomeworker: Ref<boolean | undefined> | undefined = undefined
  ) {
    return useSWRV(() => getWorkTypesKey(organization, isHomeworker), fetcher);
  }

  return {
    getWorkTypes,
  };
}
