<template>
  <ContentCard title="写真報告" :variant="variant">
    <div class="d-flex justify-content-end">
      <RectButton
        :disabled="!photoURL"
        @click="
          clearPhoto();
          openModal();
        "
      >
        <div class="me-2">
          <PhotoBlueIcon v-if="photoURL" class="icon icon-rect-btn" />
          <PhotoGrayIcon v-else class="icon icon-rect-btn" />
        </div>

        <div>撮り直す</div>
      </RectButton>
    </div>

    <div class="mt-4">
      <div
        v-if="!photoURL"
        :class="`w-100 drop-shadow take-photo-btn ${isValid ? '' : 'in-valid'}`"
        role="button"
        @click="
          openModal();
          photoInit();
        "
      >
        <PhotoWhiteIcon class="take-photo-icon" />
        撮影する
      </div>
      <div v-else class="d-flex justify-content-center w-100">
        <img
          :src="photoURL"
          class="card drop-shadow preview"
          height="300"
          width="400"
        />
      </div>
    </div>
  </ContentCard>

  <teleport to="body">
    <SimpleModal
      ref="photoModal"
      title="写真の撮影"
      :submit-text="photo ? '決定' : '撮影'"
      :submit-disabled="!videoStream"
      @submit="takePhotoAndClose()"
      @cancel="
        clearPhoto();
        closeModal();
      "
    >
      <div class="w-100 d-flex align-items-center justify-content-center">
        <video
          v-if="!photo"
          :id="`video${photoId}`"
          class="card"
          muted
          autoplay
          playsinline
          :style="{ 'max-height': '250px', 'object-fit': 'contain' }"
        ></video>
      </div>
      <canvas
        v-show="false"
        :id="`photoCanvas${photoId}`"
        class="card w-100"
      ></canvas>
    </SimpleModal>
  </teleport>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { v4 as uuidv4 } from "uuid";
import {
  getCameraStream,
  registerVideStream,
  getTrackSizeFromVideoStream,
  transcriptionPhotoFromVideoElementToCanvasElement,
} from "/@/modules/stream";
import { SimpleModal } from "/@/vue/components/Molecules";
import {
  ContentCard,
  RectButton,
  PhotoGrayIcon,
  PhotoBlueIcon,
  PhotoWhiteIcon,
} from "/@/vue/components/Atom";
import { PhotoDataObject } from "/@/types/axios";

defineProps<{
  photo?: PhotoDataObject;
  variant?: string;
  isValid?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:photo", photo: PhotoDataObject | undefined): void;
}>();

const photo = ref<Blob>();
const videoStream = ref<MediaStream>();
const videoElement = ref<HTMLVideoElement>();
const photoId = "a" + uuidv4();

async function photoInit() {
  const stream = await getCameraStream();

  if (!stream) {
    alert("カメラの起動に失敗しました。カメラの起動が必須です。");
    return;
  }

  videoStream.value = stream;

  const video = registerVideStream(stream, `video${photoId}`);

  videoElement.value = video;
}

const photoCanvas = ref<HTMLCanvasElement>();
const photoURL = ref();

async function takePhoto() {
  if (!videoElement.value || !videoStream.value) {
    alert("予期せぬエラーが発生しました。");
    return;
  }

  const canvas = document.getElementById(`photoCanvas${photoId}`);

  if (!(canvas instanceof HTMLCanvasElement)) {
    return;
  }

  const { width, height } = getTrackSizeFromVideoStream(videoStream.value);

  if (width && height) {
    const { data, blob } =
      await transcriptionPhotoFromVideoElementToCanvasElement(
        videoElement.value,
        canvas,
        width,
        height
      );

    emit("update:photo", { data });

    photo.value = blob;
    photoURL.value = data;
    photoCanvas.value = canvas;
  }
}

function takePhotoAndClose() {
  takePhoto();
  closeModal();
}

function clearPhoto() {
  photo.value = undefined;
  if (photoCanvas.value) {
    photoCanvas.value.innerHTML = "";
  }

  photoURL.value = undefined;
  photoInit();
  emit("update:photo", undefined);
}

// modal

const photoModal = ref();

async function openModal() {
  photoModal.value.openModal();
}

function closeModal() {
  photoModal.value.closeModal();

  if (videoStream.value?.getTracks()) {
    videoStream.value.getTracks().forEach((track) => track.stop());
  }
}
</script>

<style lang="scss" scoped>
.take-photo-btn {
  border-radius: 15px;
  background-color: #c2c2c2;
  height: 134px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.in-valid {
    border: 1px solid red;
    color: red;
  }
}

.take-photo-icon {
  height: 36px;
  width: 36px;
}
</style>
