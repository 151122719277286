<template>
  <div>
    <div class="mb-3">
      <ContentLabel label="日時" />
      <span>
        {{ eventRangeStr }}
      </span>
    </div>

    <div class="mb-3">
      <ContentLabel label="場所" />
      <div class="d-flex mb-2">
        <span class="me-2">{{ organizationEvent.placeName }}</span>
        <a :href="organizationEvent.placeGmapUrl" target="_blank">
          <i class="pi pi-map-marker"></i>
        </a>
      </div>
    </div>

    <div v-if="organizationEvent.remarks" class="mb-3">
      <ContentLabel label="備考" />
      <pre>{{ organizationEvent.remarks }}</pre>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { isSameDate, basicFormatter } from "/@/modules/luxon";
import { ContentLabel } from "/@/vue/components/Atom";
import { OrganizationEventClient, OrganizationEvent } from "/@/types";

const props = defineProps<{
  organizationEvent: OrganizationEventClient | OrganizationEvent;
}>();

const eventRangeStr = computed(() => {
  if (!props.organizationEvent) return "";

  const finishAtStr = props.organizationEvent.finishAt
    ? isSameDate(
        props.organizationEvent.startAt,
        props.organizationEvent.finishAt
      )
      ? basicFormatter(props.organizationEvent.finishAt, "onlyTime")
      : basicFormatter(props.organizationEvent.finishAt, "withTime")
    : "未定";

  return `${basicFormatter(
    props.organizationEvent.startAt,
    "withTime"
  )}~${finishAtStr}`;
});
</script>

<style scoped></style>
