<template>
  <div>
    <div v-if="meetingTargets" class="d-flex flex-column">
      <template v-if="!onlyShowRelatedTable">
        <div v-if="meetingTargets.reportItems" class="">
          <ContentLabel
            :label="`目標（${targetDateStr}）`"
            without-padding
            class="mb-2"
          />
          <div></div>

          <p v-if="meetingTargets.remarks" class="m-0">
            　備　考：{{ meetingTargets.remarks }}
          </p>
        </div>

        <div class="table-wrapper">
          <MeetingTargetViewerTable :meeting-target="meetingTargets" />
        </div>

        <div class="small mt-3 d-flex align-items-center">
          <span class="me-3">
            目標作成者：{{ meetingTargets.organizationMemberName }}
          </span>
          <div v-if="isOwner" class="d-flex">
            <BasicButton
              variant="success"
              middle
              rounded
              pi-pencil
              icon="pi pi-pencil"
              @click="openEditMeetingTargetFormModal"
            />
            <div class="mx-1"></div>
            <BasicButton
              variant="danger"
              rounded
              middle
              icon="pi pi-trash"
              button-type="submit"
              @click="$emit('destroyMeetingTargets', meetingTargets?.id)"
            />
          </div>
        </div>
      </template>

      <CollapseWrapper
        v-if="isOwner"
        :contents-id="`meeting-target-viewer-${meetingTargets.id}`"
        card-header
        class="card mt-3"
      >
        <template #header>
          <div class="d-flex align-items-center">
            <span class="me-2"> 関係する業績一覧 </span>
            <BasicButton
              v-if="isOwner && false"
              label="全体実績の追加"
              icon="pi pi-plus-circle"
              @click="
                $emit('createWorkReport', {
                  targetDate: meetingTargets.targetDate,
                  reportType: 'overall',
                  workPlaceId: meetingTargets.workPlaceId,
                })
              "
            />
          </div>
        </template>
        <template #contents>
          <div
            v-if="meetingTargets.workRecordEntryReports.length"
            class="records-wrapper"
          >
            <WorkReportUpdateForm
              v-for="report of meetingTargets.workRecordEntryReports"
              :key="`report-${report.id}`"
              :report="report"
              :basis-report-items="basisReportItems"
              class="card mb-3"
              :is-owner="isOwner"
              @update-work-report="$emit('updateWorkReport', $event)"
              @select-work-report="$emit('selectWorkReport', $event)"
            />
          </div>
          <span v-else>なし</span>
        </template>
      </CollapseWrapper>
    </div>
    <teleport to="body">
      <MeetingTargetWorkPlaceFormModal
        ref="editMeetingTargetFormModal"
        :meeting-target="meetingTargets"
        :meeting-target-filter="meetingTargetFilter"
        :work-places="[
          {
            id: meetingTargets?.workPlaceId,
            name: meetingTargets?.workPlaceName,
          },
        ]"
        :organization-member-id="organizationMemberId"
        :basis-report-items="basisReportItems"
        @submit="$emit('updateMeetingTargets', $event)"
      />
    </teleport>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { fromISO } from "/@/modules/luxon";
import { BasicButton, ContentLabel } from "/@/vue/components/Atom";
import { CollapseWrapper } from "/@/vue/components/Molecules";
import { WorkReportUpdateForm } from "/@/vue/components/Organisms";
import {
  BasisReportItemClient,
  MeetingTargetWorkPlaceClient,
  MeetingTargetForm,
  ReportItemFilter,
  WorkRecordEntryReportForm,
  WorkReportClient,
  Organization,
} from "/@/types";
import { MeetingTargetViewerTable, MeetingTargetWorkPlaceFormModal } from ".";
import { ref } from "vue";

const props = defineProps<{
  meetingTargets?: MeetingTargetWorkPlaceClient;
  basisReportItems: BasisReportItemClient[];
  meetingTargetFilter?: ReportItemFilter[];
  organization: Organization;
  onlyShowRelatedTable?: boolean;
  isOwner?: boolean;
}>();

defineEmits<{
  (e: "updateMeetingTargets", form: MeetingTargetForm): void;
  (e: "destroyMeetingTargets", id: number): void;
  (e: "updateWorkReport", workReport: WorkRecordEntryReportForm): void;
  (e: "selectWorkReport", workReport: WorkReportClient): void;
  (e: "createWorkReport", args: WorkRecordEntryReportForm): void;
}>();

const organizationMemberId = computed(() => {
  return props.organization.organizationMemberId;
});

function rangeOfTargetDateToStr(range: string[]) {
  if (range.length === 2) {
    return `${fromISO(range[0]).toFormat("yyyy年MM月dd日")}-${fromISO(
      range[1]
    ).toFormat("MM月dd日")}`;
  } else {
    return range.map((d) => fromISO(d).toFormat("yyyy年MM月dd日")).join(" - ");
  }
}

const targetDateStr = computed(() => {
  if (!props.meetingTargets) return "";

  return rangeOfTargetDateToStr(props.meetingTargets.rangeOfTargetDate);
});

// modal

const editMeetingTargetFormModal = ref();

function openEditMeetingTargetFormModal() {
  editMeetingTargetFormModal.value.openModal({
    meetingTarget: props.meetingTargets,
    organizationId: props.organization.id,
    organizationMemberId: props.organization.organizationMemberId,
    workPlaceId: props.meetingTargets?.workPlaceId,
  });
}
</script>

<style scoped>
.table-wrapper {
  overflow-x: scroll;
}

.records-wrapper {
  max-height: 500px;
  overflow-y: scroll;
}
</style>
