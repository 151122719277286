<template>
  <div>
    <span v-if="!organizations.length">読み込み中</span>
    <template v-else-if="isFRYTHOwner">
      <div class="mb-2"></div>
      <OrganizationSelector
        v-model:selected-organization-id="selectedOrganizationId"
        class="w-100 mb-3"
        :organizations="organizationsData || []"
      />

      <SelectDate
        v-if="existsDates.length"
        v-model:year="selectedDate.year"
        v-model:month="selectedDate.month"
        :exists-dates="existsDates"
        only-year-month
        disabled-auto-select
        class="mb-3"
      />

      <OrganizationEventTable
        :organization-events="organizationEvents || []"
        :organization-members="organizationMembers || []"
        :selected-date="selectedDate"
        @edit="openFormModal"
        @destroy="handleDestroyOrganizationEvent"
        @create="openFormModal"
      />

      <teleport to="body">
        <OrganizationEventFormModal
          v-if="selectedOrganization"
          ref="formModal"
          :organization-members="organizationMembers || []"
          :organization="selectedOrganization"
          @submit="handleSubmitOrganizationEvent"
        />
      </teleport>
    </template>
    <span v-else></span>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import {
  useOrganization,
  useOrganizationMember,
  useOrganizationEvents,
  useSelectDate,
} from "/@/vue/composables";
import { SelectDate } from "/@/vue/components/Atom";
import {
  OrganizationSelector,
  OrganizationEventTable,
  OrganizationEventFormModal,
} from "/@/vue/components/Organisms";
import { OrganizationEventForm } from "/@/types";

const {
  getOrganizations,
  organizations,
  selectedOrganizationId,
  selectedOrganization,
  isOwner,
  isFRYTHOwner,
} = useOrganization();
const { data: organizationsData } = getOrganizations();
watch(organizationsData, (os) => {
  organizations.value = os || [];
});

// date

const { selectedDate, existsDates } = useSelectDate();

// members

const { getOrganizationMembers } = useOrganizationMember();
const { data: organizationMembers } = getOrganizationMembers(
  selectedOrganization,
  isOwner
);

// events

const {
  getOrganizationEvents,
  createOrganizationEvent,
  updateOrganizationEvent,
  destroyOrganizationEvent,
} = useOrganizationEvents();
const { data: organizationEvents } =
  getOrganizationEvents(selectedOrganization);

async function handleSubmitOrganizationEvent(prms: OrganizationEventForm) {
  if (!selectedOrganization.value) {
    return;
  }

  const handler = prms.id ? updateOrganizationEvent : createOrganizationEvent;

  if (await handler(selectedOrganization.value, prms)) {
    alert(`${prms.id ? "更新" : "作成"}に成功しました`);
    closeFormModal();
  } else {
    alert("イベントの作成に失敗しました");
  }
}

async function handleDestroyOrganizationEvent(eventId: number) {
  if (!selectedOrganization.value) {
    return;
  }

  if (confirm("本当に削除しますか？")) {
    if (await destroyOrganizationEvent(eventId)) {
      alert("削除に成功しました");
    } else {
      alert("削除に失敗しました");
    }
  }
}

// modal

const formModal = ref();

const openFormModal = (eventId?: number) => {
  const organizationEvent = organizationEvents.value?.find(
    (e) => e.id === eventId
  );

  formModal.value.openModal(organizationEvent);
};

const closeFormModal = () => {
  formModal.value.closeModal();
};
</script>

<style scoped></style>
