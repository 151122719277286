<template>
  <!--
  <th>所定労働日数</th>
  <th>公休日数</th>
  <th>勤務予定日数</th>
  <th>休日勤務予定日数</th>
  <th>特別休暇日数</th>
  <th>取得予定有休日数</th>
  <th>有休残日数</th>
  -->
  <td class="labor-td cell-number-form">
    <NumberForm
      v-if="editMode"
      :value="formItem.prescribedWorkingDays || 0"
      :form-id="`labor-info-form-prescribedWorkingDays`"
      :items="laborInfoCounts"
      @update:value="
        updateFormItem({ key: 'prescribedWorkingDays', val: $event })
      "
    />
    <span v-else>{{ memberLaborInfo?.prescribedWorkingDays || "未設定" }}</span>
  </td>
  <td class="labor-td cell-number-form">
    <NumberForm
      v-if="editMode"
      :value="formItem.publicHolidays"
      :items="laborInfoCounts"
      :form-id="`labor-info-form-publicHolidays`"
      @update:value="updateFormItem({ key: 'publicHolidays', val: $event })"
    />
    <span v-else>{{ memberLaborInfo?.publicHolidays || "未設定" }}</span>
  </td>
  <td class="labor-td cell-number-form">
    <NumberForm
      v-if="editMode"
      :value="formItem.workScheduleDays"
      :items="laborInfoCounts"
      :form-id="`labor-info-form-workScheduleDays`"
      @update:value="updateFormItem({ key: 'workScheduleDays', val: $event })"
    />
    <span v-else>{{ memberLaborInfo?.workScheduleDays || "未設定" }}</span>
  </td>
  <td class="labor-td cell-number-form">
    <NumberForm
      v-if="editMode"
      :value="formItem.holidayScheduledDays"
      :items="laborInfoCounts"
      :form-id="`labor-info-form-holidayScheduledDays`"
      @update:value="
        updateFormItem({ key: 'holidayScheduledDays', val: $event })
      "
    />
    <span v-else>{{ memberLaborInfo?.holidayScheduledDays || "未設定" }}</span>
  </td>
  <td class="labor-td cell-number-form">
    <NumberForm
      v-if="editMode"
      :value="formItem.specialLeaveDays"
      :items="laborInfoCounts"
      :form-id="`labor-info-form-specialLeaveDays`"
      @update:value="updateFormItem({ key: 'specialLeaveDays', val: $event })"
    />
    <span v-else>{{ memberLaborInfo?.specialLeaveDays || "未設定" }}</span>
  </td>
  <td class="labor-td cell-number-form">
    <NumberForm
      v-if="editMode"
      :value="formItem.plannedPaidHolidays"
      :items="laborInfoCounts"
      :form-id="`labor-info-form-plannedPaidHolidays`"
      is-valid
      @update:value="
        updateFormItem({ key: 'plannedPaidHolidays', val: $event })
      "
    />
    <span v-else>{{ memberLaborInfo?.plannedPaidHolidays || "未設定" }}</span>
  </td>
  <td class="labor-td cell-number-form"></td>
</template>

<script setup lang="ts">
import _ from "lodash";
import { computed } from "vue";
import { NumberForm } from "/@/vue/components/Atom";
import { DateMap, LaborInfoForm, OrganizationMember } from "/@/types";
import { isSameMonth } from "/@/modules/luxon";

const props = defineProps<{
  member: OrganizationMember;
  selectedDate: DateMap;
  formItem: LaborInfoForm;
  daysInMonth?: number;
  editMode?: boolean;
}>();

const emit = defineEmits<{
  (
    e: "update:formItem",
    prms: { key: keyof LaborInfoForm; val: number | string | undefined }
  ): void;
}>();

const memberLaborInfo = computed(() => {
  if (!props.member.organizationMemberLabors) return;

  return props.member.organizationMemberLabors.find((labor) =>
    isSameMonth(props.selectedDate, labor.targetDate)
  );
});

function updateFormItem(prms: { key: keyof LaborInfoForm; val?: number }) {
  const { key, val } = prms;

  emit("update:formItem", { key, val });
}

const laborInfoCounts = computed(() => {
  const daysInMonth = props.daysInMonth || 31;

  return _.range(0, daysInMonth + 1);
});
</script>

<style scoped lang="scss">
.labor-td {
  width: 100px;
}

td.labor-td {
  text-align: center;
  vertical-align: middle;
}

.cell-number-form {
  min-width: 100px;
  max-width: 100px;
}
</style>
