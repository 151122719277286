import zod from "zod";
import { z } from "/@/modules/zodUtils";

export const organizationOwnerGroupRoles = [
  "group_owner",
  "group_member",
] as const;
export const OrganizationOwnerGroupRolesScheme = z.enum(
  organizationOwnerGroupRoles
);
export type OrganizationOwnerGroupRole = zod.infer<
  typeof OrganizationOwnerGroupRolesScheme
>;

export const OrganizationOwnerGroupScheme = z.object({
  organizationId: z.number().optional(),
  id: z.number(),
  name: z.string(),
});

export type OrganizationOwnerGroup = zod.infer<
  typeof OrganizationOwnerGroupScheme
>;
