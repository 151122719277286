import {
  ChangeableModel,
  EditRequestStatus,
  EditRequestOperationType,
} from "/@/types";

export function editRequestTranslater(
  targetWord: ChangeableModel | EditRequestStatus | EditRequestOperationType
) {
  switch (targetWord) {
    case "WorkRecordEntryReport":
      return "業務成績";
    case "accept":
      return "承認済";
    case "reject":
      return "拒否済";
    case "pending":
      return "承認待ち";
    case "destroy":
      return "削除依頼";
    case "update":
      return "更新依頼";
  }
}
