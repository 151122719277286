<template>
  <div>
    <div class="table-wrapper">
      <table
        class="table table-fixed table-bordered table-striped small table-work-schedules h-100"
      >
        <thead>
          <tr class="sticky">
            <th class="text-center date-th">日付</th>
            <EditorTableHeaderItem
              title="業務場所"
              with-clipboard
              @copy-to="
                $emit('copyTo', {
                  key: 'workPlaceId',
                  workCategory: notEventCategories,
                })
              "
            />
            <EditorTableHeaderItem
              title="業務種別"
              with-clipboard
              @copy-to="
                $emit('copyTo', {
                  key: 'workTypeId',
                  workCategory: notEventCategories,
                })
              "
            />
            <EditorTableHeaderItem
              title="必要人数"
              with-clipboard
              @copy-to="
                $emit('copyTo', {
                  key: 'needCount',
                  workCategory: notEventCategories,
                })
              "
            />
            <EditorTableHeaderItem title="割当済人員" />
            <EditorTableHeaderItem
              title="集合時間"
              with-clipboard
              @copy-to="
                $emit('copyTo', {
                  key: 'meetingAt',
                  workCategory: notEventCategories,
                })
              "
            />
            <EditorTableHeaderItem
              title="開始時間"
              with-clipboard
              @copy-to="
                $emit('copyTo', {
                  key: 'startAt',
                  workCategory: notEventCategories,
                })
              "
            />
            <EditorTableHeaderItem
              title="終了時間"
              @copy-to="
                $emit('copyTo', {
                  key: 'finishAt',
                  workCategory: notEventCategories,
                })
              "
            />
            <EditorTableHeaderItem
              v-if="!hideSubmitConfigs"
              title="体温の提出"
              with-clipboard
              @copy-to="
                $emit('copyTo', {
                  key: 'needBodyTemperature',
                  workCategory: notEventCategories,
                })
              "
            />
            <EditorTableHeaderItem
              v-if="!hideAntibody"
              title="抗原検査の提出 (0は不要)"
              with-clipboard
              @copy-to="
                $emit('copyTo', {
                  key: 'needBodyTemperature',
                  workCategory: notEventCategories,
                })
              "
            />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="ws of workSchedules"
            :key="`work-schedule-${ws.targetDate}`"
          >
            <td>
              <div class="d-flex align-items-center">
                <BasicButton
                  variant="primary"
                  auto
                  class="me-2"
                  icon="pi pi-plus"
                  @click="$emit('addSchedule', ws.idx)"
                />
                <BasicButton
                  variant="danger"
                  auto
                  class="me-2"
                  icon="pi pi-trash"
                  @click="deleteSchedule(ws)"
                />

                <span class="text-nowrap">
                  {{ basicFormatter(ws.targetDate, "onlyDateDay") }}
                </span>
              </div>
            </td>
            <td>
              <Dropdown
                :model-value="ws.workPlaceId"
                :options="workPlaces"
                option-label="name"
                option-value="id"
                placeholder="業務店舗を選択"
                :form-id="`work-place-selector-${ws.idx}`"
                class="w-100"
                :class="{
                  'p-invalid': !ws.workPlaceId && ws.workPlaceId !== 0,
                }"
                filter
                auto-filter-focus
                empty-filter-message="候補が見つかりませんでした"
                @update:model-value="
                  updateSchedule('workPlaceId', $event, ws.idx)
                "
              />
            </td>
            <td class="td-work-type">
              <ObjectSelector
                :value="ws.workTypeId"
                :items="workTypes"
                value-key="id"
                show-key="name"
                :is-valid="!!ws.workTypeId"
                no-auto-select
                :form-id="`work-type-selector-${ws.idx}`"
                @update:value="updateSchedule('workTypeId', $event?.id, ws.idx)"
              />
            </td>
            <td class="td-need-count">
              <InputForm
                :value="ws.needCount"
                form-type="number"
                :min="0"
                :is-valid="!!ws.needCount || ws.needCount === 0"
                @update:value="
                  updateSchedule('needCount', parseInt($event), ws.idx)
                "
              />
            </td>
            <td>
              <div
                class="h-100 d-flex align-items-center justify-content-center"
              >
                {{ assignedMemberNames(ws) }}
              </div>
            </td>
            <td class="time-form">
              <TimeForm
                :value="ws.meetingAt"
                :is-valid="!!ws.startAt"
                teleport-center
                :start-time="{ hours: 9, minutes: 50 }"
                @update:value="
                  $emit('updateTime', {
                    key: 'meetingAt',
                    at: $event,
                    idx: ws.idx,
                  })
                "
                @cleared="
                  $emit('updateTime', {
                    key: 'meetingAt',
                    at: undefined,
                    idx: ws.idx,
                  })
                "
              />
            </td>
            <td class="time-form">
              <TimeForm
                :value="ws.startAt"
                :is-valid="!!ws.startAt"
                :start-time="{ hours: 10, minutes: 0 }"
                teleport-center
                @update:value="
                  $emit('updateTime', {
                    key: 'startAt',
                    at: $event,
                    idx: ws.idx,
                  })
                "
                @cleared="
                  $emit('updateTime', {
                    key: 'startAt',
                    at: undefined,
                    idx: ws.idx,
                  })
                "
              />
            </td>
            <td class="time-form">
              <TimeForm
                :value="ws.finishAt"
                :is-valid="!!ws.finishAt"
                :min-time="ws.startAt"
                :start-time="atToTimeObj(ws.startAt)"
                teleport-center
                @update:value="
                  $emit('updateTime', {
                    key: 'finishAt',
                    at: $event,
                    idx: ws.idx,
                  })
                "
                @cleared="
                  $emit('updateTime', {
                    key: 'finishAt',
                    at: undefined,
                    idx: ws.idx,
                  })
                "
              />
            </td>
            <td v-if="!hideSubmitConfigs">
              <CheckForm
                label=""
                :value="ws.needBodyTemperature"
                :unchecked-value="false"
                :form-id="`need-body-tempreture-${ws.idx}`"
                is-valid
                @update:value="
                  updateSchedule('needBodyTemperature', $event, ws.idx)
                "
              />
            </td>
            <td v-if="!hideAntibody">
              <InputForm
                :value="ws.needAntibodyTestWithin"
                :form-id="`need-antibody-test-${ws.idx}`"
                is-valid
                @update:value="
                  updateSchedule(
                    'needAntibodyTestWithin',
                    $event || $event === '0' ? parseInt($event) : 0,
                    ws.idx
                  )
                "
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import Dropdown from "primevue/dropdown";
import { atToTimeObj } from "/@/modules/datepicker";
import { basicFormatter } from "/@/modules/luxon";
import {
  TimeForm,
  ObjectSelector,
  BasicButton,
  CheckForm,
  InputForm,
} from "/@/vue/components/Atom";
import { EditorTableHeaderItem } from "/@/vue/components/Organisms";
import {
  WorkPlace,
  WorkScheduleForm,
  WorkScheduleWithAdditionalInfo,
  WorkTypeClient,
  WorkCategory,
  workCategories,
  OrganizationMember,
} from "/@/types";

defineProps<{
  workSchedules: WorkScheduleWithAdditionalInfo[];
  workPlaces: WorkPlace[];
  workTypes: WorkTypeClient[];
  organizationMembers: OrganizationMember[];
}>();

const hideSubmitConfigs = false;
const hideAntibody = true;

const notEventCategories = workCategories.filter(
  (category) => category !== "event_helper"
);

const emit = defineEmits<{
  (
    e: "copyTo",
    params: { key: keyof WorkScheduleForm; workCategory: WorkCategory[] }
  ): void;
  (e: "deleteSchedule", idx: number): void;
  (e: "addSchedule", idx: number): void;
  (
    e: "updateTime",
    params: { key: "startAt" | "finishAt"; at: string | undefined; idx: number }
  ): void;
  (
    e: "updateSchedule",
    params: { key: keyof WorkScheduleForm; value: any; idx: number }[]
  ): void;
}>();

// members

function assignedMemberNames(workSchedule: WorkScheduleWithAdditionalInfo) {
  if (!workSchedule.assignedMembers) return "";

  return workSchedule.assignedMembers
    ?.map((member) => member.name?.split(" ")[0])
    .join("・");
}

// update

function updateSchedule(key: keyof WorkScheduleForm, value: any, idx: number) {
  let params: { key: keyof WorkScheduleForm; value: any; idx: number }[] = [];

  params.push({
    key,
    value,
    idx,
  });

  emit("updateSchedule", params);
}

function deleteSchedule(schedule: WorkScheduleWithAdditionalInfo) {
  if (schedule.assignedMemberIds?.length) {
    alert("既にメンバーが割り当てられているため削除できません");
    return;
  }

  if (schedule.idx !== 0 && !schedule.idx) return;

  emit("deleteSchedule", schedule.idx);
}
</script>

<style scoped lang="scss">
.table-wrapper {
  overflow: scroll;
  max-height: 70vh;

  .td-work-type {
    max-width: 130px;
    min-width: 130px;
  }
  .td-need-count {
    max-width: 100px;
    min-width: 100px;
  }

  .time-form {
    min-width: 120px;
    max-width: 120px;
  }
}
</style>
