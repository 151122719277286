<template>
  <th>所定労働日数</th>
  <th>公休日数</th>
  <th>勤務予定日数</th>
  <th>休日勤務予定日数</th>
  <th>特別休暇日数</th>
  <th>取得予定有休日数</th>
  <th>有休残日数</th>
</template>

<script setup lang="ts"></script>

<style scoped></style>
