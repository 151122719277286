<template>
  <div>
    <GetLocation
      v-if="needEnterLeaveReport"
      class="mb-3"
      :lat="lat"
      :lng="lng"
      @update:lat="$emit('update:lat', $event)"
      @update:lng="$emit('update:lng', $event)"
    />

    <FormItemShow
      v-if="currentWorkScheduleTitle"
      label="計画"
      content-id="workSchdelue"
      class="mb-3"
    >
      {{ currentWorkScheduleTitle }}
    </FormItemShow>

    <FormItemShow
      v-if="currentWorkTitle && false"
      label="案件"
      content-id="work"
      class="mb-3"
    >
      {{ currentWorkTitle }}
    </FormItemShow>

    <FormItemShow
      v-if="currentWorkTypeName"
      class="mb-3"
      label="業務内容"
      content-id="workPlace"
    >
      {{ currentWorkTypeName }}
    </FormItemShow>

    <FormItemShow
      v-if="currentWorkPlaceName"
      class="mb-3"
      label="業務場所"
      content-id="workPlace"
    >
      {{ currentWorkPlaceName }}
    </FormItemShow>

    <FormItemShow
      v-if="workRecordEntry"
      class="mb-3"
      label="開始時刻"
      content-id="startAt"
    >
      {{ basicFormatter(workRecordEntry.workScheduleStartAt, "withTime") }}~
    </FormItemShow>

    <template v-if="needFinishReport">
      <div class="text-danger mb-3">
        <p>
          同じ業務場所で業務を行っている人がいる場合は、全体実績の重複入力にご注意下さい。
        </p>
        <p style="margin-bottom: 0">
          全体実績を共有する他の人が入力中にこのページを開いた場合、送信時にエラーが出る場合があります。
        </p>
        <p style="margin-bottom: 0">
          エラーが出た場合は、更新後再度の入力が必要になります。
        </p>
        <p>全体実績の値が最新の状態であることを確認して下さい。</p>
      </div>

      <div
        v-for="(entryReport, idx) in workRecordEntryReports"
        :key="`record-entry-reports-${idx}-${duttyHuck}`"
        class="mb-3"
      >
        <BasicButton
          v-if="entryReport.reportType === 'overall' && false"
          variant="danger"
          rounded
          small
          icon="pi pi-trash"
          class="delete-btn"
          @click="removeWorkRecordEntryReport(idx)"
        />

        <Accordion :active-index="0">
          <AccordionTab>
            <template #header>
              <span>{{ translater(entryReport.reportType) }}</span>
            </template>
            <WorkReportForm
              :key="duttyHuck"
              v-model:report-type="entryReport.reportType"
              :report-items="entryReport.reportItems || []"
              :basis-report-items="basisReportItems"
              :work-category="workRecordEntry.workCategory"
              :work-carrier="workRecordEntry.workCarrier"
              :work-type-name="workRecordEntry.workTypeName"
              :work-type-categories="workRecordEntry.workTypeCategories"
              :request-organiaztion-id="
                workRecordEntry.workRequestOrganizationId
              "
              @update:report-items="updateWorkRecordEntryReport($event, idx)"
            />
          </AccordionTab>
        </Accordion>
      </div>
      <div
        v-if="workRecordEntryReports.length <= 1"
        class="d-flex align-items-center mb-3"
      >
        <div class="me-2">
          <BasicButton
            label="全体実績を入力"
            icon="pi pi-file-edit"
            slim
            @click="addWorkRecordEntryReport"
          />
        </div>
      </div>
    </template>

    <WorkTaskForm
      v-if="
        currentWorkCategory !== 'consulting' &&
        currentWorkCategory !== 'fulltime'
      "
      :work-tasks="workTasks || []"
      :basis-report-items="basisReportItems"
      :entry="workRecordEntry"
      @update:work-tasks="$emit('update:workTasks', $event)"
    />

    <FormItemPhoto
      v-if="needEnterLeaveReport"
      class="mb-3"
      :photo="photo"
      form-id="finishPhoto"
      label=""
      :errors="errors"
      @update:photo="$emit('update:photo', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import { computed } from "vue";
import { ZodFormattedErrors } from "/@/modules/zodUtils";
import { basicFormatter } from "/@/modules/luxon";
import { workToTitle } from "/@/modules/work";
import { translater } from "/@/modules/string";
import { workScheduleToTitle } from "/@/modules/workSchedule";
import { BasicButton } from "/@/vue/components/Atom";
import { FormItemShow, FormItemPhoto } from "/@/vue/components/Molecules";
import {
  GetLocation,
  WorkReportForm,
  WorkTaskForm,
} from "/@/vue/components/Organisms";
import type {
  PhotoDataObject,
  WorkRecordEntryClient,
  ReportItemForm,
  WorkTaskForm as WorkTaskFormType,
  WorkRecordEntryReportForm,
  BasisReportItemClient,
} from "/@/types";

const props = defineProps<{
  lat?: number;
  lng?: number;
  photo?: PhotoDataObject;
  workRecordEntryReports: WorkRecordEntryReportForm[];
  workTasks?: WorkTaskFormType[];

  // args
  workRecordEntry: WorkRecordEntryClient;
  errors: ZodFormattedErrors;
  needEnterLeaveReport?: boolean;
  needFinishReport?: boolean;
  basisReportItems: BasisReportItemClient[];
}>();

const emit = defineEmits<{
  (e: "update:lat", lat: number): void;
  (e: "update:lng", lng: number): void;
  (e: "update:photo", photo: PhotoDataObject): void;
  (
    e: "update:workRecordEntryReports",
    workRecordEntryReports: WorkRecordEntryReportForm[]
  ): void;
  (e: "update:workTasks", workTasks: WorkTaskFormType[]): void;
}>();

const currentWorkCategory = computed(() => {
  return props.workRecordEntry.workCategory;
});

const currentWorkScheduleTitle = computed<string | undefined>(() => {
  if (!props.workRecordEntry) {
    return undefined;
  }

  return workScheduleToTitle({
    workCategory: props.workRecordEntry.workScheduleWorkCategory,
    workCarrier: props.workRecordEntry.workScheduleWorkCarrier,
    workTypeName: props.workRecordEntry.workScheduleWorkTypeName,
    workPlaceName: props.workRecordEntry.workScheduleWorkPlaceName,
    eventPlaceName: props.workRecordEntry.workScheduleEventPlaceName,
    startAt: props.workRecordEntry.workScheduleStartAt,
    finishAt: props.workRecordEntry.workScheduleFinishAt,
  });
});

const currentWorkScheduleCurrentCount = computed<number>(() => {
  if (!props.workRecordEntry) {
    return 0;
  }

  return props.workRecordEntry.workScheduleCurrentCount;
});

const currentWorkTitle = computed<string | undefined>(() => {
  if (!props.workRecordEntry) {
    return undefined;
  }

  return workToTitle({
    workCategory: props.workRecordEntry.workCategory,
    workPlaceName: props.workRecordEntry.workPlaceName,
    requestOrganizaitonName: props.workRecordEntry.workRequestOrganizaitonName,
    carrier: props.workRecordEntry.workCarrier,
  });
});

const currentWorkPlaceName = computed<string | undefined>(() => {
  if (!props.workRecordEntry) {
    return undefined;
  }

  return (
    props.workRecordEntry.workScheduleEventPlaceName ||
    props.workRecordEntry.workPlaceName
  );
});

const currentWorkTypeName = computed<string | undefined>(() => {
  if (!props.workRecordEntry) {
    return undefined;
  }

  return props.workRecordEntry.workTypeName;
});

let duttyHuck = 3;

function updateWorkRecordEntryReport(
  reportItems: ReportItemForm[],
  idx: number
) {
  if (props.workRecordEntryReports.length < idx) {
    return;
  }

  const oldEntryReports =
    props.workRecordEntryReports[idx].reportItems?.filter(
      (i) => !reportItems.some((ri) => ri.key == i.key)
    ) || [];

  const newReports = [
    ...props.workRecordEntryReports.slice(0, idx),
    {
      ...props.workRecordEntryReports[idx],
      reportItems: [...oldEntryReports, ...reportItems],
    },
    ...props.workRecordEntryReports.slice(idx + 1),
  ];

  emit("update:workRecordEntryReports", newReports);

  duttyHuck++;
}

function addWorkRecordEntryReport() {
  emit("update:workRecordEntryReports", [
    ...props.workRecordEntryReports,
    {
      reportType: "overall",
      reportItems: [],
    },
  ]);
}

function removeWorkRecordEntryReport(idx: number) {
  emit("update:workRecordEntryReports", [
    ...props.workRecordEntryReports.slice(0, idx),
    ...props.workRecordEntryReports.slice(idx + 1),
  ]);
}
</script>

<style scoped lang="scss">
.report-card {
  position: relative;
  .delete-btn {
    // fix on top right
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
}
</style>
