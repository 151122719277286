import SubmitLocation from "./SubmitLocation.vue";
import Finish from "./Finish.vue";
import New from "./New.vue";
import Leave from "./Leave.vue";
import Enter from "./Enter.vue";

export default [
  {
    path: "new",
    name: "WorkRecordsNew",
    component: New,
  },
  {
    path: "finish",
    name: "WorkRecordsFinish",
    component: Finish,
  },
  {
    path: "enter",
    name: "WorkRecordsEnter",
    component: Enter,
  },
  {
    path: "leave",
    name: "WorkRecordsLeave",
    component: Leave,
  },
  {
    path: "submit_location",
    name: "SubmitLocation",
    component: SubmitLocation,
  },
];
