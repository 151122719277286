<template>
  <div>
    <table class="table table-responsive table-bordered table-striped table-sm">
      <thead>
        <tr>
          <th :rowspan="2" class="task-header-color">展開</th>
          <th :rowspan="2" class="task-header-color">
            <CheckWhiteIcon class="icon" />
          </th>
          <th
            v-for="headerItem in headerItems"
            :key="`header-${headerItem}`"
            :class="headerItem.class"
            class="task-header-color"
            :rowspan="2"
          >
            {{ headerItem.name }}
          </th>
          <template v-if="!editMode">
            <th :colspan="dates.length" class="select-month task-header-color">
              <div class="d-flex justify-content-between">
                <CaretLeftIcon class="icon" role="button" @click="prevMonth" />
                <span>{{ targetMonth }}</span>
                <CaretRightIcon class="icon" role="button" @click="nextMonth" />
              </div>
            </th>
          </template>
        </tr>
        <tr>
          <template v-if="!editMode">
            <th
              v-for="d in dates"
              :key="`header-${d.dateKey}`"
              :class="{
                [getWeekdayColor(d)]: true,
                vertical: true,
              }"
            >
              <div>
                {{ d.luxonDate.day }}
              </div>
              <div>
                {{ basicFormatter(d.luxonDate, "onlyWday") }}
              </div>
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-for="task in showTaskItems" :key="task.id">
          <TaskItem
            :task-item="task"
            :edit-mode="editMode"
            :creating="existsEmptyNameTaskItem"
            :dates="dates"
            :hide-children="hideMap[task.id]"
            @update-task-item="updateTaskItem"
            @add-sibling="$emit('addSibling', $event)"
            @add-child="$emit('addChild', $event)"
            @delete-task-item="$emit('deleteTaskItem', $event)"
            @show-children="showChildren"
            @hide-children="hideChildren"
          />
        </template>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from "vue";
import {
  CheckWhiteIcon,
  CaretLeftIcon,
  CaretRightIcon,
} from "/@/vue/components/Atom";
import TaskItem from "./TaskItem.vue";
import type {
  DateForCalendar,
  DateMap,
  TaskItemForm,
  TaskItemUpdateParams,
} from "/@/types";
import {
  basicFormatter,
  createDateForCalendars,
  getWeekdayColor,
} from "/@/modules/luxon";

const props = defineProps<{
  taskItems: TaskItemForm[];
  editMode?: boolean;
  dateMap: DateMap;
}>();

const emits = defineEmits<{
  (e: "updateTaskItem", id: number, params: TaskItemUpdateParams): void;
  (e: "addSibling", id: number): void;
  (e: "addChild", id: number): void;
  (e: "deleteTaskItem", id: number): void;

  // gantt
  (e: "prevMonth"): void;
  (e: "nextMonth"): void;
}>();

const headerItemsAllways = [
  {
    name: "番号",
    class: {
      "text-start": true,
    },
  },
  {
    name: "タスク",
    class: {
      "text-start": true,
    },
  },
  { name: "計画日" },
  { name: "完了日" },
  { name: "分" },
];

const headerItems = computed(() => {
  if (props.editMode) {
    return [
      ...headerItemsAllways,
      {
        name: "備考",
        class: {
          remarks: true,
          "d-none": !props.editMode,
        },
      },
    ];
  }

  return headerItemsAllways;
});

const existsEmptyNameTaskItem = computed(() => {
  return props.taskItems.some((taskItem) => !taskItem.name);
});

const dates = computed<DateForCalendar[]>(() => {
  if (!props.dateMap) {
    return [];
  }

  return createDateForCalendars(props.dateMap);
});

const targetMonth = computed(() => {
  if (!dates.value.length) {
    return;
  }

  const first = dates.value.at(0);

  if (!first) {
    throw new Error("unexpected in targetMonth");
  }

  return basicFormatter(first.dateKey, "onlyMonth");
});

// toggler
// 閲覧モードの時に番号を押すと子タスクを表示/非表示

// { 10: true, 12: true, 13: false } の場合, id := 10, 12 であるような task の展開ボタンは [-] 状態になる
const hideMap = reactive<Record<number, boolean>>({});

// { 10: true, 12: true, 13: false } の場合、 id := 10, 12 であるような task は非表示にする
const hiddenMap = reactive<Record<number, boolean>>({});

const showTaskItems = computed(() => {
  return props.taskItems.filter((taskItem) => !hiddenMap[taskItem.id]);
});

function showChildren(id: number) {
  const rootItem = props.taskItems.find((taskItem) => taskItem.id === id);

  if (!rootItem) {
    return;
  }

  hideMap[id] = false;

  rootItem.descendantIds.forEach((descendantId) => {
    hiddenMap[descendantId] = false;
  });
}

function hideChildren(id: number) {
  const rootItem = props.taskItems.find((taskItem) => taskItem.id === id);

  if (!rootItem) {
    return;
  }

  hideMap[id] = true;

  rootItem.descendantIds.forEach((descendantId) => {
    hiddenMap[descendantId] = true;
  });
}

// updater

function updateTaskItem(id: number, params: TaskItemUpdateParams) {
  emits("updateTaskItem", id, params);
}

// gantt

function prevMonth() {
  emits("prevMonth");
}

function nextMonth() {
  emits("nextMonth");
}
</script>

<style scoped lang="scss">
.table {
  font-size: 0.8rem;
}
.remarks {
  min-width: 300px;
}

.task-header-color {
  background-color: #4285f4;
  color: white;
}

.select-month {
  border-radius: 5rem;
}
</style>
