import {
  basicFormatter,
  countHoursOfArray,
  fromISO,
  isSameDate,
  luxonNow,
} from "./luxon";
import { roundAtPrecision } from "./math";
import { workScheduleToTitle } from "./workSchedule";
import type {
  Carrier,
  OrganizationMemberSchedulePlan,
  WorkCategory,
  WorkRecordClient,
  WorkRecordEntryClient,
} from "/@/types";
import { workRecordEntries } from "/@/vue/store/test.db";

export function getCurrent<
  T extends { startAt: string; finishAt?: string; [key: string]: any }
>(records?: T[]): T | undefined {
  if (!records) {
    return undefined;
  }

  const current: T | undefined = records.find(
    (record) => record.startAt && !record.finishAt
  );

  return current;
}

export function getLast<
  T extends { startAt: string; finishAt?: string; [key: string]: any }
>(records?: T[]) {
  if (!records) {
    return undefined;
  }

  const current = getCurrent(records);

  if (current) return current;

  const sorted = records.slice().sort((a, b) => {
    const at = a.finishAt ? fromISO(a.finishAt) : 0;
    const bt = b.finishAt ? fromISO(b.finishAt) : 0;

    if (!at || !bt) {
      return -1;
    }

    return at < bt ? 1 : 0;
  });

  return sorted.at(0);
}

export function getCurrentEntry(workRecord?: WorkRecordClient) {
  // 現在は複数の業務が並列になることを想定していない
  if (!workRecord) {
    return undefined;
  }

  const current = workRecord.workRecordEntries.find(
    (entry) => entry.startAt && !entry.finishAt
  );

  return current;
}

export function entryScheduleToTitle(workRecordEntry: WorkRecordEntryClient) {
  const {
    workPlaceName,
    workTypeName,
    workCategory,
    workCarrier,
    workScheduleEventPlaceName,
    workScheduleEventPlaceShortName,
    workScheduleStartAt,
    workScheduleFinishAt,
    workScheduleCanceled,
  } = workRecordEntry;

  return workScheduleToTitle({
    workPlaceName,
    workTypeName,
    workCategory,
    workCarrier,
    eventPlaceName: workScheduleEventPlaceName,
    eventPlaceShortName: workScheduleEventPlaceShortName,
    startAt: workScheduleStartAt,
    finishAt: workScheduleFinishAt,
    canceled: workScheduleCanceled,
  });
}

export function entryToTitle(
  prms: {
    startAt: string;
    finishAt?: string;
    workCategory: WorkCategory;
    workCarrier: Carrier;
    workPlaceName: string;
    workTypeName: string;
    workRequestOrganizationName: string;
    workScheduleEventPlaceName?: string;
    workScheduleStartAt: string;
    workScheduleFinishAt: string;
    [key: string]: any;
  },
  option: {
    withoutTime?: boolean;
    short?: boolean;
    scheduleMode?: boolean;
    withoutRequestOrganization?: boolean;
  } = {
    withoutTime: false,
    short: true,
    scheduleMode: true,
    withoutRequestOrganization: false,
  }
) {
  const {
    startAt,
    finishAt,
    workRequestOrganizationName,
    workPlaceName,
    workTypeName,
    workScheduleEventPlaceName,
    workScheduleStartAt,
    workScheduleFinishAt,
  } = prms;

  const { withoutTime, scheduleMode, withoutRequestOrganization } = option;

  const showingStartAt = scheduleMode ? workScheduleStartAt : startAt;
  const showingFinishAt = scheduleMode ? workScheduleFinishAt : finishAt;

  const workingPlaceName = workScheduleEventPlaceName
    ? workScheduleEventPlaceName + " "
    : workPlaceName
    ? workPlaceName + " "
    : "";

  const timeStr = withoutTime
    ? ""
    : `${basicFormatter(showingStartAt, "onlyTime")}~${
        finishAt ? basicFormatter(showingFinishAt, "onlyTime") : ""
      } `;

  return `${timeStr}${
    !withoutRequestOrganization && workRequestOrganizationName
      ? `${workRequestOrganizationName} `
      : ""
  }${workingPlaceName}${workTypeName}`;
}

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;

  const entry = workRecordEntries.at(1);

  if (!entry) throw new Error("db unexpected error");

  it("entryToTile", () => {
    expect(entryToTitle(entry)).toBe("13:00~ 婦中 獲得支援");
  });

  it("entrySchdelueToTile", () => {
    expect(entryScheduleToTitle(entry)).toBe(
      "13:00~17:00 店舗HP au 婦中 獲得支援"
    );
  });
}

// work entries

type HaveRangeMap = { startAt: string; finishAt?: string; [key: string]: any };

export function getTotalWorkingHours(
  entries: HaveRangeMap[],
  restTimes: HaveRangeMap[]
) {
  const workingHours = countHoursOfArray(entries);
  const restTimeHours = countHoursOfArray(restTimes);

  return roundAtPrecision(workingHours - restTimeHours, 1);
}

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;

  it("getTotalWorkingHours", () => {
    expect(
      getTotalWorkingHours(
        [
          {
            startAt: "2023-06-20T09:00:00.000+09:00",
            finishAt: "2023-06-20T18:00:00.000+09:00",
          },
        ],
        [
          {
            startAt: "2023-06-20T12:00:00.000+09:00",
            finishAt: "2023-06-20T13:00:00.000+09:00",
          },
        ]
      )
    ).toBe(8);
  });
}

// for Attendance

export type RecordStatus =
  | "today"
  | "working"
  | "worked"
  | "public_holidays"
  | "absenteeism";

export function recordStatusTranslater(status: RecordStatus) {
  const m: Record<RecordStatus, string> = {
    today: "-",
    working: "勤務中",
    worked: "勤務",
    public_holidays: "公休",
    absenteeism: "欠勤",
  };

  return m[status];
}

export function getRecordStatus(
  records: WorkRecordClient[],
  schedule?: OrganizationMemberSchedulePlan,
  today: string = luxonNow().toISO() || ""
): RecordStatus {
  if (
    schedule &&
    records.length === 0 &&
    isSameDate(schedule.targetDate, today)
  ) {
    return "today";
  }

  if (records.length) {
    const nowWorking = records.some(
      (record) => record.startAt && !record.finishAt
    );

    if (nowWorking) {
      return "working";
    } else {
      return "worked";
    }
  }

  if (!schedule) {
    return "public_holidays";
  }

  return "absenteeism";
}

// work record daily

export function workRecordDailyLink(
  organizationMemberId: number | string,
  targetDate: string
) {
  return {
    name: "WorkRecordDailiesShow",
    query: {
      targetDate: fromISO(targetDate).toISODate(),
      organizationMemberId,
    },
  };
}
