import Axios from "axios";
import { version } from "./version";

export const axios = Axios.create({
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "X-Version": version,
  },
  withCredentials: true,
});

export const fetcher = async (url: string) => {
  return axios.get(url).then((res) => {
    /*
    if (url.startsWith("/api/v1/organization_members")) {
      console.log("fetcher", url, res.data);
    }
    */

    return res.data;
  });
};
