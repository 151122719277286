<template>
  <div>
    <InputForm
      :value="value"
      :is-valid="isValid"
      :form-id="formId"
      autuocomplete="password"
      :form-type="showPassword ? 'text' : 'password'"
      :disabled="disabled"
      :readonly="readonly"
      @update:value="update"
    >
      <template #backButtonSlot>
        <button type="button" class="btn" @click="showPassword = !showPassword">
          <PasswordShowIcon v-if="!showPassword" class="icon" />
          <PasswordHideIcon v-else class="icon" />
        </button>
      </template>
    </InputForm>
  </div>
</template>

<script setup lang="ts">
import { PasswordShowIcon, PasswordHideIcon } from "/@/vue/components/Atom";
import { InputForm } from "./";
import { ref } from "vue";

interface Props {
  value?: string;
  isValid?: boolean;
  isConfirmation?: boolean;
  formId?: string;
  disabled?: boolean;
  readonly?: boolean;
}

defineProps<Props>();

const emit = defineEmits<{
  (e: "update:value", value: string | null): void;
}>();

const showPassword = ref<boolean>(false);

function update(email: string | null) {
  emit("update:value", email);
}
</script>

<style lang="scss" scoped>
.btn {
  border-color: var(--bs-border-color);
}
</style>
