import { DateMap } from "/@/types";
import { fromISO } from "/@/modules/luxon";

export function createQueryParams(
  params: Record<string, string | string[] | boolean | undefined>
) {
  const queryParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => queryParams.append(`${key}[]`, item));
    } else {
      if (value) {
        queryParams.set(key, value);
      }
    }
  });
  return queryParams.toString();
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  it("createQueryParams", () => {
    const params = {
      id: "123",
      name: "John Doe",
      hobbies: ["reading", "running"],
    };

    expect(createQueryParams(params)).toBe(
      "id=123&name=John+Doe&hobbies%5B%5D=reading&hobbies%5B%5D=running"
    );
  });
}

export function createDateParams(dateMap?: DateMap) {
  if (!dateMap) return undefined;

  return (
    dateMap &&
    createQueryParams({
      year: dateMap.year.toString(),
      month: dateMap.month.toString(),
      dayNumber: dateMap.day ? fromISO(dateMap.day).day.toString() : undefined,
    })
  );
}
