<template>
  <div>
    <SimpleModal
      ref="modal"
      title="各種申請"
      submit-text="申請"
      :loading="loading"
      @cancel="closeModal"
      @submit="submit"
    >
      <div class="mb-2 d-flex flex-column align-items-center">
        <div class="d-flex flex-column flex-start">
          <!--
            <ObjectSelector
            v-if="isOwner"
            v-model:value="formOwner.organizationMemberId"
            :items="organizationMembers || []"
            value-key="id"
            show-key="user"
            :show-fn="(user) => user.name"
          />
          -->

          <FormItemSelector
            v-if="isOwner"
            :value="selectedOrganizationMemberId"
            label="設定するメンバー"
            form-type="object"
            :errors="{}"
            :items="organizationMembers || []"
            form-id="organizationMemberId"
            show-key="user"
            value-key="id"
            :show-fn="(user) => user.name"
            @update:value="selectedOrganizationMemberId = $event?.id"
          />
          <!--
          <FormItemSelector
            v-else
            :value="formOwnee.organizationId"
            label="提出する組織"
            form-type="object"
            :errors="errorsOwnee"
            :items="organizations || []"
            form-id="organizationId"
            show-key="name"
            value-key="id"
            @update:value="formOwnee.organizationId = $event.id"
          />
          -->

          <FormItemSelector
            v-model:value="selectedApplicationType"
            label="提出種別"
            form-type="general"
            :errors="{}"
            :items="applicationTypes.filter((t) => t === 'holiday')"
            form-id="applicationType"
            :show-fn="translater"
          />

          <FormItem
            v-model:value="targetDates"
            form-type="date"
            :errors="{}"
            form-id="targetDate"
            label="日にち"
            :min-date="minDate"
            :start-date="startDate"
            multi-dates
            :auto-apply="false"
            :disabled-dates="disabledDates"
          />
        </div>
      </div>
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { luxonNow } from "/@/modules/luxon";
import { errorHandle } from "/@/modules/error";
import { translater } from "/@/modules/string";
import {
  FormItem,
  FormItemSelector,
  SimpleModal,
} from "/@/vue/components/Molecules";
import {
  Organization,
  OrganizationMember,
  applicationTypes,
  SubmitApplicationOwnerScheme,
  SubmitApplicationOwnerForm,
  SubmitApplicationOwneeScheme,
  SubmitApplicationOwneeForm,
  ApplicationType,
  AbsenceApplicationPlan,
} from "/@/types";

const props = defineProps<{
  loading?: boolean;
  organizationMembers?: OrganizationMember[];
  organizations?: Organization[];
  selectedOrganization?: Organization;
  absenceApplicationPlans: AbsenceApplicationPlan[];
  isOwner?: boolean;
}>();

const emit = defineEmits<{
  (
    e: "submit",
    application: SubmitApplicationOwnerForm | SubmitApplicationOwneeForm
  ): void;
}>();

const now = luxonNow();

const minDate = computed(() => {
  if (props.isOwner) {
    return now.startOf("day");
  }

  // now is after 10th of the month
  /*
  if (now.day > 10) {
    return now.startOf("day").plus({ month: 2 }).startOf("month");
  } else {
    return now.startOf("day").plus({ month: 1 }).startOf("month");
  }
  */

  return now.plus({ month: 1 }).startOf("month");
});

const startDate = computed(() => {
  if (now.day > 10) {
    return now.plus({ month: 1 });
  } else {
    return now.plus({ month: 1 });
  }
});

// modal difinissions

const modal = ref();

function openModal() {
  modal.value.openModal();
}

function closeModal() {
  modal.value.closeModal();
}

defineExpose({
  openModal,
  closeModal,
});

// modal form scheme

//// owner

const selectedOrganizationMemberId = ref();
const selectedApplicationType = ref<ApplicationType>("holiday");
const targetDates = ref<string[]>([]);

// absence

const selectedMemberPlans = computed(() => {
  if (!props.absenceApplicationPlans?.length) return [];

  return props.absenceApplicationPlans.filter(
    (plan) =>
      plan.applicationType === selectedApplicationType.value &&
      plan.organizationMember.id ===
        (selectedOrganizationMemberId.value ||
          props.selectedOrganization?.organizationMemberId)
  );
});

const disabledDates = computed(() => {
  return selectedMemberPlans.value.map((p) => p.targetDate);
});

// modal actions

function resetForm() {
  targetDates.value = [];
}

function submit() {
  try {
    if (props.isOwner) {
      const application = {
        organizationMemberId: selectedOrganizationMemberId.value,
        applicationType: selectedApplicationType.value,
        targetDates: targetDates.value,
      };

      const submitApplication = SubmitApplicationOwnerScheme.parse(application);
      emit("submit", submitApplication);
    } else {
      const selectedOrganization = props.selectedOrganization;

      if (!selectedOrganization) {
        alert("組織が選択されていません");
        return;
      }

      const application = {
        organizationId: selectedOrganization.id,
        applicationType: selectedApplicationType.value,
        targetDates: targetDates.value,
      };

      const submitApplication = SubmitApplicationOwneeScheme.parse(application);
      emit("submit", submitApplication);
    }
  } catch (e) {
    errorHandle(e);
  }

  resetForm();
}
</script>

<style scoped></style>
