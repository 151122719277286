<template>
  <div>
    <div class="mb-2"></div>
    <OrganizationSelector
      v-model:selected-organization-id="selectedOrganizationId"
      class="mb-3"
      :organizations="organizations || []"
    />

    <template v-if="selectedOrganization">
      <SelectDate
        v-model:year="selectedDate.year"
        v-model:month="selectedDate.month"
        :exists-dates="existsDates"
        only-year-month
        disabled-auto-select
        class="mb-3"
      />

      <div class="table-wrapper">
        <LaborInfoUserRowCalendar
          v-if="organizationMemberSchedules?.length"
          :selected-date="selectedDate"
          :jp-holidays="jpHolidays || []"
          :organization-members="visibleMembers || []"
          :absence-application-plans="absenceApplicationPlans || []"
          :organization-member-schedules="organizationMemberSchedules || []"
        />
        <i v-else class="pi pi-spin pi-spinner large"></i>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { watch, computed } from "vue";
import {
  useCalendar,
  useOrganization,
  useOrganizationMember,
  usePlans,
  useSelectDate,
} from "/@/vue/composables";
import { SelectDate } from "/@/vue/components/Atom";
import {
  LaborInfoUserRowCalendar,
  OrganizationSelector,
} from "/@/vue/components/Organisms";

// date

const { selectedDate, existsDates } = useSelectDate();

// holiday
const { getJpHolidays } = useCalendar();
const { data: jpHolidays } = getJpHolidays();

// organization

const {
  getOrganizations,
  selectedOrganizationId,
  organizations,
  selectedOrganization,
  isOwner,
} = useOrganization();
const { data: organizationsData } = getOrganizations();

watch(organizationsData, (os) => {
  organizations.value = os || [];
});

// organization members

const { getOrganizationMembers } = useOrganizationMember();
const { data: organizationMembers } = getOrganizationMembers(
  selectedOrganization,
  isOwner,
  selectedDate,
  { withLaborInfo: true }
);

const visibleMembers = computed(() => {
  return organizationMembers.value?.filter((member) => {
    return !member.hideFromSelect;
  });
});

// planMaps

const {
  getPlanMaps,
  getAbsenceApplicationPlans,
  getWorkAsPlans,
  getWorkScheduleAsPlans,
  getOrganizationMemberSchedules,
  getOrganizationSchedules,
  getAssignOrganizationSchedules,
} = usePlans();

const { data: absenceApplicationPlans } = getAbsenceApplicationPlans(
  selectedOrganization,
  selectedDate,
  isOwner
);

const { data: organizationMemberSchedules } = getOrganizationMemberSchedules(
  selectedOrganization,
  selectedDate,
  isOwner
);
</script>

<style scoped>
.table-wrapper {
  overflow-x: scroll;
}
</style>
