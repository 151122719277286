<template>
  <div>
    <SimpleModal ref="modal" :title="title" no-actions @cancel="closeModal">
      <template v-if="planModel && eventType == 'AbsenceApplicationPlan'">
        <FormItemShow v-if="!isOwn" label="メンバー" content-id="memberName">
          <div>{{ planModel.organizationMember.name }}</div>
        </FormItemShow>
        <FormItemShow label="日にち" content-id="targetDate">
          <div>{{ basicFormatter(planModel.targetDate) }}</div>
        </FormItemShow>
        <FormItemShow label="作成日" content-id="createdAt">
          <div>{{ basicFormatter(planModel.createdAt) }}</div>
        </FormItemShow>
        <FormItemShow v-if="false" label="承認状況" content-id="accept">
          <div>{{ planModel.accept ? "承認済み" : "承認待ち" }}</div>
        </FormItemShow>
        <PlanModalActions
          :event-type="eventType"
          :accept="planModel.accept"
          :is-owner="isOwner"
          @accept-plan="$emit('acceptPlan', planModel)"
          @delete-plan="$emit('deletePlan', planModel)"
        />
      </template>

      <template v-if="planModel && eventType == 'WorkAsPlan'">
        <FormItemShow label="カテゴリー" content-id="workCategory">
          <div>{{ translater(planModel.workCategory) }}</div>
        </FormItemShow>
        <FormItemShow label="期間" content-id="on">
          <div>
            {{ basicFormatter(planModel.startOn) }} ~
            {{ basicFormatter(planModel.finishOn, "noYear") }}
          </div>
        </FormItemShow>
        <PlanModalActions
          v-if="isOwner"
          :event-type="eventType"
          is-owner
          @goto-works-edit="handleGotoWorksEdit"
        />
      </template>

      <template
        v-if="planModel && eventType == 'OrganizationMemberSchedulePlan'"
      >
        <FormItemShow label="メンバー" content-id="member">
          <span>{{ planModel.organizationMemberName }}</span>
        </FormItemShow>
        <FormItemShow label="日にち" content-id="target-date">
          <span>{{ basicFormatter(planModel.targetDate) }}</span>
        </FormItemShow>
        <FormItemShow label="業務情報" content-id="schedule">
          <div class="mb-3"></div>
          <div
            v-for="ws in planModel.workSchedules"
            :key="`work-schedule-${ws.id}`"
            class="ps-3 work-schedule-item mb-3"
          >
            <div class="d-flex align-items-center">
              <ContentLabel
                label="内容"
                :content-id="`work-schedule-${ws.id}-info`"
                without-padding
                class="me-2"
              />
              <BasicButton
                v-if="planModel.workSchedules.length > 1"
                small
                rounded
                variant="danger"
                icon="pi pi-trash"
                @click="
                  destroyWorkScheduleAssigment(ws.workScheduleAssignmentId)
                "
              />
            </div>

            <FormItemShow label="" content-id="work-info">
              <span>
                {{
                  workScheduleToTitle(
                    {
                      ...ws,
                      absence: ws.assignedMembers.find(
                        (m) =>
                          m.organizationMemberId ===
                          planModel.organizationMemberId
                      ).absence,
                      vacancy: ws.assignedMembers.find(
                        (m) =>
                          m.organizationMemberId ===
                          planModel.organizationMemberId
                      ).vacancy,
                    },
                    { withoutCurrentStatus: true }
                  )
                }}
              </span>
            </FormItemShow>
          </div>
          <div
            v-if="planModel.restTimeSchedules.length > 0"
            class="work-schedule-item mb-3 ps-3"
          >
            <ContentLabel
              label="休憩予定時間"
              :content-id="`work-schedule-rest-times`"
              without-padding
              class="me-2"
            />
            <div
              v-for="(restTime, idx) in planModel.restTimeSchedules"
              :key="`rest-time-${idx}`"
              class=""
            >
              {{ basicFormatter(restTime.startAt, "onlyTime") }}~{{
                basicFormatter(restTime.finishAt, "onlyTime")
              }}
            </div>
          </div>
        </FormItemShow>
        <PlanModalActions
          v-if="isOwner"
          :event-type="eventType"
          :plan-model="planModel"
          :loading="loading"
          is-owner
          :is-fryth-owner="isFrythOwner"
          @destroy-member-schedule="$emit('destroyMemberSchedule', planModel)"
          @destroy-work-schedule="$emit('destroyWorkSchedule', planModel)"
          @cancel-work-schedule="$emit('cancelWorkSchedule', planModel)"
          @restore-work-schedule="$emit('restoreWorkSchedule', planModel)"
          @goto-works-edit="handleGotoWorksEdit"
          @open-vacancy-modal="showVacantNumberDialog = true"
        />
      </template>

      <template v-if="planModel && eventType == 'OrganizationSchedulePlan'">
        <div>
          <div class="mb-3">
            組織{{ planModel.organizationName }}へスケジュールを割り当て済み
          </div>

          <ContentLabel label="割り当て人数" content-id="needCount" />
          <div class="mb-3">
            <NumberForm
              v-model:value="form.needCount"
              :items="items"
              form-id="needCount"
            />
          </div>

          <div class="d-flex align-items-center justify-content-between">
            <BasicButton
              variant="danger"
              icon="pi pi-user-minus"
              label="割り当て削除"
              button-type="submit"
              @click="$emit('destroyOrganizationSchedule', planModel)"
            />

            <BasicButton
              variant="primary"
              icon="pi pi-refresh"
              label="更新"
              button-type="submit"
              @click="updateOrganizationMemberSchedule"
            />
          </div>
        </div>
      </template>
    </SimpleModal>
    <VacantNumberModal
      v-model="showVacantNumberDialog"
      :target-work-schedule="(planModel as OrganizationMemberSchedulePlan)?.workSchedules[0]"
      binary
      @vacancy="updateWorkScheduleAssaignmentVacancy"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, reactive } from "vue";
import { basicFormatter } from "/@/modules/luxon";
import { translater } from "/@/modules/string";
import { workScheduleToTitle } from "/@/modules/workSchedule";
import { useCalendar } from "/@/vue/composables";
import { BasicButton, ContentLabel, NumberForm } from "/@/vue/components/Atom";
import { SimpleModal, FormItemShow } from "/@/vue/components/Molecules";
import {
  PlanModalActions,
  VacantNumberModal,
} from "/@/vue/components/Organisms";
import type {
  CalendarEventArgs,
  EventType,
  Plan,
  Organization,
  OrganizationMemberSchedulePlan,
} from "/@/types";
import _ from "lodash";

const props = defineProps<{
  selectedOrganization?: Organization;
  loading?: boolean;
  isOwner?: boolean; // このモーダルを開いているのが、作成者のオーナーかどうか
  isFrythOwner?: boolean;
}>();

const emit = defineEmits<{
  (e: "closeModal"): void;
  (e: "deletePlan", planModel: Plan): void;
  (e: "destroyMemberSchedule", planModel: Plan): void;
  (e: "destroyWorkSchedule", planModel: Plan): void;
  (e: "cancelWorkSchedule", planModel: Plan): void;
  (e: "restoreWorkSchedule", planModel: Plan): void;
  (e: "gotoWorksEdit", planModel: Plan): void;
  (e: "destroyWorkScheduleAssignment", id: number): void;
  (e: "destroyOrganizationSchedule", planModel: Plan): void;
  (
    e: "updateOrganizationMemberSchedule",
    prms: {
      id: number;
      workScheduleId: number;
      needCount: number;
    }
  ): void;
  (
    e: "updateAssignmentVacancy",
    workScheduleAssignmentId: number,
    vacancy: boolean
  ): void;

  // Plan
  (e: "acceptPlan", planModel: Plan): void;
}>();

const selectedEvent = ref<CalendarEventArgs | undefined>();
const selectedEventType = computed<EventType | undefined>(() => {
  if (!selectedEvent.value) {
    return undefined;
  }

  return selectedEvent.value.extendedProps.eventType;
});

const isOwn = computed<boolean>(() => {
  if (
    !props.selectedOrganization ||
    !selectedEvent.value ||
    !selectedEventType.value
  ) {
    return false;
  }

  switch (selectedEventType.value) {
    case "AbsenceApplicationPlan":
      return (
        selectedEvent.value.extendedProps.organizationMember.id ==
        props.selectedOrganization.organizationMemberId
      );
    case "WorkAsPlan":
      return (
        selectedEvent.value.extendedProps.organizationId ==
        props.selectedOrganization.id
      );
    default:
      return false;
  }
});

const planModel = computed(() => {
  return selectedEvent.value?.extendedProps;
});

const eventType = computed<EventType | undefined>(() => {
  return selectedEvent.value?.extendedProps?.eventType;
});

const { absenceApplicationPlanToTitle } = useCalendar();

const title = computed<string | undefined>(() => {
  if (!selectedEvent.value) {
    return undefined;
  }

  switch (eventType.value) {
    case "AbsenceApplicationPlan":
      // @ts-ignore
      return absenceApplicationPlanToTitle(selectedEvent.value.extendedProps);
    case "WorkAsPlan":
      return "案件";
    case "OrganizationMemberSchedulePlan":
      return "業務計画";
    default:
      return "";
  }
});

// api

function destroyWorkScheduleAssigment(id: number) {
  emit("destroyWorkScheduleAssignment", id);
}

function handleGotoWorksEdit() {
  emit("gotoWorksEdit", { id: planModel.value.workSchedules[0].workId });
}

// organization schedule

const form = reactive<{
  id?: number;
  workScheduleId?: number;
  needCount: number;
}>({
  id: undefined,
  workScheduleId: undefined,
  needCount: 0,
});

function resetForm() {
  form.id = undefined;
  form.needCount = 0;
  form.workScheduleId = undefined;
}

const items = computed<number[]>(() => {
  return _.range(1, 10);
});

function updateOrganizationMemberSchedule() {
  if (
    form.id == undefined ||
    form.workScheduleId == undefined ||
    form.needCount == undefined
  ) {
    alert("入力が不正です");
    return;
  }

  // @ts-ignore
  emit("updateOrganizationMemberSchedule", form);
  closeModal();
}

// vacant

const showVacantNumberDialog = ref(false);

function updateWorkScheduleAssaignmentVacancy(
  targetScheduleId: number,
  vacancyCount: number
) {
  const targetOrganizationMemberId = planModel.value.organizationMemberId;
  const targetSchedule = planModel.value.workSchedules.find(
    (ws) => ws.id == targetScheduleId
  );

  const targetAssignmentId = targetSchedule?.assignedMembers.find(
    (m) => m.organizationMemberId == targetOrganizationMemberId
  )?.workScheduleAssignmentId;

  emit("updateAssignmentVacancy", targetAssignmentId, !!vacancyCount);

  showVacantNumberDialog.value = false;
  closeModal();
}

// modal difinissions

const modal = ref();

function openModal(event: CalendarEventArgs) {
  selectedEvent.value = event;

  if (event.extendedProps.eventType == "OrganizationSchedulePlan") {
    const assignedOrganizations =
      event.extendedProps.workSchedules[0].assignedOrganizations;
    const assignedOrganization = assignedOrganizations.find(
      (ao) => ao.organizationId == event.extendedProps.organizationId
    );

    form.id = event.extendedProps.id;
    form.needCount = assignedOrganization?.assignedNeedCount;
    form.workScheduleId = event.extendedProps.workSchedules[0].id;
  }

  modal.value.openModal();
}

function closeModal() {
  selectedEvent.value = undefined;

  resetForm();

  modal.value.closeModal();
  emit("closeModal");
}

defineExpose({
  openModal,
  closeModal,
});
</script>

<style lang="scss" scoped>
.work-schedule-item {
  border-left: 1px solid $main-gray-color;
}
</style>
