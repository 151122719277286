<template>
  <PageHeader page-title="出勤簿" hide-back-btn />

  <OrganizationSelector
    v-model:selected-organization-id="selectedOrganizationId"
    class="w-100 mb-3"
    :organizations="organizations || []"
  />

  <SelectDate
    :year="selectedDate.year"
    :month="selectedDate.month"
    :exists-dates="existsDates"
    only-year-month
    @update:year="updateSelectedDate({ year: $event })"
    @update:month="updateSelectedDate({ month: $event })"
  />

  <div class="mb-3">
    <ContentLabel label="メンバー" />
    <ObjectSelector
      :value="selectedOrganizationMemberId"
      :items="organizationMembers || []"
      value-key="id"
      :show-fn="(member: OrganizationMember) => memberShowFn(member, selectedOrganization, subContractors)"
      form-id="select-organization-member"
      :is-valid="!!selectedOrganizationMemberId"
      @update:value="selectedOrganizationMemberId = $event?.id"
    />
  </div>

  <div class="d-flex flex-row justify-content-between mb-3">
    <div v-if="isFRYTHOwner" class="d-flex">
      <ToggleButtonLabelItem
        v-model:value="ownerMode"
        label="編集モード"
        content-id="owner-modde-toggle"
      />

      <ToggleButtonLabelItem
        v-model:value="showRecordTime"
        label="実際の記録"
        content-id="record-time-toggle"
      />
    </div>
  </div>

  <div v-if="isFRYTHOwner" class="col-3 mb-2">
    <GenPDFBtn @click="generateAttendancePDF" />
  </div>

  <div id="pdf-contents">
    <template v-if="selectedOrganizationMember">
      <span v-if="getWorkRecordsrError" class="text-danger">
        権限がありません。組織管理者にお問い合わせして下さい。
      </span>
      <AttendanceTable
        v-else
        :member="selectedOrganizationMember"
        :selected-date="selectedDate"
        :work-records="targetMonthRecords"
        :schedules="planMaps?.organizationMemberSchedulePlans || []"
        :show-record-time="showRecordTime"
        :owner-mode="ownerMode"
        @as-worked="handleAsWorked"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import {
  createIsoRange,
  isContainInDateMap,
  luxonNow,
  basicFormatter,
} from "/@/modules/luxon";
import { generatePDF } from "/@/modules/pdf";
import { memberShowFn } from "/@/modules/organizationMember";
import {
  useOrganization,
  useWorkRecords,
  useOrganizationMember,
  usePlans,
} from "/@/vue/composables";
import { PageHeader } from "/@/vue/components/Layouts";
import {
  SelectDate,
  ContentLabel,
  ObjectSelector,
} from "/@/vue/components/Atom";
import { ToggleButtonLabelItem, GenPDFBtn } from "/@/vue/components/Molecules";
import {
  OrganizationSelector,
  AttendanceTable,
} from "/@/vue/components/Organisms";
import type {
  DateMap,
  OrganizationMember,
  PlanMap,
  WorkAsPlansLight,
  OrganizationMemberSchedulePlanLight,
  OrganizationSchedulePlanLight,
  WorkScheduleAsPlan,
} from "/@/types";

const today = luxonNow();

const showRecordTime = ref(false);

const selectedDate = ref<DateMap>({
  year: today.year,
  month: today.month,
});

function updateSelectedDate({
  year,
  month,
}: {
  year?: number;
  month?: number;
}) {
  selectedDate.value = {
    year: year ? year : selectedDate.value.year,
    month: month ? month : selectedDate.value.month,
  };
}

const {
  getOrganizations,
  organizations,
  subContractors,
  selectedOrganizationId,
  selectedOrganization,
  isOwner,
  isFRYTHOwner,
} = useOrganization();
const { data: organizationsData } = getOrganizations();

watch(organizationsData, (os) => {
  organizations.value = os || [];
});

const { getOrganizationMembers } = useOrganizationMember();
const { data: organizationMembers } = getOrganizationMembers(
  selectedOrganization,
  isOwner,
  selectedDate,
  {
    withLaborInfo: true,
  }
);

const selectedOrganizationMemberId = ref<number | undefined>();
const selectedOrganizationMember = computed(() => {
  if (!organizationMembers.value || !selectedOrganizationMemberId.value) {
    return undefined;
  }

  return organizationMembers.value.find(
    (member) => member.id === selectedOrganizationMemberId.value
  );
});
const selectedOrganizationMemberOrganization = computed(() => {
  return {
    organizationMemberId: selectedOrganizationMemberId.value,
  };
});

const { getWorkRecords, asWorked } = useWorkRecords();
const { data: workRecords, error: getWorkRecordsrError } = getWorkRecords(
  selectedOrganizationMemberOrganization,
  selectedDate
);

const targetMonthRecords = computed(() => {
  if (!workRecords.value) return [];

  return workRecords.value.filter((record) =>
    isContainInDateMap(record.startAt, selectedDate.value)
  );
});

// plan maps

const {
  getPlanMaps,
  getAbsenceApplicationPlans,
  getWorkAsPlans,
  getWorkScheduleAsPlans,
  getOrganizationMemberSchedules,
  getOrganizationSchedules,
  getAssignOrganizationSchedules,
} = usePlans();
const { data: planMapsSource, mutate: getPlanMapMutate } = getPlanMaps(
  selectedOrganization,
  isOwner,
  selectedDate
);

const planMaps = ref<PlanMap>({
  absenceApplicationPlans: [],
  workAsPlans: [],
  workScheduleAsPlans: [],
  organizationMemberSchedulePlans: [],
  organizationSchedulePlans: [],
  assignOrganizationSchedulePlans: [],
});

watch(planMapsSource, (pms) => {
  if (!isOwner.value && pms) {
    planMaps.value = pms;
  }
});

const {
  data: absenceApplicationPlans,
  mutate: getAbsenceApplicationPlansMutate,
} = getAbsenceApplicationPlans(selectedOrganization, selectedDate, isOwner);

watch(absenceApplicationPlans, (pms) => {
  if (isOwner.value && pms) {
    planMaps.value.absenceApplicationPlans = pms;
  }
});

const { data: workScheduleAsPlans, mutate: getWorkScheduleAsPlansMutate } =
  getWorkScheduleAsPlans(selectedOrganization, selectedDate, isOwner);

function embedWorkSchedule(
  plans:
    | WorkAsPlansLight[]
    | OrganizationMemberSchedulePlanLight[]
    | OrganizationSchedulePlanLight[],
  workScheduleAsPlans?: WorkScheduleAsPlan[]
) {
  if (!plans) return [];

  return (
    plans.map((p) => {
      return {
        ...p,
        workSchedules: p.workScheduleIds
          .map((sid) => workScheduleAsPlans?.find((ws) => ws.id === sid))
          .filter((b) => b),
      };
    }) || []
  );
}

const { data: workAsPlans } = getWorkAsPlans(
  selectedOrganization,
  selectedDate,
  isOwner
);

watch(workAsPlans, (wap) => {
  if (isOwner.value && wap) {
    planMaps.value.workAsPlans = embedWorkSchedule(
      wap,
      workScheduleAsPlans.value
    );
  }
});

const {
  data: organizationMemberSchedulePlans,
  mutate: getOrganizationMemberSchedulesMutate,
} = getOrganizationMemberSchedules(selectedOrganization, selectedDate, isOwner);

watch(organizationMemberSchedulePlans, (omsp) => {
  if (isOwner.value && omsp) {
    planMaps.value.organizationMemberSchedulePlans = embedWorkSchedule(
      omsp,
      workScheduleAsPlans.value
    );
  }
});

const {
  data: organizationSchedulePlans,
  mutate: getOrganizationSchedulesMutate,
} = getOrganizationSchedules(selectedOrganization, selectedDate, isOwner);

watch(organizationSchedulePlans, (osp) => {
  if (isOwner.value && osp) {
    planMaps.value.organizationSchedulePlans = embedWorkSchedule(
      osp,
      workScheduleAsPlans.value
    );
  }
});

const {
  data: assignOrganizationSchedulePlans,
  mutate: getAssignOrganizationSchedulesMutate,
} = getAssignOrganizationSchedules(selectedOrganization, selectedDate, isOwner);

watch(assignOrganizationSchedulePlans, (osp) => {
  if (isOwner.value && osp) {
    planMaps.value.assignOrganizationSchedulePlans = embedWorkSchedule(
      osp,
      workScheduleAsPlans.value
    );
  }
});

watch(workScheduleAsPlans, (wsp) => {
  if (isOwner.value && wsp) {
    planMaps.value.workScheduleAsPlans = wsp;

    planMaps.value.workAsPlans = embedWorkSchedule(
      workAsPlans.value,
      workScheduleAsPlans.value
    );

    planMaps.value.organizationMemberSchedulePlans = embedWorkSchedule(
      organizationMemberSchedulePlans.value,
      workScheduleAsPlans.value
    );

    planMaps.value.organizationSchedulePlans = embedWorkSchedule(
      organizationSchedulePlans.value,
      workScheduleAsPlans.value
    );

    planMaps.value.assignOrganizationSchedulePlans = embedWorkSchedule(
      assignOrganizationSchedulePlans.value,
      workScheduleAsPlans.value
    );
  }
});

// owner mode

const ownerMode = ref<boolean>(false);

const existsDates = computed(() => {
  if (!workRecords.value) {
    return [];
  }

  const startOn = "2023-06-01"; // Log 始動月
  const endOn = today.plus({ month: 1 }).toISO(); // 次の月は見れるように?

  if (!endOn) {
    throw new Error("cannot get today");
  }

  const range = createIsoRange(startOn, endOn, "month");

  return range;
});

// as worked

async function handleAsWorked(prms: {
  organizationMemberId: number;
  targetDate: string;
}) {
  if (await asWorked(prms)) {
    alert("変更に成功しました。");
  } else {
    alert("変更に失敗しました");
  }
}

// pdf

function generateAttendancePDF() {
  let filename: string = "";

  if (!selectedOrganizationMember.value) return;

  filename = `出勤簿${selectedOrganizationMember.value.user.name}`;

  filename = filename + "_" + basicFormatter(today, "noYear") + ".pdf";

  generatePDF("pdf-contents", filename);
}
</script>

<style scoped></style>
