export function roundAtPrecision(num: number, precision: number): number {
  if (precision < 0) {
    return num;
  }

  const factor = Math.pow(10, precision);
  return Math.round(num * factor) / factor;
}

if (import.meta.vitest) {
  const { expect, it, describe } = import.meta.vitest;

  describe("roundAtPrecision", () => {
    it("should round number to the nearest integer when precision is 0", () => {
      expect(roundAtPrecision(4.6, 0)).toEqual(5);
      expect(roundAtPrecision(4.4, 0)).toEqual(4);
    });

    it("should round number correctly when precision is positive", () => {
      expect(roundAtPrecision(4.1234, 1)).toEqual(4.1);
      expect(roundAtPrecision(4.1234, 2)).toEqual(4.12);
      expect(roundAtPrecision(4.1234, 3)).toEqual(4.123);
    });

    it("should not change number when precision is negative", () => {
      expect(roundAtPrecision(4.1234, -1)).toEqual(4.1234);
    });
  });
}
