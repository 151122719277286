<template>
  <div>
    <table class="table table-sm table-bordered table-striped">
      <tbody>
        <template v-for="column in columns" :key="column">
          <tr v-if="!['configs'].includes(column)">
            <th class="cell-column-name sticky">
              {{ columnToStr(column) }}
            </th>
            <td
              v-for="item in basisReportItems"
              :key="item.id"
              class="cell-item-column"
              role="button"
              @click="$emit('selectItem', item.id)"
            >
              <span v-if="column === 'visible'">
                <i v-if="item[column]" class="pi pi-eye text-success"></i>
                <i v-else class="pi pi-eye-slash text-danger"></i>
              </span>
              <span v-else-if="column === 'groupId'">
                {{ groupMap[item[column]]?.name }}
              </span>
              <span v-else>{{ item[column] }}</span>
            </td>
          </tr>
        </template>
        <tr>
          <th class="cell-column-name sticky">条件</th>
          <td
            v-for="item in basisReportItems"
            :key="item.id"
            class="cell-item-column"
            role="button"
            @click="$emit('selectItem', item.id)"
          >
            <div
              v-for="cond in confisToConditions(item.configs)"
              class="text-wrap"
            >
              <Chip style="font-size: 0.5rem">{{
                cond.validTypes.join("/")
              }}</Chip>
              <div style="font-size: 0.5rem">{{ cond.carriers.join("/") }}</div>
              <div style="font-size: 0.5rem">
                {{ cond.categories.join("/") }}
              </div>
              <div style="font-size: 0.5rem">
                {{ cond.requestOrganizations.join("/") }}
              </div>
            </div>
          </td>
        </tr>
        <tr v-if="showOrder">
          <th class="cell-column-name sticky">順序</th>
          <td
            v-for="(item, idx) in basisReportItems"
            :key="`order-${item.id}`"
            class="cell-item-column"
          >
            <div
              class="d-flex align-items-center justify-content-center cell-item-column"
            >
              <i
                class="pi pi-angle-left me-2"
                role="button"
                @click="changeOrder({ idx, direction: 'left' })"
              ></i>
              <i
                class="pi pi-angle-right ms-2"
                role="button"
                @click="changeOrder({ idx, direction: 'right' })"
              ></i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { translater } from "/@/modules/string";
import { columnToStr } from "/@/modules/workReport";
import {
  BasisReportItemScheme,
  BasisReportItemKey,
  BasisReportItemClient,
  BasisReportItemGroupClient,
  Organization,
} from "/@/types";
import Chip from "primevue/chip";

const props = defineProps<{
  basisReportItems: BasisReportItemClient[];
  basisReportItemGroups: BasisReportItemGroupClient[];
  requestOrganizations: Organization[];
  showOrder?: boolean;
}>();

const emit = defineEmits<{
  (e: "selectItem", id: number): void;
  (e: "changeOrder", prms: { idx: number; direction: "left" | "right" }): void;
}>();

const columns = Object.keys(BasisReportItemScheme.shape).filter(
  (k) => !["id", "organizationId", "order", "visible"].includes(k)
) as BasisReportItemKey[];

const groupMap = computed(() => {
  return props.basisReportItemGroups.reduce((acc, cur) => {
    acc[cur.id] = cur;
    return acc;
  }, {} as Record<number, BasisReportItemGroupClient>);
});

function changeOrder(prms: { idx: number; direction: "left" | "right" }) {
  emit("changeOrder", prms);
}

function confisToConditions(configs: BasisReportItemClient["configs"]) {
  return configs.map((c) => {
    let validTypes = [];
    if (c.individualCustomers) {
      validTypes.push("個別応対");
    }
    if (c.overallCustomers) {
      validTypes.push("全体応対");
    }
    if (c.individual) {
      validTypes.push("個別実績");
    }
    if (c.overall) {
      validTypes.push("全体実績");
    }

    return {
      validTypes,
      carriers: c.workCarriers.map((c) => translater(c)),
      categories: c.workCategories.map((c) => translater(c)),
      requestOrganizations: c.requestOrganizationIds.map(
        (id) => props.requestOrganizations.find((o) => o.id === id)?.name
      ),
    };
  });
}
</script>

<style scoped>
.cell-column-name {
  max-width: 100px;
  min-width: 100px;
}

.cell-item-column {
  max-width: 100px;
  min-width: 100px;
}
</style>
