import { Carrier } from "../types";

export function carrierCompareFn(a: Carrier, b: Carrier) {
  if (a === "docomo") {
    return -1;
  }

  if (b === "docomo") {
    return 1;
  }

  if (a === "softbank") {
    return -1;
  }

  if (b === "softbank") {
    return 1;
  }

  if (a === "au") {
    return -1;
  }

  if (b === "au") {
    return 1;
  }

  if (a === "yahoo") {
    return -1;
  }

  if (b === "yahoo") {
    return 1;
  }

  if (a === "uq") {
    return -1;
  }

  if (b === "uq") {
    return 1;
  }

  return 0;
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  it("luxonToPickerObject", () => {
    expect(carrierCompareFn("docomo", "softbank")).toStrictEqual(-1);
    expect(["softbank", "docomo"].sort(carrierCompareFn)).toStrictEqual([
      "docomo",
      "softbank",
    ]);

    expect(
      ["yahoo", "au", "softbank", "docomo"].sort(carrierCompareFn)
    ).toStrictEqual(["docomo", "softbank", "au", "yahoo"]);
  });
}
