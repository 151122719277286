import i18next from "i18next";
import zod from "zod";
import { zodI18nMap } from "zod-i18n-map";
import translation from "zod-i18n-map/locales/ja/zod.json";

export function zodInit() {
  i18next.init({
    lng: "ja",
    resources: {
      ja: { zod: translation },
    },
    interpolation: {
      skipOnVariables: false,
    },
  });

  zod.setErrorMap(zodI18nMap);

  return zod;
}

export const z = zodInit();

export type ZodFormattedErrors = {
  [key in string]?: {
    _errors: string[];
  };
};

export function getZodErrors(
  scheme: zod.ZodSchema,
  target: Object,
  afterSubmit: boolean | undefined
) {
  if (!afterSubmit) {
    return {};
  }

  const parsed = scheme.safeParse(target);

  if (parsed.success) {
    return {};
  } else {
    const formattedError = parsed.error.format();
    return formattedError;
  }
}

export function getErrorMessagesFromErrors(
  errors: ZodFormattedErrors,
  target: string
) {
  const keyError = errors[target];

  if (keyError && keyError._errors.length) {
    return keyError._errors;
  } else if (keyError) {
    return ["has_nested_error"];
  } else {
    return [];
  }
}

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;

  it("getErrorMessageFromErrors", () => {
    expect(
      getErrorMessagesFromErrors({ name: { _errors: ["必須"] } }, "name")
    ).toStrictEqual(["必須"]);
    expect(getErrorMessagesFromErrors({}, "name")).toStrictEqual([]);
  });
}

// bisic schemes

export const DateScheme = z.string().regex(/^\d{4}-\d{2}-\d{2}$/);
export const DateTimeScheme = z
  .string()
  .datetime({ offset: true })
  .or(DateScheme);
