<template>
  <div>
    <PageHeader page-title="アカウント情報" hide-back-btn />
    <template v-if="user">
      <BasicInfo
        class="p-2"
        :name="user.name"
        :current-email="user.email"
        :success-update-email="successUpdateEmail"
        :loading="loading"
        @update-email="updateEmail"
      />
      <PasswordCheck
        class="p-2"
        :check-count="user.passwordCheckCount || 0"
        :valid-password="checkStatus.status"
        :loading="loading"
        @check-password="checkUserPassword"
        @reset-password="resetUserPassword"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import useSWRV from "swrv";
import { ref, watch } from "vue";
import { fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import { useAuthorize, useUser } from "/@/vue/composables";
import { PageHeader } from "/@/vue/components/Layouts";
import { BasicInfo, PasswordCheck } from "/@/vue/components/Organisms";
import type { User } from "/@/types";

const {
  data: user,
  error: getUserError,
  mutate: getUserMutate,
} = useSWRV<User>("/api/v1/current_user", fetcher);
const { updateUser, checkPassword, resetPassword } = useUser();

watch(getUserError, (e) => {
  errorHandle(e);
});

// update email

const loading = ref<boolean>(false);
const successUpdateEmail = ref<boolean>(false);

async function updateEmail(email: string) {
  loading.value = true;

  if (await updateUser({ email })) {
    successUpdateEmail.value = true;
  }

  loading.value = false;
}

// check password

const checkStatus = ref<{
  status: boolean;
  currentCount?: number;
  errorCode?: "over3Count";
}>({
  currentCount: undefined,
  status: false,
  errorCode: undefined,
});

async function checkUserPassword(password: string | undefined) {
  try {
    checkStatus.value = await checkPassword(password);
  } catch (e) {
    errorHandle(e, { unexpected: true });
  }

  getUserMutate();
}

// reset password

const { logout } = useAuthorize();

async function resetUserPassword() {
  loading.value = true;
  try {
    await resetPassword();
    logout();
  } catch (e) {
    errorHandle(e);
  }
  loading.value = false;
}
</script>

<style scoped></style>
