<template>
  <Modal
    ref="modal"
    :title="title"
    :submit-text="submitText"
    :submit-text-variant="submitTextVariant"
    :cancel-text="cancelText"
    :only-ok="onlyOk"
    @submit="submit"
    @cancel="cancel"
  >
    {{ confirmText ? confirmText : "よろしいですか?" }}
  </Modal>
</template>

<script setup lang="ts">
import Modal from "./SimpleModal.vue";
import mitt, { Handler } from "mitt";
import { ref } from "vue";

withDefaults(
  defineProps<{
    title: string;
    submitText?: string;
    cancelText?: string;
    onlyOk?: boolean;
    submitTextVariant?: "danger" | "primary";
  }>(),
  {
    submitText: undefined,
    cancelText: undefined,
    submitTextVariant: undefined,
  }
);

const modal = ref();
const emitter = mitt();

const confirmText = ref<string>();

async function confirm(text: string) {
  confirmText.value = text;
  modal.value.openModal();

  return new Promise((resolve) => {
    const callback: Handler = (confirmValue: boolean) => {
      modal.value.closeModal();
      resolve(confirmValue);
    };
    return emitter.on("answerdConfirm", callback);
  });
}

function submit() {
  emitter.emit("answerdConfirm", true);
  modal.value.closeModal();
}

function cancel() {
  emitter.emit("answerdConfirm", false);
  modal.value.closeModal();
}

defineExpose({
  confirm,
});
</script>

<style lang="scss" scoped>
.modal-body {
  white-space: pre-wrap;
}
</style>
