<template>
  <div>
    <AdminWorkPlaceListFilter
      v-model:selected-prefectures="selectedPrefectures"
      v-model:selected-carriers="selectedCarriers"
      :work-places="workPlaces"
      class="mb-3"
    />
    <div class="table-wrapper">
      <table class="table table-sm table-bordered table-striped">
        <thead>
          <tr>
            <th class="cell-name">名前</th>
            <th class="cell-short-name">短縮表記</th>
            <th class="cell-area">エリア</th>
            <th class="cell-carrier">キャリア</th>
            <th class="cell-request-organization">管理組織</th>
            <th class="cell-place-category">業務種別</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="wp in filteredWorkPlaces"
            :key="`work-place-list-item-${wp.id}`"
            @click="openWorkPlaceModal(wp.id)"
          >
            <td class="cell-name">{{ wp.name }}</td>
            <td class="cell-short-name">
              {{ wp.shortName || wp.name.slice(0, 3) }}
            </td>
            <td class="cell-area">{{ translater(wp.prefecture) }}</td>
            <td class="cell-carrier">{{ translater(wp.placeSubCategory) }}</td>
            <td class="cell-request-organization">{{ wp.organizationName }}</td>
            <td class="cell-place-category">
              {{ translater(wp.placeCategory) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <teleport to="body">
      <AdminWorkPlaceForm
        ref="updateWorkPlaceModal"
        :work-places="workPlaces"
        :organizations="shopOwnerOrganizations"
        @submit="$emit('updateWorkPlace', $event)"
      />
    </teleport>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { translater } from "/@/modules/string";

import AdminWorkPlaceForm from "./AdminWorkPlaceForm.vue";
import AdminWorkPlaceListFilter from "./AdminWorkPlaceListFilter.vue";

import type {
  WorkPlace,
  Organization,
  WorkPlaceUpdate,
  Prefecture,
  Carrier,
} from "/@/types";

const props = defineProps<{
  workPlaces: WorkPlace[];
  shopOwnerOrganizations: Organization[];
}>();

defineEmits<{
  (e: "updateWorkPlace", prms: WorkPlaceUpdate): void;
}>();

// filter

const selectedPrefectures = ref<Prefecture[]>(["ishikawa"]);
const selectedCarriers = ref<Carrier[]>(["docomo"]);

const filteredWorkPlaces = computed(() => {
  return props.workPlaces.filter(
    (wp) =>
      selectedPrefectures.value.includes(wp.prefecture) &&
      selectedCarriers.value.includes(wp.placeSubCategory)
  );
});

// modal difinissions

const updateWorkPlaceModal = ref();

function openWorkPlaceModal(id: number) {
  updateWorkPlaceModal.value.openModal(id);
}
</script>

<style scoped lang="scss">
.table-wrapper {
  overflow: scroll;

  .cell-name {
    max-width: 150px;
    min-width: 150px;
  }

  .cell-short-name {
    max-width: 50px;
    min-width: 50px;
  }

  .cell-carrier {
    max-width: 50px;
    min-width: 50px;
  }

  .cell-area {
    max-width: 50px;
    min-width: 50px;
  }

  .cell-request-organization {
    max-width: 150px;
    min-width: 150px;
  }

  .cell-place-category {
    max-width: 50px;
    min-width: 50px;
  }
}
</style>
