import useSWRV from "swrv";
import { Ref, isRef } from "vue";
import { axios, fetcher } from "/@/modules/axios";
import { fromISO } from "/@/modules/luxon";
import { createQueryParams } from "/@/modules/url";
import type {
  DateMap,
  Organization,
  OrganizationMember,
  OrganizationMemberForm,
  SubmitLocationForm,
  UserActionStatus,
} from "/@/types";
import { errorHandle } from "/@/modules/error";

function createDateParams(dateMap?: DateMap) {
  if (!dateMap) return undefined;

  return (
    dateMap &&
    createQueryParams({
      year: dateMap.year.toString(),
      month: dateMap.month.toString(),
      dayNumber: dateMap.day ? fromISO(dateMap.day).day.toString() : undefined,
    })
  );
}

export function useOrganizationMember() {
  function getOrganizationMemberKeyFn(
    organization: Ref<Organization | undefined>,
    isOwner?: Ref<boolean | undefined>,
    selectedDate?: Ref<DateMap> | DateMap,
    options: {
      withLaborInfo?: boolean;
      withTodayWorkRecords?: boolean;
      needAll?: boolean;
    } = {
      withLaborInfo: false,
      withTodayWorkRecords: false,
      needAll: false,
    }
  ) {
    const { withLaborInfo, withTodayWorkRecords, needAll } = options;

    if (!organization.value) {
      return null;
    }

    let uri =
      "/api/v1/organization_members?organization_id=" + organization.value.id;

    if (isOwner && isOwner.value) {
      uri += "&with_today_work_records=true"; // TODO: isOwner ではなく必要な場所では options を使うように
    }

    if (withLaborInfo) {
      uri += "&with_labor_info=true";
    }

    if (needAll) {
      uri += "&need_all=true";
    }

    if (
      (selectedDate && !isRef(selectedDate)) ||
      (selectedDate && isRef(selectedDate) && selectedDate.value)
    ) {
      const params = createDateParams(
        isRef(selectedDate) ? selectedDate.value : selectedDate
      );
      uri += `&${params}`;
    }

    return uri;
  }

  function getOrganizationMembers(
    organization: Ref<Organization | undefined>,
    isOwner?: Ref<boolean>,
    selectedDate?: Ref<DateMap> | DateMap,
    options: {
      withLaborInfo?: boolean;
      withTodayWorkRecords?: boolean;
      revalidateOnFocus?: boolean;
      needAll?: boolean;
    } = {
      withLaborInfo: false,
      withTodayWorkRecords: false,
      revalidateOnFocus: true,
      needAll: false,
    }
  ) {
    const { revalidateOnFocus } = options;

    return useSWRV<OrganizationMember[]>(
      () =>
        getOrganizationMemberKeyFn(
          organization,
          isOwner,
          selectedDate,
          options
        ),
      fetcher,
      {
        revalidateOnFocus,
      }
    );
  }

  async function updateOrganizationMember(form: OrganizationMemberForm) {
    try {
      const { data }: { data: Organization } = await axios.patch(
        `/api/v1/organization_members/${form.id}`,
        {
          organization_members: form,
        }
      );
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  // action status

  function getActionStatusKey(organization: Ref<Organization | undefined>) {
    if (!organization.value) {
      return null;
    }

    return `/api/v1/organization_members/${organization.value.organizationMemberId}/action_status`;
  }

  function getActionStatus(
    selectedOrganization: Ref<Organization | undefined>
  ) {
    return useSWRV<UserActionStatus>(
      () => getActionStatusKey(selectedOrganization),
      fetcher
    );
  }

  // submit location

  async function submitLocation(id: number, form: SubmitLocationForm) {
    try {
      await axios.post(`/api/v1/organization_members/${id}/current_locations`, {
        currentLocations: form,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    getOrganizationMembers,
    updateOrganizationMember,
    getActionStatus,
    submitLocation,
  };
}
