<template>
  <div>
    <CollapseWrapper contents-id="losted-tasks-table" card-header class="card">
      <template #header>{{ selectedDate.month }}月戻り成約詳細</template>
      <template #contents>
        <div class="table-wrapper">
          <table
            class="table table-sm table-responsive table-bordered table-striped"
          >
            <thead>
              <tr>
                <th rowspan="2">稼動日</th>
                <th rowspan="2">成約日</th>
                <ReportItemsHeaderTop
                  :category-carrier-keys="categoryCarrierKeys"
                  :customers-items="customersItems"
                  :indivi-items="indiviItems"
                  :overall-items="overallItems"
                  :hide-map="{ customers: {}, indivi: {}, overall: {} }"
                  :show-edit-mode="false"
                  :shorter-mode="shorterMode"
                />
              </tr>
              <tr>
                <ReportItemsHeaderBottom
                  :category-carrier-keys="categoryCarrierKeys"
                  :customers-items="customersItems"
                  :indivi-items="indiviItems"
                  :overall-items="overallItems"
                  :basis-report-items="basisReportItems"
                  :hide-map="{ customers: {}, indivi: {}, overall: {} }"
                  :show-edit-mode="false"
                  :shorter-mode="shorterMode"
                />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="t of targetTasks"
                :key="`contracted-tasks-table-${t.id}`"
              >
                <td>{{ basicFormatter(t.createdAt, "onlyDateDay") }}</td>
                <td>{{ basicFormatter(t.contractedAt, "onlyDateDay") }}</td>
                <ReportItemsTds
                  :category-carrier-keys="categoryCarrierKeys"
                  :customers-items="customersItems"
                  :indivi-items="indiviItems"
                  :overall-items="overallItems"
                  :tasks="contractedTasks"
                  :work-reports="[]"
                  :hide-map="{}"
                  :show-edit-mode="false"
                />
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </CollapseWrapper>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { basicFormatter, isSameMonth } from "/@/modules/luxon";
import { removeDupStrict } from "/@/modules/array";
import {
  basisToReport,
  categoryCarrierPairToKey,
  getReportItemsNew,
} from "/@/modules/workReport";
import { CollapseWrapper } from "/@/vue/components/Molecules";
import {
  CategoryCarrierReportItemsMap,
  DateMap,
  WorkTaskClient,
  BasisReportItemClient,
  WorkCategory,
  Carrier,
} from "/@/types";

import ReportItemsHeaderBottom from "./ReportItemsHeaderBottom.vue";
import ReportItemsHeaderTop from "./ReportItemsHeaderTop.vue";
import ReportItemsTds from "./ReportItemsTds.vue";

const props = defineProps<{
  contractedTasks: WorkTaskClient[];
  basisReportItems: BasisReportItemClient[];
  selectedDate: DateMap;
  shorterMode?: boolean;
}>();

const targetTasks = computed(() => {
  return props.contractedTasks.filter((task) =>
    isSameMonth(props.selectedDate, task.createdAt)
  );
});

function getCategoryCarrierPairsFromTasks(workTasks: WorkTaskClient[]) {
  return removeDupStrict(
    workTasks.map((task) => ({
      category: task.entryWorkCategory,
      carrier: task.entryWorkCarrier,
    }))
  );
}

const workTypeCategories = computed(() => {
  const sample = props.contractedTasks.at(0);

  if (!sample) return [];

  return sample.entryWorkTypeCategories;
});

const categoryCarrierPairs = computed(() => {
  return getCategoryCarrierPairsFromTasks(props.contractedTasks);
});

const categoryCarrierKeys = computed(() => {
  return categoryCarrierPairs.value.map((pair) =>
    categoryCarrierPairToKey(pair)
  );
});

/*
const targetItems = computed<CategoryCarrierReportItemsMap>(() => {
  let m: CategoryCarrierReportItemsMap = {};

  categoryCarrierPairs.value.forEach((p) => {
    m[categoryCarrierPairToKey(p)] = getReportItems({
      workTypeCategories: workTypeCategories.value,
      carrier: p.carrier,
      workCategory: p.category,
    });
  });

  return m;
});
*/

function optionCreater({
  workCategory,
  workCarrier,
  overall,
  individual,
  overallCustomers,
  individualCustomers,
}: {
  workCategory: WorkCategory;
  workCarrier: Carrier;
  overall?: boolean;
  individual?: boolean;
  overallCustomers?: boolean;
  individualCustomers?: boolean;
}) {
  return {
    workTypeCategories: workTypeCategories.value,
    workCategory,
    workCarrier,
    overall,
    individual,
    overallCustomers,
    individualCustomers,
  };
}

const customersItems = computed<CategoryCarrierReportItemsMap>(() => {
  let m: CategoryCarrierReportItemsMap = {};

  categoryCarrierPairs.value.forEach((p) => {
    const options = optionCreater({
      workCategory: p.category,
      workCarrier: p.carrier,
      individualCustomers: true,
    });

    m[categoryCarrierPairToKey(p)] = getReportItemsNew(
      props.basisReportItems,
      options
    ).map((item) => basisToReport(item, options));
  });

  return m;
});

const indiviItems = computed<CategoryCarrierReportItemsMap>(() => {
  let m: CategoryCarrierReportItemsMap = {};

  categoryCarrierPairs.value.forEach((p) => {
    const options = optionCreater({
      workCategory: p.category,
      workCarrier: p.carrier,
      individual: true,
    });

    m[categoryCarrierPairToKey(p)] = getReportItemsNew(
      props.basisReportItems,
      options
    ).map((item) => basisToReport(item, options));
  });

  return m;
});

const overallItems = computed<CategoryCarrierReportItemsMap>(() => {
  let m: CategoryCarrierReportItemsMap = {};

  categoryCarrierPairs.value.forEach((p) => {
    const options = optionCreater({
      workCategory: p.category,
      workCarrier: p.carrier,
      overall: true,
    });

    m[categoryCarrierPairToKey(p)] = getReportItemsNew(
      props.basisReportItems,
      options
    ).map((item) => basisToReport(item, options));
  });

  return m;
});
</script>

<style scoped>
.table-wrapper {
  overflow-x: scroll;
  max-height: 100%;
}
</style>
