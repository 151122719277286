<template>
  <div>
    <SelectButton
      v-model="selectedMode"
      :options="['全て', '業績条件表示']"
      class="mb-3 me-2"
      :allow-empty="false"
    />
    <AdminBasisReportItemsList
      v-if="selectedMode === '業績条件表示'"
      :basis-report-items="basisReportItems || []"
      :basis-report-item-groups="basisReportItemGroups || []"
      :request-organizations="requestOrganizations || []"
      @select-report-item="selectReportItem"
      @submit-basis-report-item="handleSubmitBasisReportItem"
      @destroy-basis-report-item="handleDestroyBasisReportItem"
      @create-group="handleCreateGroup"
      @destroy-group="handleDestroyGroup"
      @change-order="handleChangeOrder"
    />
    <div v-if="selectedMode === '全て'">
      <div class="mb-3" style="width: 300px">
        <ContentLabel label="名前検索" />
        <InputForm v-model:value="nameSearchWord" is-valid />
      </div>

      <AdminBasisReportItemFilter
        v-model:selected-work-category="selectedWorkCategory"
        v-model:selected-work-carrier="selectedWorkCarrier"
        v-model:selected-request-organization-id="selectedRequestOrganizationId"
        :request-organizations="requestOrganizations || []"
        class="mb-3"
      />

      <div class="w-100" style="overflow-x: scroll">
        <AdminBasisReportItemListContent
          :basis-report-items="filteredBReportItems"
          :basis-report-item-groups="basisReportItemGroups || []"
          :request-organizations="requestOrganizations || []"
          class="mb-3"
          @select-item="openFormModal"
        />
      </div>
    </div>
    <teleport to="body">
      <AdminBasisReportItemFormModal
        ref="formModal"
        :basis-report-items="filteredBReportItems"
        :basis-report-item-groups="basisReportItemGroups || []"
        :request-organizations="requestOrganizations || []"
        @submit="handleSubmitBasisReportItem"
        @destroy="handleDestroyBasisReportItem"
        @create-group="$emit('createGroup', $event)"
        @destroy-group="$emit('destroyGroup', $event)"
      />
    </teleport>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import {
  useAdmin,
  useOrganization,
  useBasisReportItems,
} from "/@/vue/composables";
import { ContentLabel, InputForm } from "/@/vue/components/Atom";
import {
  AdminBasisReportItemListContent,
  AdminBasisReportItemsList,
  AdminBasisReportItemFormModal,
  AdminBasisReportItemFilter,
} from "/@/vue/components/Organisms";
import {
  BasisReportItem,
  WorkCategory,
  Carrier,
  Organization,
  OrganizationCategory,
} from "/@/types";
import SelectButton from "primevue/selectbutton";
import { isTargetConfig } from "/@/modules/workReport";

const selectedMode = ref<string>("全て");

const { getBasisReportItems, getBasisReportItemGroups } = useAdmin();
const { data: basisReportItems, mutate: getBasisReportItemsMutate } =
  getBasisReportItems();
const { data: basisReportItemGroups, mutate: getBasisReportItemGroupsMutate } =
  getBasisReportItemGroups();

const nameSearchWord = ref("");
const selectedWorkCategory = ref<WorkCategory>();
const selectedWorkCarrier = ref<Carrier>();
const selectedRequestOrganizationId = ref<number>();

const nameFilteredBReportItems = computed(() => {
  if (!basisReportItems.value) return [];
  if (nameSearchWord.value === "") return basisReportItems.value;

  return basisReportItems.value.filter((item) =>
    item.name.includes(nameSearchWord.value)
  );
});

const baseOptions = computed(() => ({
  workTypeCategories: ["telecommunications"] as OrganizationCategory[],
  workCategory: selectedWorkCategory.value,
  workCarrier: selectedWorkCarrier.value,
  requestOrganizationId: selectedRequestOrganizationId.value,
  isAdmin: true,
  overall: true,
  individual: true,
  overallCustomers: true,
  individualCustomers: true,
}));

const filteredBReportItems = computed(() => {
  return nameFilteredBReportItems.value.filter((item) =>
    item.configs.some((config) => isTargetConfig(config, baseOptions.value))
  );
});

function selectReportItem(id: number) {
  console.log(id);
}

const selectedOrganization = ref<Organization>();

const { getRequestOrganizations } = useOrganization();
const { data: requestOrganizations } =
  getRequestOrganizations(selectedOrganization);

const {
  updateBasisReportItem,
  destroyBasisReportItem,
  createBasisReportItem,
  createBasisReportItemGroup,
  destroyBasisReportItemGroup,
  changeOrderItem,
} = useBasisReportItems();

async function handleChangeOrder(prms: { id: number; order: number }) {
  if (await changeOrderItem(prms.id, prms.order)) {
    await getBasisReportItemsMutate();
  } else {
    alert("更新に失敗しました");
  }
}

async function handleSubmitBasisReportItem(form: BasisReportItem) {
  const handler = form.id ? updateBasisReportItem : createBasisReportItem;
  const handlerStr = form.id ? "更新" : "追加";

  if (await handler(form)) {
    alert(handlerStr + "しました");
    getBasisReportItemsMutate();
  } else {
    alert(handlerStr + "に失敗しました");
  }
}

async function handleDestroyBasisReportItem(id: number) {
  if (!window.confirm("削除しますか？")) return;

  if (await destroyBasisReportItem(id)) {
    alert("削除しました");
    getBasisReportItemsMutate();
  } else {
    alert("削除に失敗しました");
  }
}

async function handleCreateGroup(name: string) {
  if (await createBasisReportItemGroup(name)) {
    alert("追加しました");
    getBasisReportItemGroupsMutate();
  } else {
    alert("追加に失敗しました");
  }
}

async function handleDestroyGroup(id: number) {
  if (await destroyBasisReportItemGroup(id)) {
    alert("削除しました");
    getBasisReportItemGroupsMutate();
  } else {
    alert("削除に失敗しました");
  }
}

// modal

const formModal = ref();

function openFormModal(id?: number) {
  formModal.value?.openModal(id);
}
</script>

<style scoped lang="scss"></style>
