<template>
  <PMultiSelect
    :model-value="modelValue"
    :options="options"
    :option-label="optionLabel"
    :option-value="optionValue"
    display="chip"
    :placeholder="placeholder"
    :empty-filter-message="emptyFilterMessage || '候補がありません'"
    :show-toggle-all="false"
    :filter="filter"
    auto-filter-focus
    :class="{
      'without-border': withoutBorder,
      'without-trigger': withoutTrigger,
      'inherit-bg': withoutBg,
    }"
    @update:model-value="updateModelValue"
  />
</template>

<script setup lang="ts">
import PMultiSelect from "primevue/multiselect";

const props = defineProps<{
  modelValue: any[] | readonly any[];
  options: any[] | readonly any[];
  optionLabel?: string | ((option: any) => string);
  optionValue?: string | ((option: any) => string);
  placeholder?: string;
  emptyFilterMessage?: string;
  filter?: boolean;
  withoutAutoSort?: boolean;
  withoutBorder?: boolean;
  withoutTrigger?: boolean;
  withoutBg?: boolean;
  compareFn?: (a: any, b: any) => number;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: any[]): void;
}>();

function updateModelValue(value: any[]) {
  if (props.withoutAutoSort) {
    emit("update:modelValue", value);
  } else {
    emit(
      "update:modelValue",
      props.compareFn ? value.toSorted(props.compareFn) : value.toSorted()
    );
  }
}
</script>

<style scoped>
.p-multiselect.inherit-bg {
  background-color: inherit;
}
.p-multiselect.without-border {
  border: none;
}

.p-multiselect.without-trigger .p-multiselect-trigger {
  display: none;
}
</style>
