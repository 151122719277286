<template>
  <div>
    <PageHeader page-title="業務記録一覧" />

    <SelectDate
      :year="selectedDate.year"
      :month="selectedDate.month"
      :exists-dates="existsDates"
      only-year-month
      @update:year="updateSelectedDate({ year: $event })"
      @update:month="updateSelectedDate({ month: $event })"
    />

    <template v-if="organizationsData">
      <OrganizationSelector
        v-model:selected-organization-id="selectedOrganizationId"
        class="mb-3"
        :organizations="organizationsData"
      />
      <div class="mb-3">
        <ContentLabel label="メンバー" />
        <ObjectSelector
          :value="selectedOrganizationMemberId"
          :items="organizationMembers || []"
          value-key="id"
          :show-fn="memberShow"
          form-id="select-organization-member"
          is-valid
          @update:value="selectedOrganizationMemberId = $event?.id"
        />
      </div>
      <template v-if="selectedOrganizationMemberId">
        <WorkRecordDailyList
          class="w-100"
          :work-record-dailies="selectedMemberDailies"
          :organization-member-id="selectedOrganizationMemberId"
          :selected-date="selectedDate"
        />
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, watchEffect, onMounted } from "vue";
import { luxonNow, createIsoRange } from "/@/modules/luxon";
import { setStorage, getStorage } from "/@/modules/localStorage";
import { memberShowFn } from "/@/modules/organizationMember";
import {
  useOrganization,
  useOrganizationMember,
  useWorkRecords,
} from "/@/vue/composables";
import {
  ContentLabel,
  ObjectSelector,
  SelectDate,
} from "/@/vue/components/Atom";
import { PageHeader } from "/@/vue/components/Layouts";
import {
  WorkRecordDailyList,
  OrganizationSelector,
} from "/@/vue/components/Organisms";
import { OrganizationMember, DateMap } from "/@/types";

// organization

const {
  getOrganizations,
  organizations,
  subContractors,
  isOwner,
  selectedOrganizationId,
  selectedOrganization,
} = useOrganization();
const { data: organizationsData } = getOrganizations();

onMounted(() => {
  organizations.value = organizationsData.value || [];
});

watchEffect(() => {
  if (!organizationsData.value) return;

  organizations.value = organizationsData.value;
});

// organization members

const { getOrganizationMembers } = useOrganizationMember();
const { data: organizationMembers } = getOrganizationMembers(
  selectedOrganization,
  isOwner
);

const organizationMemberStorageKey = "WorkRecordDailiesOrganizationMemberId";

const selectedOrganizationMemberId = ref<number | undefined>(
  getStorage(organizationMemberStorageKey)
);

watch(selectedOrganizationMemberId, (id, oldId) => {
  if (id != oldId) {
    workRecordDailies.value = undefined;
    setStorage(organizationMemberStorageKey, id);
  }
});

function memberShow(member: OrganizationMember) {
  return memberShowFn(member, selectedOrganization.value, subContractors.value);
}

// date

const today = luxonNow();

const selectedDate = ref<DateMap>({
  year: today.year,
  month: today.month,
});

const existsDates = computed(() => {
  const startOn = "2023-06-01"; // Log 始動月
  const endOn = today.toISO(); // 次の月は見れるように?

  if (!endOn) {
    throw new Error("cannot get today");
  }

  const range = createIsoRange(startOn, endOn, "month");

  return range;
});

function updateSelectedDate({
  year,
  month,
}: {
  year?: number;
  month?: number;
}) {
  selectedDate.value = {
    year: year ? year : selectedDate.value.year,
    month: month ? month : selectedDate.value.month,
  };
}

// records

const { getWorkRecordDailies } = useWorkRecords();
const { data: workRecordDailies } = getWorkRecordDailies(
  selectedOrganization,
  selectedOrganizationMemberId,
  selectedDate,
  isOwner,
  () => !!selectedOrganizationMemberId.value
);
const selectedMemberDailies = computed(() => {
  if (!workRecordDailies.value || !selectedOrganizationMemberId.value)
    return undefined;

  return workRecordDailies.value.filter(
    (daily) => daily.organizationMemberId === selectedOrganizationMemberId.value
  );
});
</script>

<style scoped></style>
