import { Carrier, WorkCategory, Prefecture, Work } from "/@/types";
import { translater } from "/@/modules/string";

export function workToTitle(work: {
  [key: string]: any;
  requestOrganizaitonName?: string;
  workCategory: WorkCategory;
  carrier: Carrier;
  prefectures?: Prefecture[];
  workPlaceName?: string;
}) {
  const {
    workCategory,
    carrier,
    prefectures,
    requestOrganizationName,
    workPlaceName,
  } = work;

  const base = `${translater(carrier)}/${workPlaceName || ""}${translater(
    workCategory,
    true
  )} ${requestOrganizationName ? requestOrganizationName : ""}`;

  if (!prefectures) {
    return base;
  } else {
    return `${base} ${prefectures.map((p) => translater(p)).join("・")}`;
  }
}

function workPlacesOrRequestOrganization(work: Work) {
  const wps = work.workPlaces;

  if (!wps) {
    return "店舗未設定";
  }

  return wps.length > 1
    ? work.requestOrganizationName
    : wps.map((wp) => wp.name).join("・");
}

export function workToListTitle(work: Work) {
  let title = ``;

  if (work.name) title += `${work.name} `;

  const wp = workPlacesOrRequestOrganization(work);

  title += `${translater(work.carrier, true).slice(0, 1)}${wp}`;

  if (wp !== work.requestOrganizationName) {
    title += ` ${work.requestOrganizationName}`;
  }

  return title;
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const work = {
    id: 11,
    workCategory: "rounder" as WorkCategory,
    carrier: "softbank" as Carrier,
    prefectures: ["ishikawa"] as Prefecture[],
    requestOrganizationName: "(株)ジョイテル",
  };

  it("workToTitle", () => {
    expect(workToTitle(work)).toBe("S/RD (株)ジョイテル 石川");
  });
}
