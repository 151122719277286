<template>
  <Datepicker
    ref="datepicker"
    :model-value="input"
    locale="ja"
    :format="format"
    :allowed-dates="allowedDates"
    :disabled-dates="disabledDates"
    :disabled="disabled"
    close-on-scroll
    :preview-format="() => ''"
    week-start="0"
    :auto-apply="autoApply"
    select-text="決定"
    cancel-text="キャンセル"
    :inline="inline"
    :time-picker="timePicker"
    :teleport-center="teleportCenter || isMobile"
    :multi-dates="multiDates"
    :minutes-grid-increment="minutesGridIncrement"
    :minutes-increment="minutesIncrement"
    :min-time="minTime"
    :max-time="maxTime"
    :max-date="maxDate"
    :min-date="minDate"
    :start-time="startTime"
    :class="`${pickerClass} ${timePicker ? 'dp__time-picker' : ''} ${
      withoutBorder ? 'no-border' : ''
    }`"
    :flow="flow"
    :state="state"
    :range="range"
    :month-change-on-scroll="false"
    :input-class-name="inputClassName"
    @update:model-value="updateValue"
    @cleared="$emit('update:input', undefined)"
    @update-month-year="handleUpdateMonthYear"
  >
    <template #calendar-header="{ index, day }">
      <div :class="indexClass(index)">{{ day }}</div>
    </template>
    <template #day="{ index, day }">
      <div :class="indexClass(index)">{{ index }} {{ day }}</div>
    </template>
    <template v-if="timePicker" #input-icon>
      <div class="px-2 d-flex align-items-center justify-content-center">
        <ClockIcon />
      </div>
    </template>
  </Datepicker>
</template>

<script setup lang="ts">
import Datepicker from "@vuepic/vue-datepicker";
import { basicFormatter } from "/@/modules/luxon";
import { zeroPadding } from "/@/modules/string";
import { useMqUtils } from "/@/vue/composables";
import { ClockIcon } from "/@/vue/components/Atom";
import type { PickerTimeObject } from "/@/types/datepicker";
import { ref } from "vue";

type DatepickerFlow =
  | "month"
  | "year"
  | "calendar"
  | "time"
  | "minutes"
  | "hours"
  | "seconds";

const props = withDefaults(
  defineProps<{
    input?: PickerTimeObject | string | string[];
    maxDate?: Date;
    minDate?: Date;
    allowedDates?: Date[] | string[];
    disabledDates?: Date[] | string[];
    disabled?: boolean;
    inline?: boolean;
    readonly?: boolean;
    autoApply?: boolean;
    timePicker?: boolean;
    multiDates?: boolean;
    minutesGridIncrement?: string | number;
    minutesIncrement?: string | number;
    minTime?: PickerTimeObject;
    maxTime?: PickerTimeObject;
    pickerClass?: string;
    withTime?: boolean;
    flow?: DatepickerFlow[];
    startTime?: PickerTimeObject;
    inputClassName?: string;
    state?: boolean;
    range?: boolean;
    slashStyle?: boolean;
    withoutBorder?: boolean;
    teleportCenter?: boolean;
  }>(),
  {
    input: undefined,
    allowedDates: () => [],
    disabledDates: () => [],
    maxDate: undefined,
    minDate: undefined,
    minutesIncrement: "10",
    minutesGridIncrement: "10",
    startTime: () => {
      return { hours: new Date().getHours(), minutes: 0 };
    },
    minTime: undefined,
    maxTime: undefined,
    pickerClass: undefined,
    flow: undefined,
    inputClassName: "form-control",
  }
);

const emit = defineEmits<{
  (e: "update:input", v: PickerTimeObject | Date | Date[] | undefined): void;
  (e: "cleared"): void;
  (e: "updateMonthYear", prms: { month: number; year: number }): void;
}>();

const { isMobile } = useMqUtils();

function daypickerFormat(
  day: Date,
  options: { withoutYear?: boolean; slashStyle?: boolean } = {
    withoutYear: false,
    slashStyle: false,
  }
) {
  const { withoutYear, slashStyle } = options;

  const date = day.getDate();
  const month = day.getMonth() + 1;
  const year = day.getFullYear();
  const wday = basicFormatter(day, "onlyWday");

  const base = slashStyle ? `${month}/${date}` : `${month}月${date}日(${wday})`;

  if (withoutYear) {
    return base;
  } else {
    return slashStyle ? `${year}/` + base : `${year}年` + base;
  }
}

function daypickerFormatWithTime(day: Date) {
  const date = day.getDate();
  const month = day.getMonth() + 1;
  const year = day.getFullYear();
  const wday = basicFormatter(day, "onlyWday");
  const time = `${day.getHours()}:${zeroPadding(day.getMinutes(), 2)}`;
  return `${year}年${month}月${date}日(${wday}) ${time}`;
}

function format(day: PickerTimeObject | Date | Date[]) {
  const slashStyle = props.slashStyle; // TODO 別関数にする

  if (props.timePicker) {
    if (day instanceof Array) {
      return;
    }

    if (day instanceof Date) {
      return `${day.getHours()}:${zeroPadding(day.getMinutes(), 2)}`;
    }

    if (day.hours) {
      return `${day.hours}:${zeroPadding(day.minutes, 2)}`;
    }
  } else if (props.multiDates) {
    if (day instanceof Array) {
      return day.map((day) => daypickerFormat(day, { slashStyle })).join(", ");
    }

    return;
  } else if (props.range) {
    if (day instanceof Array) {
      const start = day.at(0);
      const finish = day.at(1);

      if (day.length != 2 || !start || !finish) {
        throw new Error(`invaild args when range DateFormat: ${day}`);
      }

      return `${daypickerFormat(start, {
        withoutYear: true,
        slashStyle,
      })} ~ ${daypickerFormat(finish, {
        withoutYear: true,
        slashStyle,
      })}`;
    }
  } else {
    if (props.withTime) {
      if (day instanceof Date) {
        return daypickerFormatWithTime(day);
      }
      return;
    } else {
      if (day instanceof Date) {
        return daypickerFormat(day, { slashStyle });
      }
    }
  }
}

function indexClass(index: number) {
  if (index === 0) {
    return {
      "text-danger": true,
    };
  } else if (index === 6) {
    return {
      "text-primary": true,
    };
  } else {
    return {};
  }
}

function updateValue(v: PickerTimeObject) {
  if (props.timePicker) {
    emit("update:input", v);
  } else {
    if (v instanceof Array) {
      v.sort((a, b) => a - b);
      emit("update:input", v);
    } else {
      emit("update:input", v);
    }
  }
}

function handleUpdateMonthYear(prms: {
  instance: number;
  month: number; // 0 origin
  year: number;
}) {
  const { month, year } = prms;

  emit("updateMonthYear", { month: month + 1, year });
}

// methods
const datepicker = ref();

function openMenu() {
  datepicker.value.openMenu();
}

defineExpose({
  openMenu,
});
</script>

<style scoped lang="scss"></style>

<style lang="scss">
.dp__time-picker {
  min-width: 100px;
}

.dp__selection_preview {
  display: none;
}

.dp__action_buttons {
  display: flex;
  flex-direction: row;
  width: 100% !important;
  justify-content: space-between !important;
  flex: auto !important;

  > * {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.dp__input_icon_pad.form-control {
  padding-left: 35px;
}

.dp__button.dp__btn {
  display: none !important;
}

.no-border {
  input {
    border: none;
  }

  .dp__input_valid {
    box-shadow: none;
  }
}
</style>
