import useSWRV from "swrv";
import { ComputedRef, Ref, computed, onMounted, ref, watch } from "vue";
import { axios, fetcher } from "/@/modules/axios";
import { isSubsetConfig } from "/@/modules/managementLabel";
import { errorHandle } from "/@/modules/error";
import { getStorage, setStorage } from "/@/modules/localStorage";
import {
  LabelType,
  ManagementLabelConfigClient,
  Organization,
  OrganizationMemberRole,
} from "/@/types";

export function useOrganization() {
  // composables

  const organizations = ref<Organization[]>([]);

  const selectedOrganizationId = ref<number | undefined>(
    getStorage("selectedOrganizationId")
  );

  watch(selectedOrganizationId, (id) => {
    setStorage("selectedOrganizationId", id);
  });

  onMounted(() => {
    selectedOrganizationId.value = getStorage("selectedOrganizationId");
  });

  const selectedOrganization = computed<Organization | undefined>(() => {
    return organizations.value.find(
      (o) => o.id === selectedOrganizationId.value
    );
  });

  // label

  function getLabels(
    organization: Organization | undefined,
    labelType: LabelType,
    config?: Partial<ManagementLabelConfigClient>
  ) {
    if (!organization || !organization.managementLabels) {
      return [];
    }

    return organization.managementLabels.filter(
      (l) =>
        l.labelType === labelType &&
        (!config || isSubsetConfig(config, l.config))
    );
  }

  function getLabel(
    organization: Organization | undefined,
    labelType: LabelType,
    config?: Partial<ManagementLabelConfigClient>
  ) {
    if (!organization || !organization.managementLabels) {
      return undefined;
    }

    return organization.managementLabels.find(
      (l) =>
        l.labelType === labelType &&
        (!config || isSubsetConfig(config, l.config))
    );
  }

  function hasLabel(
    organization: Organization | undefined,
    labelType: LabelType,
    config?: Partial<ManagementLabelConfigClient>
  ) {
    return !!getLabel(organization, labelType, config);
  }

  function asDepartmentOwner(organization: Organization | undefined) {
    return hasLabel(organization, "department_owner", { canShowMember: true });
  }

  function asCanShowMemberPlans(organization: Organization | undefined) {
    return hasLabel(organization, "department_owner", {
      canShowMember: true,
      canShowMemberPlans: true,
    });
  }

  function asCanShowWorkScheduleCalendar(
    organization: Organization | undefined
  ) {
    return hasLabel(organization, "department_owner", {
      canShowMemberWorkScheduleCalendar: true,
    });
  }

  function asCanShowWorkScheduleCalendarAdmin(
    organization: Organization | undefined
  ) {
    return hasLabel(organization, "department_owner", {
      canShowMemberWorkScheduleCalendarAdmin: true,
    });
  }

  function asCanShowWorkMeetingTarget(organization: Organization | undefined) {
    return hasLabel(organization, "request_organization", {
      canShowWorkMeetingTarget: true,
    });
  }

  const isOrganizationOwner = computed(() => {
    return asOwner(selectedOrganization.value);
  });

  const isDepartmentOwner = computed(() => {
    return asDepartmentOwner(selectedOrganization.value);
  });

  const isOwner = computed(() => {
    return isOrganizationOwner.value || isDepartmentOwner.value;
  });

  const isFRYTH = computed(() => {
    return selectedOrganization.value?.name === "(株)FRYTH";
  });

  const isFRYTHOwner = computed(() => {
    return isOrganizationOwner.value && isFRYTH.value;
  });

  const isWorker = computed(() => {
    return asWorker(selectedOrganization.value);
  });

  const isHomeworker = computed(() => {
    return asHomeworker(selectedOrganization.value);
  });

  const canShowMemberPlans = computed(() => {
    return (
      isOrganizationOwner.value ||
      asCanShowMemberPlans(selectedOrganization.value)
    );
  });

  const canShowMemberWorkScheduleCalendar = computed(() => {
    return (
      isDepartmentOwner.value &&
      asCanShowWorkScheduleCalendar(selectedOrganization.value)
    );
  });

  const canShowMemberWorkScheduleCalendarAdmin = computed(() => {
    return (
      isDepartmentOwner.value &&
      asCanShowWorkScheduleCalendarAdmin(selectedOrganization.value)
    );
  });

  const canShowWorkMeetingTarget = computed(() => {
    return (
      isFRYTHOwner.value ||
      asCanShowWorkMeetingTarget(selectedOrganization.value)
    );
  });

  const canShowWorkMeetingTargetRequestOrganizationIds = computed(() => {
    if (!canShowWorkMeetingTarget.value || !selectedOrganization.value)
      return [];

    return selectedOrganization.value.managementLabels
      ?.filter((label) => label.labelType === "request_organization")
      .map((label) => label.dependancyId);
  });

  const isAdmin = computed(() => {
    return isFRYTHOwner.value || canShowMemberWorkScheduleCalendarAdmin.value;
  });

  //

  const subContractors = computed(() => {
    if (!selectedOrganization.value || !isOwner.value) return [];

    return selectedOrganization.value.subContractors;
  });

  // swrv
  function getOrganizations(
    options: { revalidateOnFocus: boolean } = { revalidateOnFocus: true }
  ) {
    return useSWRV<Organization[]>(
      "/api/v1/current_user/organizations",
      fetcher,
      options
    );
  }

  function getRequestOrganizationsKey(
    organization:
      | Ref<Organization | undefined>
      | ComputedRef<Organization | undefined>
  ) {
    if (!organization.value) {
      // TODO;
    }

    const uri = "/api/v1/organizations/request_organizations";

    return uri;
  }

  function getRequestOrganizations(
    organization:
      | Ref<Organization | undefined>
      | ComputedRef<Organization | undefined>
  ) {
    return useSWRV<Organization[]>(
      () => getRequestOrganizationsKey(organization),
      fetcher
    );
  }

  // props

  function hasRole(
    organization: Organization | undefined,
    role: OrganizationMemberRole
  ) {
    if (!organization || !organization.roles) {
      return false;
    }

    return organization.roles.includes(role);
  }

  function asOwner(organization: Organization | undefined) {
    return hasRole(organization, "owner");
  }

  function asWorker(organization: Organization | undefined) {
    return hasRole(organization, "worker");
  }

  function asHomeworker(organization: Organization | undefined) {
    return (
      hasRole(organization, "developer") || hasRole(organization, "designer")
    );
  }

  // api

  async function updateAPI(
    organization: Organization
  ): Promise<Organization | undefined> {
    try {
      const { data }: { data: Organization } = await axios.patch(
        `/api/v1/organizations/${organization.id}`,
        {
          organizations: organization,
        }
      );
      return data;
    } catch (e) {
      errorHandle(e);
      return undefined;
    }
  }

  return {
    // composables
    organizations,
    subContractors,
    selectedOrganizationId,
    selectedOrganization,
    isOwner,
    isOrganizationOwner,
    isDepartmentOwner,
    isFRYTH,
    isFRYTHOwner,
    isWorker,
    isHomeworker,

    // label
    canShowMemberPlans,
    canShowMemberWorkScheduleCalendar,
    canShowMemberWorkScheduleCalendarAdmin,
    canShowWorkMeetingTarget,
    canShowWorkMeetingTargetRequestOrganizationIds,

    // swrv
    getOrganizations,
    getRequestOrganizations,

    // props
    asOwner,
    asWorker,
    asHomeworker,

    // label
    getLabel,
    getLabels,
    asDepartmentOwner,
    asCanShowMemberPlans,

    // util
    isAdmin,

    // api
    updateAPI,
  };
}
