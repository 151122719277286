<template>
  <div>
    <div
      v-if="!hideContractedToggle"
      class="d-flex align-items-center justify-content-end mb-2"
    >
      <SelectButton
        v-model="checkedStatus"
        :options="filterCheckItems"
        option-label="name"
        option-value="value"
        multiple
      />
    </div>
    <template v-if="showWorkTasks.length">
      <WorkTaskListItem
        v-for="(workTask, idx) of showWorkTasks"
        :key="workTask.id"
        :work-task="workTask"
        :basis-report-items="basisReportItems"
        :class="{
          'mt-2': idx !== 0,
        }"
        :is-owner="!!isOwner"
        :is-home="isHome"
        :is-selected="workTask.id === selectedId"
        :is-working="!!lastWorkRecordEntry"
        @select-work-task="selectWorkTask"
        @toggle-contract-work-task="
          workTask.contractedAt
            ? toggleContractWorkTask(workTask.id)
            : openContractModal($event)
        "
        @toggle-lost-work-task="
          workTask.lostedAt ? toggleLost({ id: $event }) : openLostModal($event)
        "
      />
    </template>
    <span v-else>なし</span>
    <teleport to="body">
      <SimpleModal
        ref="editModal"
        title="タスクの編集"
        submit-text="更新"
        :loading="loading"
        @submit="handleSubmit"
        @cancel="closeEditModal()"
      >
        <WorkTaskFormItem
          v-if="selectedWorkTask"
          :work-task="form"
          :entry="{
            workCarrier: selectedWorkTask.entryWorkCarrier,
            workCategory: selectedWorkTask.entryWorkCategory,
            workTypeCategories: selectedWorkTask.entryWorkTypeCategories,
          }"
          :basis-report-items="basisReportItems"
          :errors="errors"
          single-edit-mode
          :is-owner="isOwner"
          @update-work-task="handleUpdate"
          @destroy-work-task="handleDestroy"
        />
      </SimpleModal>

      <SimpleModal
        ref="selectModal"
        title="結果の反映日の選択"
        submit-text="決定"
        @submit="handleSelect"
        @cancel="closeSelectModal()"
      >
        <span>このタスクの結果を追加する日にちを選択してください。</span>
        <FormItemRadio
          v-model:value="countInContractedDate"
          label=""
          :items="countTargetItems"
          :errors="{}"
          form-id="count-in-contracted-date"
        />
      </SimpleModal>

      <SimpleModal
        ref="contractModal"
        title="タスクの成約"
        submit-text="成約"
        :loading="loading"
        @submit="handleContract"
        @cancel="closeContractModal()"
      >
        <WorkTaskFormItem
          v-if="selectedWorkTask"
          :work-task="form"
          :entry="{
            workCarrier: selectedWorkTask.entryWorkCarrier,
            workCategory: selectedWorkTask.entryWorkCategory,
            workTypeCategories: selectedWorkTask.entryWorkTypeCategories,
          }"
          :basis-report-items="basisReportItems"
          :errors="errors"
          single-edit-mode
          is-contract
          @update-work-task="handleUpdate"
          @destroy-work-task="handleDestroy"
        />
      </SimpleModal>

      <SimpleModal
        ref="lostModal"
        title="失注理由の選択"
        submit-text="決定"
        :loading="loading"
        @submit="handleLost"
        @cancel="closeLostModal"
      >
        <FormItemSelector
          v-model:value="selectedLostReason"
          :items="lostReasons"
          label="失注理由"
          form-id="lost-reason-selector"
          form-type="general"
          class="mb-3"
          :show-fn="translater"
          :errors="{}"
        />
        <FormItem
          v-if="selectedLostReason === 'other_reason'"
          v-model:value="lostReasonDetails"
          label="その他の内容"
          form-id="lost-reason-text"
          form-type="textarea"
          :errors="{}"
        />
      </SimpleModal>
    </teleport>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { translater } from "/@/modules/string";
import { workTaskEntryToTitle } from "/@/modules/workTask";
import { entryToTitle } from "/@/modules/workRecords";
import { basicFormatter, luxonNow } from "/@/modules/luxon";
import { errorHandle } from "/@/modules/error";
import { useZodScheme } from "/@/vue/composables";
import {
  SimpleModal,
  FormItem,
  FormItemRadio,
  FormItemSelector,
} from "/@/vue/components/Molecules";
import {
  workTaskStatus,
  workTaskTypes,
  lostReasons,
  WorkTaskScheme,
} from "/@/types";
import type {
  BasisReportItemClient,
  CheckItem,
  WorkTask,
  WorkTaskClient,
  WorkTaskForm,
  WorkTaskKey,
  WorkTaskStatus,
  WorkRecordEntryClient,
  LostReason,
} from "/@/types";
import WorkTaskListItem from "./WorkTaskListItem.vue";
import WorkTaskFormItem from "./WorkTaskFormItem.vue";
import SelectButton from "primevue/selectbutton";

const props = defineProps<{
  workTasks: WorkTaskClient[];
  basisReportItems: BasisReportItemClient[];
  lastWorkRecordEntry?: WorkRecordEntryClient;
  hideContractedToggle?: boolean;
  isOwner?: boolean;
  isHome?: boolean;
  selectedId?: number;
}>();

const emit = defineEmits<{
  (e: "updateWorkTask", form: WorkTask): void;
  (e: "destroyWorkTask", id: number): void;
  (
    e: "toggleContractWorkTask",
    prms: { id: number; countInContractedDate?: boolean }
  ): void;
  (
    e: "toggleLostWorkTask",
    prms: { id: number; lostReason?: LostReason; lostReasonDetails?: string }
  ): void;
  (e: "gotoWorkTasksIndex", id: number): void;
}>();

// modal ref

const editModal = ref();
const selectModal = ref();
const contractModal = ref();
const lostModal = ref();

// tasks

const filterCheckItems = computed<CheckItem[]>(() => {
  return workTaskStatus.map((k) => {
    return {
      value: k,
      name: translater(k),
    };
  });
});

const checkedStatus = ref<WorkTaskStatus[]>(["undone"]);

const showWorkTasks = computed(() => {
  return props.workTasks.filter((task) => {
    return (
      (checkedStatus.value.includes("contracted") && task.contractedAt) ||
      (checkedStatus.value.includes("losted") && task.lostedAt) ||
      (checkedStatus.value.includes("undone") &&
        !task.contractedAt &&
        !task.lostedAt)
    );
  });
});
// form

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } =
  useFormAndErrors<WorkTaskForm>(WorkTaskScheme);

const selectedWorkTask = ref<WorkTaskClient | undefined>();

function selectingWorkTask(id: number) {
  const selected = props.workTasks.find((workTask) => workTask.id === id);

  if (!selected) {
    throw new Error(`unexpected Id: ${id}. Please reload page.`);
  }

  selectedWorkTask.value = selected;
}

function updateFormBySelected() {
  if (!selectedWorkTask.value) {
    return;
  }

  form.id = selectedWorkTask.value.id;
  form.workTaskType = selectedWorkTask.value.workTaskType;
  form.actionAt = selectedWorkTask.value.actionAt;
  form.customerName = selectedWorkTask.value.customerName;
  form.phoneNumber = selectedWorkTask.value.phoneNumber;
  form.remarks = selectedWorkTask.value.remarks;
  form.reportItems = selectedWorkTask.value.reportItems;
  form.contractedAt = selectedWorkTask.value.contractedAt || undefined;
}

function openEditModal(id: number) {
  selectingWorkTask(id);
  updateFormBySelected();
  editModal.value.openModal();
}

function closeEditModal() {
  editModal.value.closeModal();
}

//

function selectWorkTask(id: number) {
  if (props.isHome) {
    emit("gotoWorkTasksIndex", id);
  } else {
    openEditModal(id);
  }
}

function handleUpdate(prms: { key: WorkTaskKey; value: any }) {
  const { key, value } = prms;

  if (key === "workTaskType" && workTaskTypes.includes(value)) {
    form[key] = value;
  } else if (key === "reportItems" && Array.isArray(value)) {
    form[key] = value;
  } else if (key === "phoneNumber" && typeof value === "string") {
    form[key] = value.slice(0, 4);
  } else if (key === "countInContractedDate" && typeof value === "boolean") {
    form[key] = value;
  } else if (
    key !== "reportItems" &&
    key !== "workTaskType" &&
    key !== "id" &&
    typeof value === "string"
  ) {
    form[key] = value;
  } else {
    throw new Error(
      `unexpected pattern in handleUpdate: key - ${key}, value - ${value}`
    );
  }
}

const loading = ref(false);

function handleSubmit() {
  try {
    loading.value = true;
    startValidation.value = true;
    const workTask: WorkTask = WorkTaskScheme.parse(form);
    emit("updateWorkTask", workTask);
    closeEditModal();
  } catch (e) {
    errorHandle(e);
  }
  loading.value = false;
}

function handleDestroy(id: number) {
  emit("destroyWorkTask", id);
  closeEditModal();
}

// select

function openSelectModal(id: number) {
  selectingWorkTask(id);

  selectModal.value.openModal();
}

function closeSelectModal() {
  selectModal.value.closeModal();
}

const countInContractedDate = ref(false);

const countTargetItems = computed(() => {
  if (!selectedWorkTask.value || !props.lastWorkRecordEntry) {
    return [];
  }

  return [
    {
      key: `予約取得日 ${basicFormatter(
        selectedWorkTask.value.createdAt,
        "noYear"
      )} (${workTaskEntryToTitle(selectedWorkTask.value)})`,
      value: false,
    },
    {
      key: `本日 (${entryToTitle(props.lastWorkRecordEntry, {
        short: false,
        withoutTime: true,
      })})`,
      value: true,
    },
  ];
});

function handleSelect() {
  if (!selectedWorkTask.value) {
    return;
  }

  loading.value = true;

  try {
    startValidation.value = true;

    form.countInContractedDate = countInContractedDate.value;

    const workTask: WorkTask = WorkTaskScheme.parse(form);
    emit("updateWorkTask", workTask);
    closeSelectModal();
  } catch (e) {
    errorHandle(e);
  }

  loading.value = false;
}

// contract

const today = luxonNow().set({ minute: 0 });

function openContractModal(id: number) {
  selectingWorkTask(id);

  updateFormBySelected();
  form.contractedAt = today.toISO() || undefined;

  contractModal.value.openModal();
}

function closeContractModal() {
  //form.contractedAt = undefined;
  contractModal.value.closeModal();
}

function handleContract() {
  if (!selectedWorkTask.value) {
    return;
  }

  loading.value = true;

  // 予約時と成約時の業務が同じイベント案件かどうか
  if (
    selectedWorkTask.value.entryWorkRequestOrganizationId ===
      props.lastWorkRecordEntry?.workRequestOrganizationId &&
    props.lastWorkRecordEntry?.workCategory === "event_helper"
  ) {
    openSelectModal(selectedWorkTask.value.id);
  } else {
    try {
      startValidation.value = true;
      const workTask: WorkTask = WorkTaskScheme.parse(form);
      emit("updateWorkTask", workTask);
    } catch (e) {
      errorHandle(e);
    }
  }

  loading.value = false;

  closeContractModal();
}

function toggleContractWorkTask(id: number) {
  emit("toggleContractWorkTask", { id });
}

// lost

function openLostModal(id: number) {
  selectingWorkTask(id);
  lostModal.value?.openModal();
}

function closeLostModal() {
  lostModal.value?.closeModal();
}

const selectedLostReason = ref<LostReason>("unreachable");
const lostReasonDetails = ref();

function handleLost() {
  if (!selectedWorkTask.value) {
    throw new Error("workTask is not selected");
  }

  emit("toggleLostWorkTask", {
    id: selectedWorkTask.value?.id,
    lostReason: selectedLostReason.value,
    lostReasonDetails: lostReasonDetails.value,
  });

  closeLostModal();
}

function toggleLost(prms: { id: number }) {
  const { id } = prms;
  emit("toggleLostWorkTask", {
    id: id,
  });
}
</script>

<style scoped></style>
