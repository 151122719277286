import useSWRV from "swrv";
import { translater } from "/@/modules/string";
import { workToTitle } from "/@/modules/work";
import { workScheduleToTitle } from "/@/modules/workSchedule";
import {
  AbsenceApplicationPlan,
  CalendarEventArgs,
  OrganizationSchedulePlan,
  OrganizationMemberSchedulePlan,
  PlanMap,
  WorkAsPlan,
  WorkScheduleAsPlan,
  OrganizationEventInvitationClient,
} from "/@/types";
import { fetcher } from "/@/modules/axios";

export function useCalendar() {
  // jpHoliday

  function getJpHolidays() {
    return useSWRV("/api/v1/calendars/get_jp_holidays", fetcher);
  }

  // absence application plan

  function absenceApplicationPlanToTitle(
    aap: AbsenceApplicationPlan & { [key: string]: any },
    isOwner: boolean
  ) {
    const base =
      translater(aap.applicationType) + (aap.accept ? "" : "(承認待ち)");

    if (isOwner) {
      return `${aap.organizationMember.name} ${base}`;
    } else {
      return base;
    }
  }

  function absencePlanToEvent(
    aap: AbsenceApplicationPlan,
    isOwner: boolean
  ): CalendarEventArgs {
    return {
      id: "absence-application-plan-" + aap.id,
      title: absenceApplicationPlanToTitle(aap, isOwner),
      backgroundColor: "#ff0000",
      borderColor: "#ff0000",
      start: aap.targetDate,
      allDay: true,
      extendedProps: {
        eventType: "AbsenceApplicationPlan",
        ...aap,
      },
    };
  }

  function createAbsenceApplicationPlanEvents(
    absenceApplicationPlans: AbsenceApplicationPlan[],
    isOwner: boolean
  ): CalendarEventArgs[] {
    return absenceApplicationPlans.map((aap) =>
      absencePlanToEvent(aap, isOwner)
    );
  }

  // work as plan

  function workAsPlanToEvent(work: WorkAsPlan): CalendarEventArgs {
    return {
      id: "work-plan-" + work.id,
      title: workToTitle(work),
      backgroundColor: "#c0c0c0",
      borderColor: "#c0c0c0",
      start: work.startOn,
      end: work.finishOn,
      allDay: true,
      extendedProps: {
        eventType: "WorkAsPlan",
        ...work,
      },
    };
  }

  function createWorkAsPlanEvents(
    workAsPlans: WorkAsPlan[]
  ): CalendarEventArgs[] {
    return workAsPlans.map((wp) => workAsPlanToEvent(wp));
  }

  // work schedule as plan

  function workScheduleAsPlanToTitle(workSchedule: WorkScheduleAsPlan) {
    return workScheduleToTitle(
      {
        workPlaceName: workSchedule.workPlace.name,
        workTypeName: workSchedule.workTypeName,
        workCarrier: workSchedule.work.carrier,
        workCategory: workSchedule.work.workCategory,
        startAt: workSchedule.startAt,
        finishAt: workSchedule.finishAt,
        needCount: workSchedule.needCount,
        currentCount: workSchedule.currentCount,
        enoughCount: workSchedule.enoughCount,
      },
      {
        short: true,
      }
    );
  }

  function workScheduleAsPlanToEvent(
    workSchedule: WorkScheduleAsPlan
  ): CalendarEventArgs {
    return {
      id: "work-schedule-plan-" + workSchedule.id,
      title: workScheduleAsPlanToTitle(workSchedule),
      backgroundColor: "#00cc00",
      borderColor: "#00cc00",
      start: workSchedule.startAt,
      end: workSchedule.finishAt,
      allDay: false,
      extendedProps: {
        eventType: "WorkScheduleAsPlan",
        ...workSchedule,
      },
    };
  }

  function createWorkScheduleAsPlanEvents(
    workScheduleAsPlans: WorkScheduleAsPlan[],
    hideNotEnough?: boolean
  ): CalendarEventArgs[] {
    if (hideNotEnough) return [];

    return workScheduleAsPlans
      .filter((ws) => !ws.enoughCount)
      .map((wsp) => workScheduleAsPlanToEvent(wsp));
  }

  // organization member schedule plan

  function organizationMemberScedulePlanToTitle(
    plan: OrganizationMemberSchedulePlan,
    isOwner: boolean
  ) {
    const base = `${plan.workSchedules
      .map(
        (ws) =>
          `${workScheduleToTitle(ws, {
            short: true,
            withoutCurrentStatus: true,
          })}`
      )
      .join("/")}`;

    if (isOwner) {
      return `${plan.organizationMemberName.split(" ").at(0)} ${base}`;
    } else {
      return base;
    }
  }

  function organizationMemberScedulePlanToEvent(
    plan: OrganizationMemberSchedulePlan,
    isOwner: boolean
  ): CalendarEventArgs {
    return {
      id: "organization-member-schedule-plan-" + plan.id,
      title: organizationMemberScedulePlanToTitle(plan, isOwner),
      backgroundColor: "#00aaaa",
      borderColor: "#00aaaa",
      start: plan.targetDate,
      allDay: true,
      extendedProps: {
        eventType: "OrganizationMemberSchedulePlan",
        ...plan,
      },
    };
  }

  function createOrganizationMemberSchedulePlanEvents(
    plans: OrganizationMemberSchedulePlan[],
    isOwner: boolean
  ): CalendarEventArgs[] {
    return plans.map((p) => organizationMemberScedulePlanToEvent(p, isOwner));
  }

  // organization schedule plan

  function organizationScedulePlanToTitle(plan: OrganizationSchedulePlan) {
    const base = `${plan.organizationName}へのスケジュール`;

    return base;
  }

  function organizationScedulePlanToEvent(
    plan: OrganizationSchedulePlan
  ): CalendarEventArgs {
    return {
      id: "organization-schedule-plan-" + plan.id,
      title: organizationScedulePlanToTitle(plan),
      backgroundColor: "#aa00aa",
      borderColor: "#aa00aa",
      start: plan.targetDate,
      allDay: true,
      classNames: ["hidden-event"],
      extendedProps: {
        eventType: "OrganizationSchedulePlan",
        ...plan,
      },
    };
  }

  function createOrganizationSchedulePlanEvents(
    plans: OrganizationSchedulePlan[]
  ): CalendarEventArgs[] {
    const r = plans.map((p) => organizationScedulePlanToEvent(p));
    return r;
  }

  // organization event invitation

  function organizationEventInvitationToEvent(
    invitation: OrganizationEventInvitationClient
  ): CalendarEventArgs {
    return {
      id: "organization-event-invitation-" + invitation.id,
      title: invitation.organizationEvent.name,
      backgroundColor: "#F59E0B",
      borderColor: "#F59E0B",
      start: invitation.organizationEvent.startAt,
      end: invitation.organizationEvent.finishAt,
      allDay: false,
      extendedProps: {
        eventType: "OrganizationEventInvitation",
        ...invitation,
      },
    };
  }

  function createOrganizationEventInvitationEvents(
    invitations: OrganizationEventInvitationClient[]
  ): CalendarEventArgs[] {
    return invitations.map((i) => organizationEventInvitationToEvent(i));
  }

  // core

  function createCalendarEvents(
    planMap: PlanMap | undefined,
    isOwner: boolean = false,
    options: { hideNotEnough: boolean } = { hideNotEnough: false }
  ): CalendarEventArgs[] {
    if (!planMap) {
      return [];
    }

    const { hideNotEnough } = options;

    return [
      ...(planMap.absenceApplicationPlans
        ? createAbsenceApplicationPlanEvents(
            planMap.absenceApplicationPlans,
            isOwner
          )
        : []),
      /*
      ...(planMap.workAsPlans
        ? createWorkAsPlanEvents(planMap.workAsPlans)
        : []),
      */
      ...(planMap.workScheduleAsPlans
        ? createWorkScheduleAsPlanEvents(
            planMap.workScheduleAsPlans,
            hideNotEnough
          )
        : []),
      ...(planMap.organizationMemberSchedulePlans
        ? createOrganizationMemberSchedulePlanEvents(
            planMap.organizationMemberSchedulePlans,
            isOwner
          )
        : []),
      ...(planMap.assignOrganizationSchedulePlans
        ? createOrganizationSchedulePlanEvents(
            planMap.assignOrganizationSchedulePlans
          )
        : []),
      ...(planMap.organizationEventInvitations
        ? createOrganizationEventInvitationEvents(
            planMap.organizationEventInvitations
          )
        : []),
    ];
  }

  return {
    getJpHolidays,
    absenceApplicationPlanToTitle,
    createCalendarEvents,
  };
}
