<template>
  <div>
    <PageHeader page-title="退店" />
    <LeaveForm
      v-if="currentEntry"
      :current-entry="currentEntry"
      :need-enter-leave-report="actionStatus?.needEnterLeaveReport"
      :need-finish-report="actionStatus?.needFinishReport"
      @submit="handleSubmit"
      @cancel="backto"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from "vue";
import {
  useOrganizationMember,
  useRouterUtil,
  useWorkRecords,
} from "/@/vue/composables";
import { PageHeader } from "/@/vue/components/Layouts";
import { LeaveForm } from "/@/vue/components/Organisms";
import type { WorkRecordEntryExitForm } from "/@/types";

const { query, backto, goto } = useRouterUtil();

const selectedOrganization = ref();

onMounted(() => {
  selectedOrganization.value = {
    id: query.value.organizationId,
    organizationMemberId: query.value.organizationMemberId,
  };
});

const { getActionStatus } = useOrganizationMember();
const { data: actionStatus } = getActionStatus(selectedOrganization);

const { getCurrentWorkRecord } = useWorkRecords();
const { data: currentWorkRecord } = getCurrentWorkRecord(selectedOrganization);

const currentEntry = computed(() => {
  return currentWorkRecord.value?.workRecordEntries.find(
    (entry) => !entry.finishAt
  );
});

const loading = ref<boolean>();

const { leaveEntry } = useWorkRecords();

async function handleSubmit(form: WorkRecordEntryExitForm) {
  loading.value = true;

  if (!currentWorkRecord.value || !currentEntry.value) {
    alert("現在入店していません。");
    backto();
    return;
  }

  if (await leaveEntry(currentWorkRecord.value.id, form)) {
    alert("退店しました。");
    goto({ name: "Home", query: query.value });
  } else {
    alert("業務の終了に失敗しました");
  }
  loading.value = false;
}
</script>

<style scoped></style>
