import { computed, ref, watch } from "vue";
import { createIsoRange, luxonNow } from "/@/modules/luxon";
import { DateMap } from "/@/types";
import { getStorage, setStorage } from "/@/modules/localStorage";

export function useSelectDate() {
  const today = luxonNow();

  const selectedDate = ref<DateMap>(
    getStorage("selectedDate") || {
      year: today.year,
      month: today.month,
    }
  );

  watch(selectedDate, (newDate) => {
    setStorage("selectedDate", newDate);
  });

  function updateSelectedDate({
    year,
    month,
  }: {
    year?: number;
    month?: number;
  }) {
    const newDate = {
      year: year ? year : selectedDate.value.year,
      month: month ? month : selectedDate.value.month,
    };

    selectedDate.value = newDate;
  }

  // basic in log
  const existsDates = computed<string[]>(() => {
    const startOn = "2023-06-01"; // Log 始動月
    const endOn = today.plus({ month: 1 }).toISO(); // 次の月は見れるように?

    if (!endOn) {
      throw new Error("cannot get today");
    }

    const range = createIsoRange(startOn, endOn, "month");

    return range;
  });

  return {
    today,
    selectedDate,
    updateSelectedDate,
    existsDates,
  };
}
