<template>
  <div>
    <div class="d-flex flex-wrap align-items-center mb-3">
      <SelectDate
        v-if="existsDates.length"
        v-model:year="selectedDate.year"
        v-model:month="selectedDate.month"
        v-model:day="selectedDate.day"
        :exists-dates="existsDates"
        only-year-month
        class="me-2"
      />

      <CurrentSchedulesCounter
        :schedules="selectedDateSchedules"
        class="mb-2"
      />
    </div>

    <div class="schedule-editor-wrapper">
      <template v-if="notEventWorkShedules.length">
        <ShopHelperEditorTable
          :work-schedules="notEventWorkShedules"
          :work-places="workPlaces"
          :work-types="workTypes"
          :evet-places="eventPlaces"
          :organization-members="organizationMembers"
          class="mb-3"
          @copy-to="copyTo"
          @delete-schedule="deleteSchedule"
          @add-schedule="addSchedule"
          @update-time="updateTime"
          @update-schedule="updateSchedule"
        />
      </template>

      <template v-if="eachTypeWorkSchedules['event_helper'].length">
        <ContentLabel label="イベント業務" />
        <EventHelperEditorTable
          :work-schedules="eachTypeWorkSchedules['event_helper']"
          :work-places="workPlaces"
          :work-types="workTypes"
          :event-places="eventPlaces"
          :organization-members="organizationMembers"
          class="mb-3"
          @copy-to="copyTo"
          @add-schedule="addSchedule"
          @delete-schedule="deleteSchedule"
          @update-time="updateTime"
          @update-schedule="updateSchedule"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
// TODO: 全体的に複雑すぎる リファクタリング等むずかしいと思われる
import _ from "lodash";
import { computed, reactive } from "vue";
import {
  fromISO,
  getAutoFillFinishAt,
  isContainInDateMap,
} from "/@/modules/luxon";
import {
  addToNthElement,
  removeNthElement,
  removeUndefinedFilterFn,
} from "/@/modules/array";
import { SelectDate, ContentLabel } from "/@/vue/components/Atom";
import {
  WorkScheduleWithAdditionalInfo,
  EachTypeWorkSchedulesMap,
  workCategories,
} from "/@/types";
import type {
  WorkScheduleForm,
  WorkPlace,
  DateMapForm,
  WorkTypeClient,
  WorkCategory,
  EventPlaceClient,
  OrganizationMember,
} from "/@/types";
import ShopHelperEditorTable from "./ShopHelperEditorTable.vue";
import EventHelperEditorTable from "./EventHelperEditorTable.vue";
import CurrentSchedulesCounter from "./CurrentSchedulesCounter.vue";

const props = defineProps<{
  workSchedules: WorkScheduleForm[];
  workPlaces: WorkPlace[];
  workTypes: WorkTypeClient[];
  eventPlaces: EventPlaceClient[];
  organizationMembers: OrganizationMember[];
}>();

const emits = defineEmits<{
  (e: "update:workSchedules", workSchedules: WorkScheduleForm[]): void;
}>();

//

const selectedDate = reactive<DateMapForm>({
  year: undefined,
  month: undefined,
  day: undefined,
});

const existsDates = computed<string[]>(() => {
  return (
    props.workSchedules
      ?.map((ws) => ws.targetDate)
      .filter(removeUndefinedFilterFn) || []
  );
});

// current count

const selectedDateSchedules = computed(() => {
  return props.workSchedules.filter((schedule) => {
    if (!selectedDate.year || !selectedDate.month) {
      return false;
    }

    // @ts-ignore
    return isContainInDateMap(schedule.targetDate, selectedDate);
  });
});

const workSchedulesWithLuxonInfo = computed<WorkScheduleWithAdditionalInfo[]>(
  () => {
    const cloneWorkSchedules: WorkScheduleWithAdditionalInfo[] = _.cloneDeep(
      props.workSchedules
    ).map((nws: WorkScheduleForm, idx: number) => {
      const luxonTargetDate = fromISO(nws.targetDate);

      if (!luxonTargetDate) {
        throw new Error("targetDate required");
      }

      return {
        ...nws,
        idx,
        luxonTargetDate,
      };
    });

    return cloneWorkSchedules;
  }
);

const filteredWorkSchedules = computed<WorkScheduleWithAdditionalInfo[]>(() => {
  return workSchedulesWithLuxonInfo.value.filter((ws) => {
    if (!ws.luxonTargetDate) {
      throw new Error("targetDate required");
    }

    const targetDate = ws.luxonTargetDate;

    return (
      targetDate.year == selectedDate.year &&
      (!selectedDate.month || targetDate.month == selectedDate.month)
    );
  });
});

const eachTypeWorkSchedules = computed<EachTypeWorkSchedulesMap>(() => {
  let m: Partial<Record<WorkCategory, WorkScheduleWithAdditionalInfo[]>> = {};

  workCategories.forEach((t) => {
    m[t] = filteredWorkSchedules.value.filter(
      (schedule) => schedule.workCategory === t
    );
  });

  // @ts-ignore
  const r: Record<WorkCategory, WorkScheduleWithAdditionalInfo[]> = m;

  return r;
});

const notEventWorkShedules = computed<WorkScheduleWithAdditionalInfo[]>(() => {
  const notEventCategories = workCategories.filter(
    (wc) => wc !== "event_helper"
  );

  let r: WorkScheduleWithAdditionalInfo[] = [];

  notEventCategories.forEach((category) => {
    r = [...r, ...eachTypeWorkSchedules.value[category]];
  });

  r.sort((a, b) => {
    const at = fromISO(b.targetDate);
    const bt = fromISO(a.targetDate);

    if (!at || !bt) {
      return 0;
    }

    return at < bt ? 1 : -1;
  });

  return r;
});

// updater
// TODO: updateSchedule のもっといい型の書き方はないか

function updateTime(params: {
  key: "startAt" | "finishAt" | "meetingAt" | "firstMeetingAt";
  at: string | undefined;
  idx: number;
}) {
  const { key, at, idx } = params;

  const newSchedules = _.cloneDeep(props.workSchedules);

  if (key === "meetingAt" || key === "firstMeetingAt") {
    newSchedules[idx][key] = at ? at : null;
  } else {
    newSchedules[idx][key] = at;
  }

  if (key == "startAt") {
    const startAt = at;
    const finishAt = newSchedules[idx].finishAt;

    newSchedules[idx].finishAt = getAutoFillFinishAt(startAt, finishAt);
  }

  emits("update:workSchedules", newSchedules);
}

function updateSchedule(
  params: {
    key: keyof WorkScheduleForm;
    value: any;
    idx: number;
  }[]
) {
  const newSchedules = _.cloneDeep(props.workSchedules);

  params.forEach((param) => {
    const { key, value, idx } = param;

    if (
      key === "meetingPlace" &&
      (typeof value === "string" || typeof value === "undefined")
    ) {
      newSchedules[idx][key] = value;
    } else if (key === "eventPlaceId" && typeof value === "number") {
      newSchedules[idx][key] = value;
      const selectedEventPlace = props.eventPlaces.find(
        (ep) => ep.id === value
      );
      newSchedules[idx].firstMeetingAt = selectedEventPlace?.firstMeetingAt;
      newSchedules[idx].meetingAt = selectedEventPlace?.meetingAt;
      newSchedules[idx].meetingPlace = selectedEventPlace?.meetingPlace;
      newSchedules[idx].startAt = selectedEventPlace?.startAt;
      newSchedules[idx].finishAt = selectedEventPlace?.finishAt;
    } else if (key === "needCount" && typeof value === "number") {
      newSchedules[idx][key] = value;
    } else if (
      key === "workPlaceId" &&
      (typeof value === "number" || typeof value === "undefined")
    ) {
      newSchedules[idx][key] = value;
    } else if (
      key === "workTypeId" &&
      (typeof value === "number" || typeof value === "undefined")
    ) {
      newSchedules[idx][key] = value;
    } else if (key === "needBodyTemperature" && typeof value === "boolean") {
      newSchedules[idx][key] = value;
    } else if (
      key === "needAntibodyTestWithin" &&
      (typeof value === "number" || typeof value === "undefined")
    ) {
      newSchedules[idx][key] = value || 0;
    } else {
      throw new Error(
        `Don't allowed pattern updateSchdelue: ${key} ${value} ${typeof value}`
      );
    }
  });

  emits("update:workSchedules", newSchedules);
}

function copyTo(params: {
  key: keyof WorkScheduleForm;
  workCategory: WorkCategory[];
}) {
  // TODO: 毎回 Array を全て作りなおしているので、年単位の案件などを作ると重くなるかも
  const { key, workCategory } = params;

  const newSchedules = _.cloneDeep(props.workSchedules);

  let baseSchedule: WorkScheduleWithAdditionalInfo | undefined;

  if (workCategory.at(0) === "event_helper") {
    baseSchedule = eachTypeWorkSchedules.value["event_helper"].at(0);
  } else {
    baseSchedule = notEventWorkShedules.value.at(0);
  }

  if (!baseSchedule) {
    return;
  }

  newSchedules.forEach((s) => {
    if (
      // @ts-ignore
      baseSchedule.luxonTargetDate.hasSame(fromISO(s.targetDate), "month") &&
      workCategory.includes(s.workCategory)
    ) {
      // @ts-ignore こういう関数の型の通し方がわからない
      s[key] = baseSchedule[key];

      if (key == "startAt") {
        // @ts-ignore baseSchedule never undefined
        s.finishAt = baseSchedule.finishAt;
      }
    }
  });

  emits("update:workSchedules", newSchedules);
}

function deleteSchedule(idx: number) {
  emits("update:workSchedules", removeNthElement(props.workSchedules, idx));
}

function addSchedule(idx: number) {
  const newSchedules = _.cloneDeep(props.workSchedules[idx]);
  newSchedules.id = undefined;
  newSchedules.workPlaceId = undefined;
  newSchedules.eventPlaceId = undefined;
  newSchedules.meetingPlace = undefined;
  newSchedules.needCount = 1;

  emits(
    "update:workSchedules",
    addToNthElement(newSchedules, props.workSchedules, idx + 1)
  );
}
</script>

<style scoped>
.time-form {
  width: 100px;
}
.table-wrapper {
  width: 100%;
  height: auto;
  overflow-x: scroll;
}

.date-th {
  width: 120px;
}
</style>
