import { DateTime, Settings, DateObjectUnits, Interval } from "luxon";
import type { DateForCalendar, DateMap, JpHolidays } from "/@/types";
import { removeUndefinedFilterFn } from "./array";
import { zeroPadding } from "./string";

export type LuxonTimeObject = DateObjectUnits;
/*
{
    // a year, such as 1987
    year?: number | undefined;
    // a month, 1-12
    month?: number | undefined;
    // a day of the month, 1-31, depending on the month
    day?: number | undefined;
    // day of the year, 1-365 or 366
    ordinal?: number | undefined;
    // an ISO week year
    weekYear?: number | undefined;
    // an ISO week number, between 1 and 52 or 53, depending on the year
    weekNumber?: number | undefined;
    // an ISO weekday, 1-7, where 1 is Monday and 7 is Sunday
    weekday?: number | undefined;
    // hour of the day, 0-23
    hour?: number | undefined;
    // minute of the hour, 0-59
    minute?: number | undefined;
    // second of the minute, 0-59
    second?: number | undefined;
    // millisecond of the second, 0-999
    millisecond?: number | undefined;
}
*/

export function setup() {
  Settings.defaultLocale = "ja";
  Settings.defaultZone = "Asia/Tokyo";
}

setup();

// from to

export function fromISO(iso: string) {
  const luxonDate = DateTime.fromISO(iso);

  if (!luxonDate) {
    throw new Error("fail luxon parse");
  }

  return luxonDate;
}

export function fromDate(date: Date) {
  return DateTime.fromJSDate(date);
}

export function fromISOToDate(iso: string) {
  return fromISO(iso).toFormat("yyyy-MM-dd");
}

export function fromObject(obj: LuxonTimeObject | undefined) {
  if (!obj) {
    return undefined;
  }
  return DateTime.fromObject(obj);
}

export function toDateFromISO(iso: string) {
  return DateTime.fromISO(iso).toJSDate();
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const d = "2000-01-01T10:00:00.000+09:00";

  it("toDateFromISO", () => {
    expect(toDateFromISO(d)).toStrictEqual(new Date(Date.parse(d)));
  });

  it("fromISOToDate", () => {
    expect(fromISOToDate(d)).toBe("2000-01-01");
  });
}

// format

export function isoFormat(iso: string, f: string) {
  if (!iso) {
    return null;
  }

  return DateTime.fromISO(iso).toFormat(f);
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const d = "2000-01-01T10:00:00.000+09:00";

  it("isoFormat", () => {
    expect(isoFormat(d, "EEE")).toBe("土");
    expect(isoFormat(d, "HH:mm")).toBe("10:00");
  });
}

export function basicFormatter(
  input: Date | string | DateTime | undefined,
  mode?:
    | "onlyMonth"
    | "onlyDateDay"
    | "noYear"
    | "onlyWday"
    | "onlyTime"
    | "withTime"
    | "slashStyle"
) {
  if (!input) {
    return undefined;
  }

  let format = "yyyy年M月d日(EEE)";

  switch (mode) {
    case "onlyMonth":
      format = "yyyy年M月";
      break;
    case "onlyDateDay":
      format = "d(EEE)";
      break;
    case "noYear":
      format = "M月d日(EEE)";
      break;
    case "onlyWday":
      format = "EEE";
      break;
    case "onlyTime":
      format = "HH:mm";
      break;
    case "slashStyle":
      format = "M/d";
      break;
    case "withTime":
      format = "yyyy年M月d日(EEE) HH:mm";
  }

  if (input instanceof Date) {
    return DateTime.fromJSDate(input).toFormat(format);
  } else if (typeof input == "string") {
    return DateTime.fromISO(input).toFormat(format);
  } else if (DateTime.isDateTime(input)) {
    return input.toFormat(format);
  } else {
    throw new Error(`input ${input} is no match type in  basicFormatter`);
  }
}

export function toTimeRangeString(from: string, to: string) {
  return (
    basicFormatter(from, "onlyTime") + "~" + basicFormatter(to, "onlyTime")
  );
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const d = "2000-01-01T10:00:00.000+09:00";

  it("basic", () => {
    expect(basicFormatter(d)).toBe("2000年1月1日(土)");
    expect(basicFormatter(d, "withTime")).toBe("2000年1月1日(土) 10:00");
    expect(basicFormatter(d, "onlyMonth")).toBe("2000年1月");
    expect(basicFormatter(d, "onlyDateDay")).toBe("1(土)");
    expect(basicFormatter(d, "noYear")).toBe("1月1日(土)");
    expect(basicFormatter(d, "onlyWday")).toBe("土");
    expect(basicFormatter(d, "onlyTime")).toBe("10:00");
    expect(basicFormatter(d, "slashStyle")).toBe("1/1");
  });

  it("can deal each type", () => {
    const luxonD = fromISO(d);

    if (luxonD) {
      expect(basicFormatter(luxonD)).toBe("2000年1月1日(土)");
      expect(basicFormatter(luxonD.toJSDate())).toBe("2000年1月1日(土)");
    }
  });

  it("toTimeRangeString", () => {
    expect(toTimeRangeString(d, d)).toBe("10:00~10:00");
  });
}

// compare

export function isSameDate(a: string, b: string) {
  const la = DateTime.fromISO(a).startOf("day");
  const lb = DateTime.fromISO(b).startOf("day");

  return la.hasSame(lb, "day");
}

export function isSameToday(t: string, now = luxonNow()) {
  const lt = DateTime.fromISO(t);

  return lt.hasSame(now, "day");
}

export function isFromToday(t: string, now = luxonNow()) {
  return DateTime.fromISO(t).startOf("day") >= now.startOf("day");
}

export function isAfterToday(t: string, now = luxonNow()) {
  const lt = DateTime.fromISO(t).startOf("day");
  return now.endOf("day") < lt;
}

export function isBeforeToday(t: string, now = luxonNow()) {
  return !isSameToday(t, now) && !isAfterToday(t, now);
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const d1 = "2022-10-31T10:00:00.000+09:00";
  const d2 = "2022-11-01T10:00:00.000+09:00";

  it("isAfterToday", () => {
    expect(isAfterToday(d1, fromISO(d2))).toBeFalsy();
    expect(isAfterToday(d2, fromISO(d1))).toBeTruthy();
  });

  it("isBeforeToday", () => {
    expect(isBeforeToday(d1, fromISO(d2))).toBeTruthy();
    expect(isBeforeToday(d2, fromISO(d1))).toBeFalsy();
  });

  it("isSameToday", () => {
    expect(isSameToday(d1, fromISO(d2))).toBeFalsy();
    expect(isSameToday(d2, fromISO(d1))).toBeFalsy();
  });

  it("isSameDate", () => {
    expect(isSameDate(d1, d1)).toBeTruthy();
    expect(isSameDate(d1, d2)).toBeFalsy();
  });

  it("isFromToday", () => {
    expect(isFromToday(d1, fromISO(d2))).toBeFalsy();
    expect(isFromToday(d2, fromISO(d1))).toBeTruthy();
    expect(isFromToday(d1, fromISO(d1))).toBeTruthy();
  });
}

export function dateMapToISO(dateMap: DateMap) {
  return `${dateMap.year}-${zeroPadding(dateMap.month, 2)}-01`;
}

export function isSameMonth(dateMap: DateMap, date: string) {
  const a = fromObject({ year: dateMap.year, month: dateMap.month, day: 1 });
  const b = fromISO(date);

  if (!a || !b) {
    throw new Error(`unexpected date ${date}`);
  }

  return a.hasSame(b, "month");
}

export function isSameYear(dateMap: DateMap, date: string) {
  const a = fromObject({ year: dateMap.year, month: 1, day: 1 });
  const b = fromISO(date);

  if (!a || !b) {
    throw new Error(`unexpected date ${date}`);
  }

  return a.hasSame(b, "year");
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  it("isSameDate", () => {
    expect(isSameDate("2022-10-31", "2022-10-31")).toBe(true);
    expect(isSameDate("2022-10-31", "2022-11-1")).toBe(false);
  });

  it("isSameMonth", () => {
    expect(isSameMonth({ year: 2022, month: 10 }, "2022-10-31")).toBe(true);
    expect(isSameMonth({ year: 2022, month: 10 }, "2022-11-1")).toBe(false);
  });

  it("isSameYear", () => {
    expect(isSameYear({ year: 2022, month: 10 }, "2022-10-31")).toBe(true);
    expect(isSameYear({ year: 2022, month: 10 }, "2023-11-1")).toBe(false);
  });
}

export function isFuture(at: string) {
  return DateTime.now() < DateTime.fromISO(at);
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const d1 = "2000-01-01T10:00:00.000+09:00";
  const d2 = "9999-01-01T10:00:00.000+09:00";

  it("isFuture", () => {
    expect(isFuture(d1)).toBe(false);
    expect(isFuture(d2)).toBe(true);
  });
}

export function isToday(
  iso: string,
  forTestTodayISO: string | undefined = undefined
) {
  if (forTestTodayISO) {
    return isSameDate(forTestTodayISO, iso);
  } else {
    const today = luxonNow().toISO();
    return isSameDate(today, iso);
  }
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const iso = "2023-04-02T10:00:00.000+09:00";

  it("isToday", () => {
    expect(isToday(iso)).toBeFalsy();
    expect(isToday(iso, iso)).toBeTruthy();
  });
}

// now

export function luxonNow() {
  return DateTime.now();
}

export function luxonTomorow() {
  return luxonNow().plus({ day: 1 }).startOf("day");
}

export function isoNow() {
  return DateTime.now().toISO();
}

// range

export type RangeInterval = "month" | "year" | "day";

function createRangeFromLuxons(
  start: DateTime,
  end: DateTime,
  interval: RangeInterval
): DateTime[] {
  let cursor = fromISO(start.toISO());
  const range: DateTime[] = [];

  while (cursor <= end) {
    range.push(cursor);
    cursor = cursor.plus({
      years: interval == "year" ? 1 : 0,
      months: interval == "month" ? 1 : 0,
      days: interval == "day" ? 1 : 0,
    });
  }

  return range;
}

export function createRange(
  startIso: string,
  endIso: string,
  interval: RangeInterval
): DateTime[] {
  const start = fromISO(startIso)?.startOf(interval);
  const end = fromISO(endIso)?.endOf(interval);

  if (!start || !end) {
    return [];
  }

  return createRangeFromLuxons(start, end, interval);
}

export function createIsoRange(
  startIso: string,
  endIso: string,
  interval: RangeInterval
): string[] {
  return createRange(startIso, endIso, interval)
    .map((r) => r.toISO())
    .filter(removeUndefinedFilterFn);
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const d1 = "2022-02-20T10:00:00.000+09:00";
  const d2 = "2022-02-24T10:00:00.000+09:00";

  it("createRange", () => {
    expect(createIsoRange(d1, d2, "day")).toStrictEqual([
      "2022-02-20T00:00:00.000+09:00",
      "2022-02-21T00:00:00.000+09:00",
      "2022-02-22T00:00:00.000+09:00",
      "2022-02-23T00:00:00.000+09:00",
      "2022-02-24T00:00:00.000+09:00",
    ]);
    expect(createRange(d1, d2, "month").map((ri) => ri.toISO())).toStrictEqual([
      "2022-02-01T00:00:00.000+09:00",
    ]);
    expect(createRange(d1, d2, "year").map((ri) => ri.toISO())).toStrictEqual([
      "2022-01-01T00:00:00.000+09:00",
    ]);
  });
}

export function createInterval(startIso: string, endIso: string) {
  const start = fromISO(startIso);
  const end = fromISO(endIso).endOf("day");

  return Interval.fromDateTimes(start, end);
}

export function isContain(
  target: string,
  { start, end }: { start?: string; end?: string }
) {
  if (!start && !end) return true;
  if (!start && end) return DateTime.fromISO(target) <= DateTime.fromISO(end);
  if (start && !end) return DateTime.fromISO(start) <= DateTime.fromISO(target);

  if (start && end) {
    return createInterval(start, end).contains(fromISO(target));
  }

  throw new Error("unexpected result in isContain");
}

export function isContainInDateMap(target: string, dateMap: DateMap) {
  const { start, end } = getStartEndFromMap(dateMap);
  return isContain(target, { start, end });
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const start = "2023-04-01T00:00:00.000+09:00";
  const end = "2023-04-30T00:00:00.000+09:00";

  it("createInterval", () => {
    const interval = createInterval(start, end);
    expect(interval.isValid).toBeTruthy();
    expect(interval instanceof Interval).toBeTruthy();
  });

  it("isContain", () => {
    expect(isContain("2023-04-20T00:00:00", { start, end })).toBeTruthy();
    expect(isContain(start, { start, end: start })).toBeTruthy();
    expect(isContain("2023-04-01", { start, end })).toBeTruthy();
    expect(isContain("2023-04-05", { start: undefined, end })).toBeTruthy();
    expect(isContain("2023-04-05", { start, end: undefined })).toBeTruthy();
    expect(
      isContain("2023-04-05", { start: undefined, end: undefined })
    ).toBeTruthy();
  });

  it("isContainInDateMap", () => {
    expect(
      isContainInDateMap("2023-04-20T00:00:00.000+09:00", {
        year: 2023,
        month: 4,
      })
    ).toBeTruthy();
  });
}

export function createDateMapRange(
  startIso: string,
  endIso: string
): DateMap[] {
  return createRange(startIso, endIso, "month").map((dt) => ({
    year: dt.year,
    month: dt.month,
  }));
}

export function getStartEndFromMap(dateMap: DateMap) {
  const startDateTime = DateTime.fromObject({
    year: dateMap.year,
    month: dateMap.month,
    day: 1,
  });
  const start = startDateTime.toISO();

  const end = startDateTime.endOf("month").toISO();

  if (!start || !end) {
    throw new Error("Unexpected result in getStartEndFromMap");
  }

  return {
    start,
    end,
  };
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const d1 = "2022-02-20T10:00:00.000+09:00";
  const d2 = "2022-02-24T10:00:00.000+09:00";

  it("createDateMapRange", () => {
    expect(createDateMapRange(d1, d2)).toStrictEqual([
      { year: 2022, month: 2 },
    ]);
  });

  it("getStartEndFromMap", () => {
    expect(getStartEndFromMap({ year: 2023, month: 5 })).toStrictEqual({
      start: "2023-05-01T00:00:00.000+09:00",
      end: "2023-05-31T23:59:59.999+09:00",
    });
  });
}

export function createDateForCalendars(dateMap: DateMap): DateForCalendar[] {
  const startOn = DateTime.fromObject({
    year: dateMap.year,
    month: dateMap.month,
    day: 1,
  });
  const finishOn = startOn.endOf("month");
  const range = createRangeFromLuxons(startOn, finishOn, "day");

  return range.map((dt) => ({
    weekday: dt.weekday,
    dateKey: dt.toFormat("yyyy-MM-dd"),
    luxonDate: dt,
  }));
}

export function nextMonth(dateMap: DateMap): DateMap {
  const year = dateMap.year;
  const month = dateMap.month;

  if (!year || !month) {
    return dateMap;
  }

  if (dateMap.month === 12) {
    return {
      year: year + 1,
      month: 1,
    };
  } else {
    return {
      year,
      month: month + 1,
    };
  }
}

export function prevMonth(dateMap: DateMap): DateMap {
  const year = dateMap.year;
  const month = dateMap.month;

  if (!year || !month) {
    return dateMap;
  }

  if (dateMap.month === 1) {
    return {
      year: year - 1,
      month: 12,
    };
  } else {
    return {
      year,
      month: month - 1,
    };
  }
}

export function getDaysCountInMonth(dateMap: DateMap) {
  const monthStart = `${dateMap.year}-${zeroPadding(dateMap.month, 2)}-01`;
  const l = fromISO(monthStart);

  if (!l) {
    throw new Error(
      `unexpect parse result in getDaysCountInMonth: ${monthStart}`
    );
  }

  return l.daysInMonth || 0;
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const dateMap = { year: 2022, month: 2 };

  it("createDateForCalendars", () => {
    expect(
      createDateForCalendars(dateMap).some(
        (d) => d.weekday === 2 && d.dateKey === "2022-02-08"
      )
    ).toBeTruthy();
  });

  it("moveMonth", () => {
    expect(nextMonth({ year: 2022, month: 2 })).toMatchObject({
      year: 2022,
      month: 3,
    });
    expect(nextMonth({ year: 2022, month: 12 })).toMatchObject({
      year: 2023,
      month: 1,
    });
    expect(prevMonth({ year: 2022, month: 12 })).toMatchObject({
      year: 2022,
      month: 11,
    });
    expect(prevMonth({ year: 2022, month: 1 })).toMatchObject({
      year: 2021,
      month: 12,
    });
  });

  it("getDaysCountInMonth", () => {
    expect(getDaysCountInMonth({ year: 2023, month: 7 })).toBe(31);
  });
}

export function countDaysInIsoArray(isos: string[]): number {
  const dayIsos = isos.map((iso) => fromISO(iso).toFormat("yyyy-MM-dd"));
  return [...new Set(dayIsos)].length;
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  it("countDaysInIsoArray", () => {
    expect(countDaysInIsoArray(["2023-06-01T09:00:00.000+09:00"])).toBe(1);
    expect(
      countDaysInIsoArray([
        "2023-06-01T09:00:00.000+09:00",
        "2023-06-01T09:00:00.000+09:00",
      ])
    ).toBe(1);
    expect(
      countDaysInIsoArray([
        "2023-06-01T09:00:00.000+09:00",
        "2023-06-01T09:00:00.000+09:00",
        "2023-06-03T09:00:00.000+09:00",
        "2023-06-05T09:00:00.000+09:00",
        "2023-06-05T09:00:00.000+09:00",
      ])
    ).toBe(3);
  });
}

export function countDaysBetween(from: string, to: string) {
  const date1 = DateTime.fromISO(from);
  const date2 = DateTime.fromISO(to);

  // 期間の差を計算し、その結果を日数に変換
  const diff = date2.diff(date1, "days");
  const days = Math.abs(diff.as("days"));

  return days;
}

export function countHoursBetween(from: string, to: string) {
  const date1 = DateTime.fromISO(from);
  const date2 = DateTime.fromISO(to);

  // 期間の差を計算し、その結果を日数に変換
  const diff = date2.diff(date1, "hours");
  const days = Math.abs(diff.as("hours"));

  return days;
}

export function countHoursOfArray(
  arr: ({
    startAt: string;
    finishAt?: string;
  } & {
    [k: string]: any;
  })[]
): number {
  return arr.reduce(
    (acc, crr) =>
      acc + (crr.finishAt ? countHoursBetween(crr.startAt, crr.finishAt) : 0),
    0
  );
}

export function totalWorkingHoursIs(
  expect: number,
  workingHours: number,
  restTimeHours: number
) {
  if (workingHours === expect + 1 && restTimeHours === 0) {
    return true;
  }

  return workingHours - restTimeHours === expect;
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const isoString1 = "2022-04-01T00:00:00.000Z";
  const isoString2 = "2022-04-15T00:00:00.000Z";
  const isoString3 = "2022-04-01T11:15:00.000Z";

  it("");

  it("countDaysBetween", () => {
    expect(countDaysBetween(isoString1, isoString2)).toBe(14);
    expect(countHoursBetween(isoString1, isoString3)).toBe(11.25);
  });

  it("countHoursOfArray", () => {
    expect(
      countHoursOfArray([
        {
          startAt: "2022-04-01T00:00:00.000+09:00",
          finishAt: "2022-04-01T09:00:00.000+09:00",
        },
      ])
    ).toBe(9);
    expect(
      countHoursOfArray([
        {
          startAt: "2022-04-01T00:00:00.000+09:00",
          finishAt: "2022-04-01T09:00:00.000+09:00",
        },
        {
          startAt: "2022-04-01T00:00:00.000+09:00",
          finishAt: "2022-04-01T09:00:00.000+09:00",
        },
      ])
    ).toBe(18);
  });

  it("totalWorkingHoursIs", () => {
    expect(totalWorkingHoursIs(8, 9, 0)).toBeTruthy();
    expect(totalWorkingHoursIs(8, 10, 2)).toBeTruthy();
    expect(totalWorkingHoursIs(5, 10, 4)).toBeFalsy();
    expect(totalWorkingHoursIs(5, 10, 5)).toBeTruthy();
  });
}

// 重複があるかどうかの関数

export function isOverlap(
  start1: string,
  end1: string,
  start2: string,
  end2: string
) {
  const startDT1 = DateTime.fromISO(start1);
  const endDT1 = DateTime.fromISO(end1);
  const startDT2 = DateTime.fromISO(start2);
  const endDT2 = DateTime.fromISO(end2);

  if (endDT1 < startDT2 || endDT2 < startDT1) {
    return false;
  }

  return true;
}

export function isOverLapWithDateMap(
  start: string,
  end: string,
  dateMap: DateMap
) {
  const start2 = fromObject({
    year: dateMap.year,
    month: dateMap.month,
    day: 1,
  });

  if (!start2) {
    throw new Error("unexpected result fromObject");
  }

  const end2 = start2.endOf("month");

  return isOverlap(start, end, start2.toISO(), end2.toISO());
}

if (import.meta.vitest) {
  const { describe, expect, it } = import.meta.vitest;

  describe("isOverlap", () => {
    it("returns true when overlaps", () => {
      const result = isOverlap(
        "2022-04-05T10:00:00Z",
        "2022-04-05T12:00:00Z",
        "2022-04-05T11:00:00Z",
        "2022-04-05T13:00:00Z"
      );
      expect(result).toBeTruthy();
    });

    it("returns false when does not overlap", () => {
      const result = isOverlap(
        "2022-04-05T10:00:00Z",
        "2022-04-05T11:00:00Z",
        "2022-04-05T12:00:00Z",
        "2022-04-05T13:00:00Z"
      );
      expect(result).toBeFalsy();
    });
  });

  describe("isOverlapWithDateMap", () => {
    it("returns true when overlaps", () => {
      const result = isOverLapWithDateMap(
        "2022-04-05T10:00:00Z",
        "2022-04-05T12:00:00Z",
        {
          year: 2022,
          month: 4,
        }
      );
      expect(result).toBeTruthy();
    });

    it("returns false when does not overlap", () => {
      const result = isOverLapWithDateMap(
        "2022-04-05T10:00:00Z",
        "2022-04-05T11:00:00Z",
        {
          year: 2023,
          month: 4,
        }
      );
      expect(result).toBeFalsy();
    });
  });
}

export function isoToDateMap(iso: string): DateMap {
  const dt = fromISO(iso);

  if (!dt || dt.invalidReason) {
    throw new Error(
      `isoToDateMap received unprocessable value. ${
        dt ? "Invalid reason: " + dt.invalidReason : ""
      }`
    );
  }

  return { year: dt.year, month: dt.month };
}

export function luxonDateToDateMap(date: DateTime): DateMap {
  return { year: date.year, month: date.month };
}

export function dateMapToLuxonDate(dateMap: DateMap): DateTime {
  return fromObject({ year: dateMap.year, month: dateMap.month, day: 1 });
}

export function createExistsDateMap(isos: string[]): DateMap[] {
  const sorted = isos.sort();
  return sorted.map((iso) => isoToDateMap(iso));
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  setup();

  const d1 = "2021-02-20T10:00:00.000+09:00";
  const d2 = "2022-04-24T10:00:00.000+09:00";
  const d3 = "2022-06-14T10:00:00.000+09:00";

  it("createExistsDateMap", () => {
    expect(createExistsDateMap([d1, d2, d3])).toStrictEqual([
      { year: 2021, month: 2 },
      { year: 2022, month: 4 },
      { year: 2022, month: 6 },
    ]);
  });

  it("isoToDateMap", () => {
    expect(isoToDateMap(d1)).toStrictEqual({ year: 2021, month: 2 });
  });

  it("luxonDateToDateMap", () => {
    expect(luxonDateToDateMap(fromISO(d1))).toStrictEqual({
      year: 2021,
      month: 2,
    });
    expect(luxonDateToDateMap(fromISO(d2))).toStrictEqual({
      year: 2022,
      month: 4,
    });
  });
}

export function getWeekdayColor(
  date: DateForCalendar,
  jpHolidays?: JpHolidays
) {
  if (jpHolidays && jpHolidays[date.dateKey]) {
    return "text-danger";
  }

  if (date.weekday === 7) {
    return "text-danger";
  }

  if (date.weekday === 6) {
    return "text-primary";
  }

  return "";
}

// auto fill

export function getAutoFillFinishAt(
  newStartAt: string | undefined,
  currentFinishAt: string | undefined
): string | undefined {
  if (!currentFinishAt) {
    // finish が未入力の場合は9時間後に設定
    if (!newStartAt) return newStartAt;

    const baseOfAfter9hours = fromISO(newStartAt);

    if (!baseOfAfter9hours) {
      throw new Error("unexpected formISO results");
    }

    return baseOfAfter9hours.plus({ hours: 9 }).toISO() || undefined;
  } else {
    if (!newStartAt) {
      // start を clear した場合
      return undefined;
    } else {
      // start が finish よりも後の場合は finish をリセット
      const luxonStartAt = fromISO(newStartAt);
      const luxonFinishAt = fromISO(currentFinishAt);

      if (luxonStartAt && luxonFinishAt) {
        if (luxonStartAt > luxonFinishAt) {
          return newStartAt;
        } else {
          return currentFinishAt;
        }
      }
    }
  }
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const t1 = "2000-01-01T10:00:00.000+09:00";
  const t2 = "2000-01-01T20:00:00.000+09:00";

  const t3 = "2000-01-01T19:00:00.000+09:00";
  const t4 = "2000-01-02T05:00:00.000+09:00";

  it("getAutoFillFinishAt", () => {
    expect(getAutoFillFinishAt(t1, undefined)).toBe(t3);
    expect(getAutoFillFinishAt(undefined, t2)).toBe(undefined);
    expect(getAutoFillFinishAt(t2, t1)).toBe(t2);
    expect(getAutoFillFinishAt(t1, t2)).toBe(t2);
  });
}

export function rangeOfIsoToStr(range: string[]) {
  return range.map((d) => fromISO(d).toFormat("yyyy年MM月dd日")).join(" - ");
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const t1 = "2023-10-01T10:00:00.000+09:00";
  const t2 = "2023-10-05T20:00:00.000+09:00";

  it("rangeOfIsoToStr", () => {
    expect(rangeOfIsoToStr([t1, t2])).toBe("2023年10月01日 - 2023年10月05日");
  });
}
