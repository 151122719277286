<template>
  <div>
    <div class="mb-4">
      <ContentCard title="最終提出日" variant="primary">
        {{ lastSubmit ? basicFormatter(lastSubmit) : "未提出" }}
      </ContentCard>
    </div>

    <div class="mb-4">
      <FormItemPhoto
        v-model:photo="form.photo"
        label=""
        :errors="errors"
        form-id="photo"
      />
    </div>

    <div class="mt-2">
      <BasicButton
        icon="pi pi-upload"
        label="提出"
        button-type="submit"
        @click="submit"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { basicFormatter } from "/@/modules/luxon";
import { useZodScheme } from "/@/vue/composables";
import { ContentCard, BasicButton } from "/@/vue/components/Atom";

import { FormItemPhoto } from "/@/vue/components/Molecules";
import { AntibodyTestScheme } from "/@/types";
import type { AntibodyTestForm, AntibodyTest } from "/@/types";
import { errorHandle } from "/@/modules/error";

defineProps<{
  lastSubmit?: string;
  loading?: boolean;
}>();

const emit = defineEmits<{
  (e: "submit", antibodyTest: AntibodyTest): void;
}>();

const { useFormAndErrors } = useZodScheme();

const { form, errors, startValidation } =
  useFormAndErrors<AntibodyTestForm>(AntibodyTestScheme);

function submit() {
  try {
    startValidation.value = true;

    const antibodyTest = AntibodyTestScheme.parse(form);
    emit("submit", antibodyTest);
  } catch (e) {
    errorHandle(e);
  }
}
</script>

<style lang="scss" scoped></style>
