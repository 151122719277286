<template>
  <div id="session-sign-in">
    <LoginForm
      class="w-100 h-100 d-flex align-items-center justify-content-center"
      :fail-login="failLogin"
      :loading="loading"
      @login="login"
    >
      <MessageList :messages="errors" status="error" />
    </LoginForm>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useAuthorize, useRouterUtil } from "/@/vue/composables";
import { MessageList } from "/@/vue/components/Atom";
import { LoginForm } from "/@/vue/components/Organisms";
import { User } from "/@/types";

// login

const { login: userLogin } = useAuthorize();
const { goto } = useRouterUtil();

const loading = ref<boolean>(false);
const errors = ref<Array<string>>([]);
const failLogin = ref(false);

async function login(signinForm: User) {
  failLogin.value = false;
  loading.value = true;

  if (await userLogin(signinForm)) {
    goto({ name: "Home" });
  } else {
    errors.value = ["ログインに失敗しました"];
    failLogin.value = true;
  }
  loading.value = false;
}
</script>

<style lang="scss" scoped>
.session-sign-in {
  height: 100%;
}
</style>
