<template>
  <div>
    <AdminBasisReportItemFilter
      v-model:selected-work-category="selectedWorkCategory"
      v-model:selected-work-carrier="selectedWorkCarrier"
      v-model:selected-request-organization-id="selectedRequestOrganizationId"
      :request-organizations="requestOrganizations"
      class="mb-3"
    />
    <div class="w-100 d-flex justify-content-end">
      <BasicButton icon="pi pi-plus" @click="openFormModal" />
    </div>
    <div
      v-for="t in (['individual', 'overall'] as ('individual' | 'overall')[])"
      :key="`report-type-${t}`"
    >
      <div>
        <Chip :label="columnToStr(t)" class="mb-2" />
      </div>

      <template
        v-for="k in (['customers', 'nonCustomers'] as ('customers' | 'nonCustomers')[])"
        :key="`each-key-${k}`"
      >
        <span>{{ k == "customers" ? "応対記録" : "商材" }}</span>
        <div class="table-wrapper">
          <AdminBasisReportItemListContent
            :basis-report-items="reportTypeItemMaps[t][k]"
            :basis-report-item-groups="basisReportItemGroups"
            :request-organizations="requestOrganizations"
            show-order
            class="mb-3"
            @select-item="openFormModal"
            @change-order="changeOrder($event, { t, k })"
          />
        </div>
      </template>
    </div>

    <div>
      <div class="d-flex align-items-center mb-2">
        <Chip label="その他" class="me-2" />
        <span>ここに表示されている項目は目標値の設定でのみ表示されます</span>
      </div>

      <AdminBasisReportItemListContent
        :basis-report-items="invalidReportItems"
        :basis-report-item-groups="basisReportItemGroups"
        :request-organizations="requestOrganizations"
        class="mb-3"
        @select-item="openFormModal"
        @change-order="changeOrder($event, { t, k })"
      />
    </div>

    <teleport to="body">
      <AdminBasisReportItemFormModal
        ref="formModal"
        :basis-report-items="basisReportItems"
        :basis-report-item-groups="basisReportItemGroups"
        :request-organizations="requestOrganizations"
        :selected-work-category="selectedWorkCategory"
        :selected-work-carrier="selectedWorkCarrier"
        @submit="$emit('submitBasisReportItem', $event)"
        @destroy="$emit('destroyBasisReportItem', $event)"
        @create-group="$emit('createGroup', $event)"
        @destroy-group="$emit('destroyGroup', $event)"
      />
    </teleport>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { isTargetConfig, columnToStr } from "/@/modules/workReport";
import { BasicButton } from "/@/vue/components/Atom";
import {
  AdminBasisReportItemFormModal,
  AdminBasisReportItemFilter,
  AdminBasisReportItemListContent,
} from "/@/vue/components/Organisms";
import {
  BasisReportItemClient,
  BasisReportItemGroupClient,
  BasisReportItem,
  Organization,
  OrganizationCategory,
  WorkCategory,
  Carrier,
} from "/@/types";
import { computed } from "vue";
import Chip from "primevue/chip";

const props = defineProps<{
  basisReportItems: BasisReportItemClient[];
  basisReportItemGroups: BasisReportItemGroupClient[];
  requestOrganizations: Organization[];
}>();

const emit = defineEmits<{
  (e: "selectReportItem", id: number): void;
  (e: "submitBasisReportItem", form: BasisReportItem): void;
  (e: "destroyBasisReportItem", id: number): void;
  (e: "createGroup", name: string): void;
  (e: "destroyGroup", id: number): void;
  (e: "changeOrder", prms: { id: number; order: number }): void;
}>();

function changeOrder(
  prms: { idx: number; direction: "left" | "right" },
  info: { t: "individual" | "overall"; k: "customers" | "nonCustomers" }
) {
  const { idx, direction } = prms;
  const { t, k } = info;

  const targetItems = reportTypeItemMaps.value[t][k];

  if (!targetItems || !targetItems.length) return;
  const item = targetItems[idx];
  const exchangeIdx = direction === "left" ? idx - 1 : idx + 1;
  const exchangeItem = targetItems[exchangeIdx];

  if (!exchangeItem) return;

  if (direction == "left") {
    emit("changeOrder", {
      id: item.id,
      order: exchangeItem.order,
    });
  } else {
    emit("changeOrder", {
      id: exchangeItem.id,
      order: item.order,
    });
  }
}

// filter

const selectedType = ref<"">;
const selectedWorkCategory = ref<WorkCategory>("shop_helper");
const selectedWorkCarrier = ref<Carrier>("docomo");
const selectedRequestOrganizationId = ref<number>();

const baseOptions = computed(() => ({
  workTypeCategories: ["telecommunications"] as OrganizationCategory[],
  workCategory: selectedWorkCategory.value,
  workCarrier: selectedWorkCarrier.value,
  requestOrganizationId: selectedRequestOrganizationId.value,
  isAdmin: true,
}));

const individualCustomersReportItems = computed(() => {
  return props.basisReportItems.filter((item) =>
    item.configs.some((config) =>
      isTargetConfig(config, {
        ...baseOptions.value,
        individualCustomers: true,
      })
    )
  );
});

const individualNonCustomersReportItems = computed(() => {
  return props.basisReportItems.filter((item) =>
    item.configs.some((config) =>
      isTargetConfig(config, {
        ...baseOptions.value,
        individual: true,
      })
    )
  );
});

const overallCustomersReportItems = computed(() => {
  return props.basisReportItems.filter((item) =>
    item.configs.some((config) =>
      isTargetConfig(config, {
        ...baseOptions.value,
        overallCustomers: true,
      })
    )
  );
});

const overallNonCustomersReportItems = computed(() => {
  return props.basisReportItems.filter((item) =>
    item.configs.some((config) =>
      isTargetConfig(config, {
        ...baseOptions.value,
        overall: true,
      })
    )
  );
});

const invalidReportItems = computed(() => {
  return props.basisReportItems.filter((item) =>
    item.configs.some((config) =>
      isTargetConfig(config, {
        ...baseOptions.value,
        isInvalid: true,
      })
    )
  );
});

const reportTypeItemMaps = computed<
  Record<
    "individual" | "overall",
    Record<"customers" | "nonCustomers", BasisReportItemClient[]>
  >
>(() => {
  return {
    individual: {
      customers: individualCustomersReportItems.value,
      nonCustomers: individualNonCustomersReportItems.value,
    },
    overall: {
      customers: overallCustomersReportItems.value,
      nonCustomers: overallNonCustomersReportItems.value,
    },
  };
});

// modal

const formModal = ref();

function openFormModal(id?: number) {
  formModal.value?.openModal(id);
}
</script>

<style scoped lang="scss">
.table-wrapper {
  overflow: scroll;
}
</style>
