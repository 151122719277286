<template>
  <div>
    <table class="table table-bordered table-sm table-striped align-middle">
      <thead>
        <tr class="sticky">
          <th rowspan="2">設定内容/ラベル種別</th>
          <th
            v-for="group in labelGroups"
            :key="`label-group-${group.groupType}-${group.name}`"
            :colspan="
              group.groupType === 'department_owner'
                ? eachLabelGroupCounts[group.name]?.length
                : eachLabelGroupCounts[group.groupType]?.length
            "
          >
            <span>{{ group.name }}</span>
          </th>
        </tr>
        <tr class="sticy">
          <template v-for="group in labelGroups">
            <td
              v-for="label in group.groupType === 'department_owner'
                ? eachLabelGroupCounts[group.name]
                : eachLabelGroupCounts[group.groupType]"
              :key="label.id"
              class="text-center"
            >
              <div class="d-flex flex-column align-items-center">
                <span class="text-nowrap">{{ labelToStr(label) }}</span>
                <BasicButton
                  v-if="editMode"
                  small
                  rounded
                  button-type="submit"
                  variant="danger"
                  icon="pi pi-trash"
                  @click="destroyManagementLabel(label, group)"
                />
              </div>
            </td>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="key in managementLabelConfigKeys"
          :key="`body-config-${key}`"
        >
          <td>{{ configKeyToStr(key) }}</td>
          <template v-for="group in labelGroups">
            <td
              v-for="label in group.groupType === 'department_owner'
                ? eachLabelGroupCounts[group.name]
                : eachLabelGroupCounts[group.groupType]"
              :key="label.id"
              class="text-center"
            >
              <CheckForm
                :value="label.config[key]"
                label=""
                :form-id="`${label.id}-config-${key}`"
                :unchecked-value="false"
                is-valid
                without-label
                :disabled="isDisableb(label, key)"
                @update:value="updateManagementLabelConfig(label, key, $event)"
              />
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { labelToStr } from "/@/modules/managementLabel";
import { BasicButton, CheckForm } from "/@/vue/components/Atom";
import {
  ManagementLabelClient,
  managementLabelConfigKeys,
  ManagementLabelConfigKey,
  LabelType,
  ManagementLabelConfigClient,
  LabelGroup,
} from "/@/types";
import { createLabelGroups } from "/@/modules/managementLabel";

const props = defineProps<{
  managementLabels: ManagementLabelClient[];
  editMode?: boolean;
}>();

const emit = defineEmits<{
  (
    e: "updateManagementLabel",
    prms: { id: number; config: ManagementLabelConfigClient }
  ): void;
  (
    e: "destroyManagementLabel",
    label: ManagementLabelClient,
    leastCount: number
  ): void;
}>();

function configKeyToStr(key: ManagementLabelConfigKey) {
  switch (key) {
    case "canShowMember":
      return "メンバーの閲覧";
    case "canShowMemberPlans":
      return "メンバーの予定閲覧";
    case "canShowMemberAbsenceApplicationPlans":
      return "メンバーの出勤・希望休申請閲覧";
    case "canShowMemberReports":
      return "メンバーの業績閲覧";
    case "canShowMemberRecords":
      return "メンバーの過去の業務データ閲覧";
    case "canShowMemberWorkTasks":
      return "メンバーの予約・架電閲覧";
    case "canShowMemberWorkScheduleCalendar":
      return "メンバーの稼働表の閲覧（グループ関係の情報のみ、編集不可）";
    case "canShowMemberWorkScheduleCalendarAdmin":
      return "メンバーの稼働表での全閲覧権限（割り当て等の編集は不可）";
    case "canShowMemberNotificationMails":
      return "業務開始終了メール受信";
    case "canShowMemberSummaryMails":
      return "定時業務状況確認メール受信";
    /*
    case "canShowMemberAttendance":
      return "メンバーの出勤簿閲覧";
    */
    case "canShowWorkMeetingTarget":
      return "対象店舗の目標の管理";
    case "canShowOrganizationSchedule":
      return "組織に割り当てられた計画の閲覧";
    case "canShowWorker":
      return "対象に関係するメンバーの閲覧";
    case "canShowWorkerSummaryMails":
      return "対象に関係するメンバーの定時業務状況確認メール受信";
  }
}

function isDisableb(
  label: ManagementLabelClient,
  key: ManagementLabelConfigKey
) {
  if (
    key.startsWith("canShowMember") &&
    label.labelType !== "department_owner"
  ) {
    return true;
  }

  if (
    key.startsWith("canShowWorker") &&
    label.labelType !== "request_organization" &&
    label.labelType !== "work_place"
  ) {
    return true;
  }

  /*
  if (
    key.startsWith("canShowWork") &&
    !key.startsWith("canShowWorker") &&
    label.labelType !== "request_organization" &&
    label.labelType !== "work_place"
  ) {
    return true;
  }
  */

  if (key.startsWith("canShowWork") && !key.startsWith("canShowWorker")) {
    return true;
  }

  if (
    key !== "canShowMember" &&
    key.startsWith("canShowMember") &&
    label.config.canShowMember === false
  ) {
    return true;
  }

  if (
    key !== "canShowWorker" &&
    key.startsWith("canShowWorker") &&
    label.config.canShowWorker === false
  ) {
    return true;
  }

  return false;
}

const labelGroups = computed(() => {
  return createLabelGroups(props.managementLabels);
});

const eachLabelGroupCounts = computed(() => {
  let m: Partial<Record<string | LabelType, ManagementLabelClient[]>> = {};

  labelGroups.value.forEach((group) => {
    if (group.groupType === "department_owner") {
      m[group.name] = props.managementLabels.filter(
        (label) =>
          label.labelType.startsWith(group.groupType) &&
          label.departmentName === group.name
      );
    } else {
      m[group.groupType] = props.managementLabels.filter(
        (label) => label.labelType === group.groupType
      );
    }
  });

  return m;
});

function updateManagementLabelConfig(
  label: ManagementLabelClient,
  key: ManagementLabelConfigKey,
  value: boolean
) {
  // parent case

  if ((key === "canShowMember" || key === "canShowWorker") && value === false) {
    const dependancies: ManagementLabelConfigKey[] =
      managementLabelConfigKeys.filter((k) => k.startsWith(key));

    const newConfig: Record<ManagementLabelConfigKey, boolean> = {
      [key]: false,
    };
    dependancies.forEach((k) => {
      newConfig[k] = false;
    });

    emit("updateManagementLabel", {
      id: label.id,
      config: { ...label.config, ...newConfig },
    });
  } else {
    emit("updateManagementLabel", {
      id: label.id,
      config: { ...label.config, [key]: value },
    });
  }
}

function leastCount(group: LabelGroup) {
  return group.groupType === "department_owner"
    ? eachLabelGroupCounts.value[group.name].length
    : eachLabelGroupCounts.value[group.groupType].length;
}

function destroyManagementLabel(
  label: ManagementLabelClient,
  group: LabelGroup
) {
  emit("destroyManagementLabel", label, leastCount(group));
}
</script>

<style scoped></style>
