import { z } from "/@/modules/zodUtils";
import zod from "zod";

export const carriers = [
  "docomo",
  "softbank",
  "au",
  "yahoo",
  "uq",
  //"office",
  //"home",
] as const;
export const CarriersScheme = z.enum(carriers);
export type Carrier = zod.infer<typeof CarriersScheme>;
