import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { PrefectureScheme } from "/@/types/address.d";
import {
  OrganizationMemberRolesScheme,
  OrganizationMemberScheme,
} from "/@/types/organizationMember.d";
import { OrganizationCategoryScheme } from "/@/types/category.d";
import { WorkCategoriesScheme } from "/@/types/workCategories.d";
import { OrganizationOwnerGroupScheme } from "/@/types/organizationOwnerGroup.d";
import {
  ScheduleInOrganizationScheme,
  ScheduleInOrganizationMemberScheme,
} from "/@/types/plan.d";
import { WorkRecordClientScheme } from "/@/types/workRecord.d";
import { ManagementLabelClientScheme } from "/@/types/managementLabel.d";
import { OrganizationEventInvitationClientScheme } from "/@/types/organizationEvent.d";

export const WorkCategoryMapScheme = z.object({
  key: WorkCategoriesScheme,
  ja: z.string(),
});

export type WorkCategoryMap = zod.infer<typeof WorkCategoryMapScheme>;

const PrefectureMapScheme = z.object({
  key: PrefectureScheme,
  ja: z.string(),
});

export type PrefectureMap = zod.infer<typeof PrefectureMapScheme>;

export const ContractRelationOrganizationMemberScheme = z.object({
  id: z.number(),
  userName: z.string(),
  todayWorkRecords: WorkRecordClientScheme.array().optional(),
  todayWorkSchedules: ScheduleInOrganizationMemberScheme.array().optional(),
});

export const ContractRelationOrganizationScheme = z.object({
  id: z.number(),
  name: z.string(),
  //organizationMembers: OrganizationMemberScheme.array(),
  ownerNames: z.string().array(),
  organizationMembers: ContractRelationOrganizationMemberScheme.array(),
  todayWorkSchedules: ScheduleInOrganizationScheme.array().optional(),
});

export type ContractRelationOrganization = zod.infer<
  typeof ContractRelationOrganizationScheme
>;

export const OrganizationScheme = z.object({
  id: z.number(),
  organizationMemberId: z.number(),
  name: z.string(),
  phoneNumber: z.string(),
  postcode: z.string(),
  address: z.string(),
  addressAfter: z.string().optional(),
  asEntrust: z.boolean(),
  roles: OrganizationMemberRolesScheme.array().optional(),
  organizationEventInvitations: OrganizationEventInvitationClientScheme.array(),
  managementLabels: ManagementLabelClientScheme.array().optional().nullable(),
  categories: OrganizationCategoryScheme.array().optional(),
  prefectureMaps: PrefectureMapScheme.array().optional(),
  organizationMembers: OrganizationMemberScheme.array().optional(),
  organizationOwnerGroups: OrganizationOwnerGroupScheme.array().optional(),
  contractors: ContractRelationOrganizationScheme.array(),
  subContractors: ContractRelationOrganizationScheme.array(),
});

export type Organization = zod.infer<typeof OrganizationScheme>;
