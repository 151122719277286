import { axios } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import { isToday } from "/@/modules/luxon";
import { OrganizationMemberSchedule } from "/@/types";
import type { Organization, OrganizationMemberSchedulePlan } from "/@/types";

export function useOrganizationMemberSchedule() {
  async function createOrganizationMemberSchedule(
    schedule: OrganizationMemberSchedule
  ) {
    try {
      const { data }: { data: boolean } = await axios.post(
        `/api/v1/organization_members/${schedule.organizationMemberId}/organization_member_schedules`,
        {
          organizationMemberSchedules: schedule,
        }
      );
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function destroyOrganizationMemberSchedule(
    schedule: OrganizationMemberSchedule,
    organization: Organization
  ) {
    try {
      await axios.delete(
        `/api/v1/organization_member_schedules/${schedule.id}?destroy_by=${organization.id}`
      );

      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  // plans

  function getMyTodaySchedules(
    schedules: OrganizationMemberSchedulePlan[] | undefined,
    organizationMemberId: number | undefined
  ) {
    if (!schedules || !organizationMemberId) {
      return [];
    }

    return schedules.filter(
      (s) =>
        s.organizationMemberId == organizationMemberId && isToday(s.targetDate)
    );
  }

  return {
    createOrganizationMemberSchedule,
    destroyOrganizationMemberSchedule,

    getMyTodaySchedules,
  };
}
