<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="18"
    height="18"
    viewBox="0 0 22.6 22.8"
  >
    <defs>
      <filter
        id="楕円形_64"
        x="0"
        y="0"
        width="22.6"
        height="22.8"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="線_135"
        x="10.25"
        y="3.249"
        width="2.1"
        height="9.3"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur-2" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-2" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="線_136"
        x="10.25"
        y="10.249"
        width="7.1"
        height="2.3"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur-3" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-3" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="clock_icon_gray_medium" transform="translate(0.3)">
      <g transform="matrix(1, 0, 0, 1, -0.3, 0)" filter="url(#楕円形_64)">
        <g
          id="楕円形_64-2"
          data-name="楕円形 64"
          transform="translate(0.3)"
          fill="none"
          stroke="#8a8a8e"
          stroke-width="1.5"
        >
          <circle cx="11" cy="11" r="11" stroke="none" />
          <circle cx="11" cy="11" r="10.25" fill="none" />
        </g>
      </g>
      <g transform="matrix(1, 0, 0, 1, -0.3, 0)" filter="url(#線_135)">
        <line
          id="線_135-2"
          data-name="線 135"
          y2="7"
          transform="translate(11.3 4)"
          fill="none"
          stroke="#8a8a8e"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
      </g>
      <g transform="matrix(1, 0, 0, 1, -0.3, 0)" filter="url(#線_136)">
        <line
          id="線_136-2"
          data-name="線 136"
          x1="5"
          transform="translate(11.3 11)"
          fill="none"
          stroke="#8a8a8e"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
      </g>
    </g>
  </svg>
</template>
