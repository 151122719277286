<template>
  <div>
    <table
      class="table table-responsive table-bordered table-striped small table-sm member-table"
    >
      <thead>
        <tr>
          <th>名前</th>
          <th>稼動数</th>
          <th>所定労働日数</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="member in members" :key="`operation-info-${member.id}`">
          <td>{{ member.user.name }}</td>
          <td>
            {{ memberSchedules(member).length }}
          </td>
          <td>{{ targetMonthLabor(member)?.prescribedWorkingDays || "-" }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { isSameMonth } from "/@/modules/luxon";
import {
  OrganizationMember,
  OrganizationMemberSchedulePlan,
  DateMap,
} from "/@/types";

const props = defineProps<{
  members: OrganizationMember[];
  schedules: OrganizationMemberSchedulePlan[];
  selectedDate: DateMap;
}>();

function memberSchedules(member: OrganizationMember) {
  return props.schedules.filter(
    (schedule) =>
      !schedule.absence &&
      schedule.organizationMemberId === member.id &&
      !schedule.workSchedules?.every((s) => s.canceled)
  );
}

function targetMonthLabor(member: OrganizationMember) {
  return member.organizationMemberLabors?.find((labor) =>
    isSameMonth(props.selectedDate, labor.targetDate)
  );
}
</script>

<style scoped>
table.member-table {
  height: auto;
}
</style>
