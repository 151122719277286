<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="37.6"
    height="5.8"
    viewBox="0 0 37.6 5.8"
  >
    <defs>
      <filter
        id="minus_button_blue"
        x="0"
        y="0"
        width="37.6"
        height="5.8"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#minus_button_blue)">
      <path
        id="minus_button_white-2"
        data-name="minus_button_blue"
        d="M0,0H32"
        transform="translate(2.8 2.5)"
        fill="#4285f5"
        stroke="#4285f5"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="5"
      />
    </g>
  </svg>
</template>
