<template>
  <div id="app">
    <Header
      v-if="!withoutHeader"
      :user="user"
      :is-admin-page="isAdminPage"
      @logout="logoutProcess"
    />

    <div v-if="showAdminBtn" class="goto-admin-btn d-flex justify-content-end">
      <div>
        <BasicButton
          icon="pi pi-user"
          label="管理者ページへ"
          variant="warning"
          @click="gotoAdmin"
        />
      </div>
    </div>

    <div
      :class="{
        'router-view': true,
        'is-admin-page': isAdminPage,
        'container-fluid': true,
        'd-flex': true,
        'flex-column': true,
        'align-items-center': true,
        'justify-content-start': true,
        'with-breadcrumb': !isHome && !isAdminPage,
      }"
    >
      <router-view></router-view>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useAuthorize, useRouterUtil } from "/@/vue/composables";
import { Header } from "/@/vue/components/Layouts";
import { BasicButton } from "/@/vue/components/Atom";
import { computed, onMounted } from "vue";

onMounted(() => {
  function callback(mutationsList: MutationRecord[], obs: MutationObserver) {
    // primevue で追加される panel の filterinput に対して Enter キーで閉じててしまう問題への対処
    // TODO:
    // 以下は Dropdown で開かれる panel 内の filterinput にのみ対応しているが、
    // 再帰関数でもう少し汎用的に対応できるようにできそう

    for (const mutation of mutationsList) {
      const panel = Array.from(mutation.addedNodes).find(
        (node) => node?.dataset?.pcSection === "panel"
      );

      if (panel) {
        const header = Array.from(panel.childNodes).find(
          (node) => node?.dataset?.pcSection === "header"
        );

        if (header) {
          const filtercontainer = Array.from(header.childNodes).find(
            (node) => node?.dataset?.pcSection === "filtercontainer"
          );

          if (filtercontainer) {
            const filterinput = Array.from(filtercontainer.childNodes).find(
              (node) => node?.dataset?.pcSection === "filterinput"
            );

            if (filterinput) {
              filterinput.addEventListener(
                "keydown",
                (e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                  }
                },
                true
              );
            }
          }
        }
      }
    }
  }

  const target = document.body;
  const obs = new MutationObserver(callback);
  obs.observe(target, { childList: true });
});

const { user, admin, logout } = useAuthorize();

async function logoutProcess() {
  await logout();
}

const { currentRoute, goto } = useRouterUtil();

const isHome = computed(() => {
  return currentRoute.value.name?.toString().startsWith("Home");
});

const isAdmin = computed(() => {
  return !!admin.value;
});

const isAdminPage = computed(() => {
  return currentRoute.value.name?.toString().startsWith("Admins");
});

const showAdminBtn = computed(() => {
  return isAdmin.value && !isAdminPage.value;
});

const withoutHeader = computed(() => {
  return isAdminPage.value;
});

function gotoAdmin() {
  goto({ name: "AdminsHome" });
}
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;

  .router-view {
    //padding-top: calc(0.5rem + #{$navbar-mobile-height});
    padding-bottom: 1rem;
    height: calc(100% - var(--header-height));
    overflow-y: scroll;
    padding-top: 0.5rem;

    &.is-admin-page,
    &.is-home {
      height: 100%;
    }

    &.with-breadcrumb {
      height: calc(100% - var(--header-height) - 50px);
    }

    > * {
      max-width: $pc-width;
      width: 100%;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      padding-bottom: 5rem;
    }

    &.without-header {
      padding-left: 0.5rem;

      > * {
        max-width: $pc-without-header-width;
      }
    }
  }

  .open-btn {
    position: fixed;
    left: 0;
    top: 1.3rem;
    z-index: 10;
  }

  .goto-admin-btn {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 100;
  }
}
</style>
