<template>
  <div>
    <PageHeader page-title="組織情報" hide-back-btn />
    <div v-for="o in organizations" :key="o.id" class="p-2">
      <OrganizationInfo
        v-if="!o.asEntrust"
        :organization="o"
        :loading="loading"
        @update-organization="updateOrganization"
        @select-member="openMemberFormModal(o.id, $event)"
        @goto-labor-info="gotoLaborInfo"
      />
    </div>

    <teleport to="body">
      <OrganizationMemberFormModal
        ref="memberFormModal"
        :member="selectedOrganizationMember"
        :owner-group-items="ownerGroups"
        :management-labels="managementLabels || []"
        @submit="handleUpdate"
        @close-modal="closeMemberFormModal"
        @add-owner-group="handleAddOwner"
      />
    </teleport>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import {
  useOrganization,
  useOrganizationMember,
  useOrganizationOwnerGroup,
  useRouterUtil,
  useManagementLabel,
} from "/@/vue/composables";
import { PageHeader } from "/@/vue/components/Layouts";
import {
  OrganizationInfo,
  OrganizationMemberFormModal,
} from "/@/vue/components/Organisms";
import type { Organization, OrganizationMemberForm } from "/@/types";

// organization

const { updateAPI, getOrganizations } = useOrganization();
const { data: organizations } = getOrganizations();

const loading = ref<boolean>(false);

async function updateOrganization(organization: Organization) {
  loading.value = true;

  if (await updateAPI(organization)) {
    alert("更新に成功しました。");
  } else {
    alert("更新に失敗しました。");
  }

  loading.value = false;
}

// organization member

const memberFormModal = ref();
const selectedOrganizationId = ref<number | undefined>();
const selectedOrganization = computed(() => {
  if (!organizations.value || !selectedOrganizationId.value) {
    return;
  }

  return organizations.value.find((o) => o.id == selectedOrganizationId.value);
});

const isFRYTHOwner = computed(() => {
  return selectedOrganization.value?.name === "(株)FRYTH";
});

const selectedOrganizationMemberId = ref<number | undefined>();

const selectedOrganizationMember = computed(() => {
  if (!selectedOrganization.value || !selectedOrganizationMemberId.value) {
    return;
  }

  const ownMembers = selectedOrganization.value.organizationMembers || [];
  const subContractorMembers =
    selectedOrganization.value.subContractors
      .map((sub) => sub.organizationMembers)
      .flat() || [];

  return [...ownMembers, ...subContractorMembers].find(
    (om) => om.id == selectedOrganizationMemberId.value
  );
});

function openMemberFormModal(organizationId: number, memberId: number) {
  selectedOrganizationId.value = organizationId;
  selectedOrganizationMemberId.value = memberId;

  if (!isFRYTHOwner.value) return;

  memberFormModal.value.openModal();
}

function closeMemberFormModal(withClose = false) {
  if (withClose) {
    memberFormModal.value.closeModal();
  }

  selectedOrganizationId.value = undefined;
  selectedOrganizationMemberId.value = undefined;
}

const { updateOrganizationMember } = useOrganizationMember();

async function handleUpdate(form: OrganizationMemberForm) {
  if (await updateOrganizationMember(form)) {
    alert("更新成功");
    closeMemberFormModal(true);
  } else {
    alert("更新に失敗しました");
  }
}

// group

const ownerGroups = computed(() => {
  if (!selectedOrganization.value) {
    return [];
  }

  return selectedOrganization.value.organizationOwnerGroups || [];
});

const { createGroup } = useOrganizationOwnerGroup();

async function handleAddOwner() {
  if (!selectedOrganization.value) {
    return;
  }

  const name = window.prompt(`作成するグループの名前を入力してください`);

  if (!name) {
    return;
  }

  if (await createGroup({ id: selectedOrganization.value.id, name })) {
    alert("作成に成功しました。");
  } else {
    alert("作成に失敗しました。");
  }
}

// labor info

const { goto } = useRouterUtil();

function gotoLaborInfo(id: number) {
  goto({ name: "LaborInfosShow", query: { id } });
}

// management label

const { getManagementLabels } = useManagementLabel();
const { data: managementLabels } = getManagementLabels(selectedOrganization);
</script>

<style scoped></style>
