import zod from "zod";
import { z } from "/@/modules/zodUtils";

export const prefectures = [
  "ishikawa",
  "toyama",
  "fukui",
  "shizuoka",
  "aichi",
  "other",
] as const;
export const PrefectureScheme = z.enum(prefectures);
export type Prefecture = zod.infer<typeof PrefectureScheme>;
