import zod from "zod";
import { DateTimeScheme, z } from "/@/modules/zodUtils";
import { CarriersScheme } from "/@/types/carriers.d";
import { WorkCategoriesScheme } from "/@/types/workCategories.d";
import { OrganizationCategoryScheme } from "/@/types/category.d";

export const changeableModels = ["WorkRecordEntryReport"] as const;
export const ChangeableModelScheme = zod.enum(changeableModels);
export type ChangeableModel = zod.infer<typeof ChangeableModelScheme>;

export const editRequestStatuses = ["pending", "accept", "reject"] as const;
export const EditRequestStatusScheme = zod.enum(editRequestStatuses);
export type EditRequestStatus = zod.infer<typeof EditRequestStatusScheme>;

export const editRequestOperationTypes = ["update", "destroy"] as const;
export const EditRequestOperationTypeScheme = zod.enum(
  editRequestOperationTypes
);
export type EditRequestOperationType = zod.infer<
  typeof EditRequestOperationTypeScheme
>;

export const EditRequestScheme = z.object({
  organizationMemberId: z.number(),
  changeableType: ChangeableModelScheme,
  changeableId: z.number(),
  dataBeforeChange: z.any(),
  dataAfterChange: z.any(),
  requestStatus: EditRequestStatusScheme,
  operationType: EditRequestOperationTypeScheme,
});

export const EditRequestClientScheme = EditRequestScheme.extend({
  id: z.number(),
  organizationMemberName: z.string(),
  changeDiff: z.any(),
  jsonBeforeChange: z.any(),
  jsonAfterChange: z.any(),
  workCategory: WorkCategoriesScheme,
  workCarrier: CarriersScheme,
  workPlaceName: z.string().optional(),
  workTypeName: z.string().optional(),
  workTypeCategories: OrganizationCategoryScheme.array(),
  createdAt: DateTimeScheme,
});
export type EditRequestClient = zod.infer<typeof EditRequestClientScheme>;
