<template>
  <template v-if="places.length">
    <template
      v-for="(place, idx) in places"
      :key="`results-table-body-tr-${place}`"
    >
      <tr>
        <td
          v-if="ownerMode && idx === 0"
          class="sticky"
          :rowspan="places.length || 1"
        >
          <div
            v-if="targetDateReports.length"
            class="d-flex justify-content-center"
          >
            <BasicButton
              rounded
              small
              slim
              icon="pi pi-pencil"
              @click="openEditModal(d.dateKey)"
            />
          </div>
        </td>
        <td
          v-if="idx === 0"
          class="sticky td-date"
          :class="{
            [getWeekdayColor(d, jpHolidays)]: true,
          }"
          :rowspan="places.length || 1"
        >
          {{ basicFormatter(d.dateKey, "onlyDateDay") }}
        </td>
        <td
          v-if="
            showRequestOrganization &&
            (showEditMode || !hideMapBase.requestOrganization)
          "
          class="text-nowrap td-request-organization"
        >
          {{
            `${placeCarrierToStr(place)}${
              shorterMode ? place.shopShortName : place.shopName
            }`
          }}
        </td>
        <td
          v-if="showEditMode || !hideMapBase.workPlace"
          class="text-nowrap td-work-place"
        >
          {{ shorterMode ? place.placeShortName : place.placeName }}
        </td>
        <td
          v-if="showEditMode || !hideMapBase?.workCategory"
          class="td-work-category"
        >
          {{ workCategoriesByPlace[placeToKey(place)]?.join("・") }}
        </td>
        <td
          v-if="
            selectedFilter !== 'member' &&
            (showEditMode || !hideMapBase?.workerNumber)
          "
          class="text-nowrap td-worker-number"
        >
          {{ workerNamesByPlace[placeToKey(place)]?.length || "" }}
        </td>
        <td
          v-if="
            selectedFilter !== 'member' &&
            (showEditMode || !hideMapBase?.workerNames)
          "
          class="text-nowrap td-worker-names"
        >
          {{ workerNamesByPlace[placeToKey(place)]?.join("・") }}
        </td>
        <ReportItemsTds
          :category-carrier-keys="categoryCarrierKeys"
          :customers-items="customersItems"
          :indivi-items="indiviItems"
          :overall-items="overallItems"
          :work-reports="
            targetDateReports.filter(
              (r) =>
                r.eventPlaceName === place.placeName ||
                r.workPlaceName === place.placeName
            )
          "
          :tasks="
            targetDateTaskReports.filter(
              (t) =>
                (t.entryEventPlaceName || t.entryWorkPlaceName) ===
                place.placeName
            )
          "
          :hide-map="hideMap"
          :show-edit-mode="showEditMode"
        />
      </tr>
    </template>
  </template>
  <template v-else>
    <tr v-if="!showOnlyWorkedDays">
      <td v-if="ownerMode" class="sticky"></td>
      <td
        class="sticky"
        :class="{
          [getWeekdayColor(d, jpHolidays)]: true,
        }"
      >
        {{ basicFormatter(d.dateKey, "onlyDateDay") }}
      </td>
      <td v-if="!hideMapBase.requestOrganization" class="text-nowrap"></td>
      <td v-if="!hideMapBase.workPlace" class="text-nowrap"></td>
      <td v-if="!hideMapBase.workCategory" class="text-nowrap"></td>
      <td
        v-if="selectedFilter !== 'member' && !hideMapBase.workerNumber"
        class="text-nowrap"
      ></td>
      <td
        v-if="selectedFilter !== 'member' && !hideMapBase.workerNames"
        class="text-nowrap"
      ></td>
      <ReportItemsTds
        :category-carrier-keys="categoryCarrierKeys"
        :customers-items="customersItems"
        :indivi-items="indiviItems"
        :overall-items="overallItems"
        :work-reports="targetDateReports"
        :tasks="targetDateTaskReports"
        :hide-map="hideMap"
        :show-edit-mode="showEditMode"
      />
    </tr>
  </template>
</template>

<script setup lang="ts">
import _ from "lodash";
import { computed } from "vue";
import { shorter, translater } from "/@/modules/string";
import { basicFormatter, getWeekdayColor, isSameDate } from "/@/modules/luxon";
import { BasicButton } from "/@/vue/components/Atom";
import {
  DateForCalendar,
  WorkReport,
  WorkTaskClient,
  Carrier,
  CategoryCarrierKey,
  CategoryCarrierReportItemsMap,
  JpHolidays,
  HideMap,
  HideMapBase,
  FilterKey,
} from "/@/types";
import ReportItemsTds from "./ReportItemsTds.vue";

const props = defineProps<{
  d: DateForCalendar;
  customersItems: CategoryCarrierReportItemsMap;
  indiviItems: CategoryCarrierReportItemsMap;
  overallItems: CategoryCarrierReportItemsMap;
  categoryCarrierKeys: CategoryCarrierKey[];
  workReports: WorkReport[];
  workTasks: WorkTaskClient[];
  ownerMode: boolean;
  showEditForm?: boolean;
  jpHolidays?: JpHolidays;
  hideMap: HideMap;
  hideMapBase: HideMapBase;
  showEditMode?: boolean;
  showOnlyWorkedDays?: boolean;
  shorterMode?: boolean;
  showRequestOrganization?: boolean;
  selectedFilter: FilterKey;
}>();

const emit = defineEmits<{
  (e: "openEditModal", dateKey: string): void;
}>();

const targetDateReports = computed(() => {
  return props.workReports.filter((report) =>
    isSameDate(report.targetDate, props.d.dateKey)
  );
});

const targetDateTaskReports = computed(() => {
  return props.workTasks.filter((task) => {
    return isSameDate(
      // @ts-ignore
      task.countInContractedDate ? task.contractedAt : task.createdAt,
      props.d.dateKey
    );
  });
});

// edit form

function openEditModal(dateKey: string) {
  emit("openEditModal", dateKey);
}

const shopNames = computed(() => {
  const uniqShopNames = [
    ...new Set(targetDateReports.value.map((report) => report.workPlaceName)),
  ];

  return uniqShopNames;
});

type PlaceObj = {
  carrier: Carrier;
  shopName: string;
  shopShortName?: string;
  placeName: string;
  placeShortName?: string;
};

function placeCarrierToStr(place: PlaceObj) {
  switch (place.carrier) {
    case "au":
      return "au";
    case "docomo":
      return "ds";
    case "softbank":
      return "SB";
    case "yahoo":
      return "YM";
    case "uq":
      return "UQ";
    default:
      return "";
  }
}

const places = computed<PlaceObj[]>(() => {
  const uniqPlaceNames = _.uniqWith(
    targetDateReports.value.map((report) => ({
      carrier: report.workCarrier,
      shopName: report.workPlaceName,
      shopShortName:
        report.workPlaceShortName || shorter(report.workPlaceName, 3),
      placeName: report.eventPlaceName || report.workPlaceName,
      placeShortName:
        report.eventPlaceShortName ||
        report.workPlaceShortName ||
        shorter(report.eventPlaceName || report.workPlaceName, 3),
    })),
    _.isEqual
  );

  return uniqPlaceNames;
});

function placeToKey(place: PlaceObj): string {
  return place.shopName + place.placeName;
}

function isSamePlace(report: WorkReport, place: PlaceObj) {
  return (
    report.eventPlaceName === place.placeName ||
    report.workPlaceName === place.placeName
  );
}

type ByPlaceObj = Partial<Record<string, string[]>>;

const workCategoriesByPlace = computed<ByPlaceObj>(() => {
  let m: ByPlaceObj = {};

  places.value.forEach((place) => {
    const key = placeToKey(place);

    const uniqCategories = [
      ...new Set(
        targetDateReports.value
          .filter((report) => isSamePlace(report, place))
          .map((report) => report.workCategory)
      ),
    ];

    m[key] = uniqCategories.map((c) => translater(c, props.shorterMode));
  });

  return m;
});

const workerNamesByPlace = computed<ByPlaceObj>(() => {
  let m: ByPlaceObj = {};

  places.value.forEach((place) => {
    const key = placeToKey(place);

    const uniqNames = [
      ...new Set(
        targetDateReports.value
          .filter((report) => isSamePlace(report, place))
          .map((report) => report.organizationMemberName)
          .filter((b) => b)
      ),
    ];

    m[key] = uniqNames.map((n) => n.split(" ")[0]);
  });

  return m;
});
</script>

<style scoped>
td:nth-child(2) {
  left: 40.1px;
}

td:nth-child(3) {
  left: 80.2px;
}

td.td-request-organization {
  max-width: 50px;
  min-width: 50px;
}
td.td-work-place {
  max-width: 50px;
  min-width: 50px;
}

td.td-work-category {
  max-width: 40px;
  min-width: 40px;
}

td.td-worker-number {
  max-width: 30px;
  min-width: 30px;
}

td.td-worker-names {
  max-width: 100px;
  min-width: 100px;
}
</style>
