<template>
  <div class="event-place-board">
    <AdminEventPlaceListFilter
      v-model:selected-prefectures="selectedPrefectures"
      :event-places="eventPlaces"
      class="mb-3"
    />
    <div class="add-btn">
      <BasicButton
        rounded
        small
        icon="pi pi-plus"
        @click="openEventPlaceCreateModal"
      />
    </div>
    <div class="table-wrapper">
      <table class="table table-sm table-bordered table-striped">
        <thead>
          <tr role="button">
            <th class="text-nowrap cell-name">会場通称</th>
            <th class="text-nowrap cell-short-name">短縮表記</th>
            <th class="text-nowrap cell-offical-name">正式名称</th>
            <th class="text-nowrap cell-area">エリア</th>
            <th class="text-nowrap cell-postcode">郵便番号</th>
            <th class="text-nowrap cell-address">住所</th>
            <th class="text-nowrap cell-gmap">Gmap</th>
            <th class="text-nowrap cell-meeting-place">集合場所</th>
            <th class="text-nowrap cell-time">初回集合</th>
            <th class="text-nowrap cell-time">連日集合</th>
            <th class="text-nowrap cell-time">開始時間</th>
            <th class="text-nowrap cell-time">終了時間</th>
            <th class="text-nowrap cell-shops">関連店舗</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="ep in filteredEventPlaces"
            :key="`event-place-list-item-${ep.id}`"
            @click="openEventPlaceUpdateModal(ep.id)"
          >
            <td class="text-nowrap cell-name">
              {{ ep.name }}
            </td>
            <td class="cell-short-name">
              {{ ep.shortName || ep.name.slice(0, 3) }}
            </td>
            <td class="text-nowrap cell-offical-name">{{ ep.venueName }}</td>
            <td class="cell-are">{{ translater(ep.prefecture) }}</td>
            <td class="text-nowrap cell-postcode">{{ ep.postcode }}</td>
            <td class="cell-address">
              <TooltipWrapper
                v-if="ep.address"
                :tooltip-title="ep.address"
                :content-id="`organization-row-calendar-item-`"
              >
                <span>{{ ep.address }}</span>
              </TooltipWrapper>
            </td>

            <td
              class="text-nowrap cell-gmap"
              :style="{ color: 'var(--primary-color)' }"
            >
              <a v-if="ep.gmapUrl" :href="ep.gmapUrl" target="_blank">Gmap</a>
            </td>
            <td class="cell-meeting-place">{{ ep.meetingPlace }}</td>
            <td class="cell-time">
              {{ basicFormatter(ep.firstMeetingAt, "onlyTime") }}
            </td>
            <td class="cell-time">
              {{ basicFormatter(ep.meetingAt, "onlyTime") }}
            </td>
            <td class="cell-time">
              {{ basicFormatter(ep.startAt, "onlyTime") }}
            </td>
            <td class="cell-time">
              {{ basicFormatter(ep.finishAt, "onlyTime") }}
            </td>
            <td class="cell-shops">
              <div v-if="ep.eventPlaceSelectedPlaces.length">
                <span>{{
                  ep.eventPlaceSelectedPlaces
                    .map((sp) => sp.workPlaceName || "")
                    .join("・")
                }}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <teleport to="body">
      <AdminEventPlaceUpdateModal
        ref="updateEventPlaceModal"
        :event-places="eventPlaces"
        :work-places="workPlaces"
        @submit="$emit('updateEventPlace', $event)"
        @destroy="$emit('destroyEventPlace', $event)"
      />
      <AdminEventPlaceCreateModal
        ref="createEventPlaceModal"
        :work-places="workPlaces"
        :event-places="eventPlaces"
        @submit="$emit('createEventPlace', $event)"
      />
    </teleport>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { basicFormatter } from "/@/modules/luxon";
import { translater } from "/@/modules/string";
import { BasicButton, PlusIcon } from "/@/vue/components/Atom";
import { TooltipWrapper } from "/@/vue/components/Molecules";
import {
  WorkPlace,
  EventPlace,
  EventPlaceClient,
  EventPlaceUpdate,
  Prefecture,
} from "/@/types";

import AdminEventPlaceUpdateModal from "./AdminEventPlaceUpdateModal.vue";
import AdminEventPlaceCreateModal from "./AdminEventPlaceCreateModal.vue";
import AdminEventPlaceListFilter from "./AdminEventPlaceListFilter.vue";

const props = defineProps<{
  eventPlaces: EventPlaceClient[];
  workPlaces: WorkPlace[];
}>();

defineEmits<{
  (e: "updateEventPlace", prms: EventPlaceUpdate): void;
  (e: "createEventPlace", prms: EventPlace): void;
  (e: "destroyEventPlace", id: number): void;
}>();

// filter

const selectedPrefectures = ref<Prefecture[]>(["ishikawa"]);

const filteredEventPlaces = computed(() => {
  return props.eventPlaces.filter((ep) =>
    selectedPrefectures.value.includes(ep.prefecture)
  );
});

// modal

const updateEventPlaceModal = ref();

function openEventPlaceUpdateModal(id: number) {
  updateEventPlaceModal.value.openModal(id);
}

const createEventPlaceModal = ref();

function openEventPlaceCreateModal() {
  createEventPlaceModal.value.openModal();
}
</script>

<style scoped lang="scss">
.event-place-board {
  position: relative;

  .add-btn {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.table-wrapper {
  overflow-x: scroll;

  .cell-name {
    max-width: 200px;
    min-width: 200px;
  }

  .cell-short-name {
    max-width: 70px;
    min-width: 70px;
  }

  .cell-offical-name {
    max-width: 100px;
    min-width: 100px;
  }

  .cell-area {
    max-width: 40px;
    min-width: 40px;
  }

  .cell-shops {
    max-width: 120px;
    min-width: 120px;
  }

  .cell-meeting-place {
    max-width: 90px;
    min-width: 90px;
  }

  .cell-time {
    max-width: 60px;
    min-width: 60px;
  }

  .cell-postcode {
    max-width: 80px;
    min-width: 80px;
  }

  .cell-address {
    max-width: 200px;
    min-width: 200px;
  }

  .cell-gmap {
    max-width: 60px;
    min-width: 60px;
  }
}
</style>
