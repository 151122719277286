import _ from "lodash";

export function removeNthElement<T>(arr: T[], n: number): T[] {
  // 配列の長さよりも大きいインデックスまたは負のインデックスが渡された場合は、配列をそのまま返す
  if (n >= arr.length || n < 0) {
    return arr;
  }

  return [...arr.slice(0, n), ...arr.slice(n + 1)];
}

export function addToNthElement<T>(elm: T, arr: T[], n: number): T[] {
  if (n > arr.length || n < 0) {
    return arr;
  }

  return [...arr.slice(0, n), elm, ...arr.slice(n)];
}

export const removeUndefinedFilterFn = (item: any): item is any =>
  item !== undefined;

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  it("removeNthElemnt", () => {
    expect(removeNthElement([1, 2, 3], 2)).toStrictEqual([1, 2]);
    expect(removeNthElement([], 0)).toStrictEqual([]);
    expect(removeNthElement([1, 2], -1)).toStrictEqual([1, 2]);
  });

  it("addToNthElemnt", () => {
    expect(addToNthElement(0, [1, 2, 3], 2)).toStrictEqual([1, 2, 0, 3]);
    expect(addToNthElement(0, [], 0)).toStrictEqual([0]);
    expect(addToNthElement(0, [1, 2], -1)).toStrictEqual([1, 2]);
  });

  it("removeUndefinedFilterFn", () => {
    expect(
      [1, 2, 3, undefined, 4].filter(removeUndefinedFilterFn)
    ).toStrictEqual([1, 2, 3, 4]);
  });
}

export function uniq(arr: any[]) {
  return [...new Set(arr)];
}

export function removeDup<T>(objs: Record<string, any>[], key: string): T {
  return _.uniqBy(objs, (obj) => obj[key]) as T;
}

export function removeDupStrict<T>(objs: T[]): T[] {
  return _.uniqWith(objs, _.isEqual);
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  it("removeDup", () => {
    expect(
      removeDup(
        [
          { key: 1, index: 2 },
          { key: 1, index: 2 },
        ],
        "index"
      )
    ).toStrictEqual([{ key: 1, index: 2 }]);
    expect(
      removeDup(
        [
          { key: 1, index: 2 },
          { key: 1, index: 2 },
          { key: 2, index: 2 },
        ],
        "key"
      )
    ).toStrictEqual([
      { key: 1, index: 2 },
      { key: 2, index: 2 },
    ]);
  });

  it("removeDupStrict", () => {
    expect(
      removeDupStrict([
        { a: 1, b: 1 },
        { a: 1, b: 2 },
        { a: 1, b: 2 },
        { a: 2, b: 1 },
        { a: 2, b: 2 },
      ])
    ).toStrictEqual([
      { a: 1, b: 1 },
      { a: 1, b: 2 },

      { a: 2, b: 1 },
      { a: 2, b: 2 },
    ]);
    expect(
      removeDupStrict([
        { category: "shop_helper", carrier: "docomo" },
        { category: "shop_helper", carrier: "docomo" },
        { category: "shop_helper", carrier: "docomo" },
      ])
    ).toStrictEqual([{ category: "shop_helper", carrier: "docomo" }]);
  });
}
