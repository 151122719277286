import { axios } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import { AbsenceApplicationPlan, SubmitApplicationOwneeForm } from "/@/types";

export function useAbsenceApplicationPlan() {
  async function createAbsenceApplicationPlan(
    application: SubmitApplicationOwneeForm | SubmitApplicationOwneeForm
  ) {
    try {
      const { data }: { data: boolean } = await axios.post(
        `/api/v1/absence_application_plans`,
        {
          absenceApplicationPlans: application,
        }
      );
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function acceptAbsenceApplicationPlan(
    application: AbsenceApplicationPlan
  ) {
    try {
      await axios.post(
        `/api/v1/absence_application_plans/${application.id}/accept`
      );

      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function deleteAbsenceApplicationPlan(
    application: AbsenceApplicationPlan
  ) {
    try {
      await axios.delete(`/api/v1/absence_application_plans/${application.id}`);

      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    createAbsenceApplicationPlan,
    acceptAbsenceApplicationPlan,
    deleteAbsenceApplicationPlan,
  };
}
