import { entryToTitle } from "/@/modules/workRecords";
import { WorkTaskClient } from "/@/types";

export function workTaskEntryToTitle(workTask: WorkTaskClient) {
  return entryToTitle(
    {
      startAt: workTask.entryStartAt,
      finishAt: workTask.entryFinishAt,
      workCategory: workTask.entryWorkCategory,
      workCarrier: workTask.entryWorkCarrier,
      workRequestOrganizationName: workTask.entryWorkRequestOrganizationName,
      workPlaceName: workTask.entryWorkPlaceName,
      workTypeName: workTask.entryWorkTypeName,
      workScheduleEventPlaceName: workTask.entryWorkScheduleEventPlaceName,
    },
    {
      withoutTime: true,
      short: false,
      withoutRequestOrganization: true,
    }
  );
}
