<template>
  <td :class="statusColor">
    <span v-if="!workRecords">...</span>
    <span v-else-if="isBeforeTodayCase">
      <template v-if="targetSchedules.length">
        {{ historyStatus }}
      </template>
      <template v-else> {{ absenceStatus }} </template>
    </span>
    <span v-else> </span>
  </td>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { isBeforeToday } from "/@/modules/luxon";
import { createAbsenceApplicationStatus } from "/@/modules/plan";
import {
  MemberSchedulePlanMap,
  MemberAbsencePlanMap,
  MemberWorkRecordMap,
} from "/@/types";

const props = defineProps<{
  memberSchedulePlanMap: MemberSchedulePlanMap;
  memberAbsencePlanMap: MemberAbsencePlanMap;
  memberWorkRecordMap: MemberWorkRecordMap;
  memberId: number;
  targetDate: string;
}>();

const isBeforeTodayCase = computed(() => {
  return isBeforeToday(props.targetDate);
});

const targetSchedules = computed(() => {
  return props.memberSchedulePlanMap[props.memberId]?.[props.targetDate] || [];
});

const targetAbsencePlans = computed(() => {
  return props.memberAbsencePlanMap[props.memberId]?.[props.targetDate] || [];
});

const workRecords = computed(() => {
  return props.memberWorkRecordMap[props.memberId]?.[props.targetDate] || [];
});

const absenceApplicationStatus = computed(() => {
  return createAbsenceApplicationStatus(targetAbsencePlans.value);
});

const absenceStatus = computed(() => {
  if (absenceApplicationStatus.value.isHoliday) {
    return "希休";
  } else if (absenceApplicationStatus.value.isPaid) {
    return "有休";
  } else if (absenceApplicationStatus.value.isHalfday) {
    return "半休";
  } else {
    return "";
  }
});

const historyStatus = computed(() => {
  if (targetSchedules.value.every((ws) => ws.canceled)) {
    return "中止";
  }

  return workRecords.value.length ? "出" : "欠";
});

const statusColor = computed(() => {
  return {
    "text-primary": isBeforeTodayCase.value && !workRecords.value.length,
    "text-success": absenceApplicationStatus.value.isPaid,
    "text-danger": absenceApplicationStatus.value.isHoliday,
  };
});
</script>

<style scoped></style>
