<template>
  <div>
    <SimpleModal
      ref="modal"
      title="ラベルの作成"
      @cancel="closeModal"
      @submit="submit"
    >
      <ManagementLabelsForm
        v-model:name="form.name"
        v-model:label-type="form.labelType"
        v-model:department-name="form.departmentName"
        v-model:dependancy-id="form.dependancyId"
        :dependancies="dependancies"
        :department-names="departmentNames"
        :errors="errors"
        @search-work-place="searchWorkPlace"
      />
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from "vue";
import { errorHandle } from "/@/modules/error";
import { useZodScheme } from "/@/vue/composables";
import { SimpleModal } from "/@/vue/components/Molecules";
import {
  ManagementLabel,
  ManagementLabelForm,
  ManagementLabelScheme,
  ManagementLabelClient,
  Organization,
  WorkPlace,
  Carrier,
  Prefecture,
  WorkPlaceClient,
} from "/@/types";

import ManagementLabelsForm from "./ManagementLabelsForm.vue";
import { needDeps } from "/@/modules/managementLabel";

const props = defineProps<{
  managementLabels: ManagementLabelClient[];
  requestOrganizations: Organization[];
  workPlaces: WorkPlace[];
}>();

// attributes

const departmentNames = computed(() => {
  // return unique department names
  return Array.from(
    new Set(
      props.managementLabels
        .filter((l) => l.departmentName)
        .map((l) => l.departmentName || "")
    )
  ).sort();
});

// dependancies

function removeKabu(str: string) {
  return str.replace(/\(株\)|\(有\)|\(合\)/gi, "");
}

const dependancies = computed(() => {
  if (form.labelType === "request_organization") {
    return props.requestOrganizations
      .map((o) => ({ ...o, name: removeKabu(o.name) }))
      .toSorted((a: Organization, b: Organization) =>
        a.name.localeCompare(b.name)
      );
  } else if (form.labelType === "work_place") {
    return props.workPlaces.toSorted((a: WorkPlace, b: WorkPlace) =>
      a.name.localeCompare(b.name)
    );
  }

  return [];
});

const emit = defineEmits<{
  (e: "submit", params: ManagementLabel): void;
  (
    e: "searchWorkPlace",
    prms: { prefectures: Prefecture[]; carriers: Carrier[] }
  ): void;
}>();

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } = useFormAndErrors<ManagementLabelForm>(
  ManagementLabelScheme
);

function formReset() {
  form.departmentName = undefined;
  form.name = undefined;
  form.dependancyId = undefined;
  form.labelType = "department_owner";
}

onMounted(() => {
  formReset();
});

function submit() {
  try {
    startValidation.value = true;

    if (form.labelType !== "department_owner") {
      form.departmentName = undefined;
    }

    if (!needDeps(form.labelType)) {
      form.dependancyId = undefined;
    }

    const managementLabel: ManagementLabel = ManagementLabelScheme.parse(form);
    emit("submit", managementLabel);

    formReset();
  } catch (e) {
    errorHandle(e);
  }
}

function searchWorkPlace(prms: {
  carriers: Carrier[];
  prefectures: Prefecture[];
}) {
  emit("searchWorkPlace", prms);
}

// modal difinissions

const modal = ref();

function openModal() {
  modal.value.openModal();
}

function closeModal() {
  modal.value.closeModal();
}

defineExpose({
  openModal,
  closeModal,
});
</script>

<style scoped></style>
