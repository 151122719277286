<template>
  <div>
    <ContentLabel label="送信メールアドレス" content-id="work-config-mailers" />
    <div v-for="t of mailTypes" :key="t" class="mb-3">
      <div class="d-flex align-items-center mb-2">
        <ContentLabel
          :label="translater(t)"
          :content-id="`mail-type-form-${t}`"
          without-padding
          class="me-2"
        />
        <BasicButton
          variant="primary"
          small
          rounded
          icon="pi pi-plus"
          :disabled="existsEmptyForm[t]"
          @click="addMailForm(t)"
        />
      </div>
      <template
        v-for="emailForm of workConfigMailersWithInfo.filter(
          (c) => c.mailType === t
        )"
        :key="`mail-form-${t}-${emailForm.idx}`"
      >
        <div class="d-flex align-items-center mb-2">
          <FormItemEmail
            label=""
            :value="emailForm.mailAddress"
            :form-id="`mail-address-form-${emailForm.idx}`"
            :errors="{}"
            class="me-2"
            @update:value="updateMailer('mailAddress', $event, emailForm.idx)"
          />
          <BasicButton
            rounded
            small
            icon="pi pi-trash"
            variant="danger"
            @click="removeMailForm(emailForm.idx)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";
import { computed } from "vue";
import { translater } from "/@/modules/string";
import { FormItemEmail } from "/@/vue/components/Molecules";
import {
  ContentLabel,
  BasicButton,
  PlusIcon,
  TrashIcon,
} from "/@/vue/components/Atom";
import { mailTypes } from "/@/types";
import type { MailType, WorkConfigMailersForm } from "/@/types";
import { removeNthElement } from "/@/modules/array";

const props = defineProps<{
  workConfigMailers: WorkConfigMailersForm[];
}>();

const emit = defineEmits<{
  (
    e: "update:workConfigMailers",
    workConfigMailers: WorkConfigMailersForm[]
  ): void;
}>();

type WorkConfigMailersWithInfo = WorkConfigMailersForm & { idx: number };

const workConfigMailersWithInfo = computed<WorkConfigMailersWithInfo[]>(() => {
  return props.workConfigMailers.map((mailerForm, idx) => {
    return {
      ...mailerForm,
      idx,
    };
  });
});

function updateMailer(
  key: keyof WorkConfigMailersForm,
  value: string | undefined,
  idx: number
) {
  const newMailers: WorkConfigMailersForm[] = _.cloneDeep(
    props.workConfigMailers
  );

  const target = newMailers[idx];

  if (!target) {
    alert(`not found idx: ${idx}`);
    return;
  }

  if (key === "mailAddress" && typeof value === "string") {
    target[key] = value;
  }

  emit("update:workConfigMailers", newMailers);
}

type MailTypeExistsMap = { [key in MailType]: boolean };

const existsEmptyForm = computed<MailTypeExistsMap>(() => {
  const m: Partial<MailTypeExistsMap> = {};

  mailTypes.forEach((type) => {
    m[type] = props.workConfigMailers.some(
      (mailer) => mailer.mailType === type && !mailer.mailAddress
    );
  });

  return m;
});

function addMailForm(mailType: MailType) {
  if (existsEmptyForm.value[mailType]) {
    return;
  }

  emit("update:workConfigMailers", [
    ...props.workConfigMailers,
    {
      mailType: mailType,
      mailAddress: undefined,
    },
  ]);
}

function removeMailForm(idx: number) {
  emit(
    "update:workConfigMailers",
    removeNthElement(props.workConfigMailers, idx)
  );
}
</script>

<style scoped></style>
