<template>
  <div>
    <FormItemShow label="業務時間" content-id="record-at">
      <span>
        {{ basicFormatter(workRecord.startAt, "onlyTime") }}~
        {{
          workRecord.finishAt
            ? basicFormatter(workRecord.finishAt, "onlyTime")
            : "業務中"
        }}
      </span>
    </FormItemShow>

    <template
      v-for="(entry, idx) of workRecord.workRecordEntries"
      :key="entry.id"
    >
      <div
        v-if="
          !workRecord.workRecordEntries[idx].finishAt && form.workRecordEntries
        "
        class="card card-body mb-3"
      >
        <ExitForm
          v-model:lat="form.workRecordEntries[idx].finishLat"
          v-model:lng="form.workRecordEntries[idx].finishLng"
          v-model:photo="form.workRecordEntries[idx].finishPhoto"
          v-model:work-tasks="form.workRecordEntries[idx].workTasks"
          v-model:work-record-entry-reports="
            form.workRecordEntries[idx].workRecordEntryReports
          "
          :work-record-entry="entry"
          :works="[]"
          :work-places="[]"
          :need-enter-leave-report="needEnterLeaveReport"
          :need-finish-report="needFinishReport"
          :basis-report-items="basisReportItems"
          :errors="errors"
        />
      </div>

      <span v-else class="card card-body mb-3">
        {{ entryToTitle(workRecord.workRecordEntries[idx]) }}
        (業務報告入力済み)
      </span>
    </template>

    <RestTimeForm
      v-if="workingHours > 9"
      v-model:rest-times="form.restTimeEntries"
      :errors="errors"
      class="mb-3"
    />

    <div class="d-flex w-100">
      <BasicButton
        variant="danger"
        :disabled="loading"
        icon="pi pi-times-circle"
        label="キャンセル"
        class="me-2"
        @click="$emit('cancel')"
      />

      <BasicButton
        variant="primary"
        button-type="submit"
        :disabled="loading"
        icon="pi pi-upload"
        label="送信"
        @click="handleSubmit"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, computed, ref, watch } from "vue";
import { errorHandle } from "/@/modules/error";
import { countHoursBetween, luxonNow } from "/@/modules/luxon";
import { useZodScheme } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { FormItemShow } from "/@/vue/components/Molecules";
import { ExitForm, RestTimeForm } from "/@/vue/components/Organisms";
import {
  WorkRecordClient,
  WorkRecordFinishForm,
  WorkRecordFinishNeedEnterLeaveReportScheme,
  WorkRecordFinishScheme,
  BasisReportItemClient,
} from "/@/types";
import { entryToTitle } from "/@/modules/workRecords";
import { basicFormatter } from "/@/modules/luxon";

const duttyHuck = ref(3);

const props = withDefaults(
  defineProps<{
    workRecord: WorkRecordClient;
    basisReportItems: BasisReportItemClient[];
    needEnterLeaveReport?: boolean;
    needFinishReport?: boolean;
    now?: string;
  }>(),
  {
    // @ts-ignore
    now: () => luxonNow().toISO(),
  }
);

const emit = defineEmits<{
  (e: "cancel"): void;
  (e: "submit", workRecordForm: WorkRecordFinishForm): void;
}>();

const workingHours = computed(() => {
  if (!props.now) {
    return 0;
  }

  const totalScheduleWorkingHours = props.workRecord.workRecordEntries
    .map((entry) =>
      countHoursBetween(entry.workScheduleStartAt, entry.workScheduleFinishAt)
    )
    .reduce((acc, current) => acc + current, 0);

  return totalScheduleWorkingHours;
});

//

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation, targetScheme } =
  useFormAndErrors<WorkRecordFinishForm>(WorkRecordFinishScheme);

watch(
  () => props.workRecord,
  (workRecord) => {
    if (!form.id && !!workRecord) {
      resetForm();
    }
  }
);

function resetForm() {
  form.id = props.workRecord.id;

  form.workRecordEntries = props.workRecord.workRecordEntries.map((entry) => ({
    id: entry.id,
    finishLat: entry.finishLat || undefined,
    finishLng: entry.finishLng || undefined,
    finishPhoto: entry.finishAt ? { data: "dummy" } : undefined,
    workRecordEntryReports: [
      {
        reportType: "individual",
        reportItems: [],
      },
      ...(entry.workReports.length
        ? entry.workReports
        : [
            {
              reportType: "overall",
              reportItems: [],
            },
          ]),
    ],
    workTasks: [],
  }));
  form.restTimeEntries = props.workRecord.restTimeSchedules;
}

onMounted(() => {
  if (!form.id && !!props.workRecord) {
    resetForm();
  }
});

const loading = ref(false);

function handleSubmit() {
  try {
    loading.value = true;
    startValidation.value = true;

    const currentScheme = props.needEnterLeaveReport
      ? WorkRecordFinishNeedEnterLeaveReportScheme
      : WorkRecordFinishScheme;

    targetScheme.value = currentScheme;

    const workRecord = currentScheme.parse(form);

    // @ts-ignore
    emit("submit", workRecord);
  } catch (e) {
    errorHandle(e);
  }
  loading.value = false;
}
</script>

<style scoped></style>
