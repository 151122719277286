<template>
  <div>
    <template v-if="!selectedOrganization"> 読み込み中 </template>
    <template v-else-if="canShowWorkMeetingTarget">
      <OrganizationSelector
        v-model:selected-organization-id="selectedOrganizationId"
        class="w-100 mb-3"
        :organizations="organizationsData || []"
      />
      <SelectDate
        v-if="filteredExistsDates.length"
        :year="selectedDate.year"
        :month="selectedDate.month"
        :exists-dates="filteredExistsDates"
        only-year-month
        disabled-auto-select
        class="mb-3"
        @update:year="updateSelectedDate({ year: $event })"
        @update:month="updateSelectedDate({ month: $event })"
      />

      <template v-if="selectedMeetingTargetType === 'work'">
        <ObjectSelector
          :value="selectedWork"
          :items="works || []"
          value-key="id"
          :show-fn="workToListTitle"
          form-id="organizationOwnerGroups"
          label="案件"
          is-valid
          class="mb-3"
          @update:value="selectedWorkId = $event?.id"
        />
        <ScheduleGroups
          v-if="selectedWork"
          :work="selectedWork"
          :basis-report-items="basisReportItems || []"
          @add-meeting-targets="openMeetingTargetFormModal"
          @edit-meeting-targets="openEditMeetingTargetFormModal"
          @destroy-meeting-targets="handleDestroyMeetingTargets"
        />
      </template>

      <template v-if="selectedMeetingTargetType === 'workPlace'">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex flex-wrap">
            <SelectButton
              v-model="selectedCarrier"
              :options="carriers"
              label="キャリア"
              :option-label="translater"
              form-id=""
              class="mb-3 me-2"
            />

            <SelectButton
              :model-value="selectedPrefectures[0]"
              :options="prefectures"
              label="エリア"
              :option-label="translater"
              form-id=""
              class="mb-3 me-2"
              @update:model-value="selectedPrefectures = [$event]"
            />
          </div>
        </div>

        <template v-if="false">
          <Dropdown
            v-if="filteredWorkPlaces?.length"
            v-model="selectedWorkPlace"
            :options="workPlaces || []"
            option-label="name"
            option-value="id"
            placeholder="店舗を選択"
            show-clear
            filter
            auto-filter-focus
          />
          <div v-else class="p-2 text-danger">店舗がありません</div>
        </template>

        <div class="d-flex justify-content-between flex-wrap w-100 mb-3">
          <InputForm v-model:value="workPlaceSearch" is-valid />
          <div class="d-flex">
            <BasicButton
              icon="pi pi-plus-circle"
              class="me-2"
              label="店舗目標の追加"
              @click="openMeeteingTargetFormModal"
            />
            <BasicButton
              icon="pi pi-plus-circle"
              label="目標項目の追加"
              @click="openAddFormItemModal"
            />
            <MenuButton v-if="false" :menu-items="menuItems" />
          </div>
        </div>

        <div
          v-if="
            selectedCarrier &&
            selectedOrganization &&
            filteredWorkPlaces?.length &&
            !getMeetingTargetWorkPlacesValidation &&
            meetingTargets
          "
          class=""
        >
          <template
            v-for="(wp, idx) in filteredWorkPlaces"
            :key="`work-place-mt-table-${wp.id}`"
          >
            <MeetingTargetWorkPlaceTable
              :work-place="wp"
              :work-carrier="selectedCarrier"
              :basis-report-items="basisReportItems || []"
              :added-form-items="[]"
              :meeting-targets="meetingTargets"
              :organization="selectedOrganization"
              :selected-date="selectedDate"
              :is-owner="isOwner || canShowWorkMeetingTarget"
              :is-show-divider="idx != filteredWorkPlaces.length - 1"
              @update-meeting-target-work-place="
                handleUpdateMeetingTargetWorkPlace
              "
              @update-work-report="handleUpdateWorkReport"
              @select-work-report="openReportUpdateModal"
              @create-work-report="openReportCreateModal"
              @add-meeting-target-added-form-item="
                handleCreateMeetingTargetAddedFormItems
              "
              @destroy-meeting-target-added-form-item="
                handleDestroyMeetingTargetAddedFormItem
              "
            />
          </template>
        </div>
        <i
          v-else-if="getMeetingTargetWorkPlacesValidation"
          class="pi pi-spin pi-spinner"
        ></i>
        <span v-else> {{ notFoundMessage }} </span>
      </template>

      <teleport to="body">
        <MeetingTargetFormModal
          ref="meetingTargetFormModal"
          :work-schedules="workSchedulesInGroup"
          :organization-member-id="selectedOrganization?.organizationMemberId"
          :meeting-target-filter="selectedWork?.meetingTargetFilter"
          :basis-report-items="basisReportItems || []"
          @submit="handleCreateMeetingTargets"
        />
        <EditMeetingTargetFormModal
          ref="editMeetingTargetFormModal"
          :meeting-target="selectedMeetingTarget"
          :meeting-target-filter="selectedWork?.meetingTargetFilter"
          :basis-report-items="basisReportItems || []"
          @submit="handleUpdateMeetingTarget"
        />
        <WorkReportUpdateFormModal
          ref="workReportUpdateFormModal"
          :work-record-entry-report="selectedWorkReport"
          :basis-report-items="basisReportItems || []"
          @submit="handleUpdateWorkReport"
        />
        <WorkReportCreateFormModal
          ref="workReportCreateFormModal"
          :works="selectedDateWorks"
          :work-types="workTypes || []"
          :basis-report-items="basisReportItems || []"
          @submit="handleCreateWorkReport"
        />

        <MeetingTargetWorkPlaceFormModal
          ref="formModal"
          :work-places="workPlaces || []"
          :basis-report-items="basisReportItems || []"
          :added-form-items="[]"
          :selected-date="selectedDate"
          :selected-carrier="selectedCarrier"
          @add-meeting-target-form-item="
            handleCreateMeetingTargetAddedFormItems
          "
          @destroy-meeting-target-added-form-item="
            handleDestroyMeetingTargetAddedFormItem
          "
          @submit="handleUpdateMeetingTargetWorkPlace"
        />

        <AdminBasisReportItemFormModal
          ref="addFormItemModal"
          :basis-report-items="basisReportItems || []"
          :basis-report-item-groups="basisReportItemGroups || []"
          :request-organizations="[]"
          :selected-work-carrier="selectedCarrier"
          is-meeting-target-index
          @submit="handleSubmitBasisReportItem"
          @create-group="handleCreateGroup"
          @destroy-group="handleDestroyGroup"
        />
      </teleport>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed, onMounted } from "vue";
import { translater } from "/@/modules/string";
import {
  dateMapToISO,
  isSameMonth,
  isContain,
  isAfterToday,
  fromISO,
} from "/@/modules/luxon";
import { getStorage, setStorage } from "/@/modules/localStorage";
import { workToListTitle } from "/@/modules/work";
import {
  useOrganization,
  useWorks,
  useSelectDate,
  useMeetingTargets,
  useMeetingTargetWorkPlaces,
  useBasisReportItems,
  useWorkPlaces,
  useWorkTypes,
  useWorkReport,
} from "/@/vue/composables";
import { MenuButton } from "/@/vue/components/Molecules";
import {
  OrganizationSelector,
  ScheduleGroups,
  MeetingTargetFormModal,
  MeetingTargetWorkPlaceTable,
  EditMeetingTargetFormModal,
  WorkReportUpdateFormModal,
  WorkReportCreateFormModal,
  MeetingTargetWorkPlaceFormModal,
  AdminBasisReportItemFormModal,
} from "/@/vue/components/Organisms";
import {
  SelectDate,
  ObjectSelector,
  InputForm,
  BasicButton,
} from "/@/vue/components/Atom";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";
import type { MenuItem } from "primevue/menuitem";

import {
  MeetingTargetForm,
  WorkSchedule,
  WorkClient,
  MeetingTargetClient,
  MeetingTargetWorkPlace,
  MeetingTargetType,
  WorkPlace,
  WorkPlaceClient,
  carriers,
  prefectures,
  WorkRecordEntryReportForm,
  WorkReportClient,
  BasisReportItem,
} from "/@/types";

const {
  getOrganizations,
  organizations,
  selectedOrganizationId,
  selectedOrganization,
  isOwner,
  isFRYTHOwner,
  canShowWorkMeetingTarget,
  canShowWorkMeetingTargetRequestOrganizationIds,
  getLabels,
} = useOrganization();
const { data: organizationsData } = getOrganizations();
watch(organizationsData, (os) => {
  organizations.value = os || [];
});

const requestOrganizationLabels = computed(() => {
  if (!selectedOrganization.value) return [];

  return getLabels(selectedOrganization.value, "request_organization", {
    canShowWorkMeetingTarget: true,
  });
});

const accessFroms = computed(() => {
  return requestOrganizationLabels.value.map((l) => l.accessFrom);
});

const notFoundMessage = computed(() => {
  const rightAnnounce = "画面右の + ボタンから目標を設定を追加できます";

  if (isFRYTHOwner.value) {
    return "目標が設定された店舗がありません。" + rightAnnounce;
  } else if (canShowWorkMeetingTarget.value) {
    return "閲覧可能な店舗に目標が設定されたものがありません。" + rightAnnounce;
  }

  return "権限がありません";
});

// date

const { selectedDate, updateSelectedDate, existsDates } = useSelectDate();

const filteredExistsDates = computed(() => {
  if (isFRYTHOwner.value) {
    return existsDates.value;
  } else {
    return existsDates.value.filter((d) => {
      return accessFroms.value.some((from) => {
        return isAfterToday(d, fromISO(from).minus({ days: 1 }));
      });
    });
  }
});

// menu

const showOnlyWithTarget = ref<boolean>(true);

const menuItems = computed<MenuItem[]>(() => {
  return [
    {
      label: "目標があるものだけ表示",
      icon: "pi pi-check",
      command: () => {
        showOnlyWithTarget.value = !showOnlyWithTarget.value;
      },
      style: {
        color: showOnlyWithTarget.value ? "var(--cyan-200)" : "",
      },
    },
  ];
});

// basis report item

const {
  getBasisReportItems,
  getBasisReportItemGroups,
  createBasisReportItem,
  createBasisReportItemGroup,
  destroyBasisReportItemGroup,
} = useBasisReportItems();
const { data: basisReportItems, mutate: getBasisReportItemsMutate } =
  getBasisReportItems();
const { data: basisReportItemGroups } =
  getBasisReportItemGroups(selectedOrganization);

// works

const { getWorks } = useWorks();
const { data: worksRes, mutate: mutateGetWorks } = getWorks(
  selectedOrganization,
  selectedDate
);

const works = computed<WorkClient[]>(() => {
  return worksRes.value?.works || [];
});

const selectedWorkId = ref<number | undefined>();
const selectedWork = computed<WorkClient | undefined>(() => {
  return works.value.find((w) => w.id === selectedWorkId.value);
});

const selectedDateWorks = computed(() => {
  return works.value.filter(
    (w) =>
      isContain(dateMapToISO(selectedDate.value), {
        start: w.startOn,
        end: w.finishOn,
      }) ||
      isSameMonth(selectedDate.value, w.startOn) ||
      isSameMonth(selectedDate.value, w.finishOn)
  );
});

// work places

const isMeetingTarget = ref<boolean>(true);
const {
  getWorkPlaces,
  selectedCategories,
  selectedCarrier,
  selectedPrefectures,
} = useWorkPlaces();
const { data: workPlaces } = getWorkPlaces(
  selectedOrganization,
  selectedDate,
  isMeetingTarget
);

watch(selectedOrganization, (o) => {
  if (o) {
    selectedCategories.value = o.categories || [];
  }
});

onMounted(() => {
  selectedCarrier.value =
    getStorage("MeetingTargetsIndexSelectedCarrier") || "docomo";
  selectedPrefectures.value = getStorage(
    "MeetingTargetsIndexSelectedPrefectures"
  ) || ["ishikawa"];
});
watch(selectedCarrier, (carrier) => {
  setStorage("MeetingTargetsIndexSelectedCarrier", carrier);
});
watch(selectedPrefectures, (prefectures) => {
  setStorage("MeetingTargetsIndexSelectedPrefectures", prefectures);
});

const selectedWorkPlace = ref<WorkPlace>();

// search

const workPlaceSearch = ref<string>("");
watch(workPlaceSearch, (search) => {
  setStorage("MeetingTargetsIndexWorkPlaceSearch", search);
});
onMounted(() => {
  workPlaceSearch.value =
    getStorage("MeetingTargetsIndexWorkPlaceSearch") || "";
});

const filteredWorkPlaces = computed<WorkPlaceClient[]>(() => {
  if (!workPlaces.value) return [];

  const searchedWorkPlaces = workPlaces.value.filter((wp) => {
    return (
      (!workPlaceSearch.value || wp.name.includes(workPlaceSearch.value)) &&
      meetingTargets.value?.some(
        (mt) =>
          mt.workPlaceId === wp.id &&
          isSameMonth(selectedDate.value, mt.targetDate)
      )
    );
  });

  if (isFRYTHOwner.value) {
    return searchedWorkPlaces;
  } else {
    return searchedWorkPlaces.filter((wp) => {
      return canShowWorkMeetingTargetRequestOrganizationIds.value?.includes(
        wp.organizationId
      );
    });
  }
});

// meeting targets

const selectedMeetingTargetType = ref<MeetingTargetType>("workPlace");

function meetingTargetTypeTranslater(t: MeetingTargetType): string {
  const m = {
    work: "案件別",
    workPlace: "店舗別",
  };

  return m[t];
}

const { createMeetingTargets, updateMeetingTarget, destroyMeetingTargets } =
  useMeetingTargets();

async function handleCreateMeetingTargets(
  workId: number,
  workScheduleId: number,
  form: MeetingTargetForm
) {
  if (await createMeetingTargets(workId, workScheduleId, form)) {
    alert("作成に成功しました");
    mutateGetWorks();
  } else {
    alert("作成に失敗しました");
  }
}

const workSchedulesInGroup = ref<WorkSchedule[]>([]);

const meetingTargetFormModal = ref();

function openMeetingTargetFormModal(workSchedules: WorkSchedule[]) {
  workSchedulesInGroup.value = workSchedules;
  meetingTargetFormModal.value?.openModal();
}

// update

async function handleUpdateMeetingTarget(form: MeetingTargetForm) {
  if (await updateMeetingTarget(form)) {
    // alert("更新に成功しました");
    mutateGetWorks();
  } else {
    alert("更新に失敗しました");
  }
}

const editMeetingTargetFormModal = ref();

const selectedMeetingTarget = ref<MeetingTargetClient>();

function openEditMeetingTargetFormModal(meetingTarget: MeetingTargetClient) {
  selectedMeetingTarget.value = meetingTarget;
  editMeetingTargetFormModal.value.openModal();
}

// destroy

async function handleDestroyMeetingTargets(meetingTarget: MeetingTargetClient) {
  if (
    !window.confirm(
      `${meetingTarget.rangeOfTargetDate}の目標を削除してよろしいです？`
    )
  ) {
    return;
  }

  if (await destroyMeetingTargets(meetingTarget.id)) {
    alert("削除に成功しました");
    mutateGetWorks();
  } else {
    alert("削除に失敗しました");
  }
}

// meeting target work place

const {
  getMeetingTargetWorkPlaces,
  updateMeetingTargetWorkPlace,
  createMeetingTargetWorkPlaces,

  getMeetingTargetAddedFormItems,
  createMeetingTargetAddedFormItem,
  destroyMeetingTargetAddedFormItem,
} = useMeetingTargetWorkPlaces();
const {
  data: meetingTargets,
  mutate: getMeetingTargetWorkPlacesMutate,
  isValidating: getMeetingTargetWorkPlacesValidation,
} = getMeetingTargetWorkPlaces(selectedOrganization, {
  date: selectedDate,
  carrier: selectedCarrier,
  prefectures: selectedPrefectures,
});

const formModal = ref();

function openMeeteingTargetFormModal() {
  if (!selectedOrganization.value) {
    return;
  }

  formModal.value?.openModal({
    organizationId: selectedOrganization.value.id,
    organizationMemberId: selectedOrganization.value.organizationMemberId,
  });
}

function closeMeetingTargetFormModal() {
  formModal.value?.closeModal();
}

/*
const { data: addedFormItems, mutate: getMeetingTargetAddedFormItemsMutate } =
  getMeetingTargetAddedFormItems(selectedOrganization);
*/

async function handleUpdateMeetingTargetWorkPlace(
  meetingTargetWorkPlace: MeetingTargetWorkPlace
) {
  const api = meetingTargetWorkPlace.id
    ? updateMeetingTargetWorkPlace
    : createMeetingTargetWorkPlaces;

  if (await api(meetingTargetWorkPlace)) {
    await getMeetingTargetWorkPlacesMutate();
  } else {
    alert("更新に失敗しました");
  }
}

async function handleCreateMeetingTargetAddedFormItems(form: { name: string }) {
  if (!selectedOrganization.value) return;

  if (
    await createMeetingTargetAddedFormItem(selectedOrganization.value, form)
  ) {
    // getMeetingTargetAddedFormItemsMutate();
    closeMeetingTargetFormModal();
  } else {
    alert("作成に失敗しました");
  }
}

async function handleDestroyMeetingTargetAddedFormItem(id: number) {
  if (await destroyMeetingTargetAddedFormItem(id)) {
    //getMeetingTargetAddedFormItemsMutate();
  } else {
    alert("削除に失敗しました");
  }
}

// work type

const { getWorkTypes } = useWorkTypes();
const { data: workTypes } = getWorkTypes(selectedOrganization);

// work report

const { updateWorkReport, createWorkReport } = useWorkReport();

const workReportUpdateFormModal = ref();
const workReportCreateFormModal = ref();
const selectedWorkReport = ref<WorkReportClient>();

function openReportCreateModal(workReport: WorkRecordEntryReportForm) {
  workReportCreateFormModal.value?.openModal(workReport);
}

function closeReportCreateFormModal() {
  workReportCreateFormModal.value?.closeModal();
}

async function handleCreateWorkReport(form: WorkRecordEntryReportForm) {
  if (!selectedOrganization.value?.organizationMemberId) {
    return;
  }

  if (
    await createWorkReport(
      form,
      selectedOrganization.value.organizationMemberId
    )
  ) {
    alert("業務記録の作成に成功しました");
    closeReportCreateFormModal();
  } else {
    alert("業務記録の作成に失敗しました");
  }
}

function openReportUpdateModal(workReport: WorkReportClient) {
  selectedWorkReport.value = workReport;
  workReportUpdateFormModal.value?.openModal();
}

function closeReportUpdateFormModal() {
  workReportUpdateFormModal.value?.closeModal();
}

async function handleUpdateWorkReport(workReport: WorkRecordEntryReportForm) {
  if (!selectedOrganization.value?.organizationMemberId) {
    return;
  }

  if (
    await updateWorkReport(
      workReport,
      selectedOrganization.value.organizationMemberId,
      {
        skipRequest: true,
      }
    )
  ) {
    alert("業務記録の更新に成功しました");
    closeReportUpdateFormModal();
  } else {
    alert("業務記録の更新に失敗しました");
  }
}

// add form item modal

const addFormItemModal = ref();

function openAddFormItemModal() {
  addFormItemModal.value?.openModal();
}

function closeAddFormItemModal() {
  addFormItemModal.value?.closeModal();
}

async function handleSubmitBasisReportItem(form: BasisReportItem) {
  const handlerStr = form.id ? "更新" : "追加";

  if (await createBasisReportItem(form)) {
    window.alert("追加しました");
    getBasisReportItemsMutate();
  } else {
    alert(handlerStr + "に失敗しました");
  }
}

async function handleCreateGroup(name: string) {
  if (await createBasisReportItemGroup(name)) {
    alert("追加しました");
    getBasisReportItemGroupsMutate();
  } else {
    alert("追加に失敗しました");
  }
}

async function handleDestroyGroup(id: number) {
  if (await destroyBasisReportItemGroup(id)) {
    alert("削除しました");
    getBasisReportItemGroupsMutate();
  } else {
    alert("削除に失敗しました");
  }
}
</script>

<style scoped></style>
