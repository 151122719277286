<template>
  <template v-for="(_, idx) of indentStrings" :key="_ + idx"> &nbsp; </template>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  indentCount: number;
}>();

const indentStrings = computed(() => {
  return "aaa".repeat(props.indentCount);
});
</script>

<style scoped></style>
