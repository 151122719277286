import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { PrefectureScheme } from "/@/types/address.d";
import { OrganizationCategoryScheme } from "/@/types/category.d";
import { CarriersScheme } from "/@/types/carriers.d";
import { MeetingTargetWorkPlaceClientScheme } from "/@/types/meetingTargets.d";

export const WorkPlaceScheme = z.object({
  id: z.number(),
  name: z.string(),
  shortName: z.string().optional(),
  prefecture: PrefectureScheme,
  placeCategory: OrganizationCategoryScheme,
  placeSubCategory: CarriersScheme,
  organizationId: z.number().optional(),
  organizationName: z.string().optional(),
  eventPlaces: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .array(),
});

export type WorkPlace = zod.infer<typeof WorkPlaceScheme>;

export const WorkPlaceClientScheme = WorkPlaceScheme.extend({
  meetingTargetWorkPlaces: MeetingTargetWorkPlaceClientScheme.array(),
});

export type WorkPlaceClient = zod.infer<typeof WorkPlaceClientScheme>;

export const WorkPlaceUpdateScheme = WorkPlaceScheme.pick({
  id: true,
  name: true,
}).extend({
  shortName: z.string().optional().nullable(),
  organizationId: z.number(),
});

export type WorkPlaceUpdate = zod.infer<typeof WorkPlaceUpdateScheme>;

export const WorkPlaceUpdateFormScheme = WorkPlaceUpdateScheme.partial();
export type WorkPlaceUpdateForm = zod.infer<typeof WorkPlaceUpdateFormScheme>;

export type WorkPlacesInfo = {
  workPlaces: CheckItem[];
  count: number;
};

export type WorkPlaceCheckItemMap = {
  [carrier in Carrier]?: {
    [prefecture in Prefecture]?: WorkPlacesInfo;
  };
};
