<template>
  <div>
    <template v-if="editMode">
      <ContentLabel label="一括変更" />
      <div class="labor-edit-table-wrapper">
        <table class="table table-sm table-bordered table-striped mb-3">
          <thead>
            <tr>
              <th class="cell-name"></th>
              <LaborInfoTableBaseHeader />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="cell-name"></td>
              <LaborInfoTableBaseBody
                :member="{ name: '' }"
                :selected-date="selectedDate"
                :form-item="bulkFormItem"
                :days-in-month="daysInMonth"
                :edit-mode="editMode"
                @update:form-item="updateBulkFormItem"
              />
            </tr>
          </tbody>
        </table>
      </div>
    </template>

    <div class="labor-table-wrapper mb-3">
      <table class="table table-sm table-bordered table-striped">
        <thead>
          <tr class="sticky">
            <th class="sticky">名前</th>
            <LaborInfoTableBaseHeader />
          </tr>
        </thead>
        <tbody>
          <template v-if="formItems.length">
            <tr
              v-for="(m, idx) in members"
              :key="`labor-info-table-member-${m.id}`"
            >
              <td class="sticky cell-name">
                {{ m.user.name.replaceAll(" ", "") }}
              </td>
              <LaborInfoTableBaseBody
                :member="m"
                :selected-date="selectedDate"
                :form-item="formItems[idx]"
                :edit-mode="editMode"
                @update:form-item="updateLaborInfos($event, idx)"
              />
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <div v-if="editMode" class="d-flex align-items-center flex-wrap mb-3">
      <BasicButton
        label="更新"
        icon="pi pi-sync"
        class="me-2"
        button-type="submit"
        @click="updateForms"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import { isSameMonth } from "/@/modules/luxon";
import {
  laborInfoUpdater,
  isPrescribedWorkingDaysCase,
  createDefaultLaborInfoForm,
} from "/@/modules/laborInfo";
import { ContentLabel, BasicButton } from "/@/vue/components/Atom";
import type { LaborInfoForm, OrganizationMember, DateMap } from "/@/types";
import LaborInfoTableBaseHeader from "./LaborInfoTableBaseHeader.vue";
import LaborInfoTableBaseBody from "./LaborInfoTableBaseBody.vue";
import { getDaysCountInMonth } from "/@/modules/luxon";
import { computed } from "vue";

const props = defineProps<{
  members: OrganizationMember[];
  selectedDate: DateMap;
  editMode?: boolean;
}>();

const emit = defineEmits<{
  (e: "updateLaborInfos", form: LaborInfoForm[]): void;
}>();

const daysInMonth = computed(() => {
  return getDaysCountInMonth(props.selectedDate);
});

const bulkFormItem = ref<LaborInfoForm>(
  createDefaultLaborInfoForm({
    organizationMemberId: undefined,
    selectedDate: props.selectedDate,
    daysInMonth: daysInMonth.value,
  })
);

const formItems = ref<LaborInfoForm[]>([]);

function initFormItems(members: OrganizationMember[]) {
  members.forEach((m, idx) => {
    // m.labor_infos などを作成して、ここで対象月のものがあればそれを、無ければデフォルトの物を作成する

    const targetLabor =
      m.organizationMemberLabors &&
      m.organizationMemberLabors.find((labor) =>
        isSameMonth(props.selectedDate, labor.targetDate)
      );

    if (targetLabor) {
      formItems.value[idx] = targetLabor;
    } else {
      formItems.value[idx] = createDefaultLaborInfoForm({
        organizationMemberId: m.id,
        selectedDate: props.selectedDate,
        daysInMonth: daysInMonth.value,
      });
    }
  });
}

onMounted(() => {
  initFormItems(props.members);
});

watch(props.members, (members) => {
  initFormItems(members);
});

function updateBulkFormItem(prms: {
  key: keyof LaborInfoForm;
  val: number | string | undefined;
}) {
  laborInfoUpdater(bulkFormItem.value, prms);

  if (isPrescribedWorkingDaysCase(prms)) {
    laborInfoUpdater(bulkFormItem.value, {
      key: "publicHolidays",
      // @ts-ignore prms.key === "prescribedWorkingDays" && typeof prms.val === "number"
      val: daysInMonth.value - prms.val,
    });
  }

  formItems.value.forEach((formItem) => {
    laborInfoUpdater(formItem, prms);

    if (isPrescribedWorkingDaysCase(prms)) {
      laborInfoUpdater(formItem, {
        key: "publicHolidays",
        // @ts-ignore prms.key === "prescribedWorkingDays" && typeof prms.val === "number"
        val: daysInMonth.value - prms.val,
      });
    }
  });
}

function updateLaborInfos(
  prms: {
    key: keyof LaborInfoForm;
    val: number | string | undefined;
  },
  idx: number
) {
  const targetFormItem = formItems.value[idx];

  laborInfoUpdater(targetFormItem, prms);

  if (isPrescribedWorkingDaysCase(prms)) {
    laborInfoUpdater(targetFormItem, {
      key: "publicHolidays",
      // @ts-ignore prms.key === "prescribedWorkingDays" && typeof prms.val === "number"
      val: daysInMonth.value - prms.val,
    });
  }
}

// watch selected date

watch(
  () => ({ year: props.selectedDate.year, month: props.selectedDate.month }),
  (newV) => {
    initFormItems(props.members);
  }
);

function updateForms() {
  emit("updateLaborInfos", formItems.value);
}
</script>

<style scoped>
td,
th {
  white-space: nowrap;
}

.cell-name {
  vertical-align: middle;
  min-width: 80px;
  max-width: 80px;
}

.labor-edit-table-wrapper {
  overflow: scroll;
  max-width: 100%;
}

.labor-table-wrapper {
  overflow: scroll;
  max-width: 100%;
  width: 100%;
  max-height: 50vh;
}
</style>
