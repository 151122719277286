<template>
  <div>
    <span>現在の進捗( {{ doneCount }}/{{ allCount }} )</span>
    <div class="progress">
      <div
        class="progress-bar"
        role="progressbar"
        :style="{
          width: `${currentProgress}%`,
        }"
        :aria-valuenow="currentProgress"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        {{ currentProgress }}%
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  allCount: number;
  doneCount: number;
}>();

const currentProgress = computed(() => {
  if (props.doneCount === 0) {
    return 0;
  }

  const percent = ((props.doneCount * 100) / (props.allCount * 100)) * 100;

  return Math.floor(percent);
});
</script>

<style scoped></style>
