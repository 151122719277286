import zod from "zod";
import { DateTimeScheme, z } from "/@/modules/zodUtils";

export const LaborInfoClientScheme = z.object({
  id: z.number(),
  organizationMemberId: z.number(),
  targetDate: DateTimeScheme,
  // 所定労働日数	公休日数	勤務予定日数	休日勤務予定日数	特別休暇日数	取得予定有休日数	有休残日数
  prescribedWorkingDays: z.number(),
  publicHolidays: z.number(),
  workScheduleDays: z.number(),
  holidayScheduledDays: z.number(),
  specialLeaveDays: z.number(),
  plannedPaidHolidays: z.number(),
  remainingPaidHolidays: z.number(),
});

export type LaborInfoClient = zod.infer<typeof LaborInfoClientScheme>;

export const LaborInfoFormScheme = LaborInfoClientScheme.pick({
  organizationMemberId: true,
  targetDate: true,
  prescribedWorkingDays: true,
  publicHolidays: true,
  workScheduleDays: true,
  holidayScheduledDays: true,
  specialLeaveDays: true,
  plannedPaidHolidays: true,
}).partial();

export type LaborInfoForm = zod.infer<typeof LaborInfoFormScheme>;
