<template>
  <div>
    <div class="mb-3">
      <ContentLabel label="選択したメンバー" />
      <div>
        {{ targetMember.user.name }}
      </div>
    </div>

    <FormItemChecker
      :value="roles"
      label="役職"
      form-id="roles"
      :items="oMRItems"
      :errors="errors"
      class="mb-3"
      @update:value="$emit('update:roles', $event)"
    />

    <ContentLabel label="設定" />
    <CheckForm
      :value="needEnterLeaveReport"
      label="入退店時情報提出"
      form-id="need-enter-leave-report"
      is-valid
      :unchecked-value="false"
      @update:value="$emit('update:needEnterLeaveReport', $event)"
    />
    <CheckForm
      :value="needFinishReport"
      label="業務終了レポート"
      form-id="need-finish-report"
      is-valid
      :unchecked-value="false"
      @update:value="$emit('update:needFinishReport', $event)"
    />
    <CheckForm
      :value="needSubmitLocation"
      label="現在地の定時提出"
      form-id="need-submit-location"
      is-valid
      :unchecked-value="false"
      @update:value="$emit('update:needSubmitLocation', $event)"
    />
    <div v-if="needSubmitLocation" class="d-flex align-items-center mb-3">
      <ContentLabel
        label="提出時刻"
        class="ms-4 me-2 text-nowrap"
        without-padding
      />
      <TimeForm
        :value="submitLocationTime"
        is-valid
        @update:value="$emit('update:submitLocationTime', $event)"
      />
    </div>
    <div class="d-flex align-items-center mb-3">
      <label class="me-2" for="member-color">メンバーカラー</label>
      <input
        id="member-color"
        :value="memberColor"
        type="color"
        class="form-control form-control-color"
        @update="$emit('update:memberColor', $event)"
      />
    </div>
    <div class="d-flex align-items-center mb-3">
      <ContentLabel
        label="社員コード"
        without-padding
        class="me-2 text-nowrap"
      />
      <FormItem
        :value="code"
        label=""
        :errors="errors"
        form-id="member-code"
        form-type="text"
        @update:value="$emit('update:code', $event)"
      />
    </div>

    <CheckForm
      :value="hideFromSelect"
      label="スケジュールの割り当て対象から除外する"
      form-id="hide-from-select"
      :unchecked-value="false"
      @update:value="$emit('update:hideFromSelect', $event)"
    />

    <button type="submit" class="invisible"></button>
  </div>
</template>

<script setup lang="ts">
import { translater } from "/@/modules/string";
import { ContentLabel, CheckForm, TimeForm } from "/@/vue/components/Atom";
import { FormItemChecker, FormItem } from "/@/vue/components/Molecules";
import { organizationMemberRoles } from "/@/types";
import type {
  OrganizationMember,
  OrganizationMemberForm,
  OrganizationMemberRole,
  OrganizationOwnerGroup,
  OrganizationOwnerGroupRole,
  ManagementLabelClient,
} from "/@/types";
import { ZodFormattedErrors } from "/@/modules/zodUtils";

type OwnerGroup = { groupId: number; role: OrganizationOwnerGroupRole };

defineProps<{
  roles?: OrganizationMemberRole[];
  labels?: number[];
  enabledManagementLabels: ManagementLabelClient[];
  needEnterLeaveReport?: Boolean;
  needSubmitLocation?: Boolean;
  needFinishReport?: Boolean;
  hideFromSelect?: Boolean;
  submitLocationTime?: string;
  memberColor?: string;
  code?: string;
  ownerGroups: OwnerGroup[];
  errors: ZodFormattedErrors;
  targetMember: OrganizationMember;
  ownerGroupItems: OrganizationOwnerGroup[];
  managementLabels: ManagementLabelClient[];
}>();

defineEmits<{
  (e: "update:roles", roles: OrganizationMemberRole[] | undefined): void;
  (e: "update:labels", labels: number[]): void;
  (e: "update:ownerGroups", groups: OwnerGroup[] | undefined): void;
  (e: "update:needEnterLeaveReport", needEnterLeaveReport: boolean): void;
  (e: "update:needFinishReport", needFinishReport: boolean): void;
  (e: "update:needSubmitLocation", needSubmitLocation: boolean): void;
  (e: "update:hideFromSelect", hideFromSelect: boolean): void;
  (e: "update:submitLocationTime", submitLocationTime: string): void;
  (e: "update:memberColor", memberColor: string): void;
  (e: "update:code", code: string): void;
  (e: "addOwnerGroup"): void;
  (e: "submit", organizationMemberParams: OrganizationMemberForm): void;
}>();

const oMRItems = organizationMemberRoles.map((role) => ({
  value: role,
  name: translater(role),
}));
</script>

<style scoped></style>
