<template>
  <div>
    <template v-if="form">
      <GetLocation v-model:lat="form.lat" v-model:lng="form.lng" class="mb-3" />

      <FormItemPhoto
        v-model:photo="form.photo"
        class="mb-3"
        form-id="photo"
        label=""
        :errors="errors"
      />

      <div class="d-flex w-100">
        <div class="me-2">
          <BasicButton
            variant="danger"
            :disabled="loading"
            icon="pi pi-times-circle"
            label="キャンセル"
            @click="$emit('cancel')"
          />
        </div>
        <div class="">
          <BasicButton
            button-type="submit"
            :disabled="loading"
            icon="pi pi-upload"
            label="送信"
            @click="handleSubmit"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { GetLocation } from "/@/vue/components/Organisms";
import { useZodScheme } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { FormItemPhoto } from "/@/vue/components/Molecules";
import { SubmitLocationScheme, SubmitLocationForm } from "/@/types";
import { errorHandle } from "/@/modules/error";

const emit = defineEmits<{
  (e: "submit", form: SubmitLocationForm): void;
  (e: "cancel"): void;
}>();

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } =
  useFormAndErrors<SubmitLocationForm>(SubmitLocationScheme);

const loading = ref(false);

function handleSubmit() {
  try {
    loading.value = true;
    startValidation.value = true;

    const workRecordEntry = SubmitLocationScheme.parse(form);
    emit("submit", workRecordEntry);
  } catch (e) {
    errorHandle(e);
    loading.value = false;
  }
}
</script>

<style scoped></style>
