<template>
  <div>
    <FinishForm
      v-if="workRecord"
      :work-record="workRecord"
      :need-enter-leave-report="actionStatus?.needEnterLeaveReport"
      :need-finish-report="actionStatus?.needFinishReport"
      :basis-report-items="basisReportItems || []"
      @cancel="backto"
      @submit="handleSubmit"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from "vue";
import {
  useWorkRecords,
  useRouterUtil,
  useOrganizationMember,
  useBasisReportItems,
} from "/@/vue/composables";
import { FinishForm } from "/@/vue/components/Organisms";
import { WorkRecordFinishForm } from "/@/types";

// query

const { query, backto, goto } = useRouterUtil();
const targetId = computed<number | undefined>(() => {
  const idString = query.value?.workRecordId;

  if (!idString || idString instanceof Array) {
    return undefined;
  }

  return parseInt(idString);
});

const selectedOrganization = ref();

onMounted(() => {
  selectedOrganization.value = {
    id: query.value.organizationId,
    organizationMemberId: query.value.organizationMemberId,
  };
});

const { getActionStatus } = useOrganizationMember();
const { data: actionStatus } = getActionStatus(selectedOrganization);

//

const { getWorkRecord } = useWorkRecords();
const { data: workRecord } = getWorkRecord(targetId);

// basis report items

const { getBasisReportItems } = useBasisReportItems();
const { data: basisReportItems } = getBasisReportItems();

const loading = ref<boolean>();
const errors = ref<string[]>([]);

const { finishWork } = useWorkRecords();

async function handleSubmit(form: WorkRecordFinishForm) {
  loading.value = true;

  if (await finishWork(form)) {
    alert("業務を終了しました。お疲れ様でした。");
    goto({ name: "Home", query: query.value });
  } else {
    alert("業務の終了に失敗しました");
  }
  loading.value = false;
}
</script>

<style scoped></style>
