<template>
  <div>
    <PageHeader page-title="案件編集" confirm />
    <WorkForm
      v-if="work"
      :edit-work="work"
      :searching="!workPlaces"
      :organizations="relatedOrganizations || []"
      :request-organizations="requestOrganizations || []"
      :work-places="workPlaces || []"
      :work-types="workTypes || []"
      :event-places="eventPlaces || []"
      :organization-members="organizationMembers || []"
      :basis-report-items="basisReportItems || []"
      :work-type-categories="selectedOrganization?.categories || []"
      submit-text="更新"
      :loading="loading"
      @search-work-places="searchWorkPlaces"
      @select-organization="selectOrganization"
      @cancel="handleCancel"
      @submit="handleSubmit"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import {
  useOrganization,
  useOrganizationMember,
  useRouterUtil,
  useWorkPlaces,
  useWorkTypes,
  useWorks,
  useEventPlaces,
  useBasisReportItems,
} from "/@/vue/composables";
import { PageHeader } from "/@/vue/components/Layouts";
import { WorkForm } from "/@/vue/components/Organisms";
import type { Organization, WorkForm as WorkFormType } from "/@/types";

const { goto, query, backto } = useRouterUtil();

const { getOrganizations, getRequestOrganizations, asOwner, asHomeworker } =
  useOrganization();
const { data: organizations } = getOrganizations();

const isOwner = computed(() => {
  return asOwner(selectedOrganization.value);
});

const relatedOrganizations = computed(() => {
  return (
    organizations.value?.filter((o) => asOwner(o) || asHomeworker(o)) || []
  );
});

const selectedOrganization = ref<Organization | undefined>();
function selectOrganization(organization: Organization) {
  selectedOrganization.value = organization;
}

const isHomeworker = computed(() => {
  if (!selectedOrganization.value) {
    return false;
  }

  return asHomeworker(selectedOrganization.value);
});

const { data: requestOrganizations } =
  getRequestOrganizations(selectedOrganization);

// organization members

const { getOrganizationMembers } = useOrganizationMember();
const { data: organizationMembers } = getOrganizationMembers(
  selectedOrganization,
  isOwner
);

// work places

const { searchWorkPlaces, getWorkPlaces } = useWorkPlaces();
const { data: workPlaces } = getWorkPlaces();

// work types

const { getWorkTypes } = useWorkTypes();
const { data: workTypes } = getWorkTypes(selectedOrganization, isHomeworker);

const { getEventPlaces } = useEventPlaces();
const { data: eventPlaces } = getEventPlaces();

// basis report items

const { getBasisReportItems } = useBasisReportItems();
const { data: basisReportItems } = getBasisReportItems();

// work

const { getWork } = useWorks();
const { data: work, mutate: workMutate } = getWork(query);

// submit

const { editWork } = useWorks();

const loading = ref<boolean>(false);

async function handleSubmit(work: WorkFormType) {
  loading.value = true;

  if (await editWork(work)) {
    alert("更新に成功しました");
    backto({ confirm: false });
  } else {
    alert("更新に失敗しました");
  }

  loading.value = false;
}

function handleCancel() {
  backto({ confirm: true });
}
</script>

<style scoped></style>
