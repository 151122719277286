export async function getCameraStream() {
  let stream;
  try {
    stream = await navigator.mediaDevices.getUserMedia({
      video: { facingMode: "environment" },
    });
  } catch (err1) {
    try {
      stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "user" },
      });
    } catch (err2) {
      console.error("Could not get camera stream", err1, err2);
      return false;
    }
  }
  return stream;
}

export function registerVideStream(
  stream: MediaStream,
  videoElementId: string
): HTMLVideoElement | undefined {
  // 指定した ID が video element の場合は、stream を登録しカメラの映像を表示する。
  // また返り値として video element を返す。
  const video = document.getElementById(videoElementId);

  if (video instanceof HTMLVideoElement) {
    video.srcObject = stream;
    video.play();
    return video;
  }

  return;
}

export async function transcriptionPhotoFromVideoElementToCanvasElement(
  videoElement: HTMLVideoElement,
  canvasElement: HTMLCanvasElement,
  trackWidth: number,
  trackHeight: number
): Promise<{ data: string | undefined; blob: Blob | undefined }> {
  const ctx = canvasElement.getContext("2d");

  if (!ctx) {
    return {
      data: undefined,
      blob: undefined,
    };
  }

  canvasElement.setAttribute("width", trackWidth.toString());
  canvasElement.setAttribute("height", trackHeight.toString());
  ctx.drawImage(videoElement, 0, 0, trackWidth, trackHeight);

  const photoBlob: Blob | null = await new Promise((resolve) =>
    canvasElement.toBlob(resolve, "image/jpeg", 0.95)
  );

  return {
    data: canvasElement.toDataURL("image/jpeg", 0.7),
    blob: photoBlob ?? undefined,
  };
}

export function getTrackSizeFromVideoStream(stream: MediaStream) {
  const tracks = stream.getTracks();

  const { width, height } = tracks[0].getSettings();

  return {
    width,
    height,
  };
}
