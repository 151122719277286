<template>
  <div>
    <div class="d-flex justify-content-between mb-3">
      <SelectButton
        v-model="selectedEventTypes"
        :options="organizationEventTypes"
        :option-label="translater"
        multiple
        aria-labelledby="multiple"
        class="mb-3"
      />

      <ToggleButton
        v-model="showFinishedEvents"
        on-label="終了済ミーティングの表示"
        off-label="終了済ミーティングの表示"
        on-icon="pi pi-eye"
        off-icon="pi pi-eye-slash"
        class="mb-3"
      />
    </div>

    <div class="d-flex justify-content-end w-100 mb-3">
      <BasicButton
        icon="pi pi-plus-circle"
        label="新規作成"
        @click="$emit('create')"
      />
    </div>
    <OrganizationEventList
      :organization-events="filteredEvents"
      :selected-date="selectedDate"
      :selected-event-types="selectedEventTypes"
      :organization-members="organizationMembers"
      @edit="$emit('edit', $event)"
      @destroy="$emit('destroy', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { isFromToday, isSameMonth } from "/@/modules/luxon";
import { translater } from "/@/modules/string";
import { BasicButton } from "/@/vue/components/Atom";
import { OrganizationEventList } from "/@/vue/components/Organisms";
import {
  DateMap,
  OrganizationEventClient,
  OrganizationMember,
  organizationEventTypes,
  OrganizationEventType,
} from "/@/types";
import SelectButton from "primevue/selectbutton";
import ToggleButton from "primevue/togglebutton";

const props = defineProps<{
  selectedDate: DateMap;
  organizationEvents: OrganizationEventClient[];
  organizationMembers: OrganizationMember[];
}>();

defineEmits<{
  (e: "edit", id: number): void;
  (e: "destroy", id: number): void;
  (e: "create"): void;
}>();

const selectedEventTypes = ref<OrganizationEventType[]>([
  "meeting",
  "meeting_training",
  "study",
]);

const showFinishedEvents = ref<boolean>(false);

const filteredEvents = computed(() => {
  return props.organizationEvents.filter((event) => {
    return (
      selectedEventTypes.value.includes(event.eventType) &&
      isSameMonth(props.selectedDate, event.startAt) &&
      (showFinishedEvents.value || isFromToday(event.startAt))
    );
  });
});
</script>

<style scoped></style>
