<template>
  <div>
    <input
      id="formFile"
      class="form-control mb-2"
      type="file"
      @change="onImageUploaded"
    />
    <div v-if="imagePreview" class="image-preview-wrapper">
      <img :src="imagePreview" alt="Preview" class="image-preview" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { BasicButton, CloseIcon } from "/@/vue/components/Atom";

defineProps<{
  image: any;
  withRemoveButton?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:image", image: any): void;
}>();

const selectedImage = ref();
const imagePreview = ref();

function onImageUploaded(event: any) {
  // event(=e)から画像データを取得する
  selectedImage.value = event.target.files[0];
  imagePreview.value = URL.createObjectURL(selectedImage.value);
  submit();
}

function submit() {
  const reader = new FileReader();
  reader.onload = async () => {
    const base64String = reader.result;
    emit("update:image", { data: base64String });
  };
  reader.readAsDataURL(selectedImage.value);
}

function removeImage() {
  selectedImage.value = undefined;
  imagePreview.value = undefined;
  emit("update:image", undefined);
}
</script>

<style scoped lang="scss">
.image-preview-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  .image-preview {
    width: 200px;
  }
}
</style>
