import { z } from "/@/modules/zodUtils";
import zod from "zod";

export const workCategories = [
  // "normal",
  "shop_helper",
  "event_helper",
  "fulltime",
  "rounder",
  "consulting",
  "light_ad",
  "sv",
  "sajit",
] as const;
export const WorkCategoriesScheme = z.enum(workCategories);
export type WorkCategory = zod.infer<typeof WorkCategoriesScheme>;
