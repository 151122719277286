<template>
  <div>
    <div class="table-wrapper">
      <table class="table table-sm table-bordered table-striped mb-3">
        <thead>
          <tr>
            <th class="sticky cell-month">月</th>
            <th class="cell-day">日数</th>
            <LaborInfoTableBaseHeader />
          </tr>
        </thead>
        <tbody>
          <template v-if="formItems.length">
            <tr v-for="(d, idx) in dates" :key="`labor-info-table-member-${d}`">
              <td class="cell-month">{{ d.month }}</td>
              <td class="cell-day">{{ d.dayCount }}</td>
              <LaborInfoTableBaseBody
                :member="member"
                :selected-date="d"
                :form-item="formItems[idx]"
                :edit-mode="editMode"
                @update:form-item="updateLaborInfos($event, idx, d)"
              />
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <div v-if="editMode" class="d-flex align-items-centermb-3">
      <BasicButton
        label="更新"
        icon="pi pi-sync"
        button-type="submit"
        @click="updateForms"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";
import { computed, ref, onMounted, watch } from "vue";
import {
  createDateMapRange,
  getDaysCountInMonth,
  isSameMonth,
} from "/@/modules/luxon";
import {
  createDefaultLaborInfoForm,
  isPrescribedWorkingDaysCase,
  laborInfoUpdater,
} from "/@/modules/laborInfo";
import { BasicButton } from "/@/vue/components/Atom";
import type { OrganizationMember, LaborInfoForm, DateMap } from "/@/types";
import LaborInfoTableBaseHeader from "./LaborInfoTableBaseHeader.vue";
import LaborInfoTableBaseBody from "./LaborInfoTableBaseBody.vue";

const props = defineProps<{
  year: number;
  member: OrganizationMember;
  editMode?: boolean;
}>();

const emit = defineEmits<{
  (e: "updateLaborInfos", form: LaborInfoForm[]): void;
}>();

function createDates(year: number) {
  const yearStart = `${year}-01-01`;
  const yearEnd = `${year}-12-31`;

  return createDateMapRange(yearStart, yearEnd).map((map) => ({
    ...map,
    dayCount: getDaysCountInMonth(map),
  }));
}

const dates = computed(() => {
  return createDates(props.year);
});

function createDefault(dates: DateMap[]) {
  return dates.map((d, idx) => {
    const daysInMonth = getDaysCountInMonth(d);

    const targetLabor =
      props.member.organizationMemberLabors &&
      props.member.organizationMemberLabors.find((labor) =>
        isSameMonth(d, labor.targetDate)
      );

    if (targetLabor) {
      return _.cloneDeep(targetLabor);
    } else {
      return createDefaultLaborInfoForm({
        organizationMemberId: props.member.id,
        selectedDate: d,
        daysInMonth,
      });
    }
  });
}

const formItems = ref<LaborInfoForm[]>([]);

onMounted(() => {
  formItems.value = createDefault(dates.value);
});

watch(
  () => props.year,
  (nYear) => {
    const dates = createDates(nYear);

    formItems.value = createDefault(dates);
  }
);

watch(
  () => props.member,
  (nMember) => {
    const dates = createDates(props.year);
    formItems.value = createDefault(dates);
  }
);

function updateLaborInfos(
  prms: {
    key: keyof LaborInfoForm;
    val: number | string | undefined;
  },
  idx: number,
  d: DateMap
) {
  const targetFormItem = formItems.value[idx];

  laborInfoUpdater(targetFormItem, prms);

  if (isPrescribedWorkingDaysCase(prms)) {
    const daysInMonth = getDaysCountInMonth(d);

    laborInfoUpdater(targetFormItem, {
      key: "publicHolidays",
      // @ts-ignore prms.key === "prescribedWorkingDays" && typeof prms.val === "number"
      val: daysInMonth - prms.val,
    });
  }
}

function updateForms() {
  emit("updateLaborInfos", formItems.value);
}
</script>

<style scoped lang="scss">
td,
th {
  white-space: nowrap;
}

td,
th {
  &.cell-month {
    max-width: 30px;
    min-width: 30px;
  }

  &.cell-day {
    max-width: 30px;
    min-width: 30px;
  }
}
</style>
