<template>
  <div>
    <div class="mb-4">
      <FormItem
        :value="organization.name"
        label="会社名"
        form-id="name"
        form-type="text"
        :disabled="!editMode"
        :errors="errors"
        @update:value="organizationForm.name = $event?.toString()"
      />
    </div>

    <div class="mb-4">
      <FormItem
        :value="editMode ? organizationForm.phoneNumber : parsedPhoneNumber"
        label="電話番号"
        form-id="phoneNumber"
        form-type="tel"
        :errors="errors"
        :disabled="!editMode"
        @update:value="organizationForm.phoneNumber = $event?.toString()"
      />
    </div>

    <div v-if="editMode" class="mb-4">
      <FormItem
        :value="editMode ? organizationForm.postcode : parsedPostcode"
        label="郵便番号"
        form-id="postcode"
        form-type="number"
        :errors="errors"
        :disabled="!editMode"
        @update:value="organizationForm.postcode = $event?.toString()"
      />
    </div>

    <div class="">
      <FormItem
        :value="
          editMode
            ? organizationForm.address
            : `${formatPostcode(organization.postcode) || ''} ${
                organization.address || ''
              } ${organization.addressAfter || ''}`
        "
        :label="editMode ? '住所(自動入力)' : '住所'"
        form-id="address"
        form-type="text"
        :errors="errors"
        :disabled="!editMode"
        :readonly="true"
        @update:value="updateAddress"
      />
    </div>

    <div v-if="editMode" class="mt-4">
      <FormItem
        :value="organization.addressAfter"
        label="以降の住所"
        form-id="addressAfter"
        form-type="text"
        :errors="errors"
        @update:value="organizationForm.addressAfter = $event?.toString()"
      />
    </div>

    <div v-if="editMode" class="w-100 mt-4">
      <div v-if="!loading" class="d-flex flex-row align-center">
        <BasicButton
          class="m-1 ms-0"
          button-type="button"
          variant="danger"
          icon="pi pi-stop-circle"
          label="キャンセル"
          @click="$emit('cancel')"
        />

        <BasicButton
          icon="pi pi-refresh"
          label="更新"
          button-type="submit"
          @click="submit"
        />
      </div>
      <LoadingAnimation v-else></LoadingAnimation>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from "vue";
import zod from "zod";
import jsonpAdapter from "axios-jsonp";
import { axios } from "/@/modules/axios";
import { z } from "/@/modules/zodUtils";
import { useZodScheme } from "/@/vue/composables";
import { toHalf } from "/@/modules/string";
import { parseJpPhoneNumber } from "/@/modules/phonenumber";
import { formatPostcode } from "/@/modules/address";
import { BasicButton, LoadingAnimation } from "/@/vue/components/Atom";
import { FormItem } from "/@/vue/components/Molecules";
import { parsePhoneNumber } from "libphonenumber-js";
import { errorHandle } from "/@/modules/error";
import { Organization } from "/@/types";
import { isString, isUndefined } from "lodash";

const props = defineProps<{
  editMode?: boolean;
  loading?: boolean;
  organization: Organization;
}>();

const emit = defineEmits<{
  (e: "cancel"): void;
  (e: "submit", organization: zod.infer<typeof OrganizationFormScheme>): void;
}>();

// scheme

const OrganizationFormSchemeBase = z.object({
  id: z.number(),
  name: z.string(),
  phoneNumber: z.string(),
  postcode: z.string(),
  address: z.string(),
  addressAfter: z.string().optional(),
});

const OrganizationFormScheme = OrganizationFormSchemeBase.refine(
  (data) => {
    const isPhoneNumber = !!parseJpPhoneNumber(data.phoneNumber);
    return isPhoneNumber;
  },
  {
    message: "正しい電話番号を入力してください",
    path: ["phoneNumber"],
  }
);

const { useFormAndErrors } = useZodScheme();
const OrganizationFormPartialScheme = OrganizationFormSchemeBase.partial();
type OrganizationForm = zod.infer<typeof OrganizationFormPartialScheme>;

const {
  form: organizationForm,
  errors,
  startValidation,
} = useFormAndErrors<OrganizationForm>(OrganizationFormScheme);

function fillForm() {
  if (props.organization) {
    organizationForm.id = props.organization.id;
    organizationForm.name = props.organization.name;
    organizationForm.phoneNumber = props.organization.phoneNumber;
    organizationForm.postcode = props.organization.postcode;
    organizationForm.address = props.organization.address;
    organizationForm.addressAfter = props.organization.addressAfter;
  }
}

onMounted(() => {
  fillForm();
});

watch(
  () => props.editMode,
  (mode) => {
    if (mode) {
      fillForm();
    }
  }
);

// show

const parsedPhoneNumber = computed(() => {
  if (!props.organization.phoneNumber) {
    return undefined;
  }

  const phoneNumber = parsePhoneNumber(props.organization.phoneNumber, "JP");
  return phoneNumber?.formatNational();
});

const parsedPostcode = computed(() => {
  if (
    !props.organization.postcode.length ||
    props.organization.postcode.length < 7
  ) {
    return undefined;
  }

  return (
    props.organization.postcode.slice(0, 3) +
    "-" +
    props.organization.postcode.slice(3)
  );
});

// autofill address

type ZipAddress = {
  code: number;
  pref: string;
  address: string;
  city: string;
  town: string;
  fullAddress: string;
};

watch(
  () => organizationForm.postcode,
  async (inputPostcode) => {
    try {
      if (!props.editMode || !inputPostcode) {
        return;
      }

      const postcode = toHalf(inputPostcode);

      const { data }: { data: ZipAddress } = await axios.get(
        `https://api.zipaddress.net/?zipcode=${postcode}`,
        { adapter: jsonpAdapter }
      );

      if (!data || data.code == 400 || data.code == 404) {
        organizationForm.address = undefined;
        return;
      }
      organizationForm.address = data.fullAddress;
    } catch (e) {
      errorHandle(e);
    }
  }
);

// update

function updateAddress(address: string | number | undefined) {
  if (isString(address) || isUndefined(address)) {
    organizationForm.address = address;
  } else {
    throw new Error("address is not string");
  }
}

// submit

function submit() {
  try {
    startValidation.value = true;
    const organization = OrganizationFormScheme.parse(organizationForm);
    emit("submit", organization);
  } catch (e) {
    errorHandle(e);
  }
}
</script>

<style scoped>
div.form-control {
  padding: 0;
  border: none;
}
</style>
