<template>
  <div>
    <FormItemSelector
      :value="workScheduleForAssignment.id"
      label=""
      form-type="object"
      :errors="{}"
      :items="unselectedSchedules"
      form-id="workScheduleIds"
      :show-fn="workScheduleToTitle"
      value-key="id"
      @update:value="updateAssignment('id', $event.id)"
    />
    <NumberForm
      v-if="isForOrganization"
      :value="workScheduleForAssignment.needCount"
      :items="selectItems"
      :form-id="`need-count-${idx}`"
      class="ms-2"
      @update:value="updateAssignment('needCount', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";
import { workScheduleToTitle } from "/@/modules/workSchedule";
import { NumberForm } from "/@/vue/components/Atom";
import { FormItemSelector } from "/@/vue/components/Molecules";
import { WorkScheduleForWorkAssignments, WorkScheduleAsPlan } from "/@/types";
import { computed } from "vue";

const props = defineProps<{
  workScheduleForAssignment: WorkScheduleForWorkAssignments;
  unselectedSchedules: WorkScheduleAsPlan[];
  idx: number;
  isForOrganization: boolean;
}>();

const emit = defineEmits<{
  (
    e: "updateWorkScheduleForAssignment",
    ws: WorkScheduleForWorkAssignments
  ): void;
}>();

function updateAssignment(
  key: keyof WorkScheduleForWorkAssignments,
  value: number
) {
  const curr = _.cloneDeep(props.workScheduleForAssignment);

  curr[key] = value;

  emit("updateWorkScheduleForAssignment", curr);
}

const targetSchedule = computed(() => {
  return props.unselectedSchedules.find(
    (ws) => ws.id === props.workScheduleForAssignment.id
  );
});

const needCountOfSchedule = computed(() => {
  if (!targetSchedule.value) return 0;

  return targetSchedule.value.needCount;
});

const selectItems = computed(() => {
  return _.range(1, (needCountOfSchedule.value || 0) + 1);
});
</script>

<style scoped></style>
