import { axios } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";

export function useOrganizationSchedule() {
  async function updateOrganizationSchedule(prms: {
    id: number;
    workScheduleId: number;
    targetDate: string;
    needCount: number;
  }) {
    try {
      await axios.patch(`/api/v1/organization_schedules/${prms.id}`, {
        organization_schedules: {
          work_schedule_id: prms.workScheduleId,
          target_date: prms.targetDate,
          need_count: prms.needCount,
        },
      });

      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function destroyOrganizationSchedule(id: number) {
    try {
      await axios.delete(`/api/v1/organization_schedules/${id}`);

      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    updateOrganizationSchedule,
    destroyOrganizationSchedule,
  };
}
