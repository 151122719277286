<template>
  <div>
    <table
      class="table table-responsive table-bordered table-striped small bg-white table-sm"
    >
      <thead>
        <tr>
          <th>勤務日数</th>
          <th>休日勤務日数</th>
          <th>欠勤日数</th>
          <th>遅刻早退時間</th>
          <th>取得有給日数</th>
          <th>特別休暇日数</th>
          <th>有給残日数</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ totalWorkingDays }}</td>
          <td>{{ holidayWorkingDays }}</td>
          <td>{{ absenteeismCount }}</td>
          <td>0</td>
          <td>-</td>
          <td>{{ targetMonthLabor?.specialLeaveDays }}</td>
          <td>{{ targetMonthLabor?.remainingPaidHolidays }}</td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th>勤務時間</th>
          <th>休日勤務時間</th>
          <th>残業時間</th>
          <th>深夜残業時間</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ totalWorkingHours }}</td>
          <td>0</td>
          <td>0</td>
          <td>0</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import {
  countDaysInIsoArray,
  isAfterToday,
  isSameDate,
  isSameMonth,
  isToday,
} from "/@/modules/luxon";
import { getTotalWorkingHours, getRecordStatus } from "/@/modules/workRecords";
import type {
  WorkRecordClient,
  OrganizationMemberSchedulePlan,
  OrganizationMember,
  DateMap,
} from "/@/types";

const props = defineProps<{
  member: OrganizationMember;
  records: WorkRecordClient[];
  schedules: OrganizationMemberSchedulePlan[];
  showRecordTime?: boolean;
  selectedDate: DateMap;
}>();

const doneMemberSchedules = computed(() => {
  return props.schedules.filter((schedule) => {
    return schedule.workSchedules?.every((wSchedule) => wSchedule.done);
  });
});

const targetMonthLabor = computed(() => {
  return props.member.organizationMemberLabors?.find((labor) =>
    isSameMonth(props.selectedDate, labor.targetDate)
  );
});

const totalWorkingDays = computed(() => {
  return countDaysInIsoArray(props.records.map((record) => record.startAt));
});

const totalWorkingHours = computed(() => {
  if (props.showRecordTime) {
    const entries = props.records
      .map((record) => record.workRecordEntries)
      .flat();

    const restTimes = props.records
      .map((record) => record.restTimeEntries)
      .flat();

    return getTotalWorkingHours(entries, restTimes);
  } else {
    const workSchedules = doneMemberSchedules.value
      .map((schedule) => schedule.workSchedules)
      .flat();
    const restTimeSchedules = doneMemberSchedules.value
      .map((schedule) => schedule.restTimeSchedules)
      .flat();

    return getTotalWorkingHours(workSchedules, restTimeSchedules);
  }
});

const holidayWorkingDays = computed(() => {
  return 0;
});

const absenteeismCount = computed(() => {
  const beforeTodaySchedules = props.schedules.filter(
    (schedule) =>
      !isAfterToday(schedule.targetDate) && !isToday(schedule.targetDate)
  );

  return beforeTodaySchedules
    .map((schedule) => {
      const targetDate = schedule.targetDate;
      const targetDateRecords = props.records.filter((record) =>
        isSameDate(record.startAt, targetDate)
      );
      return getRecordStatus(targetDateRecords, schedule);
    })
    .filter((status) => status === "absenteeism").length;
});
</script>

<style scoped></style>
