import zod from "zod";
import { z } from "/@/modules/zodUtils";

export const SickDayBodyTemperatureScheme = z.object({
  temperature: z.number().max(44).min(34),
  photo: z.object({ data: z.string() }),
  explanation: z.string(),
});

export const BodyTemperatureScheme = SickDayBodyTemperatureScheme.partial({
  photo: true,
  explanation: true,
});

export const BodyTemperatureFormScheme = BodyTemperatureScheme.partial();

export type BodyTemperatureForm = zod.infer<typeof BodyTemperatureFormScheme>;

export const BodyTemperatureClientScheme = BodyTemperatureScheme.extend({
  id: z.number(),
  noWork: z.boolean(),
  photoUrl: z.string(),
});

export type bodyTemperatureClient = zod.infer<
  typeof BodyTemperatureClientScheme
>;
