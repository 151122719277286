<template>
  <div>
    <div class="mb-3"></div>
    <AdminEventPlaceList
      :event-places="eventPlaces || []"
      :work-places="filteredWorkPlaces"
      @update-event-place="handleUpdateEventPlace"
      @create-event-place="handleCreateEventPlace"
      @destroy-event-place="handleDestroyEventPlace"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useAdmin, useEventPlaces } from "/@/vue/composables";
import { AdminEventPlaceList } from "/@/vue/components/Organisms";
import { EventPlace, EventPlaceUpdate } from "/@/types";

const { getEventPlaces, getWorkPlaces } = useAdmin();
const { data: workPlaces } = getWorkPlaces();

const filteredWorkPlaces = computed(() => {
  if (!workPlaces.value) return [];

  return workPlaces.value.filter(
    (wp) => !["自宅", "FRYTH事務所"].includes(wp.name)
  );
});

const { data: eventPlaces, mutate: getEventPlaceMutate } = getEventPlaces();

const { updateEventPlace, createEventPlace, destroyEventPlace } =
  useEventPlaces();

async function handleUpdateEventPlace(prms: EventPlaceUpdate) {
  if (await updateEventPlace(prms)) {
    getEventPlaceMutate();
    alert("更新に成功しました");
  } else {
    alert("更新に失敗しました");
  }
}

async function handleCreateEventPlace(prms: EventPlace) {
  if (await createEventPlace(prms)) {
    getEventPlaceMutate();
    alert("作成に成功しました");
  } else {
    alert("作成に失敗しました");
  }
}

async function handleDestroyEventPlace(id: number) {
  if (
    !window.confirm(
      "イベント会場を削除しますか？(すでにスケジュールや業績に紐づいている場合は削除できません)"
    )
  ) {
    return;
  }

  if (await destroyEventPlace(id)) {
    getEventPlaceMutate();
    alert("削除に成功しました");
  }
}
</script>

<style scoped></style>
