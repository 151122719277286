<template>
  <WorkReportForm
    :report-items="reportItems"
    :work-type-categories="workTypeCategories"
    :work-carrier="workCarrier"
    :work-category="workCategory"
    :report-type="reportType"
    :item-filter="meetingTargetFilter"
    :basis-report-items="basisReportItems"
    :errors="errors"
    is-meeting-targets
    @update:report-items="updateReportItems"
  />
</template>

<script setup lang="ts">
import { WorkReportForm } from "/@/vue/components/Organisms";
import {
  ReportType,
  ReportItemForm,
  ReportItemFilter,
  OrganizationCategory,
  Carrier,
  WorkCategory,
  BasisReportItemClient,
  MeetingTargetReportItem,
} from "/@/types";
import { ZodFormattedErrors } from "/@/modules/zodUtils";

defineProps<{
  workTypeCategories: OrganizationCategory[];
  workCarrier: Carrier;
  workCategory?: WorkCategory;
  reportItems?: ReportItemForm[];
  reportType?: ReportType;
  basisReportItems: BasisReportItemClient[];
  meetingTargetFilter?: ReportItemFilter[];
  errors: ZodFormattedErrors;
}>();

const emit = defineEmits<{
  (e: "update:reportItems", items: MeetingTargetReportItem[]): void;
}>();

function updateReportItems(reportItems: ReportItemForm[]) {
  emit(
    "update:reportItems",
    reportItems.map((ri) => ({
      ...ri,
      currentCount: 0,
      currentOrganizationCount: 0,
    }))
  );
}
</script>

<style scoped></style>
