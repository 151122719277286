<template>
  <div>
    <PageHeader page-title="業務計画" hide-back-btn />
    <OrganizationSelector
      v-model:selected-organization-id="selectedOrganizationId"
      class="mb-3"
      :organizations="organizations || []"
    />

    <SelectButton
      v-if="isOwner"
      v-model="selectedCalendarType"
      :options="calendarToggleTypes"
      option-label="name"
      option-value="key"
      :allow-empty="false"
      class="mb-3"
    />

    <template v-if="selectedOrganization">
      <SelectDate
        v-show="selectedCalendarType != 'calendar'"
        v-model:year="selectedDate.year"
        v-model:month="selectedDate.month"
        :exists-dates="existsDates"
        only-year-month
        disabled-auto-select
        class="mb-3"
      />

      <div
        v-if="isFRYTHOwner && selectedCalendarType === 'organization'"
        class="d-flex justify-content-between w-100"
      >
        <div class="d-flex flex-wrap align-items-end mb-3">
          <div class="mb-3 me-2">
            <ContentLabel label="ハイライトするメンバー" without-padding />
            <ObjectSelector
              :value="selectedOrganizationMember?.id"
              :items="visibleMembers"
              value-key="id"
              :show-fn="memberShow"
              :is-valid="true"
              :form-id="`organization-member-selecter`"
              class="w-300"
              can-without-select
              @update:value="
                selectedOrganizationMember = $event as OrganizationMember
              "
            />
          </div>
          <div v-if="selectedOrganizationMember">
            <MemberOperationInfo
              :members="[selectedOrganizationMember]"
              :schedules="organizationMemberSchedulePlansEmbeded"
              :selected-date="selectedDate"
              class="mb-3"
            />
          </div>
        </div>
      </div>

      <WorkListFilter
        v-show="selectedCalendarType == 'organization'"
        v-model:selected-date="selectedDate"
        v-model:selected-carriers="selectedCarriers"
        v-model:selected-categories="selectedCategories"
        only-checker
      />

      <div class="d-flex justify-content-end align-items-center w-100 mb-3">
        <MenuButton :menu-items="menuItems" />
      </div>

      <SelectButton
        v-if="selectedCalendarType === 'calendar'"
        v-model="selectedEventTypes"
        :options="eventTypes"
        :option-label="eventTypeToStr"
        multiple
        class="mb-3"
      />

      <div
        v-if="selectedOrganization"
        id="pdf-contents"
        class="calendar-wrapper mb-3"
      >
        <Calendars
          v-model:selected-date="selectedDate"
          :selected-organization="selectedOrganization"
          :selected-organization-member="selectedOrganizationMember"
          :selected-calendar-type="selectedCalendarType"
          :exists-dates="existsDates"
          :events="events"
          :organization-members="organizationMembers || []"
          :organization-member-schedule-plans="
            organizationMemberSchedulePlansEmbeded
          "
          :work-as-plans="filteredWorksAsPlan"
          :work-schedule-as-plans="workScheduleAsPlans || []"
          :work-records="workRecords"
          :absence-application-plans="absenceApplicationPlansEmbeded"
          :highlight-non-enough="highlightNonEnough"
          :highlight-canceled="highlightCanceled"
          :is-owner="isOwner"
          :is-fryth-owner="isFRYTHOwner"
          :can-show-member-work-schedule-calendar="
            canShowMemberWorkScheduleCalendar ||
            canShowMemberWorkScheduleCalendarAdmin
          "
          @open-show-plan-modal="openShowPlanModal"
          @select-member-schedule-as-plan="selectMemberScheduleAsPlan"
          @select-work-schedule="selectWorkSchedule"
          @select-schedule-as-plan="selectScheduleAsPlan"
          @get-plan-map-mutate="getPlanMapMutate"
        />
      </div>
      <LoadingAnimation v-else />

      <Accordion v-if="isAdmin">
        <AccordionTab header="メンバーの稼働数一覧">
          <div class="d-flex flex-row mb-2">
            <MemberOperationInfo
              :members="fulltimeMembers"
              :schedules="organizationMemberSchedulePlansEmbeded"
              :selected-date="selectedDate"
              class="me-1"
            />
            <MemberOperationInfo
              v-for="(group, idx) in outsourcingMembersGroups"
              :key="`outsourcing-group-${idx}`"
              :members="group"
              :schedules="organizationMemberSchedulePlansEmbeded"
              :selected-date="selectedDate"
              class="me-1"
            />
          </div>
        </AccordionTab>
      </Accordion>
      <teleport to="body">
        <SubmitApplicationModal
          ref="applicationModal"
          :is-owner="isOwner && isFRYTH"
          :organizations="organizations"
          :organization-members="organizationMembers"
          :selected-organization="selectedOrganization"
          :absence-application-plans="absenceApplicationPlansEmbeded"
          @submit="submitApplication"
        />
        <SelectMemberScheduleModal
          ref="selectNextActionModal"
          @select-member="selectMember"
          @select-organization="selectOrganization"
          @select-new-assignment="selectNewAssignment"
          @close-modal="closeSelectMemberScheduleModal({ forCloseEvent: true })"
        />
        <ShowOrganizationEventInvitationModal
          ref="showOrganizationEventInvitationModal"
          :organization="selectedOrganization"
        />
        <ShowPlanModal
          ref="showPlanModal"
          :is-owner="isOrganizationOwner"
          :is-fryth-owner="isFRYTHOwner"
          :selected-organiaztion="selectedOrganization"
          :loading="loading"
          @accept-plan="handleAbsenceAccepet($event as AbsenceApplicationPlan)"
          @delete-plan="handleAbsenceDestroy($event as AbsenceApplicationPlan)"
          @destroy-member-schedule="
            handleOrganizationMemberDestroy(
              $event as OrganizationMemberSchedule
            )
          "
          @destroy-work-schedule="
            handleDestroyWorkSchedule($event as OrganizationMemberSchedulePlan)
          "
          @cancel-work-schedule="
            handleCancelWorkScheduleInMemberSchedule(
              $event as OrganizationMemberSchedulePlan
            )
          "
          @restore-work-schedule="
            handleRestoreWorkScheduleInMemberSchedule(
              $event as OrganizationMemberSchedulePlan
            )
          "
          @destroy-work-schedule-assignment="
            handleDestroyWorkScheduleAssignment
          "
          @destroy-organization-schedule="handleDestroyOrganizationSchedule"
          @goto-works-edit="handleGotoWorksEdit($event as WorkAsPlan)"
          @close-modal="closeShowPlanModal({ forCloseEvent: true })"
          @update-organization-member-schedule="
            handleUpdateOrganizationSchedule
          "
          @update-assignment-vacancy="handleVacancyWorkScheduleAssignment"
        />
        <WorkScheduleAssignmentModal
          v-if="selectedOrganization"
          ref="workScheduleAssigmentModal"
          :organization-members="organizationMembers || []"
          :selected-organization="selectedOrganization"
          :sub-contractors="subContractors"
          :work-schedules="workScheduleAsPlans || []"
          :absence-application-plans="absenceApplicationPlansEmbeded"
          :loading="loading"
          :is-fryth-owner="isFRYTHOwner"
          @submit="submitWorkScheduleAssignment"
          @destroy="handleDestroyWorkScheduleInAssignModal"
          @cancel="handleCancelWorkSchedule"
          @restore="handleRestoreWorkSchedule"
          @close="closeWorkScheduleAssignmentModal(true)"
          @vacancy="handleVacancyWorkSchedule"
        />
      </teleport>
    </template>
  </div>
</template>

<script setup lang="ts">
import SelectButton from "primevue/selectbutton";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import { ref, computed, watch } from "vue";
import { setStorage, getStorage } from "/@/modules/localStorage";
import { memberShowFn } from "/@/modules/organizationMember";
import { luxonNow, createIsoRange } from "/@/modules/luxon";
import { generatePDF } from "/@/modules/pdf";
import {
  useOrganization,
  useOrganizationMember,
  useOrganizationMemberSchedule,
  useOrganizationSchedule,
  useAbsenceApplicationPlan,
  useCalendar,
  usePlans,
  useRouterUtil,
  useWorkSchedules,
  useWorkScheduleAssignments,
  useWorkRecords,
  useMqUtils,
} from "/@/vue/composables";
import { PageHeader } from "/@/vue/components/Layouts";
import {
  SelectDate,
  ObjectSelector,
  ContentLabel,
  LoadingAnimation,
} from "/@/vue/components/Atom";
import { MenuButton } from "/@/vue/components/Molecules";
import {
  OrganizationSelector,
  ShowPlanModal,
  SubmitApplicationModal,
  WorkScheduleAssignmentModal,
  SelectMemberScheduleModal,
  MemberOperationInfo,
  WorkListFilter,
  ShowOrganizationEventInvitationModal,
} from "/@/vue/components/Organisms";
import { Calendars } from "/@/vue/components/WithSideEffects";
import type {
  OrganizationMember,
  SubmitApplicationOwnerForm,
  SubmitApplicationOwneeForm,
  Carrier,
  WorkCategory,
  CalendarEventArgs,
  AbsenceApplicationPlan,
  EventType,
  OrganizationMemberSchedule,
  OrganizationMemberSchedulePlan,
  WorkAsPlansLight,
  OrganizationMemberSchedulePlanLight,
  OrganizationSchedulePlanLight,
  DateMap,
  ToggleType,
  WorkAsPlan,
  WorkScheduleAsPlan,
  AssignedMember,
  WorkScheduleAssignment,
  AssignedOrganization,
  PlanMap,
  OrganizationEventInvitationClient,
  OrganizationSchedulePlan,
} from "/@/types";
import { onMounted } from "vue";

const { isMobile } = useMqUtils();

const loading = ref<boolean>(false);

function memberShow(member: OrganizationMember) {
  return memberShowFn(member, selectedOrganization.value, subContractors.value);
}

// calendar

const today = luxonNow();
const selectedCalendarType = ref<ToggleType>(
  getStorage("PlanIndexSelectedCalendarType") || "calendar"
);
watch(selectedCalendarType, (t) => {
  setStorage("PlanIndexSelectedCalendarType", t);

  if (t !== "calendar") {
    selectedEventTypes.value = [...calendarEventTypes];
  } else {
    selectedEventTypes.value = [
      "owner_organization_member_schedule",
      "organization_event_invitation",
    ];
  }
});

const calendarToggleTypes = computed(() => {
  return [
    {
      name: "カレンダー",
      key: "calendar",
    },
    {
      name: "出勤表",
      key: "user",
    },
    isFRYTHOwner.value ||
    canShowMemberWorkScheduleCalendar.value ||
    canShowMemberWorkScheduleCalendarAdmin.value
      ? {
          name: "稼動表",
          key: "organization",
        }
      : undefined,
  ].filter((b) => b);
});

const selectedDate = ref<DateMap>({
  year: today.year,
  month: today.month,
});

// organizations

const {
  getOrganizations,
  organizations,
  subContractors,
  selectedOrganizationId,
  selectedOrganization,
  isOwner,
  isOrganizationOwner,
  isFRYTHOwner,
  isFRYTH,
  isAdmin,
  canShowMemberWorkScheduleCalendar,
  canShowMemberWorkScheduleCalendarAdmin,
} = useOrganization();
const { data: organizationsData } = getOrganizations({
  revalidateOnFocus: false,
});

const fullAccessWorkScheduleCalendar = computed(() => {
  return isAdmin.value;
});

watch(organizationsData, (os) => {
  organizations.value = os || [];
});

onMounted(() => {
  organizations.value = organizationsData.value || [];
});

// organizationMembers

const needAll = computed(() => {
  return (
    canShowMemberWorkScheduleCalendarAdmin.value &&
    selectedCalendarType.value === "organization"
  );
});

const { getOrganizationMembers } = useOrganizationMember();
const { data: organizationMembers } = getOrganizationMembers(
  selectedOrganization,
  isOwner,
  selectedDate,
  { withLaborInfo: true, needAll: needAll.value, revalidateOnFocus: false }
);

const visibleMembers = computed(() => {
  if (!organizationMembers.value) return [];

  return organizationMembers.value.filter((member) => !member.hideFromSelect);
});

const fulltimeMembers = computed(() => {
  if (!organizationMembers.value) return [];
  if (!selectedOrganization.value) return [];

  return organizationMembers.value.filter(
    (member) =>
      member.organizationId === selectedOrganization.value?.id &&
      !member.hideFromSelect
  );
});

const outsourcingMembers = computed(() => {
  if (!organizationMembers.value || !selectedOrganization.value) return [];

  return organizationMembers.value.filter(
    (member) =>
      member.organizationId !== selectedOrganization.value?.id &&
      !member.hideFromSelect
  );
});

const outsourcingMembersGroups = computed(() => {
  if (!outsourcingMembers.value) return [];

  const groups = [];

  for (let i = 0; i < outsourcingMembers.value.length; i += 10) {
    groups.push(outsourcingMembers.value.slice(i, i + 10));
  }

  return groups;
});

const selectedOrganizationMember = ref<OrganizationMember | undefined>();

const highlightNonEnough = ref<boolean>(false);
const highlightCanceled = ref<boolean>(false);

// menu

const menuItems = computed(() => [
  {
    label: "メニュー",
    items: [
      {
        label: "PDFで出力",
        icon: "pi pi-print",
        command: () => {
          generatePlanPDF();
        },
        visible: isFRYTHOwner.value && !isMobile.value,
      },
      {
        label: "更新",
        icon: "pi pi-refresh",
        command: () => {
          mutateAll();
        },
        visible: false,
      },
      {
        label: "各種申請",
        icon: "pi pi-file-edit",
        command: () => {
          openApplicationModal();
        },
        visible: true,
      },
      {
        label: "未設定項目を強調",
        icon: highlightNonEnough.value ? "pi pi-eye" : "pi pi-eye-slash",
        command: () => {
          highlightNonEnough.value = !highlightNonEnough.value;
        },
        visible: isAdmin.value && selectedCalendarType.value === "organization",
        style: {
          "background-color": highlightNonEnough.value
            ? "var(--cyan-200)"
            : "inherit",
        },
      },
      {
        label: "中止項目を強調",
        icon: highlightCanceled.value ? "pi pi-eye" : "pi pi-eye-slash",
        command: () => {
          highlightCanceled.value = !highlightCanceled.value;
        },
        visible:
          isAdmin.value &&
          (selectedCalendarType.value === "organization" ||
            selectedCalendarType.value === "user"),
        style: {
          "background-color": highlightCanceled.value
            ? "var(--cyan-200)"
            : "inherit",
        },
      },
    ],
  },
]);

// plans

const {
  getPlanMaps,
  getAbsenceApplicationPlans,
  getWorkAsPlans,
  getWorkScheduleAsPlans,
  getOrganizationMemberSchedules,
  getOrganizationSchedules,
  getAssignOrganizationSchedules,
} = usePlans();
const { data: planMapsSource, mutate: getPlanMapMutate } = getPlanMaps(
  selectedOrganization,
  isOwner,
  selectedDate,
  {
    page: "plans_index",
  }
);

const planMaps = ref<PlanMap>({
  organizationEventInvitations: [] as OrganizationEventInvitationClient[],
  absenceApplicationPlans: [] as AbsenceApplicationPlan[],
  workAsPlans: [] as WorkAsPlan[],
  workScheduleAsPlans: [] as WorkScheduleAsPlan[],
  organizationMemberSchedulePlans: [] as OrganizationMemberSchedulePlan[],
  organizationSchedulePlans: [] as OrganizationSchedulePlan[],
  assignOrganizationSchedulePlans: [] as OrganizationSchedulePlan[],
});

watch(planMapsSource, (pms) => {
  if (!isOwner.value && pms) {
    planMaps.value = pms;
  }
});

const {
  data: absenceApplicationPlans,
  mutate: getAbsenceApplicationPlansMutate,
} = getAbsenceApplicationPlans(selectedOrganization, selectedDate, isOwner, {
  revalidateOnFocus: false,
});

const absenceApplicationPlansEmbeded = computed(() => {
  if (!isOwner.value) return planMaps.value?.absenceApplicationPlans || [];

  if (!absenceApplicationPlans.value) return [];

  return absenceApplicationPlans.value;
});

const { data: workScheduleAsPlans, mutate: getWorkScheduleAsPlansMutate } =
  getWorkScheduleAsPlans(selectedOrganization, selectedDate, isOwner, {
    revalidateOnFocus: false,
    page: "plans_index",
  });

function embedWorkSchedule(
  plans:
    | WorkAsPlansLight[]
    | OrganizationMemberSchedulePlanLight[]
    | OrganizationSchedulePlanLight[],
  workScheduleAsPlans?: WorkScheduleAsPlan[]
) {
  if (!plans) return [];

  return (
    plans.map((p) => {
      return {
        ...p,
        workSchedules: p.workScheduleIds
          .map((sid) => workScheduleAsPlans?.find((ws) => ws.id === sid))
          .filter((b) => b),
      };
    }) || []
  );
}

const { data: workAsPlans, mutate: getWorkAsPlansMutate } = getWorkAsPlans(
  selectedOrganization,
  selectedDate,
  isOwner,
  { revalidateOnFocus: false }
);

const workAsPlansEmbeded = computed(() => {
  if (!isOwner.value) return planMaps.value?.workAsPlans || [];

  if (!workAsPlans.value) return [];

  return embedWorkSchedule(
    workAsPlans.value,
    workScheduleAsPlans.value
  ) as (WorkAsPlan & { workSchedules: WorkScheduleAsPlan[] })[];
});

const {
  data: organizationMemberSchedulePlans,
  mutate: getOrganizationMemberSchedulesMutate,
} = getOrganizationMemberSchedules(
  selectedOrganization,
  selectedDate,
  isOwner,
  {
    revalidateOnFocus: false,
  }
);

const organizationMemberSchedulePlansTempo = ref<
  OrganizationMemberSchedulePlanLight[]
>([]);

watch(organizationMemberSchedulePlans, (omsps) => {
  if (isOwner.value && omsps) {
    organizationMemberSchedulePlansTempo.value = omsps;
  }
});

const organizationMemberSchedulePlansEmbeded = computed(() => {
  if (!isOwner.value)
    return planMaps.value?.organizationMemberSchedulePlans || [];

  if (!organizationMemberSchedulePlans.value) return [];

  return embedWorkSchedule(
    organizationMemberSchedulePlansTempo.value,
    workScheduleAsPlans.value
  ) as OrganizationMemberSchedulePlan[];
});

const {
  data: organizationSchedulePlans,
  mutate: getOrganizationSchedulesMutate,
} = getOrganizationSchedules(selectedOrganization, selectedDate, isOwner, {
  revalidateOnFocus: false,
});

const organizationSchedulePlansEmbeded = computed(() => {
  if (!isOwner.value) return planMaps.value?.organizationSchedulePlans || [];

  if (!organizationSchedulePlans.value) return [];

  return embedWorkSchedule(
    organizationSchedulePlans.value,
    workScheduleAsPlans.value
  ) as OrganizationSchedulePlan[];
});

const {
  data: assignOrganizationSchedulePlans,
  mutate: getAssignOrganizationSchedulesMutate,
} = getAssignOrganizationSchedules(
  selectedOrganization,
  selectedDate,
  isOwner,
  {
    revalidateOnFocus: false,
  }
);

const assignOrganizationSchedulePlansEmbeded = computed(() => {
  if (!isOwner.value)
    return planMaps.value?.assignOrganizationSchedulePlans || [];

  if (!assignOrganizationSchedulePlans.value) return [];

  return embedWorkSchedule(
    assignOrganizationSchedulePlans.value,
    workScheduleAsPlans.value
  ) as OrganizationSchedulePlan[];
});

// work records

const { getOrganizationWorkRecords } = useWorkRecords();
const { data: workRecords, mutate: getOrganizationWorkRecordMutate } =
  getOrganizationWorkRecords(
    selectedOrganization,
    selectedDate,
    () => isOwner.value,
    {
      revalidateOnFocus: false,
    }
  );

// organization event

const organizationEventInvitations = computed(() => {
  if (!selectedOrganization.value) return [];

  return selectedOrganization.value.organizationEventInvitations.filter(
    (oei) => oei.eventApplicationStatus === "approved"
  );
});

// update dependancy

async function mutateAll() {
  await getWorkScheduleAsPlansMutate();

  getAssignOrganizationSchedulesMutate();
  getOrganizationSchedulesMutate();
  getOrganizationMemberSchedulesMutate();
  getWorkAsPlansMutate();
  getAbsenceApplicationPlansMutate();
  getOrganizationWorkRecordMutate();
}

// events

type CalendarEventType =
  | "absence"
  | "work_schedule"
  | "owner_organization_member_schedule"
  | "organization_member_schedule"
  | "organization_schedule"
  | "assign_organization_schedule"
  | "organization_event_invitation";

const calendarEventTypes: CalendarEventType[] = [
  "absence",
  "work_schedule",
  "owner_organization_member_schedule",
  "organization_member_schedule",
  "organization_schedule",
  "assign_organization_schedule",
  "organization_event_invitation",
];

const eventTypes = computed<CalendarEventType[]>(() => {
  return [
    "owner_organization_member_schedule",
    "organization_event_invitation",
    "absence",
    isOwner.value ? "organization_member_schedule" : undefined,
    isOrganizationOwner.value ? "organization_schedule" : undefined,
    isOrganizationOwner.value ? "assign_organization_schedule" : undefined,
    isOrganizationOwner.value ? "work_schedule" : undefined,
  ].filter((b) => b) as CalendarEventType[];
});

function eventTypeToStr(eventType: CalendarEventType) {
  switch (eventType) {
    case "absence":
      return "休日申請";
    case "work_schedule":
      return "割り当て可能な業務計画";
    case "owner_organization_member_schedule":
      return "自身の日程";
    case "organization_member_schedule":
      return "メンバーの日程";
    case "organization_schedule":
      return "組織業務";
    case "assign_organization_schedule":
      return "割り当てた組織業務";
    case "organization_event_invitation":
      return "ミーティング";
  }
}

const selectedEventTypes = ref<CalendarEventType[]>(
  !getStorage("PlanIndexSelectedCalendarType") ||
    getStorage("PlanIndexSelectedCalendarType") === "calendar"
    ? ["owner_organization_member_schedule", "organization_event_invitation"]
    : calendarEventTypes
);

function checkShowType(eventType: CalendarEventType) {
  return selectedEventTypes.value?.includes(eventType);
}

const { createCalendarEvents } = useCalendar();

const events = computed<CalendarEventArgs[]>(() => {
  const visibleAbsenceApplicationPlans = checkShowType("absence")
    ? absenceApplicationPlansEmbeded.value || []
    : [];
  const visibleWorkScheduleAsPlans = checkShowType("work_schedule")
    ? workScheduleAsPlans.value || []
    : [];

  const showOwn = checkShowType("owner_organization_member_schedule");
  const showOthers = checkShowType("organization_member_schedule");

  const visibleOrganizationMemberSchedulePlans = !planMaps.value
    ?.organizationMemberSchedulePlans
    ? []
    : !showOwn && !showOthers
    ? []
    : organizationMemberSchedulePlansEmbeded.value.filter((omsp) => {
        return (
          (showOwn &&
            omsp.organizationMemberId ===
              selectedOrganization.value?.organizationMemberId) ||
          (showOthers &&
            omsp.organizationMemberId !==
              selectedOrganization.value?.organizationMemberId)
        );
      });

  checkShowType("organization_member_schedule")
    ? organizationMemberSchedulePlansEmbeded.value || []
    : [];
  const visibleOrganizationSchedulePlans = checkShowType(
    "organization_schedule"
  )
    ? organizationSchedulePlansEmbeded.value
    : [];
  const visibleAssignOrganizationSchedulePlans = checkShowType(
    "assign_organization_schedule"
  )
    ? assignOrganizationSchedulePlansEmbeded.value || []
    : [];
  const visibleInvitations = checkShowType("organization_event_invitation")
    ? organizationEventInvitations.value || []
    : [];

  return createCalendarEvents(
    {
      ...planMaps.value,
      absenceApplicationPlans: visibleAbsenceApplicationPlans,
      workScheduleAsPlans: visibleWorkScheduleAsPlans,
      organizationMemberSchedulePlans: visibleOrganizationMemberSchedulePlans,
      organizationSchedulePlans: visibleOrganizationSchedulePlans,
      assignOrganizationSchedulePlans: visibleAssignOrganizationSchedulePlans,
      organizationEventInvitations: visibleInvitations,
    },
    isOwner.value,
    {
      hideNotEnough:
        !fullAccessWorkScheduleCalendar.value && !isOrganizationOwner.value, // TODO: 案件割り当て権限の追加が必要
    }
  );
});

// exists dates

const existsDates = computed<string[]>(() => {
  //return getExistsDateFromPlanMap(planMaps.value);
  const startOn = "2023-06-01"; // Log 始動月
  const endOn = today.plus({ year: 1 }).toISO(); // 1年は見れるように

  if (!endOn) {
    throw new Error("cannot get today");
  }

  const range = createIsoRange(startOn, endOn, "month");

  return range;
});

// filter

const selectedCarriers = ref<Carrier[]>(
  getStorage("PlansIndexSelectedCarriers") || [
    "docomo",
    "softbank",
    "au",
    "yahoo",
    "uq",
  ]
);
watch(selectedCarriers, (newValue) => {
  setStorage("PlansIndexSelectedCarriers", newValue);
});
const selectedCategories = ref<WorkCategory[]>(
  getStorage("PlansIndexSelectedCategories") || [
    "event_helper",
    "rounder",
    "shop_helper",
    "light_ad",
    "consulting",
    "fulltime",
    "sv",
    "sajit",
  ]
);
watch(selectedCategories, (newValue) => {
  setStorage("PlansIndexSelectedCategories", newValue);
});

const filteredWorksAsPlan = computed(() => {
  return workAsPlansEmbeded.value.filter(
    (w) =>
      selectedCarriers.value.includes(w.carrier) &&
      selectedCategories.value.includes(w.workCategory)
  );
});

// organization event invitation modal

const showOrganizationEventInvitationModal = ref();

function openOrganizationEventInvitationModal(event: CalendarEventArgs) {
  showOrganizationEventInvitationModal.value.openModal(
    event.extendedProps as OrganizationEventInvitationClient
  );
}

/*
function closeOrganizationEventInvitationModal() {
  showOrganizationEventInvitationModal.value.closeModal();
}
*/

// show Plan modal

const showPlanModal = ref();

function openShowPlanModal(event: CalendarEventArgs) {
  if (!event.extendedProps) {
    throw new Error(`unexpected event in openShowPlanModal: ${event}`);
  }

  if (!isFRYTHOwner.value && selectedCalendarType.value === "organization")
    return;

  if (event.extendedProps.eventType === "WorkScheduleAsPlan") {
    // @ts-ignore
    openWorkScheduleAssignmentModal(event);
  } else if (event.extendedProps.eventType === "OrganizationEventInvitation") {
    openOrganizationEventInvitationModal(event);
  } else {
    showPlanModal.value.openModal(event);
  }
}

function closeShowPlanModal({
  forCloseEvent,
}: { forCloseEvent?: boolean } = {}) {
  // modal の closeEvent で呼ばれる場合はすでに modal がクローズしているので、selectedEvent の初期化のみおこなう

  if (!forCloseEvent) {
    showPlanModal.value.closeModal();
  }
}

// selectNextActionModal

const selectNextActionModal = ref();

function selectEvent({ eventType, id }: { eventType: EventType; id: number }) {
  const targetEvent = events.value.find(
    (event) =>
      event.extendedProps.eventType == eventType && event.extendedProps.id == id
  );

  if (!targetEvent) {
    throw new Error(`cannot find target event: ${eventType} ${id}`);
  }

  if (!isFRYTHOwner.value && selectedCalendarType.value === "organization")
    return;

  openShowPlanModal(targetEvent);
}

function selectWorkSchedule({ id }: { id: number }) {
  selectEvent({ eventType: "WorkScheduleAsPlan", id });
}

function selectMember(member: AssignedMember) {
  closeSelectMemberScheduleModal();
  selectEvent({
    eventType: "OrganizationMemberSchedulePlan",
    id: member.schedulePlanId,
  });
}

function selectOrganization(organization: AssignedOrganization) {
  closeSelectMemberScheduleModal();
  selectEvent({
    eventType: "OrganizationSchedulePlan",
    id: organization.schedulePlanId,
  });
}

function selectNewAssignment(scheduleId: number) {
  closeSelectMemberScheduleModal();
  selectWorkSchedule({ id: scheduleId });
}

function openSelectMemberScheduleModal(
  organizationMembers: AssignedMember[],
  organizations: AssignedOrganization[],
  schedule: WorkScheduleAsPlan
) {
  if (!isFRYTHOwner.value && selectedCalendarType.value === "organization")
    return;

  selectNextActionModal.value.openModal({
    members: organizationMembers,
    organizations: organizations,
    schedule: schedule,
  });
}

function closeSelectMemberScheduleModal(option = { forCloseEvent: false }) {
  const { forCloseEvent } = option;

  if (!forCloseEvent) {
    selectNextActionModal.value.closeModal();
  }
}

function selectScheduleAsPlan(targetSchedule: WorkScheduleAsPlan) {
  // for organization row calendar
  const assignedMembers = targetSchedule.assignedMembers;
  const assignedOrganizations = targetSchedule.assignedOrganizations;

  if (
    assignedOrganizations.length ||
    assignedMembers.length >= 2 ||
    (assignedMembers.length === 1 &&
      targetSchedule.needCount &&
      targetSchedule.needCount > 1)
  ) {
    openSelectMemberScheduleModal(
      assignedMembers,
      assignedOrganizations,
      targetSchedule
    );
  } /* assignedMembers.length === 1 */ else {
    const targetMember = assignedMembers.at(0);

    if (!targetMember) {
      throw new Error(
        `target Member dont have schedule plan id: ${targetMember}`
      );
    }
    selectEvent({
      eventType: "OrganizationMemberSchedulePlan",
      id: targetMember.schedulePlanId,
    });
  }
}

function selectMemberScheduleAsPlan(
  targetSchedule: OrganizationMemberSchedulePlan
) {
  selectEvent({
    eventType: "OrganizationMemberSchedulePlan",
    id: targetSchedule.id,
  });
}

// goto WorksEdit

const { goto } = useRouterUtil();

function handleGotoWorksEdit(plan: WorkAsPlan) {
  closeShowPlanModal();
  goto({ name: "WorksEdit", query: { id: plan.id } });
}

// application Model

const applicationModal = ref();

function openApplicationModal() {
  applicationModal.value.openModal();
}

function closeApplicationModal() {
  applicationModal.value.closeModal();
}

const {
  createAbsenceApplicationPlan,
  acceptAbsenceApplicationPlan,
  deleteAbsenceApplicationPlan,
} = useAbsenceApplicationPlan();

async function submitApplication(
  application: SubmitApplicationOwnerForm | SubmitApplicationOwneeForm
) {
  if (await createAbsenceApplicationPlan(application)) {
    alert("作成に成功");
    if (isOwner.value) {
      getAbsenceApplicationPlansMutate();
    } else {
      getPlanMapMutate();
    }
  } else {
    alert("作成に失敗");
  }
  closeApplicationModal();
}

async function handleAbsenceAccepet(plan: AbsenceApplicationPlan) {
  if (!window.confirm("申請を承認します。")) {
    return;
  }

  loading.value = true;

  if (await acceptAbsenceApplicationPlan(plan)) {
    if (isOwner.value) {
      getAbsenceApplicationPlansMutate();
    } else {
      getPlanMapMutate();
    }
    closeShowPlanModal();
  } else {
    alert("承認に失敗");
  }

  loading.value = false;
}

async function handleAbsenceDestroy(plan: AbsenceApplicationPlan) {
  if (!window.confirm("申請を削除します。")) {
    return;
  }

  loading.value = true;

  if (await deleteAbsenceApplicationPlan(plan)) {
    if (isOwner.value) {
      getAbsenceApplicationPlansMutate();
    } else {
      getPlanMapMutate();
    }
    closeShowPlanModal();
  } else {
    alert("削除失敗");
  }

  loading.value = false;
}

// work schedule modal

const workScheduleAssigmentModal = ref();

type EventProps = {
  extendedProps: {
    targetDate: string;
    [key: string]: any;
  };
  [key: string]: any;
};

function openWorkScheduleAssignmentModal(event: EventProps) {
  workScheduleAssigmentModal.value.openModal(event);
}

function closeWorkScheduleAssignmentModal(forCloseEvent = false) {
  if (!forCloseEvent) {
    workScheduleAssigmentModal.value.closeModal();
  }
  return;
}

const { destroyOrganizationMemberSchedule } = useOrganizationMemberSchedule();

async function handleOrganizationMemberDestroy(
  schedule: OrganizationMemberSchedule
) {
  if (!selectedOrganization.value || !planMaps.value) return;

  if (!window.confirm("計画への割り当てを解除します。")) {
    return;
  }

  loading.value = true;

  if (
    await destroyOrganizationMemberSchedule(
      schedule,
      selectedOrganization.value
    )
  ) {
    organizationMemberSchedulePlansTempo.value =
      organizationMemberSchedulePlansEmbeded.value.filter(
        (oms) => oms.id !== schedule.id
      );

    if (isOwner.value) {
      getOrganizationMemberSchedulesMutate();
      getWorkScheduleAsPlansMutate();
    } else {
      getPlanMapMutate();
    }
    closeShowPlanModal();
  }

  loading.value = false;
}

const {
  destroyWorkSchedule,
  cancelWorkSchedule,
  restoreWorkSchedule,
  updateWorkSchedule,
} = useWorkSchedules();

async function handleDestroyWorkScheduleInAssignModal(id: number) {
  if (!window.confirm("案件にある計画を削除します。よろしいですか？")) {
    return;
  }

  loading.value = true;

  if (await destroyWorkSchedule(id)) {
    closeWorkScheduleAssignmentModal();
    if (isOwner.value) {
      getOrganizationMemberSchedulesMutate();
      getOrganizationSchedulesMutate();
    } else {
      getPlanMapMutate();
    }
  } else {
    alert("削除失敗");
  }

  loading.value = false;
}

async function handleDestroyWorkSchedule(schedule: {
  workSchedules: { id: number }[];
}) {
  if (!window.confirm("案件にある計画を削除します。よろしいですか？")) {
    return;
  }

  loading.value = true;

  if (schedule.workSchedules.length > 1) {
    alert("スケジュールが二つ以上あるため削除できません。");
    loading.value = false;
    return;
  }

  const id = schedule.workSchedules.at(0)?.id;

  if (!id) {
    return;
  }

  if (await destroyWorkSchedule(id)) {
    if (isOwner.value) {
      getOrganizationMemberSchedulesMutate();
      getOrganizationSchedulesMutate();
    } else {
      getPlanMapMutate();
    }
    closeShowPlanModal();
  }

  loading.value = false;
}

async function handleCancelWorkSchedule(scheduleId: number) {
  if (
    !window.confirm(
      "計画をキャンセルします。すでにメンバーに設定されている場合はそれもキャンセルされます。よろしいですか？"
    )
  ) {
    return;
  }

  loading.value = true;

  if (!scheduleId) {
    return;
  }

  if (await cancelWorkSchedule(scheduleId)) {
    if (isOwner.value) {
      getOrganizationMemberSchedulesMutate();
      getOrganizationSchedulesMutate();
      getWorkScheduleAsPlansMutate();
    } else {
      getPlanMapMutate();
    }
    closeShowPlanModal();
    closeWorkScheduleAssignmentModal();
  }

  loading.value = false;
}

async function handleVacancyWorkSchedule(
  workScheduleId: number,
  vacancyCount: number
) {
  loading.value = true;

  if (await updateWorkSchedule(workScheduleId, { vacancyCount })) {
    if (isOwner.value) {
      getOrganizationMemberSchedulesMutate();
      getWorkScheduleAsPlansMutate();
    } else {
      getPlanMapMutate();
    }
    closeShowPlanModal();
    closeWorkScheduleAssignmentModal();
  }

  loading.value = false;
}

async function handleVacancyWorkScheduleAssignment(
  workScheduleAssignmentId: number,
  vacancy: boolean
) {
  loading.value = true;

  if (
    await updateWorkScheduleAssignment(workScheduleAssignmentId, { vacancy })
  ) {
    if (isOwner.value) {
      getOrganizationMemberSchedulesMutate();
      getWorkScheduleAsPlansMutate();
    } else {
      getPlanMapMutate();
    }
    closeShowPlanModal();
    closeWorkScheduleAssignmentModal();
  }

  loading.value = false;
}

async function handleCancelWorkScheduleInMemberSchedule(
  schedule: OrganizationMemberSchedulePlan
) {
  if (schedule.workSchedules.length > 1) {
    alert("スケジュールが二つ以上あるためキャンセルできません。");
    return;
  }

  const targetWorkSchedule = schedule.workSchedules.at(0);

  if (!targetWorkSchedule) {
    alert("予期せぬエラーが発生しました。");
    return;
  }

  handleCancelWorkSchedule(targetWorkSchedule.id);
}

async function handleRestoreWorkSchedule(scheduleId: number) {
  if (
    !window.confirm(
      "計画を有効化します。これにより計画へのメンバーの割り当てが可能になります。"
    )
  ) {
    return;
  }

  loading.value = true;

  if (!scheduleId) {
    return;
  }

  if (await restoreWorkSchedule(scheduleId)) {
    if (isOwner.value) {
      getOrganizationMemberSchedulesMutate();
      getOrganizationSchedulesMutate();
      getWorkScheduleAsPlansMutate();
    } else {
      getPlanMapMutate();
    }
    closeShowPlanModal();
  }

  loading.value = false;
}

async function handleRestoreWorkScheduleInMemberSchedule(
  schedule: OrganizationMemberSchedulePlan
) {
  if (schedule.workSchedules.length > 1) {
    alert("スケジュールが二つ以上あるため中止をキャンセルできません。");
    return;
  }

  const targetWorkSchedule = schedule.workSchedules.at(0);

  if (!targetWorkSchedule) {
    alert("予期せぬエラーが発生しました。");
    return;
  }

  handleRestoreWorkSchedule(targetWorkSchedule.id);
}

const {
  createWorkScheduleAssignment,
  destroyWorkScheduleAssignment,
  updateWorkScheduleAssignment,
} = useWorkScheduleAssignments();

async function submitWorkScheduleAssignment(
  workScheduleAssignment: WorkScheduleAssignment
) {
  if (!selectedOrganizationId.value) return;

  loading.value = true;
  let prms = workScheduleAssignment;

  const res = await createWorkScheduleAssignment(
    workScheduleAssignment,
    selectedOrganizationId.value
  );

  if (res && Array.isArray(res.workSchedules)) {
    //getPlanMapMutate();
    /*
    updatePlanMap("workScheduleAsPlans", [
      ...planMaps.value.workScheduleAsPlans.filter(
        (ws) => !res.workSchedules.some((nws) => nws.id === ws.id)
      ),
      ...res.workSchedules,
    ]);

    if (res.organizationMemberSchedulePlan) {
      updatePlanMap("organizationMemberSchedulePlans", [
        ...planMaps.value.organizationMemberSchedulePlans,
        res.organizationMemberSchedulePlan,
      ]);
    }

    if (res.organizationSchedulePlan) {
      planMaps.value.organizationSchedulePlans = [
        ...planMaps.value.organizationSchedulePlans,
        res.organizationSchedulePlan,
      ];
    }
    */

    /*
    if (
      isFRYTHOwner.value &&
      prms.organizationMemberId &&
      res.organizationMemberSchedulePlan?.memberOrganizationId !=
        selectedOrganizationId.value
    ) {
      if (window.confirm("作成に成功しました。組織の割り当ても行いますか？")) {
        prms.organizationId =
          res.organizationMemberSchedulePlan?.memberOrganizationId;
        delete prms.organizationMemberId;
        await createWorkScheduleAssignment(prms, selectedOrganizationId.value);
      }
    }
    */

    if (isOwner.value) {
      getWorkScheduleAsPlansMutate();
      getOrganizationMemberSchedulesMutate();
      getOrganizationSchedulesMutate();
    } else {
      getPlanMapMutate();
    }
  } else {
    alert("作成に失敗");
  }

  loading.value = false;
  closeWorkScheduleAssignmentModal();
}

async function handleDestroyWorkScheduleAssignment(id: number) {
  if (!window.confirm(`計画の割り当てを解除します。`)) {
    return;
  }

  loading.value = true;

  if (await destroyWorkScheduleAssignment(id)) {
    if (isOwner.value) {
      getOrganizationMemberSchedulesMutate();
      getOrganizationSchedulesMutate();
      getAssignOrganizationSchedulesMutate();
    } else {
      getPlanMapMutate();
    }

    closeShowPlanModal();
  } else {
    alert("削除失敗");
  }

  loading.value = false;
}

const { updateOrganizationSchedule, destroyOrganizationSchedule } =
  useOrganizationSchedule();

async function handleDestroyOrganizationSchedule(organizationSchedule: {
  id?: number;
}) {
  if (!window.confirm(`組織への割り当てをキャンセルします。`)) {
    return;
  }

  const { id } = organizationSchedule;

  if (!id) return;
  loading.value = true;

  if (await destroyOrganizationSchedule(id)) {
    if (isOwner.value) {
      getOrganizationSchedulesMutate();
    } else {
      getPlanMapMutate();
    }

    closeShowPlanModal();
  } else {
    alert("削除失敗");
  }

  loading.value = false;
}

async function handleUpdateOrganizationSchedule(form: {
  id: number;
  workScheduleId: number;
  needCount: number;
}) {
  if (await updateOrganizationSchedule(form)) {
    alert("更新に成功");
    getOrganizationSchedulesMutate();
    getAssignOrganizationSchedulesMutate();
  } else {
    alert("更新に失敗");
  }
}

// pdf

function generatePlanPDF() {
  let filename = "計画";

  generatePDF("pdf-contents", filename, {
    unit: "in",
    format: "a4",
    orientation: "l",
    precision: 0,
  });
}
</script>

<style lang="scss" scoped>
.calendar-wrapper {
  overflow: scroll;
  width: 100%;
  height: 70vh;
  margin-bottom: 1rem;
}
</style>
