<template>
  <div>
    <ContentLabel label="今日の予定" />
    <template v-if="todayBodyTemperature && todayBodyTemperature.noWork">
      <span class="text-danger"> 欠勤申請済 </span>
    </template>
    <template v-else-if="todaySchedulePlans && todaySchedulePlans.length >= 1">
      <div v-for="sp in todaySchedulePlans" :key="sp.id" class="w-100">
        <div
          v-for="s in sp.workSchedules"
          :key="s.id"
          class="d-flex align-items-center schedules-container"
        >
          <div v-if="s.done" class="done-icon me-2">
            <CheckBlueIcon class="icon" />
          </div>

          <div class="d-flex flex-column w-100">
            <div>
              業　　務：{{ s.canceled ? "（中止）" : ""
              }}{{
                `${translater(s.workCarrier)}${translater(s.workCategory)}（${
                  s.workTypeName
                }）`
              }}
            </div>
            <div
              class="d-flex text-wrap align-items-center justify-content-start"
            >
              <span class="text-nowrap me-2">
                業務場所：{{
                  `${basicFormatter(s.startAt, "onlyTime")}~${basicFormatter(
                    s.finishAt,
                    "onlyTime"
                  )}`
                }}
              </span>
              <span class="text-nowrap">
                {{ s.eventPlaceName ? s.eventPlaceName : s.workPlaceName }}
              </span>
              <div>
                <BasicButton
                  v-if="s.eventPlaceGmapUrl"
                  slim
                  rounded
                  text
                  button-type="submit"
                  icon="pi pi-external-link"
                  @click="openGmap(s.eventPlaceGmapUrl)"
                />
              </div>
            </div>
            <div v-if="s.meetingAt">
              集合場所：{{
                `${basicFormatter(s.meetingAt, "onlyTime")}${
                  s.firstMeetingAt
                    ? `(初回${basicFormatter(s.firstMeetingAt, "onlyTime")})`
                    : ""
                } ${s.meetingPlace || ""}`
              }}
            </div>
            <div class="d-flex flex-wrap mb-3">
              <div
                v-for="attachedImage in s.eventPlaceAttachedImages"
                :key="attachedImage.id"
                class="me-2"
              >
                <ContentLabel :label="attachedImage.name" />
                <ShowPhoto
                  v-if="attachedImage.imageUrl"
                  :photo-url="attachedImage.imageUrl"
                  :alt="attachedImage.name"
                />
              </div>
            </div>
            <div v-if="s.needMeetingTargets && s.meetingTargets">
              <MeetingTargetViewer
                v-if="false"
                :meeting-targets="s.meetingTargets"
                :meeting-target-filter="s.meetingTargetFilter"
                :current-organization-member-id="organizationMemberId"
                :basis-report-items="basisReportItems"
                :organization="organization"
                @destroy-meeting-targets="
                  $emit('destroyMeetingTargets', $event)
                "
                @update-meeting-targets="$emit('updateMeetingTargets', $event)"
                @update-work-report="$emit('updateWorkReport', $event)"
                @select-work-report="$emit('selectWorkReport', $event)"
              />
              <MeetingTargetWorkPlaceTable
                v-if="!loading"
                :work-place="{
                  id: s.meetingTargets.workPlaceId,
                  name: s.meetingTargets.workPlaceName,
                }"
                :work-carrier="s.workCarrier"
                :basis-report-items="basisReportItems"
                :added-form-items="addedFormItems"
                :meeting-targets="[s.meetingTargets]"
                :organization="organization"
                :selected-date="todayMonthMap"
                :is-owner="false"
                :is-show-divider="false"
                is-home
                @update-meeting-target-work-place="
                  updateMeetingTargetWorkPlace(s, $event)
                "
              />
              <i v-else class="pi pi-spin pi-spinner"></i>
            </div>
          </div>
        </div>
      </div>
    </template>
    <span v-else>予定はありません</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DateTime } from "luxon";
import { luxonNow, basicFormatter, luxonDateToDateMap } from "/@/modules/luxon";
import {
  CheckBlueIcon,
  ContentLabel,
  BasicButton,
} from "/@/vue/components/Atom";
import { ShowPhoto } from "/@/vue/components/Molecules";
import type {
  Organization,
  OrganizationMemberSchedulePlan,
  bodyTemperatureClient,
  WorkRecordEntryReportForm,
  WorkReportClient,
  WorkScheduleAsPlan,
  BasisReportItemClient,
  MeetingTargetWorkPlace,
  MeetingTargetAddedFormItem,
} from "/@/types";
import {
  MeetingTargetViewer,
  MeetingTargetWorkPlaceTable,
} from "/@/vue/components/Organisms";
import { translater } from "/@/modules/string";
import { openGmapInNewTab } from "/@/modules/address";

const props = withDefaults(
  defineProps<{
    todaySchedulePlans: OrganizationMemberSchedulePlan[];
    todayBodyTemperature: bodyTemperatureClient;
    today?: DateTime;
    organizationMemberId?: number;
    basisReportItems: BasisReportItemClient[];
    addedFormItems: MeetingTargetAddedFormItem[];
    organization: Organization;
    canShowMeetingTargets?: boolean;
    loading?: boolean;
  }>(),
  {
    today: () => luxonNow(),
    organizationMemberId: undefined,
  }
);

const emit = defineEmits<{
  (e: "destroyMeetingTargets", id: number): void;
  (e: "updateMeetingTargets", id: number): void;
  (e: "updateWorkReport", report: WorkRecordEntryReportForm): void;
  (e: "selectWorkReport", report: WorkReportClient): void;
  (e: "updateMeetingTargetWorkPlace", prms: MeetingTargetWorkPlace): void;
}>();

function openGmap(url: string) {
  openGmapInNewTab(url);
}

const todayMonthMap = computed(() => {
  return luxonDateToDateMap(props.today);
});

function updateMeetingTargetWorkPlace(
  schedule: WorkScheduleAsPlan,
  prms: MeetingTargetWorkPlace
) {
  schedule.meetingTargets = prms;

  emit("updateMeetingTargetWorkPlace", prms);
}
</script>

<style scoped lang="scss">
.schedules-container {
  position: relative;
  .done-icon {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
