<template>
  <div>
    <PageHeader page-title="入店" />
    <EnterForm
      :work-schedules="myTodaySchedules"
      :need-enter-leave-report="actionStatus?.needEnterLeaveReport"
      @cancel="backto"
      @submit="handleSubmit"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from "vue";
import {
  useOrganizationMember,
  useRouterUtil,
  useWorkRecords,
  useOrganizationMemberSchedule,
  usePlans,
} from "/@/vue/composables";
import { PageHeader } from "/@/vue/components/Layouts";
import { EnterForm } from "/@/vue/components/Organisms";
import { WorkRecordEntryEntranceForm } from "/@/types";

const { query, backto, goto } = useRouterUtil();

const selectedOrganization = ref();

onMounted(() => {
  selectedOrganization.value = {
    id: query.value.organizationId,
    organizationMemberId: query.value.organizationMemberId,
  };
});

const { getActionStatus } = useOrganizationMember();
const { data: actionStatus } = getActionStatus(selectedOrganization);

const isOwner = ref<boolean>(false);

const { getPlanMaps } = usePlans();
const { data: planMap } = getPlanMaps(selectedOrganization, isOwner);

const { getCurrentWorkRecord } = useWorkRecords();
const { data: currentWorkRecord } = getCurrentWorkRecord(selectedOrganization);

const currentEntry = computed(() => {
  return currentWorkRecord.value?.workRecordEntries.find(
    (entry) => !entry.finishAt
  );
});

// schedules

const { getMyTodaySchedules } = useOrganizationMemberSchedule();

const schedules = computed(() => {
  if (!planMap.value) {
    return [];
  }

  return planMap.value.organizationMemberSchedulePlans;
});

const myTodaySchedules = computed(() => {
  return getMyTodaySchedules(
    schedules.value,
    selectedOrganization.value?.organizationMemberId
  )
    .map((s) => s.workSchedules)
    .flat()
    .filter((s) => !s.done);
});

const loading = ref<boolean>();

const { enterEntry } = useWorkRecords();

async function handleSubmit(form: WorkRecordEntryEntranceForm) {
  loading.value = true;

  if (!currentWorkRecord.value) {
    alert("業務中ではありません");
    backto();
    return;
  }

  if (await enterEntry(currentWorkRecord.value.id, form)) {
    alert("入店しました。");
    goto({ name: "Home" });
  } else {
    alert("入店に失敗しました");
  }
  loading.value = false;
}
</script>

<style scoped></style>
