<template>
  <div>
    <div v-if="invitations.length" class="card card-body mb-3">
      <template v-if="pendingInvitations.length">
        <ContentLabel label="招待されているミーティングがあります" />
        <Accordion class="mb-3">
          <AccordionTab
            v-for="invitation in pendingInvitations"
            :key="invitation.id"
          >
            <template #header>
              <div class="d-flex flex-wrap">
                <span class="me-1">
                  {{
                    invitation.organizationEvent.startAt &&
                    basicFormatter(
                      invitation.organizationEvent.startAt,
                      "slashStyle"
                    )
                  }}
                </span>
                <span class="me-2">{{
                  invitation.organizationEvent.name
                }}</span>
                <Chip class="me-2">
                  {{ translater(invitation.organizationEvent.eventType) }}
                </Chip>
                <Chip
                  :class="eventColorClass(invitation.eventApplicationStatus)"
                >
                  {{ translater(invitation.eventApplicationStatus) }}
                </Chip>
              </div>
            </template>
            <OrganizationEventInvitationItem
              :invitation="invitation"
              @approve="$emit('approve', $event)"
              @reject="$emit('reject', $event)"
            />
          </AccordionTab>
        </Accordion>
      </template>
      <template v-if="approvedInvitations.length">
        <ContentLabel label="参加予定のミーティング" />
        <Accordion>
          <AccordionTab
            v-for="invitation in approvedInvitations"
            :key="invitation.id"
          >
            <template #header>
              <div class="d-flex flex-wrap">
                <span class="me-1">
                  {{
                    invitation.organizationEvent.startAt &&
                    basicFormatter(
                      invitation.organizationEvent.startAt,
                      "slashStyle"
                    )
                  }}
                </span>
                <span class="me-2">{{
                  invitation.organizationEvent.name
                }}</span>
                <Chip class="me-2">
                  {{ translater(invitation.organizationEvent.eventType) }}
                </Chip>
              </div>
            </template>
            <OrganizationEventInvitationItem :invitation="invitation" />
          </AccordionTab>
        </Accordion>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { translater } from "/@/modules/string";
import { eventColorClass } from "/@/modules/organizationEvent";
import { ContentLabel } from "/@/vue/components/Atom";
import { OrganizationEventInvitationItem } from "/@/vue/components/Organisms";
import { OrganizationEventInvitationClient } from "/@/types";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Chip from "primevue/chip";
import { basicFormatter } from "/@/modules/luxon";

const props = defineProps<{
  invitations: OrganizationEventInvitationClient[];
}>();

defineEmits<{
  (e: "approve", id: number): void;
  (e: "reject", id: number): void;
}>();

const pendingInvitations = computed(() => {
  return props.invitations.filter(
    (i) => i.eventApplicationStatus === "pending"
  );
});

const approvedInvitations = computed(() => {
  return props.invitations.filter(
    (i) => i.eventApplicationStatus === "approved"
  );
});
</script>

<style scoped></style>
