<template>
  <div>
    <PageHeader page-title="タスク管理" />
    <OrganizationSelector
      v-if="organizations"
      v-model:selected-organization-id="selectedOrganizationId"
      class="mb-3"
      :organizations="organizations"
    />

    <div
      class="d-flex align-items-center justify-content-between w-100 flex-wrap"
    >
      <GroupToggler
        v-model:selected="taskMode"
        :toggle-types="taskModeToggleTypes"
        class="mb-3"
      />

      <div class="d-flex align-items-start flex-wrap">
        <div class="d-flex align-items-center flex-wrap">
          <CheckForm
            v-model:value="hideDoneTask"
            label="完了タスクを非表示"
            form-id="show-done-task"
            class="me-2 mb-3"
            is-valid
          />
          <div class="d-flex align-items-center mb-3">
            <span class="me-2">検索</span>
            <SearchForm
              v-model:value="searchWord"
              :need-count="2"
              form-id="search-form"
              class="me-2"
            />
          </div>
        </div>

        <SelectDate
          v-model:year="selectedDate.year"
          v-model:month="selectedDate.month"
          v-model:day="selectedDate.day"
          :exists-dates="existsDates"
        />
      </div>
    </div>

    <div class="d-flex w-100 flex-wrap">
      <div
        class="project-container mb-3"
        :class="{
          'w-100': isMobile,
        }"
      >
        <div v-if="!isMobile" class="project-header-spacer"></div>
        <ProjectList
          v-if="selectedProjectId"
          :projects="projects || []"
          :selected-project-id="selectedProjectId"
          @select-project="selectedProjectId = $event"
          @delete-project="handleDestroyProject"
          @create-project="handleCreateProject"
        />
      </div>

      <div
        v-if="selectedProject"
        class="d-flex flex-column tasks-container"
        :class="{
          'w-100': isMobile,
        }"
      >
        <template v-if="taskMode !== 'sort'">
          <div
            class="d-flex justify-content-between align-items-center w-100 flex-wrap"
            :class="{
              'flex-row-reverse': isMobile,
            }"
          >
            <BasicButton
              class="w-25 mb-2"
              :class="{
                'w-25': !isMobile,
                'w-100': isMobile,
              }"
              variant="primary"
              slim
              @click="handleMoveIncompleteTaskItems"
            >
              未完了タスクを今日へ移動
            </BasicButton>
            <ProgressBar
              :all-count="allTaskCount"
              :done-count="doneTaskCount"
              class="mb-2"
              :class="{
                'w-25': !isMobile,
                'w-100': isMobile,
              }"
            />
          </div>

          <div class="table-wrapper mb-3">
            <TaskTable
              :task-items="filteredTaskItems"
              :edit-mode="taskMode === 'edit'"
              :date-map="selectedDateForGantt"
              @update-task-item="handleUpdate"
              @add-sibling="handleAddSibling"
              @add-child="handleAddChild"
              @delete-task-item="handleDestroy"
              @prev-month="prevMonthForGantt"
              @next-month="nextMonthForGantt"
            />
          </div>
        </template>
        <NestedTaskTable
          v-else
          :task-items="filteredTaskItems"
          @on-end="handleUpdateHierarchy"
        />
      </div>
    </div>
  </div>

  <teleport to="body">
    <ConfirmModal ref="confirmModal" title="日程の調整" />
    <AutoChangePlanModal
      ref="autoChangePlanModal"
      :task-item="selectedTaskItem"
      @shift-plan="handleShiftPlan"
    />
  </teleport>
</template>

<script setup lang="ts">
import { computed, reactive, ref, watch } from "vue";
import { getStorage, setStorage } from "/@/modules/localStorage";
import { taskItemsFilter } from "/@/modules/taskManager";
import {
  countDaysBetween,
  createRange,
  fromISO,
  luxonNow,
  nextMonth,
  prevMonth,
} from "/@/modules/luxon";
import {
  useOrganization,
  useTaskManager,
  useMqUtils,
} from "/@/vue/composables";
import { PageHeader } from "/@/vue/components/Layouts";
import {
  BasicButton,
  SearchForm,
  SelectDate,
  CheckForm,
} from "/@/vue/components/Atom";
import {
  GroupToggler,
  ConfirmModal,
  ProgressBar,
} from "/@/vue/components/Molecules";
import { OrganizationSelector } from "/@/vue/components/Organisms";
import {
  NestedTaskTable,
  TaskTable,
  AutoChangePlanModal,
  ProjectList,
} from "/@/vue/components/Organisms/TaskManager";
import type { Organization } from "/@/types/organization";
import type {
  DateMapForm,
  DateMap,
  TaskItemUpdateParams,
  TaskItemClient,
} from "/@/types";

// ui

const { isMobile } = useMqUtils();

//

const { getOrganizations } = useOrganization();
const { data: organizations } = getOrganizations();

const selectedOrganizationId = ref<number | undefined>(
  getStorage("selectedOrganizationId")
);
watch(selectedOrganizationId, (id) => setStorage("selectedOrganizationId", id));
const selectedOrganization = computed<Organization | undefined>(() => {
  if (!organizations.value) {
    return;
  }

  return organizations.value?.find((o) => o.id == selectedOrganizationId.value);
});

const {
  getProjectOfTasks,
  updateTaskItemHierarchy,
  updateTaskItem,
  addSibling,
  addChild,
  destroyTaskItem,
  moveIncompleteTaskItems,
  autoShiftTaskItem,
  createProject,
  destroyProject,
} = useTaskManager();

const { data: projects, mutate } = getProjectOfTasks(selectedOrganization);

const selectedProjectId = ref<number | undefined>();

watch(projects, (ps) => {
  if (!selectedProjectId.value && !!ps && !!ps.length) {
    const first = ps.at(0);

    if (!first) {
      throw new Error("unexpected pattern: watch projects");
    }

    selectedProjectId.value = first.id;
  }
});

const selectedProject = computed(() => {
  if (!projects.value || !selectedProjectId.value) {
    return undefined;
  }

  return projects.value.find(
    (project) => project.id == selectedProjectId.value
  );
});

// mode

type TaskMode = "edit" | "list" | "sort";
const taskMode = ref<TaskMode>("list");

const taskModeToggleTypes = [
  {
    name: "一覧",
    key: "list",
  },
  {
    name: "編集",
    key: "edit",
  },
  {
    name: "順序",
    key: "sort",
  },
];

// target task items

const searchWord = ref<string | undefined>(undefined);
const hideDoneTask = ref<boolean>(true);

const selectedDate = reactive<DateMapForm>({
  year: undefined,
  month: undefined,
  day: undefined,
});

const existsDates = computed<string[]>(() => {
  if (taskMode.value === "sort" || !selectedProject.value) {
    return [];
  }

  return selectedProject.value.flatTaskItems
    .filter((task) => task.startOn && task.finishOn)
    .map(
      // @ts-ignore 上のフィルターで task.startOn と task.finishOn が undefined である可能性は無い
      (task) => createRange(task.startOn, task.finishOn, "day")
    )
    .flat()
    .map((luxonObj) => luxonObj.toISO());
});

const targetTaskItems = computed(() => {
  if (!selectedProject.value) {
    return [];
  }

  const targetTaskItems =
    taskMode.value === "sort"
      ? selectedProject.value.hierarchalTaskItems
      : selectedProject.value.flatTaskItems;
  return targetTaskItems;
});

const filteredTaskItems = computed(() => {
  return taskItemsFilter(targetTaskItems.value, {
    searchWord: searchWord.value,
    needCount: 2,
    selectedDate: selectedDate,
    hideDoneTask: hideDoneTask.value,
  });
});

// for gantt

const today = luxonNow();

const selectedDateForGantt = reactive<DateMap>({
  year: today.year,
  month: today.month,
  day: undefined,
});

function prevMonthForGantt() {
  const prev = prevMonth({
    year: selectedDateForGantt.year,
    month: selectedDateForGantt.month,
  });

  selectedDateForGantt.year = prev.year;
  selectedDateForGantt.month = prev.month;
}

function nextMonthForGantt() {
  const next = nextMonth({
    year: selectedDateForGantt.year,
    month: selectedDateForGantt.month,
  });

  selectedDateForGantt.year = next.year;
  selectedDateForGantt.month = next.month;
}

// progress

const allTaskCount = computed(() => {
  if (!selectedProject.value) {
    return 0;
  }

  const taskItems = selectedProject.value.flatTaskItems;
  return taskItems.length;
});

const doneTaskCount = computed(() => {
  if (!selectedProject.value) {
    return 0;
  }

  const taskItems = selectedProject.value.flatTaskItems;
  return taskItems.filter((taskItem) => !!taskItem.doneAt).length;
});

// update planned

const confirmModal = ref();
const autoChangePlanModal = ref();

const selectedTaskItem = ref<TaskItemClient | undefined>();

async function handleShiftPlan(
  num: number,
  targetDate: string /* isostring */
) {
  if (!selectedProjectId.value) {
    return;
  }

  if (
    await autoShiftTaskItem({
      projectId: selectedProjectId.value,
      num,
      targetDate,
    })
  ) {
    mutate();
    autoChangePlanModal.value.closeModal();
  } else {
    alert("なんらかの問題が発生しました。一度ページを更新して下さい");
  }
}

async function handleUpdatePlannedOn({
  startOn,
  finishOn,
}: {
  startOn: string;
  finishOn: string;
}) {
  if (
    await confirmModal.value.confirm(
      "日程の変更が行われました。今回設定した以降のタスクの日付の変更を行いますか？"
    )
  ) {
    const num = countDaysBetween(startOn, finishOn) + 1;

    autoChangePlanModal.value.openModal({ num, target: startOn });
  } else {
    return;
  }
}

// general updater

function existsSameDayOrAfterTasks(targetDate: string) {
  if (!selectedProject.value || !selectedProject.value.lastPlannedDate) {
    return false;
  }

  return fromISO(targetDate) <= fromISO(selectedProject.value.lastPlannedDate);
}

async function handleUpdate(id: number, params: TaskItemUpdateParams) {
  if (
    typeof params.startOn === "string" &&
    typeof params.finishOn === "string" &&
    existsSameDayOrAfterTasks(params.finishOn)
  ) {
    // 日付の更新でかつ予定日の削除操作で無い場合
    handleUpdatePlannedOn({
      startOn: params.startOn,
      finishOn: params.finishOn,
    });
  }

  if (await updateTaskItem(id, params)) {
    mutate();
  } else {
    alert("なんらかの問題が発生しました。一度ページを更新して下さい");
  }
}

async function handleUpdateHierarchy(evt: any) {
  const updatedTask = evt.item.dataset;
  const newParent = evt.to.parentElement.dataset;

  const newOrder = evt.newIndex;

  if (newOrder === -1) {
    alert("-1 detected");
    return;
  }

  if (
    await updateTaskItemHierarchy({
      id: updatedTask.id,
      parentId: newParent.id,
      sortOrder: newOrder,
    })
  ) {
    mutate();
  } else {
    alert("なんらかの問題が発生しました。一度ページを更新して下さい");
  }
}

async function handleAddSibling(id: number) {
  if (
    await addSibling({
      id,
    })
  ) {
    mutate();
  } else {
    alert("なんらかの問題が発生しました。一度ページを更新して下さい");
  }
}

async function handleAddChild(id: number) {
  if (
    await addChild({
      id,
    })
  ) {
    mutate();
  } else {
    alert("なんらかの問題が発生しました。一度ページを更新して下さい");
  }
}

async function handleDestroy(id: number) {
  if (
    await destroyTaskItem({
      id,
    })
  ) {
    mutate();
  } else {
    alert("なんらかの問題が発生しました。一度ページを更新して下さい");
  }
}

async function handleMoveIncompleteTaskItems() {
  if (
    !window.confirm(
      `全ての未完了タスクの内、予定日が今日以前のものまたは未設定のものの予定日が今日になります。よろしいですか？`
    )
  ) {
    return;
  }

  if (!selectedProjectId.value) {
    throw new Error("project isnt selected");
  }

  if (await moveIncompleteTaskItems({ id: selectedProjectId.value })) {
    mutate();
  } else {
    alert("なんらかの問題が発生しました。一度ページを更新して下さい");
  }
}

// project

async function handleCreateProject() {
  if (!selectedOrganization.value?.organizationMemberId) {
    return;
  }

  const name = window.prompt(`新しいプロジェクトの名前を入力して下さい`);

  if (!name) {
    return;
  }

  if (
    await createProject({
      organizationMemberId: selectedOrganization.value.organizationMemberId,
      name,
    })
  ) {
    mutate();
  } else {
    alert("なんらかの問題が発生しました。一度ページを更新して下さい");
  }
}

async function handleDestroyProject(id: number) {
  if (
    !window.confirm(
      "プロジェクトの削除を行います。この操作は取り消せません。よろしいですか？"
    )
  ) {
    return;
  }

  if (await destroyProject(id)) {
    mutate();
  } else {
    alert("なんらかの問題が発生しました。一度ページを更新して下さい");
  }
}
</script>

<style scoped>
.table-wrapper {
  overflow: scroll;
}

.project-container {
  width: 200px;
  font-size: 0.8rem;
  padding: 0.25rem;
}

.project-header-spacer {
  height: 40px;
  margin-bottom: 0.5rem;
}
.tasks-container {
  width: calc(100% - 200px);
  padding: 0.25rem;
}
</style>
