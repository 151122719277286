<template>
  <div>
    <div class="report-item">
      <label class="mb-1 text-nowrap" :for="`report-form-${item.key}`">
        {{ item.key }}
      </label>
      <div style="width: 120px" class="d-flex align-items-center">
        <NumberForm
          :value="targetFormItem?.count || 0"
          :form-id="`report-form-${item.key}`"
          :items="countNumbers"
          class="me-2"
          @update:value="
            $emit('updateItem', {
              ...item,
              count: $event || 0,
              basisReportItemId: props.basisReportItem.id,
            })
          "
        />
        <span>{{ unit }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";
import { computed } from "vue";
import { ReportItemForm, ReportItem, BasisReportItemClient } from "/@/types";
import { NumberForm } from "/@/vue/components/Atom";

const props = defineProps<{
  item: ReportItemForm;
  basisReportItem: BasisReportItemClient;
  formItems: ReportItemForm[];
}>();

defineEmits<{
  (e: "updateItem", params: ReportItem): void;
}>();

const targetFormItem = computed(() => {
  return props.formItems.find(
    (item) =>
      item.basisReportItemId === props.item.basisReportItemId &&
      !!item.overall === !!props.item.overall &&
      !!item.customers === !!props.item.customers
  );
});

const countNumbers = computed(() => {
  const unitRange = props.basisReportItem.unitRange;
  return _.range(0, unitRange * 100, unitRange);
});

const unit = computed(() => {
  return props.basisReportItem.unit;
});
</script>

<style scoped lang="scss">
.report-item {
  .number-form {
    width: 170px;
  }
}
</style>
