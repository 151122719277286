<template>
  <div>
    <div class="d-flex justify-content-end mb-3 align-items-center py-3">
      <div>
        <BasicButton
          icon="pi pi-sign-out"
          label="管理者ログアウト"
          variant="primary"
          button-type="submit"
          @click="handleLogout"
        />
      </div>
    </div>

    <AdminOrganizationList
      :organizations="organizations || []"
      :members="organizationMembers || []"
      @admin-proxy-login="handleAdminProxyLogin"
    />
  </div>
</template>

<script setup lang="ts">
import { useAuthorize, useAdmin, useRouterUtil } from "/@/vue/composables";
import { errorHandle } from "/@/modules/error";
import { clearStorage } from "/@/modules/localStorage";
import { BasicButton } from "/@/vue/components/Atom";
import { AdminOrganizationList } from "/@/vue/components/Organisms";

const { getOrganizations, getOrganizationMembers } = useAdmin();
const { data: organizations } = getOrganizations();

const { data: organizationMembers } = getOrganizationMembers();

const { adminLogout, adminProxyLogin } = useAuthorize();

function handleLogout() {
  adminLogout();
}

const { goto } = useRouterUtil();

async function handleAdminProxyLogin(memberId: number) {
  try {
    clearStorage();
    await adminProxyLogin(memberId);
    goto({ name: "Home" });
  } catch (e) {
    errorHandle(e);
  }
}
</script>

<style scoped lang="scss">
.admin-contents-item {
  height: 500px;
  width: 100%;
  overflow-y: scroll;

  @include mq(pc) {
    width: 48%;
    margin-inline: 1%;
  }
}
</style>
