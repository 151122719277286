<template>
  <div>
    <CollapseWrapper
      v-if="report"
      :contents-id="`work-reports-${report.id}`"
      card-header
    >
      <template #header>
        <div>
          <div class="">
            <template v-if="report.reportType === 'individual'">
              <div>{{ report.eventPlaceName || report.workPlaceName }}</div>
              <div>{{ report.createdByName }}</div>
              <div>{{ basicFormatter(report.targetDate) }}</div>
            </template>
            <template v-if="report.reportType === 'overall'">
              <div>{{ basicFormatter(report.targetDate) }}の全体実績</div>
            </template>
          </div>
        </div>
      </template>
      <template #contents>
        <WorkReportsShowForm
          :work-reports="[report]"
          :work-type-categories="report.workTypeCategories"
          :work-category="report.workCategory"
          :work-carrier="report.workCarrier"
          :request-organization-id="report.requestOrganizationId"
          :basis-report-items="basisReportItems"
        />
        <div v-if="isOwner" class="d-flex">
          <BasicButton
            variant="success"
            icon="pi pi-pencil"
            rounded
            small
            @click="selectReport"
          />
        </div>
      </template>
    </CollapseWrapper>
  </div>
</template>

<script setup lang="ts">
import {
  BasicButton,
  ContentLabel,
  BasisReportItemClient,
} from "/@/vue/components/Atom";
import { CollapseWrapper } from "/@/vue/components/Molecules";
import { WorkReportClient, WorkRecordEntryReportForm } from "/@/types";
import { WorkReportsShowForm } from ".";
import { basicFormatter } from "/@/modules/luxon";

const props = defineProps<{
  report: WorkReportClient;
  basisReportItems: BasisReportItemClient[];
  isOwner?: boolean;
}>();

const emit = defineEmits<{
  (e: "updateWorkReport", workReport: WorkRecordEntryReportForm): void;
  (e: "selectWorkReport", workReport: WorkReportClient): void;
}>();

async function selectReport() {
  emit("selectWorkReport", props.report);
}
</script>

<style scoped></style>
