<template>
  <div>
    <table
      class="table table-responsive table-bordered table-striped table-sm small"
    >
      <thead>
        <tr class="sticky">
          <th class="sticky">名前</th>
          <th
            v-for="d in dates"
            :key="`header-${d.dateKey}`"
            :class="{
              'text-center': true,
              [getWeekdayColor(d, jpHolidays)]: true,
            }"
          >
            {{ d.luxonDate.day }}
          </th>
          <th>稼働数</th>
        </tr>
      </thead>
      <tbody>
        <template
          v-for="member in fulltimeMembers"
          :key="`member-${member.id}`"
        >
          <tr>
            <td
              class="text-nowrap align-middle sticky td-member-name"
              rowspan="2"
            >
              {{ member.user.name }}
            </td>
            <UserRowCalendarItem
              v-for="d in dates"
              :key="`body-${d.dateKey}`"
              :schedule-plans="schedulePlans"
              :member-id="member.id"
              :member-labels="member.managementLabels || []"
              :target-date="d.dateKey"
              :is-mobile="isMobile"
              :member-schedule-plan-map="memberSchedulePlanMap"
              :member-absence-plan-map="memberAbsencePlanMap"
              :highlight-canceled="highlightCanceled"
              short
              @select-schedule-as-plan="$emit('selectScheduleAsPlan', $event)"
            />
            <UserRowMemberOperationInfo
              :member="member"
              :schedules="schedulePlans"
              :selected-date="dateMap"
            />
          </tr>
          <tr>
            <UserRowCalendarStatusItem
              v-for="d in dates"
              :key="`status-${d.dateKey}`"
              :member-id="member.id"
              :target-date="d.dateKey"
              :member-schedule-plan-map="memberSchedulePlanMap"
              :member-absence-plan-map="memberAbsencePlanMap"
              :member-work-record-map="memberWorkRecordMap"
            />
          </tr>
        </template>
        <tr v-if="outsourcingMembers.length">
          <td class="sticky">外注</td>
        </tr>
        <template
          v-for="member in outsourcingMembers"
          :key="`member-${member.id}-outsourcing`"
        >
          <tr>
            <td class="text-nowrap align-middle sticky" rowspan="2">
              {{ member.user.name }}
            </td>
            <UserRowCalendarItem
              v-for="d in dates"
              :key="`body-${d.dateKey}-outsourcing`"
              :schedule-plans="schedulePlans"
              :member-id="member.id"
              :member-labels="member.managementLabels || []"
              :target-date="d.dateKey"
              :is-mobile="isMobile"
              :absence-application-plans="absenceApplicationPlans"
              :member-schedule-plan-map="memberSchedulePlanMap"
              :member-absence-plan-map="memberAbsencePlanMap"
              short
              @select-schedule-as-plan="$emit('selectScheduleAsPlan', $event)"
            />
            <UserRowMemberOperationInfo
              :member="member"
              :schedules="schedulePlans"
              :selected-date="dateMap"
            />
          </tr>
          <tr>
            <UserRowCalendarStatusItem
              v-for="d in dates"
              :key="`status-${d.dateKey}-outsourcing`"
              :member-id="member.id"
              :target-date="d.dateKey"
              :member-schedule-plan-map="memberSchedulePlanMap"
              :member-absence-plan-map="memberAbsencePlanMap"
              :member-work-record-map="memberWorkRecordMap"
            />
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import {
  createDateForCalendars,
  getWeekdayColor,
  isSameDate,
} from "/@/modules/luxon";
import { useMqUtils } from "/@/vue/composables";
import type {
  AbsenceApplicationPlan,
  OrganizationMember,
  DateForCalendar,
  Organization,
  OrganizationMemberSchedulePlan,
  MemberAbsencePlanMap,
  MemberSchedulePlanMap,
  MemberWorkRecordMap,
  WorkRecordClient,
  DateMap,
  JpHolidays,
} from "/@/types";
import UserRowCalendarItem from "./UserRowCalendarItem.vue";
import UserRowCalendarStatusItem from "./UserRowCalendarStatusItem.vue";
import UserRowMemberOperationInfo from "./UserRowMemberOperationInfo.vue";

const props = defineProps<{
  organization: Organization;
  members: OrganizationMember[]; // 閲覧しているメンバーの組織
  dateMap: DateMap;
  schedulePlans: OrganizationMemberSchedulePlan[];
  absenceApplicationPlans: AbsenceApplicationPlan[];
  workRecords?: WorkRecordClient[];
  highlightCanceled?: boolean;
  jpHolidays?: JpHolidays;
}>();

defineEmits<{
  (
    e: "selectScheduleAsPlan",
    targetSchedule: OrganizationMemberSchedulePlan
  ): void;
}>();

const { isMobile } = useMqUtils();

const dates = computed<DateForCalendar[]>(() => {
  if (!props.dateMap) {
    return [];
  }

  return createDateForCalendars(props.dateMap);
});

const fulltimeMembers = computed(() => {
  return props.members.filter(
    (member) => member.organizationId === props.organization.id
  );
});

const outsourcingMembers = computed(() => {
  return props.members.filter(
    (member) => member.organizationId !== props.organization.id
  );
});

const memberSchedulePlanMap = computed(() => {
  let m: MemberSchedulePlanMap = {};

  props.members.forEach((member) => {
    m[member.id] = {};
    const schedulePlans = props.schedulePlans.filter(
      (plan) => plan.organizationMemberId === member.id
    );

    dates.value.forEach((d) => {
      m[member.id][d.dateKey] = schedulePlans
        .filter((plan) => plan.targetDate === d.dateKey)
        .map((plan) => plan.workSchedules)
        .flat();
    });
  });

  return m;
});

const memberAbsencePlanMap = computed(() => {
  let m: MemberAbsencePlanMap = {};

  props.members.forEach((member) => {
    m[member.id] = {};
    const absencePlans = props.absenceApplicationPlans.filter(
      (plan) => plan.organizationMember.id === member.id
    );

    dates.value.forEach((d) => {
      // @ts-ignore
      m[member.id][d.dateKey] = absencePlans.filter(
        (plan) => plan.targetDate === d.dateKey
      );
    });
  });

  return m;
});

const memberWorkRecordMap = computed(() => {
  let m: MemberWorkRecordMap = {};

  const allRecords = props.workRecords;

  if (!allRecords) return m;

  props.members.forEach((member) => {
    m[member.id] = {};
    const records = allRecords.filter(
      (record) => record.organizationMemberId === member.id
    );

    dates.value.forEach((d) => {
      // @ts-ignore
      m[member.id][d.dateKey] = records.filter((plan) =>
        isSameDate(plan.startAt, d.dateKey)
      );
    });
  });

  return m;
});
</script>

<style scoped>
.td-member-name {
  max-width: 60px;
  min-width: 60px;
}
</style>
