<template>
  <div>
    <SimpleModal
      ref="modal"
      :title="form.id ? 'イベントの更新' : 'イベントの作成'"
      submit-text="送信"
      :loading="loading"
      @cancel="closeModal"
      @submit="handleSubmit"
    >
      <OrganizationEventForm
        v-model:event-type="form.eventType"
        v-model:name="form.name"
        v-model:start-at="form.startAt"
        v-model:finish-at="form.finishAt"
        v-model:remarks="form.remarks"
        v-model:place-name="form.placeName"
        v-model:place-gmap-url="form.placeGmapUrl"
        v-model:organization-member-ids="form.organizationMemberIds"
        v-model:force-invitation="form.forceInvitation"
        :errors="errors"
        :organization-members="filteredOrganizationMembers"
        :organization="organization"
      />
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useZodScheme } from "/@/vue/composables";
import { isDummyMember } from "/@/modules/organizationMember";
import { errorHandle } from "/@/modules/error";
import { SimpleModal } from "/@/vue/components/Molecules";
import { OrganizationEventForm } from "/@/vue/components/Organisms";
import {
  Organization,
  OrganizationEventScheme,
  OrganizationEvent,
  OrganizationEventForm as OrganizationEventFormType,
  OrganizationEventClient,
  OrganizationMember,
} from "/@/types";

const props = defineProps<{
  organization: Organization;
  organizationMembers: OrganizationMember[];
}>();

const emit = defineEmits<{
  (e: "submit", form: OrganizationEventFormType): void;
}>();

const filteredOrganizationMembers = computed(() => {
  return props.organizationMembers.filter(
    (om) => !isDummyMember(om) && !om.hideFromSelect
  );
});

const loading = ref<boolean>();

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } =
  useFormAndErrors<OrganizationEventFormType>(OrganizationEventScheme, {
    eventType: "meeting",
    name: undefined,
    placeGmapUrl: "",
    organizationMemberIds: [props.organization.organizationMemberId],
  });

function formReset(organizationEvent?: OrganizationEventClient) {
  if (organizationEvent) {
    form.id = organizationEvent.id;
    form.eventType = organizationEvent.eventType;
    form.name = organizationEvent.name;
    form.startAt = organizationEvent.startAt;
    form.finishAt = organizationEvent.finishAt || undefined;
    form.remarks = organizationEvent.remarks || undefined;
    form.placeName = organizationEvent.placeName || undefined;
    form.placeGmapUrl = organizationEvent.placeGmapUrl || "";
    form.organizationMemberIds = organizationEvent.organizationMemberIds;
    form.forceInvitation = organizationEvent.forceInvitation;
  } else {
    form.id = undefined;
    form.eventType = "meeting";
    form.name = undefined;
    form.startAt = undefined;
    form.finishAt = undefined;
    form.remarks = undefined;
    form.placeName = undefined;
    form.placeGmapUrl = "";
    form.forceInvitation = false;
    form.organizationMemberIds = [props.organization.organizationMemberId];
  }
}

async function handleSubmit() {
  try {
    loading.value = true;
    startValidation.value = true;

    const organizationEventPrms: OrganizationEvent =
      OrganizationEventScheme.parse(form);

    emit("submit", organizationEventPrms);
  } catch (e) {
    errorHandle(e);
    loading.value = false;
  }
}

const modal = ref();

function openModal(organizationEventClient?: OrganizationEventClient) {
  formReset(organizationEventClient);
  modal.value?.openModal();
}

function closeModal() {
  formReset();
  modal.value?.closeModal();
}

defineExpose({
  openModal,
  closeModal,
});
</script>

<style scoped></style>
