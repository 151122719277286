import useSWRV from "swrv";
import {
  EventPlaceClient,
  Organization,
  OrganizationMember,
  WorkPlace,
  BasisReportItemClient,
  BasisReportItemGroupClient,
} from "/@/types";
import { fetcher } from "/@/modules/axios";

export function useAdmin() {
  function getOrganizations() {
    return useSWRV<Organization[]>("/api/v1/admins/organizations", fetcher);
  }

  function getOrganizationMembers() {
    return useSWRV<OrganizationMember[]>(
      () => "/api/v1/admins/organization_members",
      fetcher
    );
  }

  function getWorkPlaces() {
    return useSWRV<WorkPlace[]>(() => "/api/v1/admins/work_places", fetcher);
  }

  function getEventPlaces() {
    return useSWRV<EventPlaceClient[]>(
      () => "/api/v1/admins/event_places",
      fetcher
    );
  }

  function getBasisReportItems() {
    return useSWRV<BasisReportItemClient[]>(
      () => "/api/v1/admins/basis_report_items",
      fetcher
    );
  }

  function getBasisReportItemGroups() {
    return useSWRV<BasisReportItemGroupClient[]>(
      () => "/api/v1/admins/basis_report_item_groups",
      fetcher
    );
  }

  return {
    getOrganizations,
    getOrganizationMembers,
    getWorkPlaces,
    getEventPlaces,
    getBasisReportItems,
    getBasisReportItemGroups,
  };
}
