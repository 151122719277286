<template>
  <div>
    <table class="table table-responsive table-bordered table-md table-striped">
      <thead>
        <tr>
          <th class="bg-primary text-white">日数</th>
          <td>{{ count }}日間</td>
          <th class="bg-primary text-white">稼動数</th>
          <td>{{ operatingNumber }}稼動</td>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";
import { computed } from "vue";

type ScheduleForCount = Partial<{
  targetDate: string;
  needCount: number;
}>;

const props = defineProps<{
  schedules: ScheduleForCount[];
}>();

const count = computed(() => {
  return _.uniqBy(props.schedules, "targetDate").length;
});

const operatingNumber = computed(() => {
  return props.schedules.reduce((acc, crr) => acc + (crr.needCount || 0), 0);
});
</script>

<style scoped></style>
