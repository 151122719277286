import useSWRV from "swrv";
import { axios, fetcher } from "/@/modules/axios";
import type { Ref } from "vue";
import type {
  Organization,
  TaskItemUpdateParams,
  TaskProjectClient,
} from "/@/types";
import { errorHandle } from "/@/modules/error";

export function useTaskManager() {
  function getProjectOfTasksKey(organization: Ref<Organization | undefined>) {
    const organizationMemberId = organization.value?.organizationMemberId;

    if (!organization.value || !organizationMemberId) {
      return null;
    }

    return `/api/v1/organization_members/${organizationMemberId}/project_for_tasks`;
  }

  function getProjectOfTasks(organization: Ref<Organization | undefined>) {
    return useSWRV<TaskProjectClient[]>(
      () => getProjectOfTasksKey(organization),
      fetcher
    );
  }

  // crud

  async function updateTaskItem(id: number, params: TaskItemUpdateParams) {
    try {
      await axios.patch(`/api/v1/task_items/${id}`, {
        taskItems: params,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function updateTaskItemHierarchy({
    id,
    parentId,
    sortOrder,
  }: {
    id: number;
    parentId: number;
    sortOrder: number;
  }) {
    try {
      await axios.patch(`/api/v1/task_items/${id}/update_hierarchy`, {
        taskItems: {
          parentId,
          sortOrder,
        },
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function addSibling({ id }: { id: number }) {
    try {
      await axios.post(`/api/v1/task_items/${id}/add_sibling`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function addChild({ id }: { id: number }) {
    try {
      await axios.post(`/api/v1/task_items/${id}/add_child`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function destroyTaskItem({ id }: { id: number }) {
    try {
      await axios.delete(`/api/v1/task_items/${id}`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  // special api

  async function moveIncompleteTaskItems({ id }: { id: number }) {
    try {
      await axios.patch(
        `/api/v1/project_for_tasks/${id}/move_incomplete_tasks`
      );
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function autoShiftTaskItem({
    projectId,
    num,
    targetDate,
  }: {
    projectId: number;
    num: number;
    targetDate: string;
  }) {
    try {
      await axios.post(
        `/api/v1/project_for_tasks/${projectId}/auto_shift_tasks`,
        {
          projectForTasks: {
            num,
            targetDate,
          },
        }
      );
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  // project

  async function createProject({
    organizationMemberId,
    name,
  }: {
    organizationMemberId: number;
    name: string;
  }) {
    try {
      await axios.post(
        `/api/v1/organization_members/${organizationMemberId}/project_for_tasks`,
        {
          project_for_tasks: {
            name,
          },
        }
      );
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function destroyProject(id: number) {
    try {
      await axios.delete(`/api/v1/project_for_tasks/${id}`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    getProjectOfTasks,
    updateTaskItem,
    updateTaskItemHierarchy,
    addSibling,
    addChild,
    destroyTaskItem,
    moveIncompleteTaskItems,
    autoShiftTaskItem,

    // project
    createProject,
    destroyProject,
  };
}
