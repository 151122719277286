<template>
  <div>
    <LoadingAnimation v-if="!workRecordDailies" />
    <FormItemShow
      v-else-if="filteredDailies.length"
      label="過去の業務一覧"
      content-id="dailies"
    >
      <div class="card">
        <div class="list-group list-group-flush">
          <WorkRecordDailyListItem
            v-for="daily of filteredDailies"
            :key="daily.targetDate"
            :work-record-daily="daily"
            :organization-member-id="organizationMemberId"
            class="list-group-item"
          />
        </div>
      </div>
    </FormItemShow>
    <div v-else>
      <span class="text-danger">
        過去の業務データが存在しないか権限がありません
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { fromISO, isSameDate } from "/@/modules/luxon";
import { LoadingAnimation } from "/@/vue/components/Atom";
import { FormItemShow } from "/@/vue/components/Molecules";
import { WorkRecordDailyListItem } from "/@/vue/components/Organisms";
import type { WorkRecordDaily, DateMap } from "/@/types";

const props = defineProps<{
  workRecordDailies?: WorkRecordDaily[];
  organizationMemberId: number;
  selectedDate: DateMap;
}>();

const filteredDailies = computed(() => {
  if (!props.workRecordDailies) return [];

  return props.workRecordDailies.filter((daily) => {
    const luxonTargetDate = fromISO(daily.targetDate);

    if (props.selectedDate.day) {
      return isSameDate(daily.targetDate, props.selectedDate.day);
    }

    if (props.selectedDate.month) {
      return (
        luxonTargetDate.year == props.selectedDate.year &&
        luxonTargetDate.month == props.selectedDate.month
      );
    }

    return luxonTargetDate.year == props.selectedDate.year;
  });
});
</script>

<style scoped lang="scss">
a {
  color: inherit;
  text-decoration: none;
}
</style>
