<template>
  <div>
    <div>
      エリア：{{ work.prefectures.map((p) => translater(p)).join("・") }}
    </div>
    <div>店　舗：{{ work.workPlaces?.map((wp) => wp.name).join("・") }}</div>
    <div>
      期　間：{{ basicFormatter(work.startOn) }}~
      {{ basicFormatter(work.finishOn, "noYear") }}
    </div>
    <div>
      情　報：{{ dayCount }}日間
      {{ `${laneCurrentCount}/${laneNeedCount}` }}レーン
    </div>
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";
import { translater } from "/@/modules/string";
import { computed } from "vue";
import { basicFormatter } from "/@/modules/luxon";
import type { Work } from "/@/types";

const props = defineProps<{
  work: Work;
  isOwner?: boolean;
}>();

const dayCount = computed(() => {
  return _.uniqBy(props.work.workSchedules, "targetDate").length;
});

// レーン数
const laneNeedCount = computed(() => {
  return props.work.workSchedules.reduce((acc, cur) => {
    return acc + (cur.needCount || 0);
  }, 0);
});

const laneCurrentCount = computed(() => {
  return props.work.workSchedules.reduce((acc, cur) => {
    return acc + (cur.currentCount || 0);
  }, 0);
});
</script>

<style lang="scss" scoped></style>
