import useSWRV from "swrv";
import { axios, fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import {
  Organization,
  OrganizationEventClient,
  OrganizationEventForm,
} from "/@/types";
import { ComputedRef, Ref } from "vue";

export function useOrganizationEvents() {
  function getOrganizationEventsKey(
    organization:
      | Ref<Organization | undefined>
      | ComputedRef<Organization | undefined>
  ) {
    if (!organization.value) return null;

    return `/api/v1/organizations/${organization.value.id}/organization_events`;
  }

  function getOrganizationEvents(
    organization:
      | Ref<Organization | undefined>
      | ComputedRef<Organization | undefined>
  ) {
    return useSWRV<OrganizationEventClient[]>(
      () => getOrganizationEventsKey(organization),
      fetcher
    );
  }

  async function createOrganizationEvent(
    organization: Organization,
    prms: OrganizationEventForm
  ) {
    try {
      await axios.post(
        `/api/v1/organizations/${organization.id}/organization_events`,
        {
          organizationEvents: prms,
        }
      );
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function updateOrganizationEvent(
    organization: Organization,
    prms: OrganizationEventForm
  ) {
    try {
      await axios.patch(`/api/v1/organization_events/${prms.id}`, {
        organizationEvents: prms,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function destroyOrganizationEvent(id: number) {
    try {
      await axios.delete(`/api/v1/organization_events/${id}`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  // invitation

  async function approveOrganizationEventInvitation(id: number) {
    try {
      await axios.post(`/api/v1/organization_event_invitations/${id}/approve`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function rejectOrganizationEventInvitation(id: number) {
    try {
      await axios.post(`/api/v1/organization_event_invitations/${id}/reject`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    getOrganizationEvents,
    createOrganizationEvent,
    updateOrganizationEvent,
    destroyOrganizationEvent,

    approveOrganizationEventInvitation,
    rejectOrganizationEventInvitation,
  };
}
