<template>
  <div>
    <Dialog
      v-model:visible="showModal"
      modal
      header="欠員数の設定"
      :style="{ width: '25rem' }"
      dismissable-mask
    >
      <div v-if="!binary" class="mb-3 d-flex flex-column">
        <span class="p-text-secondary">
          欠員に設定する人数を設定して下さい
        </span>
        <span class="p-text-secondary">
          （※この数値は欠員人数のメモで、既にスケジュールに設定されているメンバーは個別に欠員設定をする必要があります）
        </span>
      </div>

      <div v-else class="mb-3 d-flex flex-column">
        欠員の設定を選択してください
      </div>

      <div v-if="targetWorkSchedule" class="mb-3">
        <NumberForm
          v-if="!binary"
          v-model:value="vacancyCount"
          :form-id="`vacancy-count-${targetWorkSchedule.id}`"
          :items="vacancyCountOptions.map((o) => o.value)"
          class="me-2"
        />
        <Dropdown
          v-else
          v-model="vacancyCount"
          :options="vacancyCountOptions"
          option-label="label"
          option-value="value"
          :form-id="`vacancy-count-${targetWorkSchedule.id}`"
          class="me-2"
        />
        <span v-if="!binary">最大：{{ targetWorkSchedule.needCount }}</span>
      </div>

      <div class="d-flex justify-content-start w-100">
        <BasicButton
          label="キャンセル"
          severity="secondary"
          class="me-2"
          @click="showModal = false"
        />
        <BasicButton label="更新" @click="updateVacancyCount" />
      </div>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";
import { ref, computed, watch } from "vue";
import { NumberForm, BasicButton } from "/@/vue/components/Atom";
import { WorkScheduleAsPlan } from "/@/types";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";

const showModal = defineModel<boolean>({
  default: false,
});
const vacancyCount = ref<number | boolean>(0);

const props = defineProps<{
  targetWorkSchedule?: WorkScheduleAsPlan;
  binary?: boolean;
}>();

watch(
  () => props.targetWorkSchedule,
  (newVal) => {
    if (newVal) {
      vacancyCount.value = props.binary ? 0 : newVal.vacancyCount || 0;
    }
  }
);

const emit = defineEmits<{
  (e: "vacancy", id: number, count: number): void;
}>();

const vacancyCountOptions = computed(() => {
  if (props.binary)
    return [
      {
        label: "設定しない",
        value: 0,
      },
      {
        label: "欠員に設定",
        value: 1,
      },
    ];

  if (!props.targetWorkSchedule) {
    return [
      {
        label: "0",
        value: 0,
      },
    ];
  }

  return _.range(0, (props.targetWorkSchedule.needCount || 0) + 1).map((i) => {
    return {
      label: i.toString(),
      value: i,
    };
  });
});

function updateVacancyCount() {
  if (!props.targetWorkSchedule) return;

  emit("vacancy", props.targetWorkSchedule.id, vacancyCount.value);
}
</script>

<style scoped></style>
