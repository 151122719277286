<template>
  <div>
    <SimpleModal
      ref="modal"
      title="選択して下さい"
      no-actions
      @cancel="closeModal"
    >
      <div
        v-for="member of assignedMembers"
        :key="member.organizationMemberId"
        class="card card-body mb-2"
        role="button"
        @click="$emit('selectMember', member)"
      >
        {{ member.organizationMemberName }}
      </div>
      <div
        v-for="organization of assignedOrganizations"
        :key="organization.organizationId"
        class="card card-body mb-2"
        role="button"
        @click="$emit('selectOrganization', organization)"
      >
        {{ organization.organizationName }}
        {{ organization.assignedNeedCount }}枠
      </div>
      <div
        v-if="!targetSchedule?.enoughCount"
        role="button"
        class="card card-body"
        @click="$emit('selectNewAssignment', targetSchedule?.id)"
      >
        追加割り当て
      </div>
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { SimpleModal } from "/@/vue/components/Molecules";
import type {
  AssignedMember,
  AssignedOrganization,
  WorkScheduleAsPlan,
} from "/@/types";

defineProps<{
  schedule?: WorkScheduleAsPlan;
}>();

const emit = defineEmits<{
  (e: "selectMember", member: AssignedMember): void;
  (e: "selectOrganization", organization: AssignedOrganization): void;
  (e: "selectNewAssignment", id: number): void;
  (e: "closeModal"): void;
}>();

const assignedMembers = ref<AssignedMember[]>([]);
const assignedOrganizations = ref<AssignedOrganization[]>([]);
const targetSchedule = ref<WorkScheduleAsPlan>();

// modal difinissions

const modal = ref();

function openModal({
  members,
  organizations,
  schedule,
}: {
  members: AssignedMember[];
  organizations: AssignedOrganization[];
  schedule: WorkScheduleAsPlan;
}) {
  assignedMembers.value = members;
  assignedOrganizations.value = organizations;
  targetSchedule.value = schedule;

  modal.value.openModal();
}

function closeModal() {
  assignedMembers.value = [];
  assignedOrganizations.value = [];
  targetSchedule.value = undefined;

  modal.value.closeModal();
  emit("closeModal");
}

defineExpose({
  openModal,
  closeModal,
});
</script>

<style scoped></style>
