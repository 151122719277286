<template>
  <div>
    <div class="d-flex flex-wrap">
      <SelectButton
        :model-value="selectedWorkCategory"
        :options="workCategories.map((k) => k)"
        :option-label="translater"
        class="mb-3 me-2"
        @update:model-value="$emit('update:selectedWorkCategory', $event)"
      />

      <SelectButton
        :model-value="selectedWorkCarrier"
        :options="carriers.map((k) => k)"
        :option-label="translater"
        class="mb-3 me-2"
        @update:model-value="$emit('update:selectedWorkCarrier', $event)"
      />

      <div class="w-100">
        <ContentLabel label="依頼組織" />
        <div style="max-width: 100%; overflow: scroll">
          <Dropdown
            :model-value="selectedRequestOrganizationId"
            :options="organizationOptions"
            display="chip"
            filter
            auto-filter-focus
            show-clear
            empty-filter-message="見つかりません"
            option-label="name"
            option-value="id"
            placeholder="未選択"
            @update:model-value="
              $emit('update:selectedRequestOrganizationId', $event)
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { translater } from "/@/modules/string";
import { ContentLabel } from "/@/vue/components/Atom";
import {
  workCategories,
  carriers,
  WorkCategory,
  Carrier,
  Organization,
} from "/@/types";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";

const props = defineProps<{
  selectedWorkCategory?: WorkCategory;
  selectedWorkCarrier?: Carrier;
  selectedRequestOrganizationId?: number;
  requestOrganizations: Organization[];
}>();

defineEmits<{
  (e: "update:selectedWorkCategory", key: WorkCategory[]): void;
  (e: "update:selectedWorkCarrier", key: Carrier[]): void;
  (e: "update:selectedRequestOrganizationId", key: Organization[]): void;
}>();

const organizationOptions = computed(() => {
  return [
    { id: undefined, name: "未選択" },
    { id: -1, name: "全て表示する" },
    ...props.requestOrganizations,
  ];
});
</script>

<style scoped></style>
