import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { OrganizationCategoryScheme } from "/@/types/category.d";
import { OrganizationMemberRolesScheme } from "/@/types/organizationMemberRole.d";
import { ManagementLabelClientScheme } from "/@/types/managementLabel.d";

export const UserScheme = z.object({
  id: z.number().optional(),
  name: z.string(),
  email: z.string().email(),
  password: z.string().optional(),
  passwordCheckCount: z.number().optional(),
  organizationCategories: OrganizationCategoryScheme.array(),
  organizations: z
    .object({
      name: z.string(),
      roles: OrganizationMemberRolesScheme.array(),
      categories: OrganizationCategoryScheme.array(),
      labels: ManagementLabelClientScheme.array(),
    })
    .array(),
  unhandledRequestsCount: z.number().optional(),
});

export type User = zod.infer<typeof UserScheme>;

export type UserParams = {
  name?: string;
  email?: string;
  password?: string;
};

export type UserAttributes = keyof User;

type SingUpResponse = {
  status: "confirm" | "success" | "error";
  user?: User;
};

export type UserActionStatus = {
  canStartWork: boolean;
  canFinishWork: boolean;
  canRestartWork: boolean;
  canEnter: boolean;
  canLeave: boolean;
  needSubmitLocation: boolean;
  needEnterLeaveReport: boolean;
  needFinishReport: boolean;
  needSubmitBodyTemperature: boolean;
  needSubmitAntibodyTest: boolean;
  needHandleTask: boolean;
  needSubmitTargets: boolean;
  isHoliday: boolean;
  isSickDay: boolean;
  isCurrentWorkingFromYesterday: boolean | string;
};
