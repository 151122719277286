import zod from "zod";
import { z, DateScheme, DateTimeScheme } from "/@/modules/zodUtils";
import { PrefectureScheme } from "/@/types/address.d";
import { WorkScheduleScheme } from "/@/types/workSchedule.d";
import { ReportItemFilterScheme } from "/@/types/workReport.d";
import { WorkCategoriesScheme } from "/@/types/workCategories.d";
import { CarriersScheme } from "/@/types/carriers.d";
import { MeetingTargetClientScheme } from "/@/types/meetingTargets.d";

// work config
export const WorkConfigScheme = z.object({
  needBodyTemperature: z.boolean(),
  needAntibodyTestWithin: z.number(),
});

export const mailTypes = ["start", "finish", "submit_bodytemperature"] as const;
export const MailTypesScheme = z.enum(mailTypes);
export type MailType = zod.infer<typeof MailTypesScheme>;

export const WorkConfigMailerScheme = z.object({
  id: z.number().optional(),
  mailType: MailTypesScheme,
  mailAddress: z.string(),
});

export const WorkConfigMailersFormScheme = WorkConfigMailerScheme.partial({
  id: true,
  mailAddress: true,
});

export type WorkConfigMailersForm = zod.infer<
  typeof WorkConfigMailersFormScheme
>;

export const scheduleGroupTypes = [
  "unnecessary",
  "weekly",
  "monthly",
  "continuous",
] as const;
export const ScheduleGroupTypesScheme = z.enum(scheduleGroupTypes);
export type ScheduleGroupType = zod.infer<typeof ScheduleGroupTypesScheme>;

// work

export const WorkScheme = z.object({
  id: z.number().optional(),
  name: z.string().optional().nullable(),
  organizationId: z.number(),
  requestOrganizationId: z.number().optional(),
  requestOrganizationName: z.string().optional(),
  workCategory: WorkCategoriesScheme,
  carrier: CarriersScheme,
  prefectures: PrefectureScheme.array().min(1, {
    message: "1つ以上選択してください",
  }),
  workPlaceIds: z
    .number()
    .array()
    .min(1, { message: "1つ以上選択してください" }),
  workPlaces: z
    .object({
      id: z.number(),
      name: z.string(),
      shortName: z.string().optional(),
      organizationName: z.string(),
      organizationId: z.number(),
    })
    .array()
    .optional(),
  workTypeIds: z
    .number()
    .array()
    .min(1, { message: "1つ以上選択してください" }),
  startOn: z.union([DateTimeScheme, DateScheme]),
  finishOn: z.union([DateTimeScheme, DateScheme]),
  assignedMemberIds: z.number().array(),
  workSchedules: WorkScheduleScheme.array().min(1, {
    message: "1つ以上のスケジュールを作成して下さい",
  }),
  scheduleAssignedMemberIds: z.number().array().optional(),
  workConfig: WorkConfigScheme,
  workConfigMailers: WorkConfigMailerScheme.array(),
  scheduleGroupType: ScheduleGroupTypesScheme.optional(),
  meetingTargetFilter: ReportItemFilterScheme.array().optional().nullable(),
});

export type Work = zod.infer<typeof WorkScheme>;

export const WorkClientScheme = WorkScheme.extend({
  meetingTargets: MeetingTargetClientScheme.array(),
  scheduleGroups: z
    .object({
      targetDateRange: DateTimeScheme.array(),
      meetingTarget: MeetingTargetClientScheme,
      workSchedules: WorkScheduleScheme.array(),
    })
    .array(),
});
export type WorkClient = zod.infer<typeof WorkClientScheme>;

export const WorkFormScheme = WorkScheme.partial().extend({
  workPlaceIds: WorkScheme.shape.workPlaceIds,
  workConfigMailers: WorkConfigMailerScheme.array(),
});

export type WorkForm = zod.infer<typeof WorkFormScheme>;
