<template>
  <div>
    <div v-for="(m, i) in messages" :key="`message-${i}`" :class="messageClass">
      {{ m }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { MessageStatus } from "/@/types/message";

interface Props {
  messages: Array<string>;
  status?: MessageStatus;
}

const props = defineProps<Props>();

const messageClass = computed<any>(() => {
  return {
    "text-danger": props.status == "error",
    "text-success": props.status == "success",
    "text-warning": props.status == "sync-location",
  };
});
</script>

<style scoped lang="scss">
.text-warning {
  color: #fbbc05;
}
</style>
