import zod from "zod";
import { DateTimeScheme, z } from "/@/modules/zodUtils";

export const labelTypes = [
  "department_owner",
  //"department_member",
  "labor",
  "role",
  "request_organization",
  "work_place",
] as const;

export const LabelTypeScheme = zod.enum(labelTypes);
export type LabelType = zod.infer<typeof LabelTypeScheme>;

export const needDepsLabelTypes: LabelType[] = [
  "request_organization",
  "work_place",
];

const ManagementLabelBaseScheme = z.object({
  labelType: LabelTypeScheme,
  name: z.string(),
  departmentName: z.string().optional(),
  dependancyId: z.number().optional(),
});

export const ManagementLabelScheme = ManagementLabelBaseScheme.refine(
  ({ labelType, departmentName }) => {
    return (
      labelType !== "department_owner" ||
      (departmentName !== undefined && departmentName !== "")
    );
  },
  {
    path: ["departmentName"],
    message: "グループ名は必須です",
  }
);

export type ManagementLabel = zod.infer<typeof ManagementLabelScheme>;

export const ManagementLabelFormScheme = ManagementLabelBaseScheme.partial({
  name: true,
  departmentName: true,
});
export type ManagementLabelForm = zod.infer<typeof ManagementLabelFormScheme>;

export const managementLabelConfigKeys = [
  // member
  "canShowMember",
  "canShowMemberPlans",
  "canShowMemberAbsenceApplicationPlans",
  "canShowMemberReports",
  "canShowMemberRecords",
  "canShowMemberWorkTasks",
  // "canShowMemberWorkScheduleCalendar",
  "canShowMemberWorkScheduleCalendarAdmin",
  //"canShowMemberAttendance",
  "canShowMemberNotificationMails",
  "canShowMemberSummaryMails",
  // work
  "canShowWorkMeetingTarget",
  // organization
  //"canShowOrganizationSchedule",
  // request organizaiton, work place
  "canShowWorker",
  "canShowWorkerSummaryMails",
] as const;

export const ManagementLabelConfigKeyScheme = zod.enum(
  managementLabelConfigKeys
);

export type ManagementLabelConfigKey = zod.infer<
  typeof ManagementLabelConfigKeyScheme
>;

export const ManagementLabelConfigScheme = z.object({
  // member
  canShowMember: z.boolean(),
  canShowMemberAbsenceApplicationPlans: z.boolean(),
  canShowMemberPlans: z.boolean(),
  canShowMemberReports: z.boolean(),
  canShowMemberRecords: z.boolean(),
  canShowMemberWorkTasks: z.boolean(),
  canShowMemberWorkScheduleCalendar: z.boolean(),
  canShowMemberWorkScheduleCalendarAdmin: z.boolean(),
  //canShowMemberAttendance: z.boolean(),
  canShowMemberNotificationMails: z.boolean(),
  canShowMemberSummaryMails: z.boolean(),
  // work
  canShowWorkMeetingTarget: z.boolean(),
  // organization
  canShowOrganizationSchedule: z.boolean(),
  // request organization, work place
  canShowWorker: z.boolean(),
  canShowWorkerSummaryMails: z.boolean(),
});

export type ManagementLabelConfigClient = zod.infer<
  typeof ManagementLabelConfigScheme
>;

export const ManagementLabelClientScheme = ManagementLabelBaseScheme.extend({
  id: z.number(),
  accessFrom: DateTimeScheme,
  config: ManagementLabelConfigScheme,
});

export type ManagementLabelClient = zod.infer<
  typeof ManagementLabelClientScheme
>;

// label group

export const groupTypes = labelTypes;
export const GroupTypeScheme = zod.enum(groupTypes);
export type GroupType = zod.infer<typeof GroupTypeScheme>;

export const LabelGroupScheme = z.object({
  groupType: GroupTypeScheme,
  name: z.string(),
});

export type LabelGroup = zod.infer<typeof LabelGroupScheme>;
