<template>
  <div>
    <div class="d-flex align-items-end flex-wrap">
      <div>
        <div v-if="!onlyChecker" class="mb-3">
          <ContentLabel label="表示月" />
          <SelectDate
            :year="selectedDate.year"
            :month="selectedDate.month"
            :exists-dates="existsDates"
            only-year-month
            disabled-auto-select
            @update:year="updateYear"
            @update:month="updateMonth"
          />
        </div>

        <div class="d-flex flex-wrap mb-3">
          <SelectButton
            :model-value="selectedCarriers"
            :options="carrierItems"
            option-label="name"
            option-value="value"
            multiple
            aria-labelledby="multiple"
            class="me-2 mb-2"
            @update:model-value="$emit('update:selectedCarriers', $event)"
          />

          <SelectButton
            :model-value="selectedCategories"
            :options="categoryItems"
            option-label="name"
            option-value="value"
            multiple
            aria-labelledby="multiple"
            @update:model-value="$emit('update:selectedCategories', $event)"
          />
        </div>
      </div>
      <div v-if="false" class="">
        <div class="">
          <BasicButton
            variant="warning"
            class="mb-3"
            slim
            label="全て表示"
            icon="pi pi-eye"
            @click="allEnable"
          />
        </div>
        <div class="">
          <BasicButton
            variant="warning"
            class="mb-3"
            label="全て非表示"
            icon="pi pi-eye-slash"
            @click="allDisabled"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SelectButton from "primevue/selectbutton";
import { computed } from "vue";
import { createIsoRange } from "/@/modules/luxon";
import { carriers, workCategories } from "/@/types";
import type { Carrier, DateMapForm, WorkCategory } from "/@/types";
import { ContentLabel, SelectDate, BasicButton } from "/@/vue/components/Atom";
import { FormItemChecker } from "/@/vue/components/Molecules";
import { translater } from "/@/modules/string";

const props = defineProps<{
  selectedDate: DateMapForm;
  oldestStartOn?: string;
  newestFinishOn?: string;
  selectedCarriers: Carrier[];
  selectedCategories: WorkCategory[];
  onlyChecker?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:selectedDate", date: DateMapForm): void;
  (e: "update:selectedCategories", categories: WorkCategory[]): void;
  (e: "update:selectedCarriers", carriers: Carrier[]): void;
  (e: "update:selectedDate", date: DateMapForm): void;
}>();

function updateYear(year: number | undefined) {
  emit("update:selectedDate", { ...props.selectedDate, year });
}

function updateMonth(month: number | undefined) {
  emit("update:selectedDate", { ...props.selectedDate, month });
}

const existsDates = computed(() => {
  if (!props.oldestStartOn || !props.newestFinishOn) return [];
  return createIsoRange(props.oldestStartOn, props.newestFinishOn, "month");
});

const carrierItems = computed(() => {
  return carriers
    .filter((c) => c !== "home" && c !== "office")
    .map((c) => ({
      value: c,
      name: translater(c),
    }));
});

const categoryItems = computed(() => {
  return workCategories
    .filter((c) => c !== "normal")
    .map((c) => ({
      value: c,
      name: translater(c),
    }));
});

function allEnable() {
  emit("update:selectedCarriers", ["au", "docomo", "softbank", "yahoo", "uq"]);
  emit("update:selectedCategories", [
    "consulting",
    "event_helper",
    "fulltime",
    "light_ad",
    "rounder",
    "shop_helper",
    "sv",
    "sajit",
  ]);
}

function allDisabled() {
  emit("update:selectedCarriers", []);
  emit("update:selectedCategories", []);
}
</script>

<style scoped></style>
