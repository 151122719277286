<template>
  <div>
    <SelectButton
      :model-value="eventType"
      :options="organizationEventTypes"
      label="種別"
      :option-label="translater"
      form-id="visible"
      class="mb-3"
      :allow-empty="false"
      :class="{
        'p-invalid': !eventType,
      }"
      @update:model-value="$emit('update:eventType', $event)"
    />

    <FormItem
      :value="name"
      label="イベント名"
      form-id="name"
      form-type="text"
      :errors="errors"
      class="mb-3"
      @update:value="$emit('update:name', $event as string | undefined)"
    />

    <div class="d-flex flex-wrap">
      <FormItem
        :value="placeName"
        label="会場"
        form-id="placeName"
        form-type="text"
        :errors="errors"
        class="mb-3 me-2"
        @update:value="$emit('update:placeName', $event as string | undefined)"
      />

      <FormItem
        :value="placeGmapUrl"
        label="Google Map URL"
        form-id="placeGmapUrl"
        form-type="text"
        :errors="errors"
        class="mb-3"
        @update:value="$emit('update:placeGmapUrl', $event as string)"
      />
    </div>

    <ContentLabel label="開始日時" />
    <div class="d-flex flex-wrap">
      <FormItem
        :value="startAt"
        form-id="startAt"
        form-type="date"
        :errors="errors"
        class="mb-3 me-2"
        :is-valid="!errors.startAt"
        @update:value="$emit('update:startAt', $event as string | undefined)"
      />

      <FormItemTime
        :value="startAt"
        :start-time="{ hours: 12, minutes: 0 }"
        form-id="startAt"
        :env="startEnv"
        :errors="errors"
        class="mb-3"
        :disabled="!startAt"
        :is-valid="!errors.startAt"
        @update:value="$emit('update:startAt', $event as string | undefined)"
      />
    </div>

    <ContentLabel label="終了日時(未設定可)" />
    <div class="d-flex flex-wrap">
      <FormItem
        :value="finishAt"
        form-id="finishAt"
        form-type="date"
        :errors="errors"
        class="mb-3 me-2"
        @update:value="$emit('update:finishAt', $event as string | undefined)"
      />

      <FormItemTime
        :value="finishAt"
        :start-time="{ hours: 12, minutes: 0 }"
        form-id="finishAt"
        :env="finishEnv"
        :errors="errors"
        class="mb-3"
        :disabled="!finishAt"
        :is-valid="!errors.finishAt"
        @update:value="$emit('update:finishAt', $event as string | undefined)"
      />
    </div>

    <div class="mb-3">
      <FormItem
        :value="remarks"
        label="備考"
        :errors="errors"
        form-type="textarea"
        form-id="remarks"
        @update:value="$emit('update:remarks', $event as string | undefined)"
      />
    </div>

    <div class="mb-3">
      <div class="d-flex flex-wrap justify-content-between align-items-center">
        <ContentLabel label="参加者" without-padding class="mb-3" />
        <div class="d-flex flex-wrap">
          <BasicButton
            label="組織メンバーを全選択"
            class="me-2 mb-3"
            @click="selectAllOrganizationMembers"
          />
          <BasicButton
            label="下請けメンバーを全選択"
            class="mb-3 me-2"
            @click="selectAllSubcontractorsMembers"
          />
          <BasicButton
            label="クリア"
            variant="danger"
            icon="pi pi-trash"
            class="mb-3"
            @click="clearMembers"
          />
        </div>
      </div>

      <div class="w-100" style="overflow-x: scroll">
        <MultiSelect
          :model-value="organizationMemberIds"
          :options="organizationMembers"
          filter
          empty-filter-message="メンバーが見つかりません"
          :option-label="(member: OrganizationMember) => member.user.name"
          option-value="id"
          placeholder="参加者を選択"
          :compare-fn="
            (a, b) =>
              organizationMemberCompareFn(
                a,
                b,
                organizationMembers,
                organization
              )
          "
          @update:model-value="$emit('update:organizationMemberIds', $event)"
        />
      </div>
    </div>

    <div class="mb-3 d-flex flex-wrap align-items-center">
      <ToggleButton
        :model-value="forceInvitation"
        on-icon="pi pi-exclamation-circle"
        off-icon="pi pi-file-edit"
        on-label="強制参加"
        off-label="自由参加"
        class="me-2 mb-2"
        @update:model-value="$emit('update:forceInvitation', $event)"
      />
      <div class="mb-2">
        <span v-if="forceInvitation">招待された人が自動で参加になります</span>
        <span v-else>参加者が参加・不参加を選択できます</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { translater } from "/@/modules/string";
import { fromISO } from "/@/modules/luxon";
import {
  isDummyMember,
  organizationMemberCompareFn,
} from "/@/modules/organizationMember";
import { ZodFormattedErrors } from "/@/modules/zodUtils";
import { FormItem, FormItemTime } from "/@/vue/components/Molecules";
import { ContentLabel, BasicButton, MultiSelect } from "/@/vue/components/Atom";
import {
  Organization,
  OrganizationEventType,
  OrganizationMember,
  organizationEventTypes,
} from "/@/types";
import SelectButton from "primevue/selectbutton";
import ToggleButton from "primevue/togglebutton";

const props = defineProps<{
  name?: string;
  eventType?: OrganizationEventType;
  placeName?: string;
  placeGmapUrl: string;
  startAt?: string;
  finishAt?: string;
  remarks?: string;
  forceInvitation?: boolean;
  organizationMemberIds: number[];
  organizationMembers: OrganizationMember[];
  errors: ZodFormattedErrors;
  organization: Organization;
}>();

const emit = defineEmits<{
  (e: "update:name", name: string | undefined): void;
  (e: "update:eventType", eventType: OrganizationEventType | undefined): void;
  (e: "update:startAt", startAt: string | undefined): void;
  (e: "update:finishAt", finishAt: string | undefined): void;
  (e: "update:placeName", placeName: string | undefined): void;
  (e: "update:placeGmapUrl", placeGmapUrl: string): void;
  (e: "update:remarks", remarks: string | undefined): void;
  (e: "update:organizationMemberIds", organizationMemberIds: number[]): void;
  (e: "update:forceInvitation", forceInvitation: boolean): void;
}>();

const startEnv = computed(() => {
  if (!props.startAt) return undefined;

  return fromISO(props.startAt).toObject();
});

const finishEnv = computed(() => {
  if (!props.finishAt) return undefined;

  return fromISO(props.finishAt).toObject();
});

function selectAllOrganizationMembers() {
  const organizationMemberIds = props.organizationMembers
    .filter(
      (om) =>
        !om.hideFromSelect &&
        om.organizationId == props.organization.id &&
        !isDummyMember(om)
    )
    .map((member) => member.id);

  const current = props.organizationMemberIds;

  emit("update:organizationMemberIds", [
    ...new Set([...current, ...organizationMemberIds]),
  ]);
}

function selectAllSubcontractorsMembers() {
  const organizationMemberIds = props.organizationMembers
    .filter(
      (om) =>
        !om.hideFromSelect &&
        om.organizationId != props.organization.id &&
        !isDummyMember(om)
    )
    .map((member) => member.id);

  const current = props.organizationMemberIds;

  emit("update:organizationMemberIds", [
    ...new Set([...current, ...organizationMemberIds]),
  ]);
}

function clearMembers() {
  emit("update:organizationMemberIds", []);
}
</script>

<style scoped></style>
