<template>
  <div class="d-flex flex-column justify-content-end align-items-end">
    <div class="d-flex mb-2">
      <FormItemSelector
        v-model:value="selectedConfig"
        label=""
        form-type="object"
        :errors="{}"
        :items="hideMapConfigs"
        no-auto-select
        value-key="id"
        show-key="name"
        form-id="hide-map-configs-form"
        class="me-2"
      />

      <div class="me-2">
        <BasicButton
          variant="success"
          slim
          :disabled="!selectedConfig"
          button-type="submit"
          label="読込"
          icon="pi pi-refresh"
          @click="loadConfig"
        />
      </div>

      <div class="">
        <BasicButton
          variant="danger"
          slim
          :disabled="!selectedConfig"
          label="削除"
          icon="pi pi-trash"
          @click="destroyHideMapConfig"
        />
      </div>
    </div>

    <BasicButton
      variant="primary"
      slim
      label="現在の設定に名前を付けて保存"
      icon="pi pi-save"
      class="mb-2"
      @click="saveConfig"
    />

    <BasicButton
      label="表示設定モードの終了"
      slim
      icon="pi pi-eye"
      @click="$emit('exitHideMapConfig')"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { BasicButton } from "/@/vue/components/Atom";
import { FormItemSelector } from "/@/vue/components/Molecules";

import { HideMapConfig } from "/@/types";

const props = defineProps<{
  hideMapConfigs: HideMapConfig[];
}>();

const emit = defineEmits<{
  (e: "saveHideMapConfig", name: string): void;
  (e: "loadHideMapConfig", config: HideMapConfig): void;
  (e: "destroyHideMapConfig", config: HideMapConfig): void;
  (e: "exitHideMapConfig"): void;
}>();

const selectedConfig = ref<HideMapConfig | undefined>();

function loadConfig() {
  if (!selectedConfig.value) return;

  emit("loadHideMapConfig", selectedConfig.value);
}

function saveConfig() {
  const name = window.prompt(
    "名前を付けて現在の設定を保存します。名前を入力して下さい。"
  );

  if (!name || name === "") {
    window.alert("保存をキャンセルしました");
    return;
  }

  emit("saveHideMapConfig", name);
}

function destroyHideMapConfig() {
  if (!selectedConfig.value) return;

  emit("destroyHideMapConfig", selectedConfig.value);
}
</script>

<style scoped></style>
