<template>
  <div
    :id="contentId"
    data-bs-toggle="tooltip"
    data-bs-placement="top"
    data-bs-html="true"
    :title="tooltipTitle"
    :data-original-title="tooltipTitle"
  >
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { onMounted, watch, ref, onBeforeUnmount } from "vue";
import { initTooltip, disposeTooltip } from "/@/modules/bootstrapUtil";
import { nextTick } from "vue";

const props = defineProps<{
  tooltipTitle: string;
  contentId: string;
}>();

const tooltips = ref();

onMounted(async () => {
  tooltips.value = initTooltip(props.contentId);
});

onBeforeUnmount(() => {
  disposeTooltip(tooltips.value);
});

watch(
  () => props.tooltipTitle,
  async () => {
    disposeTooltip(tooltips.value);
    await nextTick();
    tooltips.value = initTooltip(props.contentId);
  }
);
</script>

<style scoped></style>
