import { ActionContext, Module } from "vuex";
import { axios } from "/@/modules/axios";
import { RootState } from "./index";
import { Admin } from "/@/types";
import { errorHandle } from "/@/modules/error";

export type AdminStoreState = {
  admin?: Admin;
};

type AdminActionContext = ActionContext<AdminStoreState, RootState>;

export const adminState: Module<AdminStoreState, RootState> = {
  namespaced: true,
  state: {
    admin: undefined,
  },

  mutations: {
    SET_ADMIN(state: AdminStoreState, admin: Admin) {
      state.admin = admin;
    },

    LOGOUT(state: AdminStoreState) {
      state.admin = undefined;
    },
  },

  actions: {
    SET_ADMIN({ commit }: AdminActionContext, admin: Admin) {
      commit("SET_ADMIN", admin);
    },

    async GET_ADMIN({ dispatch }: AdminActionContext) {
      try {
        const result = await axios.get("/api/v1/admins/current");

        if (!result || !result.data) {
          dispatch("SET_ADMIN", undefined);
          return false;
        }

        const admin: Admin = result.data;
        dispatch("SET_ADMIN", admin);

        return admin;
      } catch (e) {
        return false;
      }
    },

    async LOGIN({ dispatch }: AdminActionContext, admin: Admin) {
      try {
        const result = await axios.post("/api/v1/admins/log_in", {
          admins: admin,
        });

        if (result.data.state) {
          dispatch("SET_ADMIN", result.data);
          return true;
        } else {
          return false;
        }
      } catch (e) {
        errorHandle(e);
        return false;
      }
    },

    async PROXY_LOGIN({ dispatch }: AdminActionContext, memberId: number) {
      try {
        const result = await axios.post("/api/v1/admins/proxy_log_in", {
          admins: {
            memberId,
          },
        });

        if (result.data.state) {
          dispatch("user/SET_USER", result.data, { root: true });
          return true;
        } else {
          return false;
        }
      } catch (e) {
        errorHandle(e);
        return false;
      }
    },

    async LOGOUT({ commit }: AdminActionContext) {
      try {
        await axios.post("/api/v1/admins/log_out");
        commit("LOGOUT");
        return true;
      } catch (e) {
        errorHandle(e);
        return false;
      }
    },
  },
};
