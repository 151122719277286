<template>
  <div>
    <PageHeader page-title="案件作成" />
    <WorkForm
      :searching="!workPlaces"
      :organizations="relatedOrganizations || []"
      :request-organizations="requestOrganizations || []"
      :work-places="workPlaces || []"
      :work-types="workTypes || []"
      :event-places="eventPlaces || []"
      :organization-members="[]"
      :basis-report-items="basisReportItems || []"
      :is-homeworker="isHomeworker"
      :loading="loading"
      :work-type-categories="selectedOrganizaiton?.categories || []"
      @select-organization="selectOrganization"
      @search-work-places="searchWorkPlaces"
      @submit="handleSubmit"
      @cancel="handleCancel"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import {
  useBasisReportItems,
  useOrganization,
  useRouterUtil,
  useWorkPlaces,
  useWorkTypes,
  useWorks,
  useEventPlaces,
} from "/@/vue/composables";
import { PageHeader } from "/@/vue/components/Layouts";
import { WorkForm } from "/@/vue/components/Organisms";
import type { Organization, WorkForm as WorkFormType } from "/@/types";

const { getOrganizations, getRequestOrganizations, asOwner, asHomeworker } =
  useOrganization();
const { data: organizations } = getOrganizations();

const relatedOrganizations = computed(() => {
  return (
    organizations.value?.filter((o) => asOwner(o) || asHomeworker(o)) || []
  );
});

// work places

const { searchWorkPlaces, getWorkPlaces } = useWorkPlaces();
const { data: workPlaces } = getWorkPlaces();

// work types

const selectedOrganizaiton = ref<Organization | undefined>();
function selectOrganization(organization: Organization) {
  selectedOrganizaiton.value = organization;
}

// event places

const { getEventPlaces } = useEventPlaces();
const { data: eventPlaces } = getEventPlaces();

const isHomeworker = computed(() => {
  if (!selectedOrganizaiton.value) {
    return false;
  }

  return asHomeworker(selectedOrganizaiton.value);
});

const { data: requestOrganizations } =
  getRequestOrganizations(selectedOrganizaiton);

const { getWorkTypes } = useWorkTypes();
const { data: workTypes } = getWorkTypes(selectedOrganizaiton, isHomeworker);

// basis report items

const { getBasisReportItems } = useBasisReportItems();
const { data: basisReportItems } = getBasisReportItems();

// submit

const { createWork } = useWorks();
const { goto, backto } = useRouterUtil();

const loading = ref<boolean>(false);

async function handleSubmit(work: WorkFormType) {
  loading.value = true;

  if (await createWork(work)) {
    alert("作成に成功しました");
    goto({ name: "WorksIndex" });
  } else {
    alert("作成に失敗しました");
    loading.value = false;
  }
}

function handleCancel() {
  backto({ confirm: true });
}
</script>

<style scoped></style>
