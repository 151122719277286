import zod from "zod";
import { DateTimeScheme, z } from "/@/modules/zodUtils";
import { OrganizationCategoryScheme } from "/@/types/category.d";
import { CarriersScheme } from "/@/types/carriers.d";
import { WorkCategoriesScheme } from "/@/types/workCategories.d";

export const BaseOfBasisReportItemScheme = z.object({
  id: z.number().optional(),
  organizationId: z.number().optional().nullable(),
  name: z.string(),
  shortName: z.string().optional().nullable(),
  groupId: z.number().optional().nullable(),
  unit: z.string(),
  unitRange: z.number(),
  validFrom: DateTimeScheme.optional().nullable(),
  validTo: DateTimeScheme.optional().nullable(),
  visible: z.boolean(),
  order: z.number(),
});

export type BaseOfBasisReportItem = zod.infer<
  typeof BaseOfBasisReportItemScheme
>;

export const BasisReportItemConfigScheme =
  BaseOfBasisReportItemScheme.partial().extend({
    name: z.string().optional().nullable(),
    unit: z.string().optional().nullable(),
    unitRange: z.number().optional().nullable(),
    individualCustomers: z.boolean(),
    overallCustomers: z.boolean(),
    individual: z.boolean(),
    overall: z.boolean(),
    visible: z.boolean().optional().nullable(),
    order: z.number().optional().nullable(),
    workCategories: WorkCategoriesScheme.array(),
    workCarriers: CarriersScheme.array(),
    requestOrganizationIds: z.number().array(),
  });

export type BasisReportItemConfig = zod.infer<
  typeof BasisReportItemConfigScheme
>;

export const BasisReportItemConfigClientScheme =
  BasisReportItemConfigScheme.extend({
    id: z.number(),
    basisReportItemId: z.number(),
  });
export type BasisReportItemConfigClient = zod.infer<
  typeof BasisReportItemConfigClientScheme
>;

export const BasisReportItemScheme = BaseOfBasisReportItemScheme.extend({
  configs: BasisReportItemConfigScheme.array(),
});
export type BasisReportItem = zod.infer<typeof BasisReportItemScheme>;

export const BasisReportItemFormScheme = BasisReportItemScheme.partial().extend(
  {
    configs: BasisReportItemConfigScheme.partial().array(),
  }
);

export type BasisReportItemForm = zod.infer<typeof BasisReportItemFormScheme>;

export const BasisReportItemClientScheme = BasisReportItemScheme.extend({
  id: z.number(),
  groupName: z.string().optional(),
  configs: BasisReportItemConfigClientScheme.array(),
});

export type BasisReportItemClient = zod.infer<
  typeof BasisReportItemClientScheme
>;

export type BasisReportItemKey = keyof BasisReportItemConfig;

export const BasisReportItemGroupScheme = z.object({
  name: z.string(),
});

export const BasisReportItemGroupForm =
  BasisReportItemGroupScheme.partial().extend({
    id: z.number().optional(),
  });
export type BasisReportItemGroupForm = zod.infer<
  typeof BasisReportItemGroupForm
>;

export const BasisReportItemGroupClientScheme =
  BasisReportItemGroupScheme.extend({
    id: z.number(),
  });
export type BasisReportItemGroupClient = zod.infer<
  typeof BasisReportItemGroupClientScheme
>;

export const reportItemKeys = [
  // event docomo
  "catchCount", // キャッチ
  "customerCount", // 応対数
  "sitCount", // 着席数
  "visitors_count", // 来客数
  // "reception_count", // 応対数
  "suggestion_count", // 提案数
  "premiere", // プレミア
  "ocn", // OCN
  "eximo", // eximo,
  "irumo", // irumo,
  "reservation", // 予約
  "transport", // のりかえ
  "docomo_light", // ドコモ光
  "tvop", // TVOP
  "sky_perfect", // スカパー
  "home_5g", // home5G
  "gold", // gold
  "r", // R
  "migration", // マイグレ
  "bakuage", // 爆アゲ
  "d_type", // 他d系
  "affiliate", // アフィリエイト
  "coating", // コーティング
  "galaxy", // Galaxy

  // shop docomo

  // rounder docomo
  "training", // 研修数
  "ojt_triangle", // OJT

  // event softbank
  "smnp_with_unit", // SMNP端末付
  "smnp_only_sim", // SMNPsim単
  "s_new_with_sim", // S新規
  "ymnp_with_unit", // YMNP端末付
  "ymnp_only_sim", // YMNPsim単
  "y_new_with_unit", // Y新規
  "up_with_unit", // UP
  "light", // 光
  "light_tv", // 光TV
  "air", // Air
  "pay_c", // payC
  "pay_g", // payG
  "mnp_with_unit",
  "mnp_only_sim",
  "new_with_unit",

  // shop softabkn
  "setting_support", // 設定サポ
  "helpo", // HELPO

  // rounder softbank

  // ad yahoo
  "light_new", // 光新規
  "light_diversion", // 光転用
  "light_operator_change", // 光事業者変更（光事変）
  "light_service_change", // 光サービス変更（光サ変）
  "air_new", // Air新規
  "air_model_change", // Air機変
  "air_service_change", // Airサービス変更（Airサ変）
] as const;

export const ReportItemKeyScheme = z.enum(reportItemKeys);
export type ReportItemKey = zod.infer<typeof ReportItemKeyScheme>;

export const ReportItemScheme = z.object({
  key: z.string(),
  overall: z.boolean().optional(),
  customers: z.boolean().optional(),
  count: z.number(),
  basisReportItemId: z.number(),
});

export type ReportItem = zod.infer<typeof ReportItemScheme>;

export const ReportItemFormScheme = ReportItemScheme.partial({
  count: true,
});
export type ReportItemForm = zod.infer<typeof ReportItemFormScheme>;

// report item filter

export const ReportItemFilterKeyScheme = z.enum(reportItemKeys);
export type ReportItemFilterKey = zod.infer<typeof FilterKeyScheme>;

export const ReportItemFilterScheme = z.object({
  key: z.string(),
  value: z.boolean(),
  overall: z.boolean(),
  customers: z.boolean(),
  basisReportItemId: z.number(),
});

export type ReportItemFilter = zod.infer<typeof ReportItemFilterScheme>;

const reportTypes = ["overall", "individual"] as const;
const ReportTypeScheme = zod.enum(reportTypes);
type ReportType = zod.infer<typeof ReportTypeScheme>;

export const WorkReportScheme = z.object({
  id: z.number(),
  organizationMemberId: z.number(),
  organizationMemberName: z.string(),
  workRecordEntryId: z.number(),
  targetDate: DateTimeScheme,
  workPlaceName: z.string(),
  workPlaceShortName: z.string().optional(),
  eventPlaceName: z.string().optional(),
  eventPlaceShortName: z.string().optional(),
  workTypeCategories: OrganizationCategoryScheme.array(),
  reportItems: ReportItemScheme.array(),
  workId: z.number(),
  workCategory: WorkCategoriesScheme,
  workCarrier: CarriersScheme,
  requestOrganizationId: z.number().optional(),
});

export type WorkReport = zod.infer<typeof WorkReportScheme>;

export const WorkReportClientScheme = WorkReportScheme.extend({
  reportType: ReportTypeScheme,
  createdById: z.number(),
  createdByName: z.string(),
});

export type WorkReportClient = zod.infer<typeof WorkReportClientScheme>;

// CategoryCarrier

export type CategoryCarrierPair = {
  category: WorkCategory;
  carrier: Carrier;
};

export const categoryCarrierKeys = [
  "docomo_normal",
  "softbank_normal",
  "au_normal",
  "uq_normal",
  "docomo_rounder",
  "softbank_rounder",
  "au_rounder",
  "uq_rounder",
  "docomo_shop_helper",
  "softbank_shop_helper",
  "au_shop_helper",
  "uq_shop_helper",
  "docomo_event_helper",
  "softbank_event_helper",
  "au_event_helper",
  "uq_event_helper",
  "docomo_fulltime",
  "softbank_fulltime",
  "au_fulltime",
  "uq_fulltime",
  "docomo_consulting",
  "softbank_consulting",
  "au_consulting",
  "uq_consulting",
  "yahoo_light_ad",
  "docomo_sv",
  "softbank_sv",
  "au_sv",
  "yahoo_sv",
  "uq_sv",
  "docomo_sajit",
  "softbank_sajit",
  "au_sajit",
  "yahoo_sajit",
  "uq_sajit",
] as const;
export const CategoryCarrierKeyScheme = zod.enum(categoryCarrierKeys);
export type CategoryCarrierKey = zod.infer<typeof CategoryCarrierKeyScheme>;

export type CategoryCarrierReportItemsMap = {
  [key in CategoryCarrierKey]?: ReportItemForm[];
};

export const filterKeys = ["requestOrganization", "member"] as const;
export const FilterKeysScheme = zod.enum(filterKeys);

export type FilterKey = zod.infer<typeof FilterKeysScheme>;

export const hideMapBaseKeys = [
  "requestOrganization",
  "workPlace",
  "workCategory",
  "workerNumber",
  "workerNames",
] as const;

export const HideMapBaseKeyScheme = zod.enum(hideMapBaseKeys);
export type HideMapBaseKey = zod.infer<typeof HideMapBaseKeyScheme>;

export type HideMapBase = Partial<Record<HideMapBaseKey, boolean>>;

export const hideMapKeys = ["customers", "indivi", "overall"] as const;
export const HideMapKeyScheme = zod.enum(hideMapKeys);
export type HideMapKey = zod.infer<typeof HideMapKeyScheme>;
export type HideMap = Partial<
  Record<
    CategoryCarrierKey,
    Partial<Record<HideMapKey, Record<number, boolean>>>
  >
>;

export type HideMapConfigForm = {
  name: string;
  configMap: {
    hideMap: HideMap;
    hideMapBase: HideMapBase;
  };
};

export type HideMapConfig = {
  id: number;
  name: string;
  config_map: {
    hide_map: HideMap;
    hide_map_base: HideMapBase;
  };
};
