import zod from "zod";
import { DateScheme, DateTimeScheme, z } from "/@/modules/zodUtils";
import { PhotoDataObjectScheme } from "/@/types/axios.d";
import { WorkCategoriesScheme } from "/@/types/workCategories.d";
import { CarriersScheme } from "/@/types/carriers.d";
import {
  ReportItemFormScheme,
  ReportItemKeyScheme,
} from "/@/types/workReport.d";
import { OrganizationCategoryScheme } from "/@/types/category.d";
import { RestTimeScheme } from "/@/types/restTime.d";
import { BodyTemperatureClientScheme } from "/@/types/bodyTemperature.d";
import { WorkTaskClientScheme, WorkTaskFormScheme } from "/@/types/workTask.d";

// TODO: 実装のミスが考えられる
// Client 用の Scheme と、Form 用のスキームをここでは分けて考えられていない
//

// report

export const reportTypes = ["overall", "individual"] as const;
export const ReportTypeScheme = zod.enum(reportTypes);
export type ReportType = zod.infer<typeof ReportTypeScheme>;

export const WorkRecordEntryReportScheme = z.object({
  id: z.number().optional(),
  reportType: ReportTypeScheme,
  reportItems: ReportItemFormScheme.array(),
  targetDate: DateTimeScheme.optional(),
  workPlaceId: z.number().optional(),
  workTypeId: z.number().optional(),
  workId: z.number().optional(),
  workCategory: WorkCategoriesScheme.optional(),
});

export const WorkRecordEntryReportFormScheme =
  WorkRecordEntryReportScheme.partial();
export type WorkRecordEntryReportForm = zod.infer<
  typeof WorkRecordEntryReportFormScheme
>;

export const WorkRecordentryReportClientScheme =
  WorkRecordEntryReportScheme.extend({
    id: z.number(),
    workId: z.number(),
    workPlaceId: z.number(),
    workTypeId: z.number(),
    workCategory: WorkCategoriesScheme,
    eventPlaceId: z.number().optional(),
    createdById: z.number(),
    targetDate: DateScheme,
    workRecordEntryId: z.number(),
  });

export type WorkRecordEntryReportClient = zod.infer<
  typeof WorkRecordentryReportClientScheme
>;

// Work Record Entry

export const WorkRecordEntryEntranceNeedEnterLeaveReportScheme = z.object({
  // 一旦業務を始めるのに案件や計画は必須にするが、一般的には計画無しで業務を行うことも考えられる
  workRecordId: z.number().optional(),
  workScheduleId: z.number(),
  workId: z.number().optional(),
  workPlaceId: z.number().optional(),
  startLat: z.number(),
  startLng: z.number(),
  startPhoto: PhotoDataObjectScheme,
});

export const WorkRecordEntryEntranceScheme =
  WorkRecordEntryEntranceNeedEnterLeaveReportScheme.partial({
    startLat: true,
    startLng: true,
    startPhoto: true,
  });

export const WorkRecordEntryEntranceFormScheme =
  WorkRecordEntryEntranceScheme.partial();

export type WorkRecordEntryEntranceForm = zod.infer<
  typeof WorkRecordEntryEntranceFormScheme
>;

export const WorkRecordEntryExitNeedEnterLeaveReportScheme = z.object({
  id: z.number(),
  finishLat: z.number(),
  finishLng: z.number(),
  finishPhoto: PhotoDataObjectScheme,
  workRecordEntryReports: WorkRecordEntryReportFormScheme.array(),
  workTasks: WorkTaskFormScheme.array().optional(),
});

export const WorkRecordEntryExitScheme =
  WorkRecordEntryExitNeedEnterLeaveReportScheme.partial({
    finishLat: true,
    finishLng: true,
    finishPhoto: true,
  });

export const WorkRecordEntryExitFormScheme =
  WorkRecordEntryExitScheme.partial();

export type WorkRecordEntryExitForm = zod.infer<
  typeof WorkRecordEntryExitFormScheme
>;

export const WorkRecordEntryScheme = WorkRecordEntryEntranceScheme.merge(
  WorkRecordEntryExitScheme
).extend({
  startAt: DateTimeScheme,
  finishAt: DateTimeScheme.optional(),
});

export type WorkRecordEntry = zod.infer<typeof WorkRecordEntryScheme>;

// submit location

export const SubmitLocationScheme = z.object({
  lat: z.number(),
  lng: z.number(),
  photo: PhotoDataObjectScheme,
});

export const SubmitLocationFormScheme = SubmitLocationScheme.partial();

export type SubmitLocationForm = zod.infer<typeof SubmitLocationFormScheme>;

export const CurrentLocationClientScheme = z.object({
  lat: z.number(),
  lng: z.number(),
  photoUrl: z.string(),
  createdAt: DateTimeScheme,
});

export type CurrentLocationClient = zod.infer<
  typeof CurrentLocationClientScheme
>;

// entry

export const WorkRecordEntryClientScheme = WorkRecordEntryScheme.extend({
  id: z.number(),
  workCategory: WorkCategoriesScheme,
  workCarrier: CarriersScheme,
  workRequestOrganizaitonName: z.string(),
  workTypeName: z.string(),
  workTypeCategories: OrganizationCategoryScheme.array(),
  workPlaceName: z.string(),
  workPlaceShortName: z.string(),
  eventPlaceName: z.string().optional(),
  eventPlaceShortName: z.string().optional(),
  workRequestOrganizationName: z.string(),
  workRequestOrganizationId: z.number(),
  workScheduleWorkTypeName: z.string(),
  workScheduleWorkPlaceName: z.string(),
  workScheduleEventPlaceName: z.string().optional(),
  workScheduleEventPlaceShortName: z.string().optional(),
  workScheduleWorkCategory: WorkCategoriesScheme,
  workScheduleWorkCarrier: CarriersScheme,
  workScheduleMeetingat: DateTimeScheme.optional(),
  workScheduleStartAt: DateTimeScheme,
  workScheduleFinishAt: DateTimeScheme,
  workScheduleCanceled: z.boolean(),
  workScheduleCurrentCount: z.number(),
  startPhotoUrl: z.string().optional(),
  finishPhotoUrl: z.string().optional(),
  workReports: WorkRecordentryReportClientScheme.array(),
  //workTasks: WorkTaskScheme.array(),
});

export type WorkRecordEntryClient = zod.infer<
  typeof WorkRecordEntryClientScheme
>;

// Work Record

export const WorkRecordStartNeedEnterLeaveReportScheme = z.object({
  workRecordEntry: WorkRecordEntryEntranceNeedEnterLeaveReportScheme,
});

export const WorkRecordStartScheme = z.object({
  // 業務を始める時に、同時に入店をしない場合を考えるかどうか
  // 業務開始 -> 数刻後入店 -> 退店 -> 業務終了 というのはよくあるように感じるが、どうするべきか
  // 通信業界特有？
  workRecordEntry: WorkRecordEntryEntranceScheme.optional(),
});

export const WorkRecordStartFormScheme = z.object({
  workRecordEntry: WorkRecordEntryEntranceFormScheme.optional(),
});

export type WorkRecordStartForm = zod.infer<typeof WorkRecordStartFormScheme>;

export const WorkRecordFinishNeedEnterLeaveReportScheme = z.object({
  id: z.number(),
  workRecordEntries: WorkRecordEntryExitNeedEnterLeaveReportScheme.array(),
  restTimeEntries: RestTimeScheme.array(),
});

export const WorkRecordFinishScheme = z.object({
  id: z.number(),
  workRecordEntries: WorkRecordEntryExitScheme.array().optional(),
  restTimeEntries: RestTimeScheme.array(),
});

export const WorkRecordFinishFormScheme = z.object({
  id: z.number(),
  workRecordEntries: WorkRecordEntryExitFormScheme.array().optional(),
  restTimeEntries: RestTimeScheme.array(),
});

export type WorkRecordFinishForm = zod.infer<typeof WorkRecordFinishFormScheme>;

export const WorkRecordScheme = z.object({
  id: z.number(),
  organizationMemberId: z.number(),
  startAt: DateTimeScheme,
  finishAt: DateTimeScheme.optional(),
  workRecordEntries: WorkRecordEntryScheme.array(),
  restTimeEntries: RestTimeScheme.array(),
});

export type WorkRecord = zod.infer<typeof WorkRecordScheme>;

export const WorkRecordClientScheme = WorkRecordScheme.extend({
  workRecordEntries: WorkRecordEntryClientScheme.array(),
  restTimeSchedules: RestTimeScheme.array(),
});

export type WorkRecordClient = zod.infer<typeof WorkRecordClientScheme>;

// Daily

export const WorkRecordDailyScheme = z.object({
  organizationMemberId: z.number(),
  organizationMemberName: z.string(),
  targetDate: DateScheme,
  workRecords: WorkRecordClientScheme.array(),
  workTasks: WorkTaskClientScheme.array(),
  bodyTemperatures: BodyTemperatureClientScheme.array(),
  currentLocations: CurrentLocationClientScheme.array(),
});

export type WorkRecordDaily = zod.infer<typeof WorkRecordDailyScheme>;
