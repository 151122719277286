<template>
  <div>
    <SelectButton
      :model-value="selectedCarriers"
      :options="carrierItems"
      option-label="name"
      option-value="value"
      form-id="select-carrier"
      class="d-flex mb-2 flex-wrap"
      multiple
      @update:model-value="$emit('update:selectedCarriers', $event)"
    />
    <SelectButton
      :model-value="selectedPrefectures"
      :options="prefectureItems"
      form-id="select-work-category"
      option-label="name"
      option-value="value"
      class="d-flex mb-2 flex-wrap"
      multiple
      @update:model-value="$emit('update:selectedPrefectures', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { Carrier, Prefecture } from "/@/types";
import { translater } from "/@/modules/string";
import SelectButton from "primevue/selectbutton";

const props = defineProps<{
  selectedCarriers: Carrier[];
  selectedPrefectures: Prefecture[];
  carriers: Carrier[] | readonly Carrier[];
  prefectures: Prefecture[] | readonly Prefecture[];
}>();

defineEmits<{
  (e: "update:selectedPrefectures", places: string[]): void;
  (e: "update:selectedCarriers", carriers: Carrier[]): void;
}>();

const carrierItems = computed(() => {
  return props.carriers.map((c) => ({
    name: translater(c),
    value: c,
  }));
});

const prefectureItems = computed(() => {
  return props.prefectures.map((p) => ({
    name: translater(p),
    value: p,
  }));
});
</script>

<style scoped></style>
