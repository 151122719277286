<template>
  <div>
    <div class="table-wrapper">
      <table
        class="table table-fixed table-bordered table-striped small table-work-schedules"
      >
        <thead>
          <tr class="sticky">
            <th class="text-center date-th">日付</th>
            <EditorTableHeaderItem
              title="依頼元"
              with-clipboard
              @copy-to="
                $emit('copyTo', {
                  key: 'workPlaceId',
                  workCategory: ['event_helper'],
                })
              "
            />
            <EditorTableHeaderItem
              title="会場"
              with-clipboard
              @copy-to="
                $emit('copyTo', {
                  key: 'eventPlaceId',
                  workCategory: ['event_helper'],
                })
              "
            />
            <EditorTableHeaderItem
              title="集合場所"
              with-clipboard
              @copy-to="
                $emit('copyTo', {
                  key: 'meetingPlace',
                  workCategory: ['event_helper'],
                })
              "
            />
            <EditorTableHeaderItem
              title="業務種別"
              with-clipboard
              @copy-to="
                $emit('copyTo', {
                  key: 'workTypeId',
                  workCategory: ['event_helper'],
                })
              "
            />
            <EditorTableHeaderItem
              title="必要人数"
              with-clipboard
              class="th-need-count"
              @copy-to="
                $emit('copyTo', {
                  key: 'needCount',
                  workCategory: ['event_helper'],
                })
              "
            />
            <EditorTableHeaderItem title="割当済人員" />
            <!-- EditorTableHeaderItem
              title="初回集合"
              with-clipboard
              @copy-to="
                $emit('copyTo', {
                  key: 'firstMeetingAt',
                  workCategory: ['event_helper'],
                })
              "
            / -->
            <EditorTableHeaderItem
              title="連日集合"
              with-clipboard
              @copy-to="
                $emit('copyTo', {
                  key: 'meetingAt',
                  workCategory: ['event_helper'],
                })
              "
            />
            <EditorTableHeaderItem
              title="開始時間"
              with-clipboard
              @copy-to="
                $emit('copyTo', {
                  key: 'startAt',
                  workCategory: ['event_helper'],
                })
              "
            />
            <EditorTableHeaderItem
              title="終了時間"
              @copy-to="
                $emit('copyTo', {
                  key: 'finishAt',
                  workCategory: ['event_helper'],
                })
              "
            />
            <EditorTableHeaderItem
              v-if="!hideSubmitConfigs"
              title="体温の提出"
              with-clipboard
              @copy-to="
                $emit('copyTo', {
                  key: 'needBodyTemperature',
                  workCategory: ['event_helper'],
                })
              "
            />
            <EditorTableHeaderItem
              v-if="!hideSubmitConfigs"
              title="抗原検査の提出 (0は不要)"
              with-clipboard
              @copy-to="
                $emit('copyTo', {
                  key: 'needBodyTemperature',
                  workCategory: ['event_helper'],
                })
              "
            />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="ws of workSchedules"
            :key="`work-schedule-${ws.targetDate}`"
          >
            <td>
              <div class="d-flex align-items-center">
                <BasicButton
                  variant="primary"
                  auto
                  class="me-2"
                  icon="pi pi-plus"
                  @click="$emit('addSchedule', ws.idx)"
                />

                <BasicButton
                  variant="danger"
                  auto
                  class="me-2"
                  icon="pi pi-trash"
                  @click="deleteSchedule(ws)"
                />

                <span class="text-nowrap">
                  {{ basicFormatter(ws.targetDate, "onlyDateDay") }}
                </span>
              </div>
            </td>
            <td>
              <Dropdown
                :model-value="ws.workPlaceId"
                :options="workPlaces"
                option-label="name"
                option-value="id"
                placeholder="依頼店舗を選択"
                :form-id="`work-place-selector-${ws.idx}`"
                class="w-100"
                :class="{
                  'p-invalid': !ws.workPlaceId && ws.workPlaceId !== 0,
                }"
                filter
                auto-filter-focus
                empty-filter-message="候補が見つかりませんでした"
                @update:model-value="
                  updateSchedule('workPlaceId', $event, ws.idx)
                "
              />
            </td>
            <td>
              <Dropdown
                :model-value="ws.eventPlaceId"
                :options="selectedWorkPlaceEventPlacesMap[ws.idx]"
                option-label="name"
                option-value="id"
                placeholder="イベント会場を選択"
                :form-id="`event-place-selector-${ws.idx}`"
                class="w-100"
                :class="{
                  'p-invalid': !ws.eventPlaceId && ws.eventPlaceId !== 0,
                }"
                filter
                auto-filter-focus
                empty-filter-message="候補が見つかりませんでした"
                @update:model-value="
                  updateSchedule('eventPlaceId', $event, ws.idx)
                "
              />
            </td>
            <td>
              <div class="d-flex align-items-center">
                <InputForm
                  :value="ws.meetingPlace || ''"
                  is-valid
                  @update:value="updateSchedule('meetingPlace', $event, ws.idx)"
                />
              </div>
            </td>
            <td class="td-work-type">
              <ObjectSelector
                :value="ws.workTypeId"
                :items="workTypes"
                value-key="id"
                show-key="name"
                :is-valid="!!ws.workTypeId"
                no-auto-select
                :form-id="`work-type-selector-${ws.idx}`"
                @update:value="updateSchedule('workTypeId', $event?.id, ws.idx)"
              />
            </td>
            <td class="td-need-count">
              <InputForm
                :value="ws.needCount"
                form-type="number"
                :min="0"
                :is-valid="!!ws.needCount || ws.needCount === 0"
                @update:value="
                  updateSchedule('needCount', parseInt($event), ws.idx)
                "
              />
            </td>
            <td>
              {{ assignedMemberNames(ws) }}
            </td>
            <!-- td class="time-form">
              <TimeForm
                :value="ws.firstMeetingAt"
                teleport-center
                :start-time="{ hours: 9, minutes: 30 }"
                is-valid
                @update:value="
                  $emit('updateTime', {
                    key: 'firstMeetingAt',
                    at: $event,
                    idx: ws.idx,
                  })
                "
                @cleared="
                  $emit('updateTime', {
                    key: 'firstMeetingAt',
                    at: undefined,
                    idx: ws.idx,
                  })
                "
              />
            </td -->
            <td class="time-form">
              <TimeForm
                :value="ws.meetingAt"
                teleport-center
                :start-time="{ hours: 9, minutes: 50 }"
                is-valid
                @update:value="
                  $emit('updateTime', {
                    key: 'meetingAt',
                    at: $event,
                    idx: ws.idx,
                  })
                "
                @cleared="
                  $emit('updateTime', {
                    key: 'meetingAt',
                    at: undefined,
                    idx: ws.idx,
                  })
                "
              />
            </td>
            <td class="time-form">
              <TimeForm
                :value="ws.startAt"
                :is-valid="!!ws.startAt"
                teleport-center
                @update:value="
                  $emit('updateTime', {
                    key: 'startAt',
                    at: $event,
                    idx: ws.idx,
                  })
                "
                @cleared="
                  $emit('updateTime', {
                    key: 'startAt',
                    at: undefined,
                    idx: ws.idx,
                  })
                "
              />
            </td>
            <td class="time-form">
              <TimeForm
                :value="ws.finishAt"
                :is-valid="!!ws.finishAt"
                :min-time="ws.startAt"
                :start-time="atToTimeObj(ws.startAt)"
                teleport-center
                @update:value="
                  $emit('updateTime', {
                    key: 'finishAt',
                    at: $event,
                    idx: ws.idx,
                  })
                "
                @cleared="
                  $emit('updateTime', {
                    key: 'finishAt',
                    at: undefined,
                    idx: ws.idx,
                  })
                "
              />
            </td>
            <td v-if="!hideSubmitConfigs">
              <CheckForm
                label=""
                :value="ws.needBodyTemperature"
                :unchecked-value="false"
                :form-id="`need-body-tempreture-${ws.idx}`"
                is-valid
                @update:value="
                  updateSchedule('needBodyTemperature', $event, ws.idx)
                "
              />
            </td>
            <td v-if="!hideSubmitConfigs">
              <InputForm
                :value="ws.needAntibodyTestWithin"
                :form-id="`need-antibody-test-${ws.idx}`"
                is-valid
                @update:value="
                  updateSchedule(
                    'needAntibodyTestWithin',
                    $event || $event === '0' ? parseInt($event) : 0,
                    ws.idx
                  )
                "
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import Dropdown from "primevue/dropdown";
import { ref } from "vue";
import { atToTimeObj } from "/@/modules/datepicker";
import { basicFormatter } from "/@/modules/luxon";
import {
  TimeForm,
  ObjectSelector,
  BasicButton,
  InputForm,
  CheckForm,
} from "/@/vue/components/Atom";
import { EditorTableHeaderItem } from "/@/vue/components/Organisms";
import {
  WorkCategory,
  WorkPlace,
  WorkScheduleForm,
  WorkScheduleWithAdditionalInfo,
  WorkTypeClient,
  EventPlaceClient,
  OrganizationMember,
} from "/@/types";
import { computed } from "vue";

const props = defineProps<{
  workSchedules: WorkScheduleWithAdditionalInfo[];
  workPlaces: WorkPlace[];
  workTypes: WorkTypeClient[];
  eventPlaces: EventPlaceClient[];
  organizationMembers: OrganizationMember[];
}>();

const emit = defineEmits<{
  (
    e: "copyTo",
    params: { key: keyof WorkScheduleForm; workCategory: WorkCategory[] }
  ): void;
  (e: "addSchedule", idx: number): void;
  (e: "deleteSchedule", idx: number): void;
  (
    e: "updateTime",
    params: { key: "startAt" | "finishAt"; at: string | undefined; idx: number }
  ): void;

  (
    e: "updateSchedule",
    params: { key: keyof WorkScheduleForm; value: any; idx: number }[]
  ): void;
}>();

const hideSubmitConfigs = false;

// members

function assignedMemberNames(workSchedule: WorkScheduleWithAdditionalInfo) {
  if (!workSchedule.assignedMembers) return "";

  return workSchedule.assignedMembers
    ?.map((member) => member.name?.split(" ")[0])
    .join("・");
}

// work place

type EventPlaceMap = Partial<Record<number, { id: number; name: string }[]>>;

const selectedWorkPlaceEventPlacesMap = computed<EventPlaceMap>(() => {
  let m: EventPlaceMap = {};

  props.workSchedules.forEach((ws) => {
    if (!ws.idx && ws.idx !== 0) return;
    m[ws.idx] = [];

    const targetWorkPlace = props.workPlaces.find(
      (wp) => wp.id === ws.workPlaceId
    );

    if (targetWorkPlace) {
      m[ws.idx] = targetWorkPlace.eventPlaces.length
        ? targetWorkPlace.eventPlaces
        : props.eventPlaces;
    } else {
      m[ws.idx] = props.eventPlaces;
    }
  });

  return m;
});

// update

function updateSchedule(key: keyof WorkScheduleForm, value: any, idx: number) {
  let params: { key: keyof WorkScheduleForm; value: any; idx: number }[] = [];

  params.push({
    key,
    value,
    idx,
  });

  emit("updateSchedule", params);
}

function deleteSchedule(schedule: WorkScheduleWithAdditionalInfo) {
  if (schedule.assignedMemberIds?.length) {
    alert("既にメンバーが割り当てられているため削除できません");
    return;
  }

  if (schedule.idx !== 0 && !schedule.idx) return;

  emit("deleteSchedule", schedule.idx);
}
</script>

<style scoped lang="scss">
.table-wrapper {
  overflow: scroll;
  max-height: 70vh;

  .th-need-count,
  .td-need-count {
    max-width: 100px;
    min-width: 100px;
  }

  .td-work-type {
    max-width: 130px;
    min-width: 130px;
  }

  .time-form {
    width: 100px;
  }
}
</style>
