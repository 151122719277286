<template>
  <AttendanceTableMemberInfo
    :member="member"
    :selected-date="selectedDate"
    class="mb-3"
  />

  <div class="d-flex mb-3">
    <AttendanceTableLaborInfo
      :member="member"
      :selected-date="selectedDate"
      class="me-2 mb-3"
    />

    <AttendanceTableSummaryInfo
      v-if="member"
      class="w-100"
      :member="member"
      :records="workRecords"
      :schedules="targetMemberSchedules"
      :show-record-time="showRecordTime"
      :selected-date="selectedDate"
    />
  </div>

  <div class="table-wrapper">
    <table
      class="table table-responsive table-bordered table-striped small bg-white table-sm"
    >
      <thead>
        <tr class="sticky">
          <th v-if="ownerMode" class="one-char" rowspan="2">編集</th>
          <th class="one-char sticky" rowspan="2" colspan="2">日付</th>

          <th rowspan="2">予定</th>
          <th rowspan="2">勤怠</th>
          <th rowspan="2">開始</th>
          <th rowspan="2">終了</th>
          <th rowspan="2">休憩</th>
          <th colspan="5">勤怠時間</th>
          <th rowspan="2">備考</th>
        </tr>
        <tr class="sticky">
          <th>実勤務</th>
          <th>休日勤務</th>
          <th>遅刻早退</th>
          <th>残業</th>
          <th>深夜残業</th>
        </tr>
      </thead>
      <tbody>
        <AttendanceTableRow
          v-for="d in dates"
          :key="`header-${d.dateKey}`"
          :d="d"
          :records="workRecords"
          :schedules="targetMemberSchedules"
          :show-record-time="showRecordTime"
          :owner-mode="ownerMode"
          @open-as-worked-modal="handleAsWorked"
        />
        <tr>
          <td v-if="ownerMode"></td>
          <td colspan="2">集計</td>
          <td>{{ scheduleDaysCount }}</td>
          <td>{{ totalWorkingDays }}</td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{ totalWorkingHours }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";
import { computed, ref } from "vue";
import {
  basicFormatter,
  countDaysInIsoArray,
  createDateForCalendars,
} from "/@/modules/luxon";
import { getTotalWorkingHours } from "/@/modules/workRecords";
import {
  OrganizationMember,
  DateMap,
  DateForCalendar,
  WorkRecordClient,
  OrganizationMemberSchedulePlan,
} from "/@/types";

import AttendanceTableMemberInfo from "./AttendanceTableMemberInfo.vue";
import AttendanceTableLaborInfo from "./AttendanceTableLaborInfo.vue";
import AttendanceTableSummaryInfo from "./AttendanceTableSummaryInfo.vue";
import AttendanceTableRow from "./AttendanceTableRow.vue";

const props = defineProps<{
  member: OrganizationMember;
  selectedDate: DateMap;
  workRecords: WorkRecordClient[];
  schedules: OrganizationMemberSchedulePlan[];
  showRecordTime?: boolean;
  ownerMode?: boolean;
}>();

const emit = defineEmits<{
  (
    e: "asWorked",
    prms: { organizationMemberId: number; targetDate: string }
  ): void;
}>();

const dates = computed<DateForCalendar[]>(() => {
  if (!props.selectedDate) {
    return [];
  }

  return createDateForCalendars(props.selectedDate);
});

const targetMemberSchedules = computed(() => {
  return props.schedules.filter(
    (schedule) => schedule.organizationMemberId === props.member.id
  );
});

const doneMemberSchedules = computed(() => {
  return targetMemberSchedules.value.filter((schedule) => {
    return schedule.workSchedules?.every((wSchedule) => wSchedule.done);
  });
});

const scheduleDaysCount = computed(() => {
  return _.uniq(
    targetMemberSchedules.value.map((schedule) => schedule.targetDate)
  ).length;
});

const totalWorkingDays = computed(() => {
  return countDaysInIsoArray(props.workRecords.map((record) => record.startAt));
});

const totalWorkingHours = computed(() => {
  if (props.showRecordTime) {
    const entries = props.workRecords
      .map((record) => record.workRecordEntries)
      .flat();

    const restTimes = props.workRecords
      .map((record) => record.restTimeEntries)
      .flat();

    return getTotalWorkingHours(entries, restTimes);
  } else {
    const workSchedules = doneMemberSchedules.value
      .map((schedule) => schedule.workSchedules)
      .flat();
    const restTimeSchedules = doneMemberSchedules.value
      .map((schedule) => schedule.restTimeSchedules)
      .flat();

    return getTotalWorkingHours(workSchedules, restTimeSchedules);
  }
});

// edit modal

// edit modal

const asWorkedModal = ref();
const asWorkedTargetDate = ref<string>();

function openAsWorkedModal(dateKey: string) {
  asWorkedTargetDate.value = dateKey;
  asWorkedModal.value.openModal();
}

function closeAsWorkedModal() {
  asWorkedModal.value.closeModal();
}

function handleAsWorked(dateKey: string) {
  if (
    window.confirm(
      `${basicFormatter(
        dateKey,
        "onlyDateDay"
      )}を業務行ったように変更します。よろしいですか？`
    )
  ) {
    emit("asWorked", {
      organizationMemberId: props.member.id,
      targetDate: dateKey,
    });
  }

  //closeAsWorkedModal();
}
</script>

<style scoped lang="scss">
.table-wrapper {
  overflow-x: scroll;
  max-height: 100%;

  tr.sticky:nth-child(2) {
    th {
      top: 20.2px;
    }
  }
}
</style>
