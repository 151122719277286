<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="23.6"
    height="21.3"
    viewBox="0 0 23.6 21.3"
  >
    <defs>
      <filter
        id="長方形_4"
        x="0"
        y="3.5"
        width="23.6"
        height="17.8"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="楕円形_13"
        x="6.5"
        y="7"
        width="10.6"
        height="10.8"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="0.1" result="blur-2" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-2" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="camera_icon_blue" transform="translate(1.8)">
      <g
        id="グループ_23"
        data-name="グループ 23"
        transform="translate(-77 -214)"
      >
        <g transform="matrix(1, 0, 0, 1, 75.2, 214)" filter="url(#長方形_4)">
          <g
            id="長方形_4-2"
            data-name="長方形 4"
            transform="translate(1.8 5)"
            fill="#fff"
            stroke="#4285f5"
            stroke-linejoin="round"
            stroke-width="1.5"
          >
            <rect width="20" height="14" stroke="none" />
            <rect x="-0.75" y="-0.75" width="21.5" height="15.5" fill="none" />
          </g>
        </g>
      </g>
      <g transform="matrix(1, 0, 0, 1, -1.8, 0)" filter="url(#楕円形_13)">
        <g
          id="楕円形_13-2"
          data-name="楕円形 13"
          transform="translate(6.8 7)"
          fill="#fff"
          stroke="#4285f5"
          stroke-width="1.5"
        >
          <circle cx="5" cy="5" r="5" stroke="none" />
          <circle cx="5" cy="5" r="4.25" fill="none" />
        </g>
      </g>
      <g
        id="長方形_14"
        data-name="長方形 14"
        transform="translate(13)"
        fill="#fff"
        stroke="#4285f5"
        stroke-width="1.5"
      >
        <rect width="6" height="5" rx="1" stroke="none" />
        <rect
          x="0.75"
          y="0.75"
          width="4.5"
          height="3.5"
          rx="0.25"
          fill="none"
        />
      </g>
    </g>
  </svg>
</template>
