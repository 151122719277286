<template>
  <template v-for="cck in categoryCarrierKeys" :key="`body-${cck}`">
    <template v-for="(item, idx) in customersItems[cck]" :key="item.key">
      <th
        v-if="showEditMode || !hideMap?.[cck]?.customers?.[idx]"
        :class="`bg-${categoryCarrierColor(
          cck
        )} text-white text-nowrap th-header-bottom-item`"
      >
        {{ shorterMode ? getShortKey(item) || item.key : item.key }}
      </th>
    </template>

    <template v-for="(item, idx) of indiviItems[cck]" :key="item.key">
      <th
        v-if="showEditMode || !hideMap?.[cck]?.indivi?.[idx]"
        :class="`bg-${categoryCarrierColor(
          cck
        )} text-white text-nowrap th-header-bottom-item`"
      >
        {{ shorterMode ? getShortKey(item) || item.key : item.key }}
      </th>
    </template>

    <template v-for="(item, idx) of overallItems[cck]" :key="item.key">
      <th
        v-if="showEditMode || !hideMap?.[cck]?.overall?.[idx]"
        :class="`bg-${categoryCarrierColor(
          cck
        )} text-white text-nowrap th-header-bottom-item`"
      >
        {{ shorterMode ? getShortKey(item) || item.key : item.key }}
      </th>
    </template>
  </template>
</template>

<script setup lang="ts">
import { categoryCarrierColor } from "/@/modules/workReport";
import {
  BasisReportItemClient,
  CategoryCarrierKey,
  CategoryCarrierReportItemsMap,
  HideMap,
  ReportItemForm,
} from "/@/types";

const props = defineProps<{
  categoryCarrierKeys: CategoryCarrierKey[];
  indiviItems: CategoryCarrierReportItemsMap;
  customersItems: CategoryCarrierReportItemsMap;
  overallItems: CategoryCarrierReportItemsMap;
  basisReportItems: BasisReportItemClient[];
  hideMap: HideMap;
  showEditMode?: boolean;
  shorterMode?: boolean;
}>();

function getShortKey(item: ReportItemForm) {
  if (!props.basisReportItems) return undefined;

  const targetBasis = props.basisReportItems.find(
    (b) => b.id === item.basisReportItemId
  );

  return targetBasis?.shortName || undefined;
}
</script>

<style scoped>
.th-header-bottom-item {
  width: 30px;
  max-width: 30px;
}
</style>
