import Index from "./Index.vue";
import SignIn from "./SignIn.vue";
import SignUp from "./SignUp.vue";
import Home from "./Home.vue";
import { User, Admin } from "/@/types";

import { Router as AdminRouter, Root as AdminRoot } from "./Admin";
import {
  Router as AntibodyTestsRouter,
  Root as AntibodyTestsRoot,
} from "./AntibodyTest";
import {
  Router as AttendancesRouter,
  Root as AttendancesRoot,
} from "./Attendances";
import {
  Router as BodyTemperaturesRouter,
  Root as BodyTemperaturesRoot,
} from "./BodyTemperature";
import {
  Router as EditRequestsRouter,
  Root as EditRequestsRoot,
} from "./EditRequests";
import {
  Router as LaborInfosRouter,
  Root as LaborInfosRoot,
} from "./LaborInfo";
import {
  Router as ManagementLabelsRouter,
  Root as ManagementLabelsRoot,
} from "./ManagementLabels";
import {
  Router as MeetingTargetsRouter,
  Root as MeetingTargetsRoot,
} from "./MeetingTargets";
import {
  Router as OrganizationEventsRouter,
  Root as OrganizationEventsRoot,
} from "./OrganizationEvents";
import {
  Router as OrganizationsRouter,
  Root as OrganizationsRoot,
} from "./Organizations";
import { Router as PlansRouter, Root as PlansRoot } from "./Plans";
import { Router as PortalRouter, Root as PortalRoot } from "./Portal";
import {
  Router as TaskManagersRouter,
  Root as TaskManagersRoot,
} from "./TaskManagers";
import { Router as UsersRouter, Root as UsersRoot } from "./Users";
import {
  Router as WorkRecordDailiesRouter,
  Root as WorkRecordDailiesRoot,
} from "./WorkRecordDailies";
import {
  Router as WorkRecordsRouter,
  Root as WorkRecordsRoot,
} from "./WorkRecords";
import {
  Router as WorkReportsRouter,
  Root as WorkReportsRoot,
} from "./WorkReports";
import { Router as WorksRouter, Root as WorksRoot } from "./Works";
import { Router as WorkTasksRouter, Root as WorkTasksRoot } from "./WorkTasks";

export const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
  },
  {
    path: "/sign_up",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/sign_in",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },

  // children
  {
    path: "/admin",
    component: AdminRoot,
    children: [...AdminRouter],
  },
  {
    path: "/antibody_tests",
    component: AntibodyTestsRoot,
    children: [...AntibodyTestsRouter],
  },
  {
    path: "/attendances",
    component: AttendancesRoot,
    children: [...AttendancesRouter],
  },
  {
    path: "/body_temperatures",
    component: BodyTemperaturesRoot,
    children: [...BodyTemperaturesRouter],
  },
  {
    path: "/edit_requests",
    component: EditRequestsRoot,
    children: [...EditRequestsRouter],
  },
  {
    path: "/labor_infos",
    component: LaborInfosRoot,
    children: [...LaborInfosRouter],
  },
  {
    path: "/management_labels",
    component: ManagementLabelsRoot,
    children: [...ManagementLabelsRouter],
  },
  {
    path: "/meeting_targets",
    component: MeetingTargetsRoot,
    children: [...MeetingTargetsRouter],
  },
  {
    path: "/organization_events",
    component: OrganizationEventsRoot,
    children: [...OrganizationEventsRouter],
  },
  {
    path: "/organizations",
    component: OrganizationsRoot,
    children: [...OrganizationsRouter],
  },
  {
    path: "/plans",
    component: PlansRoot,
    children: [...PlansRouter],
  },
  {
    path: "/portal",
    component: PortalRoot,
    children: [...PortalRouter],
  },
  {
    path: "/task_managers",
    component: TaskManagersRoot,
    children: [...TaskManagersRouter],
  },
  {
    path: "/users",
    component: UsersRoot,
    children: [...UsersRouter],
  },
  {
    path: "/work_record_dailies",
    component: WorkRecordDailiesRoot,
    children: [...WorkRecordDailiesRouter],
  },
  {
    path: "/work_records",
    component: WorkRecordsRoot,
    children: [...WorkRecordsRouter],
  },
  {
    path: "/work_reports",
    component: WorkReportsRoot,
    children: [...WorkReportsRouter],
  },
  {
    path: "/works",
    component: WorksRoot,
    children: [...WorksRouter],
  },
  {
    path: "/work_tasks",
    component: WorkTasksRoot,
    children: [...WorkTasksRouter],
  },
];

export const dontNeedLoginList = ["SignUp", "SignIn", "Index", "PasswordReset"];

function dontNeedLogin(name: string): boolean {
  return dontNeedLoginList.includes(name) || name.startsWith("Admins");
}

export function isNeedLogin(name: string, user?: User): boolean {
  return !dontNeedLogin(name) && !user;
}

function needAdminLogin(name: string): boolean {
  return name.startsWith("Admins") && name != "AdminsSignIn";
}

export function isNeedAdminLogin(name: string, admin?: Admin): boolean {
  return needAdminLogin(name) && !admin;
}
