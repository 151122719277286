<template>
  <div>
    <div class="d-flex flex-wrap">
      <SelectButton
        :model-value="selectedPrefectures"
        :options="prefectureItems"
        option-label="name"
        option-value="value"
        multiple
        aria-labelledby="multiple"
        class="me-2"
        @update:model-value="$emit('update:selectedPrefectures', $event)"
      />
      <SelectButton
        :model-value="selectedCarriers"
        :options="carrierItems"
        option-label="name"
        option-value="value"
        multiple
        aria-labelledby="multiple"
        @update:model-value="$emit('update:selectedCarriers', $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import SelectButton from "primevue/selectbutton";
import { computed } from "vue";
import { translater } from "/@/modules/string";
import { Carrier, Prefecture, WorkPlace } from "/@/types";

const props = defineProps<{
  workPlaces: WorkPlace[];
  selectedPrefectures: Prefecture[];
  selectedCarriers: Carrier[];
}>();

defineEmits<{
  (e: "update:selectedPrefectures", prefectures: Prefecture[]): void;
  (e: "update:selectedCarriers", carriers: Carrier[]): void;
}>();

const prefectures = computed(() => {
  return [...new Set(props.workPlaces.map((wp) => wp.prefecture))].filter(
    (prefecture) => prefecture !== "other"
  );
});

const prefectureItems = computed(() => {
  return prefectures.value.map((pre) => ({
    name: translater(pre),
    value: pre,
  }));
});

const carriers = computed(() => {
  return [...new Set(props.workPlaces.map((wp) => wp.placeSubCategory))].filter(
    (carrier) => carrier !== "home" && carrier !== "office"
  );
});

const carrierItems = computed(() => {
  return carriers.value.map((carrier) => ({
    name: translater(carrier),
    value: carrier,
  }));
});
</script>

<style scoped></style>
