import {
  NestedTaskItemClient,
  OrganizationMember,
  OrganizationMemberSchedulePlan,
  PlanMap,
  TaskItemClient,
  WorkAsPlan,
  WorkRecordEntryClient,
  WorkScheduleAsPlan,
  WorkTypeClient,
  TaskProjectClient,
  WorkReport,
} from "/@/types";

export const schedulePlans: OrganizationMemberSchedulePlan[] = [
  {
    id: 1,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-01",
    workSchedules: [
      {
        id: 1,
        targetDate: "2023-06-01",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 1,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "コンサル",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 262,
    organizationMemberId: 6,
    organizationMemberName: "髙木 晶平",
    targetDate: "2023-06-01",
    workSchedules: [
      {
        id: 247,
        targetDate: "2023-06-01",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 324,
        workId: 17,
        workCategory: "fulltime",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "リアルメディア",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)新通エスピー",
        workPlaceOrganizationId: 62,
        workTypeName: "常勤",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 216,
    organizationMemberId: 17,
    organizationMemberName: "山村 朋矢",
    targetDate: "2023-06-01",
    workSchedules: [
      {
        id: 160,
        targetDate: "2023-06-01",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 217,
        workId: 10,
        workCategory: "rounder",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "高岡中央店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "(株)トリニティ",
        workPlaceOrganizationId: 17,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 51,
    organizationMemberId: 18,
    organizationMemberName: "網島 恭平",
    targetDate: "2023-06-01",
    workSchedules: [
      {
        id: 200,
        targetDate: "2023-06-01",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 260,
        workId: 12,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa"],
        workPlaceName: "穴水店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 255,
    organizationMemberId: 21,
    organizationMemberName: "木戸脇 悠平",
    targetDate: "2023-06-01",
    workSchedules: [
      {
        id: 30,
        targetDate: "2023-06-01",
        eventPlaceName: "武生SIPY",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=武生SIPY+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 294,
        workId: 2,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 251,
    organizationMemberId: 4,
    organizationMemberName: "木戸 理恵",
    targetDate: "2023-06-02",
    workSchedules: [
      {
        id: 31,
        targetDate: "2023-06-02",
        eventPlaceName: "武生SIPY",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=SIPY+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 281,
        workId: 2,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 2,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-02",
    workSchedules: [
      {
        id: 2,
        targetDate: "2023-06-02",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 2,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "コンサル",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 263,
    organizationMemberId: 6,
    organizationMemberName: "髙木 晶平",
    targetDate: "2023-06-02",
    workSchedules: [
      {
        id: 248,
        targetDate: "2023-06-02",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 325,
        workId: 17,
        workCategory: "fulltime",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "リアルメディア",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)新通エスピー",
        workPlaceOrganizationId: 62,
        workTypeName: "常勤",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 179,
    organizationMemberId: 3,
    organizationMemberName: "小島 孝介",
    targetDate: "2023-06-02",
    workSchedules: [
      {
        id: 180,
        targetDate: "2023-06-02",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 237,
        workId: 11,
        workCategory: "rounder",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "江守中",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)リアルメディア",
        workPlaceOrganizationId: 59,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 250,
    organizationMemberId: 18,
    organizationMemberName: "網島 恭平",
    targetDate: "2023-06-02",
    workSchedules: [
      {
        id: 233,
        targetDate: "2023-06-02",
        eventPlaceName: "アピタ福井大和田",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 309,
        workId: 16,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 287,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-03",
    workSchedules: [
      {
        id: 208,
        targetDate: "2023-06-03",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:30:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8.5,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 349,
        workId: 13,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "学園店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
        workPlaceOrganizationId: 9,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 145,
    organizationMemberId: 1,
    organizationMemberName: "竹中 福善",
    targetDate: "2023-06-03",
    workSchedules: [
      {
        id: 107,
        targetDate: "2023-06-03",
        eventPlaceName: "ヤマキシ新加賀店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=ヤマキシ新加賀店+",
        meetingAt: "2000-01-01T09:20:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 146,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "加賀店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 217,
    organizationMemberId: 17,
    organizationMemberName: "山村 朋矢",
    targetDate: "2023-06-03",
    workSchedules: [
      {
        id: 161,
        targetDate: "2023-06-03",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 218,
        workId: 10,
        workCategory: "rounder",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "高岡中央店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "(株)トリニティ",
        workPlaceOrganizationId: 17,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 264,
    organizationMemberId: 6,
    organizationMemberName: "髙木 晶平",
    targetDate: "2023-06-03",
    workSchedules: [
      {
        id: 249,
        targetDate: "2023-06-03",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 326,
        workId: 17,
        workCategory: "fulltime",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "リアルメディア",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)新通エスピー",
        workPlaceOrganizationId: 62,
        workTypeName: "常勤",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 92,
    organizationMemberId: 18,
    organizationMemberName: "網島 恭平",
    targetDate: "2023-06-03",
    workSchedules: [
      {
        id: 234,
        targetDate: "2023-06-03",
        eventPlaceName: "アピタ福井大和田",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 310,
        workId: 16,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 66,
    organizationMemberId: 5,
    organizationMemberName: "松島 湧吾",
    targetDate: "2023-06-03",
    workSchedules: [
      {
        id: 59,
        targetDate: "2023-06-03",
        eventPlaceName: "サンコー砺波店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=サンコー砺波店+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 66,
        workId: 4,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "砺波店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "米原商事(株)",
        workPlaceOrganizationId: 18,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 103,
    organizationMemberId: 4,
    organizationMemberName: "木戸 理恵",
    targetDate: "2023-06-03",
    workSchedules: [
      {
        id: 32,
        targetDate: "2023-06-03",
        eventPlaceName: "武生SIPY",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=SIPY+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 103,
        workId: 2,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 180,
    organizationMemberId: 3,
    organizationMemberName: "小島 孝介",
    targetDate: "2023-06-03",
    workSchedules: [
      {
        id: 181,
        targetDate: "2023-06-03",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 238,
        workId: 11,
        workCategory: "rounder",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "江守中",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)リアルメディア",
        workPlaceOrganizationId: 59,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 146,
    organizationMemberId: 21,
    organizationMemberName: "木戸脇 悠平",
    targetDate: "2023-06-03",
    workSchedules: [
      {
        id: 107,
        targetDate: "2023-06-03",
        eventPlaceName: "ヤマキシ新加賀店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=ヤマキシ新加賀店+",
        meetingAt: "2000-01-01T09:20:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 147,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "加賀店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 76,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-04",
    workSchedules: [
      {
        id: 209,
        targetDate: "2023-06-04",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:30:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8.5,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 279,
        workId: 13,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "学園店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
        workPlaceOrganizationId: 9,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 147,
    organizationMemberId: 21,
    organizationMemberName: "木戸脇 悠平",
    targetDate: "2023-06-04",
    workSchedules: [
      {
        id: 108,
        targetDate: "2023-06-04",
        eventPlaceName: "ヤマキシ新加賀店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=ヤマキシ新加賀店+",
        meetingAt: "2000-01-01T09:20:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 148,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "加賀店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 102,
    organizationMemberId: 4,
    organizationMemberName: "木戸 理恵",
    targetDate: "2023-06-04",
    workSchedules: [
      {
        id: 33,
        targetDate: "2023-06-04",
        eventPlaceName: "武生SIPY",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=SIPY+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 102,
        workId: 2,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 218,
    organizationMemberId: 17,
    organizationMemberName: "山村 朋矢",
    targetDate: "2023-06-04",
    workSchedules: [
      {
        id: 162,
        targetDate: "2023-06-04",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 219,
        workId: 10,
        workCategory: "rounder",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "高岡中央店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "(株)トリニティ",
        workPlaceOrganizationId: 17,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 265,
    organizationMemberId: 6,
    organizationMemberName: "髙木 晶平",
    targetDate: "2023-06-04",
    workSchedules: [
      {
        id: 250,
        targetDate: "2023-06-04",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 327,
        workId: 17,
        workCategory: "fulltime",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "リアルメディア",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)新通エスピー",
        workPlaceOrganizationId: 62,
        workTypeName: "常勤",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 93,
    organizationMemberId: 18,
    organizationMemberName: "網島 恭平",
    targetDate: "2023-06-04",
    workSchedules: [
      {
        id: 235,
        targetDate: "2023-06-04",
        eventPlaceName: "アピタ福井大和田",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 311,
        workId: 16,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 148,
    organizationMemberId: 16,
    organizationMemberName: "後藤2",
    targetDate: "2023-06-04",
    workSchedules: [
      {
        id: 108,
        targetDate: "2023-06-04",
        eventPlaceName: "ヤマキシ新加賀店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=ヤマキシ新加賀店+",
        meetingAt: "2000-01-01T09:20:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "後藤2",
        workScheduleAssignmentId: 149,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "加賀店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 67,
    organizationMemberId: 5,
    organizationMemberName: "松島 湧吾",
    targetDate: "2023-06-04",
    workSchedules: [
      {
        id: 60,
        targetDate: "2023-06-04",
        eventPlaceName: "サンコー砺波店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=サンコー砺波店+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 67,
        workId: 4,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "砺波店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "米原商事(株)",
        workPlaceOrganizationId: 18,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 236,
    organizationMemberId: 3,
    organizationMemberName: "小島 孝介",
    targetDate: "2023-06-04",
    workSchedules: [
      {
        id: 182,
        targetDate: "2023-06-04",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 239,
        workId: 11,
        workCategory: "rounder",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "リアルメディア",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)新通エスピー",
        workPlaceOrganizationId: 62,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 258,
    organizationMemberId: 1,
    organizationMemberName: "竹中 福善",
    targetDate: "2023-06-05",
    workSchedules: [
      {
        id: 244,
        targetDate: "2023-06-05",
        eventPlaceName: "",
        eventPlaceGmapUrl: "",
        meetingAt: "2000-01-01T09:20:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:30:00.000+09:00",
        workingHours: 7.5,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 320,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "金沢駅前店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 39,
    organizationMemberId: 5,
    organizationMemberName: "松島 湧吾",
    targetDate: "2023-06-05",
    workSchedules: [
      {
        id: 34,
        targetDate: "2023-06-05",
        eventPlaceName: "武生SIPY",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=SIPY+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 39,
        workId: 2,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 3,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-05",
    workSchedules: [
      {
        id: 3,
        targetDate: "2023-06-05",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 3,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "コンサル",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 52,
    organizationMemberId: 18,
    organizationMemberName: "網島 恭平",
    targetDate: "2023-06-05",
    workSchedules: [
      {
        id: 45,
        targetDate: "2023-06-05",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 52,
        workId: 3,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 219,
    organizationMemberId: 17,
    organizationMemberName: "山村 朋矢",
    targetDate: "2023-06-05",
    workSchedules: [
      {
        id: 163,
        targetDate: "2023-06-05",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 220,
        workId: 10,
        workCategory: "rounder",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "高岡中央店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "(株)トリニティ",
        workPlaceOrganizationId: 17,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 149,
    organizationMemberId: 5,
    organizationMemberName: "松島 湧吾",
    targetDate: "2023-06-06",
    workSchedules: [
      {
        id: 125,
        targetDate: "2023-06-06",
        eventPlaceName: "業務スーパー和倉",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=業務スーパー和倉+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 150,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 259,
    organizationMemberId: 1,
    organizationMemberName: "竹中 福善",
    targetDate: "2023-06-06",
    workSchedules: [
      {
        id: 245,
        targetDate: "2023-06-06",
        eventPlaceName: "",
        eventPlaceGmapUrl: "",
        meetingAt: "2000-01-01T09:20:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:30:00.000+09:00",
        workingHours: 7.5,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: true,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 321,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "金沢駅前店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 4,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-06",
    workSchedules: [
      {
        id: 4,
        targetDate: "2023-06-06",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: false,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 4,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "コンサル",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 53,
    organizationMemberId: 18,
    organizationMemberName: "網島 恭平",
    targetDate: "2023-06-06",
    workSchedules: [
      {
        id: 46,
        targetDate: "2023-06-06",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 53,
        workId: 3,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 104,
    organizationMemberId: 4,
    organizationMemberName: "木戸 理恵",
    targetDate: "2023-06-06",
    workSchedules: [
      {
        id: 35,
        targetDate: "2023-06-06",
        eventPlaceName: "武生SIPY",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=SIPY+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 104,
        workId: 2,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 183,
    organizationMemberId: 3,
    organizationMemberName: "小島 孝介",
    targetDate: "2023-06-06",
    workSchedules: [
      {
        id: 183,
        targetDate: "2023-06-06",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 240,
        workId: 11,
        workCategory: "rounder",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "御幸",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)リアルメディア",
        workPlaceOrganizationId: 59,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 184,
    organizationMemberId: 3,
    organizationMemberName: "小島 孝介",
    targetDate: "2023-06-07",
    workSchedules: [
      {
        id: 184,
        targetDate: "2023-06-07",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 241,
        workId: 11,
        workCategory: "rounder",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "福井運動公園",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)ベルパーク",
        workPlaceOrganizationId: 58,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 77,
    organizationMemberId: 19,
    organizationMemberName: "水上 祐介",
    targetDate: "2023-06-07",
    workSchedules: [
      {
        id: 71,
        targetDate: "2023-06-07",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 77,
        workId: 5,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa"],
        workPlaceName: "もりの里店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
        workPlaceOrganizationId: 9,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 150,
    organizationMemberId: 5,
    organizationMemberName: "松島 湧吾",
    targetDate: "2023-06-07",
    workSchedules: [
      {
        id: 126,
        targetDate: "2023-06-07",
        eventPlaceName: "業務スーパー和倉",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=業務スーパー和倉+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 151,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 112,
    organizationMemberId: 21,
    organizationMemberName: "木戸脇 悠平",
    targetDate: "2023-06-07",
    workSchedules: [
      {
        id: 210,
        targetDate: "2023-06-07",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:30:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8.5,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 283,
        workId: 13,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "学園店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
        workPlaceOrganizationId: 9,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 5,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-07",
    workSchedules: [
      {
        id: 5,
        targetDate: "2023-06-07",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 5,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "コンサル",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 54,
    organizationMemberId: 18,
    organizationMemberName: "網島 恭平",
    targetDate: "2023-06-07",
    workSchedules: [
      {
        id: 201,
        targetDate: "2023-06-07",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 261,
        workId: 12,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa"],
        workPlaceName: "穴水店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 266,
    organizationMemberId: 6,
    organizationMemberName: "髙木 晶平",
    targetDate: "2023-06-07",
    workSchedules: [
      {
        id: 251,
        targetDate: "2023-06-07",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 328,
        workId: 17,
        workCategory: "fulltime",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "リアルメディア",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)新通エスピー",
        workPlaceOrganizationId: 62,
        workTypeName: "常勤",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 220,
    organizationMemberId: 17,
    organizationMemberName: "山村 朋矢",
    targetDate: "2023-06-07",
    workSchedules: [
      {
        id: 164,
        targetDate: "2023-06-07",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 221,
        workId: 10,
        workCategory: "rounder",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "高岡中央店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "(株)トリニティ",
        workPlaceOrganizationId: 17,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 105,
    organizationMemberId: 4,
    organizationMemberName: "木戸 理恵",
    targetDate: "2023-06-07",
    workSchedules: [
      {
        id: 36,
        targetDate: "2023-06-07",
        eventPlaceName: "武生SIPY",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=SIPY+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 105,
        workId: 2,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 78,
    organizationMemberId: 19,
    organizationMemberName: "水上 祐介",
    targetDate: "2023-06-08",
    workSchedules: [
      {
        id: 215,
        targetDate: "2023-06-08",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 288,
        workId: 14,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "呉羽店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
        workPlaceOrganizationId: 9,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 267,
    organizationMemberId: 6,
    organizationMemberName: "髙木 晶平",
    targetDate: "2023-06-08",
    workSchedules: [
      {
        id: 252,
        targetDate: "2023-06-08",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 329,
        workId: 17,
        workCategory: "fulltime",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "リアルメディア",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)新通エスピー",
        workPlaceOrganizationId: 62,
        workTypeName: "常勤",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 221,
    organizationMemberId: 17,
    organizationMemberName: "山村 朋矢",
    targetDate: "2023-06-08",
    workSchedules: [
      {
        id: 165,
        targetDate: "2023-06-08",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 222,
        workId: 10,
        workCategory: "rounder",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "高岡中央店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "(株)トリニティ",
        workPlaceOrganizationId: 17,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 97,
    organizationMemberId: 4,
    organizationMemberName: "木戸 理恵",
    targetDate: "2023-06-09",
    workSchedules: [
      {
        id: 6,
        targetDate: "2023-06-09",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 97,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 125,
    organizationMemberId: 5,
    organizationMemberName: "松島 湧吾",
    targetDate: "2023-06-09",
    workSchedules: [
      {
        id: 96,
        targetDate: "2023-06-09",
        eventPlaceName: "イオン金沢",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=イオン金沢+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 126,
        workId: 7,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["ishikawa"],
        workPlaceName: "春江",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)ジョイテル",
        workPlaceOrganizationId: 54,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 185,
    organizationMemberId: 3,
    organizationMemberName: "小島 孝介",
    targetDate: "2023-06-09",
    workSchedules: [
      {
        id: 185,
        targetDate: "2023-06-09",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 242,
        workId: 11,
        workCategory: "rounder",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 151,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-09",
    workSchedules: [
      {
        id: 109,
        targetDate: "2023-06-09",
        eventPlaceName: "100満ボルトアピタ金沢店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=100満ボルトアピタ金沢店+",
        meetingAt: "2000-01-01T09:00:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 152,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "金沢駅前店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 201,
    organizationMemberId: 1,
    organizationMemberName: "竹中 福善",
    targetDate: "2023-06-09",
    workSchedules: [
      {
        id: 220,
        targetDate: "2023-06-09",
        eventPlaceName: "アピタ福井大和田",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 296,
        workId: 15,
        workCategory: "normal",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 79,
    organizationMemberId: 19,
    organizationMemberName: "水上 祐介",
    targetDate: "2023-06-09",
    workSchedules: [
      {
        id: 73,
        targetDate: "2023-06-09",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 79,
        workId: 5,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa"],
        workPlaceName: "もりの里店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
        workPlaceOrganizationId: 9,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 152,
    organizationMemberId: 15,
    organizationMemberName: "後藤1",
    targetDate: "2023-06-09",
    workSchedules: [
      {
        id: 109,
        targetDate: "2023-06-09",
        eventPlaceName: "100満ボルトアピタ金沢店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=100満ボルトアピタ金沢店+",
        meetingAt: "2000-01-01T09:00:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "後藤1",
        workScheduleAssignmentId: 153,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "金沢駅前店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 94,
    organizationMemberId: 18,
    organizationMemberName: "網島 恭平",
    targetDate: "2023-06-09",
    workSchedules: [
      {
        id: 236,
        targetDate: "2023-06-09",
        eventPlaceName: "アピタ福井大和田",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 312,
        workId: 16,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 268,
    organizationMemberId: 6,
    organizationMemberName: "髙木 晶平",
    targetDate: "2023-06-10",
    workSchedules: [
      {
        id: 253,
        targetDate: "2023-06-10",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 330,
        workId: 17,
        workCategory: "fulltime",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "リアルメディア",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)新通エスピー",
        workPlaceOrganizationId: 62,
        workTypeName: "常勤",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 98,
    organizationMemberId: 4,
    organizationMemberName: "木戸 理恵",
    targetDate: "2023-06-10",
    workSchedules: [
      {
        id: 7,
        targetDate: "2023-06-10",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 98,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 186,
    organizationMemberId: 3,
    organizationMemberName: "小島 孝介",
    targetDate: "2023-06-10",
    workSchedules: [
      {
        id: 186,
        targetDate: "2023-06-10",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 243,
        workId: 11,
        workCategory: "rounder",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 155,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-10",
    workSchedules: [
      {
        id: 110,
        targetDate: "2023-06-10",
        eventPlaceName: "イオンモール新小松",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=イオンモール新小松+",
        meetingAt: "2000-01-01T09:00:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 4,
        currentCount: 3,
        enoughCount: false,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 156,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "中部特機産業(株)",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 257,
    organizationMemberId: 22,
    organizationMemberName: "長岡 陸",
    targetDate: "2023-06-10",
    workSchedules: [
      {
        id: 237,
        targetDate: "2023-06-10",
        eventPlaceName: "アピタ福井大和田",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "長岡 陸",
        workScheduleAssignmentId: 313,
        workId: 16,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 202,
    organizationMemberId: 1,
    organizationMemberName: "竹中 福善",
    targetDate: "2023-06-10",
    workSchedules: [
      {
        id: 221,
        targetDate: "2023-06-10",
        eventPlaceName: "アピタ福井大和田",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 297,
        workId: 15,
        workCategory: "normal",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 133,
    organizationMemberId: 21,
    organizationMemberName: "木戸脇 悠平",
    targetDate: "2023-06-10",
    workSchedules: [
      {
        id: 97,
        targetDate: "2023-06-10",
        eventPlaceName: "イオン金沢",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=イオン金沢+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 134,
        workId: 7,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["ishikawa"],
        workPlaceName: "春江",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)ジョイテル",
        workPlaceOrganizationId: 54,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 153,
    organizationMemberId: 15,
    organizationMemberName: "後藤1",
    targetDate: "2023-06-10",
    workSchedules: [
      {
        id: 110,
        targetDate: "2023-06-10",
        eventPlaceName: "イオンモール新小松",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=イオンモール新小松+",
        meetingAt: "2000-01-01T09:00:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 4,
        currentCount: 3,
        enoughCount: false,
        done: null,
        organizationMemberName: "後藤1",
        workScheduleAssignmentId: 154,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "中部特機産業(株)",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 154,
    organizationMemberId: 16,
    organizationMemberName: "後藤2",
    targetDate: "2023-06-10",
    workSchedules: [
      {
        id: 110,
        targetDate: "2023-06-10",
        eventPlaceName: "イオンモール新小松",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=イオンモール新小松+",
        meetingAt: "2000-01-01T09:00:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 4,
        currentCount: 3,
        enoughCount: false,
        done: null,
        organizationMemberName: "後藤2",
        workScheduleAssignmentId: 155,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "中部特機産業(株)",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 68,
    organizationMemberId: 5,
    organizationMemberName: "松島 湧吾",
    targetDate: "2023-06-10",
    workSchedules: [
      {
        id: 61,
        targetDate: "2023-06-10",
        eventPlaceName: "サンコー砺波店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=サンコー砺波店+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 68,
        workId: 4,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "砺波店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "米原商事(株)",
        workPlaceOrganizationId: 18,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 126,
    organizationMemberId: 18,
    organizationMemberName: "網島 恭平",
    targetDate: "2023-06-10",
    workSchedules: [
      {
        id: 97,
        targetDate: "2023-06-10",
        eventPlaceName: "イオン金沢",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=イオン金沢+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 127,
        workId: 7,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["ishikawa"],
        workPlaceName: "春江",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)ジョイテル",
        workPlaceOrganizationId: 54,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 157,
    organizationMemberId: 16,
    organizationMemberName: "後藤2",
    targetDate: "2023-06-11",
    workSchedules: [
      {
        id: 111,
        targetDate: "2023-06-11",
        eventPlaceName: "イオンモール新小松",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=イオンモール新小松+",
        meetingAt: "2000-01-01T09:20:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 4,
        currentCount: 3,
        enoughCount: false,
        done: null,
        organizationMemberName: "後藤2",
        workScheduleAssignmentId: 158,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "中部特機産業(株)",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 69,
    organizationMemberId: 5,
    organizationMemberName: "松島 湧吾",
    targetDate: "2023-06-11",
    workSchedules: [
      {
        id: 62,
        targetDate: "2023-06-11",
        eventPlaceName: "サンコー砺波店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=サンコー砺波店+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 69,
        workId: 4,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "砺波店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "米原商事(株)",
        workPlaceOrganizationId: 18,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 222,
    organizationMemberId: 17,
    organizationMemberName: "山村 朋矢",
    targetDate: "2023-06-11",
    workSchedules: [
      {
        id: 166,
        targetDate: "2023-06-11",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 223,
        workId: 10,
        workCategory: "rounder",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "高岡中央店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "(株)トリニティ",
        workPlaceOrganizationId: 17,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 127,
    organizationMemberId: 18,
    organizationMemberName: "網島 恭平",
    targetDate: "2023-06-11",
    workSchedules: [
      {
        id: 98,
        targetDate: "2023-06-11",
        eventPlaceName: "イオン金沢",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=イオン金沢+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 128,
        workId: 7,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["ishikawa"],
        workPlaceName: "春江",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)ジョイテル",
        workPlaceOrganizationId: 54,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 269,
    organizationMemberId: 6,
    organizationMemberName: "髙木 晶平",
    targetDate: "2023-06-11",
    workSchedules: [
      {
        id: 254,
        targetDate: "2023-06-11",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 331,
        workId: 17,
        workCategory: "fulltime",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "リアルメディア",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)新通エスピー",
        workPlaceOrganizationId: 62,
        workTypeName: "常勤",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 99,
    organizationMemberId: 4,
    organizationMemberName: "木戸 理恵",
    targetDate: "2023-06-11",
    workSchedules: [
      {
        id: 8,
        targetDate: "2023-06-11",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 99,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 187,
    organizationMemberId: 3,
    organizationMemberName: "小島 孝介",
    targetDate: "2023-06-11",
    workSchedules: [
      {
        id: 187,
        targetDate: "2023-06-11",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 244,
        workId: 11,
        workCategory: "rounder",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 80,
    organizationMemberId: 19,
    organizationMemberName: "水上 祐介",
    targetDate: "2023-06-11",
    workSchedules: [
      {
        id: 74,
        targetDate: "2023-06-11",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 80,
        workId: 5,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa"],
        workPlaceName: "もりの里店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
        workPlaceOrganizationId: 9,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 158,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-11",
    workSchedules: [
      {
        id: 111,
        targetDate: "2023-06-11",
        eventPlaceName: "イオンモール新小松",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=イオンモール新小松+",
        meetingAt: "2000-01-01T09:20:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 4,
        currentCount: 3,
        enoughCount: false,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 159,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "中部特機産業(株)",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 203,
    organizationMemberId: 1,
    organizationMemberName: "竹中 福善",
    targetDate: "2023-06-11",
    workSchedules: [
      {
        id: 222,
        targetDate: "2023-06-11",
        eventPlaceName: "アピタ福井大和田",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 298,
        workId: 15,
        workCategory: "normal",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 134,
    organizationMemberId: 21,
    organizationMemberName: "木戸脇 悠平",
    targetDate: "2023-06-11",
    workSchedules: [
      {
        id: 98,
        targetDate: "2023-06-11",
        eventPlaceName: "イオン金沢",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=イオン金沢+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 135,
        workId: 7,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["ishikawa"],
        workPlaceName: "春江",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)ジョイテル",
        workPlaceOrganizationId: 54,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 156,
    organizationMemberId: 15,
    organizationMemberName: "後藤1",
    targetDate: "2023-06-11",
    workSchedules: [
      {
        id: 111,
        targetDate: "2023-06-11",
        eventPlaceName: "イオンモール新小松",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=イオンモール新小松+",
        meetingAt: "2000-01-01T09:20:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 4,
        currentCount: 3,
        enoughCount: false,
        done: null,
        organizationMemberName: "後藤1",
        workScheduleAssignmentId: 157,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "中部特機産業(株)",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 122,
    organizationMemberId: 22,
    organizationMemberName: "長岡 陸",
    targetDate: "2023-06-11",
    workSchedules: [
      {
        id: 238,
        targetDate: "2023-06-11",
        eventPlaceName: "アピタ福井大和田",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "長岡 陸",
        workScheduleAssignmentId: 314,
        workId: 16,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 106,
    organizationMemberId: 4,
    organizationMemberName: "木戸 理恵",
    targetDate: "2023-06-12",
    workSchedules: [
      {
        id: 10,
        targetDate: "2023-06-12",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 106,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 160,
    organizationMemberId: 21,
    organizationMemberName: "木戸脇 悠平",
    targetDate: "2023-06-12",
    workSchedules: [
      {
        id: 112,
        targetDate: "2023-06-12",
        eventPlaceName: "イオンモール新小松",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=イオンモール新小松+",
        meetingAt: "2000-01-01T09:20:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 161,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "中部特機産業(株)",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 224,
    organizationMemberId: 17,
    organizationMemberName: "山村 朋矢",
    targetDate: "2023-06-12",
    workSchedules: [
      {
        id: 167,
        targetDate: "2023-06-12",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 225,
        workId: 10,
        workCategory: "rounder",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "高岡中央店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "(株)トリニティ",
        workPlaceOrganizationId: 17,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 159,
    organizationMemberId: 5,
    organizationMemberName: "松島 湧吾",
    targetDate: "2023-06-12",
    workSchedules: [
      {
        id: 112,
        targetDate: "2023-06-12",
        eventPlaceName: "イオンモール新小松",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=イオンモール新小松+",
        meetingAt: "2000-01-01T09:20:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 160,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "中部特機産業(株)",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 188,
    organizationMemberId: 3,
    organizationMemberName: "小島 孝介",
    targetDate: "2023-06-12",
    workSchedules: [
      {
        id: 188,
        targetDate: "2023-06-12",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 245,
        workId: 11,
        workCategory: "rounder",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 81,
    organizationMemberId: 19,
    organizationMemberName: "水上 祐介",
    targetDate: "2023-06-12",
    workSchedules: [
      {
        id: 75,
        targetDate: "2023-06-12",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 81,
        workId: 5,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa"],
        workPlaceName: "もりの里店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
        workPlaceOrganizationId: 9,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 204,
    organizationMemberId: 1,
    organizationMemberName: "竹中 福善",
    targetDate: "2023-06-12",
    workSchedules: [
      {
        id: 223,
        targetDate: "2023-06-12",
        eventPlaceName: "アピタ福井大和田",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 299,
        workId: 15,
        workCategory: "normal",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 10,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-12",
    workSchedules: [
      {
        id: 9,
        targetDate: "2023-06-12",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 10,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "コンサル",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 55,
    organizationMemberId: 18,
    organizationMemberName: "網島 恭平",
    targetDate: "2023-06-12",
    workSchedules: [
      {
        id: 48,
        targetDate: "2023-06-12",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 55,
        workId: 3,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 11,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-13",
    workSchedules: [
      {
        id: 11,
        targetDate: "2023-06-13",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 11,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "コンサル",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 56,
    organizationMemberId: 18,
    organizationMemberName: "網島 恭平",
    targetDate: "2023-06-13",
    workSchedules: [
      {
        id: 49,
        targetDate: "2023-06-13",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 56,
        workId: 3,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 223,
    organizationMemberId: 17,
    organizationMemberName: "山村 朋矢",
    targetDate: "2023-06-13",
    workSchedules: [
      {
        id: 168,
        targetDate: "2023-06-13",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 224,
        workId: 10,
        workCategory: "rounder",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "高岡中央店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "(株)トリニティ",
        workPlaceOrganizationId: 17,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 161,
    organizationMemberId: 21,
    organizationMemberName: "木戸脇 悠平",
    targetDate: "2023-06-13",
    workSchedules: [
      {
        id: 113,
        targetDate: "2023-06-13",
        eventPlaceName: "どんたくアスティ店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=どんたくアスティ店+",
        meetingAt: "2000-01-01T09:00:00.000+09:00",
        startAt: "2000-01-01T09:30:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7.5,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 162,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 270,
    organizationMemberId: 6,
    organizationMemberName: "髙木 晶平",
    targetDate: "2023-06-13",
    workSchedules: [
      {
        id: 255,
        targetDate: "2023-06-13",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 332,
        workId: 17,
        workCategory: "fulltime",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "リアルメディア",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)新通エスピー",
        workPlaceOrganizationId: 62,
        workTypeName: "常勤",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 162,
    organizationMemberId: 5,
    organizationMemberName: "松島 湧吾",
    targetDate: "2023-06-13",
    workSchedules: [
      {
        id: 113,
        targetDate: "2023-06-13",
        eventPlaceName: "どんたくアスティ店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=どんたくアスティ店+",
        meetingAt: "2000-01-01T09:00:00.000+09:00",
        startAt: "2000-01-01T09:30:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7.5,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 163,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 189,
    organizationMemberId: 3,
    organizationMemberName: "小島 孝介",
    targetDate: "2023-06-13",
    workSchedules: [
      {
        id: 189,
        targetDate: "2023-06-13",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 248,
        workId: 11,
        workCategory: "rounder",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "リアルメディア",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)新通エスピー",
        workPlaceOrganizationId: 62,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 163,
    organizationMemberId: 18,
    organizationMemberName: "網島 恭平",
    targetDate: "2023-06-14",
    workSchedules: [
      {
        id: 114,
        targetDate: "2023-06-14",
        eventPlaceName: "どんたくアスティ店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=どんたくアスティ店+",
        meetingAt: "2000-01-01T09:00:00.000+09:00",
        startAt: "2000-01-01T09:30:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7.5,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 164,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 164,
    organizationMemberId: 5,
    organizationMemberName: "松島 湧吾",
    targetDate: "2023-06-14",
    workSchedules: [
      {
        id: 114,
        targetDate: "2023-06-14",
        eventPlaceName: "どんたくアスティ店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=どんたくアスティ店+",
        meetingAt: "2000-01-01T09:00:00.000+09:00",
        startAt: "2000-01-01T09:30:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7.5,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 165,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 12,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-15",
    workSchedules: [
      {
        id: 12,
        targetDate: "2023-06-15",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 12,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "コンサル",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 82,
    organizationMemberId: 21,
    organizationMemberName: "木戸脇 悠平",
    targetDate: "2023-06-15",
    workSchedules: [
      {
        id: 211,
        targetDate: "2023-06-15",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:30:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8.5,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 284,
        workId: 13,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "学園店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
        workPlaceOrganizationId: 9,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 194,
    organizationMemberId: 3,
    organizationMemberName: "小島 孝介",
    targetDate: "2023-06-15",
    workSchedules: [
      {
        id: 190,
        targetDate: "2023-06-15",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 246,
        workId: 11,
        workCategory: "rounder",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "福井運動公園",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)ベルパーク",
        workPlaceOrganizationId: 58,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 42,
    organizationMemberId: 5,
    organizationMemberName: "松島 湧吾",
    targetDate: "2023-06-15",
    workSchedules: [
      {
        id: 37,
        targetDate: "2023-06-15",
        eventPlaceName: "ワイプラザ鯖江店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=ワイプラザ鯖江店+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 42,
        workId: 2,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 271,
    organizationMemberId: 6,
    organizationMemberName: "髙木 晶平",
    targetDate: "2023-06-15",
    workSchedules: [
      {
        id: 256,
        targetDate: "2023-06-15",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 333,
        workId: 17,
        workCategory: "fulltime",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "リアルメディア",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)新通エスピー",
        workPlaceOrganizationId: 62,
        workTypeName: "常勤",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 225,
    organizationMemberId: 17,
    organizationMemberName: "山村 朋矢",
    targetDate: "2023-06-15",
    workSchedules: [
      {
        id: 169,
        targetDate: "2023-06-15",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 226,
        workId: 10,
        workCategory: "rounder",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "高岡中央店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "(株)トリニティ",
        workPlaceOrganizationId: 17,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 205,
    organizationMemberId: 1,
    organizationMemberName: "竹中 福善",
    targetDate: "2023-06-16",
    workSchedules: [
      {
        id: 224,
        targetDate: "2023-06-16",
        eventPlaceName: "アピタ福井大和田",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 300,
        workId: 15,
        workCategory: "normal",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 253,
    organizationMemberId: 6,
    organizationMemberName: "髙木 晶平",
    targetDate: "2023-06-16",
    workSchedules: [
      {
        id: 239,
        targetDate: "2023-06-16",
        eventPlaceName: "アピタ福井大和田",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 315,
        workId: 16,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 166,
    organizationMemberId: 5,
    organizationMemberName: "松島 湧吾",
    targetDate: "2023-06-16",
    workSchedules: [
      {
        id: 115,
        targetDate: "2023-06-16",
        eventPlaceName: "あだちストア",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=あだちストア+",
        meetingAt: "2000-01-01T09:00:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 167,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 57,
    organizationMemberId: 18,
    organizationMemberName: "網島 恭平",
    targetDate: "2023-06-16",
    workSchedules: [
      {
        id: 50,
        targetDate: "2023-06-16",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 57,
        workId: 3,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 13,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-16",
    workSchedules: [
      {
        id: 13,
        targetDate: "2023-06-16",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 13,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "コンサル",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 165,
    organizationMemberId: 21,
    organizationMemberName: "木戸脇 悠平",
    targetDate: "2023-06-16",
    workSchedules: [
      {
        id: 115,
        targetDate: "2023-06-16",
        eventPlaceName: "あだちストア",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=あだちストア+",
        meetingAt: "2000-01-01T09:00:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 166,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 113,
    organizationMemberId: 4,
    organizationMemberName: "木戸 理恵",
    targetDate: "2023-06-16",
    workSchedules: [
      {
        id: 38,
        targetDate: "2023-06-16",
        eventPlaceName: "ワイプラザ鯖江店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=ワイプラザ鯖江店+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 113,
        workId: 2,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 227,
    organizationMemberId: 17,
    organizationMemberName: "山村 朋矢",
    targetDate: "2023-06-16",
    workSchedules: [
      {
        id: 170,
        targetDate: "2023-06-16",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 228,
        workId: 10,
        workCategory: "rounder",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "高岡中央店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "(株)トリニティ",
        workPlaceOrganizationId: 17,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 191,
    organizationMemberId: 3,
    organizationMemberName: "小島 孝介",
    targetDate: "2023-06-16",
    workSchedules: [
      {
        id: 191,
        targetDate: "2023-06-16",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 247,
        workId: 11,
        workCategory: "rounder",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "江守中",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)リアルメディア",
        workPlaceOrganizationId: 59,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 114,
    organizationMemberId: 4,
    organizationMemberName: "木戸 理恵",
    targetDate: "2023-06-17",
    workSchedules: [
      {
        id: 39,
        targetDate: "2023-06-17",
        eventPlaceName: "ワイプラザ鯖江店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=ワイプラザ鯖江店+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 114,
        workId: 2,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 135,
    organizationMemberId: 16,
    organizationMemberName: "後藤2",
    targetDate: "2023-06-17",
    workSchedules: [
      {
        id: 99,
        targetDate: "2023-06-17",
        eventPlaceName: "ユナイテッドシネマ金沢",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=ユナイテッドシネマ金沢+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "後藤2",
        workScheduleAssignmentId: 136,
        workId: 7,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["ishikawa"],
        workPlaceName: "春江",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)ジョイテル",
        workPlaceOrganizationId: 54,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 70,
    organizationMemberId: 5,
    organizationMemberName: "松島 湧吾",
    targetDate: "2023-06-17",
    workSchedules: [
      {
        id: 63,
        targetDate: "2023-06-17",
        eventPlaceName: "100満ボルトドン・キホーテ砺波店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=100満ボルトドン・キホーテ砺波店+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 70,
        workId: 4,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "砺波店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "米原商事(株)",
        workPlaceOrganizationId: 18,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 128,
    organizationMemberId: 15,
    organizationMemberName: "後藤1",
    targetDate: "2023-06-17",
    workSchedules: [
      {
        id: 99,
        targetDate: "2023-06-17",
        eventPlaceName: "ユナイテッドシネマ金沢",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=ユナイテッドシネマ金沢+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "後藤1",
        workScheduleAssignmentId: 129,
        workId: 7,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["ishikawa"],
        workPlaceName: "春江",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)ジョイテル",
        workPlaceOrganizationId: 54,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 14,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-17",
    workSchedules: [
      {
        id: 14,
        targetDate: "2023-06-17",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 14,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "コンサル",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 214,
    organizationMemberId: 3,
    organizationMemberName: "小島 孝介",
    targetDate: "2023-06-17",
    workSchedules: [
      {
        id: 225,
        targetDate: "2023-06-17",
        eventPlaceName: "アピタ福井大和田",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 307,
        workId: 15,
        workCategory: "normal",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 95,
    organizationMemberId: 6,
    organizationMemberName: "髙木 晶平",
    targetDate: "2023-06-17",
    workSchedules: [
      {
        id: 240,
        targetDate: "2023-06-17",
        eventPlaceName: "アピタ福井大和田",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 316,
        workId: 16,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 167,
    organizationMemberId: 21,
    organizationMemberName: "木戸脇 悠平",
    targetDate: "2023-06-17",
    workSchedules: [
      {
        id: 116,
        targetDate: "2023-06-17",
        eventPlaceName: "アル・プラザ小松",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アル・プラザ小松+",
        meetingAt: "2000-01-01T09:00:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:30:00.000+09:00",
        workingHours: 7.5,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 168,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "粟津店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 83,
    organizationMemberId: 19,
    organizationMemberName: "水上 祐介",
    targetDate: "2023-06-17",
    workSchedules: [
      {
        id: 77,
        targetDate: "2023-06-17",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 83,
        workId: 5,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa"],
        workPlaceName: "もりの里店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
        workPlaceOrganizationId: 9,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 283,
    organizationMemberId: 18,
    organizationMemberName: "網島 恭平",
    targetDate: "2023-06-17",
    workSchedules: [
      {
        id: 116,
        targetDate: "2023-06-17",
        eventPlaceName: "アル・プラザ小松",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アル・プラザ小松+",
        meetingAt: "2000-01-01T09:00:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:30:00.000+09:00",
        workingHours: 7.5,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 345,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "粟津店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 115,
    organizationMemberId: 4,
    organizationMemberName: "木戸 理恵",
    targetDate: "2023-06-18",
    workSchedules: [
      {
        id: 40,
        targetDate: "2023-06-18",
        eventPlaceName: "ワイプラザ鯖江店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=ワイプラザ鯖江店+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 115,
        workId: 2,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 136,
    organizationMemberId: 16,
    organizationMemberName: "後藤2",
    targetDate: "2023-06-18",
    workSchedules: [
      {
        id: 100,
        targetDate: "2023-06-18",
        eventPlaceName: "ユナイテッドシネマ金沢",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=ユナイテッドシネマ金沢+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "後藤2",
        workScheduleAssignmentId: 137,
        workId: 7,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["ishikawa"],
        workPlaceName: "春江",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)ジョイテル",
        workPlaceOrganizationId: 54,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 71,
    organizationMemberId: 5,
    organizationMemberName: "松島 湧吾",
    targetDate: "2023-06-18",
    workSchedules: [
      {
        id: 64,
        targetDate: "2023-06-18",
        eventPlaceName: "100満ボルトドン・キホーテ砺波店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=100満ボルトドン・キホーテ砺波店+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 71,
        workId: 4,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "砺波店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "米原商事(株)",
        workPlaceOrganizationId: 18,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 129,
    organizationMemberId: 15,
    organizationMemberName: "後藤1",
    targetDate: "2023-06-18",
    workSchedules: [
      {
        id: 100,
        targetDate: "2023-06-18",
        eventPlaceName: "ユナイテッドシネマ金沢",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=ユナイテッドシネマ金沢+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "後藤1",
        workScheduleAssignmentId: 130,
        workId: 7,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["ishikawa"],
        workPlaceName: "春江",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)ジョイテル",
        workPlaceOrganizationId: 54,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 15,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-18",
    workSchedules: [
      {
        id: 15,
        targetDate: "2023-06-18",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 15,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "コンサル",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 215,
    organizationMemberId: 3,
    organizationMemberName: "小島 孝介",
    targetDate: "2023-06-18",
    workSchedules: [
      {
        id: 226,
        targetDate: "2023-06-18",
        eventPlaceName: "アピタ福井大和田",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 308,
        workId: 15,
        workCategory: "normal",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 282,
    organizationMemberId: 18,
    organizationMemberName: "網島 恭平",
    targetDate: "2023-06-18",
    workSchedules: [
      {
        id: 117,
        targetDate: "2023-06-18",
        eventPlaceName: "アル・プラザ小松",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アル・プラザ小松+",
        meetingAt: "2000-01-01T09:20:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:30:00.000+09:00",
        workingHours: 7.5,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 344,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "粟津店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 96,
    organizationMemberId: 6,
    organizationMemberName: "髙木 晶平",
    targetDate: "2023-06-18",
    workSchedules: [
      {
        id: 241,
        targetDate: "2023-06-18",
        eventPlaceName: "アピタ福井大和田",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 317,
        workId: 16,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 168,
    organizationMemberId: 21,
    organizationMemberName: "木戸脇 悠平",
    targetDate: "2023-06-18",
    workSchedules: [
      {
        id: 117,
        targetDate: "2023-06-18",
        eventPlaceName: "アル・プラザ小松",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アル・プラザ小松+",
        meetingAt: "2000-01-01T09:20:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:30:00.000+09:00",
        workingHours: 7.5,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 169,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "粟津店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 84,
    organizationMemberId: 19,
    organizationMemberName: "水上 祐介",
    targetDate: "2023-06-19",
    workSchedules: [
      {
        id: 216,
        targetDate: "2023-06-19",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 289,
        workId: 14,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "呉羽店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
        workPlaceOrganizationId: 9,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 192,
    organizationMemberId: 3,
    organizationMemberName: "小島 孝介",
    targetDate: "2023-06-19",
    workSchedules: [
      {
        id: 192,
        targetDate: "2023-06-19",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 249,
        workId: 11,
        workCategory: "rounder",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "江守中",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)リアルメディア",
        workPlaceOrganizationId: 59,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 208,
    organizationMemberId: 1,
    organizationMemberName: "竹中 福善",
    targetDate: "2023-06-19",
    workSchedules: [
      {
        id: 227,
        targetDate: "2023-06-19",
        eventPlaceName: "アピタ福井大和田",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 301,
        workId: 15,
        workCategory: "normal",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 58,
    organizationMemberId: 18,
    organizationMemberName: "網島 恭平",
    targetDate: "2023-06-19",
    workSchedules: [
      {
        id: 202,
        targetDate: "2023-06-19",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 262,
        workId: 12,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa"],
        workPlaceName: "穴水店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 272,
    organizationMemberId: 6,
    organizationMemberName: "髙木 晶平",
    targetDate: "2023-06-19",
    workSchedules: [
      {
        id: 257,
        targetDate: "2023-06-19",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 334,
        workId: 17,
        workCategory: "fulltime",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "リアルメディア",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)新通エスピー",
        workPlaceOrganizationId: 62,
        workTypeName: "常勤",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 226,
    organizationMemberId: 17,
    organizationMemberName: "山村 朋矢",
    targetDate: "2023-06-19",
    workSchedules: [
      {
        id: 171,
        targetDate: "2023-06-19",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 227,
        workId: 10,
        workCategory: "rounder",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "高岡中央店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "(株)トリニティ",
        workPlaceOrganizationId: 17,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 16,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-19",
    workSchedules: [
      {
        id: 16,
        targetDate: "2023-06-19",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 16,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "コンサル",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 254,
    organizationMemberId: 17,
    organizationMemberName: "山村 朋矢",
    targetDate: "2023-06-20",
    workSchedules: [
      {
        id: 219,
        targetDate: "2023-06-20",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 293,
        workId: 10,
        workCategory: "rounder",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "高岡中央店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "(株)トリニティ",
        workPlaceOrganizationId: 17,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 107,
    organizationMemberId: 4,
    organizationMemberName: "木戸 理恵",
    targetDate: "2023-06-20",
    workSchedules: [
      {
        id: 17,
        targetDate: "2023-06-20",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 107,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 193,
    organizationMemberId: 3,
    organizationMemberName: "小島 孝介",
    targetDate: "2023-06-20",
    workSchedules: [
      {
        id: 193,
        targetDate: "2023-06-20",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 250,
        workId: 11,
        workCategory: "rounder",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "江守中",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)リアルメディア",
        workPlaceOrganizationId: 59,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 261,
    organizationMemberId: 5,
    organizationMemberName: "松島 湧吾",
    targetDate: "2023-06-20",
    workSchedules: [
      {
        id: 118,
        targetDate: "2023-06-20",
        eventPlaceName: "シマヤ小杉店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=シマヤ小杉店+",
        meetingAt: "2000-01-01T09:40:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 323,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "高岡野村店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 273,
    organizationMemberId: 6,
    organizationMemberName: "髙木 晶平",
    targetDate: "2023-06-20",
    workSchedules: [
      {
        id: 258,
        targetDate: "2023-06-20",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 335,
        workId: 17,
        workCategory: "fulltime",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "リアルメディア",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)新通エスピー",
        workPlaceOrganizationId: 62,
        workTypeName: "常勤",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 89,
    organizationMemberId: 5,
    organizationMemberName: "松島 湧吾",
    targetDate: "2023-06-21",
    workSchedules: [
      {
        id: 212,
        targetDate: "2023-06-21",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:30:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8.5,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 285,
        workId: 13,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "学園店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
        workPlaceOrganizationId: 9,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 274,
    organizationMemberId: 6,
    organizationMemberName: "髙木 晶平",
    targetDate: "2023-06-21",
    workSchedules: [
      {
        id: 259,
        targetDate: "2023-06-21",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 336,
        workId: 17,
        workCategory: "fulltime",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "リアルメディア",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)新通エスピー",
        workPlaceOrganizationId: 62,
        workTypeName: "常勤",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 85,
    organizationMemberId: 19,
    organizationMemberName: "水上 祐介",
    targetDate: "2023-06-21",
    workSchedules: [
      {
        id: 80,
        targetDate: "2023-06-21",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 85,
        workId: 5,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa"],
        workPlaceName: "もりの里店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
        workPlaceOrganizationId: 9,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 170,
    organizationMemberId: 18,
    organizationMemberName: "網島 恭平",
    targetDate: "2023-06-21",
    workSchedules: [
      {
        id: 119,
        targetDate: "2023-06-21",
        eventPlaceName: "シマヤ小杉店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=シマヤ小杉店+",
        meetingAt: "2000-01-01T09:40:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 171,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "高岡野村店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 142,
    organizationMemberId: 4,
    organizationMemberName: "木戸 理恵",
    targetDate: "2023-06-21",
    workSchedules: [
      {
        id: 104,
        targetDate: "2023-06-21",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 143,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 17,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-21",
    workSchedules: [
      {
        id: 18,
        targetDate: "2023-06-21",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 17,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "コンサル",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 229,
    organizationMemberId: 17,
    organizationMemberName: "山村 朋矢",
    targetDate: "2023-06-22",
    workSchedules: [
      {
        id: 173,
        targetDate: "2023-06-22",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 230,
        workId: 10,
        workCategory: "rounder",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "高岡中央店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "(株)トリニティ",
        workPlaceOrganizationId: 17,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 256,
    organizationMemberId: 1,
    organizationMemberName: "竹中 福善",
    targetDate: "2023-06-22",
    workSchedules: [
      {
        id: 213,
        targetDate: "2023-06-22",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:30:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8.5,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 295,
        workId: 13,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "学園店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
        workPlaceOrganizationId: 9,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 18,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-22",
    workSchedules: [
      {
        id: 19,
        targetDate: "2023-06-22",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 18,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "コンサル",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 86,
    organizationMemberId: 19,
    organizationMemberName: "水上 祐介",
    targetDate: "2023-06-22",
    workSchedules: [
      {
        id: 217,
        targetDate: "2023-06-22",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 290,
        workId: 14,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "呉羽店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
        workPlaceOrganizationId: 9,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 260,
    organizationMemberId: 5,
    organizationMemberName: "松島 湧吾",
    targetDate: "2023-06-22",
    workSchedules: [
      {
        id: 246,
        targetDate: "2023-06-22",
        eventPlaceName: "シマヤ小杉店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=シマヤ小杉店+",
        meetingAt: "2000-01-01T09:40:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 322,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "高岡野村店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 59,
    organizationMemberId: 18,
    organizationMemberName: "網島 恭平",
    targetDate: "2023-06-22",
    workSchedules: [
      {
        id: 203,
        targetDate: "2023-06-22",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 263,
        workId: 12,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa"],
        workPlaceName: "穴水店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 171,
    organizationMemberId: 21,
    organizationMemberName: "木戸脇 悠平",
    targetDate: "2023-06-23",
    workSchedules: [
      {
        id: 206,
        targetDate: "2023-06-23",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 265,
        workId: 12,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa"],
        workPlaceName: "穴水店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 275,
    organizationMemberId: 6,
    organizationMemberName: "髙木 晶平",
    targetDate: "2023-06-23",
    workSchedules: [
      {
        id: 260,
        targetDate: "2023-06-23",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 337,
        workId: 17,
        workCategory: "fulltime",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "リアルメディア",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)新通エスピー",
        workPlaceOrganizationId: 62,
        workTypeName: "常勤",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 172,
    organizationMemberId: 5,
    organizationMemberName: "松島 湧吾",
    targetDate: "2023-06-23",
    workSchedules: [
      {
        id: 205,
        targetDate: "2023-06-23",
        eventPlaceName: "あだちストア",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=あだちストア+",
        meetingAt: "2000-01-01T09:00:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 266,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 19,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-23",
    workSchedules: [
      {
        id: 21,
        targetDate: "2023-06-23",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 19,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "コンサル",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 209,
    organizationMemberId: 1,
    organizationMemberName: "竹中 福善",
    targetDate: "2023-06-23",
    workSchedules: [
      {
        id: 228,
        targetDate: "2023-06-23",
        eventPlaceName: "アピタ福井大和田",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 302,
        workId: 15,
        workCategory: "normal",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 116,
    organizationMemberId: 4,
    organizationMemberName: "木戸 理恵",
    targetDate: "2023-06-23",
    workSchedules: [
      {
        id: 41,
        targetDate: "2023-06-23",
        eventPlaceName: "ワイプラザ鯖江店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=ワイプラザ鯖江店+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 116,
        workId: 2,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 195,
    organizationMemberId: 3,
    organizationMemberName: "小島 孝介",
    targetDate: "2023-06-23",
    workSchedules: [
      {
        id: 194,
        targetDate: "2023-06-23",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 251,
        workId: 11,
        workCategory: "rounder",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "福井運動公園",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)ベルパーク",
        workPlaceOrganizationId: 58,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 130,
    organizationMemberId: 19,
    organizationMemberName: "水上 祐介",
    targetDate: "2023-06-23",
    workSchedules: [
      {
        id: 101,
        targetDate: "2023-06-23",
        eventPlaceName: "イオン金沢",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=イオン金沢+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 131,
        workId: 7,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["ishikawa"],
        workPlaceName: "春江",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)ジョイテル",
        workPlaceOrganizationId: 54,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 123,
    organizationMemberId: 22,
    organizationMemberName: "長岡 陸",
    targetDate: "2023-06-24",
    workSchedules: [
      {
        id: 242,
        targetDate: "2023-06-24",
        eventPlaceName: "アピタ福井大和田",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "長岡 陸",
        workScheduleAssignmentId: 318,
        workId: 16,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 173,
    organizationMemberId: 21,
    organizationMemberName: "木戸脇 悠平",
    targetDate: "2023-06-24",
    workSchedules: [
      {
        id: 121,
        targetDate: "2023-06-24",
        eventPlaceName: "業務スーパー和倉",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=業務スーパー和倉+",
        meetingAt: "2000-01-01T09:00:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 174,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 230,
    organizationMemberId: 17,
    organizationMemberName: "山村 朋矢",
    targetDate: "2023-06-24",
    workSchedules: [
      {
        id: 174,
        targetDate: "2023-06-24",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 231,
        workId: 10,
        workCategory: "rounder",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "高岡中央店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "(株)トリニティ",
        workPlaceOrganizationId: 17,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 276,
    organizationMemberId: 6,
    organizationMemberName: "髙木 晶平",
    targetDate: "2023-06-24",
    workSchedules: [
      {
        id: 261,
        targetDate: "2023-06-24",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 338,
        workId: 17,
        workCategory: "fulltime",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "リアルメディア",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)新通エスピー",
        workPlaceOrganizationId: 62,
        workTypeName: "常勤",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 137,
    organizationMemberId: 16,
    organizationMemberName: "後藤2",
    targetDate: "2023-06-24",
    workSchedules: [
      {
        id: 102,
        targetDate: "2023-06-24",
        eventPlaceName: "イオン金沢",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=イオン金沢+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "後藤2",
        workScheduleAssignmentId: 138,
        workId: 7,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["ishikawa"],
        workPlaceName: "春江",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)ジョイテル",
        workPlaceOrganizationId: 54,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 210,
    organizationMemberId: 1,
    organizationMemberName: "竹中 福善",
    targetDate: "2023-06-24",
    workSchedules: [
      {
        id: 229,
        targetDate: "2023-06-24",
        eventPlaceName: "アピタ福井大和田",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 303,
        workId: 15,
        workCategory: "normal",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 117,
    organizationMemberId: 4,
    organizationMemberName: "木戸 理恵",
    targetDate: "2023-06-24",
    workSchedules: [
      {
        id: 42,
        targetDate: "2023-06-24",
        eventPlaceName: "ワイプラザ鯖江店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=ワイプラザ鯖江店+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 117,
        workId: 2,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 72,
    organizationMemberId: 5,
    organizationMemberName: "松島 湧吾",
    targetDate: "2023-06-24",
    workSchedules: [
      {
        id: 65,
        targetDate: "2023-06-24",
        eventPlaceName: "ドン・キホーテ砺波店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=ドン・キホーテ砺波店+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 72,
        workId: 4,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "砺波店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "米原商事(株)",
        workPlaceOrganizationId: 18,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 174,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-24",
    workSchedules: [
      {
        id: 121,
        targetDate: "2023-06-24",
        eventPlaceName: "業務スーパー和倉",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=業務スーパー和倉+",
        meetingAt: "2000-01-01T09:00:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 175,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 60,
    organizationMemberId: 18,
    organizationMemberName: "網島 恭平",
    targetDate: "2023-06-24",
    workSchedules: [
      {
        id: 53,
        targetDate: "2023-06-24",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 60,
        workId: 3,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 196,
    organizationMemberId: 3,
    organizationMemberName: "小島 孝介",
    targetDate: "2023-06-24",
    workSchedules: [
      {
        id: 195,
        targetDate: "2023-06-24",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 255,
        workId: 11,
        workCategory: "rounder",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "御幸",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)リアルメディア",
        workPlaceOrganizationId: 59,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 131,
    organizationMemberId: 19,
    organizationMemberName: "水上 祐介",
    targetDate: "2023-06-24",
    workSchedules: [
      {
        id: 102,
        targetDate: "2023-06-24",
        eventPlaceName: "イオン金沢",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=イオン金沢+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 132,
        workId: 7,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["ishikawa"],
        workPlaceName: "春江",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)ジョイテル",
        workPlaceOrganizationId: 54,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 124,
    organizationMemberId: 22,
    organizationMemberName: "長岡 陸",
    targetDate: "2023-06-25",
    workSchedules: [
      {
        id: 243,
        targetDate: "2023-06-25",
        eventPlaceName: "アピタ福井大和田",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "長岡 陸",
        workScheduleAssignmentId: 319,
        workId: 16,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 175,
    organizationMemberId: 21,
    organizationMemberName: "木戸脇 悠平",
    targetDate: "2023-06-25",
    workSchedules: [
      {
        id: 122,
        targetDate: "2023-06-25",
        eventPlaceName: "業務スーパー和倉",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=業務スーパー和倉+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 176,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 231,
    organizationMemberId: 17,
    organizationMemberName: "山村 朋矢",
    targetDate: "2023-06-25",
    workSchedules: [
      {
        id: 175,
        targetDate: "2023-06-25",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 232,
        workId: 10,
        workCategory: "rounder",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "高岡中央店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "(株)トリニティ",
        workPlaceOrganizationId: 17,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 277,
    organizationMemberId: 6,
    organizationMemberName: "髙木 晶平",
    targetDate: "2023-06-25",
    workSchedules: [
      {
        id: 262,
        targetDate: "2023-06-25",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 339,
        workId: 17,
        workCategory: "fulltime",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "リアルメディア",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)新通エスピー",
        workPlaceOrganizationId: 62,
        workTypeName: "常勤",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 138,
    organizationMemberId: 16,
    organizationMemberName: "後藤2",
    targetDate: "2023-06-25",
    workSchedules: [
      {
        id: 103,
        targetDate: "2023-06-25",
        eventPlaceName: "イオン金沢",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=イオン金沢+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "後藤2",
        workScheduleAssignmentId: 139,
        workId: 7,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["ishikawa"],
        workPlaceName: "春江",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)ジョイテル",
        workPlaceOrganizationId: 54,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 211,
    organizationMemberId: 1,
    organizationMemberName: "竹中 福善",
    targetDate: "2023-06-25",
    workSchedules: [
      {
        id: 230,
        targetDate: "2023-06-25",
        eventPlaceName: "アピタ福井大和田",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 304,
        workId: 15,
        workCategory: "normal",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 118,
    organizationMemberId: 4,
    organizationMemberName: "木戸 理恵",
    targetDate: "2023-06-25",
    workSchedules: [
      {
        id: 43,
        targetDate: "2023-06-25",
        eventPlaceName: "ワイプラザ鯖江店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=ワイプラザ鯖江店+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 118,
        workId: 2,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 73,
    organizationMemberId: 5,
    organizationMemberName: "松島 湧吾",
    targetDate: "2023-06-25",
    workSchedules: [
      {
        id: 66,
        targetDate: "2023-06-25",
        eventPlaceName: "ドン・キホーテ砺波店",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=ドン・キホーテ砺波店+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 73,
        workId: 4,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "砺波店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "米原商事(株)",
        workPlaceOrganizationId: 18,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 176,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-25",
    workSchedules: [
      {
        id: 122,
        targetDate: "2023-06-25",
        eventPlaceName: "業務スーパー和倉",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=業務スーパー和倉+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 177,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 61,
    organizationMemberId: 18,
    organizationMemberName: "網島 恭平",
    targetDate: "2023-06-25",
    workSchedules: [
      {
        id: 54,
        targetDate: "2023-06-25",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 61,
        workId: 3,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 197,
    organizationMemberId: 3,
    organizationMemberName: "小島 孝介",
    targetDate: "2023-06-25",
    workSchedules: [
      {
        id: 196,
        targetDate: "2023-06-25",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 252,
        workId: 11,
        workCategory: "rounder",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "リアルメディア",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)新通エスピー",
        workPlaceOrganizationId: 62,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 132,
    organizationMemberId: 19,
    organizationMemberName: "水上 祐介",
    targetDate: "2023-06-25",
    workSchedules: [
      {
        id: 103,
        targetDate: "2023-06-25",
        eventPlaceName: "イオン金沢",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=イオン金沢+",
        meetingAt: "2000-01-01T09:30:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 2,
        currentCount: 2,
        enoughCount: true,
        done: null,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 133,
        workId: 7,
        workCategory: "event_helper",
        workCarrier: "softbank",
        workPrefectures: ["ishikawa"],
        workPlaceName: "春江",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)ジョイテル",
        workPlaceOrganizationId: 54,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 237,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-26",
    workSchedules: [
      {
        id: 22,
        targetDate: "2023-06-26",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 258,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "コンサル",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 62,
    organizationMemberId: 18,
    organizationMemberName: "網島 恭平",
    targetDate: "2023-06-26",
    workSchedules: [
      {
        id: 55,
        targetDate: "2023-06-26",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 62,
        workId: 3,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 198,
    organizationMemberId: 3,
    organizationMemberName: "小島 孝介",
    targetDate: "2023-06-26",
    workSchedules: [
      {
        id: 197,
        targetDate: "2023-06-26",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 256,
        workId: 11,
        workCategory: "rounder",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "福井運動公園",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)ベルパーク",
        workPlaceOrganizationId: 58,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 232,
    organizationMemberId: 17,
    organizationMemberName: "山村 朋矢",
    targetDate: "2023-06-26",
    workSchedules: [
      {
        id: 176,
        targetDate: "2023-06-26",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 233,
        workId: 10,
        workCategory: "rounder",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "高岡中央店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "(株)トリニティ",
        workPlaceOrganizationId: 17,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 278,
    organizationMemberId: 6,
    organizationMemberName: "髙木 晶平",
    targetDate: "2023-06-26",
    workSchedules: [
      {
        id: 263,
        targetDate: "2023-06-26",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 340,
        workId: 17,
        workCategory: "fulltime",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "リアルメディア",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)新通エスピー",
        workPlaceOrganizationId: 62,
        workTypeName: "常勤",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 212,
    organizationMemberId: 1,
    organizationMemberName: "竹中 福善",
    targetDate: "2023-06-26",
    workSchedules: [
      {
        id: 231,
        targetDate: "2023-06-26",
        eventPlaceName: "アピタ福井大和田",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 305,
        workId: 15,
        workCategory: "normal",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 87,
    organizationMemberId: 5,
    organizationMemberName: "松島 湧吾",
    targetDate: "2023-06-26",
    workSchedules: [
      {
        id: 214,
        targetDate: "2023-06-26",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:30:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 8.5,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 287,
        workId: 13,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "学園店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
        workPlaceOrganizationId: 9,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 238,
    organizationMemberId: 4,
    organizationMemberName: "木戸 理恵",
    targetDate: "2023-06-26",
    workSchedules: [
      {
        id: 105,
        targetDate: "2023-06-26",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 259,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 65,
    organizationMemberId: 1,
    organizationMemberName: "竹中 福善",
    targetDate: "2023-06-27",
    workSchedules: [
      {
        id: 57,
        targetDate: "2023-06-27",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 65,
        workId: 3,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 63,
    organizationMemberId: 18,
    organizationMemberName: "網島 恭平",
    targetDate: "2023-06-27",
    workSchedules: [
      {
        id: 204,
        targetDate: "2023-06-27",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 264,
        workId: 12,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa"],
        workPlaceName: "穴水店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 199,
    organizationMemberId: 3,
    organizationMemberName: "小島 孝介",
    targetDate: "2023-06-27",
    workSchedules: [
      {
        id: 198,
        targetDate: "2023-06-27",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 253,
        workId: 11,
        workCategory: "rounder",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "御幸",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)リアルメディア",
        workPlaceOrganizationId: 59,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 21,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-27",
    workSchedules: [
      {
        id: 23,
        targetDate: "2023-06-27",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 21,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "コンサル",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 177,
    organizationMemberId: 21,
    organizationMemberName: "木戸脇 悠平",
    targetDate: "2023-06-27",
    workSchedules: [
      {
        id: 123,
        targetDate: "2023-06-27",
        eventPlaceName: "ロッキー羽咋",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=ロッキー羽咋+",
        meetingAt: "2000-01-01T09:00:00.000+09:00",
        startAt: "2000-01-01T09:30:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7.5,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 178,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 64,
    organizationMemberId: 18,
    organizationMemberName: "網島 恭平",
    targetDate: "2023-06-28",
    workSchedules: [
      {
        id: 58,
        targetDate: "2023-06-28",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 64,
        workId: 3,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 109,
    organizationMemberId: 4,
    organizationMemberName: "木戸 理恵",
    targetDate: "2023-06-28",
    workSchedules: [
      {
        id: 25,
        targetDate: "2023-06-28",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 109,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 178,
    organizationMemberId: 21,
    organizationMemberName: "木戸脇 悠平",
    targetDate: "2023-06-28",
    workSchedules: [
      {
        id: 124,
        targetDate: "2023-06-28",
        eventPlaceName: "ロッキー羽咋",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=ロッキー羽咋+",
        meetingAt: "2000-01-01T09:00:00.000+09:00",
        startAt: "2000-01-01T09:30:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        workingHours: 7.5,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 179,
        workId: 8,
        workCategory: "event_helper",
        workCarrier: "docomo",
        workPrefectures: ["ishikawa", "toyama"],
        workPlaceName: "羽咋店",
        workPlacePrefecture: "ishikawa",
        workPlaceOrganizationName: "中部特機産業(株)",
        workPlaceOrganizationId: 10,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 233,
    organizationMemberId: 17,
    organizationMemberName: "山村 朋矢",
    targetDate: "2023-06-28",
    workSchedules: [
      {
        id: 177,
        targetDate: "2023-06-28",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 234,
        workId: 10,
        workCategory: "rounder",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "高岡中央店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "(株)トリニティ",
        workPlaceOrganizationId: 17,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 279,
    organizationMemberId: 6,
    organizationMemberName: "髙木 晶平",
    targetDate: "2023-06-28",
    workSchedules: [
      {
        id: 264,
        targetDate: "2023-06-28",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 341,
        workId: 17,
        workCategory: "fulltime",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "リアルメディア",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)新通エスピー",
        workPlaceOrganizationId: 62,
        workTypeName: "常勤",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 30,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-28",
    workSchedules: [
      {
        id: 24,
        targetDate: "2023-06-28",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 30,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "コンサル",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 280,
    organizationMemberId: 6,
    organizationMemberName: "髙木 晶平",
    targetDate: "2023-06-29",
    workSchedules: [
      {
        id: 265,
        targetDate: "2023-06-29",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 342,
        workId: 17,
        workCategory: "fulltime",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "リアルメディア",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)新通エスピー",
        workPlaceOrganizationId: 62,
        workTypeName: "常勤",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 200,
    organizationMemberId: 3,
    organizationMemberName: "小島 孝介",
    targetDate: "2023-06-29",
    workSchedules: [
      {
        id: 199,
        targetDate: "2023-06-29",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 254,
        workId: 11,
        workCategory: "rounder",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "御幸",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)リアルメディア",
        workPlaceOrganizationId: 59,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 88,
    organizationMemberId: 19,
    organizationMemberName: "水上 祐介",
    targetDate: "2023-06-29",
    workSchedules: [
      {
        id: 218,
        targetDate: "2023-06-29",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 291,
        workId: 14,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "呉羽店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
        workPlaceOrganizationId: 9,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 110,
    organizationMemberId: 4,
    organizationMemberName: "木戸 理恵",
    targetDate: "2023-06-29",
    workSchedules: [
      {
        id: 27,
        targetDate: "2023-06-29",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 110,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 23,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-29",
    workSchedules: [
      {
        id: 26,
        targetDate: "2023-06-29",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 23,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "コンサル",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 234,
    organizationMemberId: 17,
    organizationMemberName: "山村 朋矢",
    targetDate: "2023-06-29",
    workSchedules: [
      {
        id: 178,
        targetDate: "2023-06-29",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 235,
        workId: 10,
        workCategory: "rounder",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "高岡中央店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "(株)トリニティ",
        workPlaceOrganizationId: 17,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 235,
    organizationMemberId: 17,
    organizationMemberName: "山村 朋矢",
    targetDate: "2023-06-30",
    workSchedules: [
      {
        id: 179,
        targetDate: "2023-06-30",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 236,
        workId: 10,
        workCategory: "rounder",
        workCarrier: "docomo",
        workPrefectures: ["toyama"],
        workPlaceName: "高岡中央店",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "(株)トリニティ",
        workPlaceOrganizationId: 17,
        workTypeName: "ラウンダー",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 281,
    organizationMemberId: 6,
    organizationMemberName: "髙木 晶平",
    targetDate: "2023-06-30",
    workSchedules: [
      {
        id: 266,
        targetDate: "2023-06-30",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 343,
        workId: 17,
        workCategory: "fulltime",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "リアルメディア",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)新通エスピー",
        workPlaceOrganizationId: 62,
        workTypeName: "常勤",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 111,
    organizationMemberId: 4,
    organizationMemberName: "木戸 理恵",
    targetDate: "2023-06-30",
    workSchedules: [
      {
        id: 29,
        targetDate: "2023-06-30",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 111,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "獲得HP",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 25,
    organizationMemberId: 20,
    organizationMemberName: "井上 敬太",
    targetDate: "2023-06-30",
    workSchedules: [
      {
        id: 28,
        targetDate: "2023-06-30",
        eventPlaceName: null,
        eventPlaceGmapUrl: null,
        meetingAt: null,
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: false,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 25,
        workId: 1,
        workCategory: "shop_helper",
        workCarrier: "docomo",
        workPrefectures: ["fukui"],
        workPlaceName: "武生店",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "カワイ(株)",
        workPlaceOrganizationId: 22,
        workTypeName: "コンサル",
      },
    ],
    restTimeSchedules: [],
  },
  {
    id: 213,
    organizationMemberId: 1,
    organizationMemberName: "竹中 福善",
    targetDate: "2023-06-30",
    workSchedules: [
      {
        id: 232,
        targetDate: "2023-06-30",
        eventPlaceName: "アピタ福井大和田",
        eventPlaceGmapUrl:
          "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
        meetingAt: "2000-01-01T09:50:00.000+09:00",
        startAt: "2000-01-01T10:00:00.000+09:00",
        finishAt: "2000-01-01T19:00:00.000+09:00",
        workingHours: 9,
        needBodyTemperature: true,
        needAntibodyTestWithin: 0,
        needCount: 1,
        currentCount: 1,
        enoughCount: true,
        done: null,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 306,
        workId: 15,
        workCategory: "normal",
        workCarrier: "softbank",
        workPrefectures: ["fukui"],
        workPlaceName: "アピタ福井大和田",
        workPlacePrefecture: "fukui",
        workPlaceOrganizationName: "(株)NDP",
        workPlaceOrganizationId: 55,
        workTypeName: "クローザー",
      },
    ],
    restTimeSchedules: [],
  },
];

export const workAsPlans: WorkAsPlan[] = [
  {
    id: 6,
    organizationId: 1,
    workCategory: "rounder",
    startOn: "2023-03-01",
    finishOn: "2023-03-31",
    prefectures: ["ishikawa"],
    carrier: "softbank",
    workOrganizations: [
      {
        id: 49,
        name: "(株)ジョイテル",
      },
    ],
    workPlaces: [
      {
        id: 153,
        name: "イオンタウン羽咋",
      },
    ],
    workSchedules: [
      {
        id: 10,
        workId: 6,
        workPlaceId: 174,
        targetDate: "2023-03-28",
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
      },
      {
        id: 11,
        workId: 6,
        workPlaceId: 174,
        targetDate: "2023-03-29",
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
      },
      {
        id: 12,
        workId: 6,
        workPlaceId: 176,
        targetDate: "2023-03-14",
        startAt: "2000-01-01T09:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
      },
    ],
  },
  {
    id: 7,
    organizationId: 1,
    workCategory: "shop_helper",
    startOn: "2023-03-01",
    finishOn: "2023-04-30",
    prefectures: ["toyama"],
    carrier: "au",
    workOrganizations: [
      {
        id: 35,
        name: "(株)ダイエー通信",
      },
    ],
    workPlaces: [
      {
        id: 115,
        name: "婦中",
      },
    ],
    workSchedules: [
      {
        id: 13,
        workId: 7,
        workPlaceId: 115,
        targetDate: "2023-03-27",
        startAt: "2000-01-01T08:00:00.000+09:00",
        finishAt: "2000-01-01T16:00:00.000+09:00",
      },
      {
        id: 14,
        workId: 7,
        workPlaceId: 115,
        targetDate: "2023-03-28",
        startAt: "2000-01-01T08:00:00.000+09:00",
        finishAt: "2000-01-01T16:00:00.000+09:00",
      },
      {
        id: 15,
        workId: 7,
        workPlaceId: 115,
        targetDate: "2023-03-29",
        startAt: "2000-01-01T08:00:00.000+09:00",
        finishAt: "2000-01-01T16:00:00.000+09:00",
      },
      {
        id: 16,
        workId: 7,
        workPlaceId: 115,
        targetDate: "2023-03-30",
        startAt: "2000-01-01T08:00:00.000+09:00",
        finishAt: "2000-01-01T16:00:00.000+09:00",
      },
      {
        id: 17,
        workId: 7,
        workPlaceId: 115,
        targetDate: "2023-03-31",
        startAt: "2000-01-01T08:00:00.000+09:00",
        finishAt: "2000-01-01T16:00:00.000+09:00",
      },
      {
        id: 23,
        workId: 7,
        workPlaceId: 115,
        targetDate: "2023-04-03",
        startAt: "2000-01-01T07:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
      },
      {
        id: 24,
        workId: 7,
        workPlaceId: 115,
        targetDate: "2023-04-04",
        startAt: "2000-01-01T07:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
      },
      {
        id: 25,
        workId: 7,
        workPlaceId: 115,
        targetDate: "2023-04-05",
        startAt: "2000-01-01T07:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
      },
      {
        id: 26,
        workId: 7,
        workPlaceId: 115,
        targetDate: "2023-04-06",
        startAt: "2000-01-01T07:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
      },
      {
        id: 27,
        workId: 7,
        workPlaceId: 115,
        targetDate: "2023-04-07",
        startAt: "2000-01-01T07:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
      },
    ],
  },
  {
    id: 8,
    organizationId: 1,
    workCategory: "shop_helper",
    startOn: "2023-03-01",
    finishOn: "2023-04-30",
    prefectures: ["toyama"],
    carrier: "softbank",
    workOrganizations: [
      {
        id: 52,
        name: "(株)コスモネット",
      },
    ],
    workPlaces: [
      {
        id: 196,
        name: "ファボーレ",
      },
    ],
    workSchedules: [
      {
        id: 18,
        workId: 8,
        workPlaceId: 196,
        targetDate: "2023-03-27",
        startAt: "2000-01-01T08:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
      },
      {
        id: 19,
        workId: 8,
        workPlaceId: 196,
        targetDate: "2023-03-28",
        startAt: "2000-01-01T08:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
      },
      {
        id: 20,
        workId: 8,
        workPlaceId: 196,
        targetDate: "2023-03-29",
        startAt: "2000-01-01T08:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
      },
      {
        id: 21,
        workId: 8,
        workPlaceId: 196,
        targetDate: "2023-03-30",
        startAt: "2000-01-01T08:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
      },
      {
        id: 22,
        workId: 8,
        workPlaceId: 196,
        targetDate: "2023-03-31",
        startAt: "2000-01-01T08:00:00.000+09:00",
        finishAt: "2000-01-01T18:00:00.000+09:00",
      },
    ],
  },
];

export const workScheduleAsPlans: WorkScheduleAsPlan[] = [
  {
    id: 164,
    targetDate: "2023-06-07",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-07T09:00:00.000+09:00",
    finishAt: "2023-06-07T18:00:00.000+09:00",
    workPlace: {
      id: 34,
      name: "高岡中央店",
      organizationName: "(株)トリニティ",
    },
    work: {
      id: 10,
      workCategory: "rounder",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 17,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 221,
        schedulePlanId: 220,
      },
    ],
    workId: 10,
    workCategory: "rounder",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "高岡中央店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "(株)トリニティ",
    workPlaceOrganizationId: 17,
  },
  {
    id: 215,
    targetDate: "2023-06-08",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-08T09:00:00.000+09:00",
    finishAt: "2023-06-08T18:00:00.000+09:00",
    workPlace: {
      id: 51,
      name: "呉羽店",
      organizationName: "兼松コミュニケーションズ(株)",
    },
    work: {
      id: 14,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 19,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 288,
        schedulePlanId: 78,
      },
    ],
    workId: 14,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "呉羽店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
    workPlaceOrganizationId: 9,
  },
  {
    id: 167,
    targetDate: "2023-06-12",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-12T09:00:00.000+09:00",
    finishAt: "2023-06-12T18:00:00.000+09:00",
    workPlace: {
      id: 34,
      name: "高岡中央店",
      organizationName: "(株)トリニティ",
    },
    work: {
      id: 10,
      workCategory: "rounder",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 17,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 225,
        schedulePlanId: 224,
      },
    ],
    workId: 10,
    workCategory: "rounder",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "高岡中央店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "(株)トリニティ",
    workPlaceOrganizationId: 17,
  },
  {
    id: 218,
    targetDate: "2023-06-29",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-29T09:00:00.000+09:00",
    finishAt: "2023-06-29T18:00:00.000+09:00",
    workPlace: {
      id: 51,
      name: "呉羽店",
      organizationName: "兼松コミュニケーションズ(株)",
    },
    work: {
      id: 14,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 19,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 291,
        schedulePlanId: 88,
      },
    ],
    workId: 14,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "呉羽店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
    workPlaceOrganizationId: 9,
  },
  {
    id: 170,
    targetDate: "2023-06-16",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-16T09:00:00.000+09:00",
    finishAt: "2023-06-16T18:00:00.000+09:00",
    workPlace: {
      id: 34,
      name: "高岡中央店",
      organizationName: "(株)トリニティ",
    },
    work: {
      id: 10,
      workCategory: "rounder",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 17,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 228,
        schedulePlanId: 227,
      },
    ],
    workId: 10,
    workCategory: "rounder",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "高岡中央店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "(株)トリニティ",
    workPlaceOrganizationId: 17,
  },
  {
    id: 174,
    targetDate: "2023-06-24",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-24T09:00:00.000+09:00",
    finishAt: "2023-06-24T18:00:00.000+09:00",
    workPlace: {
      id: 34,
      name: "高岡中央店",
      organizationName: "(株)トリニティ",
    },
    work: {
      id: 10,
      workCategory: "rounder",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 17,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 231,
        schedulePlanId: 230,
      },
    ],
    workId: 10,
    workCategory: "rounder",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "高岡中央店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "(株)トリニティ",
    workPlaceOrganizationId: 17,
  },
  {
    id: 177,
    targetDate: "2023-06-28",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-28T09:00:00.000+09:00",
    finishAt: "2023-06-28T18:00:00.000+09:00",
    workPlace: {
      id: 34,
      name: "高岡中央店",
      organizationName: "(株)トリニティ",
    },
    work: {
      id: 10,
      workCategory: "rounder",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 17,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 234,
        schedulePlanId: 233,
      },
    ],
    workId: 10,
    workCategory: "rounder",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "高岡中央店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "(株)トリニティ",
    workPlaceOrganizationId: 17,
  },
  {
    id: 219,
    targetDate: "2023-06-20",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-20T09:00:00.000+09:00",
    finishAt: "2023-06-20T18:00:00.000+09:00",
    workPlace: {
      id: 34,
      name: "高岡中央店",
      organizationName: "(株)トリニティ",
    },
    work: {
      id: 10,
      workCategory: "rounder",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 17,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 293,
        schedulePlanId: 254,
      },
    ],
    workId: 10,
    workCategory: "rounder",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "高岡中央店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "(株)トリニティ",
    workPlaceOrganizationId: 17,
  },
  {
    id: 45,
    targetDate: "2023-06-05",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-05T09:00:00.000+09:00",
    finishAt: "2023-06-05T18:00:00.000+09:00",
    workPlace: {
      id: 3,
      name: "羽咋店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 3,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["ishikawa"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 18,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 52,
        schedulePlanId: 52,
      },
    ],
    workId: 3,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa"],
    workPlaceName: "羽咋店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 49,
    targetDate: "2023-06-13",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-13T09:00:00.000+09:00",
    finishAt: "2023-06-13T18:00:00.000+09:00",
    workPlace: {
      id: 3,
      name: "羽咋店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 3,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["ishikawa"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 18,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 56,
        schedulePlanId: 56,
      },
    ],
    workId: 3,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa"],
    workPlaceName: "羽咋店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 202,
    targetDate: "2023-06-19",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-19T09:00:00.000+09:00",
    finishAt: "2023-06-19T18:00:00.000+09:00",
    workPlace: {
      id: 26,
      name: "穴水店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 12,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["ishikawa"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 18,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 262,
        schedulePlanId: 58,
      },
    ],
    workId: 12,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa"],
    workPlaceName: "穴水店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 54,
    targetDate: "2023-06-25",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-25T09:00:00.000+09:00",
    finishAt: "2023-06-25T18:00:00.000+09:00",
    workPlace: {
      id: 3,
      name: "羽咋店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 3,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["ishikawa"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 18,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 61,
        schedulePlanId: 61,
      },
    ],
    workId: 3,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa"],
    workPlaceName: "羽咋店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 58,
    targetDate: "2023-06-28",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-28T09:00:00.000+09:00",
    finishAt: "2023-06-28T18:00:00.000+09:00",
    workPlace: {
      id: 3,
      name: "羽咋店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 3,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["ishikawa"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 18,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 64,
        schedulePlanId: 64,
      },
    ],
    workId: 3,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa"],
    workPlaceName: "羽咋店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 206,
    targetDate: "2023-06-23",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-23T09:00:00.000+09:00",
    finishAt: "2023-06-23T18:00:00.000+09:00",
    workPlace: {
      id: 26,
      name: "穴水店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 12,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["ishikawa"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 21,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 265,
        schedulePlanId: 171,
      },
    ],
    workId: 12,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa"],
    workPlaceName: "穴水店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 162,
    targetDate: "2023-06-04",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-04T09:00:00.000+09:00",
    finishAt: "2023-06-04T18:00:00.000+09:00",
    workPlace: {
      id: 34,
      name: "高岡中央店",
      organizationName: "(株)トリニティ",
    },
    work: {
      id: 10,
      workCategory: "rounder",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 17,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 219,
        schedulePlanId: 218,
      },
    ],
    workId: 10,
    workCategory: "rounder",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "高岡中央店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "(株)トリニティ",
    workPlaceOrganizationId: 17,
  },
  {
    id: 165,
    targetDate: "2023-06-08",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-08T09:00:00.000+09:00",
    finishAt: "2023-06-08T18:00:00.000+09:00",
    workPlace: {
      id: 34,
      name: "高岡中央店",
      organizationName: "(株)トリニティ",
    },
    work: {
      id: 10,
      workCategory: "rounder",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 17,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 222,
        schedulePlanId: 221,
      },
    ],
    workId: 10,
    workCategory: "rounder",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "高岡中央店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "(株)トリニティ",
    workPlaceOrganizationId: 17,
  },
  {
    id: 216,
    targetDate: "2023-06-19",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-19T09:00:00.000+09:00",
    finishAt: "2023-06-19T18:00:00.000+09:00",
    workPlace: {
      id: 51,
      name: "呉羽店",
      organizationName: "兼松コミュニケーションズ(株)",
    },
    work: {
      id: 14,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 19,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 289,
        schedulePlanId: 84,
      },
    ],
    workId: 14,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "呉羽店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
    workPlaceOrganizationId: 9,
  },
  {
    id: 168,
    targetDate: "2023-06-13",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-13T09:00:00.000+09:00",
    finishAt: "2023-06-13T18:00:00.000+09:00",
    workPlace: {
      id: 34,
      name: "高岡中央店",
      organizationName: "(株)トリニティ",
    },
    work: {
      id: 10,
      workCategory: "rounder",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 17,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 224,
        schedulePlanId: 223,
      },
    ],
    workId: 10,
    workCategory: "rounder",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "高岡中央店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "(株)トリニティ",
    workPlaceOrganizationId: 17,
  },
  {
    id: 171,
    targetDate: "2023-06-19",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-19T09:00:00.000+09:00",
    finishAt: "2023-06-19T18:00:00.000+09:00",
    workPlace: {
      id: 34,
      name: "高岡中央店",
      organizationName: "(株)トリニティ",
    },
    work: {
      id: 10,
      workCategory: "rounder",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 17,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 227,
        schedulePlanId: 226,
      },
    ],
    workId: 10,
    workCategory: "rounder",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "高岡中央店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "(株)トリニティ",
    workPlaceOrganizationId: 17,
  },
  {
    id: 175,
    targetDate: "2023-06-25",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-25T09:00:00.000+09:00",
    finishAt: "2023-06-25T18:00:00.000+09:00",
    workPlace: {
      id: 34,
      name: "高岡中央店",
      organizationName: "(株)トリニティ",
    },
    work: {
      id: 10,
      workCategory: "rounder",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 17,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 232,
        schedulePlanId: 231,
      },
    ],
    workId: 10,
    workCategory: "rounder",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "高岡中央店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "(株)トリニティ",
    workPlaceOrganizationId: 17,
  },
  {
    id: 178,
    targetDate: "2023-06-29",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-29T09:00:00.000+09:00",
    finishAt: "2023-06-29T18:00:00.000+09:00",
    workPlace: {
      id: 34,
      name: "高岡中央店",
      organizationName: "(株)トリニティ",
    },
    work: {
      id: 10,
      workCategory: "rounder",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 17,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 235,
        schedulePlanId: 234,
      },
    ],
    workId: 10,
    workCategory: "rounder",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "高岡中央店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "(株)トリニティ",
    workPlaceOrganizationId: 17,
  },
  {
    id: 46,
    targetDate: "2023-06-06",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-06T09:00:00.000+09:00",
    finishAt: "2023-06-06T18:00:00.000+09:00",
    workPlace: {
      id: 3,
      name: "羽咋店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 3,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["ishikawa"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 18,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 53,
        schedulePlanId: 53,
      },
    ],
    workId: 3,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa"],
    workPlaceName: "羽咋店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 50,
    targetDate: "2023-06-16",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-16T09:00:00.000+09:00",
    finishAt: "2023-06-16T18:00:00.000+09:00",
    workPlace: {
      id: 3,
      name: "羽咋店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 3,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["ishikawa"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 18,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 57,
        schedulePlanId: 57,
      },
    ],
    workId: 3,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa"],
    workPlaceName: "羽咋店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 200,
    targetDate: "2023-06-01",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-01T09:00:00.000+09:00",
    finishAt: "2023-06-01T18:00:00.000+09:00",
    workPlace: {
      id: 26,
      name: "穴水店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 12,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["ishikawa"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 18,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 260,
        schedulePlanId: 51,
      },
    ],
    workId: 12,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa"],
    workPlaceName: "穴水店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 55,
    targetDate: "2023-06-26",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-26T09:00:00.000+09:00",
    finishAt: "2023-06-26T18:00:00.000+09:00",
    workPlace: {
      id: 3,
      name: "羽咋店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 3,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["ishikawa"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 18,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 62,
        schedulePlanId: 62,
      },
    ],
    workId: 3,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa"],
    workPlaceName: "羽咋店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 203,
    targetDate: "2023-06-22",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-22T09:00:00.000+09:00",
    finishAt: "2023-06-22T18:00:00.000+09:00",
    workPlace: {
      id: 26,
      name: "穴水店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 12,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["ishikawa"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 18,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 263,
        schedulePlanId: 59,
      },
    ],
    workId: 12,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa"],
    workPlaceName: "穴水店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 160,
    targetDate: "2023-06-01",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-01T09:00:00.000+09:00",
    finishAt: "2023-06-01T18:00:00.000+09:00",
    workPlace: {
      id: 34,
      name: "高岡中央店",
      organizationName: "(株)トリニティ",
    },
    work: {
      id: 10,
      workCategory: "rounder",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 17,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 217,
        schedulePlanId: 216,
      },
    ],
    workId: 10,
    workCategory: "rounder",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "高岡中央店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "(株)トリニティ",
    workPlaceOrganizationId: 17,
  },
  {
    id: 163,
    targetDate: "2023-06-05",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-05T09:00:00.000+09:00",
    finishAt: "2023-06-05T18:00:00.000+09:00",
    workPlace: {
      id: 34,
      name: "高岡中央店",
      organizationName: "(株)トリニティ",
    },
    work: {
      id: 10,
      workCategory: "rounder",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 17,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 220,
        schedulePlanId: 219,
      },
    ],
    workId: 10,
    workCategory: "rounder",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "高岡中央店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "(株)トリニティ",
    workPlaceOrganizationId: 17,
  },
  {
    id: 166,
    targetDate: "2023-06-11",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-11T09:00:00.000+09:00",
    finishAt: "2023-06-11T18:00:00.000+09:00",
    workPlace: {
      id: 34,
      name: "高岡中央店",
      organizationName: "(株)トリニティ",
    },
    work: {
      id: 10,
      workCategory: "rounder",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 17,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 223,
        schedulePlanId: 222,
      },
    ],
    workId: 10,
    workCategory: "rounder",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "高岡中央店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "(株)トリニティ",
    workPlaceOrganizationId: 17,
  },
  {
    id: 217,
    targetDate: "2023-06-22",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-22T09:00:00.000+09:00",
    finishAt: "2023-06-22T18:00:00.000+09:00",
    workPlace: {
      id: 51,
      name: "呉羽店",
      organizationName: "兼松コミュニケーションズ(株)",
    },
    work: {
      id: 14,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 19,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 290,
        schedulePlanId: 86,
      },
    ],
    workId: 14,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "呉羽店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
    workPlaceOrganizationId: 9,
  },
  {
    id: 169,
    targetDate: "2023-06-15",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-15T09:00:00.000+09:00",
    finishAt: "2023-06-15T18:00:00.000+09:00",
    workPlace: {
      id: 34,
      name: "高岡中央店",
      organizationName: "(株)トリニティ",
    },
    work: {
      id: 10,
      workCategory: "rounder",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 17,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 226,
        schedulePlanId: 225,
      },
    ],
    workId: 10,
    workCategory: "rounder",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "高岡中央店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "(株)トリニティ",
    workPlaceOrganizationId: 17,
  },
  {
    id: 173,
    targetDate: "2023-06-22",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-22T09:00:00.000+09:00",
    finishAt: "2023-06-22T18:00:00.000+09:00",
    workPlace: {
      id: 34,
      name: "高岡中央店",
      organizationName: "(株)トリニティ",
    },
    work: {
      id: 10,
      workCategory: "rounder",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 17,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 230,
        schedulePlanId: 229,
      },
    ],
    workId: 10,
    workCategory: "rounder",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "高岡中央店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "(株)トリニティ",
    workPlaceOrganizationId: 17,
  },
  {
    id: 176,
    targetDate: "2023-06-26",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-26T09:00:00.000+09:00",
    finishAt: "2023-06-26T18:00:00.000+09:00",
    workPlace: {
      id: 34,
      name: "高岡中央店",
      organizationName: "(株)トリニティ",
    },
    work: {
      id: 10,
      workCategory: "rounder",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 17,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 233,
        schedulePlanId: 232,
      },
    ],
    workId: 10,
    workCategory: "rounder",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "高岡中央店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "(株)トリニティ",
    workPlaceOrganizationId: 17,
  },
  {
    id: 179,
    targetDate: "2023-06-30",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-30T09:00:00.000+09:00",
    finishAt: "2023-06-30T18:00:00.000+09:00",
    workPlace: {
      id: 34,
      name: "高岡中央店",
      organizationName: "(株)トリニティ",
    },
    work: {
      id: 10,
      workCategory: "rounder",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 17,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 236,
        schedulePlanId: 235,
      },
    ],
    workId: 10,
    workCategory: "rounder",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "高岡中央店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "(株)トリニティ",
    workPlaceOrganizationId: 17,
  },
  {
    id: 48,
    targetDate: "2023-06-12",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-12T09:00:00.000+09:00",
    finishAt: "2023-06-12T18:00:00.000+09:00",
    workPlace: {
      id: 3,
      name: "羽咋店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 3,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["ishikawa"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 18,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 55,
        schedulePlanId: 55,
      },
    ],
    workId: 3,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa"],
    workPlaceName: "羽咋店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 53,
    targetDate: "2023-06-24",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-24T09:00:00.000+09:00",
    finishAt: "2023-06-24T18:00:00.000+09:00",
    workPlace: {
      id: 3,
      name: "羽咋店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 3,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["ishikawa"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 18,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 60,
        schedulePlanId: 60,
      },
    ],
    workId: 3,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa"],
    workPlaceName: "羽咋店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 201,
    targetDate: "2023-06-07",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-07T09:00:00.000+09:00",
    finishAt: "2023-06-07T18:00:00.000+09:00",
    workPlace: {
      id: 26,
      name: "穴水店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 12,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["ishikawa"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 18,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 261,
        schedulePlanId: 54,
      },
    ],
    workId: 12,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa"],
    workPlaceName: "穴水店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 57,
    targetDate: "2023-06-27",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-27T09:00:00.000+09:00",
    finishAt: "2023-06-27T18:00:00.000+09:00",
    workPlace: {
      id: 3,
      name: "羽咋店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 3,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["ishikawa"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 1,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 65,
        schedulePlanId: 65,
      },
    ],
    workId: 3,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa"],
    workPlaceName: "羽咋店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 204,
    targetDate: "2023-06-27",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-27T09:00:00.000+09:00",
    finishAt: "2023-06-27T18:00:00.000+09:00",
    workPlace: {
      id: 26,
      name: "穴水店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 12,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["ishikawa"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 18,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 264,
        schedulePlanId: 63,
      },
    ],
    workId: 12,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa"],
    workPlaceName: "穴水店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 161,
    targetDate: "2023-06-03",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-03T09:00:00.000+09:00",
    finishAt: "2023-06-03T18:00:00.000+09:00",
    workPlace: {
      id: 34,
      name: "高岡中央店",
      organizationName: "(株)トリニティ",
    },
    work: {
      id: 10,
      workCategory: "rounder",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 17,
        organizationMemberName: "山村 朋矢",
        workScheduleAssignmentId: 218,
        schedulePlanId: 217,
      },
    ],
    workId: 10,
    workCategory: "rounder",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "高岡中央店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "(株)トリニティ",
    workPlaceOrganizationId: 17,
  },
  {
    id: 212,
    targetDate: "2023-06-21",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 8.5,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-21T09:30:00.000+09:00",
    finishAt: "2023-06-21T18:00:00.000+09:00",
    workPlace: {
      id: 69,
      name: "学園店",
      organizationName: "兼松コミュニケーションズ(株)",
    },
    work: {
      id: 13,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 5,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 285,
        schedulePlanId: 89,
      },
    ],
    workId: 13,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "学園店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
    workPlaceOrganizationId: 9,
  },
  {
    id: 114,
    targetDate: "2023-06-14",
    needCount: 2,
    currentCount: 2,
    enoughCount: true,
    eventPlaceName: "どんたくアスティ店",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=どんたくアスティ店+",
    workingHours: 7.5,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-14T09:00:00.000+09:00",
    startAt: "2023-06-14T09:30:00.000+09:00",
    finishAt: "2023-06-14T17:00:00.000+09:00",
    workPlace: {
      id: 3,
      name: "羽咋店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 8,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["ishikawa", "toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 18,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 164,
        schedulePlanId: 163,
      },
      {
        organizationMemberId: 5,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 165,
        schedulePlanId: 164,
      },
    ],
    workId: 8,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa", "toyama"],
    workPlaceName: "羽咋店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 124,
    targetDate: "2023-06-28",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "ロッキー羽咋",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=ロッキー羽咋+",
    workingHours: 7.5,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-28T09:00:00.000+09:00",
    startAt: "2023-06-28T09:30:00.000+09:00",
    finishAt: "2023-06-28T17:00:00.000+09:00",
    workPlace: {
      id: 3,
      name: "羽咋店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 8,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["ishikawa", "toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 21,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 179,
        schedulePlanId: 178,
      },
    ],
    workId: 8,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa", "toyama"],
    workPlaceName: "羽咋店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 210,
    targetDate: "2023-06-07",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 8.5,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-07T09:30:00.000+09:00",
    finishAt: "2023-06-07T18:00:00.000+09:00",
    workPlace: {
      id: 69,
      name: "学園店",
      organizationName: "兼松コミュニケーションズ(株)",
    },
    work: {
      id: 13,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 21,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 283,
        schedulePlanId: 112,
      },
    ],
    workId: 13,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "学園店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
    workPlaceOrganizationId: 9,
  },
  {
    id: 213,
    targetDate: "2023-06-22",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 8.5,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-22T09:30:00.000+09:00",
    finishAt: "2023-06-22T18:00:00.000+09:00",
    workPlace: {
      id: 69,
      name: "学園店",
      organizationName: "兼松コミュニケーションズ(株)",
    },
    work: {
      id: 13,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 1,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 295,
        schedulePlanId: 256,
      },
    ],
    workId: 13,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "学園店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
    workPlaceOrganizationId: 9,
  },
  {
    id: 208,
    targetDate: "2023-06-03",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 8.5,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-03T09:30:00.000+09:00",
    finishAt: "2023-06-03T18:00:00.000+09:00",
    workPlace: {
      id: 69,
      name: "学園店",
      organizationName: "兼松コミュニケーションズ(株)",
    },
    work: {
      id: 13,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 349,
        schedulePlanId: 287,
      },
    ],
    workId: 13,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "学園店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
    workPlaceOrganizationId: 9,
  },
  {
    id: 211,
    targetDate: "2023-06-15",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 8.5,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-15T09:30:00.000+09:00",
    finishAt: "2023-06-15T18:00:00.000+09:00",
    workPlace: {
      id: 69,
      name: "学園店",
      organizationName: "兼松コミュニケーションズ(株)",
    },
    work: {
      id: 13,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 21,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 284,
        schedulePlanId: 82,
      },
    ],
    workId: 13,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "学園店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
    workPlaceOrganizationId: 9,
  },
  {
    id: 214,
    targetDate: "2023-06-26",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 8.5,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-26T09:30:00.000+09:00",
    finishAt: "2023-06-26T18:00:00.000+09:00",
    workPlace: {
      id: 69,
      name: "学園店",
      organizationName: "兼松コミュニケーションズ(株)",
    },
    work: {
      id: 13,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 5,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 287,
        schedulePlanId: 87,
      },
    ],
    workId: 13,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "学園店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
    workPlaceOrganizationId: 9,
  },
  {
    id: 113,
    targetDate: "2023-06-13",
    needCount: 2,
    currentCount: 2,
    enoughCount: true,
    eventPlaceName: "どんたくアスティ店",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=どんたくアスティ店+",
    workingHours: 7.5,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-13T09:00:00.000+09:00",
    startAt: "2023-06-13T09:30:00.000+09:00",
    finishAt: "2023-06-13T17:00:00.000+09:00",
    workPlace: {
      id: 3,
      name: "羽咋店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 8,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["ishikawa", "toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 21,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 162,
        schedulePlanId: 161,
      },
      {
        organizationMemberId: 5,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 163,
        schedulePlanId: 162,
      },
    ],
    workId: 8,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa", "toyama"],
    workPlaceName: "羽咋店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 123,
    targetDate: "2023-06-27",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "ロッキー羽咋",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=ロッキー羽咋+",
    workingHours: 7.5,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-27T09:00:00.000+09:00",
    startAt: "2023-06-27T09:30:00.000+09:00",
    finishAt: "2023-06-27T17:00:00.000+09:00",
    workPlace: {
      id: 3,
      name: "羽咋店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 8,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["ishikawa", "toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 21,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 178,
        schedulePlanId: 177,
      },
    ],
    workId: 8,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa", "toyama"],
    workPlaceName: "羽咋店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 209,
    targetDate: "2023-06-04",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 8.5,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-04T09:30:00.000+09:00",
    finishAt: "2023-06-04T18:00:00.000+09:00",
    workPlace: {
      id: 69,
      name: "学園店",
      organizationName: "兼松コミュニケーションズ(株)",
    },
    work: {
      id: 13,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 279,
        schedulePlanId: 76,
      },
    ],
    workId: 13,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "学園店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
    workPlaceOrganizationId: 9,
  },
  {
    id: 28,
    targetDate: "2023-06-30",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-30T10:00:00.000+09:00",
    finishAt: "2023-06-30T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 7,
    workTypeName: "コンサル",
    assignedMembers: [
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 25,
        schedulePlanId: 25,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 63,
    targetDate: "2023-06-17",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "100満ボルトドン・キホーテ砺波店",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=100満ボルトドン・キホーテ砺波店+",
    workingHours: 8,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-17T09:30:00.000+09:00",
    startAt: "2023-06-17T10:00:00.000+09:00",
    finishAt: "2023-06-17T18:00:00.000+09:00",
    workPlace: {
      id: 41,
      name: "砺波店",
      organizationName: "米原商事(株)",
    },
    work: {
      id: 4,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 5,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 70,
        schedulePlanId: 70,
      },
    ],
    workId: 4,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "砺波店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "米原商事(株)",
    workPlaceOrganizationId: 18,
  },
  {
    id: 100,
    targetDate: "2023-06-18",
    needCount: 2,
    currentCount: 2,
    enoughCount: true,
    eventPlaceName: "ユナイテッドシネマ金沢",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=ユナイテッドシネマ金沢+",
    workingHours: 8,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-18T09:30:00.000+09:00",
    startAt: "2023-06-18T10:00:00.000+09:00",
    finishAt: "2023-06-18T18:00:00.000+09:00",
    workPlace: {
      id: 201,
      name: "春江",
      organizationName: "(株)ジョイテル",
    },
    work: {
      id: 7,
      workCategory: "event_helper",
      carrier: "softbank",
      prefectures: ["ishikawa"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 15,
        organizationMemberName: "後藤1",
        workScheduleAssignmentId: 130,
        schedulePlanId: 129,
      },
      {
        organizationMemberId: 16,
        organizationMemberName: "後藤2",
        workScheduleAssignmentId: 137,
        schedulePlanId: 136,
      },
    ],
    workId: 7,
    workCategory: "event_helper",
    workCarrier: "softbank",
    workPrefectures: ["ishikawa"],
    workPlaceName: "春江",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)ジョイテル",
    workPlaceOrganizationId: 54,
  },
  {
    id: 262,
    targetDate: "2023-06-25",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-25T10:00:00.000+09:00",
    finishAt: "2023-06-25T19:00:00.000+09:00",
    workPlace: {
      id: 220,
      name: "リアルメディア",
      organizationName: "(株)新通エスピー",
    },
    work: {
      id: 17,
      workCategory: "fulltime",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 8,
    workTypeName: "常勤",
    assignedMembers: [
      {
        organizationMemberId: 6,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 339,
        schedulePlanId: 277,
      },
    ],
    workId: 17,
    workCategory: "fulltime",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "リアルメディア",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)新通エスピー",
    workPlaceOrganizationId: 62,
  },
  {
    id: 105,
    targetDate: "2023-06-26",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-26T10:00:00.000+09:00",
    finishAt: "2023-06-26T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 4,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 259,
        schedulePlanId: 238,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 181,
    targetDate: "2023-06-03",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-03T10:00:00.000+09:00",
    finishAt: "2023-06-03T19:00:00.000+09:00",
    workPlace: {
      id: 212,
      name: "江守中",
      organizationName: "(株)リアルメディア",
    },
    work: {
      id: 11,
      workCategory: "rounder",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 3,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 238,
        schedulePlanId: 180,
      },
    ],
    workId: 11,
    workCategory: "rounder",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "江守中",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)リアルメディア",
    workPlaceOrganizationId: 59,
  },
  {
    id: 103,
    targetDate: "2023-06-25",
    needCount: 2,
    currentCount: 2,
    enoughCount: true,
    eventPlaceName: "イオン金沢",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=イオン金沢+",
    workingHours: 8,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-25T09:30:00.000+09:00",
    startAt: "2023-06-25T10:00:00.000+09:00",
    finishAt: "2023-06-25T18:00:00.000+09:00",
    workPlace: {
      id: 201,
      name: "春江",
      organizationName: "(株)ジョイテル",
    },
    work: {
      id: 7,
      workCategory: "event_helper",
      carrier: "softbank",
      prefectures: ["ishikawa"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 19,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 133,
        schedulePlanId: 132,
      },
      {
        organizationMemberId: 16,
        organizationMemberName: "後藤2",
        workScheduleAssignmentId: 139,
        schedulePlanId: 138,
      },
    ],
    workId: 7,
    workCategory: "event_helper",
    workCarrier: "softbank",
    workPrefectures: ["ishikawa"],
    workPlaceName: "春江",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)ジョイテル",
    workPlaceOrganizationId: 54,
  },
  {
    id: 265,
    targetDate: "2023-06-29",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-29T10:00:00.000+09:00",
    finishAt: "2023-06-29T19:00:00.000+09:00",
    workPlace: {
      id: 220,
      name: "リアルメディア",
      organizationName: "(株)新通エスピー",
    },
    work: {
      id: 17,
      workCategory: "fulltime",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 8,
    workTypeName: "常勤",
    assignedMembers: [
      {
        organizationMemberId: 6,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 342,
        schedulePlanId: 280,
      },
    ],
    workId: 17,
    workCategory: "fulltime",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "リアルメディア",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)新通エスピー",
    workPlaceOrganizationId: 62,
  },
  {
    id: 1,
    targetDate: "2023-06-01",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-01T10:00:00.000+09:00",
    finishAt: "2023-06-01T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 7,
    workTypeName: "コンサル",
    assignedMembers: [
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 1,
        schedulePlanId: 1,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 32,
    targetDate: "2023-06-03",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "武生SIPY",
    eventPlaceGmapUrl: "https://www.google.com/maps/search/?api=1&query=SIPY+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-03T09:50:00.000+09:00",
    startAt: "2023-06-03T10:00:00.000+09:00",
    finishAt: "2023-06-03T17:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 2,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 4,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 103,
        schedulePlanId: 103,
      },
    ],
    workId: 2,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 184,
    targetDate: "2023-06-07",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-07T10:00:00.000+09:00",
    finishAt: "2023-06-07T19:00:00.000+09:00",
    workPlace: {
      id: 210,
      name: "福井運動公園",
      organizationName: "(株)ベルパーク",
    },
    work: {
      id: 11,
      workCategory: "rounder",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 3,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 241,
        schedulePlanId: 184,
      },
    ],
    workId: 11,
    workCategory: "rounder",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "福井運動公園",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)ベルパーク",
    workPlaceOrganizationId: 58,
  },
  {
    id: 108,
    targetDate: "2023-06-04",
    needCount: 2,
    currentCount: 2,
    enoughCount: true,
    eventPlaceName: "ヤマキシ新加賀店",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=ヤマキシ新加賀店+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-04T09:20:00.000+09:00",
    startAt: "2023-06-04T10:00:00.000+09:00",
    finishAt: "2023-06-04T17:00:00.000+09:00",
    workPlace: {
      id: 5,
      name: "加賀店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 8,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["ishikawa", "toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 21,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 148,
        schedulePlanId: 147,
      },
      {
        organizationMemberId: 16,
        organizationMemberName: "後藤2",
        workScheduleAssignmentId: 149,
        schedulePlanId: 148,
      },
    ],
    workId: 8,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa", "toyama"],
    workPlaceName: "加賀店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 111,
    targetDate: "2023-06-11",
    needCount: 4,
    currentCount: 3,
    enoughCount: false,
    eventPlaceName: "イオンモール新小松",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=イオンモール新小松+",
    workingHours: 8,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-11T09:20:00.000+09:00",
    startAt: "2023-06-11T10:00:00.000+09:00",
    finishAt: "2023-06-11T18:00:00.000+09:00",
    workPlace: {
      id: 217,
      name: "中部特機産業(株)",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 8,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["ishikawa", "toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 15,
        organizationMemberName: "後藤1",
        workScheduleAssignmentId: 157,
        schedulePlanId: 156,
      },
      {
        organizationMemberId: 16,
        organizationMemberName: "後藤2",
        workScheduleAssignmentId: 158,
        schedulePlanId: 157,
      },
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 159,
        schedulePlanId: 158,
      },
    ],
    workId: 8,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa", "toyama"],
    workPlaceName: "中部特機産業(株)",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 4,
    targetDate: "2023-06-06",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-06T10:00:00.000+09:00",
    finishAt: "2023-06-06T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 7,
    workTypeName: "コンサル",
    assignedMembers: [
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 4,
        schedulePlanId: 4,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 35,
    targetDate: "2023-06-06",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "武生SIPY",
    eventPlaceGmapUrl: "https://www.google.com/maps/search/?api=1&query=SIPY+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-06T09:50:00.000+09:00",
    startAt: "2023-06-06T10:00:00.000+09:00",
    finishAt: "2023-06-06T17:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 2,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 4,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 104,
        schedulePlanId: 104,
      },
    ],
    workId: 2,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 187,
    targetDate: "2023-06-11",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-11T10:00:00.000+09:00",
    finishAt: "2023-06-11T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 11,
      workCategory: "rounder",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 3,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 244,
        schedulePlanId: 187,
      },
    ],
    workId: 11,
    workCategory: "rounder",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 222,
    targetDate: "2023-06-11",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "アピタ福井大和田",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-11T09:50:00.000+09:00",
    startAt: "2023-06-11T10:00:00.000+09:00",
    finishAt: "2023-06-11T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 15,
      workCategory: "normal",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 1,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 298,
        schedulePlanId: 203,
      },
    ],
    workId: 15,
    workCategory: "normal",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 7,
    targetDate: "2023-06-10",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-10T10:00:00.000+09:00",
    finishAt: "2023-06-10T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 4,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 98,
        schedulePlanId: 98,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 38,
    targetDate: "2023-06-16",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "ワイプラザ鯖江店",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=ワイプラザ鯖江店+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-16T09:50:00.000+09:00",
    startAt: "2023-06-16T10:00:00.000+09:00",
    finishAt: "2023-06-16T17:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 2,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 4,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 113,
        schedulePlanId: 113,
      },
    ],
    workId: 2,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 190,
    targetDate: "2023-06-15",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-15T10:00:00.000+09:00",
    finishAt: "2023-06-15T19:00:00.000+09:00",
    workPlace: {
      id: 210,
      name: "福井運動公園",
      organizationName: "(株)ベルパーク",
    },
    work: {
      id: 11,
      workCategory: "rounder",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 3,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 246,
        schedulePlanId: 194,
      },
    ],
    workId: 11,
    workCategory: "rounder",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "福井運動公園",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)ベルパーク",
    workPlaceOrganizationId: 58,
  },
  {
    id: 225,
    targetDate: "2023-06-17",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "アピタ福井大和田",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-17T09:50:00.000+09:00",
    startAt: "2023-06-17T10:00:00.000+09:00",
    finishAt: "2023-06-17T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 15,
      workCategory: "normal",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 3,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 307,
        schedulePlanId: 214,
      },
    ],
    workId: 15,
    workCategory: "normal",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 117,
    targetDate: "2023-06-18",
    needCount: 2,
    currentCount: 2,
    enoughCount: true,
    eventPlaceName: "アル・プラザ小松",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アル・プラザ小松+",
    workingHours: 7.5,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-18T09:20:00.000+09:00",
    startAt: "2023-06-18T10:00:00.000+09:00",
    finishAt: "2023-06-18T17:30:00.000+09:00",
    workPlace: {
      id: 23,
      name: "粟津店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 8,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["ishikawa", "toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 21,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 169,
        schedulePlanId: 168,
      },
      {
        organizationMemberId: 18,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 344,
        schedulePlanId: 282,
      },
    ],
    workId: 8,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa", "toyama"],
    workPlaceName: "粟津店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 10,
    targetDate: "2023-06-12",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-12T10:00:00.000+09:00",
    finishAt: "2023-06-12T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 4,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 106,
        schedulePlanId: 106,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 41,
    targetDate: "2023-06-23",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "ワイプラザ鯖江店",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=ワイプラザ鯖江店+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-23T09:30:00.000+09:00",
    startAt: "2023-06-23T10:00:00.000+09:00",
    finishAt: "2023-06-23T17:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 2,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 4,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 116,
        schedulePlanId: 116,
      },
    ],
    workId: 2,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 193,
    targetDate: "2023-06-20",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-20T10:00:00.000+09:00",
    finishAt: "2023-06-20T19:00:00.000+09:00",
    workPlace: {
      id: 212,
      name: "江守中",
      organizationName: "(株)リアルメディア",
    },
    work: {
      id: 11,
      workCategory: "rounder",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 3,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 250,
        schedulePlanId: 193,
      },
    ],
    workId: 11,
    workCategory: "rounder",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "江守中",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)リアルメディア",
    workPlaceOrganizationId: 59,
  },
  {
    id: 228,
    targetDate: "2023-06-23",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "アピタ福井大和田",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-23T09:50:00.000+09:00",
    startAt: "2023-06-23T10:00:00.000+09:00",
    finishAt: "2023-06-23T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 15,
      workCategory: "normal",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 1,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 302,
        schedulePlanId: 209,
      },
    ],
    workId: 15,
    workCategory: "normal",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 121,
    targetDate: "2023-06-24",
    needCount: 2,
    currentCount: 2,
    enoughCount: true,
    eventPlaceName: "業務スーパー和倉",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=業務スーパー和倉+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-24T09:00:00.000+09:00",
    startAt: "2023-06-24T10:00:00.000+09:00",
    finishAt: "2023-06-24T17:00:00.000+09:00",
    workPlace: {
      id: 3,
      name: "羽咋店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 8,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["ishikawa", "toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 21,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 174,
        schedulePlanId: 173,
      },
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 175,
        schedulePlanId: 174,
      },
    ],
    workId: 8,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa", "toyama"],
    workPlaceName: "羽咋店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 248,
    targetDate: "2023-06-02",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-02T10:00:00.000+09:00",
    finishAt: "2023-06-02T19:00:00.000+09:00",
    workPlace: {
      id: 220,
      name: "リアルメディア",
      organizationName: "(株)新通エスピー",
    },
    work: {
      id: 17,
      workCategory: "fulltime",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 8,
    workTypeName: "常勤",
    assignedMembers: [
      {
        organizationMemberId: 6,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 325,
        schedulePlanId: 263,
      },
    ],
    workId: 17,
    workCategory: "fulltime",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "リアルメディア",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)新通エスピー",
    workPlaceOrganizationId: 62,
  },
  {
    id: 13,
    targetDate: "2023-06-16",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-16T10:00:00.000+09:00",
    finishAt: "2023-06-16T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 7,
    workTypeName: "コンサル",
    assignedMembers: [
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 13,
        schedulePlanId: 13,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 196,
    targetDate: "2023-06-25",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-25T10:00:00.000+09:00",
    finishAt: "2023-06-25T19:00:00.000+09:00",
    workPlace: {
      id: 220,
      name: "リアルメディア",
      organizationName: "(株)新通エスピー",
    },
    work: {
      id: 11,
      workCategory: "rounder",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 3,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 252,
        schedulePlanId: 197,
      },
    ],
    workId: 11,
    workCategory: "rounder",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "リアルメディア",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)新通エスピー",
    workPlaceOrganizationId: 62,
  },
  {
    id: 231,
    targetDate: "2023-06-26",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "アピタ福井大和田",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-26T09:50:00.000+09:00",
    startAt: "2023-06-26T10:00:00.000+09:00",
    finishAt: "2023-06-26T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 15,
      workCategory: "normal",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 1,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 305,
        schedulePlanId: 212,
      },
    ],
    workId: 15,
    workCategory: "normal",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 66,
    targetDate: "2023-06-25",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "ドン・キホーテ砺波店",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=ドン・キホーテ砺波店+",
    workingHours: 8,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-25T09:30:00.000+09:00",
    startAt: "2023-06-25T10:00:00.000+09:00",
    finishAt: "2023-06-25T18:00:00.000+09:00",
    workPlace: {
      id: 41,
      name: "砺波店",
      organizationName: "米原商事(株)",
    },
    work: {
      id: 4,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 5,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 73,
        schedulePlanId: 73,
      },
    ],
    workId: 4,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "砺波店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "米原商事(株)",
    workPlaceOrganizationId: 18,
  },
  {
    id: 251,
    targetDate: "2023-06-07",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-07T10:00:00.000+09:00",
    finishAt: "2023-06-07T19:00:00.000+09:00",
    workPlace: {
      id: 220,
      name: "リアルメディア",
      organizationName: "(株)新通エスピー",
    },
    work: {
      id: 17,
      workCategory: "fulltime",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 8,
    workTypeName: "常勤",
    assignedMembers: [
      {
        organizationMemberId: 6,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 328,
        schedulePlanId: 266,
      },
    ],
    workId: 17,
    workCategory: "fulltime",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "リアルメディア",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)新通エスピー",
    workPlaceOrganizationId: 62,
  },
  {
    id: 16,
    targetDate: "2023-06-19",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-19T10:00:00.000+09:00",
    finishAt: "2023-06-19T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 7,
    workTypeName: "コンサル",
    assignedMembers: [
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 16,
        schedulePlanId: 16,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 199,
    targetDate: "2023-06-29",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-29T10:00:00.000+09:00",
    finishAt: "2023-06-29T19:00:00.000+09:00",
    workPlace: {
      id: 211,
      name: "御幸",
      organizationName: "(株)リアルメディア",
    },
    work: {
      id: 11,
      workCategory: "rounder",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 3,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 254,
        schedulePlanId: 200,
      },
    ],
    workId: 11,
    workCategory: "rounder",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "御幸",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)リアルメディア",
    workPlaceOrganizationId: 59,
  },
  {
    id: 234,
    targetDate: "2023-06-03",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "アピタ福井大和田",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-03T09:50:00.000+09:00",
    startAt: "2023-06-03T10:00:00.000+09:00",
    finishAt: "2023-06-03T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 16,
      workCategory: "event_helper",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 18,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 310,
        schedulePlanId: 92,
      },
    ],
    workId: 16,
    workCategory: "event_helper",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 237,
    targetDate: "2023-06-10",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "アピタ福井大和田",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-10T09:50:00.000+09:00",
    startAt: "2023-06-10T10:00:00.000+09:00",
    finishAt: "2023-06-10T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 16,
      workCategory: "event_helper",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 22,
        organizationMemberName: "長岡 陸",
        workScheduleAssignmentId: 313,
        schedulePlanId: 257,
      },
    ],
    workId: 16,
    workCategory: "event_helper",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 74,
    targetDate: "2023-06-11",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-11T10:00:00.000+09:00",
    finishAt: "2023-06-11T19:00:00.000+09:00",
    workPlace: {
      id: 14,
      name: "もりの里店",
      organizationName: "兼松コミュニケーションズ(株)",
    },
    work: {
      id: 5,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["ishikawa"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 19,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 80,
        schedulePlanId: 80,
      },
    ],
    workId: 5,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa"],
    workPlaceName: "もりの里店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
    workPlaceOrganizationId: 9,
  },
  {
    id: 205,
    targetDate: "2023-06-23",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "あだちストア",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=あだちストア+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-23T09:00:00.000+09:00",
    startAt: "2023-06-23T10:00:00.000+09:00",
    finishAt: "2023-06-23T17:00:00.000+09:00",
    workPlace: {
      id: 3,
      name: "羽咋店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 8,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["ishikawa", "toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 5,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 266,
        schedulePlanId: 172,
      },
    ],
    workId: 8,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa", "toyama"],
    workPlaceName: "羽咋店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 254,
    targetDate: "2023-06-11",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-11T10:00:00.000+09:00",
    finishAt: "2023-06-11T19:00:00.000+09:00",
    workPlace: {
      id: 220,
      name: "リアルメディア",
      organizationName: "(株)新通エスピー",
    },
    work: {
      id: 17,
      workCategory: "fulltime",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 8,
    workTypeName: "常勤",
    assignedMembers: [
      {
        organizationMemberId: 6,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 331,
        schedulePlanId: 269,
      },
    ],
    workId: 17,
    workCategory: "fulltime",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "リアルメディア",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)新通エスピー",
    workPlaceOrganizationId: 62,
  },
  {
    id: 19,
    targetDate: "2023-06-22",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-22T10:00:00.000+09:00",
    finishAt: "2023-06-22T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 7,
    workTypeName: "コンサル",
    assignedMembers: [
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 18,
        schedulePlanId: 18,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 23,
    targetDate: "2023-06-27",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-27T10:00:00.000+09:00",
    finishAt: "2023-06-27T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 7,
    workTypeName: "コンサル",
    assignedMembers: [
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 21,
        schedulePlanId: 21,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 240,
    targetDate: "2023-06-17",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "アピタ福井大和田",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-17T09:50:00.000+09:00",
    startAt: "2023-06-17T10:00:00.000+09:00",
    finishAt: "2023-06-17T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 16,
      workCategory: "event_helper",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 6,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 316,
        schedulePlanId: 95,
      },
    ],
    workId: 16,
    workCategory: "event_helper",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 80,
    targetDate: "2023-06-21",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-21T10:00:00.000+09:00",
    finishAt: "2023-06-21T19:00:00.000+09:00",
    workPlace: {
      id: 14,
      name: "もりの里店",
      organizationName: "兼松コミュニケーションズ(株)",
    },
    work: {
      id: 5,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["ishikawa"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 19,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 85,
        schedulePlanId: 85,
      },
    ],
    workId: 5,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa"],
    workPlaceName: "もりの里店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
    workPlaceOrganizationId: 9,
  },
  {
    id: 246,
    targetDate: "2023-06-22",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "シマヤ小杉店",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=シマヤ小杉店+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-22T09:40:00.000+09:00",
    startAt: "2023-06-22T10:00:00.000+09:00",
    finishAt: "2023-06-22T17:00:00.000+09:00",
    workPlace: {
      id: 37,
      name: "高岡野村店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 8,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["ishikawa", "toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 5,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 322,
        schedulePlanId: 260,
      },
    ],
    workId: 8,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa", "toyama"],
    workPlaceName: "高岡野村店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 257,
    targetDate: "2023-06-19",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-19T10:00:00.000+09:00",
    finishAt: "2023-06-19T19:00:00.000+09:00",
    workPlace: {
      id: 220,
      name: "リアルメディア",
      organizationName: "(株)新通エスピー",
    },
    work: {
      id: 17,
      workCategory: "fulltime",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 8,
    workTypeName: "常勤",
    assignedMembers: [
      {
        organizationMemberId: 6,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 334,
        schedulePlanId: 272,
      },
    ],
    workId: 17,
    workCategory: "fulltime",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "リアルメディア",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)新通エスピー",
    workPlaceOrganizationId: 62,
  },
  {
    id: 26,
    targetDate: "2023-06-29",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-29T10:00:00.000+09:00",
    finishAt: "2023-06-29T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 7,
    workTypeName: "コンサル",
    assignedMembers: [
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 23,
        schedulePlanId: 23,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 61,
    targetDate: "2023-06-10",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "サンコー砺波店",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=サンコー砺波店+",
    workingHours: 8,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-10T09:30:00.000+09:00",
    startAt: "2023-06-10T10:00:00.000+09:00",
    finishAt: "2023-06-10T18:00:00.000+09:00",
    workPlace: {
      id: 41,
      name: "砺波店",
      organizationName: "米原商事(株)",
    },
    work: {
      id: 4,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 5,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 68,
        schedulePlanId: 68,
      },
    ],
    workId: 4,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "砺波店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "米原商事(株)",
    workPlaceOrganizationId: 18,
  },
  {
    id: 98,
    targetDate: "2023-06-11",
    needCount: 2,
    currentCount: 2,
    enoughCount: true,
    eventPlaceName: "イオン金沢",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=イオン金沢+",
    workingHours: 8,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-11T09:30:00.000+09:00",
    startAt: "2023-06-11T10:00:00.000+09:00",
    finishAt: "2023-06-11T18:00:00.000+09:00",
    workPlace: {
      id: 201,
      name: "春江",
      organizationName: "(株)ジョイテル",
    },
    work: {
      id: 7,
      workCategory: "event_helper",
      carrier: "softbank",
      prefectures: ["ishikawa"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 18,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 128,
        schedulePlanId: 127,
      },
      {
        organizationMemberId: 21,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 135,
        schedulePlanId: 134,
      },
    ],
    workId: 7,
    workCategory: "event_helper",
    workCarrier: "softbank",
    workPrefectures: ["ishikawa"],
    workPlaceName: "春江",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)ジョイテル",
    workPlaceOrganizationId: 54,
  },
  {
    id: 260,
    targetDate: "2023-06-23",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-23T10:00:00.000+09:00",
    finishAt: "2023-06-23T19:00:00.000+09:00",
    workPlace: {
      id: 220,
      name: "リアルメディア",
      organizationName: "(株)新通エスピー",
    },
    work: {
      id: 17,
      workCategory: "fulltime",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 8,
    workTypeName: "常勤",
    assignedMembers: [
      {
        organizationMemberId: 6,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 337,
        schedulePlanId: 275,
      },
    ],
    workId: 17,
    workCategory: "fulltime",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "リアルメディア",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)新通エスピー",
    workPlaceOrganizationId: 62,
  },
  {
    id: 29,
    targetDate: "2023-06-30",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-30T10:00:00.000+09:00",
    finishAt: "2023-06-30T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 4,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 111,
        schedulePlanId: 111,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 64,
    targetDate: "2023-06-18",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "100満ボルトドン・キホーテ砺波店",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=100満ボルトドン・キホーテ砺波店+",
    workingHours: 8,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-18T09:30:00.000+09:00",
    startAt: "2023-06-18T10:00:00.000+09:00",
    finishAt: "2023-06-18T18:00:00.000+09:00",
    workPlace: {
      id: 41,
      name: "砺波店",
      organizationName: "米原商事(株)",
    },
    work: {
      id: 4,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 5,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 71,
        schedulePlanId: 71,
      },
    ],
    workId: 4,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "砺波店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "米原商事(株)",
    workPlaceOrganizationId: 18,
  },
  {
    id: 101,
    targetDate: "2023-06-23",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "イオン金沢",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=イオン金沢+",
    workingHours: 8,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-23T09:30:00.000+09:00",
    startAt: "2023-06-23T10:00:00.000+09:00",
    finishAt: "2023-06-23T18:00:00.000+09:00",
    workPlace: {
      id: 201,
      name: "春江",
      organizationName: "(株)ジョイテル",
    },
    work: {
      id: 7,
      workCategory: "event_helper",
      carrier: "softbank",
      prefectures: ["ishikawa"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 19,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 131,
        schedulePlanId: 130,
      },
    ],
    workId: 7,
    workCategory: "event_helper",
    workCarrier: "softbank",
    workPrefectures: ["ishikawa"],
    workPlaceName: "春江",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)ジョイテル",
    workPlaceOrganizationId: 54,
  },
  {
    id: 263,
    targetDate: "2023-06-26",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-26T10:00:00.000+09:00",
    finishAt: "2023-06-26T19:00:00.000+09:00",
    workPlace: {
      id: 220,
      name: "リアルメディア",
      organizationName: "(株)新通エスピー",
    },
    work: {
      id: 17,
      workCategory: "fulltime",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 8,
    workTypeName: "常勤",
    assignedMembers: [
      {
        organizationMemberId: 6,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 340,
        schedulePlanId: 278,
      },
    ],
    workId: 17,
    workCategory: "fulltime",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "リアルメディア",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)新通エスピー",
    workPlaceOrganizationId: 62,
  },
  {
    id: 30,
    targetDate: "2023-06-01",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "武生SIPY",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=武生SIPY+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-01T09:30:00.000+09:00",
    startAt: "2023-06-01T10:00:00.000+09:00",
    finishAt: "2023-06-01T17:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 2,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 21,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 294,
        schedulePlanId: 255,
      },
    ],
    workId: 2,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 182,
    targetDate: "2023-06-04",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-04T10:00:00.000+09:00",
    finishAt: "2023-06-04T19:00:00.000+09:00",
    workPlace: {
      id: 220,
      name: "リアルメディア",
      organizationName: "(株)新通エスピー",
    },
    work: {
      id: 11,
      workCategory: "rounder",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 3,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 239,
        schedulePlanId: 236,
      },
    ],
    workId: 11,
    workCategory: "rounder",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "リアルメディア",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)新通エスピー",
    workPlaceOrganizationId: 62,
  },
  {
    id: 106,
    targetDate: "2023-06-02",
    needCount: 2,
    currentCount: 0,
    enoughCount: false,
    eventPlaceName: "ヤマキシ新加賀店",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=ヤマキシ新加賀店+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-02T09:20:00.000+09:00",
    startAt: "2023-06-02T10:00:00.000+09:00",
    finishAt: "2023-06-02T17:00:00.000+09:00",
    workPlace: {
      id: 5,
      name: "加賀店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 8,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["ishikawa", "toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [],
    workId: 8,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa", "toyama"],
    workPlaceName: "加賀店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 266,
    targetDate: "2023-06-30",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-30T10:00:00.000+09:00",
    finishAt: "2023-06-30T19:00:00.000+09:00",
    workPlace: {
      id: 220,
      name: "リアルメディア",
      organizationName: "(株)新通エスピー",
    },
    work: {
      id: 17,
      workCategory: "fulltime",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 8,
    workTypeName: "常勤",
    assignedMembers: [
      {
        organizationMemberId: 6,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 343,
        schedulePlanId: 281,
      },
    ],
    workId: 17,
    workCategory: "fulltime",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "リアルメディア",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)新通エスピー",
    workPlaceOrganizationId: 62,
  },
  {
    id: 2,
    targetDate: "2023-06-02",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-02T10:00:00.000+09:00",
    finishAt: "2023-06-02T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 7,
    workTypeName: "コンサル",
    assignedMembers: [
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 2,
        schedulePlanId: 2,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 33,
    targetDate: "2023-06-04",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "武生SIPY",
    eventPlaceGmapUrl: "https://www.google.com/maps/search/?api=1&query=SIPY+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-04T09:50:00.000+09:00",
    startAt: "2023-06-04T10:00:00.000+09:00",
    finishAt: "2023-06-04T17:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 2,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 4,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 102,
        schedulePlanId: 102,
      },
    ],
    workId: 2,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 185,
    targetDate: "2023-06-09",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-09T10:00:00.000+09:00",
    finishAt: "2023-06-09T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 11,
      workCategory: "rounder",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 3,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 242,
        schedulePlanId: 185,
      },
    ],
    workId: 11,
    workCategory: "rounder",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 220,
    targetDate: "2023-06-09",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "アピタ福井大和田",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-09T09:50:00.000+09:00",
    startAt: "2023-06-09T10:00:00.000+09:00",
    finishAt: "2023-06-09T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 15,
      workCategory: "normal",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 1,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 296,
        schedulePlanId: 201,
      },
    ],
    workId: 15,
    workCategory: "normal",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 109,
    targetDate: "2023-06-09",
    needCount: 2,
    currentCount: 2,
    enoughCount: true,
    eventPlaceName: "100満ボルトアピタ金沢店",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=100満ボルトアピタ金沢店+",
    workingHours: 8,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-09T09:00:00.000+09:00",
    startAt: "2023-06-09T10:00:00.000+09:00",
    finishAt: "2023-06-09T18:00:00.000+09:00",
    workPlace: {
      id: 17,
      name: "金沢駅前店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 8,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["ishikawa", "toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 152,
        schedulePlanId: 151,
      },
      {
        organizationMemberId: 15,
        organizationMemberName: "後藤1",
        workScheduleAssignmentId: 153,
        schedulePlanId: 152,
      },
    ],
    workId: 8,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa", "toyama"],
    workPlaceName: "金沢駅前店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 112,
    targetDate: "2023-06-12",
    needCount: 2,
    currentCount: 2,
    enoughCount: true,
    eventPlaceName: "イオンモール新小松",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=イオンモール新小松+",
    workingHours: 8,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-12T09:20:00.000+09:00",
    startAt: "2023-06-12T10:00:00.000+09:00",
    finishAt: "2023-06-12T18:00:00.000+09:00",
    workPlace: {
      id: 217,
      name: "中部特機産業(株)",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 8,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["ishikawa", "toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 5,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 160,
        schedulePlanId: 159,
      },
      {
        organizationMemberId: 21,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 161,
        schedulePlanId: 160,
      },
    ],
    workId: 8,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa", "toyama"],
    workPlaceName: "中部特機産業(株)",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 5,
    targetDate: "2023-06-07",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-07T10:00:00.000+09:00",
    finishAt: "2023-06-07T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 7,
    workTypeName: "コンサル",
    assignedMembers: [
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 5,
        schedulePlanId: 5,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 36,
    targetDate: "2023-06-07",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "武生SIPY",
    eventPlaceGmapUrl: "https://www.google.com/maps/search/?api=1&query=SIPY+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-07T09:50:00.000+09:00",
    startAt: "2023-06-07T10:00:00.000+09:00",
    finishAt: "2023-06-07T17:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 2,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 4,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 105,
        schedulePlanId: 105,
      },
    ],
    workId: 2,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 188,
    targetDate: "2023-06-12",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-12T10:00:00.000+09:00",
    finishAt: "2023-06-12T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 11,
      workCategory: "rounder",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 3,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 245,
        schedulePlanId: 188,
      },
    ],
    workId: 11,
    workCategory: "rounder",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 223,
    targetDate: "2023-06-12",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "アピタ福井大和田",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-12T09:50:00.000+09:00",
    startAt: "2023-06-12T10:00:00.000+09:00",
    finishAt: "2023-06-12T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 15,
      workCategory: "normal",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 1,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 299,
        schedulePlanId: 204,
      },
    ],
    workId: 15,
    workCategory: "normal",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 115,
    targetDate: "2023-06-16",
    needCount: 2,
    currentCount: 2,
    enoughCount: true,
    eventPlaceName: "あだちストア",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=あだちストア+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-16T09:00:00.000+09:00",
    startAt: "2023-06-16T10:00:00.000+09:00",
    finishAt: "2023-06-16T17:00:00.000+09:00",
    workPlace: {
      id: 3,
      name: "羽咋店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 8,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["ishikawa", "toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 21,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 166,
        schedulePlanId: 165,
      },
      {
        organizationMemberId: 5,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 167,
        schedulePlanId: 166,
      },
    ],
    workId: 8,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa", "toyama"],
    workPlaceName: "羽咋店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 8,
    targetDate: "2023-06-11",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-11T10:00:00.000+09:00",
    finishAt: "2023-06-11T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 4,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 99,
        schedulePlanId: 99,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 39,
    targetDate: "2023-06-17",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "ワイプラザ鯖江店",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=ワイプラザ鯖江店+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-17T09:50:00.000+09:00",
    startAt: "2023-06-17T10:00:00.000+09:00",
    finishAt: "2023-06-17T17:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 2,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 4,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 114,
        schedulePlanId: 114,
      },
    ],
    workId: 2,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 191,
    targetDate: "2023-06-16",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-16T10:00:00.000+09:00",
    finishAt: "2023-06-16T19:00:00.000+09:00",
    workPlace: {
      id: 212,
      name: "江守中",
      organizationName: "(株)リアルメディア",
    },
    work: {
      id: 11,
      workCategory: "rounder",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 3,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 247,
        schedulePlanId: 191,
      },
    ],
    workId: 11,
    workCategory: "rounder",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "江守中",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)リアルメディア",
    workPlaceOrganizationId: 59,
  },
  {
    id: 226,
    targetDate: "2023-06-18",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "アピタ福井大和田",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-18T09:50:00.000+09:00",
    startAt: "2023-06-18T10:00:00.000+09:00",
    finishAt: "2023-06-18T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 15,
      workCategory: "normal",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 3,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 308,
        schedulePlanId: 215,
      },
    ],
    workId: 15,
    workCategory: "normal",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 118,
    targetDate: "2023-06-20",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "シマヤ小杉店",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=シマヤ小杉店+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-20T09:40:00.000+09:00",
    startAt: "2023-06-20T10:00:00.000+09:00",
    finishAt: "2023-06-20T17:00:00.000+09:00",
    workPlace: {
      id: 37,
      name: "高岡野村店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 8,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["ishikawa", "toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 5,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 323,
        schedulePlanId: 261,
      },
    ],
    workId: 8,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa", "toyama"],
    workPlaceName: "高岡野村店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 243,
    targetDate: "2023-06-25",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "アピタ福井大和田",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-25T09:50:00.000+09:00",
    startAt: "2023-06-25T10:00:00.000+09:00",
    finishAt: "2023-06-25T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 16,
      workCategory: "event_helper",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 22,
        organizationMemberName: "長岡 陸",
        workScheduleAssignmentId: 319,
        schedulePlanId: 124,
      },
    ],
    workId: 16,
    workCategory: "event_helper",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 11,
    targetDate: "2023-06-13",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-13T10:00:00.000+09:00",
    finishAt: "2023-06-13T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 7,
    workTypeName: "コンサル",
    assignedMembers: [
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 11,
        schedulePlanId: 11,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 42,
    targetDate: "2023-06-24",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "ワイプラザ鯖江店",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=ワイプラザ鯖江店+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-24T09:50:00.000+09:00",
    startAt: "2023-06-24T10:00:00.000+09:00",
    finishAt: "2023-06-24T17:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 2,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 4,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 117,
        schedulePlanId: 117,
      },
    ],
    workId: 2,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 194,
    targetDate: "2023-06-23",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-23T10:00:00.000+09:00",
    finishAt: "2023-06-23T19:00:00.000+09:00",
    workPlace: {
      id: 210,
      name: "福井運動公園",
      organizationName: "(株)ベルパーク",
    },
    work: {
      id: 11,
      workCategory: "rounder",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 3,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 251,
        schedulePlanId: 195,
      },
    ],
    workId: 11,
    workCategory: "rounder",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "福井運動公園",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)ベルパーク",
    workPlaceOrganizationId: 58,
  },
  {
    id: 229,
    targetDate: "2023-06-24",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "アピタ福井大和田",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-24T09:50:00.000+09:00",
    startAt: "2023-06-24T10:00:00.000+09:00",
    finishAt: "2023-06-24T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 15,
      workCategory: "normal",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 1,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 303,
        schedulePlanId: 210,
      },
    ],
    workId: 15,
    workCategory: "normal",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 122,
    targetDate: "2023-06-25",
    needCount: 2,
    currentCount: 2,
    enoughCount: true,
    eventPlaceName: "業務スーパー和倉",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=業務スーパー和倉+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-25T09:30:00.000+09:00",
    startAt: "2023-06-25T10:00:00.000+09:00",
    finishAt: "2023-06-25T17:00:00.000+09:00",
    workPlace: {
      id: 3,
      name: "羽咋店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 8,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["ishikawa", "toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 21,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 176,
        schedulePlanId: 175,
      },
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 177,
        schedulePlanId: 176,
      },
    ],
    workId: 8,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa", "toyama"],
    workPlaceName: "羽咋店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 249,
    targetDate: "2023-06-03",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-03T10:00:00.000+09:00",
    finishAt: "2023-06-03T19:00:00.000+09:00",
    workPlace: {
      id: 220,
      name: "リアルメディア",
      organizationName: "(株)新通エスピー",
    },
    work: {
      id: 17,
      workCategory: "fulltime",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 8,
    workTypeName: "常勤",
    assignedMembers: [
      {
        organizationMemberId: 6,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 326,
        schedulePlanId: 264,
      },
    ],
    workId: 17,
    workCategory: "fulltime",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "リアルメディア",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)新通エスピー",
    workPlaceOrganizationId: 62,
  },
  {
    id: 14,
    targetDate: "2023-06-17",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-17T10:00:00.000+09:00",
    finishAt: "2023-06-17T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 7,
    workTypeName: "コンサル",
    assignedMembers: [
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 14,
        schedulePlanId: 14,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 197,
    targetDate: "2023-06-26",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-26T10:00:00.000+09:00",
    finishAt: "2023-06-26T19:00:00.000+09:00",
    workPlace: {
      id: 210,
      name: "福井運動公園",
      organizationName: "(株)ベルパーク",
    },
    work: {
      id: 11,
      workCategory: "rounder",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 3,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 256,
        schedulePlanId: 198,
      },
    ],
    workId: 11,
    workCategory: "rounder",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "福井運動公園",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)ベルパーク",
    workPlaceOrganizationId: 58,
  },
  {
    id: 232,
    targetDate: "2023-06-30",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "アピタ福井大和田",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-30T09:50:00.000+09:00",
    startAt: "2023-06-30T10:00:00.000+09:00",
    finishAt: "2023-06-30T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 15,
      workCategory: "normal",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 1,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 306,
        schedulePlanId: 213,
      },
    ],
    workId: 15,
    workCategory: "normal",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 71,
    targetDate: "2023-06-07",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-07T10:00:00.000+09:00",
    finishAt: "2023-06-07T19:00:00.000+09:00",
    workPlace: {
      id: 14,
      name: "もりの里店",
      organizationName: "兼松コミュニケーションズ(株)",
    },
    work: {
      id: 5,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["ishikawa"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 19,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 77,
        schedulePlanId: 77,
      },
    ],
    workId: 5,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa"],
    workPlaceName: "もりの里店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
    workPlaceOrganizationId: 9,
  },
  {
    id: 125,
    targetDate: "2023-06-06",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "業務スーパー和倉",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=業務スーパー和倉+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-06T09:30:00.000+09:00",
    startAt: "2023-06-06T10:00:00.000+09:00",
    finishAt: "2023-06-06T17:00:00.000+09:00",
    workPlace: {
      id: 3,
      name: "羽咋店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 8,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["ishikawa", "toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 5,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 150,
        schedulePlanId: 149,
      },
    ],
    workId: 8,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa", "toyama"],
    workPlaceName: "羽咋店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 252,
    targetDate: "2023-06-08",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-08T10:00:00.000+09:00",
    finishAt: "2023-06-08T19:00:00.000+09:00",
    workPlace: {
      id: 220,
      name: "リアルメディア",
      organizationName: "(株)新通エスピー",
    },
    work: {
      id: 17,
      workCategory: "fulltime",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 8,
    workTypeName: "常勤",
    assignedMembers: [
      {
        organizationMemberId: 6,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 329,
        schedulePlanId: 267,
      },
    ],
    workId: 17,
    workCategory: "fulltime",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "リアルメディア",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)新通エスピー",
    workPlaceOrganizationId: 62,
  },
  {
    id: 17,
    targetDate: "2023-06-20",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-20T10:00:00.000+09:00",
    finishAt: "2023-06-20T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 4,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 107,
        schedulePlanId: 107,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 235,
    targetDate: "2023-06-04",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "アピタ福井大和田",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-04T09:50:00.000+09:00",
    startAt: "2023-06-04T10:00:00.000+09:00",
    finishAt: "2023-06-04T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 16,
      workCategory: "event_helper",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 18,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 311,
        schedulePlanId: 93,
      },
    ],
    workId: 16,
    workCategory: "event_helper",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 238,
    targetDate: "2023-06-11",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "アピタ福井大和田",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-11T09:50:00.000+09:00",
    startAt: "2023-06-11T10:00:00.000+09:00",
    finishAt: "2023-06-11T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 16,
      workCategory: "event_helper",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 22,
        organizationMemberName: "長岡 陸",
        workScheduleAssignmentId: 314,
        schedulePlanId: 122,
      },
    ],
    workId: 16,
    workCategory: "event_helper",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 75,
    targetDate: "2023-06-12",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-12T10:00:00.000+09:00",
    finishAt: "2023-06-12T19:00:00.000+09:00",
    workPlace: {
      id: 14,
      name: "もりの里店",
      organizationName: "兼松コミュニケーションズ(株)",
    },
    work: {
      id: 5,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["ishikawa"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 19,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 81,
        schedulePlanId: 81,
      },
    ],
    workId: 5,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa"],
    workPlaceName: "もりの里店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
    workPlaceOrganizationId: 9,
  },
  {
    id: 244,
    targetDate: "2023-06-05",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "",
    eventPlaceGmapUrl: "",
    workingHours: 7.5,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-05T09:20:00.000+09:00",
    startAt: "2023-06-05T10:00:00.000+09:00",
    finishAt: "2023-06-05T17:30:00.000+09:00",
    workPlace: {
      id: 17,
      name: "金沢駅前店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 8,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["ishikawa", "toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 1,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 320,
        schedulePlanId: 258,
      },
    ],
    workId: 8,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa", "toyama"],
    workPlaceName: "金沢駅前店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 255,
    targetDate: "2023-06-13",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-13T10:00:00.000+09:00",
    finishAt: "2023-06-13T19:00:00.000+09:00",
    workPlace: {
      id: 220,
      name: "リアルメディア",
      organizationName: "(株)新通エスピー",
    },
    work: {
      id: 17,
      workCategory: "fulltime",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 8,
    workTypeName: "常勤",
    assignedMembers: [
      {
        organizationMemberId: 6,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 332,
        schedulePlanId: 270,
      },
    ],
    workId: 17,
    workCategory: "fulltime",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "リアルメディア",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)新通エスピー",
    workPlaceOrganizationId: 62,
  },
  {
    id: 21,
    targetDate: "2023-06-23",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-23T10:00:00.000+09:00",
    finishAt: "2023-06-23T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 7,
    workTypeName: "コンサル",
    assignedMembers: [
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 19,
        schedulePlanId: 19,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 24,
    targetDate: "2023-06-28",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-28T10:00:00.000+09:00",
    finishAt: "2023-06-28T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 7,
    workTypeName: "コンサル",
    assignedMembers: [
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 30,
        schedulePlanId: 30,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 59,
    targetDate: "2023-06-03",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "サンコー砺波店",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=サンコー砺波店+",
    workingHours: 8,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-03T09:30:00.000+09:00",
    startAt: "2023-06-03T10:00:00.000+09:00",
    finishAt: "2023-06-03T18:00:00.000+09:00",
    workPlace: {
      id: 41,
      name: "砺波店",
      organizationName: "米原商事(株)",
    },
    work: {
      id: 4,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 5,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 66,
        schedulePlanId: 66,
      },
    ],
    workId: 4,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "砺波店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "米原商事(株)",
    workPlaceOrganizationId: 18,
  },
  {
    id: 241,
    targetDate: "2023-06-18",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "アピタ福井大和田",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-18T09:50:00.000+09:00",
    startAt: "2023-06-18T10:00:00.000+09:00",
    finishAt: "2023-06-18T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 16,
      workCategory: "event_helper",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 6,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 317,
        schedulePlanId: 96,
      },
    ],
    workId: 16,
    workCategory: "event_helper",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 96,
    targetDate: "2023-06-09",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "イオン金沢",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=イオン金沢+",
    workingHours: 8,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-09T09:30:00.000+09:00",
    startAt: "2023-06-09T10:00:00.000+09:00",
    finishAt: "2023-06-09T18:00:00.000+09:00",
    workPlace: {
      id: 201,
      name: "春江",
      organizationName: "(株)ジョイテル",
    },
    work: {
      id: 7,
      workCategory: "event_helper",
      carrier: "softbank",
      prefectures: ["ishikawa"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 5,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 126,
        schedulePlanId: 125,
      },
    ],
    workId: 7,
    workCategory: "event_helper",
    workCarrier: "softbank",
    workPrefectures: ["ishikawa"],
    workPlaceName: "春江",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)ジョイテル",
    workPlaceOrganizationId: 54,
  },
  {
    id: 258,
    targetDate: "2023-06-20",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-20T10:00:00.000+09:00",
    finishAt: "2023-06-20T19:00:00.000+09:00",
    workPlace: {
      id: 220,
      name: "リアルメディア",
      organizationName: "(株)新通エスピー",
    },
    work: {
      id: 17,
      workCategory: "fulltime",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 8,
    workTypeName: "常勤",
    assignedMembers: [
      {
        organizationMemberId: 6,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 335,
        schedulePlanId: 273,
      },
    ],
    workId: 17,
    workCategory: "fulltime",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "リアルメディア",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)新通エスピー",
    workPlaceOrganizationId: 62,
  },
  {
    id: 27,
    targetDate: "2023-06-29",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-29T10:00:00.000+09:00",
    finishAt: "2023-06-29T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 4,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 110,
        schedulePlanId: 110,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 62,
    targetDate: "2023-06-11",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "サンコー砺波店",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=サンコー砺波店+",
    workingHours: 8,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-11T09:30:00.000+09:00",
    startAt: "2023-06-11T10:00:00.000+09:00",
    finishAt: "2023-06-11T18:00:00.000+09:00",
    workPlace: {
      id: 41,
      name: "砺波店",
      organizationName: "米原商事(株)",
    },
    work: {
      id: 4,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 5,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 69,
        schedulePlanId: 69,
      },
    ],
    workId: 4,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "砺波店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "米原商事(株)",
    workPlaceOrganizationId: 18,
  },
  {
    id: 99,
    targetDate: "2023-06-17",
    needCount: 2,
    currentCount: 2,
    enoughCount: true,
    eventPlaceName: "ユナイテッドシネマ金沢",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=ユナイテッドシネマ金沢+",
    workingHours: 8,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-17T09:30:00.000+09:00",
    startAt: "2023-06-17T10:00:00.000+09:00",
    finishAt: "2023-06-17T18:00:00.000+09:00",
    workPlace: {
      id: 201,
      name: "春江",
      organizationName: "(株)ジョイテル",
    },
    work: {
      id: 7,
      workCategory: "event_helper",
      carrier: "softbank",
      prefectures: ["ishikawa"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 15,
        organizationMemberName: "後藤1",
        workScheduleAssignmentId: 129,
        schedulePlanId: 128,
      },
      {
        organizationMemberId: 16,
        organizationMemberName: "後藤2",
        workScheduleAssignmentId: 136,
        schedulePlanId: 135,
      },
    ],
    workId: 7,
    workCategory: "event_helper",
    workCarrier: "softbank",
    workPrefectures: ["ishikawa"],
    workPlaceName: "春江",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)ジョイテル",
    workPlaceOrganizationId: 54,
  },
  {
    id: 261,
    targetDate: "2023-06-24",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-24T10:00:00.000+09:00",
    finishAt: "2023-06-24T19:00:00.000+09:00",
    workPlace: {
      id: 220,
      name: "リアルメディア",
      organizationName: "(株)新通エスピー",
    },
    work: {
      id: 17,
      workCategory: "fulltime",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 8,
    workTypeName: "常勤",
    assignedMembers: [
      {
        organizationMemberId: 6,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 338,
        schedulePlanId: 276,
      },
    ],
    workId: 17,
    workCategory: "fulltime",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "リアルメディア",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)新通エスピー",
    workPlaceOrganizationId: 62,
  },
  {
    id: 104,
    targetDate: "2023-06-21",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-21T10:00:00.000+09:00",
    finishAt: "2023-06-21T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 4,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 143,
        schedulePlanId: 142,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 180,
    targetDate: "2023-06-02",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-02T10:00:00.000+09:00",
    finishAt: "2023-06-02T19:00:00.000+09:00",
    workPlace: {
      id: 212,
      name: "江守中",
      organizationName: "(株)リアルメディア",
    },
    work: {
      id: 11,
      workCategory: "rounder",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 3,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 237,
        schedulePlanId: 179,
      },
    ],
    workId: 11,
    workCategory: "rounder",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "江守中",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)リアルメディア",
    workPlaceOrganizationId: 59,
  },
  {
    id: 102,
    targetDate: "2023-06-24",
    needCount: 2,
    currentCount: 2,
    enoughCount: true,
    eventPlaceName: "イオン金沢",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=イオン金沢+",
    workingHours: 8,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-24T09:30:00.000+09:00",
    startAt: "2023-06-24T10:00:00.000+09:00",
    finishAt: "2023-06-24T18:00:00.000+09:00",
    workPlace: {
      id: 201,
      name: "春江",
      organizationName: "(株)ジョイテル",
    },
    work: {
      id: 7,
      workCategory: "event_helper",
      carrier: "softbank",
      prefectures: ["ishikawa"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 19,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 132,
        schedulePlanId: 131,
      },
      {
        organizationMemberId: 16,
        organizationMemberName: "後藤2",
        workScheduleAssignmentId: 138,
        schedulePlanId: 137,
      },
    ],
    workId: 7,
    workCategory: "event_helper",
    workCarrier: "softbank",
    workPrefectures: ["ishikawa"],
    workPlaceName: "春江",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)ジョイテル",
    workPlaceOrganizationId: 54,
  },
  {
    id: 264,
    targetDate: "2023-06-28",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-28T10:00:00.000+09:00",
    finishAt: "2023-06-28T19:00:00.000+09:00",
    workPlace: {
      id: 220,
      name: "リアルメディア",
      organizationName: "(株)新通エスピー",
    },
    work: {
      id: 17,
      workCategory: "fulltime",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 8,
    workTypeName: "常勤",
    assignedMembers: [
      {
        organizationMemberId: 6,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 341,
        schedulePlanId: 279,
      },
    ],
    workId: 17,
    workCategory: "fulltime",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "リアルメディア",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)新通エスピー",
    workPlaceOrganizationId: 62,
  },
  {
    id: 31,
    targetDate: "2023-06-02",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "武生SIPY",
    eventPlaceGmapUrl: "https://www.google.com/maps/search/?api=1&query=SIPY+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-02T09:50:00.000+09:00",
    startAt: "2023-06-02T10:00:00.000+09:00",
    finishAt: "2023-06-02T17:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 2,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 4,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 281,
        schedulePlanId: 251,
      },
    ],
    workId: 2,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 183,
    targetDate: "2023-06-06",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-06T10:00:00.000+09:00",
    finishAt: "2023-06-06T19:00:00.000+09:00",
    workPlace: {
      id: 211,
      name: "御幸",
      organizationName: "(株)リアルメディア",
    },
    work: {
      id: 11,
      workCategory: "rounder",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 3,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 240,
        schedulePlanId: 183,
      },
    ],
    workId: 11,
    workCategory: "rounder",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "御幸",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)リアルメディア",
    workPlaceOrganizationId: 59,
  },
  {
    id: 107,
    targetDate: "2023-06-03",
    needCount: 2,
    currentCount: 2,
    enoughCount: true,
    eventPlaceName: "ヤマキシ新加賀店",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=ヤマキシ新加賀店+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-03T09:20:00.000+09:00",
    startAt: "2023-06-03T10:00:00.000+09:00",
    finishAt: "2023-06-03T17:00:00.000+09:00",
    workPlace: {
      id: 5,
      name: "加賀店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 8,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["ishikawa", "toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 1,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 146,
        schedulePlanId: 145,
      },
      {
        organizationMemberId: 21,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 147,
        schedulePlanId: 146,
      },
    ],
    workId: 8,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa", "toyama"],
    workPlaceName: "加賀店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 3,
    targetDate: "2023-06-05",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-05T10:00:00.000+09:00",
    finishAt: "2023-06-05T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 7,
    workTypeName: "コンサル",
    assignedMembers: [
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 3,
        schedulePlanId: 3,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 34,
    targetDate: "2023-06-05",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "武生SIPY",
    eventPlaceGmapUrl: "https://www.google.com/maps/search/?api=1&query=SIPY+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-05T09:50:00.000+09:00",
    startAt: "2023-06-05T10:00:00.000+09:00",
    finishAt: "2023-06-05T17:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 2,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 5,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 39,
        schedulePlanId: 39,
      },
    ],
    workId: 2,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 186,
    targetDate: "2023-06-10",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-10T10:00:00.000+09:00",
    finishAt: "2023-06-10T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 11,
      workCategory: "rounder",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 3,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 243,
        schedulePlanId: 186,
      },
    ],
    workId: 11,
    workCategory: "rounder",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 221,
    targetDate: "2023-06-10",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "アピタ福井大和田",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-10T09:50:00.000+09:00",
    startAt: "2023-06-10T10:00:00.000+09:00",
    finishAt: "2023-06-10T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 15,
      workCategory: "normal",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 1,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 297,
        schedulePlanId: 202,
      },
    ],
    workId: 15,
    workCategory: "normal",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 110,
    targetDate: "2023-06-10",
    needCount: 4,
    currentCount: 3,
    enoughCount: false,
    eventPlaceName: "イオンモール新小松",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=イオンモール新小松+",
    workingHours: 8,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-10T09:00:00.000+09:00",
    startAt: "2023-06-10T10:00:00.000+09:00",
    finishAt: "2023-06-10T18:00:00.000+09:00",
    workPlace: {
      id: 217,
      name: "中部特機産業(株)",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 8,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["ishikawa", "toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 15,
        organizationMemberName: "後藤1",
        workScheduleAssignmentId: 154,
        schedulePlanId: 153,
      },
      {
        organizationMemberId: 16,
        organizationMemberName: "後藤2",
        workScheduleAssignmentId: 155,
        schedulePlanId: 154,
      },
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 156,
        schedulePlanId: 155,
      },
    ],
    workId: 8,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa", "toyama"],
    workPlaceName: "中部特機産業(株)",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 6,
    targetDate: "2023-06-09",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-09T10:00:00.000+09:00",
    finishAt: "2023-06-09T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 4,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 97,
        schedulePlanId: 97,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 37,
    targetDate: "2023-06-15",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "ワイプラザ鯖江店",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=ワイプラザ鯖江店+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-15T09:30:00.000+09:00",
    startAt: "2023-06-15T10:00:00.000+09:00",
    finishAt: "2023-06-15T17:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 2,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 5,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 42,
        schedulePlanId: 42,
      },
    ],
    workId: 2,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 189,
    targetDate: "2023-06-13",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-13T10:00:00.000+09:00",
    finishAt: "2023-06-13T19:00:00.000+09:00",
    workPlace: {
      id: 220,
      name: "リアルメディア",
      organizationName: "(株)新通エスピー",
    },
    work: {
      id: 11,
      workCategory: "rounder",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 3,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 248,
        schedulePlanId: 189,
      },
    ],
    workId: 11,
    workCategory: "rounder",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "リアルメディア",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)新通エスピー",
    workPlaceOrganizationId: 62,
  },
  {
    id: 224,
    targetDate: "2023-06-16",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "アピタ福井大和田",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-16T09:50:00.000+09:00",
    startAt: "2023-06-16T10:00:00.000+09:00",
    finishAt: "2023-06-16T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 15,
      workCategory: "normal",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 1,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 300,
        schedulePlanId: 205,
      },
    ],
    workId: 15,
    workCategory: "normal",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 116,
    targetDate: "2023-06-17",
    needCount: 2,
    currentCount: 2,
    enoughCount: true,
    eventPlaceName: "アル・プラザ小松",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アル・プラザ小松+",
    workingHours: 7.5,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-17T09:00:00.000+09:00",
    startAt: "2023-06-17T10:00:00.000+09:00",
    finishAt: "2023-06-17T17:30:00.000+09:00",
    workPlace: {
      id: 23,
      name: "粟津店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 8,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["ishikawa", "toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 21,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 168,
        schedulePlanId: 167,
      },
      {
        organizationMemberId: 18,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 345,
        schedulePlanId: 283,
      },
    ],
    workId: 8,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa", "toyama"],
    workPlaceName: "粟津店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 9,
    targetDate: "2023-06-12",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-12T10:00:00.000+09:00",
    finishAt: "2023-06-12T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 7,
    workTypeName: "コンサル",
    assignedMembers: [
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 10,
        schedulePlanId: 10,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 40,
    targetDate: "2023-06-18",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "ワイプラザ鯖江店",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=ワイプラザ鯖江店+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-18T09:50:00.000+09:00",
    startAt: "2023-06-18T10:00:00.000+09:00",
    finishAt: "2023-06-18T17:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 2,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 4,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 115,
        schedulePlanId: 115,
      },
    ],
    workId: 2,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 192,
    targetDate: "2023-06-19",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-19T10:00:00.000+09:00",
    finishAt: "2023-06-19T19:00:00.000+09:00",
    workPlace: {
      id: 212,
      name: "江守中",
      organizationName: "(株)リアルメディア",
    },
    work: {
      id: 11,
      workCategory: "rounder",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 3,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 249,
        schedulePlanId: 192,
      },
    ],
    workId: 11,
    workCategory: "rounder",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "江守中",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)リアルメディア",
    workPlaceOrganizationId: 59,
  },
  {
    id: 227,
    targetDate: "2023-06-19",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "アピタ福井大和田",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-19T09:50:00.000+09:00",
    startAt: "2023-06-19T10:00:00.000+09:00",
    finishAt: "2023-06-19T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 15,
      workCategory: "normal",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 1,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 301,
        schedulePlanId: 208,
      },
    ],
    workId: 15,
    workCategory: "normal",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 119,
    targetDate: "2023-06-21",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "シマヤ小杉店",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=シマヤ小杉店+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-21T09:40:00.000+09:00",
    startAt: "2023-06-21T10:00:00.000+09:00",
    finishAt: "2023-06-21T17:00:00.000+09:00",
    workPlace: {
      id: 37,
      name: "高岡野村店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 8,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["ishikawa", "toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 18,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 171,
        schedulePlanId: 170,
      },
    ],
    workId: 8,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa", "toyama"],
    workPlaceName: "高岡野村店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 247,
    targetDate: "2023-06-01",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-01T10:00:00.000+09:00",
    finishAt: "2023-06-01T19:00:00.000+09:00",
    workPlace: {
      id: 220,
      name: "リアルメディア",
      organizationName: "(株)新通エスピー",
    },
    work: {
      id: 17,
      workCategory: "fulltime",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 8,
    workTypeName: "常勤",
    assignedMembers: [
      {
        organizationMemberId: 6,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 324,
        schedulePlanId: 262,
      },
    ],
    workId: 17,
    workCategory: "fulltime",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "リアルメディア",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)新通エスピー",
    workPlaceOrganizationId: 62,
  },
  {
    id: 12,
    targetDate: "2023-06-15",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-15T10:00:00.000+09:00",
    finishAt: "2023-06-15T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 7,
    workTypeName: "コンサル",
    assignedMembers: [
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 12,
        schedulePlanId: 12,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 43,
    targetDate: "2023-06-25",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "ワイプラザ鯖江店",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=ワイプラザ鯖江店+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-25T09:50:00.000+09:00",
    startAt: "2023-06-25T10:00:00.000+09:00",
    finishAt: "2023-06-25T17:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 2,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 4,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 118,
        schedulePlanId: 118,
      },
    ],
    workId: 2,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 195,
    targetDate: "2023-06-24",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-24T10:00:00.000+09:00",
    finishAt: "2023-06-24T19:00:00.000+09:00",
    workPlace: {
      id: 211,
      name: "御幸",
      organizationName: "(株)リアルメディア",
    },
    work: {
      id: 11,
      workCategory: "rounder",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 3,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 255,
        schedulePlanId: 196,
      },
    ],
    workId: 11,
    workCategory: "rounder",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "御幸",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)リアルメディア",
    workPlaceOrganizationId: 59,
  },
  {
    id: 230,
    targetDate: "2023-06-25",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "アピタ福井大和田",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-25T09:50:00.000+09:00",
    startAt: "2023-06-25T10:00:00.000+09:00",
    finishAt: "2023-06-25T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 15,
      workCategory: "normal",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 1,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 304,
        schedulePlanId: 211,
      },
    ],
    workId: 15,
    workCategory: "normal",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 65,
    targetDate: "2023-06-24",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "ドン・キホーテ砺波店",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=ドン・キホーテ砺波店+",
    workingHours: 8,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-24T09:30:00.000+09:00",
    startAt: "2023-06-24T10:00:00.000+09:00",
    finishAt: "2023-06-24T18:00:00.000+09:00",
    workPlace: {
      id: 41,
      name: "砺波店",
      organizationName: "米原商事(株)",
    },
    work: {
      id: 4,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 5,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 72,
        schedulePlanId: 72,
      },
    ],
    workId: 4,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "砺波店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "米原商事(株)",
    workPlaceOrganizationId: 18,
  },
  {
    id: 250,
    targetDate: "2023-06-04",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-04T10:00:00.000+09:00",
    finishAt: "2023-06-04T19:00:00.000+09:00",
    workPlace: {
      id: 220,
      name: "リアルメディア",
      organizationName: "(株)新通エスピー",
    },
    work: {
      id: 17,
      workCategory: "fulltime",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 8,
    workTypeName: "常勤",
    assignedMembers: [
      {
        organizationMemberId: 6,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 327,
        schedulePlanId: 265,
      },
    ],
    workId: 17,
    workCategory: "fulltime",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "リアルメディア",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)新通エスピー",
    workPlaceOrganizationId: 62,
  },
  {
    id: 15,
    targetDate: "2023-06-18",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-18T10:00:00.000+09:00",
    finishAt: "2023-06-18T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 7,
    workTypeName: "コンサル",
    assignedMembers: [
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 15,
        schedulePlanId: 15,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 198,
    targetDate: "2023-06-27",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: true,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-27T10:00:00.000+09:00",
    finishAt: "2023-06-27T19:00:00.000+09:00",
    workPlace: {
      id: 211,
      name: "御幸",
      organizationName: "(株)リアルメディア",
    },
    work: {
      id: 11,
      workCategory: "rounder",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 6,
    workTypeName: "ラウンダー",
    assignedMembers: [
      {
        organizationMemberId: 3,
        organizationMemberName: "小島 孝介",
        workScheduleAssignmentId: 253,
        schedulePlanId: 199,
      },
    ],
    workId: 11,
    workCategory: "rounder",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "御幸",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)リアルメディア",
    workPlaceOrganizationId: 59,
  },
  {
    id: 233,
    targetDate: "2023-06-02",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "アピタ福井大和田",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-02T09:50:00.000+09:00",
    startAt: "2023-06-02T10:00:00.000+09:00",
    finishAt: "2023-06-02T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 16,
      workCategory: "event_helper",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 18,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 309,
        schedulePlanId: 250,
      },
    ],
    workId: 16,
    workCategory: "event_helper",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 236,
    targetDate: "2023-06-09",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "アピタ福井大和田",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-09T09:50:00.000+09:00",
    startAt: "2023-06-09T10:00:00.000+09:00",
    finishAt: "2023-06-09T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 16,
      workCategory: "event_helper",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 18,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 312,
        schedulePlanId: 94,
      },
    ],
    workId: 16,
    workCategory: "event_helper",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 73,
    targetDate: "2023-06-09",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-09T10:00:00.000+09:00",
    finishAt: "2023-06-09T19:00:00.000+09:00",
    workPlace: {
      id: 14,
      name: "もりの里店",
      organizationName: "兼松コミュニケーションズ(株)",
    },
    work: {
      id: 5,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["ishikawa"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 19,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 79,
        schedulePlanId: 79,
      },
    ],
    workId: 5,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa"],
    workPlaceName: "もりの里店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
    workPlaceOrganizationId: 9,
  },
  {
    id: 126,
    targetDate: "2023-06-07",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "業務スーパー和倉",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=業務スーパー和倉+",
    workingHours: 7,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-07T09:30:00.000+09:00",
    startAt: "2023-06-07T10:00:00.000+09:00",
    finishAt: "2023-06-07T17:00:00.000+09:00",
    workPlace: {
      id: 3,
      name: "羽咋店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 8,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["ishikawa", "toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 5,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 151,
        schedulePlanId: 150,
      },
    ],
    workId: 8,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa", "toyama"],
    workPlaceName: "羽咋店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 253,
    targetDate: "2023-06-10",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-10T10:00:00.000+09:00",
    finishAt: "2023-06-10T19:00:00.000+09:00",
    workPlace: {
      id: 220,
      name: "リアルメディア",
      organizationName: "(株)新通エスピー",
    },
    work: {
      id: 17,
      workCategory: "fulltime",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 8,
    workTypeName: "常勤",
    assignedMembers: [
      {
        organizationMemberId: 6,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 330,
        schedulePlanId: 268,
      },
    ],
    workId: 17,
    workCategory: "fulltime",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "リアルメディア",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)新通エスピー",
    workPlaceOrganizationId: 62,
  },
  {
    id: 18,
    targetDate: "2023-06-21",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-21T10:00:00.000+09:00",
    finishAt: "2023-06-21T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 7,
    workTypeName: "コンサル",
    assignedMembers: [
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 17,
        schedulePlanId: 17,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 22,
    targetDate: "2023-06-26",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-26T10:00:00.000+09:00",
    finishAt: "2023-06-26T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 7,
    workTypeName: "コンサル",
    assignedMembers: [
      {
        organizationMemberId: 20,
        organizationMemberName: "井上 敬太",
        workScheduleAssignmentId: 258,
        schedulePlanId: 237,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 239,
    targetDate: "2023-06-16",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "アピタ福井大和田",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-16T09:50:00.000+09:00",
    startAt: "2023-06-16T10:00:00.000+09:00",
    finishAt: "2023-06-16T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 16,
      workCategory: "event_helper",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 6,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 315,
        schedulePlanId: 253,
      },
    ],
    workId: 16,
    workCategory: "event_helper",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 77,
    targetDate: "2023-06-17",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-17T10:00:00.000+09:00",
    finishAt: "2023-06-17T19:00:00.000+09:00",
    workPlace: {
      id: 14,
      name: "もりの里店",
      organizationName: "兼松コミュニケーションズ(株)",
    },
    work: {
      id: 5,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["ishikawa"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 19,
        organizationMemberName: "水上 祐介",
        workScheduleAssignmentId: 83,
        schedulePlanId: 83,
      },
    ],
    workId: 5,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa"],
    workPlaceName: "もりの里店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "兼松コミュニケーションズ(株)",
    workPlaceOrganizationId: 9,
  },
  {
    id: 245,
    targetDate: "2023-06-06",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "",
    eventPlaceGmapUrl: "",
    workingHours: 7.5,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-06T09:20:00.000+09:00",
    startAt: "2023-06-06T10:00:00.000+09:00",
    finishAt: "2023-06-06T17:30:00.000+09:00",
    workPlace: {
      id: 17,
      name: "金沢駅前店",
      organizationName: "中部特機産業(株)",
    },
    work: {
      id: 8,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["ishikawa", "toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 1,
        organizationMemberName: "竹中 福善",
        workScheduleAssignmentId: 321,
        schedulePlanId: 259,
      },
    ],
    workId: 8,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["ishikawa", "toyama"],
    workPlaceName: "金沢駅前店",
    workPlacePrefecture: "ishikawa",
    workPlaceOrganizationName: "中部特機産業(株)",
    workPlaceOrganizationId: 10,
  },
  {
    id: 256,
    targetDate: "2023-06-15",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-15T10:00:00.000+09:00",
    finishAt: "2023-06-15T19:00:00.000+09:00",
    workPlace: {
      id: 220,
      name: "リアルメディア",
      organizationName: "(株)新通エスピー",
    },
    work: {
      id: 17,
      workCategory: "fulltime",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 8,
    workTypeName: "常勤",
    assignedMembers: [
      {
        organizationMemberId: 6,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 333,
        schedulePlanId: 271,
      },
    ],
    workId: 17,
    workCategory: "fulltime",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "リアルメディア",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)新通エスピー",
    workPlaceOrganizationId: 62,
  },
  {
    id: 25,
    targetDate: "2023-06-28",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-28T10:00:00.000+09:00",
    finishAt: "2023-06-28T19:00:00.000+09:00",
    workPlace: {
      id: 54,
      name: "武生店",
      organizationName: "カワイ(株)",
    },
    work: {
      id: 1,
      workCategory: "shop_helper",
      carrier: "docomo",
      prefectures: ["fukui"],
    },
    workTypeId: 1,
    workTypeName: "獲得HP",
    assignedMembers: [
      {
        organizationMemberId: 4,
        organizationMemberName: "木戸 理恵",
        workScheduleAssignmentId: 109,
        schedulePlanId: 109,
      },
    ],
    workId: 1,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workPrefectures: ["fukui"],
    workPlaceName: "武生店",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "カワイ(株)",
    workPlaceOrganizationId: 22,
  },
  {
    id: 60,
    targetDate: "2023-06-04",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "サンコー砺波店",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=サンコー砺波店+",
    workingHours: 8,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-04T09:30:00.000+09:00",
    startAt: "2023-06-04T10:00:00.000+09:00",
    finishAt: "2023-06-04T18:00:00.000+09:00",
    workPlace: {
      id: 41,
      name: "砺波店",
      organizationName: "米原商事(株)",
    },
    work: {
      id: 4,
      workCategory: "event_helper",
      carrier: "docomo",
      prefectures: ["toyama"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 5,
        organizationMemberName: "松島 湧吾",
        workScheduleAssignmentId: 67,
        schedulePlanId: 67,
      },
    ],
    workId: 4,
    workCategory: "event_helper",
    workCarrier: "docomo",
    workPrefectures: ["toyama"],
    workPlaceName: "砺波店",
    workPlacePrefecture: "toyama",
    workPlaceOrganizationName: "米原商事(株)",
    workPlaceOrganizationId: 18,
  },
  {
    id: 242,
    targetDate: "2023-06-24",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: "アピタ福井大和田",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=アピタ福井大和田+",
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-24T09:50:00.000+09:00",
    startAt: "2023-06-24T10:00:00.000+09:00",
    finishAt: "2023-06-24T19:00:00.000+09:00",
    workPlace: {
      id: 207,
      name: "アピタ福井大和田",
      organizationName: "(株)NDP",
    },
    work: {
      id: 16,
      workCategory: "event_helper",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 22,
        organizationMemberName: "長岡 陸",
        workScheduleAssignmentId: 318,
        schedulePlanId: 123,
      },
    ],
    workId: 16,
    workCategory: "event_helper",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "アピタ福井大和田",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)NDP",
    workPlaceOrganizationId: 55,
  },
  {
    id: 97,
    targetDate: "2023-06-10",
    needCount: 2,
    currentCount: 2,
    enoughCount: true,
    eventPlaceName: "イオン金沢",
    eventPlaceGmapUrl:
      "https://www.google.com/maps/search/?api=1&query=イオン金沢+",
    workingHours: 8,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: "2023-06-10T09:30:00.000+09:00",
    startAt: "2023-06-10T10:00:00.000+09:00",
    finishAt: "2023-06-10T18:00:00.000+09:00",
    workPlace: {
      id: 201,
      name: "春江",
      organizationName: "(株)ジョイテル",
    },
    work: {
      id: 7,
      workCategory: "event_helper",
      carrier: "softbank",
      prefectures: ["ishikawa"],
    },
    workTypeId: 3,
    workTypeName: "クローザー",
    assignedMembers: [
      {
        organizationMemberId: 18,
        organizationMemberName: "網島 恭平",
        workScheduleAssignmentId: 127,
        schedulePlanId: 126,
      },
      {
        organizationMemberId: 21,
        organizationMemberName: "木戸脇 悠平",
        workScheduleAssignmentId: 134,
        schedulePlanId: 133,
      },
    ],
    workId: 7,
    workCategory: "event_helper",
    workCarrier: "softbank",
    workPrefectures: ["ishikawa"],
    workPlaceName: "春江",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)ジョイテル",
    workPlaceOrganizationId: 54,
  },
  {
    id: 259,
    targetDate: "2023-06-21",
    needCount: 1,
    currentCount: 1,
    enoughCount: true,
    eventPlaceName: null,
    eventPlaceGmapUrl: null,
    workingHours: 9,
    needBodyTemperature: false,
    needAntibodyTestWithin: 0,
    meetingAt: null,
    startAt: "2023-06-21T10:00:00.000+09:00",
    finishAt: "2023-06-21T19:00:00.000+09:00",
    workPlace: {
      id: 220,
      name: "リアルメディア",
      organizationName: "(株)新通エスピー",
    },
    work: {
      id: 17,
      workCategory: "fulltime",
      carrier: "softbank",
      prefectures: ["fukui"],
    },
    workTypeId: 8,
    workTypeName: "常勤",
    assignedMembers: [
      {
        organizationMemberId: 6,
        organizationMemberName: "髙木 晶平",
        workScheduleAssignmentId: 336,
        schedulePlanId: 274,
      },
    ],
    workId: 17,
    workCategory: "fulltime",
    workCarrier: "softbank",
    workPrefectures: ["fukui"],
    workPlaceName: "リアルメディア",
    workPlacePrefecture: "fukui",
    workPlaceOrganizationName: "(株)新通エスピー",
    workPlaceOrganizationId: 62,
  },
];

export const workRecordEntries: WorkRecordEntryClient[] = [
  {
    id: 5,
    workScheduleId: 25,
    workId: 7,
    startAt: "2023-04-05T07:00:47.171+09:00",
    startLat: 46.5478,
    startLng: 146.665,
    startPhotoUrl:
      "http://localhost:3000/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBDZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--48543b6ae9bc9e1b1c189d2472b115afe824adba/1680689987",
    finishAt: "2023-04-05T12:00:47.171+09:00",
    finishLat: undefined,
    finishLng: undefined,
    finishPhotoUrl: undefined,
    workCategory: "shop_helper",
    workCarrier: "docomo",
    workTypeName: "獲得支援",
    workTypeCategories: ["telecommunications"],
    workPlaceId: 10,
    workPlaceName: "婦中",
    workPlaceShortName: "婦中",
    workScheduleWorkTypeName: "獲得支援",
    workScheduleWorkPlaceName: "婦中",
    workScheduleWorkCategory: "shop_helper",
    workScheduleWorkCarrier: "au",
    workScheduleStartAt: "2000-01-01T07:00:00.000+09:00",
    workScheduleFinishAt: "2000-01-01T12:00:00.000+09:00",
    workScheduleCanceled: false,
    workRequestOrganizationId: 1,
    workRequestOrganizaitonName: "(株)テスト",
    workTasks: [],
    workRecordEntryReports: [],
  },
  {
    id: 5,
    workScheduleId: 25,
    workId: 7,
    startAt: "2023-04-05T13:00:47.171+09:00",
    startLat: 46.5478,
    startLng: 146.665,
    startPhotoUrl:
      "http://localhost:3000/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBDZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--48543b6ae9bc9e1b1c189d2472b115afe824adba/1680689987",
    finishAt: undefined,
    finishLat: undefined,
    finishLng: undefined,
    finishPhotoUrl: undefined,
    workCategory: "shop_helper",
    workCarrier: "au",
    workTypeName: "獲得支援",
    workPlaceId: 10,
    workPlaceName: "婦中",
    workPlaceShortName: "婦中",
    workScheduleWorkTypeName: "獲得支援",
    workScheduleWorkPlaceName: "婦中",
    workScheduleWorkCategory: "shop_helper",
    workScheduleWorkCarrier: "au",
    workScheduleStartAt: "2000-01-01T13:00:00.000+09:00",
    workScheduleFinishAt: "2000-01-01T17:00:00.000+09:00",
    workScheduleCanceled: false,
    workRequestOrganizationId: 1,
    workRequestOrganizaitonName: "(株)テスト",
    workRecordEntryReports: [
      {
        reportType: "overall",
        id: 1,
        reportItems: [],
      },
    ],
  },
  {
    id: 5,
    workScheduleId: undefined,
    workId: 7,
    startAt: "2023-04-05T07:00:47.171+09:00",
    startLat: 46.5478,
    startLng: 146.665,
    startPhotoUrl:
      "http://localhost:3000/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBDZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--48543b6ae9bc9e1b1c189d2472b115afe824adba/1680689987",
    finishAt: undefined,
    finishLat: undefined,
    finishLng: undefined,
    finishPhotoUrl: undefined,
    workCategory: "shop_helper",
    workCarrier: "au",
    workPlaceId: 10,
    workPlaceName: "婦中",
    workScheduleWorkPlaceName: undefined,
    workScheduleWorkCategory: undefined,
    workScheduleWorkCarrier: undefined,
    workScheduleStartAt: undefined,
    workScheduleFinishAt: undefined,
    workRecordEntryReports: [],
  },
];

export const workRecords = [
  {
    id: 5,
    startAt: "2023-05-05T07:00:47.171+09:00",
    finishAt: undefined,
    workRecordEntries: [workRecordEntries.at(0), workRecordEntries.at(1)],
  },
];

export const organizationMembersWithSchedules: OrganizationMember[] = [
  {
    id: 1,
    organizationId: 1,
    enteringOn: "2023-03-04",
    code: "1",
    user: { id: 2, name: "管理者", email: "s1200191@gmail.com" },
    needFinishReport: false,
    needEnterLeaveReport: true,
    submitLocationTime: undefined,
    memberColor: "#703e8c",
    roles: ["owner"],
    todayWorkSchedules: [],
    todayWorkRecords: [],
  },
  {
    id: 5,
    organizationId: 1,
    enteringOn: "2023-03-18",
    code: "2",
    user: { id: 3, name: "個人事業主", email: "t.furukawa@fryth.co.jp" },
    needFinishReport: false,
    needEnterLeaveReport: true,
    submitLocationTime: "2000-01-01T15:00:00.000+09:00",
    memberColor: "#8a819e",
    roles: ["worker"],
    todayWorkSchedules: [
      {
        id: 28,
        targetDate: "2023-04-14",
        startAt: "2000-01-01T07:00:00.000+09:00",
        finishAt: "2000-01-01T17:00:00.000+09:00",
        organizationMemberName: "個人事業主",
        done: false,
        workId: 7,
        workCategory: "shop_helper",
        workCarrier: "au",
        workPrefectures: ["toyama"],
        workTypeName: "獲得支援",
        workPlaceName: "婦中",
        workPlacePrefecture: "toyama",
        workPlaceOrganizationName: "(株)ダイエー通信",
        workPlaceOrganizationId: 35,
      },
    ],
    todayWorkRecords: [
      {
        id: 7,
        organizationMemberId: 1,
        startAt: "2023-04-14T20:34:34.577+09:00",
        finishAt: undefined,
        workRecordEntries: [
          {
            id: 7,
            workScheduleId: 28,
            workId: 7,
            startAt: "2023-04-14T20:34:34.577+09:00",
            startLat: 36.5478,
            startLng: 136.665,
            startPhotoUrl:
              "http://localhost:3000/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBFQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--a742cc3fd1804d330ff83addf6500f97f6c50600/1681472074",
            finishAt: undefined,
            finishLat: undefined,
            finishLng: undefined,
            finishPhotoUrl: undefined,
            workCategory: "shop_helper",
            workCarrier: "au",
            workTypeName: "獲得支援",
            workPlaceId: 10,
            workPlaceName: "婦中",
            workScheduleWorkTypeName: "獲得支援",
            workScheduleWorkPlaceName: "婦中",
            workScheduleWorkCategory: "shop_helper",
            workScheduleWorkCarrier: "au",
            workScheduleStartAt: "2000-01-01T07:00:00.000+09:00",
            workScheduleFinishAt: "2000-01-01T17:00:00.000+09:00",
          },
        ],
      },
    ],
  },
  {
    id: 6,
    organizationId: 1,
    enteringOn: "2023-03-28",
    code: "3",
    user: { id: 4, name: "従業員", email: "tester@fryth.co.jp" },
    needFinishReport: true,
    needEnterLeaveReport: true,
    submitLocationTime: "2000-01-01T14:00:00.000+09:00",
    memberColor: "#184e5d",
    roles: ["worker"],
    todayWorkSchedules: [],
    todayWorkRecords: [],
  },
];

export const workTypes: WorkTypeClient[] = [
  {
    id: 3,
    name: "獲得支援",
    categories: ["telecommunications"],
  },
  {
    id: 5,
    name: "資料作成",
    categories: ["general"],
  },
];

export const planMap: PlanMap = {
  absenceApplicationPlans: [
    {
      id: 3,
      targetDate: "2023-03-21",
      applicationType: "paid",
      accept: true,
      organizationMember: {
        id: 1,
        name: "管理者",
      },
    },
    {
      id: 7,
      targetDate: "2023-03-30",
      applicationType: "holiday",
      accept: true,
      organizationMember: {
        id: 5,
        name: "個人事業主",
      },
    },
    {
      id: 8,
      targetDate: "2023-03-28",
      applicationType: "halfday",
      accept: false,
      organizationMember: {
        id: 5,
        name: "個人事業主",
      },
    },
    {
      id: 9,
      targetDate: "2023-03-29",
      applicationType: "holiday",
      accept: false,
      organizationMember: {
        id: 5,
        name: "個人事業主",
      },
    },
    {
      id: 11,
      targetDate: "2023-03-31",
      applicationType: "paid",
      accept: false,
      organizationMember: {
        id: 6,
        name: "従業員",
      },
    },
  ],
  workAsPlans: [
    {
      id: 6,
      organizationId: 1,
      workCategory: "rounder",
      startOn: "2023-03-01",
      finishOn: "2023-03-31",
      prefectures: ["ishikawa"],
      carrier: "softbank",
      workOrganizations: [
        {
          id: 48,
          name: "ダイワ通信(株)",
        },
      ],
      workPlaces: [
        {
          id: 172,
          name: "イオンモール白山",
        },
      ],
      workSchedules: [
        {
          id: 10,
          workId: 6,
          workPlaceId: 172,
          targetDate: "2023-03-28",
          startAt: "2000-01-01T09:00:00.000+09:00",
          finishAt: "2000-01-01T18:00:00.000+09:00",
        },
        {
          id: 11,
          workId: 6,
          workPlaceId: 172,
          targetDate: "2023-03-29",
          startAt: "2000-01-01T09:00:00.000+09:00",
          finishAt: "2000-01-01T18:00:00.000+09:00",
        },
        {
          id: 12,
          workId: 6,
          workPlaceId: 172,
          targetDate: "2023-03-14",
          startAt: "2000-01-01T08:00:00.000+09:00",
          finishAt: "2000-01-01T18:00:00.000+09:00",
        },
      ],
    },
    {
      id: 7,
      organizationId: 1,
      workCategory: "shop_helper",
      startOn: "2023-03-01",
      finishOn: "2023-04-30",
      prefectures: ["toyama"],
      carrier: "au",
      workOrganizations: [
        {
          id: 35,
          name: "(株)ダイエー通信",
        },
      ],
      workPlaces: [
        {
          id: 115,
          name: "婦中",
        },
      ],
      workSchedules: [
        {
          id: 13,
          workId: 7,
          workPlaceId: 115,
          targetDate: "2023-03-27",
          startAt: "2000-01-01T08:00:00.000+09:00",
          finishAt: "2000-01-01T16:00:00.000+09:00",
        },
        {
          id: 14,
          workId: 7,
          workPlaceId: 115,
          targetDate: "2023-03-28",
          startAt: "2000-01-01T08:00:00.000+09:00",
          finishAt: "2000-01-01T16:00:00.000+09:00",
        },
        {
          id: 15,
          workId: 7,
          workPlaceId: 115,
          targetDate: "2023-03-29",
          startAt: "2000-01-01T08:00:00.000+09:00",
          finishAt: "2000-01-01T16:00:00.000+09:00",
        },
        {
          id: 16,
          workId: 7,
          workPlaceId: 115,
          targetDate: "2023-03-30",
          startAt: "2000-01-01T08:00:00.000+09:00",
          finishAt: "2000-01-01T16:00:00.000+09:00",
        },
        {
          id: 17,
          workId: 7,
          workPlaceId: 115,
          targetDate: "2023-03-31",
          startAt: "2000-01-01T08:00:00.000+09:00",
          finishAt: "2000-01-01T16:00:00.000+09:00",
        },
        {
          id: 23,
          workId: 7,
          workPlaceId: 115,
          targetDate: "2023-04-03",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
        },
        {
          id: 24,
          workId: 7,
          workPlaceId: 115,
          targetDate: "2023-04-04",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
        },
        {
          id: 25,
          workId: 7,
          workPlaceId: 115,
          targetDate: "2023-04-05",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
        },
        {
          id: 26,
          workId: 7,
          workPlaceId: 115,
          targetDate: "2023-04-06",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
        },
        {
          id: 27,
          workId: 7,
          workPlaceId: 115,
          targetDate: "2023-04-07",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
        },
        {
          id: 28,
          workId: 7,
          workPlaceId: 115,
          targetDate: "2023-04-14",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
        },
        {
          id: 29,
          workId: 7,
          workPlaceId: 115,
          targetDate: "2023-04-15",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
        },
        {
          id: 30,
          workId: 7,
          workPlaceId: 115,
          targetDate: "2023-04-16",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
        },
        {
          id: 31,
          workId: 7,
          workPlaceId: 115,
          targetDate: "2023-04-17",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
        },
        {
          id: 32,
          workId: 7,
          workPlaceId: 115,
          targetDate: "2023-04-18",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
        },
        {
          id: 33,
          workId: 7,
          workPlaceId: 115,
          targetDate: "2023-04-19",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
        },
        {
          id: 34,
          workId: 7,
          workPlaceId: 115,
          targetDate: "2023-04-20",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
        },
        {
          id: 35,
          workId: 7,
          workPlaceId: 115,
          targetDate: "2023-04-21",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
        },
        {
          id: 36,
          workId: 7,
          workPlaceId: 115,
          targetDate: "2023-04-22",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
        },
      ],
    },
    {
      id: 8,
      organizationId: 1,
      workCategory: "shop_helper",
      startOn: "2023-03-01",
      finishOn: "2023-04-30",
      prefectures: ["toyama"],
      carrier: "softbank",
      workOrganizations: [
        {
          id: 52,
          name: "(株)コスモネット",
        },
      ],
      workPlaces: [
        {
          id: 196,
          name: "ファボーレ",
        },
      ],
      workSchedules: [
        {
          id: 18,
          workId: 8,
          workPlaceId: 196,
          targetDate: "2023-03-27",
          startAt: "2000-01-01T08:00:00.000+09:00",
          finishAt: "2000-01-01T18:00:00.000+09:00",
        },
        {
          id: 19,
          workId: 8,
          workPlaceId: 196,
          targetDate: "2023-03-28",
          startAt: "2000-01-01T08:00:00.000+09:00",
          finishAt: "2000-01-01T18:00:00.000+09:00",
        },
        {
          id: 20,
          workId: 8,
          workPlaceId: 196,
          targetDate: "2023-03-29",
          startAt: "2000-01-01T08:00:00.000+09:00",
          finishAt: "2000-01-01T18:00:00.000+09:00",
        },
        {
          id: 21,
          workId: 8,
          workPlaceId: 196,
          targetDate: "2023-03-30",
          startAt: "2000-01-01T08:00:00.000+09:00",
          finishAt: "2000-01-01T18:00:00.000+09:00",
        },
        {
          id: 22,
          workId: 8,
          workPlaceId: 196,
          targetDate: "2023-03-31",
          startAt: "2000-01-01T08:00:00.000+09:00",
          finishAt: "2000-01-01T18:00:00.000+09:00",
        },
      ],
    },
    {
      id: 9,
      organizationId: 1,
      workCategory: "shop_helper",
      startOn: "2023-03-01",
      finishOn: "2023-05-31",
      prefectures: ["toyama"],
      carrier: "au",
      workOrganizations: [
        {
          id: 35,
          name: "(株)ダイエー通信",
        },
      ],
      workPlaces: [
        {
          id: 115,
          name: "婦中",
        },
      ],
      workSchedules: [
        {
          id: 37,
          workId: 9,
          workPlaceId: 115,
          targetDate: "2023-04-17",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
        },
        {
          id: 38,
          workId: 9,
          workPlaceId: 115,
          targetDate: "2023-04-18",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
        },
        {
          id: 39,
          workId: 9,
          workPlaceId: 115,
          targetDate: "2023-04-19",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
        },
        {
          id: 40,
          workId: 9,
          workPlaceId: 115,
          targetDate: "2023-04-20",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
        },
        {
          id: 41,
          workId: 9,
          workPlaceId: 115,
          targetDate: "2023-04-21",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
        },
      ],
    },
  ],
  workScheduleAsPlans: [
    {
      id: 10,
      targetDate: "2023-03-28",
      startAt: "2023-03-28T09:00:00.000+09:00",
      finishAt: "2023-03-28T18:00:00.000+09:00",
      workPlace: {
        id: 172,
        name: "イオンモール白山",
      },
      work: {
        id: 6,
        workCategory: "rounder",
        carrier: "softbank",
        prefectures: ["ishikawa"],
      },
      workTypeId: 3,
      workTypeName: "獲得支援",
      assignedMembers: [],
    },
    {
      id: 11,
      targetDate: "2023-03-29",
      startAt: "2023-03-29T09:00:00.000+09:00",
      finishAt: "2023-03-29T18:00:00.000+09:00",
      workPlace: {
        id: 172,
        name: "イオンモール白山",
      },
      work: {
        id: 6,
        workCategory: "rounder",
        carrier: "softbank",
        prefectures: ["ishikawa"],
      },
      workTypeId: 3,
      workTypeName: "獲得支援",
      assignedMembers: [],
    },
    {
      id: 12,
      targetDate: "2023-03-14",
      startAt: "2023-03-14T08:00:00.000+09:00",
      finishAt: "2023-03-14T18:00:00.000+09:00",
      workPlace: {
        id: 172,
        name: "イオンモール白山",
      },
      work: {
        id: 6,
        workCategory: "rounder",
        carrier: "softbank",
        prefectures: ["ishikawa"],
      },
      workTypeId: 3,
      workTypeName: "獲得支援",
      assignedMembers: [],
    },
    {
      id: 13,
      targetDate: "2023-03-27",
      startAt: "2023-03-27T08:00:00.000+09:00",
      finishAt: "2023-03-27T16:00:00.000+09:00",
      workPlace: {
        id: 115,
        name: "婦中",
      },
      work: {
        id: 7,
        workCategory: "shop_helper",
        carrier: "au",
        prefectures: ["toyama"],
      },
      workTypeId: 1,
      workTypeName: "報告会",
      assignedMembers: [],
    },
    {
      id: 14,
      targetDate: "2023-03-28",
      startAt: "2023-03-28T08:00:00.000+09:00",
      finishAt: "2023-03-28T16:00:00.000+09:00",
      workPlace: {
        id: 115,
        name: "婦中",
      },
      work: {
        id: 7,
        workCategory: "shop_helper",
        carrier: "au",
        prefectures: ["toyama"],
      },
      workTypeId: 1,
      workTypeName: "報告会",
      assignedMembers: [],
    },
    {
      id: 15,
      targetDate: "2023-03-29",
      startAt: "2023-03-29T08:00:00.000+09:00",
      finishAt: "2023-03-29T16:00:00.000+09:00",
      workPlace: {
        id: 115,
        name: "婦中",
      },
      work: {
        id: 7,
        workCategory: "shop_helper",
        carrier: "au",
        prefectures: ["toyama"],
      },
      workTypeId: 1,
      workTypeName: "報告会",
      assignedMembers: [],
    },
    {
      id: 16,
      targetDate: "2023-03-30",
      startAt: "2023-03-30T08:00:00.000+09:00",
      finishAt: "2023-03-30T16:00:00.000+09:00",
      workPlace: {
        id: 115,
        name: "婦中",
      },
      work: {
        id: 7,
        workCategory: "shop_helper",
        carrier: "au",
        prefectures: ["toyama"],
      },
      workTypeId: 1,
      workTypeName: "報告会",
      assignedMembers: [],
    },
    {
      id: 17,
      targetDate: "2023-03-31",
      startAt: "2023-03-31T08:00:00.000+09:00",
      finishAt: "2023-03-31T16:00:00.000+09:00",
      workPlace: {
        id: 115,
        name: "婦中",
      },
      work: {
        id: 7,
        workCategory: "shop_helper",
        carrier: "au",
        prefectures: ["toyama"],
      },
      workTypeId: 1,
      workTypeName: "報告会",
      assignedMembers: [],
    },
    {
      id: 23,
      targetDate: "2023-04-03",
      startAt: "2023-04-03T07:00:00.000+09:00",
      finishAt: "2023-04-03T17:00:00.000+09:00",
      workPlace: {
        id: 115,
        name: "婦中",
      },
      work: {
        id: 7,
        workCategory: "shop_helper",
        carrier: "au",
        prefectures: ["toyama"],
      },
      workTypeId: 1,
      workTypeName: "報告会",
      assignedMembers: [],
    },
    {
      id: 24,
      targetDate: "2023-04-04",
      startAt: "2023-04-04T07:00:00.000+09:00",
      finishAt: "2023-04-04T17:00:00.000+09:00",
      workPlace: {
        id: 115,
        name: "婦中",
      },
      work: {
        id: 7,
        workCategory: "shop_helper",
        carrier: "au",
        prefectures: ["toyama"],
      },
      workTypeId: 1,
      workTypeName: "報告会",
      assignedMembers: [],
    },
    {
      id: 25,
      targetDate: "2023-04-05",
      startAt: "2023-04-05T07:00:00.000+09:00",
      finishAt: "2023-04-05T17:00:00.000+09:00",
      workPlace: {
        id: 115,
        name: "婦中",
      },
      work: {
        id: 7,
        workCategory: "shop_helper",
        carrier: "au",
        prefectures: ["toyama"],
      },
      workTypeId: 1,
      workTypeName: "報告会",
      assignedMembers: [],
    },
    {
      id: 26,
      targetDate: "2023-04-06",
      startAt: "2023-04-06T07:00:00.000+09:00",
      finishAt: "2023-04-06T17:00:00.000+09:00",
      workPlace: {
        id: 115,
        name: "婦中",
      },
      work: {
        id: 7,
        workCategory: "shop_helper",
        carrier: "au",
        prefectures: ["toyama"],
      },
      workTypeId: 1,
      workTypeName: "報告会",
      assignedMembers: [],
    },
    {
      id: 27,
      targetDate: "2023-04-07",
      startAt: "2023-04-07T07:00:00.000+09:00",
      finishAt: "2023-04-07T17:00:00.000+09:00",
      workPlace: {
        id: 115,
        name: "婦中",
      },
      work: {
        id: 7,
        workCategory: "shop_helper",
        carrier: "au",
        prefectures: ["toyama"],
      },
      workTypeId: 1,
      workTypeName: "報告会",
      assignedMembers: [],
    },

    {
      id: 28,
      targetDate: "2023-04-14",
      startAt: "2023-04-14T07:00:00.000+09:00",
      finishAt: "2023-04-14T17:00:00.000+09:00",
      workPlace: {
        id: 115,
        name: "婦中",
      },
      work: {
        id: 7,
        workCategory: "shop_helper",
        carrier: "au",
        prefectures: ["toyama"],
      },
      workTypeId: 1,
      workTypeName: "報告会",
      assignedMembers: [],
    },
    {
      id: 29,
      targetDate: "2023-04-15",
      startAt: "2023-04-15T07:00:00.000+09:00",
      finishAt: "2023-04-15T17:00:00.000+09:00",
      workPlace: {
        id: 115,
        name: "婦中",
      },
      work: {
        id: 7,
        workCategory: "shop_helper",
        carrier: "au",
        prefectures: ["toyama"],
      },
      workTypeId: 1,
      workTypeName: "報告会",
      assignedMembers: [],
    },
    {
      id: 30,
      targetDate: "2023-04-16",
      startAt: "2023-04-16T07:00:00.000+09:00",
      finishAt: "2023-04-16T17:00:00.000+09:00",
      workPlace: {
        id: 115,
        name: "婦中",
      },
      work: {
        id: 7,
        workCategory: "shop_helper",
        carrier: "au",
        prefectures: ["toyama"],
      },
      workTypeId: 1,
      workTypeName: "報告会",
      assignedMembers: [],
    },
    {
      id: 31,
      targetDate: "2023-04-17",
      startAt: "2023-04-17T07:00:00.000+09:00",
      finishAt: "2023-04-17T17:00:00.000+09:00",
      workPlace: {
        id: 115,
        name: "婦中",
      },
      work: {
        id: 7,
        workCategory: "shop_helper",
        carrier: "au",
        prefectures: ["toyama"],
      },
      workTypeId: 1,
      workTypeName: "報告会",
      assignedMembers: [],
    },
    {
      id: 32,
      targetDate: "2023-04-18",
      startAt: "2023-04-18T07:00:00.000+09:00",
      finishAt: "2023-04-18T17:00:00.000+09:00",
      workPlace: {
        id: 115,
        name: "婦中",
      },
      work: {
        id: 7,
        workCategory: "shop_helper",
        carrier: "au",
        prefectures: ["toyama"],
      },
      workTypeId: 1,
      workTypeName: "報告会",
      assignedMembers: [],
    },
    {
      id: 33,
      targetDate: "2023-04-19",
      startAt: "2023-04-19T07:00:00.000+09:00",
      finishAt: "2023-04-19T17:00:00.000+09:00",
      workPlace: {
        id: 115,
        name: "婦中",
      },
      work: {
        id: 7,
        workCategory: "shop_helper",
        carrier: "au",
        prefectures: ["toyama"],
      },
      workTypeId: 1,
      workTypeName: "報告会",
      assignedMembers: [],
    },
    {
      id: 34,
      targetDate: "2023-04-20",
      startAt: "2023-04-20T07:00:00.000+09:00",
      finishAt: "2023-04-20T17:00:00.000+09:00",
      workPlace: {
        id: 115,
        name: "婦中",
      },
      work: {
        id: 7,
        workCategory: "shop_helper",
        carrier: "au",
        prefectures: ["toyama"],
      },
      workTypeId: 1,
      workTypeName: "報告会",
      assignedMembers: [],
    },
    {
      id: 35,
      targetDate: "2023-04-21",
      startAt: "2023-04-21T07:00:00.000+09:00",
      finishAt: "2023-04-21T17:00:00.000+09:00",
      workPlace: {
        id: 115,
        name: "婦中",
      },
      work: {
        id: 7,
        workCategory: "shop_helper",
        carrier: "au",
        prefectures: ["toyama"],
      },
      workTypeId: 1,
      workTypeName: "報告会",
      assignedMembers: [],
    },
    {
      id: 36,
      targetDate: "2023-04-22",
      startAt: "2023-04-22T07:00:00.000+09:00",
      finishAt: "2023-04-22T17:00:00.000+09:00",
      workPlace: {
        id: 115,
        name: "婦中",
      },
      work: {
        id: 7,
        workCategory: "shop_helper",
        carrier: "au",
        prefectures: ["toyama"],
      },
      workTypeId: 1,
      workTypeName: "報告会",
      assignedMembers: [],
    },
    {
      id: 18,
      targetDate: "2023-03-27",
      startAt: "2023-03-27T08:00:00.000+09:00",
      finishAt: "2023-03-27T18:00:00.000+09:00",
      workPlace: {
        id: 196,
        name: "ファボーレ",
      },
      work: {
        id: 8,
        workCategory: "shop_helper",
        carrier: "softbank",
        prefectures: ["toyama"],
      },
      workTypeId: 1,
      workTypeName: "報告会",
      assignedMembers: [],
    },
    {
      id: 19,
      targetDate: "2023-03-28",
      startAt: "2023-03-28T08:00:00.000+09:00",
      finishAt: "2023-03-28T18:00:00.000+09:00",
      workPlace: {
        id: 196,
        name: "ファボーレ",
      },
      work: {
        id: 8,
        workCategory: "shop_helper",
        carrier: "softbank",
        prefectures: ["toyama"],
      },
      workTypeId: 1,
      workTypeName: "報告会",
      assignedMembers: [],
    },
    {
      id: 20,
      targetDate: "2023-03-29",
      startAt: "2023-03-29T08:00:00.000+09:00",
      finishAt: "2023-03-29T18:00:00.000+09:00",
      workPlace: {
        id: 196,
        name: "ファボーレ",
      },
      work: {
        id: 8,
        workCategory: "shop_helper",
        carrier: "softbank",
        prefectures: ["toyama"],
      },
      workTypeId: 1,
      workTypeName: "報告会",
      assignedMembers: [],
    },
    {
      id: 21,
      targetDate: "2023-03-30",
      startAt: "2023-03-30T08:00:00.000+09:00",
      finishAt: "2023-03-30T18:00:00.000+09:00",
      workPlace: {
        id: 196,
        name: "ファボーレ",
      },
      work: {
        id: 8,
        workCategory: "shop_helper",
        carrier: "softbank",
        prefectures: ["toyama"],
      },
      workTypeId: 1,
      workTypeName: "報告会",
      assignedMembers: [],
    },
    {
      id: 22,
      targetDate: "2023-03-31",
      startAt: "2023-03-31T08:00:00.000+09:00",
      finishAt: "2023-03-31T18:00:00.000+09:00",
      workPlace: {
        id: 196,
        name: "ファボーレ",
      },
      work: {
        id: 8,
        workCategory: "shop_helper",
        carrier: "softbank",
        prefectures: ["toyama"],
      },
      workTypeId: 1,
      workTypeName: "報告会",
      assignedMembers: [],
    },
    {
      id: 37,
      targetDate: "2023-04-17",
      startAt: "2023-04-17T07:00:00.000+09:00",
      finishAt: "2023-04-17T17:00:00.000+09:00",
      workPlace: {
        id: 115,
        name: "婦中",
      },
      work: {
        id: 9,
        workCategory: "shop_helper",
        carrier: "au",
        prefectures: ["toyama"],
      },
      workTypeId: 3,
      workTypeName: "獲得支援",
      assignedMembers: [],
    },
    {
      id: 38,
      targetDate: "2023-04-18",
      startAt: "2023-04-18T07:00:00.000+09:00",
      finishAt: "2023-04-18T17:00:00.000+09:00",
      workPlace: {
        id: 115,
        name: "婦中",
      },
      work: {
        id: 9,
        workCategory: "shop_helper",
        carrier: "au",
        prefectures: ["toyama"],
      },
      workTypeId: 3,
      workTypeName: "獲得支援",
      assignedMembers: [],
    },
    {
      id: 39,
      targetDate: "2023-04-19",
      startAt: "2023-04-19T07:00:00.000+09:00",
      finishAt: "2023-04-19T17:00:00.000+09:00",
      workPlace: {
        id: 115,
        name: "婦中",
      },
      work: {
        id: 9,
        workCategory: "shop_helper",
        carrier: "au",
        prefectures: ["toyama"],
      },
      workTypeId: 3,
      workTypeName: "獲得支援",
      assignedMembers: [],
    },
    {
      id: 40,
      targetDate: "2023-04-20",
      startAt: "2023-04-20T07:00:00.000+09:00",
      finishAt: "2023-04-20T17:00:00.000+09:00",
      workPlace: {
        id: 115,
        name: "婦中",
      },
      work: {
        id: 9,
        workCategory: "shop_helper",
        carrier: "au",
        prefectures: ["toyama"],
      },
      workTypeId: 3,
      workTypeName: "獲得支援",
      assignedMembers: [],
    },
    {
      id: 41,
      targetDate: "2023-04-21",
      startAt: "2023-04-21T07:00:00.000+09:00",
      finishAt: "2023-04-21T17:00:00.000+09:00",
      workPlace: {
        id: 115,
        name: "婦中",
      },
      work: {
        id: 9,
        workCategory: "shop_helper",
        carrier: "au",
        prefectures: ["toyama"],
      },
      workTypeId: 3,
      workTypeName: "獲得支援",
      assignedMembers: [],
    },
  ],
  organizationMemberSchedulePlans: [
    {
      id: 5,
      organizationMemberId: 1,
      organizationMemberName: "管理者",
      targetDate: "2023-04-03",
      workSchedules: [
        {
          id: 23,
          targetDate: "2023-04-03",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
          organizationMemberName: "管理者",
          done: undefined,
          workId: 7,
          workCategory: "shop_helper",
          workCarrier: "au",
          workPrefectures: ["toyama"],
          workPlaceName: "婦中",
          workPlacePrefecture: "toyama",
          workPlaceOrganizationName: "(株)ダイエー通信",
          workPlaceOrganizationId: 35,
          workTypeName: "報告会",
        },
      ],
    },
    {
      id: 6,
      organizationMemberId: 1,
      organizationMemberName: "管理者",
      targetDate: "2023-04-04",
      workSchedules: [
        {
          id: 24,
          targetDate: "2023-04-04",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
          organizationMemberName: "管理者",
          done: undefined,
          workId: 7,
          workCategory: "shop_helper",
          workCarrier: "au",
          workPrefectures: ["toyama"],
          workPlaceName: "婦中",
          workPlacePrefecture: "toyama",
          workPlaceOrganizationName: "(株)ダイエー通信",
          workPlaceOrganizationId: 35,
          workTypeName: "報告会",
        },
      ],
    },
    {
      id: 7,
      organizationMemberId: 1,
      organizationMemberName: "管理者",
      targetDate: "2023-04-05",
      workSchedules: [
        {
          id: 25,
          targetDate: "2023-04-05",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
          organizationMemberName: "管理者",
          done: undefined,
          workId: 7,
          workCategory: "shop_helper",
          workCarrier: "au",
          workPrefectures: ["toyama"],
          workPlaceName: "婦中",
          workPlacePrefecture: "toyama",
          workPlaceOrganizationName: "(株)ダイエー通信",
          workPlaceOrganizationId: 35,
          workTypeName: "報告会",
        },
      ],
    },
    {
      id: 10,
      organizationMemberId: 1,
      organizationMemberName: "管理者",
      targetDate: "2023-04-07",
      workSchedules: [
        {
          id: 27,
          targetDate: "2023-04-07",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
          organizationMemberName: "管理者",
          done: undefined,
          workId: 7,
          workCategory: "shop_helper",
          workCarrier: "au",
          workPrefectures: ["toyama"],
          workPlaceName: "婦中",
          workPlacePrefecture: "toyama",
          workPlaceOrganizationName: "(株)ダイエー通信",
          workPlaceOrganizationId: 35,
          workTypeName: "報告会",
        },
      ],
    },
    {
      id: 3,
      organizationMemberId: 5,
      organizationMemberName: "個人事業主",
      targetDate: "2023-03-29",
      workSchedules: [
        {
          id: 11,
          targetDate: "2023-03-29",
          startAt: "2000-01-01T09:00:00.000+09:00",
          finishAt: "2000-01-01T18:00:00.000+09:00",
          organizationMemberName: "個人事業主",
          done: undefined,
          workId: 6,
          workCategory: "rounder",
          workCarrier: "softbank",
          workPrefectures: ["ishikawa"],
          workPlaceName: "イオンモール白山",
          workPlacePrefecture: "ishikawa",
          workPlaceOrganizationName: "ダイワ通信(株)",
          workPlaceOrganizationId: 48,
          workTypeName: "獲得支援",
        },
      ],
    },
    {
      id: 8,
      organizationMemberId: 5,
      organizationMemberName: "個人事業主",
      targetDate: "2023-04-05",
      workSchedules: [
        {
          id: 25,
          targetDate: "2023-04-05",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
          organizationMemberName: "個人事業主",
          done: true,
          workId: 7,
          workCategory: "shop_helper",
          workCarrier: "au",
          workPrefectures: ["toyama"],
          workPlaceName: "婦中",
          workPlacePrefecture: "toyama",
          workPlaceOrganizationName: "(株)ダイエー通信",
          workPlaceOrganizationId: 35,
          workTypeName: "報告会",
        },
      ],
    },
    {
      id: 9,
      organizationMemberId: 5,
      organizationMemberName: "個人事業主",
      targetDate: "2023-04-06",
      workSchedules: [
        {
          id: 26,
          targetDate: "2023-04-06",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
          organizationMemberName: "個人事業主",
          done: true,
          workId: 7,
          workCategory: "shop_helper",
          workCarrier: "au",
          workPrefectures: ["toyama"],
          workPlaceName: "婦中",
          workPlacePrefecture: "toyama",
          workPlaceOrganizationName: "(株)ダイエー通信",
          workPlaceOrganizationId: 35,
          workTypeName: "報告会",
        },
      ],
    },
    {
      id: 11,
      organizationMemberId: 5,
      organizationMemberName: "個人事業主",
      targetDate: "2023-04-14",
      workSchedules: [
        {
          id: 28,
          targetDate: "2023-04-14",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
          organizationMemberName: "個人事業主",
          done: true,
          workId: 7,
          workCategory: "shop_helper",
          workCarrier: "au",
          workPrefectures: ["toyama"],
          workPlaceName: "婦中",
          workPlacePrefecture: "toyama",
          workPlaceOrganizationName: "(株)ダイエー通信",
          workPlaceOrganizationId: 35,
          workTypeName: "報告会",
        },
      ],
    },
    {
      id: 12,
      organizationMemberId: 5,
      organizationMemberName: "個人事業主",
      targetDate: "2023-04-15",
      workSchedules: [
        {
          id: 29,
          targetDate: "2023-04-15",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
          organizationMemberName: "個人事業主",
          done: true,
          workId: 7,
          workCategory: "shop_helper",
          workCarrier: "au",
          workPrefectures: ["toyama"],
          workPlaceName: "婦中",
          workPlacePrefecture: "toyama",
          workPlaceOrganizationName: "(株)ダイエー通信",
          workPlaceOrganizationId: 35,
          workTypeName: "報告会",
        },
      ],
    },
    {
      id: 13,
      organizationMemberId: 5,
      organizationMemberName: "個人事業主",
      targetDate: "2023-04-17",
      workSchedules: [
        {
          id: 31,
          targetDate: "2023-04-17",
          startAt: "2000-01-01T07:00:00.000+09:00",
          finishAt: "2000-01-01T17:00:00.000+09:00",
          organizationMemberName: "個人事業主",
          done: true,
          workId: 7,
          workCategory: "shop_helper",
          workCarrier: "au",
          workPrefectures: ["toyama"],
          workPlaceName: "婦中",
          workPlacePrefecture: "toyama",
          workPlaceOrganizationName: "(株)ダイエー通信",
          workPlaceOrganizationId: 35,
          workTypeName: "報告会",
        },
      ],
    },
    {
      id: 4,
      organizationMemberId: 6,
      organizationMemberName: "従業員",
      targetDate: "2023-03-31",
      workSchedules: [],
    },
  ],
};

export const taskItems: TaskItemClient[] = [
  {
    id: 1,
    projectId: 1,
    name: "大変なタスクグループ",
    startOn: "2023-04-18T00:00:00.000+09:00",
    finishOn: "2023-04-21T00:00:00.000+09:00",
    needTime: 20,
    remarks: "メモや備考欄",
    sortOrder: 0,
    orderPath: [0],
    childrenIds: [2],
    descendantIds: [],
  },
  {
    id: 2,
    projectId: 1,
    name: "タスクA",
    doneAt: "2023-04-21T20:03:42.895+09:00",
    startOn: "2023-04-18T00:00:00.000+09:00",
    finishOn: "2023-04-21T00:00:00.000+09:00",
    needTime: 30,
    remarks: "メモや備考欄",
    sortOrder: 1,
    orderPath: [0, 1],
    childrenIds: [],
    descendantIds: [],
  },
  {
    id: 3,
    projectId: 1,
    name: "大変なタスクグループその2",
    startOn: "2023-04-18T00:00:00.000+09:00",
    finishOn: "2023-04-25T00:00:00.000+09:00",
    needTime: 60,
    remarks: "メモや備考欄",
    sortOrder: 2,
    orderPath: [1],
    childrenIds: [4, 1],
    descendantIds: [4, 5, 6],
  },
  {
    id: 4,
    projectId: 1,
    name: "タスクB",
    startOn: "2023-04-18T00:00:00.000+09:00",
    finishOn: "2023-04-25T00:00:00.000+09:00",
    needTime: 60,
    remarks: "",
    sortOrder: 3,
    orderPath: [1, 0],
    childrenIds: [],
    descendantIds: [],
  },
  {
    id: 5,
    projectId: 1,
    name: "タスクC",
    startOn: undefined,
    finishOn: undefined,
    needTime: undefined,
    remarks: "",
    sortOrder: 4,
    orderPath: [1, 1],
    childrenIds: [6],
    descendantIds: [6],
  },
  {
    id: 6,
    projectId: 1,
    name: "タスクD",
    startOn: undefined,
    finishOn: undefined,
    needTime: undefined,
    remarks: "",
    sortOrder: 0,
    orderPath: [1, 1, 0],
    childrenIds: [],
    descendantIds: [],
  },
];

export const nestedTaskItems: NestedTaskItemClient[] = [
  {
    id: 1,
    projectId: 1,
    name: "大変なタスクA",
    startOn: "2023-04-18T00:00:00.000+09:00",
    finishOn: "2023-04-21T00:00:00.000+09:00",
    needTime: 20,
    remarks: "メモや備考欄",
    sortOrder: 0,
    orderPath: [0],
    children: [
      {
        id: 2,
        projectId: 1,
        name: "タスクa",
        doneAt: "2023-04-21T20:03:42.895+09:00",
        startOn: "2023-04-18T00:00:00.000+09:00",
        finishOn: "2023-04-21T00:00:00.000+09:00",
        needTime: 30,
        remarks: "メモや備考欄",
        sortOrder: 0,
        orderPath: [0, 0],
        children: [],
      },
    ],
  },
  {
    id: 3,
    projectId: 1,

    name: "大変なタスクB",
    startOn: "2023-04-18T00:00:00.000+09:00",
    finishOn: "2023-04-25T00:00:00.000+09:00",
    needTime: 60,
    remarks: "メモや備考欄",
    sortOrder: 1,
    orderPath: [1],
    children: [
      {
        id: 4,
        projectId: 1,
        name: "タスクb",
        startOn: "2023-04-18T00:00:00.000+09:00",
        finishOn: "2023-04-25T00:00:00.000+09:00",
        needTime: 60,
        remarks: "",
        sortOrder: 0,
        orderPath: [1, 0],
        children: [
          {
            id: 5,
            projectId: 1,
            name: "タスクc",
            startOn: undefined,
            finishOn: undefined,
            needTime: undefined,
            remarks: "",
            sortOrder: 0,
            orderPath: [1, 0, 0],
            children: [],
          },
        ],
      },
      {
        id: 6,
        projectId: 1,
        name: "タスクd",
        startOn: undefined,
        finishOn: undefined,
        needTime: undefined,
        remarks: "",
        sortOrder: 1,
        orderPath: [1, 1],
        children: [],
      },
    ],
  },
];

export const taskProjects: TaskProjectClient[] = [
  {
    name: "Project A",
    id: 1,
    organizationMemberId: 1,
    lastPlannedDate: "2023-05-01T09:00:00.000+09:00",
    hierarchalTaskItems: [],
    flatTaskItems: [],
    createdAt: "2023-04-28T00:00:00.000+09:00",
  },
  {
    name: "Project B",
    id: 2,
    organizationMemberId: 1,
    lastPlannedDate: "2023-05-01T09:00:00.000+09:00",
    hierarchalTaskItems: [],
    flatTaskItems: [],
    createdAt: "2023-04-01T00:00:00.000+09:00",
  },
];

export const workReports: WorkReport[] = [
  {
    id: 1,
    workRecordEntryId: 11,
    reportItems: [
      { key: "premiere", count: 5, overall: true },
      { key: "premiere", count: 6, overall: false },
    ],
    targetDate: "2023-05-05T18:07:12.251+09:00",
    workTypeCategories: ["telecommunications"],
    workPlaceName: "婦中",
  },
  {
    id: 4,
    workRecordEntryId: 16,
    reportItems: [
      { key: "customerCount", count: 2, overall: false },
      { key: "sky_perfect", count: 2, overall: false },
      { key: "d_type", count: 3, overall: false },
      { key: "premiere", count: 6, overall: true },
      { key: "ocn", count: 5, overall: true },
      { key: "transport", count: 4, overall: false },
    ],
    targetDate: "2023-05-08T20:19:30.131+09:00",
    workTypeCategories: ["telecommunications"],
    workPlaceName: "婦中",
  },
];
