import zod from "zod";
import { z } from "/@/modules/zodUtils";

export const organizationCategories = [
  "telecommunications",
  "general",
  "create_work",
  "shop_owner",
] as const;
export const OrganizationCategoryScheme = z.enum(organizationCategories);
export type OrganizationCategory = zod.infer<typeof OrganizationCategoryScheme>;
