import { z } from "/@/modules/zodUtils";
import { ApplicationTypeScheme } from "/@/types/plan.d";

const SubmitApplicationScheme = z.object({
  organizationMemberId: z.number(),
  organizationId: z.number(),
  applicationType: ApplicationTypeScheme,
  targetDates: z.string().datetime({ offset: true }).array(),
});

const SubmitApplicationFormScheme = SubmitApplicationScheme.partial();

// owner

const ownerFilter = {
  organizationMemberId: true,
  applicationType: true,
  targetDates: true,
};

export const SubmitApplicationOwnerScheme =
  SubmitApplicationScheme.pick(ownerFilter);

export const SubmitApplicationOwnerFormScheme =
  SubmitApplicationFormScheme.pick(ownerFilter);

export type SubmitApplicationOwnerForm = zod.infer<
  typeof SubmitApplicationOwnerFormScheme
>;

// ownee

const owneeFilter = {
  organizationId: true,
  applicationType: true,
  targetDates: true,
};

export const SubmitApplicationOwneeScheme =
  SubmitApplicationScheme.pick(owneeFilter);

export const SubmitApplicationOwneeFormScheme =
  SubmitApplicationFormScheme.partial();

export type SubmitApplicationOwneeForm = zod.infer<
  typeof SubmitApplicationOwneeFormScheme
>;
