<template>
  <div>
    <div class="d-flex justify-content-between align-items-center w-100">
      <div class="d-flex flex-wrap">
        <div style="width: 80px" class="me-2">
          <Chip>{{ translater(organizationEvent.eventType) }}</Chip>
        </div>

        <span>{{ organizationEvent.name }}</span>
      </div>

      <MenuButton v-if="!isHome" :menu-items="menuItems" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { translater } from "/@/modules/string";
import { OrganizationEventClient } from "/@/types";
import { MenuButton } from "/@/vue/components/Molecules";
import Chip from "primevue/chip";

const props = defineProps<{
  organizationEvent: OrganizationEventClient;
  isHome?: boolean;
}>();

const emit = defineEmits<{
  (e: "edit", id: number): void;
  (e: "destroy", id: number): void;
}>();

const menuItems = computed(() => {
  return [
    {
      label: "編集",
      icon: "pi pi-pencil",
      command: () => {
        emit("edit", props.organizationEvent.id);
      },
    },
    {
      label: "削除",
      icon: "pi pi-trash",
      command: () => {
        emit("destroy", props.organizationEvent.id);
      },
    },
  ];
});
</script>

<style scoped></style>
