<template>
  <div>
    <form @submit.prevent="handleSubmit">
      <ExitForm
        v-model:lat="form.finishLat"
        v-model:lng="form.finishLng"
        v-model:photo="form.finishPhoto"
        v-model:report-items="form.workRecordEntryReports"
        v-model:work-tasks="form.workTasks"
        :work-record-entry="currentEntry"
        :works="[]"
        :work-places="[]"
        :need-enter-leave-report="needEnterLeaveReport"
        :need-finish-report="needFinishReport"
        :errors="errors"
      />

      <div class="d-flex w-100">
        <div class="p-1 me-2">
          <BasicButton
            variant="danger"
            :disabled="loading"
            icon="pi pi-times-circle"
            label="キャンセル"
            @click="$emit('cancel')"
          />
        </div>
        <div class="p-1">
          <BasicButton
            button-type="submit"
            :disabled="loading"
            icon="pi pi-upload"
            label="送信"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { errorHandle } from "/@/modules/error";
import { useZodScheme } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { ExitForm } from "/@/vue/components/Organisms";
import {
  WorkRecordEntryClient,
  WorkRecordEntryExitForm,
  WorkRecordEntryExitNeedEnterLeaveReportScheme,
  WorkRecordEntryExitScheme,
} from "/@/types";

const props = defineProps<{
  currentEntry: WorkRecordEntryClient;
  needEnterLeaveReport?: boolean;
  needFinishReport?: boolean;
}>();

const emit = defineEmits<{
  (e: "submit", entry: WorkRecordEntryExitForm): void;
  (e: "cancel"): void;
}>();

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation, targetScheme } =
  useFormAndErrors<WorkRecordEntryExitForm>(WorkRecordEntryExitScheme);

onMounted(() => {
  form.id = props.currentEntry.id;

  form.finishLat = undefined;
  form.finishLng = undefined;
  form.finishPhoto = undefined;
  form.reportItems = [];
  form.workTasks = [];
});

const loading = ref(false);

function handleSubmit() {
  try {
    loading.value = true;
    startValidation.value = true;

    const currentScheme = props.needEnterLeaveReport
      ? WorkRecordEntryExitNeedEnterLeaveReportScheme
      : WorkRecordEntryExitScheme;

    targetScheme.value = currentScheme;

    const workRecordEntry = currentScheme.parse(form);

    // @ts-ignore
    emit("submit", workRecordEntry);
  } catch (e) {
    errorHandle(e);
    loading.value = false;
  }
}
</script>

<style scoped></style>
