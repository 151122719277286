import zod from "zod";
import { z } from "/@/modules/zodUtils";

export const organizationMemberRoles = [
  "owner",
  "subOwner",
  "worker",
  "developer",
  "designer",
] as const;

export const OrganizationMemberRolesScheme = z.enum(organizationMemberRoles);
export type OrganizationMemberRole = zod.infer<
  typeof OrganizationMemberRolesScheme
>;
