<template>
  <!-- 
    <th>遅刻早退</th>
    <th>休日勤務</th>
    <th>残業</th>
    <th>深夜残業</th>
    <th>備考</th> 
  -->
  <tr scope="col">
    <td v-if="ownerMode" class="sticky">
      <div
        v-if="firstWorkSchedule && noRecord && !isAfterToday"
        class="d-flex justify-content-center"
      >
        <BasicButton
          rounded
          small
          icon="pi pi-building"
          @click="openAsWorkedModal"
        />
      </div>
    </td>
    <td
      class="sticky"
      :class="{
        [getWeekdayColor(d)]: true,
      }"
    >
      {{ d.luxonDate.day }}
    </td>
    <td
      class="sticky"
      :class="{
        [getWeekdayColor(d)]: true,
      }"
    >
      {{ basicFormatter(d.dateKey, "onlyWday") }}
    </td>
    <td>
      <!-- 予定 -->
      {{ scheduleToStatus }}
    </td>
    <td>
      <!-- 勤怠 -->
      {{ isAfterToday ? "" : recordToStatus }}
    </td>
    <td>
      <!-- 開始 -->
      <span v-if="!noRecord">
        {{ isAfterToday ? "" : basicFormatter(startAt, "onlyTime") }}
      </span>
    </td>
    <td>
      <!-- 終了 -->
      <span v-if="!noRecord && !nowWorking">
        {{ isAfterToday ? "" : basicFormatter(finishAt, "onlyTime") }}
      </span>
    </td>
    <td>
      <!-- <th>休憩</th> -->
      <span v-if="!noRecord && !nowWorking">
        {{ isAfterToday ? "" : restTimeHours || "" }}
      </span>
    </td>
    <td>
      <!--<th>実勤務</th>-->
      <span v-if="!noRecord && !nowWorking">
        {{ isAfterToday ? "" : totalWorkingHours || "" }}
      </span>
    </td>
    <td>{{ isAfterToday ? "" : "-" }}</td>
    <td>{{ isAfterToday ? "" : 0 }}</td>
    <td>{{ isAfterToday ? "" : 0 }}</td>
    <td>{{ isAfterToday ? "" : 0 }}</td>
    <td></td>
  </tr>
</template>

<script setup lang="ts">
import { computed } from "vue";
import {
  getRecordStatus,
  recordStatusTranslater,
} from "/@/modules/workRecords";
import { roundAtPrecision } from "/@/modules/math";
import {
  getWeekdayColor,
  basicFormatter,
  isSameDate,
  luxonNow,
  countHoursBetween,
} from "/@/modules/luxon";
import {
  DateForCalendar,
  OrganizationMemberSchedulePlan,
  WorkRecordClient,
} from "/@/types";
import { BusinessIcon, BasicButton } from "/@/vue/components/Atom";

const props = defineProps<{
  d: DateForCalendar;
  records: WorkRecordClient[];
  schedules: OrganizationMemberSchedulePlan[];
  showRecordTime?: boolean;
  ownerMode?: boolean;
}>();

const emit = defineEmits<{
  (e: "openAsWorkedModal", dateKey: string): void;
}>();

const today = luxonNow();

// schedule

const targetDateSchedule = computed(() => {
  return props.schedules.find((schedule) =>
    isSameDate(schedule.targetDate, props.d.dateKey)
  );
});

const scheduleToStatus = computed(() => {
  if (targetDateSchedule.value) {
    return "勤務";
  } else {
    return "公休";
  }
});

const targetDateWorkSchedules = computed(() => {
  if (!targetDateSchedule.value) {
    return [];
  }

  return targetDateSchedule.value.workSchedules;
});

const firstWorkSchedule = computed(() => {
  return targetDateWorkSchedules.value.at(0);
});

const lastWorkSchedule = computed(() => {
  return targetDateWorkSchedules.value.at(-1);
});

const targetDateRecords = computed(() => {
  return props.records.filter((record) =>
    isSameDate(record.startAt, props.d.dateKey)
  );
});

const nowWorking = computed(() => {
  return targetDateRecords.value.some((record) => !record.finishAt);
});

const noRecord = computed(() => {
  return !targetDateRecords.value.length;
});

const isAfterToday = computed(() => {
  return props.d.luxonDate > today.endOf("day");
});

const recordToStatus = computed(() => {
  const status = getRecordStatus(
    targetDateRecords.value,
    targetDateSchedule.value
  );

  return recordStatusTranslater(status);
});

const firstRecord = computed(() => {
  return targetDateRecords.value.at(0);
});

const lastRecord = computed(() => {
  return targetDateRecords.value.at(-1);
});

const startAt = computed(() => {
  if (props.showRecordTime) {
    if (!firstRecord.value) {
      return;
    }

    return firstRecord.value.startAt;
  } else {
    if (!firstWorkSchedule.value) {
      return;
    }

    return firstWorkSchedule.value.startAt;
  }
});

const finishAt = computed(() => {
  if (props.showRecordTime) {
    if (!lastRecord.value) {
      return;
    }

    return lastRecord.value.finishAt;
  } else {
    if (!lastWorkSchedule.value) {
      return;
    }

    return lastWorkSchedule.value.finishAt;
  }
});

const workingTimeHours = computed(() => {
  if (!startAt.value || !finishAt.value) {
    return 0;
  }

  const hours = countHoursBetween(startAt.value, finishAt.value);

  return hours;
});

const restTimeHours = computed(() => {
  if (props.showRecordTime) {
    return props.records
      .map((record) => record.restTimeEntries)
      .flat()
      .map((restTime) => countHoursBetween(restTime.startAt, restTime.finishAt))
      .reduce((acc, current) => acc + current, 0);
  } else {
    const restTimeSchedules = props.schedules
      .map((schedule) => schedule.restTimeSchedules)
      .flat();

    if (workingTimeHours.value <= 9 && !restTimeSchedules.length) {
      return Math.max(workingTimeHours.value - 8, 0);
    } else {
      return restTimeSchedules
        .map((restTime) =>
          countHoursBetween(restTime.startAt, restTime.finishAt)
        )
        .reduce((acc, current) => acc + current, 0);
    }
  }
});

const totalWorkingHours = computed(() => {
  return roundAtPrecision(workingTimeHours.value - restTimeHours.value, 1);
});

// edit modal

function openAsWorkedModal() {
  emit("openAsWorkedModal", props.d.dateKey);
}
</script>

<style scoped>
td.sticky:nth-child(2) {
  left: 40px;
}
</style>
