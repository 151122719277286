import { axios } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import { LaborInfoForm, Organization } from "/@/types";

export function useLaborInfo() {
  async function updateLaborInfos(
    organization: Organization,
    forms: LaborInfoForm[]
  ) {
    try {
      await axios.post(
        `/api/v1/organizations/${organization.id}/organization_member_labor_infos/bulk_upsert`,
        {
          labor_infos: { forms },
        }
      );

      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return { updateLaborInfos };
}
