import { Tooltip } from "bootstrap";

export function initTooltip(id: string) {
  if (!document) {
    throw new Error("need in document context");
  }

  const tooltipTriggerList = [].slice.call(
    document.querySelectorAll(`#${id}[data-bs-toggle="tooltip"]`)
  );

  const tooltips = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl);
  });

  if ("ontouchstart" in document.documentElement) {
    document.addEventListener("touchstart", () => hideTooltip(tooltips));
  } else {
    document.addEventListener("click", () => hideTooltip(tooltips));
  }

  return tooltips;
}

export function disposeTooltip(tooltips: any[]) {
  tooltips.forEach((tooltip) => tooltip.dispose());
}

export function hideTooltip(tooltips: any[]) {
  tooltips.forEach((tooltip) => tooltip.hide());
}
