import Show from "./Show.vue";
import Index from "./Index.vue";

export default [
  {
    path: "",
    name: "LaborInfosIndex",
    component: Index,
  },
  {
    path: "show",
    name: "LaborInfosShow",
    component: Show,
  },
];
