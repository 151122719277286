<template>
  <div>
    <PageHeader page-title="業務開始" />
    <StartForm
      :work-schedules="myTodaySchedules"
      :need-enter-leave-report="actionStatus?.needEnterLeaveReport"
      @submit="handleSubmit"
      @cancel="backto"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from "vue";
import {
  usePlans,
  useWorkRecords,
  useRouterUtil,
  useOrganizationMember,
  useOrganizationMemberSchedule,
} from "/@/vue/composables";
import { luxonNow } from "/@/modules/luxon";
import { PageHeader } from "/@/vue/components/Layouts";
import { StartForm } from "/@/vue/components/Organisms";
import type { DateMap, WorkRecordStartForm } from "/@/types";

const { query, goto, backto } = useRouterUtil();
const selectedOrganization = ref();

const today = luxonNow();

const selectedDate = ref<DateMap>({
  year: today.year,
  month: today.month,
  day: today.toISO() || undefined,
});

onMounted(() => {
  selectedOrganization.value = {
    id: query.value.organizationId,
    organizationMemberId: query.value.organizationMemberId,
  };
});

const isOwner = ref<boolean>(false);

const { getPlanMaps } = usePlans();
const { data: planMap } = getPlanMaps(
  selectedOrganization,
  isOwner,
  selectedDate,
  {
    page: "plans_index",
  }
);

const { getActionStatus } = useOrganizationMember();
const { data: actionStatus } = getActionStatus(selectedOrganization);

// schedules

const { getMyTodaySchedules } = useOrganizationMemberSchedule();

const schedules = computed(() => {
  if (!planMap.value) {
    return [];
  }

  return planMap.value.organizationMemberSchedulePlans;
});

const myTodaySchedules = computed(() => {
  return getMyTodaySchedules(
    schedules.value,
    selectedOrganization.value?.organizationMemberId
  )
    .map((s) => s.workSchedules)
    .flat();
});

const loading = ref<boolean>();
const errors = ref<string[]>([]);

const { startWork } = useWorkRecords();

async function handleSubmit(form: WorkRecordStartForm) {
  loading.value = true;

  if (await startWork(form, selectedOrganization.value.organizationMemberId)) {
    goto({ name: "Home", query: query.value });
  } else {
    alert("業務の開始に失敗しました");
  }
  loading.value = false;
}
</script>

<style scoped></style>
