import useSWRV from "swrv";
import { axios, fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import { EventPlaceUpdate, EventPlace } from "/@/types";

export function useEventPlaces() {
  function getEventPlaces() {
    return useSWRV("/api/v1/event_places", fetcher);
  }

  async function createEventPlace(prms: EventPlace) {
    try {
      await axios.post(`/api/v1/event_places/`, {
        eventPlaces: prms,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function updateEventPlace(prms: EventPlaceUpdate) {
    try {
      await axios.patch(`/api/v1/event_places/${prms.id}`, {
        eventPlaces: prms,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function destroyEventPlace(id: number) {
    try {
      await axios.delete(`/api/v1/event_places/${id}`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    getEventPlaces,
    createEventPlace,
    updateEventPlace,
    destroyEventPlace,
  };
}
