<template>
  <div v-if="disabled">
    <MessageList status="success" :messages="['位置情報は取得していません']" />
  </div>
  <div v-else>
    <MessageList
      :messages="locationStatus.messages"
      :status="locationStatus.status"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { MessageList } from "/@/vue/components/Atom";
import type { MessageStatus } from "/@/types/message";

const props = defineProps<{
  lat?: number;
  lng?: number;
  disabled?: boolean;
}>();

const emit = defineEmits(["update:lat", "update:lng"]);

const geoWatcher = ref();
const failGetLocation = ref(false);

const locationStatus = reactive<{
  status: MessageStatus;
  messages: string[];
}>({
  status: "sync-location",
  messages: ["読み込み中..."],
});

function success(pos: GeolocationPosition) {
  failGetLocation.value = false;

  locationStatus.status = "sync-location";
  locationStatus.messages = [""];

  if (!props.disabled) {
    emit("update:lat", pos.coords.latitude);
    emit("update:lng", pos.coords.longitude);
  } else {
    emit("update:lat", null);
    emit("update:lng", null);
  }
}

function error(err: { code: number; message: string }) {
  console.warn("ERROR(" + err.code + "): " + err.message);
  failGetLocation.value = true;
  locationStatus.status = "error";

  switch (err.code) {
    case 1:
      locationStatus.messages = [
        "位置情報の取得が許可されていません。",
        "ブラウザでの位置情報取得をオンにしてリロードして下さい。",
      ];
      break;

    case 3:
      locationStatus.messages = [
        "位置情報の取得に時間が掛かっており、",
        "電波が悪い・ページの表示に時間がかかっているなどの可能性があります。",
        "解決しない場合はページのリロードを行って下さい。",
      ];
      break;

    default:
      locationStatus.messages = [
        "予期せぬエラーが発生しました。",
        "ページをリロードして下さい。",
      ];
      break;
  }
}

function watchLocation() {
  const options = {
    enableHighAccuracy: false,
    timeout: 5000,
    maximumAge: 5000,
  };

  geoWatcher.value = window.navigator.geolocation.watchPosition(
    success,
    error,
    options
  );
}

watch(
  () => props.disabled,
  (disabled) => {
    if (disabled) {
      emit("update:lat", null);
      emit("update:lng", null);
    }
  }
);

// mounted

onMounted(() => {
  watchLocation();
});

onUnmounted(() => {
  navigator.geolocation.clearWatch(geoWatcher.value);
});
</script>
