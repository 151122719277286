<template>
  <div>
    <div class="relative-wrapper">
      <ContentCard :title="title">
        <div v-if="!editMode && isOwner(organization)" class="edit-btn">
          <EditButton
            label="労務情報へ"
            class="me-2"
            @click="$emit('gotoLaborInfo', organization.id)"
          />
          <EditButton @click="editMode = true" />
        </div>

        <OrganizationForm
          class="mb-3"
          :organization="organization"
          :edit-mode="editMode"
          :loading="loading"
          @cancel="editMode = false"
          @submit="updateOrganization"
        />

        <div v-if="!editMode && organization.organizationMembers">
          <CollapseWrapper
            :contents-id="`members-table-${organization.id}`"
            card-header
            class="card mb-3"
          >
            <template #header>
              <ContentLabel label="メンバー一覧" without-padding />
            </template>
            <template #contents>
              <template v-if="false">
                <ObjectSelector
                  v-if="isOwner(organization)"
                  v-model:value="selectedGroup"
                  :items="organization.organizationOwnerGroups || []"
                  value-key="id"
                  show-key="name"
                  form-id="organizationOwnerGroups"
                  label="表示するグループ"
                  without-select-text="すべて"
                  is-valid
                  can-without-select
                  no-auto-select
                  class="mb-3"
                />
              </template>

              <OrganizationMemberTable
                :organization-members="filteredMembers"
                :selected-group="selectedGroup"
                @select-member="emit('selectMember', $event)"
              />
            </template>
          </CollapseWrapper>

          <CollapseWrapper
            v-if="contractors.length"
            :contents-id="`contractors-table-${organization.id}`"
            card-header
            class="card mb-3"
          >
            <template #header>
              <ContentLabel label="提携組織一覧(上位組織)" without-padding />
            </template>
            <template #contents>
              <div v-for="con in contractors" :key="`contractors-${con.id}`">
                {{ con.name }}
                <span v-if="con.ownerNames.length">
                  ({{ con.ownerNames.join("・") }})
                </span>
              </div>
            </template>
          </CollapseWrapper>

          <CollapseWrapper
            v-if="subContractors.length"
            :contents-id="`sub-contractors-table-${organization.id}`"
            card-header
            class="card"
          >
            <template #header>
              <ContentLabel label="提携組織一覧(下請け)" without-padding />
            </template>
            <template #contents>
              <template
                v-for="sub in subContractors"
                :key="`sub-contractors-${sub.id}`"
              >
                <CollapseWrapper
                  v-if="sub.organizationMembers.length"
                  :contents-id="`sub-contractors-${sub.id}`"
                  card-header
                  class="card mb-1"
                >
                  <template #header>
                    {{ sub.name
                    }}<span v-if="sub.ownerNames.length">
                      ({{ sub.ownerNames.join("・") }})
                    </span>
                  </template>
                  <template #contents>
                    <OrganizationMemberTable
                      :organization-members="sub.organizationMembers"
                      @select-member="emit('selectMember', $event)"
                    />
                  </template>
                </CollapseWrapper>
              </template>
            </template>
          </CollapseWrapper>
        </div>
      </ContentCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { isOwner } from "/@/modules/organization";
import {
  ContentCard,
  ContentLabel,
  EditButton,
  ObjectSelector,
} from "/@/vue/components/Atom";
import { CollapseWrapper } from "/@/vue/components/Molecules";
import { OrganizationMemberTable } from "/@/vue/components/Organisms";
import { OrganizationForm } from ".";
import type { Organization, OrganizationOwnerGroup } from "/@/types";

const props = defineProps<{
  loading?: boolean;
  organization: Organization;
}>();

const emit = defineEmits<{
  (e: "updateOrganization", organiaziton: Organization): void;
  (e: "selectMember", id: number): void;
  (e: "gotoLaborInfo", id: number): void;
}>();

const title = computed<string>(() => {
  return props.organization.asEntrust ? "会社情報(委託先)" : "会社情報";
});

const editMode = ref<boolean>(false);

function updateOrganization(organization: Organization) {
  emit("updateOrganization", organization);
  editMode.value = false;
}

const selectedGroup = ref<OrganizationOwnerGroup>();

const filteredMembers = computed(() => {
  if (!props.organization?.organizationMembers) {
    return [];
  }

  if (!selectedGroup.value) {
    return props.organization.organizationMembers;
  } else {
    return props.organization.organizationMembers.filter((om) =>
      om.organizationOwnerGroups
        ?.map((og) => og.groupId)
        // @ts-ignore
        .includes(selectedGroup.value.id)
    );
  }
});

const contractors = computed(() => {
  return props.organization.contractors;
});

const subContractors = computed(() => {
  return props.organization.subContractors;
});
</script>

<style lang="scss" scoped>
.edit-btn {
  position: absolute;
  top: 30px;
  right: 26px;
}
</style>
