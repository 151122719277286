<template>
  <div>
    <OrganizationEventBaseInfo :organization-event="event" />

    <div
      v-if="invitation.eventApplicationStatus === 'pending'"
      class="d-flex flex-wrap justify-content-end"
    >
      <BasicButton
        label="参加する"
        icon="pi pi-directions"
        class="me-2"
        :disabled="eventStatus === 'approved'"
        @click="$emit('approve', invitation.id)"
      />
      <BasicButton
        label="参加しない"
        icon="pi pi-times"
        variant="danger"
        :disabled="eventStatus === 'rejected'"
        @click="$emit('reject', invitation.id)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { translater } from "/@/modules/string";
import { eventColorClass } from "/@/modules/organizationEvent";
import { BasicButton } from "/@/vue/components/Atom";
import { OrganizationEventBaseInfo } from "/@/vue/components/Organisms";
import { OrganizationEventInvitationClient } from "/@/types";
import Chip from "primevue/chip";

const props = defineProps<{
  invitation: OrganizationEventInvitationClient;
}>();

defineEmits<{
  (e: "approve", id: number): void;
  (e: "reject", id: number): void;
}>();

const event = computed(() => {
  return props.invitation.organizationEvent;
});

const eventStatus = computed(() => {
  return props.invitation.eventApplicationStatus;
});
</script>

<style scoped></style>
