<template>
  <div>
    <SimpleModal
      ref="modal"
      :title="member?.user.name"
      @cancel="closeModal"
      @submit="submit"
    >
      <OrganizationMemberForm
        v-if="member"
        v-model:code="form.code"
        v-model:member-color="form.memberColor"
        v-model:need-finish-report="form.needFinishReport"
        v-model:need-submit-location="needSubmitLocation"
        v-model:hide-from-select="form.hideFromSelect"
        v-model:roles="form.roles"
        v-model:labels="form.labels"
        v-model:submit-location-time="form.submitLocationTime"
        v-model:owner-groups="form.organizationOwnerGroups"
        :need-enter-leave-report="form.needEnterLeaveReport"
        :target-member="member"
        :owner-group-items="ownerGroupItems"
        :management-labels="managementLabels"
        :errors="errors"
        @update:need-enter-leave-report="form.needEnterLeaveReport = $event"
        @add-owner-group="$emit('addOwnerGroup')"
      />
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { SimpleModal } from "/@/vue/components/Molecules";
import { OrganizationMemberForm } from "/@/vue/components/Organisms";
import {
  OrganizationMember,
  OrganizationMemberFormScheme,
  OrganizationMemberForm as OrganizationMemberFormType,
  OrganizationOwnerGroup,
  ManagementLabelClient,
} from "/@/types";
import { useZodScheme } from "/@/vue/composables";
import { errorHandle } from "/@/modules/error";

const props = defineProps<{
  member?: OrganizationMember;
  ownerGroupItems: OrganizationOwnerGroup[];
  managementLabels: ManagementLabelClient[];
}>();

const emit = defineEmits<{
  (e: "closeModal"): void;
  (e: "addOwnerGroup"): void;
  (e: "submit", params: OrganizationMemberFormType): void;
}>();

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } =
  useFormAndErrors<OrganizationMemberFormType>(OrganizationMemberFormScheme);

const needSubmitLocation = ref<boolean>(false);

function updateForm(member: OrganizationMember | undefined) {
  if (member) {
    form.id = member.id;
    form.code = member.code ?? undefined;
    form.memberColor = member.memberColor;
    form.roles = member.roles;
    form.labels = member.labels;
    form.organizationOwnerGroups = member.organizationOwnerGroups;
    form.needEnterLeaveReport = member.needEnterLeaveReport;
    form.needFinishReport = member.needFinishReport;
    form.hideFromSelect = member.hideFromSelect;
    form.submitLocationTime = member.submitLocationTime ?? undefined;

    if (form.submitLocationTime) {
      needSubmitLocation.value = true;
    }
  }
}

watch(
  () => props.member,
  (member: OrganizationMember | undefined) => {
    updateForm(member);
  }
);

function submit() {
  try {
    startValidation.value = true;
    const organizationMemberParams: OrganizationMemberFormType =
      OrganizationMemberFormScheme.parse(form);
    emit("submit", organizationMemberParams);
  } catch (e) {
    errorHandle(e);
  }
}

// modal difinissions

const modal = ref();

function openModal() {
  updateForm(props.member);
  modal.value.openModal();
}

function closeModal() {
  modal.value.closeModal();
  needSubmitLocation.value = false;
  emit("closeModal");
}

defineExpose({
  openModal,
  closeModal,
});
</script>

<style scoped></style>
