<template>
  <td
    class="td-user-row-item"
    @click="selectScheduleAsPlan($event, 'click')"
    @dblclick="selectScheduleAsPlan($event, 'dblclick')"
  >
    <template v-if="titleMaps.length">
      <TooltipWrapper
        v-for="(map, i) in titleMaps"
        :key="`user-row-calendar-item-${i}`"
        class="d-flex flex-column align-items-center"
        :class="{
          canceled: map.canceled,
          'highlight-canceled': highlightCanceled && map.canceled,
          absence: !map.canceled && targetMemberSchedulePlan?.absence,
        }"
        :tooltip-title="tooltipTitle"
        :content-id="`user-row-calendar-item-${targetDate}`"
      >
        <template v-if="short">
          <div class="d-flex align-items-center justify-content-center">
            <i
              v-if="!map.canceled && targetMemberSchedulePlan?.absence"
              class="text-danger pi pi-times"
              style="font-size: 0.9rem"
            ></i>
            <span>{{ map.workCategoryShort }} </span>
          </div>

          <span>
            {{ map.workPlaceShortName }}
          </span>
          <span v-if="map.eventPlaceName">{{ map.eventPlaceShortName }}</span>
        </template>
        <template v-else>
          <span>{{ map.workCategory }} </span>
          <span>{{ map.workPlaceName }}({{ map.workCarrier }})</span>
          <span>{{ map.workTypeName }}</span>
        </template>
      </TooltipWrapper>
    </template>
    <template v-else>
      <span v-if="!isBeforeTodayCase" :class="dayStatusColor">
        {{ dayStatus }}
      </span>
    </template>
  </td>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { workScheduleToTitleMap } from "/@/modules/workSchedule";
import { isBeforeToday } from "/@/modules/luxon";
import { createAbsenceApplicationStatus } from "/@/modules/plan";
import { TooltipWrapper } from "/@/vue/components/Molecules";
import type {
  OrganizationMemberSchedulePlan,
  ScheduleInOrganizationMember,
  ManagementLabelClient,
  MemberSchedulePlanMap,
  MemberAbsencePlanMap,
} from "/@/types";
import { isSameDate } from "/@/modules/luxon";
import { existsLabel } from "/@/modules/organizationMember";

const props = defineProps<{
  schedulePlans: OrganizationMemberSchedulePlan[];
  memberId: number;
  memberLabels: ManagementLabelClient[];
  targetDate: string;
  memberSchedulePlanMap: MemberSchedulePlanMap;
  memberAbsencePlanMap: MemberAbsencePlanMap;
  isMobile: boolean;
  highlightCanceled?: boolean;
  short?: boolean;
}>();

const emit = defineEmits<{
  (
    e: "selectScheduleAsPlan",
    targetSchedule: OrganizationMemberSchedulePlan
  ): void;
}>();

const targetSchedules = computed(() => {
  // @ts-ignore
  return props.memberSchedulePlanMap[props.memberId]?.[props.targetDate] || [];
});

const targetAbsencePlans = computed(() => {
  // @ts-ignore
  return props.memberAbsencePlanMap[props.memberId]?.[props.targetDate] || [];
});

const absenceApplicationStatus = computed(() => {
  return createAbsenceApplicationStatus(targetAbsencePlans.value);
});

const isBeforeTodayCase = computed(() => {
  return isBeforeToday(props.targetDate);
});

const isFreelance = computed(() => {
  return existsLabel(props.memberLabels, "labor", "フリーランス");
});

const dayStatus = computed(() => {
  if (absenceApplicationStatus.value.isHoliday) {
    return isFreelance.value ? "希休" : "希休";
  } else if (absenceApplicationStatus.value.isPaid) {
    return "有休";
  } else if (absenceApplicationStatus.value.isHalfday) {
    return "半休";
  } else {
    return isFreelance.value ? "" : "";
  }
});

const dayStatusColor = computed(() => {
  return {
    "text-danger": absenceApplicationStatus.value.isHoliday,
    "text-success": absenceApplicationStatus.value.isPaid,
  };
});

function schedulesToCalendarTitle(schedules: ScheduleInOrganizationMember[]) {
  const maps = schedules.map((schedule) => {
    // @ts-ignore
    return workScheduleToTitleMap(schedule);
  });

  return maps;
}

const titleMaps = computed(() => {
  // @ts-ignore
  return schedulesToCalendarTitle(targetSchedules.value);
});

const tooltipTitle = computed(() => {
  const title = titleMaps.value
    .map((tmap) => {
      return [
        !tmap.canceled && targetMemberSchedulePlan.value?.absence && "欠勤",
        tmap.canceled && "中止",
        tmap.workCategory,
        tmap.workPlaceName,
        tmap.eventPlaceName && tmap.eventPlaceName !== tmap.workPlaceName
          ? tmap.eventPlaceName
          : null,
        tmap.workTypeName,
      ]
        .filter((b) => b)
        .map((s) => `<span>${s}</span>`)
        .join("<br>");
    })
    .join("<br><br>"); // WorkSchedule が二つ以上ある場合

  return title;
});

const targetMemberSchedulePlan = computed(() => {
  return props.schedulePlans.find((schedule) => {
    return (
      schedule.organizationMemberId == props.memberId &&
      isSameDate(schedule.targetDate, props.targetDate)
    );
  });
});

function selectScheduleAsPlan(e: Event, clickType: "click" | "dblclick") {
  if (props.isMobile && clickType === "click") {
    return;
  }

  e.stopPropagation();

  if (!targetMemberSchedulePlan.value) {
    return;
  }

  emit("selectScheduleAsPlan", targetMemberSchedulePlan.value); // TODO: handle over 2 work schedules case
}
</script>

<style scoped>
.canceled {
  text-decoration: line-through;
}

.highlight-canceled.canceled {
  background-color: var(--blue-200);
}

.absence {
  background-color: var(--red-200);
}
.td-user-row-item {
  max-width: 45px;
  min-width: 45px;
}
</style>
