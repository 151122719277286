<template>
  <div>
    <EntranceForm
      v-model:work-schedule-id="form.workScheduleId"
      v-model:lat="form.startLat"
      v-model:lng="form.startLng"
      v-model:photo="form.startPhoto"
      :works="[]"
      :work-places="[]"
      :work-types="[]"
      :work-schedules="workSchedules || []"
      :need-enter-leave-report="needEnterLeaveReport"
      :errors="errors"
    />

    <div class="d-flex w-100">
      <div class="me-2">
        <BasicButton
          variant="danger"
          :disabled="loading"
          icon="pi pi-times-circle"
          label="キャンセル"
          @click="$emit('cancel')"
        />
      </div>
      <div class="">
        <BasicButton
          label="送信"
          icon="pi pi-upload"
          button-type="submit"
          :disabled="loading"
          @click="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useZodScheme } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { EntranceForm } from "/@/vue/components/Organisms";
import {
  ScheduleInOrganizationMember,
  WorkRecordEntryEntranceFormScheme,
  WorkRecordEntryEntranceNeedEnterLeaveReportScheme,
  WorkRecordEntryEntranceScheme,
} from "/@/types";
import type { WorkRecordEntryEntranceForm } from "/@/types";
import { errorHandle } from "/@/modules/error";

const props = defineProps<{
  needEnterLeaveReport?: boolean;
  workSchedules: ScheduleInOrganizationMember[];
}>();

const emit = defineEmits<{
  (e: "submit", workRecondEntry: WorkRecordEntryEntranceForm): void;
  (e: "cancel"): void;
}>();

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation, targetScheme } =
  useFormAndErrors<WorkRecordEntryEntranceForm>(
    WorkRecordEntryEntranceFormScheme
  );

const loading = ref(false);

async function handleSubmit() {
  try {
    loading.value = true;
    startValidation.value = true;

    const currentScheme = props.needEnterLeaveReport
      ? WorkRecordEntryEntranceNeedEnterLeaveReportScheme
      : WorkRecordEntryEntranceScheme;

    targetScheme.value = currentScheme;

    const workRecordEntry = currentScheme.parse(form);
    emit("submit", workRecordEntry);
  } catch (e) {
    errorHandle(e);
    loading.value = false;
  }
}
</script>

<style scoped></style>
