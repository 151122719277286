<template>
  <div>
    <label v-if="label" :for="formId" class="form-label">{{ label }}</label>
    <select
      :id="formId"
      :selected="value"
      :class="`form-select ${isValid ? '' : 'is-invalid'}`"
      :disabled="disabled"
      @change="update"
    >
      <option :value="false" :selected="!value" :disabled="!canWithoutSelect">
        {{ canWithoutSelect ? withoutSelectText : "選択して下さい" }}
      </option>
      <template v-for="item in items" :key="item">
        <option :value="item" :selected="value == item">
          {{ showFn(item) }}
        </option>
      </template>

      <slot></slot>
    </select>
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";
import { onMounted, watch } from "vue";
type Item = any;

const props = withDefaults(
  defineProps<{
    value?: Item;
    label?: string;
    formId?: string;
    items: Item[] | readonly Item[];
    showFn?: (x: any) => string;
    disabled?: boolean;
    isValid?: boolean;
    noAutoSelect?: boolean;
    canWithoutSelect?: boolean;
    withoutSelectText?: string;
  }>(),
  {
    value: undefined,
    label: undefined,
    formId: undefined,
    showFn: (x: any) => x,
    withoutSelectText: "選択して下さい",
  }
);

const emit = defineEmits<{
  (e: "update:value", v: Item): void;
}>();

function autoSelect(idx = 0) {
  if (
    !props.value &&
    !props.noAutoSelect &&
    props.items &&
    props.items.length >= 1
  ) {
    emit("update:value", props.items.at(idx));
  }
}

onMounted(() => {
  autoSelect();
});

watch(
  () => props.items,
  (newItems, oldItems) => {
    if (!_.isEqual(newItems, oldItems)) {
      autoSelect();
    }
  }
);

function update(e: Event) {
  if (e.target instanceof HTMLSelectElement) {
    emit("update:value", props.items[e.target.selectedIndex - 1]);
  } else {
    throw new Error("予期せぬエラーが発生しました");
  }
}
</script>

<style lang="scss" scoped></style>
