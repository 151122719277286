<template>
  <div>
    <PageHeader page-title="ラベルの設定" />

    <OrganizationSelector
      v-model:selected-organization-id="selectedOrganizationId"
      class="w-100 mb-3"
      :organizations="organizations || []"
    />

    <div class="d-flex justify-content-between flex-wrap">
      <div class="d-flex flex-wrap">
        <OrganizationMemberFilter
          v-model:selected-member-types="selectedMemberTypes"
          class="me-2 mb-3"
          :organization-members="organizationMembers || []"
        />

        <ManagementLabelsFilter
          v-model:selected-label-groups="selectedLabelGroups"
          class="mb-3"
          :label-groups="labelGroup"
        />
      </div>

      <div>
        <BasicButton
          icon="pi pi-tags"
          label="ラベルの設定画面へ"
          variant="primary"
          class="mb-3"
          @click="gotoEditPage"
        />
      </div>
    </div>

    <ManagementLabelsTable
      :organization-members="filteredMembers"
      :management-labels="filteredManagementLabels"
      :sub-contractors="subContractors || []"
      class="table-wrapper"
      @update-member="handleUpdateMember"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { getStorage, setStorage } from "/@/modules/localStorage";
import { createLabelGroups } from "/@/modules/managementLabel";
import { PageHeader } from "/@/vue/components/Layouts";
import {
  useOrganization,
  useOrganizationMember,
  useManagementLabel,
  useRouterUtil,
} from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import {
  OrganizationSelector,
  ManagementLabelsTable,
  ManagementLabelsFilter,
  OrganizationMemberFilter,
} from "/@/vue/components/Organisms";
import { MemberType, Organization } from "/@/types";

// goto

const { goto } = useRouterUtil();

function gotoEditPage() {
  goto({ name: "ManagementLabelsEdit" });
}

// organizations

const { getOrganizations, asOwner } = useOrganization();
const { data: organizations } = getOrganizations();

const selectedOrganizationId = ref<number | undefined>(
  getStorage("selectedOrganizationId")
);
watch(selectedOrganizationId, (id) => setStorage("selectedOrganizationId", id));
const selectedOrganization = computed<Organization | undefined>(() => {
  if (!organizations.value) {
    return;
  }

  return organizations.value.find((o) => o.id == selectedOrganizationId.value);
});

const subContractors = computed(() => {
  if (!selectedOrganization.value) return [];

  return selectedOrganization.value.subContractors;
});

const isOwner = computed(() => {
  return asOwner(selectedOrganization.value);
});

// organization members

const { getOrganizationMembers, updateOrganizationMember } =
  useOrganizationMember();
const { data: organizationMembers } = getOrganizationMembers(
  selectedOrganization,
  isOwner
);

const selectedMemberTypes = ref<MemberType[]>(
  getStorage("ManagementLabelsIndexSelectedMemberTypes") || [
    "outsourcing",
    "own_organization",
  ]
);

watch(selectedMemberTypes, (types) => {
  setStorage("ManagementLabelsIndexSelectedMemberTypes", types);
});

// management labels

const { getManagementLabels } = useManagementLabel();
const { data: managementLabels } = getManagementLabels(selectedOrganization);

const filteredMembers = computed(() => {
  if (!organizationMembers.value) return [];

  return organizationMembers.value?.filter((member) => {
    if (!selectedMemberTypes.value.length) {
      return false;
    }

    if (
      selectedMemberTypes.value.includes("own_organization") &&
      member.organizationId == selectedOrganizationId.value
    ) {
      return true;
    }

    if (
      selectedMemberTypes.value.includes("outsourcing") &&
      member.organizationId != selectedOrganizationId.value
    ) {
      return true;
    }
  });
});

// group

const selectedLabelGroups = ref<string[]>(
  getStorage("ManagementLabelsIndexSelectedLabelGroups") || ["labor", "role"]
);

watch(selectedLabelGroups, (groups) => {
  setStorage("ManagementLabelsIndexSelectedLabelGroups", groups);
});

const labelGroup = computed(() => {
  if (!managementLabels.value) return [];

  return createLabelGroups(managementLabels.value);
});

const filteredManagementLabels = computed(() => {
  if (!managementLabels.value) return [];

  return managementLabels.value.filter((ml) => {
    return selectedLabelGroups.value.includes(ml.labelType);
  });
});

// update

async function handleUpdateMember(form: { id: number; labels: number[] }) {
  if (await updateOrganizationMember(form)) {
    console.log("更新成功");
  } else {
    alert("更新に失敗しました");
  }
}
</script>

<style lang="scss" scoped>
.table-wrapper {
  max-height: 80vh;
  overflow: scroll;
}
</style>
