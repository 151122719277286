<template>
  <tr
    :class="{
      done: taskItem.doneAt,
    }"
  >
    <td class="bg-white">
      <template v-if="taskItem.childrenIds && !!taskItem.childrenIds.length">
        <HtmlIndent :indent-count="indentCount" />
        <MinusBlueIcon
          v-if="!hideChildren"
          class="icon p-1"
          role="button"
          @click="handleHideChildren"
        />
        <PlusBlueIcon
          v-else
          class="icon p-1"
          role="button"
          @click="handleShowChildren"
        />
      </template>
    </td>
    <td class="bg-white">
      <template v-if="editMode">
        <BasicButton
          rounded
          icon="pi pi-trash"
          variant="danger"
          @click="deleteTaskItem"
        />
      </template>
      <template v-else>
        <CheckForm
          v-model:value="doneChecked"
          :unchecked-value="false"
          :form-id="`done-${formattedId}`"
          label=""
          is-valid
          @update:value="updateDoneOn"
        />
      </template>
    </td>
    <td
      class="text-start path-with-adder"
      :role="disabledShowButtons ? '' : 'button'"
      @click="toggleShowAddingButtons"
    >
      <span>{{ formattedId }}</span>
      <div v-if="showAddingButtons" class="d-flex buttons">
        <BasicButton
          rounded
          small
          class="me-2"
          icon="pi pi-plus"
          @click="addSibling"
        />

        <BasicButton icon="pi pi-plus" rounded small @click="addChild" />
      </div>
    </td>
    <td
      :class="{
        'without-padding': editMode,
        'task-name': true,
        'text-start': !editMode,
      }"
    >
      <div v-if="editMode" class="d-flex">
        <HtmlIndent :indent-count="indentCount" />
        <InputForm
          :value="taskItem.name"
          form-type="text"
          :is-valid="!!taskItem.name"
          when-change
          class="w-100"
          @update:value="updateTaskItem({ name: $event })"
        />
      </div>
      <template v-else>
        <HtmlIndent :indent-count="indentCount" />
        <span>
          {{ taskItem.name }}
        </span>
      </template>
    </td>
    <td class="without-padding planned-on">
      <template v-if="editMode">
        <DateForm
          :value="
            !!taskItem.startOn && !!taskItem.finishOn
              ? [taskItem.startOn, taskItem.finishOn]
              : undefined
          "
          range
          slash-style
          is-valid
          teleport-center
          class="h-100"
          @update:value="updatePlannedOn"
        />
      </template>
      <template v-else>
        <span v-if="!taskItem.startOn || !taskItem.finishOn"> - </span>
        <span v-else>
          {{
            isSameDate(taskItem.startOn, taskItem.finishOn)
              ? basicFormatter(taskItem.startOn, "slashStyle")
              : `${basicFormatter(
                  taskItem.startOn,
                  "slashStyle"
                )}~${basicFormatter(taskItem.finishOn, "slashStyle")}`
          }}
        </span>
      </template>
    </td>
    <td>
      {{
        taskItem.doneAt ? basicFormatter(taskItem.doneAt, "slashStyle") : "  "
      }}
    </td>
    <td class="without-padding need-time">
      <template v-if="editMode">
        <InputForm
          :value="taskItem.needTime"
          form-type="number"
          is-valid
          text-end
          when-change
          :min="0"
          @update:value="updateTaskItem({ needTime: $event })"
        />
      </template>
      <template v-else>
        <span>
          {{ taskItem.needTime }}
        </span>
      </template>
    </td>
    <td
      v-if="editMode"
      :class="{
        'without-padding': editMode,
        remarks: true,
        'text-start': true,
      }"
    >
      <InputForm
        :value="taskItem.remarks"
        form-type="text"
        is-valid
        when-change
        @update:value="updateTaskItem({ remarks: $event })"
      />
    </td>
    <template v-if="!editMode">
      <td
        v-for="d in dates"
        :key="`body-${d.dateKey}`"
        class="gantt-area"
        :class="getPlannedClass(d)"
      ></td>
    </template>
  </tr>
</template>

<script setup lang="ts">
import { watch, computed, onMounted, ref } from "vue";
import { taskIdFromAncestry } from "/@/modules/taskManager";
import {
  CheckForm,
  DateForm,
  InputForm,
  BasicButton,
  PlusIcon,
  PlusBlueIcon,
  MinusBlueIcon,
  TrashIcon,
  HtmlIndent,
} from "/@/vue/components/Atom";
import {
  basicFormatter,
  luxonNow,
  isSameDate,
  isContain,
} from "/@/modules/luxon";
import { getIndentCount } from "/@/modules/taskManager";
import type {
  TaskItemForm,
  TaskItemUpdateParams,
  DateForCalendar,
} from "/@/types";

const props = defineProps<{
  taskItem: TaskItemForm;
  editMode?: boolean;
  creating?: boolean;
  dates: DateForCalendar[];
  hideChildren?: boolean;
}>();

const emits = defineEmits<{
  (e: "updateTaskItem", id: number, params: TaskItemUpdateParams): void;
  (e: "deleteTaskItem", id: number): void;
  (e: "addSibling", id: number): void;
  (e: "addChild", id: number): void;
  (e: "showChildren", id: number): void;
  (e: "hideChildren", id: number): void;
}>();

// gantt

function isPlannedDate(d: DateForCalendar) {
  if (!props.taskItem.startOn || !props.taskItem.finishOn) {
    return false;
  }

  const contain = isContain(d.dateKey, {
    start: props.taskItem.startOn,
    end: props.taskItem.finishOn,
  });

  return contain;
}

function getPlannedClass(d: DateForCalendar) {
  const isPlanned = isPlannedDate(d);

  if (!isPlanned) {
    return;
  }

  return {
    "is-planned": true,
    "is-start": props.taskItem.startOn === d.dateKey,
    "is-end": props.taskItem.finishOn === d.dateKey,
  };
}

// getter

const formattedId = computed(() => {
  return taskIdFromAncestry(props.taskItem.orderPath);
});

const indentCount = computed(() => {
  if (!props.taskItem.name) {
    return 0;
  }

  return getIndentCount(props.taskItem.orderPath);
});

// adder

const showAddingButtons = ref<boolean>(false);

const disabledShowButtons = computed(() => {
  return !props.editMode || props.creating;
});

function toggleShowAddingButtons() {
  if (disabledShowButtons.value) {
    return;
  }

  showAddingButtons.value = !showAddingButtons.value;
}

function handleShowChildren() {
  emits("showChildren", props.taskItem.id);
}

function handleHideChildren() {
  emits("hideChildren", props.taskItem.id);
}

function addSibling(e: Event) {
  e.stopPropagation();

  emits("addSibling", props.taskItem.id);
  showAddingButtons.value = false;
}

function addChild(e: Event) {
  e.stopPropagation();

  emits("addChild", props.taskItem.id);
  showAddingButtons.value = false;
}

// updater

const doneChecked = ref<boolean>();

onMounted(() => {
  doneChecked.value = !!props.taskItem.doneAt;
});

watch(
  () => props.taskItem.doneAt,
  (doneAt) => {
    doneChecked.value == !!doneAt;
  }
);

const today = luxonNow();

function updateTaskItem(params: TaskItemUpdateParams) {
  emits("updateTaskItem", props.taskItem.id, params);
}

function updateDoneOn(checked: boolean) {
  if (checked) {
    updateTaskItem({ doneAt: today.toISO() });
  } else {
    updateTaskItem({ doneAt: null });
  }
}

function updatePlannedOn(days: string[] | undefined) {
  if (!days || days.length <= 1) {
    updateTaskItem({ startOn: null, finishOn: null });
  } else {
    const startOn = days.at(0);
    const finishOn = days.at(1);

    if (!startOn || !finishOn) {
      throw new Error(`days length error: ${days.length}`);
    }

    updateTaskItem({ startOn, finishOn });
  }
}

function deleteTaskItem() {
  emits("deleteTaskItem", props.taskItem.id);
}
</script>

<style scoped lang="scss">
.task-name {
  min-width: 300px;
}

.form-check {
  padding-left: 1.75rem;
}

.path-with-adder {
  position: relative;
  min-width: 80px;

  .buttons {
    position: absolute;
    right: 0;
    bottom: -0.5rem;
    z-index: 10;
  }
}

.planned-on {
  min-width: 150px;
}

td.gantt-area {
  background-color: white;

  &.is-planned {
    background-color: yellow;
    background-origin: padding-box;

    &.is-start {
      //border-top-left-radius: 5rem;
      //border-bottom-left-radius: 5rem;
    }

    &.is-end {
      //border-top-right-radius: 5rem;
      //border-bottom-right-radius: 5rem;
    }
  }
}

.need-time {
  min-width: 75px;
}

.remarks {
  min-width: 100px;
}

.done {
  background-color: $main-gray-color;
}
</style>
