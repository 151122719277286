<template>
  <div class="d-flex w-100">
    <template v-if="eventType == 'AbsenceApplicationPlan'">
      <div class="me-2">
        <BasicButton
          variant="danger"
          :disabled="loading"
          icon="pi pi-trash"
          button-type="submit"
          label="削除"
          @click="$emit('deletePlan')"
        />
      </div>
      <div v-if="isOwner && !accept" class="">
        <BasicButton
          icon="pi pi-check-circle"
          label="承認"
          :disabled="loading"
          button-type="submit"
          @click="$emit('acceptPlan')"
        />
      </div>
    </template>
    <template v-if="eventType == 'OrganizationMemberSchedulePlan'">
      <div class="d-flex justify-content-between w-100">
        <BasicButton
          v-if="planModel?.haveOwneredAssignments"
          variant="danger"
          :disabled="loading"
          icon="pi pi-user-minus"
          label="人員削除"
          @click="$emit('destroyMemberSchedule')"
        />
        <MenuButton v-if="isFrythOwner" :menu-items="menuItems" />
      </div>
    </template>
    <template v-if="eventType == 'WorkAsPlan'">
      <div class="p-1">
        <BasicButton
          button-type="button"
          label="案件ページへ"
          icon="pi pi-briefcase"
          :disabled="loading"
          @click="$emit('gotoWorksEdit')"
        />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { BasicButton } from "/@/vue/components/Atom";
import { MenuButton } from "/@/vue/components/Molecules";
import { EventType, Plan } from "/@/types";

const props = defineProps<{
  eventType?: EventType;
  accept?: boolean;
  planModel?: Plan;
  isOwner?: boolean;
  isFrythOwner?: boolean;
  loading?: boolean;
}>();

const emit = defineEmits<{
  (e: "deletePlan"): void;
  (e: "acceptPlan"): void;
  (e: "destroyMemberSchedule"): void;
  (e: "destroyWorkSchedule"): void;
  (e: "cancelWorkSchedule"): void;
  (e: "restoreWorkSchedule"): void;
  (e: "gotoWorksEdit"): void;
  (e: "openVacancyModal"): void;
}>();

const menuItems = computed(() => {
  if (!props.planModel) return [];

  const showDeleteActions =
    props.planModel?.haveOwneredAssignments &&
    props.planModel?.workSchedules.length === 1;

  const isCanceled = props.planModel?.workSchedules[0]?.canceled;

  return [
    {
      label: "案件ページへ",
      icon: "pi pi-briefcase",
      command: () => {
        emit("gotoWorksEdit");
      },
    },
    {
      label: "スケジュール削除",
      icon: "pi pi-trash",
      visible: showDeleteActions,
      command: () => {
        emit("destroyWorkSchedule");
      },
    },
    {
      label: isCanceled ? "中止のキャンセル" : "スケジュール中止",
      icon: isCanceled ? "pi pi-circle" : "pi pi-ban",
      command: () => {
        if (isCanceled) {
          emit("restoreWorkSchedule");
        } else {
          emit("cancelWorkSchedule");
        }
      },
    },
    {
      label: "欠員設定",
      icon: "pi pi-user-minus",
      visible: props.eventType === "OrganizationMemberSchedulePlan",
      command: () => {
        emit("openVacancyModal");
      },
    },
  ];
});

/*
const isTargetBeforeToday = computed(() => {
  if (!targetDate.value) return false;

  return isBeforeToday(targetDate.value);
});
*/
</script>

<style scoped></style>
