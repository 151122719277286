<template>
  <div>
    <SimpleModal
      ref="modal"
      title="日程の調整"
      @cancel="closeModal"
      @submit="shiftPlan"
    >
      <template v-if="targetDate">
        <span>{{ basicFormatter(targetDate) }} 以降の計画を</span>
        <FormItem
          v-model:value="changeDayCount"
          label="以下に入力した期間ずらす"
          form-id="change-day-form"
          form-type="number"
          :errors="{}"
        />
      </template>
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { SimpleModal, FormItem } from "/@/vue/components/Molecules";
import { TaskItemClient } from "/@/types";
import { basicFormatter } from "/@/modules/luxon";

defineProps<{
  taskItem?: TaskItemClient;
}>();

const emit = defineEmits<{
  (e: "closeModal"): void;
  (e: "shiftPlan", num: number, targetDate: string): void;
}>();

const changeDayCount = ref(1);

function shiftPlan() {
  if (!targetDate.value) {
    return;
  }

  emit("shiftPlan", changeDayCount.value, targetDate.value);
}

// modal difinissions

const modal = ref();

const targetDate = ref<string | undefined>();

function openModal({ num, target }: { num: number; target: string }) {
  changeDayCount.value = num;
  targetDate.value = target;

  modal.value.openModal();
}

function closeModal() {
  modal.value.closeModal();
  emit("closeModal");
}

defineExpose({
  openModal,
  closeModal,
});
</script>

<style scoped></style>
