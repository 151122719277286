<template>
  <div>
    <Accordion :active-index="0">
      <AccordionTab header="ユーザーログイン">
        <Accordion :active-index="0">
          <AccordionTab
            v-for="o in organizationsWithMember"
            :key="`organization-list-${o.id}`"
          >
            <template #header>
              {{ o.name }}
            </template>
            <MemberList
              :members="o.organizationMembers"
              @admin-proxy-login="$emit('adminProxyLogin', $event)"
            />
          </AccordionTab>
        </Accordion>
      </AccordionTab>
    </Accordion>
  </div>
</template>

<script setup lang="ts">
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import { computed } from "vue";
import { ContentLabel } from "/@/vue/components/Atom";
import { CollapseWrapper } from "/@/vue/components/Molecules";
import type { OrganizationMember, Organization } from "/@/types";

import MemberList from "./AdminOrganizationMemberList.vue";

const props = defineProps<{
  members: OrganizationMember[];
  organizations: Organization[];
}>();

defineEmits<{
  (e: "adminProxyLogin", memberId: number): void;
}>();

const organizationsWithMember = computed(() => {
  return props.organizations
    .map((o) => {
      return {
        ...o,
        organizationMembers: props.members.filter(
          (member) => member.organizationId === o.id
        ),
      };
    })
    .filter((o) => o.organizationMembers.length);
});
</script>

<style scoped></style>
