import _ from "lodash";
import {
  LabelGroup,
  LabelType,
  GroupType,
  ManagementLabelClient,
  ManagementLabelConfigClient,
  needDepsLabelTypes,
  groupTypes,
} from "/@/types";

export function labelToStr(label: ManagementLabelClient) {
  return label.name;
}

export function labelTypeToStr(labelType: LabelType): string {
  switch (labelType) {
    case "labor":
      return "雇用形態";
    case "role":
      return "役職";
    case "department_owner":
      return "グループ";
    case "request_organization":
      return "代理店";
    case "work_place":
      return "店舗";
    default:
      return labelType;
  }
}

export function groupTypeTranslater(groupType: GroupType): string {
  return labelTypeToStr(groupType);
}

export function needDeps(labelType: LabelType) {
  return needDepsLabelTypes.includes(labelType);
}

function sortLabels(labels: ManagementLabelClient[]) {
  const toOrderLabels = labels.map((l) => ({
    ...l,
    order: groupTypes.indexOf(l.labelType),
  }));

  return _.sortBy(toOrderLabels, ["order", "departmentName"]);
}

export function sameGroupLabel(
  labelA: ManagementLabelClient,
  labelB: ManagementLabelClient
) {
  if (labelA.labelType === "department_owner") {
    return (
      labelB.labelType === "department_owner" &&
      labelA.departmentName === labelB.departmentName
    );
  }

  return labelA.labelType === labelB.labelType;
}

export function createLabelGroups(
  labels: ManagementLabelClient[]
): LabelGroup[] {
  const sorted = sortLabels(labels);

  const departments = _.uniqBy(
    sorted.filter((label) => label.labelType.startsWith("department")),
    "departmentName"
  );
  const others = _.uniqBy(
    sorted.filter((label) => !label.labelType.startsWith("department")),
    "labelType"
  );

  return departments
    .map((label) => ({
      groupType: "department_owner" as GroupType,
      name: label.departmentName || "",
    }))
    .concat(
      others.map((label) => ({
        groupType: label.labelType as GroupType,
        name: labelTypeToStr(label.labelType),
      }))
    );
}

export function isSubsetConfig(
  a: Partial<ManagementLabelConfigClient>,
  b: Partial<ManagementLabelConfigClient>
) {
  return [...Object.keys(a)].every((k) => a[k] === b[k]);
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const config = {
    canShowMember: true,
    canShowMemberPlans: true,
    canShowMemberRecords: true,
    canShowMemberReports: true,
    canShowMemberWorkTasks: true,
    canShowMemberNotificationMails: true,
    canShowMemberSummaryMails: true,
    canShowMemberAbsenceApplicationPlans: true,
    canShowMemberWorkScheduleCalendar: true,
    canShowMemberWorkScheduleCalendarAdmin: true,
    canShowWorkMeetingTarget: true,
    canShowOrganizationSchedule: true,
    canShowWorker: true,
    canShowWorkerSummaryMails: true,
  };

  it("createGroup", () => {
    expect(
      createLabelGroups([
        {
          id: 1,
          labelType: "department_owner",
          name: "管理者",
          departmentName: "sales1",
          config,
        },
        {
          id: 2,
          labelType: "labor",
          name: "正社員",
          config,
        },
        { id: 3, labelType: "labor", name: "アルバイト", config },
        { id: 4, labelType: "labor", name: "派遣", config },
      ])
    ).toMatchObject([
      { groupType: "department_owner", name: "sales1" },
      { groupType: "labor", name: "雇用形態" },
    ]);
  });

  it("isSubsetConfig", () => {
    expect(
      isSubsetConfig(
        { canShowMember: true, canShowMemberPlans: true },

        { canShowMember: true }
      )
    ).toBeFalsy();
  });
}
