<template>
  <router-link :to="linkTo"> {{ dateString }} {{ dailyTitle }} </router-link>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { basicFormatter, fromISO } from "/@/modules/luxon";
import { workRecordDailyLink } from "/@/modules/workRecords";
import { WorkRecordDaily } from "/@/types";
import { translater } from "/@/modules/string";

const props = defineProps<{
  workRecordDaily: WorkRecordDaily;
  organizationMemberId: number;
}>();

const linkTo = computed(() => {
  return workRecordDailyLink(
    props.organizationMemberId,
    props.workRecordDaily.targetDate
  );
});

const dateString = computed(() => {
  return basicFormatter(fromISO(props.workRecordDaily.targetDate), "noYear");
});

const isNoWork = computed(() => {
  return (
    !!props.workRecordDaily.bodyTemperatures.length &&
    props.workRecordDaily.bodyTemperatures.some((bt) => bt.noWork)
  );
});

const dailyTitle = computed(() => {
  const isNoRecord = !props.workRecordDaily.workRecords.length;

  if (isNoWork.value) {
    return "欠勤申請済";
  }

  if (!!props.workRecordDaily.bodyTemperatures.length && isNoRecord) {
    return `体温提出済`;
  }

  if (!!props.workRecordDaily.currentLocations.length && isNoRecord) {
    return `現在地提出済`;
  }

  return props.workRecordDaily.workRecords
    .map((record) =>
      record.workRecordEntries
        .map(
          (entry) =>
            `${translater(entry.workCategory)} ${
              entry.eventPlaceName || entry.workPlaceName
            }`
        )
        .join("・")
    )
    .join("・");
});
</script>

<style scoped></style>
