<template>
  <div>
    <PageHeader page-title="現在地の提出" />
    <SubmitLocationForm @submit="handleSubmit" @cancel="backto" />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { PageHeader } from "/@/vue/components/Layouts";
import { SubmitLocationForm } from "/@/vue/components/Organisms";
import type { SubmitLocationForm as SubmitLocationFormType } from "/@/types";
import { useRouterUtil, useOrganizationMember } from "/@/vue/composables";
import { onMounted } from "vue";

const { query, backto, goto } = useRouterUtil();

const selectedOrganization = ref();

onMounted(() => {
  selectedOrganization.value = {
    id: query.value.organizationId,
    organizationMemberId: query.value.organizationMemberId,
  };
});

const loading = ref(false);

const { submitLocation } = useOrganizationMember();

async function handleSubmit(form: SubmitLocationFormType) {
  loading.value = true;

  if (
    await submitLocation(selectedOrganization.value.organizationMemberId, form)
  ) {
    alert("現在地の提出を行いました");
    goto({
      name: "Home",
      query: { organizationId: selectedOrganization.value.id },
    });
  } else {
    alert(
      "現在地の提出に失敗しました。一度更新をしてから再度送りなおして下さい。"
    );
  }
  loading.value = false;
}
</script>

<style scoped></style>
