<template>
  <div>
    <div class="d-flex align-items-center">
      <ContentLabel
        label="休憩時間"
        content-id="rest-times-form"
        without-padding
        class="me-2"
      />
      <BasicButton
        small
        rounded
        :disabled="existsEmptyForm"
        icon="pi pi-plus"
        @click="addRestTime"
      />
    </div>

    <RestTimeFormItem
      v-for="(restTime, idx) of restTimes"
      :key="`rest-time-form-${idx}`"
      :idx="idx"
      :start-at="restTime.startAt"
      :finish-at="restTime.finishAt"
      @update:start-at="
        updateRestTimes({ key: 'startAt', value: $event, idx: idx })
      "
      @update:finish-at="
        updateRestTimes({ key: 'finishAt', value: $event, idx: idx })
      "
      @delete-rest-time="deleteRestTime(idx)"
    />

    <div v-if="errors && errors.restTimes" class="text-danger">
      休憩時間の入力に問題があります
    </div>
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";
import { onMounted, computed } from "vue";
import { ContentLabel, BasicButton, PlusIcon } from "/@/vue/components/Atom";
import { getAutoFillFinishAt } from "/@/modules/luxon";
import type { RestTimeForm } from "/@/types";
import RestTimeFormItem from "./RestTimeFormItem.vue";
import { removeNthElement } from "/@/modules/array";
import { ZodFormattedErrors } from "/@/modules/zodUtils";

const props = defineProps<{
  restTimes?: RestTimeForm[];
  errors: ZodFormattedErrors;
}>();

const emits = defineEmits<{
  (e: "update:restTimes", restTimes: RestTimeForm[]): void;
}>();

onMounted(() => {
  if (!props.restTimes) {
    emits("update:restTimes", []);
  }
});

const existsEmptyForm = computed(() => {
  if (!props.restTimes) {
    return false;
  }

  return props.restTimes.some((form) => !form.startAt || !form.finishAt);
});

function addRestTime() {
  if (existsEmptyForm.value) {
    return;
  }

  if (!props.restTimes) {
    emits("update:restTimes", []);
    return;
  }

  emits("update:restTimes", [
    ...props.restTimes,
    { startAt: undefined, finishAt: undefined },
  ]);
}

function deleteRestTime(idx: number) {
  if (!props.restTimes || props.restTimes.length < idx) {
    return;
  }

  emits("update:restTimes", removeNthElement(props.restTimes, idx));
}

function updateRestTimes({
  key,
  value,
  idx,
}: {
  key: keyof RestTimeForm;
  value: string | undefined;
  idx: number;
}) {
  if (!props.restTimes || key === "id" || key === "workRecordId") {
    return;
  }

  const newRestTimes = _.cloneDeep(props.restTimes);

  const target = newRestTimes[idx];

  if (!target) {
    throw new Error(`not found idx: ${idx}`);
  }

  target[key] = value;

  if (key === "startAt") {
    target.finishAt = getAutoFillFinishAt(value, target.finishAt);
  }

  emits("update:restTimes", newRestTimes);
}
</script>

<style scoped></style>
