<template>
  <div>
    <EntranceForm
      v-if="form.workRecordEntry"
      v-model:work-schedule-id="form.workRecordEntry.workScheduleId"
      v-model:lat="form.workRecordEntry.startLat"
      v-model:lng="form.workRecordEntry.startLng"
      v-model:photo="form.workRecordEntry.startPhoto"
      :works="[]"
      :work-places="[]"
      :work-types="[]"
      :work-schedules="workSchedules || []"
      :need-enter-leave-report="needEnterLeaveReport"
      :errors="errors"
    />

    <div class="d-flex w-100">
      <div class="me-2">
        <BasicButton
          variant="danger"
          :disabled="loading"
          icon="pi pi-times-circle"
          label="キャンセル"
          @click="$emit('cancel')"
        />
      </div>
      <div class="">
        <BasicButton
          button-type="submit"
          :disabled="loading"
          icon="pi pi-upload"
          label="送信"
          @click="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { errorHandle } from "/@/modules/error";
import { useZodScheme } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { EntranceForm } from "/@/vue/components/Organisms";
import {
  WorkRecordStartForm,
  WorkRecordStartFormScheme,
  WorkRecordStartNeedEnterLeaveReportScheme,
} from "/@/types";
import type { ScheduleInOrganizationMember } from "/@/types";

const props = defineProps<{
  workSchedules: ScheduleInOrganizationMember[];
  needEnterLeaveReport?: Boolean;
}>();

const emit = defineEmits<{
  (e: "cancel"): void;
  (e: "submit", workRecordForm: WorkRecordStartForm): void;
}>();

//

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation, targetScheme } =
  useFormAndErrors<WorkRecordStartForm>(WorkRecordStartFormScheme);

form.workRecordEntry = {};

const loading = ref(false);

function handleSubmit() {
  try {
    startValidation.value = true;
    loading.value = true;

    const currentScheme = props.needEnterLeaveReport
      ? WorkRecordStartNeedEnterLeaveReportScheme
      : WorkRecordStartFormScheme;

    targetScheme.value = currentScheme;

    const workRecord = currentScheme.parse(form);
    emit("submit", workRecord);
  } catch (e) {
    errorHandle(e);
    loading.value = false;
  }
}
</script>

<style scoped></style>
