<template>
  <div>
    <ContentLabel v-if="label" :label="label" :content-id="formId" />
    <ThermometerForm
      v-if="isThermometerForm"
      :value="value"
      :is-valid="!errors[formId]"
      :form-id="formId"
      :disabeld="disabled"
      :readonly="readonly"
      :step="step"
      @update:value="$emit('update:value', $event)"
    />
    <MessageList :messages="errorMessage" status="error" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { getErrorMessagesFromErrors } from "/@/modules/zodUtils";
import {
  ContentLabel,
  MessageList,
  ThermometerForm,
} from "/@/vue/components/Atom";

interface Props {
  value?: number;
  label: string;
  formId: string;
  formType?: "temperature";
  disabled?: boolean;
  readonly?: boolean;
  errors: {
    [key in string]?: {
      _errors: string[];
    };
  };
  step?: number;
}

const props = defineProps<Props>();
defineEmits<{
  (e: "update:value", value: number | undefined): void;
}>();

const isThermometerForm = computed<boolean>(() => {
  return props.formType == "temperature";
});

const errorMessage = computed<string[]>(() => {
  return getErrorMessagesFromErrors(props.errors, props.formId);
});
</script>

<style scoped></style>
