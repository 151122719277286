export function formatPostcode(n?: string) {
  if (!n) {
    return "";
  }

  return "〒" + n.slice(0, 3) + "-" + n.slice(3);
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const n = "9201111";

  it("formatPostcode", () => {
    expect(formatPostcode()).toBe("");
    expect(formatPostcode(n)).toBe("〒920-1111");
  });
}

// google map

export function createGmapURL(
  eventPlaceName: string | undefined,
  others?: string[]
) {
  if (!eventPlaceName) {
    return "";
  }

  const otherInfo = others?.filter((o) => o !== "").join("+") || "";
  return `https://www.google.com/maps/search/?api=1&query=${eventPlaceName.trim()}+${otherInfo}`;
}

export function openGmapInNewTab(url: string | null | undefined) {
  if (!url) {
    return;
  }

  window.open(url, "_blank");
}
