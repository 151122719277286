import { ComputedRef, Ref } from "vue";
import { axios, fetcher } from "/@/modules/axios";
import { createDateParams } from "/@/modules/url";
import { errorHandle } from "/@/modules/error";
import {
  DateMap,
  Organization,
  WorkRecordClient,
  WorkRecordDaily,
  WorkRecordEntryEntranceForm,
  WorkRecordEntryExitForm,
  WorkRecordFinishForm,
  WorkRecordStartForm,
} from "/@/types";
import useSWRV from "swrv";

export function useWorkRecords() {
  async function startWork(
    form: WorkRecordStartForm,
    organizationMemberId: number
  ) {
    try {
      await axios.post(
        `/api/v1/organization_members/${organizationMemberId}/work_records`,
        {
          workRecords: form,
        }
      );
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function finishWork(form: WorkRecordFinishForm) {
    try {
      await axios.post(`/api/v1/work_records/${form.id}/finish`, {
        workRecords: form,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  function getOrganizationWorkRecordsKey(
    organization: ComputedRef<Organization | undefined>,
    selectedDate: Ref<DateMap | undefined> | undefined = undefined,
    isNeedFetch?: Function
  ) {
    if (!organization.value || (isNeedFetch && !isNeedFetch())) return null;

    let apiUri = `/api/v1/organizations/${organization.value.id}/work_records`;

    if (selectedDate && selectedDate.value) {
      const dateParams = createDateParams(selectedDate.value);
      apiUri += `?${dateParams}`;
    }

    return apiUri;
  }

  function getOrganizationWorkRecords(
    organization: ComputedRef<Organization | undefined>,
    selectedDate: Ref<DateMap | undefined> | undefined = undefined,
    isNeedFetch?: Function,
    options: { revalidateOnFocus: boolean } = { revalidateOnFocus: true }
  ) {
    return useSWRV<WorkRecordClient[]>(
      () =>
        getOrganizationWorkRecordsKey(organization, selectedDate, isNeedFetch),
      fetcher,
      options
    );
  }

  function getWorkRecordsKey(
    organization: ComputedRef<Organization | undefined>,
    selectedDate: Ref<DateMap | undefined> | undefined = undefined
    // TODO: add isOwner case
  ) {
    const organizationMemberId = organization.value?.organizationMemberId;

    if (!organization.value || !organizationMemberId) {
      return null;
    }

    let apiUri = `/api/v1/organization_members/${organizationMemberId}/work_records`;

    if (selectedDate && selectedDate.value) {
      const dateParams = createDateParams(selectedDate.value);
      apiUri += `?${dateParams}`;
    }

    return apiUri;
  }

  function getWorkRecords(
    organization: ComputedRef<Organization | undefined>,
    selectedDate: Ref<DateMap | undefined> | undefined = undefined
  ) {
    return useSWRV<WorkRecordClient[]>(
      () => getWorkRecordsKey(organization, selectedDate),
      fetcher
    );
  }

  function getWorkRecordKey(id: ComputedRef<number | undefined>) {
    if (!id.value) {
      return null;
    }

    return `/api/v1/work_records/${id.value}`;
  }

  function getWorkRecord(id: ComputedRef<number | undefined>) {
    return useSWRV<WorkRecordClient>(() => getWorkRecordKey(id), fetcher);
  }

  function getCurrentWorkRecordKey(
    organization: Ref<Organization | undefined>
  ) {
    if (!organization.value) {
      return null;
    }

    return `/api/v1/organization_members/${organization.value.organizationMemberId}/current_work_record`;
  }

  function getCurrentWorkRecord(organization: Ref<Organization | undefined>) {
    return useSWRV<WorkRecordClient>(
      () => getCurrentWorkRecordKey(organization),
      fetcher
    );
  }

  function getWorkRecordDailiesKey(
    organization: ComputedRef<Organization | undefined>,
    organizationMemberId?: Ref<number | undefined>,
    selectedDate?: Ref<DateMap | undefined>,
    isOwner?: Ref<boolean>,
    isNeedFetch?: Function,
    options?: {
      needSubContractors?: boolean;
    }
  ) {
    if (!organization.value || (isNeedFetch && !isNeedFetch())) {
      return null;
    }

    let uri;

    if (isOwner && isOwner.value) {
      uri = `/api/v1/organizations/${organization.value.id}/work_record_dailies`;

      uri =
        organizationMemberId && organizationMemberId.value
          ? uri + `?organization_member_id=${organizationMemberId.value}`
          : uri;
    } else {
      uri = `/api/v1/organization_members/${organization.value.organizationMemberId}/work_record_dailies`;
    }

    if (options && options.needSubContractors) {
      uri += "?need_sub_contractors=true";
    }

    if (selectedDate) {
      const dateParams = createDateParams(selectedDate.value);
      uri = uri + `?${dateParams}`;
    }

    return uri;
  }

  function getWorkRecordDailies(
    organization: ComputedRef<Organization | undefined>,
    organizationMembereId?: Ref<number | undefined>,
    selectedDate?: Ref<DateMap | undefined>,
    isOwner?: Ref<boolean>,
    isNeedFetch?: Function,
    options?: {
      needSubContractors?: boolean;
    }
  ) {
    return useSWRV<WorkRecordDaily[]>(
      () =>
        getWorkRecordDailiesKey(
          organization,
          organizationMembereId,
          selectedDate,
          isOwner,
          isNeedFetch,
          options
        ),
      fetcher
    );
  }

  function getWorkRecordDailyKey(
    organizationMemberId:
      | ComputedRef<string | undefined>
      | Ref<number | undefined>,
    targetDate: ComputedRef<string | undefined>
  ) {
    if (!organizationMemberId.value || !targetDate.value) {
      return null;
    }

    return `/api/v1/organization_members/${organizationMemberId.value}/work_record_dailies/${targetDate.value}`;
  }

  function getWorkRecordDaily(
    organizationMemberId:
      | ComputedRef<string | undefined>
      | Ref<number | undefined>,
    targetDate: ComputedRef<string | undefined>
  ) {
    return useSWRV<WorkRecordDaily>(
      () => getWorkRecordDailyKey(organizationMemberId, targetDate),
      fetcher
    );
  }

  // entry

  async function enterEntry(id: number, form: WorkRecordEntryEntranceForm) {
    // id = workRecord id

    try {
      await axios.post(`/api/v1/work_records/${id}/enter`, {
        workRecords: {
          workRecordEntry: form,
        },
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function leaveEntry(id: number, form: WorkRecordEntryExitForm) {
    // id = workRecord id

    try {
      await axios.post(`/api/v1/work_records/${id}/leave`, {
        workRecords: {
          workRecordEntries: [form],
        },
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  // as worked

  async function asWorked(prms: {
    organizationMemberId: number;
    targetDate: string;
  }) {
    const { organizationMemberId, targetDate } = prms;

    try {
      await axios.post(
        `/api/v1/organization_members/${organizationMemberId}/work_records/as_worked`,
        {
          workRecords: {
            targetDate,
          },
        }
      );
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    startWork,
    finishWork,
    leaveEntry,
    enterEntry,

    getWorkRecord,
    getCurrentWorkRecord,
    getWorkRecords,
    getOrganizationWorkRecords,
    getWorkRecordDailies,
    getWorkRecordDaily,

    asWorked,
  };
}
