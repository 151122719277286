<template>
  <div>
    <div :class="cardClass">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  withShadow?: boolean;
  forAccount?: boolean;
  narrowPadding?: boolean;
  semiNarrowPadding?: boolean;
}>();

const cardClass = computed(() => ({
  card: true,
  "card-body": true,
  "drop-shadow": props.withShadow,
  "account-card": props.forAccount,
  "p-2": props.narrowPadding,
  "p-3": props.semiNarrowPadding,
}));
</script>

<style scoped lang="scss">
.card.card-body {
  padding: 30px 26px;
  border-radius: 0.375rem;

  &.account-card {
    border-top-left-radius: 0;
    border-top: none;
  }
}
</style>
