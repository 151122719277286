import { computed } from "vue";
import { RouteLocationRaw, useRoute, useRouter } from "vue-router";

export function useRouterUtil() {
  const router = useRouter();
  const route = useRoute();

  const query = computed(() => {
    return route.query;
  });

  const currentRoute = computed(() => {
    return router.currentRoute.value;
  });

  // mover

  function goto(to: RouteLocationRaw, withoutCurrentQuery = false) {
    if (to instanceof String) {
      router.push(to);
      return;
    }

    if (withoutCurrentQuery) {
      router.push(to);
    } else {
      if (to instanceof Object) {
        //const nto = { ...to, query: { ...to.query, ...query.value } };
        const nto = { ...to, query: { ...to.query } };
        router.push(nto);
      }
    }
  }

  function backto(options = { confirm: true }) {
    const { confirm } = options;

    if (confirm) {
      if (
        !window.confirm(
          "前のページに戻る場合、この操作により現在入力した内容が失なわれます。前のページに戻る場合は「OK」を押して下さい。"
        )
      ) {
        return;
      }
    }

    router.go(-1);
  }

  function replace(to: RouteLocationRaw) {
    router.replace(to);
  }

  return {
    goto,
    backto,
    query,
    replace,
    currentRoute,
  };
}
