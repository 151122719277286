import type {
  OrganizationMemberRole,
  Prefecture,
  ApplicationType,
  WorkCategory,
  Carrier,
  ReportItemKey,
  MailType,
  WorkTaskType,
  WorkTaskStatus,
  OrganizationOwnerGroupRole,
  LostReason,
  OrganizationCategory,
  ScheduleGroupType,
  ReportType,
  OrganizationEventType,
  EventApplicationStatus,
} from "/@/types";

export function zeroPadding(input: string | number, targetLength: number) {
  let inputStr: string | number = input;

  if (typeof inputStr === "number") {
    inputStr = inputStr.toString();
  }

  if (inputStr.length >= targetLength) {
    return inputStr;
  } else {
    const paddingSize = targetLength - inputStr.length;
    const padding = "0".repeat(paddingSize);
    return padding + inputStr;
  }
}

export function toHalf(str: string) {
  // 全角 -> 半角
  return str.replace(
    /[Ａ-Ｚａ-ｚ０-９－！”＃＄％＆’（）＝＜＞，．？＿［］｛｝＠＾～￥]/g,
    function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 65248);
    }
  );
}

export function truncate(str: string, len: number, by = "...") {
  return str.length <= len ? str : str.substr(0, len) + by;
}

export function addHtmlIndent(str: string, indentCount: number) {
  return "&nbsp;".repeat(indentCount) + str;
}

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;

  it("zeroPadding", () => {
    expect(zeroPadding("10", 2)).toBe("10");
    expect(zeroPadding("3", 3)).toBe("003");
    expect(zeroPadding("3", 10)).toBe("0000000003");
    expect(zeroPadding("12345", 5)).toBe("12345");
    expect(zeroPadding(10, 3)).toBe("010");
  });

  it("toHalf", () => {
    expect(toHalf("ＡＢＣＤ")).toBe("ABCD");
    expect(toHalf("ABC")).toBe("ABC");
  });

  it("truncate", () => {
    expect(truncate("long long test", 9)).toBe("long long...");
    expect(truncate("long text", 4, "・・・")).toBe("long・・・");
  });

  it("addHTMLIndent", () => {
    expect(addHtmlIndent("hoge", 3)).toBe("&nbsp;&nbsp;&nbsp;hoge");
  });
}

export function countMatches(str: string, target: string) {
  const regex = new RegExp(target, "g");
  const matches = str.match(regex);
  return matches ? matches.length : 0;
}

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;

  it("countMatches", () => {
    expect(countMatches("hogehoge", "hoge")).toBe(2);
    expect(countMatches("hogehoge", "o")).toBe(2);
  });
}

type AllKeys =
  | ApplicationType
  | OrganizationEventType
  | EventApplicationStatus
  | OrganizationCategory
  | Prefecture
  | OrganizationMemberRole
  | WorkCategory
  | Carrier
  | ReportItemKey
  | MailType
  | WorkTaskType
  | WorkTaskStatus
  | OrganizationOwnerGroupRole
  | LostReason
  | ScheduleGroupType
  | ReportType;

export function translater(s: AllKeys, short: boolean = false) {
  const jaMap = {
    // Application
    paid: "有休申請",
    holiday: "全休申請",
    halfday: "半休申請",
    // Prefecture
    ishikawa: "石川",
    toyama: "富山",
    fukui: "福井",
    shizuoka: "静岡",
    aichi: "愛知",
    other: "その他",
    // Role
    owner: "管理者",
    subOwner: "副管理者",
    worker: "業務",
    developer: "開発",
    designer: "デザイン",
    // WorkCategory
    normal: "通常業務",
    rounder: "ラウンダー",
    shop_helper: "店舗HP",
    event_helper: "イベント",
    fulltime: "常勤",
    consulting: "コンサル",
    light_ad: "光AD",
    sv: "SV",
    sajit: "サジット",
    // Carrier
    docomo: "D",
    au: "A",
    softbank: "S",
    home: "在宅",
    yahoo: "Y",
    uq: "U",
    office: "事務所",
    // organization category
    telecommunications: "通信",
    general: "一般",
    create_work: "業務作成",
    shop_owner: "店舗管理",

    // report item
    customerCount: "応対数",
    catchCount: "キャッチ",
    sitCount: "着座数",
    visitors_count: "来客数",
    suggestion_count: "提案数",
    premiere: "PI(プレミア)",
    ocn: "PI(OCN)",
    eximo: "PI(eximo)",
    irumo: "PI(irumo)",
    reservation: "予約",
    transport: "のりかえ",
    docomo_light: "ドコモ光",
    tvop: "TVOP",
    sky_perfect: "スカパー",
    home_5g: "home5G",
    gold: "GOLD",
    r: "R",
    migration: "マイグレ",
    bakuage: "爆アゲ",
    d_type: "d系",
    affiliate: "アフィリエイト",
    coating: "コーティング",
    galaxy: "galaxy",
    training: "研修数",
    ojt_triangle: "OJT",
    smnp_with_unit: "SMNP端末付",
    smnp_only_sim: "SMNPsim単",
    s_new_with_sim: "S新規",
    ymnp_with_unit: "YMNP端末付",
    ymnp_only_sim: "YMNPsim単",
    y_new_with_unit: "Y新規",
    up_with_unit: "UP",
    light: "光",
    light_tv: "光TV",
    air: "Air",
    pay_c: "payC",
    pay_g: "payG",
    mnp_with_unit: "MNP端末付",
    mnp_only_sim: "MNPsim単",
    new_with_unit: "新規",
    setting_support: "設定サポ",
    helpo: "HELPO",
    light_new: "光新規",
    light_diversion: "光転用",
    light_operator_change: "光事業者変更",
    light_service_change: "光サービス変更",
    air_new: "Air新規",
    air_model_change: "Air機変",
    air_service_change: "Airサービス変更",

    // mail type
    start: "開始時",
    finish: "終了時",
    submit_bodytemperature: "体温提出時",

    // work task
    calling: "架電",
    contracted: "成約済",
    losted: "失注済",
    undone: "未完了",

    // OrganizationOwnerGroupRole
    group_member: "メンバー",
    group_owner: "管理者",

    // lost reason
    family_opposition: "家族の反対",
    other_stores: "他店での手続き",
    change_mind: "考え直した",
    unreachable: "連絡取れず",
    other_reason: "その他",

    // schedule group type
    unnecessary: "未設定",
    weekly: "週毎(火曜〜月曜))",
    monthly: "月毎",
    continuous: "連続した日程",

    // report type
    overall: "全体実績",
    individual: "個人実績",

    // organization event type
    meeting: "会議",
    study: "勉強会",
    meeting_training: "研修会",
    pending: "未提出",
    approved: "参加",
    rejected: "不参加",
  };

  const jaShortMap = {
    // Application
    paid: "有休申請",
    holiday: "全休申請",
    halfday: "半休申請",
    // Prefecture
    ishikawa: "石川",
    toyama: "富山",
    fukui: "福井",
    aichi: "愛知",
    shizuoka: "静岡",
    other: "他",
    // Role
    owner: "管",
    subOwner: "副管",
    worker: "業務",
    developer: "開発",
    designer: "デザ",
    // WorkCategory
    normal: "通常",
    rounder: "RD",
    shop_helper: "HP",
    event_helper: "EV",
    fulltime: "常勤",
    consulting: "Co",
    light_ad: "AD",
    sv: "SV",
    sajit: "sj",
    // Carrier
    docomo: "D",
    au: "A",
    softbank: "S",
    yahoo: "Y",
    uq: "U",
    home: "在宅",
    office: "事務",
    // organization category
    telecommunications: "通信",
    general: "一般",
    create_work: "業管",
    shop_owner: "店管",

    // report item
    customerCount: "応対",
    catchCount: "キャッチ",
    sitCount: "着座",
    visitors_count: "来客",
    suggestion_count: "提案",
    premiere: "PI(プレミア)",
    ocn: "PI(OCN)",
    eximo: "PI(eximo)",
    irumo: "PI(irumo)",
    reservation: "予約",
    transport: "のりかえ",
    docomo_light: "ドコモ光",
    tvop: "TVOP",
    sky_perfect: "スカパー",
    home_5g: "home5G",
    gold: "GOLD",
    r: "R",
    migration: "マイグレ",
    bakuage: "爆アゲ",
    d_type: "d系",
    affiliate: "アフィリエイト",
    coating: "コーティング",
    galaxy: "galaxy",
    training: "研修数",
    ojt_triangle: "OJT",
    smnp_with_unit: "SMNP端末付",
    smnp_only_sim: "SMNPsim単",
    s_new_with_sim: "S新規",
    ymnp_with_unit: "YMNP端末付",
    ymnp_only_sim: "YMNPsim単",
    y_new_with_unit: "Y新規",
    up_with_unit: "UP",
    light: "光",
    light_tv: "光TV",
    air: "Air",
    pay_c: "payC",
    pay_g: "payG",
    mnp_with_unit: "MNP端末付",
    mnp_only_sim: "MNPsim単",
    new_with_unit: "新規",
    setting_support: "設定サポ",
    helpo: "HELPO",
    light_new: "光新規",
    light_diversion: "光転用",
    light_operator_change: "光事変",
    light_service_change: "光サ変",
    air_new: "Air新規",
    air_model_change: "Air機変",
    air_service_change: "Airサ変",

    // mail type
    start: "開始",
    finish: "終了",
    submit_bodytemperature: "体温",

    // work task
    calling: "架電",
    contracted: "成約済",
    losted: "失注済",
    undone: "未完了",

    // OrganizationOwnerGroupRole
    group_member: "メンバー",
    group_owner: "管理者",

    // lost reason
    family_opposition: "家族の反対",
    other_stores: "他店での手続き",
    change_mind: "考え直した",
    unreachable: "連絡取れず",
    other_reason: "その他",

    // schedule group type
    unnecessary: "未設定",
    weekly: "週毎(火曜〜月曜))",
    monthly: "月毎",
    continuous: "連続した日程",

    // report type
    overall: "全体実績",
    individual: "個人実績",

    // organization event type
    meeting: "会議",
    study: "勉強",
    meeting_training: "研修",
    pending: "未提出",
    approved: "参加",
    rejected: "不参加",
  };

  const ja: string = short ? jaShortMap[s] : jaMap[s];

  return ja;
}

export function carrierToPrefix(carrier?: string) {
  if (!carrier) {
    return "";
  }

  return carrier.at(0)?.toUpperCase() || "";
}

export function shorter(str?: string, num = 3): string | undefined {
  // 上記の `translater` を利用する場合を除いた
  // 他の全ての文字列の短縮形の定義場所
  // 基本的には先頭3文字を取り出すが、条件にマッチする場合はそれを返す

  if (!str) return undefined;

  if (str.match("アル・プラザ")) {
    return shorter(str.replace("アル・プラザ", "アルプラザ"));
  }

  if (str.match("ドン・キホーテ")) {
    return shorter(str.replace("ドン・キホーテ", "ドンキホーテ"));
  }

  if (str.match("・")) {
    return str
      .split("・")
      .map((s) => shorter(s))
      .join("・");
  } else if (str.match("\n")) {
    return str
      .split("\n")
      .map((s) => shorter(s))
      .join("\n");
  } else if (str.startsWith("auショップ")) {
    return str.replace("auショップ", "").slice(0, num);
  } else {
    return str.slice(0, num);
  }
}

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;

  it("shorter", () => {
    expect(shorter("auショップ婦中")).toBe("婦中");
    expect(shorter(undefined)).toBeFalsy();
    expect(shorter("田中裕司・伊藤太司")).toBe("田中裕・伊藤太");
    expect(shorter("アル・プラザ")).toBe("アルプ");
  });
}

export function numberToCircle(num: number) {
  return "①②③④⑤⑥⑦⑧⑨⑩".at(num - 1);
}

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;

  it("numberToCircle", () => {
    expect(numberToCircle(1)).toBe("①");
    expect(numberToCircle(2)).toBe("②");
    expect(numberToCircle(3)).toBe("③");
    expect(numberToCircle(10)).toBe("⑩");
  });
}
