<template>
  <div>
    <div class="d-flex flex-column w-100">
      <div class="mb-3">
        <FormItem
          v-model:value="loginForm.email"
          :errors="errors"
          label="メール"
          form-id="email"
          form-type="email"
        />
      </div>
      <div class="mb-3">
        <FormItem
          v-model:value="loginForm.password"
          :errors="errors"
          label="パスワード"
          form-id="password"
          form-type="password"
        />
      </div>

      <div class="my-3">
        <MessageList :messages="messages" status="error" />
      </div>

      <div>
        <BasicButton
          button-type="submit"
          :disabled="loading"
          :variant="'warning'"
          icon="pi pi-sign-in"
          :label="loginBtnText"
          @click="login"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { errorHandle } from "/@/modules/error";
import { useZodScheme } from "/@/vue/composables/zod";
import { BasicButton, MessageList } from "/@/vue/components/Atom";
import { FormItem } from "/@/vue/components/Molecules";

// scheme
const LoginScheme = z.object({
  email: z.string().email(),
  password: z.string().min(1, { message: "パスワードが入力されていません" }),
});

type LoginForm = zod.infer<typeof LoginScheme>;

// define

const props = withDefaults(
  defineProps<{
    loading?: boolean;
    failLogin?: boolean;
    loginBtnText?: string;
  }>(),
  {
    loginBtnText: "ログイン",
  }
);

const emit = defineEmits<{
  (e: "login", user: LoginForm): void;
}>();

// core

const { useFormAndErrors } = useZodScheme();
const {
  form: loginForm,
  errors,
  startValidation,
} = useFormAndErrors<LoginForm>(LoginScheme);

function login() {
  try {
    startValidation.value = true;
    const user = LoginScheme.parse(loginForm);
    emit("login", user);
  } catch (e) {
    errorHandle(e);
  }
}

const messages = computed(() => {
  return props.failLogin ? ["ログインに失敗しました"] : [];
});
</script>

<style scoped></style>
