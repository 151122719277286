import {
  WorkPlace,
  WorkPlaceCheckItemMap,
  WorkPlacesInfo,
  CheckItem,
  Carrier,
  Prefecture,
} from "/@/types";

export function createWorkPlaceCheckItemMap({
  wps,
  cs,
  ps,
}: {
  wps: WorkPlace[] | undefined;
  cs: Carrier[];
  ps: Prefecture[];
}) {
  if (!wps) {
    return {};
  }

  const m: WorkPlaceCheckItemMap = {};

  cs.forEach((c) => {
    m[c] = {};

    ps.forEach((p) => {
      const filtered = wps
        .filter((wp) => wp.placeSubCategory == c && wp.prefecture == p)
        .map(
          (wp: WorkPlace): CheckItem => ({
            value: wp.id,
            name: wp.name,
          })
        );

      // @ts-ignore ここでは m[c] には確実に {} が入っているが型エラーが起こる
      m[c][p] = {
        workPlaces: filtered,
        count: filtered.length,
      };
    });
  });

  return m;
}

export function targetWorkPlacesInfo(
  map: WorkPlaceCheckItemMap | undefined,
  c: Carrier,
  p: Prefecture
): WorkPlacesInfo | undefined {
  if (!map) {
    return undefined;
  }

  const ofCarrier = map[c];

  if (!ofCarrier) {
    return undefined;
  }

  const ofPrefecture = ofCarrier[p];

  if (!ofPrefecture) {
    return undefined;
  }

  return ofPrefecture;
}

export function targetWorkPlaces(
  map: WorkPlaceCheckItemMap,
  c: Carrier,
  p: Prefecture
) {
  const workPlaceInfo = targetWorkPlacesInfo(map, c, p);
  return workPlaceInfo ? workPlaceInfo.workPlaces : [];
}

export function existsWorkPlace(
  map: WorkPlaceCheckItemMap,
  c: Carrier,
  p: Prefecture
): boolean {
  return !!targetWorkPlaces(map, c, p).length;
}

export function createFilteredWorkPlacesCount({
  wpcim,
  cs,
  ps,
}: {
  wpcim: WorkPlaceCheckItemMap;
  cs: Carrier[];
  ps: Prefecture[];
}) {
  let count = 0;

  cs.forEach((c) => {
    ps.forEach((p) => {
      const workPlacesInfo = targetWorkPlacesInfo(wpcim, c, p);
      if (workPlacesInfo) {
        count += workPlacesInfo.count;
      }
    });
  });

  return count;
}
