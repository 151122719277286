<template>
  <div>
    <PageHeader page-title="ラベル管理" />
    <OrganizationSelector
      v-if="organizationsData"
      v-model:selected-organization-id="selectedOrganizationId"
      class="w-100 mb-3"
      :organizations="organizationsData || []"
    />

    <div v-if="isOwner" class="table-wrapper">
      <div class="w-100 d-flex justify-content-end mb-3">
        <BasicButton
          variant="primary"
          rounded
          middle
          icon="pi pi-plus"
          class="me-2"
          @click="openFormModal"
        />

        <BasicButton
          variant="danger"
          rounded
          middle
          icon="pi pi-trash"
          @click="editMode = !editMode"
        />
      </div>

      <div class="table-wrapper">
        <ManagementLabelsConfigTable
          :management-labels="managementLabels || []"
          :edit-mode="editMode"
          @update-management-label="handleUpdateManagementLabel"
          @destroy-management-label="handleDestroyManagementLabel"
        />
      </div>
    </div>

    <teleport to="body">
      <ManagementLabelsFormModal
        v-if="selectedOrganizationId"
        ref="formModal"
        :management-labels="managementLabels || []"
        :request-organizations="filteredRequestOrganizations"
        :work-places="workPlaces || []"
        @submit="handleCreateManagementLabel"
        @search-work-place="handleSearchWorkPlaces"
      />
    </teleport>
  </div>
</template>

<script setup lang="ts">
import { watch, ref, computed } from "vue";
import {
  useOrganization,
  useManagementLabel,
  useWorkPlaces,
} from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { PageHeader } from "/@/vue/components/Layouts";
import {
  OrganizationSelector,
  ManagementLabelsConfigTable,
  ManagementLabelsFormModal,
} from "/@/vue/components/Organisms";
import {
  ManagementLabelConfigClient,
  ManagementLabel,
  ManagementLabelClient,
  Prefecture,
  Carrier,
} from "/@/types";
import { onMounted } from "vue";
import { labelTypeToStr } from "/@/modules/managementLabel";

// organization

const {
  getOrganizations,
  organizations,
  isOwner,
  selectedOrganizationId,
  selectedOrganization,
  getRequestOrganizations,
} = useOrganization();

const { data: organizationsData } = getOrganizations();
watch(organizationsData, (os) => {
  organizations.value = os || [];
});
onMounted(() => {
  if (organizationsData.value) {
    organizations.value = organizationsData.value;
  }
});

// request organizations

const { data: requestOrganizations } =
  getRequestOrganizations(selectedOrganization);

const filteredRequestOrganizations = computed(() => {
  if (!requestOrganizations.value) return [];

  return requestOrganizations.value.filter(
    (o) => o.id !== selectedOrganizationId.value
  );
});

// work places

const { getWorkPlaces, searchWorkPlaces } = useWorkPlaces();
const { data: workPlaces } = getWorkPlaces();

function handleSearchWorkPlaces(prms: {
  prefectures: Prefecture[];
  carriers: Carrier[];
}) {
  if (!selectedOrganization.value || !selectedOrganization.value.categories)
    return;

  searchWorkPlaces(
    selectedOrganization.value.categories,
    prms.carriers,
    prms.prefectures
  );
}

// label

const {
  getManagementLabels,
  updateManagementLabel,
  createManagementLabel,
  destroyManagementLabel,
} = useManagementLabel();
const { data: managementLabels, mutate: getManagementLabelsMutate } =
  getManagementLabels(selectedOrganization);

async function handleUpdateManagementLabel(prms: {
  id: number;
  config: ManagementLabelConfigClient;
}) {
  if (await updateManagementLabel(prms)) {
    getManagementLabelsMutate();
  } else {
    alert("更新に失敗しました");
  }
}

const editMode = ref<boolean>(false);

async function handleCreateManagementLabel(form: ManagementLabel) {
  if (await createManagementLabel(selectedOrganization.value, form)) {
    getManagementLabelsMutate();
    closeFormModal();
  } else {
    alert("作成に失敗しました");
  }
}

async function handleDestroyManagementLabel(
  label: ManagementLabelClient,
  leastCount: number
) {
  let labelName;
  let alertMessage = "";

  if (label.labelType === "department_owner") {
    labelName = `${labelTypeToStr(label.labelType)} ${label.departmentName} ${
      label.name
    }`;
    if (leastCount === 1) {
      alertMessage =
        "グループのラベルが0になるとグループ自体が自動的に削除されます。";
    }
  } else {
    labelName = `${labelTypeToStr(label.labelType)} ${label.name}`;
  }

  if (!confirm(`${labelName}を削除してよろしいですか？${alertMessage}`)) return;

  if (await destroyManagementLabel(label.id)) {
    getManagementLabelsMutate();
  } else {
    alert("削除に失敗しました");
  }
}

// form modal

const formModal = ref();

function openFormModal() {
  formModal.value.openModal();
}

function closeFormModal() {
  formModal.value.closeModal();
}
</script>

<style scoped lang="scss">
.table-wrapper {
  overflow-x: scrolle;
}
</style>
