import { createApp, App as Application } from "vue";
import { Vue3Mq } from "vue3-mq";
import PrimeVue from "primevue/config";

import Root from "../vue/pages/Root.vue";

const app: Application = createApp(Root);

import { creatingRouter } from "../vue/pages/router";
import { createStoreWith, key } from "../vue/store/index";

const store = createStoreWith();

app.use(creatingRouter({ store, mock: false }));
app.use(store, key);
app.use(Vue3Mq, { preset: "bootstrap5" });
app.use(PrimeVue);

app.mount("#root-vue");
