<template>
  <div>
    <PageHeader page-title="体温提出" />
    <BodyTemperatureForm @submit="submitBodyTemperature" />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { axios } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import { useRouterUtil } from "/@/vue/composables";
import { PageHeader } from "/@/vue/components/Layouts";
import { BodyTemperatureForm } from "/@/vue/components/Organisms";

// 体温提出の scheme の定義
type BodyTemperature = {
  temperature: number;
  photo: { data: string };
  explanation: string;
};

async function submitAPI(bodyTemperature: BodyTemperature) {
  try {
    axios.post("/api/v1/body_temperatures", {
      bodyTemperatures: bodyTemperature,
    });
    return true;
  } catch (e) {
    errorHandle(e);
    return false;
  }
}

const { goto, query } = useRouterUtil();
const loading = ref<boolean>(false);

async function submitBodyTemperature(bodyTemperature: BodyTemperature) {
  loading.value = true;

  if (await submitAPI(bodyTemperature)) {
    goto({ name: "Home", query: query.value });
  } else {
    alert("更新に失敗しました。");
  }

  loading.value = false;
}
</script>

<style scoped></style>
