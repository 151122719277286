import { axios } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import { WorkScheduleAssignment, AssignScheduleResponse } from "/@/types";

export function useWorkScheduleAssignments() {
  async function createWorkScheduleAssignment(
    assignment: WorkScheduleAssignment,
    assginOrganizationId: number
  ): Promise<AssignScheduleResponse | undefined> {
    try {
      const { data: newWorkScheduleAsPlans }: { data: AssignScheduleResponse } =
        await axios.post(
          `/api/v1/work_schedule_assignments?assign_organization_id=${assginOrganizationId}`,
          {
            workScheduleAssignments: assignment,
          }
        );
      return newWorkScheduleAsPlans;
    } catch (e) {
      errorHandle(e);
      return undefined;
    }
  }

  async function destroyWorkScheduleAssignment(id: number) {
    try {
      await axios.delete(`/api/v1/work_schedule_assignments/${id}`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function updateWorkScheduleAssignment(
    id: number,
    prms: { vacancy: boolean }
  ): Promise<AssignScheduleResponse | undefined> {
    try {
      const { data: newWorkScheduleAsPlans }: { data: AssignScheduleResponse } =
        await axios.put(`/api/v1/work_schedule_assignments/${id}`, {
          workScheduleAssignments: prms,
        });
      return newWorkScheduleAsPlans;
    } catch (e) {
      errorHandle(e);
      return undefined;
    }
  }

  return {
    createWorkScheduleAssignment,
    updateWorkScheduleAssignment,
    destroyWorkScheduleAssignment,
  };
}
