<template>
  <div id="home" class="d-flex flex-column align-items-center">
    <OrganizationSelector
      v-if="organizationsData"
      v-model:selected-organization-id="selectedOrganizationId"
      class="w-100"
      :organizations="organizationsData"
    />
    <template v-if="selectedOrganization && (isWorker || isHomeworker)">
      <OrganizationEventInvitationList
        v-if="selectedOrganization.organizationEventInvitations.length"
        :invitations="selectedOrganization?.organizationEventInvitations"
        class="w-100 mb-3"
        @approve="handleUpdateOrganizationEventInvitation($event, 'approve')"
        @reject="handleUpdateOrganizationEventInvitation($event, 'reject')"
      />

      <TodaySchedules
        v-if="selectedOrganization"
        class="w-100 mb-3 card card-body"
        :today-schedule-plans="myTodaySchedules"
        :today-body-temperature="todayNewestBodyTemperature"
        :organization-member-id="selectedOrganization?.organizationMemberId"
        :basis-report-items="basisReportItems || []"
        :added-form-items="[]"
        :organization="selectedOrganization"
        :can-show-meeting-targets="canShowWorkMeetingTarget"
        :loading="updateMeetingTargetWorkPlaceLoading"
        @destroy-meeting-targets="handleDestroyMeetingTargets"
        @update-meeting-targets="handleUpdateMeetingTargets"
        @update-work-report="handleUpdateWorkReport"
        @select-work-report="openReportUpdateModal"
        @update-meeting-target-work-place="handleUpdateMeetingTargetWorkPlace"
      />

      <CurrentWorkRecord
        v-if="currentWorkRecord"
        class="w-100 mb-3 card card-body"
        :work-record="currentWorkRecord"
      />

      <TodayWorkTasks
        v-if="todayWorkTasks && todayWorkTasks.length"
        :work-tasks="todayWorkTasks || []"
        :basis-report-items="basisReportItems || []"
        class="w-100 mb-3 card card-body"
        @goto-work-tasks-index="
          goto({ name: 'WorkTasksIndex', query: { id: $event } })
        "
      />

      <template v-if="actionStatus">
        <OperationButtons
          :user-action-status="actionStatus"
          @submit-body-temperature="submitBodyTemperature"
          @submit-antibody-test="submitAntibodyTest"
          @start-work="startWork"
          @finish-work="finishWork"
          @submit-location="submitLocation"
          @submit-targets="openMeetingTargetFormModal"
          @enter="enter"
          @leave="leave"
          @goto-work-tasks-index="gotoWorkTasksIndex"
        />
      </template>
    </template>

    <template v-if="canShowMemberPlans && workerMembers.length">
      <OrganizationMemberSchedules
        :owner-organization-id="selectedOrganization?.id"
        :members="workerMembers"
        :sub-contractors="subContractors"
        :is-admin="isAdmin"
        class="w-100 mb-3"
      />
    </template>

    <teleport to="body">
      <MeetingTargetFormModal
        ref="meetingTargetFormModal"
        :work-schedules="myTodayWorkSchedules"
        :basis-report-items="basisReportItems || []"
        :organization-member-id="selectedOrganization?.organizationMemberId"
        @submit="handleCreateMeetingTargets"
      />
      <WorkReportUpdateFormModal
        ref="workReportUpdateFormModal"
        :work-record-entry-report="selectedWorkReport"
        :basis-report-items="basisReportItems || []"
        @submit="handleUpdateWorkReport"
      />
    </teleport>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch } from "vue";
import {
  useRouterUtil,
  useOrganization,
  usePlans,
  useWorkRecords,
  useOrganizationEvents,
  useOrganizationMember,
  useOrganizationMemberSchedule,
  useWorkTasks,
  useUser,
  useMeetingTargets,
  useWorkReport,
  useBasisReportItems,
  useMeetingTargetWorkPlaces,
} from "/@/vue/composables";
import {
  OperationButtons,
  TodaySchedules,
  OrganizationSelector,
  CurrentWorkRecord,
  OrganizationMemberSchedules,
  TodayWorkTasks,
  MeetingTargetFormModal,
  WorkReportUpdateFormModal,
  OrganizationEventInvitationList,
} from "/@/vue/components/Organisms";
import type {
  DateMap,
  MeetingTargetForm,
  WorkRecordEntryReportForm,
  WorkReportClient,
  MeetingTargetWorkPlace,
} from "/@/types";
import { luxonNow, isSameDate } from "/@/modules/luxon";
import { onMounted } from "vue";

const { goto } = useRouterUtil();

// user

const { getTodayNewestBodyTemperature } = useUser();
const { data: todayNewestBodyTemperature } = getTodayNewestBodyTemperature();

// organization

const {
  getOrganizations,
  isOwner,
  isFRYTHOwner,
  isWorker,
  isHomeworker,
  canShowMemberPlans,
  canShowWorkMeetingTarget,
  isAdmin,
  organizations,
  selectedOrganizationId,
  selectedOrganization,
} = useOrganization();
const { data: organizationsData } = getOrganizations();

watch(organizationsData, (os) => {
  organizations.value = os || [];
});

//const roleMode = ref<"owner" | "worker" | undefined>("owner");

// label

// organization members (when isOwner)

const { getOrganizationMembers } = useOrganizationMember();
const { data: organizationMembers } = getOrganizationMembers(
  selectedOrganization,
  isOwner
);

const workerMembers = computed(() => {
  if (!organizationMembers.value) return [];

  return organizationMembers.value?.filter((om) => om.roles.includes("worker"));
});

// sub contractors

const subContractors = computed(() => {
  if (!selectedOrganization.value) {
    return [];
  }

  return selectedOrganization.value.subContractors;
});

// status

const { getActionStatus } = useOrganizationMember();
const { data: actionStatus } = getActionStatus(selectedOrganization);

// plan

const selectedDate = ref<DateMap>({
  year: 2023,
  month: 1,
});

onMounted(() => {
  const today = luxonNow();

  selectedDate.value = {
    year: today.year,
    month: today.month,
    day: today.toISO() || undefined,
  };

  if (organizations.value.length !== organizationsData.value?.length) {
    organizations.value = organizationsData.value || [];
  }
});

const { getPlanMaps, getOrganizationMemberSchedules, getWorkScheduleAsPlans } =
  usePlans();
const { data: planMapsSource, mutate: getPlanMapSourceMutate } = getPlanMaps(
  selectedOrganization,
  isFRYTHOwner,
  selectedDate
);

const planMaps = ref({});

watch(planMapsSource, (pms) => {
  if (pms) {
    planMaps.value = pms;
  }
});

const { data: workScheduleAsPlans, mutate: getWorkScheduleAsPlansMutate } =
  getWorkScheduleAsPlans(selectedOrganization, selectedDate, isFRYTHOwner);

const {
  data: organizationMemberSchedulePlans,
  mutate: getOrganizationMemberSchedulePlansMutate,
} = getOrganizationMemberSchedules(
  selectedOrganization,
  selectedDate,
  isFRYTHOwner
);

watch(organizationMemberSchedulePlans, (omsps) => {
  if (omsps) {
    planMaps.value.organizationMemberSchedulePlans = omsps.map((omsp) => {
      omsp.workSchedules = omsp.workScheduleIds
        .map((id) => {
          return workScheduleAsPlans.value?.find((wsap) => wsap.id === id);
        })
        .filter((b) => b);

      return omsp;
    });
  }
});

// schedule

const { getMyTodaySchedules } = useOrganizationMemberSchedule();

const schedules = computed(() => {
  if (!planMaps.value) {
    return [];
  }

  return planMaps.value.organizationMemberSchedulePlans;
});

const myTodaySchedules = computed(() => {
  return getMyTodaySchedules(
    schedules.value,
    selectedOrganization.value?.organizationMemberId
  );
});

const myTodayWorkSchedules = computed(() => {
  return myTodaySchedules.value
    .flatMap((s) => s.workSchedules)
    .filter((b) => b);
});

// basis report items

const { getBasisReportItems } = useBasisReportItems();
const { data: basisReportItems } = getBasisReportItems();

// workRecord

const { getCurrentWorkRecord } = useWorkRecords();

const { data: currentWorkRecord } = getCurrentWorkRecord(selectedOrganization);

// workTasks

const { getWorkTasks } = useWorkTasks();
const { data: workTasks } = getWorkTasks(selectedOrganization); // TODO: add selected date

const today = luxonNow().toISO() || "";

const todayWorkTasks = computed(() => {
  return (
    workTasks.value?.filter(
      (task) =>
        isSameDate(task.actionAt, today) &&
        task.organizationMemberId ===
          selectedOrganization.value?.organizationMemberId
    ) || []
  );
});

// actions

function submitBodyTemperature() {
  if (!selectedOrganization.value) {
    return;
  }

  goto({
    name: "BodyTemperaturesNew",
    query: {
      organizationId: selectedOrganization.value.id,
    },
  });
}

function submitAntibodyTest() {
  if (!selectedOrganization.value) {
    return;
  }
  goto({
    name: "AntibodyTestsNew",
    query: {
      organizationId: selectedOrganization.value.id,
    },
  });
}

function startWork() {
  if (!selectedOrganization.value) {
    return;
  }

  goto(
    {
      name: "WorkRecordsNew",
      query: {
        organizationId: selectedOrganization.value.id,
        organizationMemberId: selectedOrganization.value.organizationMemberId,
      },
    },
    true
  );
}

function finishWork() {
  if (!currentWorkRecord.value) {
    alert("現在業務中ではありません");
    return;
  }

  if (!selectedOrganization.value) {
    return;
  }

  // TODO: workRecordId をここから渡すのは微妙な気がする

  goto({
    name: "WorkRecordsFinish",
    query: {
      workRecordId: currentWorkRecord.value.id,
      organizationId: selectedOrganization.value.id,
      organizationMemberId: selectedOrganization.value.organizationMemberId,
    },
  });
}

function submitLocation() {
  if (!selectedOrganization.value) {
    return;
  }

  goto({
    name: "SubmitLocation",
    query: {
      organizationId: selectedOrganization.value.id,
      organizationMemberId: selectedOrganization.value.organizationMemberId,
    },
  });
}

function gotoWorkTasksIndex() {
  goto({
    name: "WorkTasksIndex",
  });
}

function enter() {
  if (!currentWorkRecord.value) {
    alert("現在業務中ではありません");
    return;
  }

  if (!selectedOrganization.value) {
    return;
  }

  goto({
    name: "WorkRecordsEnter",
    query: {
      organizationId: selectedOrganization.value.id,
      organizationMemberId: selectedOrganization.value.organizationMemberId,
    },
  });
}

function leave() {
  if (!currentWorkRecord.value) {
    alert("現在業務中ではありません");
    return;
  }

  if (!selectedOrganization.value) {
    return;
  }

  goto({
    name: "WorkRecordsLeave",
    query: {
      organizationId: selectedOrganization.value.id,
      organizationMemberId: selectedOrganization.value.organizationMemberId,
    },
  });
}

// meeting targets

const { createMeetingTargets, destroyMeetingTargets, updateMeetingTarget } =
  useMeetingTargets();

async function handleCreateMeetingTargets(
  workId: number,
  workScheduleId: number,
  form: MeetingTargetForm
) {
  if (await createMeetingTargets(workId, workScheduleId, form)) {
    alert("作成に成功しました");
  } else {
    alert("作成に失敗しました");
  }
}

async function handleUpdateMeetingTargets(form: MeetingTargetForm) {
  if (await updateMeetingTarget(form)) {
    alert("更新に成功しました");
  } else {
    alert("更新に失敗しました");
  }
}

async function handleDestroyMeetingTargets(id: number) {
  if (!window.confirm("目標を削除してよろしいですか?")) {
    return;
  }

  if (await destroyMeetingTargets(id)) {
    alert("削除に成功しました");
  } else {
    alert("削除に失敗しました");
  }
}

const meetingTargetFormModal = ref();

function openMeetingTargetFormModal() {
  meetingTargetFormModal.value?.openModal();
}

function closeMeetingTargetFormModal() {
  meetingTargetFormModal.value?.closeModal();
}

// meeting target work place

const { updateMeetingTargetWorkPlace } = useMeetingTargetWorkPlaces();

const updateMeetingTargetWorkPlaceLoading = ref(false);

async function handleUpdateMeetingTargetWorkPlace(
  meetingTargetWorkPlace: MeetingTargetWorkPlace
) {
  const api = updateMeetingTargetWorkPlace;

  updateMeetingTargetWorkPlaceLoading.value = true;

  if (await api(meetingTargetWorkPlace)) {
    Promise.all([
      getOrganizationMemberSchedulePlansMutate(),
      getPlanMapSourceMutate(),
      getWorkScheduleAsPlansMutate(),
    ]).then(() => {
      updateMeetingTargetWorkPlaceLoading.value = false;
    });
  } else {
    alert("更新に失敗しました");
  }
}

// report

const workReportUpdateFormModal = ref();

const selectedWorkReport = ref<WorkReportClient>();

function openReportUpdateModal(workReport: WorkReportClient) {
  selectedWorkReport.value = workReport;
  workReportUpdateFormModal.value?.openModal();
}

function closeReportUpdateFormModal() {
  workReportUpdateFormModal.value?.closeModal();
}

const { updateWorkReport } = useWorkReport();

async function handleUpdateWorkReport(workReport: WorkRecordEntryReportForm) {
  if (!selectedOrganization.value?.organizationMemberId) {
    return;
  }

  if (
    await updateWorkReport(
      workReport,
      selectedOrganization.value.organizationMemberId,
      {
        skipRequest: true,
      }
    )
  ) {
    alert("業務記録の更新に成功しました");
    closeReportUpdateFormModal();
  } else {
    alert("業務記録の更新に失敗しました");
  }
}

// event invitation

const {
  approveOrganizationEventInvitation,
  rejectOrganizationEventInvitation,
} = useOrganizationEvents();

async function handleUpdateOrganizationEventInvitation(
  invitationId: number,
  type: "approve" | "reject"
) {
  if (!selectedOrganization.value) {
    return;
  }

  const handler =
    type === "approve"
      ? approveOrganizationEventInvitation
      : rejectOrganizationEventInvitation;

  if (await handler(invitationId)) {
    alert("成功しました");
  } else {
    alert("失敗しました");
  }
}
</script>

<style lang="scss" scoped></style>
