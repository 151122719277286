import { Ref } from "vue";
import { Organization, EditRequestClient, EditRequestStatus } from "/@/types";
import useSWRV from "swrv";
import { axios, fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";

export function useEditRequest() {
  function getEditRequestsKey(organization: Ref<Organization | undefined>) {
    if (!organization.value) {
      return null;
    }

    return `/api/v1/organizations/${organization.value.id}/edit_requests?organization_member_id=${organization.value.organizationMemberId}`;
  }

  function getEditRequests(organization: Ref<Organization | undefined>) {
    return useSWRV<EditRequestClient[]>(
      () => getEditRequestsKey(organization),
      fetcher
    );
  }

  async function handleRequest(id: number, action: EditRequestStatus) {
    try {
      await axios.post(`/api/v1/edit_requests/${id}/${action}`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    getEditRequests,
    handleRequest,
  };
}
