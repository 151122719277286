<template>
  <div>
    <table
      class="table table-responsive table-bordered table-striped small table-sm"
    >
      <thead>
        <tr class="sticky">
          <th class="sticky td-sort"></th>
          <th class="sticky td-request-organization-name">依頼組織</th>
          <th class="sticky td-work-name">案件名</th>
          <th
            v-for="d in dates"
            :key="`header-${d.dateKey}`"
            class="td-row-item"
            :class="{
              'text-center': true,
              [getWeekdayColor(d, jpHolidays)]: true,
            }"
          >
            {{ d.luxonDate.day }}
          </th>
          <th class="td-row-item">稼動数</th>
        </tr>
        <tr class="sticky">
          <th class="sticky align-middle" colspan="3">現在人数/必要人数</th>
          <th
            v-for="d in dates"
            :key="`header-2-${d.dateKey}`"
            class="td-row-item align-middle"
            :class="{
              'text-center': true,
              [getWeekdayColor(d, jpHolidays)]: true,
            }"
          >
            <div
              class="w-100 d-flex flex-column align-items-center justify-content-center"
            >
              <span class="text-center">
                {{
                  `${countStatusEachDate[d.dateKey].currentCount}/${
                    countStatusEachDate[d.dateKey].needCount
                  }`
                }}
              </span>
              <span
                v-if="countStatusEachDate[d.dateKey].cancelCount"
                class="text-center"
              >
                {{ `(${countStatusEachDate[d.dateKey].cancelCount})` }}
              </span>
            </div>
          </th>
          <th class="sticky align-middle">
            <div class="w-100 d-flex flex-column">
              <span>{{
                `${totalCountStatus.currentCount}/${totalCountStatus.needCount}`
              }}</span>
              <span v-if="totalCountStatus.cancelCount > 0">{{
                `(${totalCountStatus.cancelCount})`
              }}</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(o, oidx) in organizationOrder" :key="o.id">
          <template v-if="organizationWorkMap[o.id]">
            <template
              v-for="(w, idxInOrganization) in organizationWorkMap[o.id].works"
              :key="`${o.name}-${w.workCategory}-${w.id}`"
            >
              <tr
                v-for="(wt, idxInWorkType) in organizationWorkMap[o.id]
                  .workTypesOfWork[w.id]"
                :key="`${o.id}-${w.id}-${wt.id}`"
              >
                <td
                  v-if="idxInOrganization === 0 && idxInWorkType === 0"
                  class="sticky td-sort"
                  :rowspan="organizationWorkMap[o.id].organizationRowSpan"
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center h-100"
                  >
                    <BasicButton
                      small
                      icon="pi pi-angle-up"
                      class="order-button"
                      :disabled="oidx === 0"
                      @click="upOrder(oidx)"
                    />
                    <BasicButton
                      v-if="oidx !== organizationOrder.length - 1"
                      small
                      icon="pi pi-angle-down"
                      class="order-button mt-1"
                      @click="downOrder(oidx)"
                    />
                  </div>
                </td>
                <td
                  v-if="idxInOrganization === 0 && idxInWorkType === 0"
                  class="text-center align-middle sticky td-request-organization-name"
                  width="50"
                  :rowspan="organizationWorkMap[o.id].organizationRowSpan"
                >
                  {{ o.name.replace("(株)", "") }}
                </td>
                <td class="sticky td-work-name text-center align-middle">
                  <div class="wrapper">
                    <template v-if="w.name">
                      {{ w.name }}
                    </template>
                    <template v-else>
                      <template v-if="w.workPlaces.length <= 2">
                        <div
                          v-for="wp in w.workPlaces"
                          :key="`work-place-name-${wp.id}`"
                        >
                          {{ wp.shortName || wp.name.slice(0, 3) }}
                        </div>
                      </template>
                      <template v-else>
                        <div
                          v-for="wp in w.workPlaces.slice(0, 3)"
                          :key="`work-place-name-${wp.id}`"
                        >
                          {{ wp.name }}
                        </div>
                        <div>...</div>
                      </template>

                      <div>
                        <span>
                          {{
                            `${carrierToPrefix(w.carrier)}${translater(
                              w.workCategory
                            )}`
                          }}
                        </span>
                        <span
                          v-if="translater(w.workCategory) !== wt.name"
                          class="small"
                        >
                          {{ "/" }}{{ wt.name }}
                        </span>
                      </div>
                    </template>
                  </div>
                </td>
                <OrganizationRowCalendarItem
                  v-for="d in dates"
                  :key="`body-${d.dateKey}`"
                  :work-schedules="
                    organizationWorkMap[o.id].workSchedulesOfWorkType[w.id][
                      wt.id
                    ]
                  "
                  :work-id="w.id"
                  :target-date="d.dateKey"
                  :work-type="wt"
                  :highlight-member-id="selectedMember?.id"
                  :highlight-non-enough="highlightNonEnough"
                  :highlight-canceled="highlightCanceled"
                  short
                  :is-mobile="isMobile"
                  @select-work-schedule="$emit('selectWorkSchedule', $event)"
                  @select-schedule-as-plan="
                    $emit('selectScheduleAsPlan', $event)
                  "
                  @select-empty="$emit('selectEmpty', $event)"
                />
                <td class="td-row-item align-middle">
                  <div class="w-100 d-flex flex-column">
                    <span class="text-center">
                      {{
                        `${countStatus[w.id][wt.id].currentCount}/${
                          countStatus[w.id][wt.id].needCount
                        }`
                      }}
                    </span>
                    <span
                      v-if="countStatus[w.id][wt.id].cancelCount > 0"
                      class="text-center"
                    >
                      {{ `(${countStatus[w.id][wt.id].cancelCount})` }}
                    </span>
                  </div>
                </td>
              </tr>
            </template>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
// TODO: コードが難解なためリファクタリング
// ループ回数を減らすために organizationWorkMap でほぼ全てのことをやろうとしているが、
// 多少計算量が増えても問題無さそうなので、もう少し分かりやすくするべき
import _ from "lodash";
import { ref, computed, watch } from "vue";
import { createDateForCalendars, getWeekdayColor } from "/@/modules/luxon";
import { carrierToPrefix, translater } from "/@/modules/string";
import { removeDup } from "/@/modules/array";
import { useMqUtils } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import type {
  DateForCalendar,
  DateMap,
  WorkAsPlan,
  WorkScheduleAsPlan,
  OrganizationMember,
  JpHolidays,
} from "/@/types";
import OrganizationRowCalendarItem from "./OrganizationRowCalendarItem.vue";

const props = defineProps<{
  dateMap: DateMap;
  workAsPlans: WorkAsPlan[];
  workSchedules: WorkScheduleAsPlan[];
  selectedMember?: OrganizationMember;
  highlightNonEnough?: boolean;
  highlightCanceled?: boolean;
  jpHolidays: JpHolidays;
}>();

defineEmits<{
  (e: "selectWorkSchedule", params: { id: number }): void;
  (e: "selectScheduleAsPlan", targetSchedule: WorkScheduleAsPlan): void;
  (
    e: "selectEmpty",
    prms: { workId: number; workTypeId: number; targetDate: string }
  ): void;
}>();

const { isMobile } = useMqUtils();

//

const dates = computed<DateForCalendar[]>(() => {
  if (!props.dateMap) {
    return [];
  }

  return createDateForCalendars(props.dateMap);
});

type WorkOrganization = { id: number; name: string };

const organizations = computed<WorkOrganization[]>(() => {
  const oDups: { id: number; name: string }[] = props.workAsPlans
    .map((w) => ({
      id: w.requestOrganizationId || 0,
      name: w.requestOrganizationName || "依頼店舗の指定無し",
    }))
    .flat();
  const os = removeDup<WorkOrganization[]>(oDups, "id");

  return os;
});

// order

const organizationOrder = ref<WorkOrganization[]>([]);

watch(organizations, (os) => {
  if (os && organizationOrder.value.length != os.length) {
    organizationOrder.value = [...os.map((o) => o)];
  }
});

function changeOrder(from: number, to: number) {
  organizationOrder.value.splice(
    to,
    0,
    organizationOrder.value.splice(from, 1)[0]
  );
}

function upOrder(currentIdx: number) {
  if (currentIdx === 0) return;

  changeOrder(currentIdx, currentIdx - 1);
}

function downOrder(currentIdx: number) {
  if (currentIdx === organizationOrder.value.length - 1) return;

  changeOrder(currentIdx, currentIdx + 1);
}

// work type obj

type WorkTypeObj = {
  id: number;
  name: string;
};

type OrganizationWorkMapItem = {
  id: number;
  name: string;
  works: WorkAsPlan[];
  workTypesOfWork: Record<number, WorkTypeObj[]>;
  workSchedulesOfWorkType: Record<number, Record<string, WorkScheduleAsPlan[]>>;
  organizationRowSpan: number;
  worksRowSpan: Record<number, number>;
};

type OrganizationWorkMap = Record<number, OrganizationWorkMapItem>;

const organizationWorkMap = computed<OrganizationWorkMap>(() => {
  let m: OrganizationWorkMap = {};

  organizations.value.forEach((o) => {
    const works = props.workAsPlans.filter(
      (wp) => wp.requestOrganizationId === o.id
    );

    let workTypesOfWork: Record<number, WorkTypeObj[]> = {};
    let workSchedulesOfWorkType: Record<
      number,
      Record<string, WorkScheduleAsPlan[]>
    > = {};

    let organizationRowSpan = 0;
    let worksRowSpan: Record<number, number> = {};

    works.forEach((w) => {
      const workSchedulesInWork =
        props.workSchedules?.filter((ws) => ws.work.id === w.id) || [];

      workTypesOfWork[w.id] = workSchedulesInWork.map((ws) => ({
        id: ws.workTypeId,
        name: ws.workTypeName,
      }));
      workTypesOfWork[w.id] = _.uniqBy(workTypesOfWork[w.id], "id");

      workSchedulesOfWorkType[w.id] = {};

      // create workSchedulesOfWorkType
      workTypesOfWork[w.id].forEach((workType) => {
        workSchedulesOfWorkType[w.id][workType.id] = workSchedulesInWork.filter(
          (ws) => ws.workTypeName === workType.name
        );
      });

      worksRowSpan[w.id] = workTypesOfWork[w.id].length;
      organizationRowSpan += worksRowSpan[w.id];
    });

    m[o.id] = {
      id: o.id,
      name: o.name,
      works,
      workTypesOfWork,
      workSchedulesOfWorkType,
      organizationRowSpan,
      worksRowSpan,
    };
  });

  return m;
});

type CountStatusMap = Record<
  number,
  Record<
    number,
    { currentCount: number; needCount: number; cancelCount: number }
  >
>;

const countStatus = computed(() => {
  let m: CountStatusMap = {};

  organizations.value.forEach((o) => {
    const works = organizationWorkMap.value[o.id].works;

    works.forEach((w) => {
      m[w.id] = {};
      organizationWorkMap.value[o.id].workTypesOfWork[w.id].forEach((wt) => {
        m[w.id][wt.id] = {
          currentCount: organizationWorkMap.value[o.id].workSchedulesOfWorkType[
            w.id
          ][wt.id]
            .filter((sche) => !sche.canceled)
            .map((sche) =>
              sche.currentCount
                ? sche.currentCount -
                  sche.assignedMembers.filter((m) => m.absence).length
                : 0
            )
            .reduce((acc, n) => acc + n, 0),
          needCount: organizationWorkMap.value[o.id].workSchedulesOfWorkType[
            w.id
          ][wt.id]
            .filter((sche) => !sche.canceled)
            .map((sche) => sche.needCount || 0)
            .reduce((acc, n) => acc + n, 0),
          cancelCount: organizationWorkMap.value[o.id].workSchedulesOfWorkType[
            w.id
          ][wt.id]
            .filter((sche) => sche.canceled)
            .map((sche) => sche.needCount || 0)
            .reduce((acc, n) => acc + n, 0),
        };
      });
    });
  });

  return m;
});

type CountStatusEachDateMap = Record<
  string,
  Partial<{ currentCount: number; needCount: number; cancelCount: number }>
>;

const countStatusEachDate = computed<CountStatusEachDateMap>(() => {
  let m: CountStatusEachDateMap = {};

  dates.value.forEach((d) => {
    m[d.dateKey] = {};

    const targetDateSchedules = props.workSchedules.filter(
      (schedule) => schedule.targetDate === d.dateKey && !schedule.canceled
    );

    const canceledSchedules = props.workSchedules.filter(
      (schedule) => schedule.targetDate === d.dateKey && schedule.canceled
    );

    m[d.dateKey] = {
      currentCount: targetDateSchedules.reduce(
        (acc, schedule) =>
          acc +
          (schedule.currentCount
            ? schedule.currentCount -
              schedule.assignedMembers.filter((m) => m.absence).length
            : 0),
        0
      ),
      needCount: targetDateSchedules.reduce(
        (acc, schedule) => acc + (schedule.needCount || 0),
        0
      ),
      cancelCount: canceledSchedules.reduce(
        (acc, schedule) =>
          acc + (schedule.canceled ? schedule.needCount || 0 : 0),
        0
      ),
    };
  });

  /*
  organizations.value.forEach((o) => {
    const works = organizationWorkMap.value[o.id].works;

    works.forEach((w) => {
      m[w.id] = {};
      organizationWorkMap.value[o.id].workTypesOfWork[w.id].forEach((wt) => {
        m[w.id][wt.id] = {
          currentCount: organizationWorkMap.value[o.id].workSchedulesOfWorkType[
            w.id
          ][wt.id]
            .map((sche) => sche.currentCount || 0)
            .reduce((acc, n) => acc + n, 0),
          needCount: organizationWorkMap.value[o.id].workSchedulesOfWorkType[
            w.id
          ][wt.id]
            .map((sche) => sche.needCount || 0)
            .reduce((acc, n) => acc + n, 0),
          cancelCount: organizationWorkMap.value[o.id].workSchedulesOfWorkType[
            w.id
          ][wt.id]
            .map((sche) => (sche.canceled ? sche.needCount || 0 : 0))
            .reduce((acc, n) => acc + n, 0),
        };
      });
    });
  });
  */

  return m;
});

const totalCountStatus = computed(() => {
  // get sum of each values in countStatusEachData
  const currentCount = Object.values(countStatusEachDate.value).reduce(
    (acc, n) => acc + (n.currentCount || 0),
    0
  );
  const needCount = Object.values(countStatusEachDate.value).reduce(
    (acc, n) => acc + (n.needCount || 0),
    0
  );
  const cancelCount = Object.values(countStatusEachDate.value).reduce(
    (acc, n) => acc + (n.cancelCount || 0),
    0
  );

  return {
    currentCount,
    needCount,
    cancelCount,
  };
});
</script>

<style scoped lang="scss">
.order-button {
  height: 20px;
}

th.sticky:nth-child(2),
td.sticky:nth-child(2) {
  left: 30px;
}

.td-sort {
  max-width: 30px;
  min-width: 30px;
}

.td-request-organization-name {
  max-width: 60px;
  min-width: 60px;
}

.td-work-name {
  max-width: 50px;
  min-width: 50px;
}

th.sticky:nth-child(3),
td.sticky:nth-child(3),
td.sticky.td-work-name {
  left: 90px;
}

tr.sticky:nth-child(2) {
  th,
  td {
    top: 17px;
  }
}

.td-row-item {
  max-width: 45px;
  min-width: 45px;
}
</style>
