import zod from "zod";
import { z } from "/@/modules/zodUtils";

export const AdminScheme = z.object({
  id: z.number().optional(),
  email: z.string().email(),
  password: z.string().optional(),
});

export type Admin = zod.infer<typeof AdminScheme>;
