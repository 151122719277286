<template>
  <div>
    <ContentCard title="パスワードの確認">
      <div class="form-group">
        <div class="mb-2">
          <ContentLabel label="パスワード" content-id="password" />
          <PasswordForm
            :value="password"
            form-id="password"
            :is-valid="isValid"
            :readonly="canCheckCount <= 0"
            @update:value="updatePassword"
          />
        </div>

        <MessageList v-if="showMessage" v-bind="checkedMessage" />

        <div class="d-flex mt-4">
          <BasicButton
            class="me-2"
            button-type="submit"
            icon="pi pi-check"
            label="確認"
            :disabled="!isValid || loading || !canCheck"
            @click="checkPassword"
          />
          <BasicButton
            id="reset"
            variant="danger"
            class="ms-3"
            icon="pi pi-refresh"
            :disabled="loading"
            label="パスワードの再設定"
            @click="openModal"
          />
        </div>
      </div>
    </ContentCard>
    <teleport to="body">
      <SimpleModal
        ref="modal"
        title="パスワードの再設定"
        submit-text="送信"
        submit-text-variant="danger"
        @cancel="closeModal"
        @submit="
          $emit('reset-password');
          closeModal();
        "
        @hidden-bs-modal="closeModal"
      >
        <div class="fw-bold">
          登録してあるメールアドレス宛にパスワード変更のメールを送信します。またセキュリティのため自動的にログアウトされます。
        </div>
      </SimpleModal>
    </teleport>
  </div>
</template>

<script setup lang="ts">
import { onMounted, computed, ref, watch } from "vue";
import {
  ContentCard,
  ContentLabel,
  BasicButton,
  PasswordForm,
  MessageList,
} from "/@/vue/components/Atom";
import { SimpleModal } from "/@/vue/components/Molecules";
import type { MessageStatus } from "/@/types";

const props = defineProps<{
  loading?: boolean;
  validPassword?: boolean;
  checkCount: number;
}>();

const emit = defineEmits<{
  (e: "update:password"): void;
  (e: "check-password", pass: string | undefined): void;
  (e: "reset-password"): void;
}>();

const password = ref<string>();

const isValid = computed(() => {
  return !!password.value;
});

const maxCheckCount = 3;

onMounted(() => {
  if (props.checkCount >= maxCheckCount) {
    showMessage.value = true;
  }
});

watch(
  () => [props.checkCount, props.validPassword],
  () => {
    showMessage.value = true;
  }
);

const canCheckCount = computed(() => {
  return maxCheckCount - props.checkCount;
});

const checkedMessage = computed<{ messages: string[]; status: MessageStatus }>(
  () => {
    let messages: string[] = [];
    let status: MessageStatus;

    if (canCheckCount.value < 1) {
      messages = [
        "パスワードの確認可能回数が0になりました。",
        "パスワードの再設定を行って下さい",
      ];
      status = "error";
    } else {
      messages = props.validPassword
        ? ["正しいパスワードです"]
        : ["パスワードが正しくありません", `残り${canCheckCount.value}回`];
      status = props.validPassword ? "success" : "error";
    }

    return {
      messages,
      status,
    };
  }
);

const showMessage = ref(false);

function updatePassword(pass: string | null) {
  password.value = pass ?? undefined;
  showMessage.value = false;
}

function checkPassword() {
  emit("check-password", password.value);
}

const canCheck = computed(() => {
  return props.checkCount < maxCheckCount;
});
// modal

const modal = ref();

function openModal() {
  modal.value.openModal();
}

function closeModal() {
  modal.value.closeModal();
}
</script>
