import Edit from "./Edit.vue";
import Index from "./Index.vue";
import New from "./New.vue";

export default [
  {
    path: "",
    name: "WorksIndex",
    component: Index,
  },
  {
    path: "new",
    name: "WorksNew",
    component: New,
  },
  {
    path: "edit",
    name: "WorksEdit",
    component: Edit,
  },
];
