<template>
  <div>
    <div
      role="button"
      :class="`collapse-header ${isOpen ? 'open' : ''} ${
        cardHeader ? 'card-body' : ''
      } ${disabled ? 'disabled' : ''}`"
      @click="toggle"
    >
      <slot name="header"></slot>
    </div>
    <div
      :id="contentsId"
      ref="el"
      class="collapse"
      @click="stopToggleBody ? undefined : toggle"
    >
      <div
        :class="{
          'card-body': !withoutBodyPadding,
        }"
      >
        <slot name="contents"></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Collapse } from "bootstrap";
import { onMounted, ref } from "vue";
import { useCollapse } from "/@/vue/composables";

const props = defineProps<{
  contentsId: string;
  disabled?: boolean;
  cardHeader?: boolean;
  openDefault?: boolean;
  withoutBodyPadding?: boolean;
  stopToggleBody?: boolean;
}>();

const emits = defineEmits<{
  (e: "toggleCollapse", isOpen: boolean): void;
}>();

// collapse

const { isOpen, prepareCollapse } = useCollapse();

const el = ref();
const col = ref();

onMounted(() => {
  col.value = new Collapse(el.value, {
    toggle: props.openDefault,
  });

  prepareCollapse(el.value);
});

async function toggle(e: Event) {
  e.stopPropagation();

  if (props.disabled) {
    return;
  }
  col.value.toggle();

  emits("toggleCollapse", !isOpen.value); // TODO: too hacky
}
</script>

<style scoped lang="scss">
.collapse-header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &::after {
    content: "";
    flex-shrink: 0;
    width: 0.75rem;
    height: 1rem;
    margin-left: auto;
    padding-left: 1rem;
    background-repeat: no-repeat;
    background-size: 0.75rem;
    background-position: center;
    transition: transform 0.2s ease-in-out;
  }

  &:not(.disabled)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }

  &.open {
    &::after {
      transform: rotate(180deg);
    }
  }
}

.btn.disabled {
  border: none;
}
</style>
