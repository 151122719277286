<template>
  <div>
    <OrganizationEventBaseInfo :organization-event="organizationEvent" />
    <div>
      <div class="mb-2"></div>
      <ContentLabel label="参加者" />
      <template v-if="organizationEvent.organizationMemberIds.length">
        <div style="max-height: 200px; overflow-y: scroll">
          <div
            v-for="(m, idx) in memberAndStatus"
            :key="m.member?.id"
            class="d-flex flex-wrap align-items-center"
          >
            <div class="me-2">{{ m.member?.user.name }}</div>
            <Chip :class="eventColorClass(m.status)">{{
              translater(m.status)
            }}</Chip>
            <Divider v-if="idx !== memberAndStatus.length - 1" />
          </div>
        </div>
      </template>
      <div v-else>未定</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { eventColorClass } from "/@/modules/organizationEvent";
import { translater } from "/@/modules/string";
import { ContentLabel } from "/@/vue/components/Atom";
import { OrganizationEventBaseInfo } from "/@/vue/components/Organisms";
import { OrganizationEventClient, OrganizationMember } from "/@/types";
import Chip from "primevue/chip";
import Divider from "primevue/divider";

const props = defineProps<{
  organizationEvent: OrganizationEventClient;
  organizationMembers: OrganizationMember[];
}>();

const memberAndStatus = computed(() => {
  return props.organizationEvent.organizationEventInvitations.map((i) => {
    const member = props.organizationMembers.find(
      (m) => m.id === i.organizationMemberId
    );
    return {
      member,
      status: i.eventApplicationStatus,
    };
  });
});
</script>

<style scoped></style>
