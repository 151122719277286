<template>
  <div>
    <SimpleModal
      ref="modal"
      :title="form.id ? '目標の更新' : '目標の作成'"
      submit-text="送信"
      :loading="loading"
      @cancel="closeModal"
      @submit="handleSubmit"
    >
      <FormItemSelector
        v-model:value="selectedWorkSchedule"
        label="目標設定する業務"
        form-type="object"
        :errors="{}"
        :items="workSchedules"
        form-id="selected-work-schedules"
        :show-fn="workScheduleToTitle"
        value-key="id"
        class="mb-3"
      />
      <MeetingTargetForm
        v-if="selectedWorkSchedule"
        v-model:report-items="form.reportItems"
        :work-type-categories="selectedWorkSchedule.workTypeCategories"
        :work-carrier="selectedWorkSchedule.workCarrier"
        :work-category="selectedWorkSchedule.workCategory"
        :report-type="'overall'"
        :meeting-target-filter="meetingTargetFilter"
        :basis-report-items="basisReportItems"
        :is-create="isCreate"
        :errors="errors"
      />
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { workScheduleToTitle } from "/@/modules/workSchedule";
import { FormItemSelector, SimpleModal } from "/@/vue/components/Molecules";
import { MeetingTargetForm } from "/@/vue/components/Organisms";
import { useZodScheme } from "/@/vue/composables";
import {
  MeetingTargetForm as MeetingTargetFormType,
  MeetingTargetSubmitScheme,
  MeetingTargetSubmit,
  ScheduleInOrganizationMember,
  WorkSchedule,
  BasisReportItemClient,
} from "/@/types";
import { errorHandle } from "/@/modules/error";
import { watch } from "vue";
import { computed } from "vue";

const props = defineProps<{
  organizationMemberId?: number;
  workSchedules: ScheduleInOrganizationMember[] | WorkSchedule[];
  basisReportItems: BasisReportItemClient[];
  isCreate?: boolean;
}>();

const emit = defineEmits<{
  (
    e: "submit",
    workId: number,
    workScheduleId: number,
    form: MeetingTargetFormType
  ): void;
}>();

const loading = ref<boolean>();

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } =
  useFormAndErrors<MeetingTargetFormType>(MeetingTargetSubmitScheme, {
    reportItems: [],
    organizationMemberId: props.organizationMemberId,
  });

const selectedWorkSchedule = ref<ScheduleInOrganizationMember>();

const meetingTargetFilter = computed(() => {
  return selectedWorkSchedule.value?.meetingTargetFilter;
});

function formReset() {
  if (!props.organizationMemberId) return;

  form.reportItems = [];
  form.organizationMemberId = props.organizationMemberId;
}

watch(selectedWorkSchedule, (schedule) => {
  if (schedule) {
    formReset();
  }
});

async function handleSubmit() {
  try {
    if (!props.organizationMemberId)
      throw new Error("メンバー情報がありません");

    if (!selectedWorkSchedule.value)
      throw new Error("業務が選択されていません");

    loading.value = true;
    startValidation.value = true;

    form.organizationMemberId = props.organizationMemberId;

    const meetingTargetPrms: MeetingTargetSubmit =
      MeetingTargetSubmitScheme.parse(form);

    const workId = selectedWorkSchedule.value.workId;
    const workScheduleId = selectedWorkSchedule.value.id;

    emit("submit", workId, workScheduleId, meetingTargetPrms);
    closeModal();
    formReset();
  } catch (e) {
    errorHandle(e);
    loading.value = false;
    formReset();
  }
}

const modal = ref();

function openModal() {
  modal.value?.openModal();
}

function closeModal() {
  modal.value?.closeModal();
}

defineExpose({
  openModal,
  closeModal,
});
</script>

<style scoped></style>
